import React from 'react';
import { lightIcon } from './LightIcon.scss';

/** Icon names that need to be changed to match the expected "Light" variation name */
const iconNameCorrections: {
    [iconName: string]: string;
} = {
    ArrowDownFilled: 'ArrowDownRegular',
    BorderAllRegular: 'TableSimpleRegular',
    DocumentSignatureRegular: 'SignatureRegular',
    MailArrowDoubleBackFilled: 'MailArrowDoubleBackRegular',
    MicFilled: 'MicRegular',
    TextTRegular: 'TextRegular',
};

export const LightIcon = ({ iconName }: { iconName: string }) => {
    return <i className={`${lightIcon} lightIcon--${iconNameCorrections[iconName] || iconName}`} />;
};
