import React from 'react';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import {
    makeStyles,
    mergeClasses,
    Button,
    Input,
    MenuList,
    MenuItemRadio,
} from '@fluentui/react-components';
import { iconRegularClassName } from '@fluentui/react-icons';
import type {
    InputOnChangeData,
    MenuCheckedValueChangeEvent,
    MenuCheckedValueChangeData,
} from '@fluentui/react-components';
import CategoryIcon from 'owa-categories/lib/components/CategoryIcon';
import getMasterCategoryList from 'owa-categories/lib/utils/getMasterCategoryList';
import type { MailboxInfo } from 'owa-client-types';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import type { TableView, MailFolderTableQuery } from 'owa-mail-list-store';
import { type SortColumn } from 'owa-mail-list-store';

import { getJumpToCategoryOptions } from '../helpers/getJumpToCategoryOptions';
import { getJumpToSizeOptions } from '../helpers/getJumpToSizeOptions';
import { getJumpToImportanceOptions } from '../helpers/getJumpToImportanceOptions';
import executeJumpTo from '../orchestrators/executeJumpTo';
import { goButtonLabel, jumpToInputPlaceholder } from './MailListJumpToPopover.locstring.json';
import {
    popoverSurfaceContainer,
    goButton,
    jumpToInput,
    checkmarkDisplay,
    categoryMenuContainer,
    categoryIcon,
    popoverSurfaceText,
    full,
} from './MailListJumpToPopover.scss';

import { LazyJumpToDateCalloutContent } from '../lazyComponents';

import classnames from 'owa-classnames';

export interface JumpToCalloutContentProps {
    tableView: TableView;
    mailboxInfo: MailboxInfo;
    onClose: () => void;
}

const checkmarkProps = { className: checkmarkDisplay };

const useStyles = makeStyles({
    menuItem: {
        '&:hover': {
            [`& .${iconRegularClassName}`]: {
                display: 'inline-flex !important',
            },
        },
    },
});

export default observer(function JumpToCalloutContent(props: JumpToCalloutContentProps) {
    const { mailboxInfo, tableView, onClose } = props;
    const [inputValue, setInputValue] = React.useState('');
    const sortColumn = (tableView?.tableQuery as MailFolderTableQuery)?.sortBy?.sortColumn;
    const sortDirection = (tableView?.tableQuery as MailFolderTableQuery)?.sortBy?.sortDirection;
    const categoryList = getMasterCategoryList(mailboxInfo);
    const styles = useStyles();
    const densityMode = getDensityModeCssClass(full, undefined, undefined);

    // Show the Go button when the user needs to input some data and then trigger the jump.
    // For the sort columns where we have a dropdown, we don't need the Go button because we trigger the jump on selection.
    const shouldShowGoButton = React.useMemo(() => {
        return sortColumn === 1 || sortColumn === 8 || sortColumn === 3;
    }, [sortColumn]);

    const onGoClicked = React.useCallback(() => {
        onClose();
        if (inputValue !== '') {
            executeJumpTo(tableView, inputValue /*restrictionValue*/);
        }
    }, [inputValue, onClose, tableView]);

    const setInputValueCallback = React.useCallback((input: string) => {
        setInputValue(input);
    }, []);

    const handleInputChange = React.useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            ev.stopPropagation();
            if (data.value !== undefined) {
                setInputValue(data.value);
            } else {
                setInputValue('');
            }
        },
        []
    );

    const onCheckedValueChange = React.useCallback(
        (ev: MenuCheckedValueChangeEvent, data: MenuCheckedValueChangeData) => {
            ev.stopPropagation();
            onClose();
            const checkedItem = data.checkedItems?.[0];
            if (checkedItem !== undefined) {
                executeJumpTo(tableView, checkedItem /*restrictionValue*/);
            }
        },
        [onClose, tableView]
    );

    const onKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                onGoClicked();
                event.preventDefault();
                event.stopPropagation();
            }
        },
        [onGoClicked]
    );

    const getCategoryIcon = React.useCallback(
        (categoryName: string) => {
            return (
                <CategoryIcon
                    categoryName={categoryName}
                    iconClassName={categoryIcon}
                    categoryList={categoryList}
                    mailboxInfo={mailboxInfo}
                />
            );
        },
        [categoryList, mailboxInfo]
    );

    const inputForSortColumn = React.useMemo((): JSX.Element | null => {
        switch (sortColumn) {
            case 3:
            case 8:
                return (
                    <Input
                        className={classnames(jumpToInput, popoverSurfaceText, densityMode)}
                        placeholder={loc(jumpToInputPlaceholder)}
                        onChange={handleInputChange}
                        onKeyDown={onKeyDown}
                    />
                );
            case 5:
                return (
                    <MenuList onCheckedValueChange={onCheckedValueChange}>
                        {getJumpToImportanceOptions().map(option => {
                            return (
                                <MenuItemRadio
                                    key={option.value}
                                    className={classnames(popoverSurfaceText, densityMode)}
                                    checkmark={checkmarkProps}
                                    name="importance"
                                    value={option.value}
                                >
                                    {loc(option.text)}
                                </MenuItemRadio>
                            );
                        })}
                    </MenuList>
                );
            case 1:
                return (
                    <LazyJumpToDateCalloutContent
                        tableView={tableView}
                        onCheckedValueChange={onCheckedValueChange}
                        setInputValue={setInputValueCallback}
                    />
                );
            case 14:
                return (
                    <MenuList
                        className={classnames(categoryMenuContainer, 'customScrollBar')}
                        onCheckedValueChange={onCheckedValueChange}
                    >
                        {getJumpToCategoryOptions(categoryList).map(option => {
                            return (
                                <MenuItemRadio
                                    key={option.value}
                                    checkmark={checkmarkProps}
                                    name="category"
                                    value={option.value}
                                    className={mergeClasses(
                                        styles.menuItem,
                                        popoverSurfaceText,
                                        densityMode
                                    )}
                                >
                                    <>
                                        {getCategoryIcon(option.value)}
                                        {option.text}
                                    </>
                                </MenuItemRadio>
                            );
                        })}
                    </MenuList>
                );
            case 7:
                return (
                    <MenuList onCheckedValueChange={onCheckedValueChange}>
                        {getJumpToSizeOptions(sortDirection).map(option => {
                            return (
                                <MenuItemRadio
                                    key={option.value}
                                    className={classnames(popoverSurfaceText, densityMode)}
                                    checkmark={checkmarkProps}
                                    name="size"
                                    value={option.value.toString()}
                                >
                                    {loc(option.text)}
                                </MenuItemRadio>
                            );
                        })}
                    </MenuList>
                );
            default:
                return null;
        }
    }, [sortColumn, sortDirection, tableView, onCheckedValueChange, onKeyDown]);

    return (
        <div className={popoverSurfaceContainer}>
            {inputForSortColumn}
            {shouldShowGoButton && (
                <Button
                    onClick={onGoClicked}
                    className={classnames(goButton, popoverSurfaceText, densityMode)}
                >
                    {loc(goButtonLabel)}
                </Button>
            )}
        </div>
    );
}, 'JumpToCalloutContent');
