/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Using transaction to mitigate a perf issue; this should be refactored to use idiomatic
 * Satchel/MobX patterns (https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/242008) */
import { transaction } from 'mobx';
import mailStore from 'owa-mail-store/lib/store/Store';
import tryRemoveFromMailStoreItems, {
    type RemoveItemSource,
} from 'owa-mail-actions/lib/triage/tryRemoveFromMailStoreItems';
import { onDeleteConversationItemPartsComplete } from 'owa-mail-actions/lib/mailTriageActions';
import { mutatorAction } from 'satcheljs';

export default function deleteConversationItemParts(conversationId: string) {
    transaction(() => {
        if (mailStore.conversations.has(conversationId)) {
            const conversationItemParts = mailStore.conversations.get(conversationId);
            if (conversationItemParts) {
                for (const conversationNodeId of conversationItemParts.conversationNodeIds) {
                    const conversationNode = mailStore.conversationNodes.get(conversationNodeId);
                    if (conversationNode) {
                        for (const itemId of conversationNode.itemIds) {
                            tryRemoveFromMailStoreItems(itemId, 1);
                        }
                    }
                    deleteConversationNode(conversationNodeId);
                }
            }

            deleteConversation(conversationId);
        }

        onDeleteConversationItemPartsComplete();
    });
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const deleteConversationNode = mutatorAction(
    'deleteConversationNode',
    (conversationNodeId: string) => {
        mailStore.conversationNodes.delete(conversationNodeId);
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const deleteConversation = mutatorAction('deleteConversation', (conversationId: string) => {
    mailStore.conversations.delete(conversationId);
});
