import {
    add as addToAlwaysInCacheList,
    clear as clearFromAlwaysInCacheList,
} from './alwaysInCacheTableIdList';
import { isFocusedInboxEnabled } from 'owa-mail-triage-common';
import {
    getNonFocusedInboxTableQuery,
    getFocusedInboxTableQuery,
    getOtherInboxTableQuery,
} from 'owa-mail-triage-table-utils/lib/getDefaultInboxTableQueries';
import type { MailFolderTableQuery, SortBy, TableView } from 'owa-mail-list-store';
import { getViewFilterForTable, type SortColumn, isDigestFilter } from 'owa-mail-list-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';

export const FOCUSED_INBOX = 'FOCUSED';
export const OTHER_INBOX = 'OTHER';
export const ALL_INBOX = 'ALL';

let currentSortBy: SortBy | undefined;
let currentIsFocusedInboxEnabled: boolean | undefined;
let currentListViewType: ReactListViewType | undefined;

/**
 * This function is called to update the "always in cache" table views when the
 * app starts or when the user's F/O setting changes.
 *
 * @param mailboxInfo
 */
export function initializeAlwaysInCacheTableViews(mailboxInfo: MailboxInfo) {
    if (shouldUpdateAlwaysInCacheTableViews(mailboxInfo, currentSortBy, currentListViewType)) {
        updateAlwaysInCacheTableViews(mailboxInfo, currentSortBy);
    }
}

/**
 * This function will attempt to update the "always in cache" table views (if
 * applicable).
 *
 * The function returns true if the table view is an "always in cache"
 * table view. Otherwise, it returns false which indicates the table view should
 * be subject to the MRU cache.
 *
 * @param tableView Table view to be cached
 * @returns True if the table view is an "always in cache" table view, false otherwise
 */
export function tryUpdateAlwaysInCacheTableViews(tableView: TableView): boolean {
    const mailTableQuery = tableView.tableQuery as MailFolderTableQuery;

    // Only cache Inbox with All filter and for primary accounts
    if (
        tableView?.tableQuery?.folderId &&
        folderIdToName(tableView.tableQuery.folderId) === 'inbox' &&
        getViewFilterForTable(tableView) === 'All' &&
        !isDigestFilter(mailTableQuery) &&
        getIndexerValueForMailboxInfo(getMailboxInfo(tableView)) ===
            getIndexerValueForMailboxInfo(
                getGlobalSettingsAccountMailboxInfo()
            ) /* Primary Account check - We need to explicitly add the table view to cache for secondary accounts so return false for them */
    ) {
        const mailboxInfo = getMailboxInfo(tableView);
        const sortBy = mailTableQuery.sortBy;

        if (
            shouldUpdateAlwaysInCacheTableViews(
                mailboxInfo,
                sortBy,
                tableView.tableQuery.listViewType
            )
        ) {
            updateAlwaysInCacheTableViews(mailboxInfo, sortBy);
        }

        return true;
    }

    return false;
}

/**
 * This function will determine if the "always in cache" table views needs to
 * be updated.
 *
 * @param mailboxInfo
 * @param sortBy SortBy object for the table view to be cached
 * @param listViewType ListViewType for the table view to be cached
 * @returns True if the "always in cache" table views needs to be updated, false otherwise.
 */
function shouldUpdateAlwaysInCacheTableViews(
    mailboxInfo: MailboxInfo,
    sortBy: SortBy | undefined,
    listViewType: ReactListViewType | undefined
): boolean {
    const newIsFocusedInboxEnabled = isFocusedInboxEnabled(mailboxInfo);

    // If the table view that is being added is the same as the current one,
    // then we don't need to do anything.
    return !(
        sortBy &&
        currentSortBy &&
        sortBy.sortDirection === currentSortBy.sortDirection &&
        sortBy.sortColumn === currentSortBy.sortColumn &&
        currentIsFocusedInboxEnabled == newIsFocusedInboxEnabled &&
        currentListViewType === listViewType
    );
}

/**
 * This function will update the "always in cache" table views based on the
 * current F/O setting.
 */
async function updateAlwaysInCacheTableViews(mailboxInfo: MailboxInfo, sortBy: SortBy | undefined) {
    let inboxQuery;
    if (isFocusedInboxEnabled(mailboxInfo)) {
        const focusedTableQuery = getFocusedInboxTableQuery(mailboxInfo);
        const otherTableQuery = getOtherInboxTableQuery(mailboxInfo);
        inboxQuery = focusedTableQuery;
        if (sortBy !== undefined) {
            focusedTableQuery.sortBy = sortBy;
            otherTableQuery.sortBy = sortBy;

            if (sortBy.sortColumn === 14) {
                focusedTableQuery.listViewType = 1;
                otherTableQuery.listViewType = 1;
            }
        }

        clearAlwaysInCacheTableViews();

        // Add updated Focused and Other tables
        addToAlwaysInCacheList(FOCUSED_INBOX, focusedTableQuery);
        addToAlwaysInCacheList(OTHER_INBOX, otherTableQuery);
    } else {
        const nonFocusedTableQuery = getNonFocusedInboxTableQuery(mailboxInfo);
        inboxQuery = nonFocusedTableQuery;
        if (sortBy !== undefined) {
            nonFocusedTableQuery.sortBy = sortBy;

            if (sortBy.sortColumn === 14) {
                nonFocusedTableQuery.listViewType = 1;
            }
        }

        clearAlwaysInCacheTableViews();

        // Add updated Inbox table
        addToAlwaysInCacheList(ALL_INBOX, nonFocusedTableQuery);
    }

    // Update references to current values of the Inbox table view being
    // cached so that we can compare against them next time.
    currentSortBy = inboxQuery.sortBy;
    currentIsFocusedInboxEnabled = inboxQuery.focusedViewFilter !== -1;
    currentListViewType = inboxQuery.listViewType;
}

function clearAlwaysInCacheTableViews() {
    clearFromAlwaysInCacheList(ALL_INBOX);
    clearFromAlwaysInCacheList(FOCUSED_INBOX);
    clearFromAlwaysInCacheList(OTHER_INBOX);
}

export function resetForTests() {
    currentSortBy = undefined;
    currentIsFocusedInboxEnabled = undefined;
    currentListViewType = undefined;
}
