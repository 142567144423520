import { useLayoutEffect, useRef, useCallback } from 'react';
import { blockPlt, type PltBlocker } from 'owa-performance';

/**
 * This hook blocks the PLT until the returned function is called.
 * This is useful for components that need to wait for some async operation to complete
 * before they can be considered "loaded".
 * The returned function should be called when the async operation is complete.
 */
export function useBlockPlt(source: PltBlocker) {
    const unblockPltRef = useRef<(() => void) | null>(null);
    const earlyCallRef = useRef(false);

    const unblockPlt = useCallback(() => {
        if (unblockPltRef.current) {
            unblockPltRef.current();
        } else {
            earlyCallRef.current = true;
        }
    }, []);

    useLayoutEffect(() => {
        if (!earlyCallRef.current) {
            unblockPltRef.current = blockPlt(source);
        }

        return () => unblockPltRef.current?.();
    }, []);

    return unblockPlt;
}
