import { owaComputedFn } from 'owa-computed-fn';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import type { Layout } from 'owa-acui';

export const getMLRViewTabGroupLayouts = owaComputedFn(function getMLRViewTabGroupLayouts(
    _group: RibbonGroupId,
    _props: ReadOnlyRibbonControlDefProps
): Layout[] | undefined {
    // View tab has no group layouts
    return undefined;
});
