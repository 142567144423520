import { addBootTiming, safeRequestAnimationFrame } from 'owa-performance';
import { getConfig } from 'owa-service/lib/config';
import type { LoaderOptions } from './types/LoaderOptions';
const ANIMATION_DURATION = 150;
const ANIMATION_EASING = 'cubic-bezier(.75,0,1,.75)';

export function removeLoadingScreen(options?: LoaderOptions) {
    const loadingScreen = document.getElementById('loadingScreen');
    if (loadingScreen && loadingScreen.parentNode == document.body) {
        addBootTiming('ls_e');
        const finish = () => {
            safeRequestAnimationFrame(() => {
                document.body.removeChild(loadingScreen);
            });
        };

        const fadeOut =
            options?.fadeOut && getConfig().isFeatureEnabled('fwk-loader-fadeout')
                ? loadingScreen.animate?.([{ opacity: 1 }, { opacity: 0 }], {
                      duration: ANIMATION_DURATION,
                      easing: ANIMATION_EASING,
                  })
                : undefined;
        if (fadeOut) {
            fadeOut.onfinish = finish;
        } else {
            finish();
        }
    }
}
