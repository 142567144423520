import type { MailboxInfo } from 'owa-client-types';
import type OwaNonBootUserConfiguration from 'owa-service/lib/contract/OwaNonBootUserConfiguration';

export interface GetNonBootUserConfigurationOverride {
    (mailboxInfo: MailboxInfo): Promise<OwaNonBootUserConfiguration>;
}

export let getNonBootUserConfigurationOverride: GetNonBootUserConfigurationOverride | undefined;

export function setNonBootUserConfigurationOverride(value: GetNonBootUserConfigurationOverride) {
    getNonBootUserConfigurationOverride = value;
}
