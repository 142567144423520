import { writeQuery } from 'owa-apollo';
import { hasLaunchPage } from 'owa-m365-acquisitions/lib/utils/hasStaticTab';
import { LaunchPageAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { errorThatWillCauseAlert } from 'owa-trace';

import { convertAcquisitionToM365Application } from './convertAcquisitionToM365Application';
import { getAppAcquisitionLink } from '../data/getAppAcquisitionLink';
import { NovaControl } from '../types';
import { AppBarControlDataDocument } from '../graphql/query/__generated__/AppBarControlData.interface';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { M365Acquisition, StrictAcquisition } from 'owa-m365-acquisitions/lib/types';
import type {
    StrictAppBarControlDataQuery,
    StrictM365Application,
    StrictM365ApplicationEdge,
} from '../types';
import type { MailboxInfo } from 'owa-client-types';
import { getAppBarTilesIndices } from '../data/getAppBarTilesIndices';
import { isMOSLaunchPagesEnabled } from 'owa-m365-acquisitions/lib/utils/isMOSLaunchPagesEnabled';

/**
 * Filter, from app catalog that is currently in the cache, which acquisitions
 * should be transformed and written to App Bar's data query.
 * @param  {M365Acquisition} acquisition
 * @param  {boolean} isLaunchPagesEnabled
 * @returns boolean
 */
function onlyLaunchPagesOrUserPinnedTiles(
    acquisition: M365Acquisition,
    isLaunchPagesEnabled: boolean
): boolean {
    if (
        acquisition.titleId === LaunchPageAppId.AppStore ||
        acquisition.titleId === LaunchPageAppId.AppStoreModal ||
        acquisition.titleId === LaunchPageAppId.AppStoreAbout
    ) {
        // Don't render AppStore tiles – they are displayed through AppAcquisitionLink.
        return false;
    }

    if (!isLaunchPagesEnabled) {
        return (
            !!acquisition.titleDefinition.categories?.find(
                category => category === 'outlook_app' || category === 'm365_native_app'
            ) && hasLaunchPage(acquisition)
        );
    }

    if (acquisition.titleDefinition.ingestionSource === 'UserPinnedAcquisition') {
        return true;
    }

    return hasLaunchPage(acquisition);
}

/**
 * Converts the results for a `m365Acquisitions` query into `m365Apps` query, which feeds
 * the App Bar control.
 * @param queryResult
 * @returns
 */
async function m365AcquisitionsToM365Apps(
    acquisitions: StrictAcquisition[],
    mailboxInfo?: MailboxInfo
): Promise<StrictAppBarControlDataQuery> {
    const appStoreLaunchPage = !isConsumer(undefined /* smtpAddress */, mailboxInfo)
        ? await getAppAcquisitionLink(true, mailboxInfo)
        : undefined;
    const { pinnedApps, lockedApps } = getAppBarTilesIndices(mailboxInfo);

    const isLaunchPagesEnabled = isMOSLaunchPagesEnabled(mailboxInfo);

    const m365Apps: StrictM365Application[] = await Promise.all(
        acquisitions
            .filter(acquisition =>
                onlyLaunchPagesOrUserPinnedTiles(acquisition, isLaunchPagesEnabled)
            )
            .map(acquisition =>
                convertAcquisitionToM365Application(
                    acquisition,
                    NovaControl.AppBar,
                    {
                        pinnedApps,
                        lockedApps,
                    },
                    mailboxInfo
                )
            )
    );
    const edges: StrictM365ApplicationEdge[] = m365Apps.map(node => ({
        __typename: 'M365ApplicationEdge',
        node,
    }));

    return {
        __typename: 'Query',
        m365Apps: {
            __typename: 'M365ApplicationConnection',
            edges,
            appAcquisitionLinks: !!appStoreLaunchPage ? [appStoreLaunchPage] : [],
        },
    };
}

/**
 * Note: Function below is used as a transitionary step, gated by a feature flag, to the new app catalog
 * schema.
 *
 * Converts a list of acquisitions cache references to its underlying
 * object value, converts to AppDefinition, then to M365Application type and writes to the `m365Apps`
 * query in Apollo cache.
 * @param acquisitions incoming list of cached acquisitions to write
 * @param mailboxInfo current flyout account, or undefined if mos-multiAccount is off
 */
export async function writeM365AppsQuery(
    acquisitions: M365Acquisition[],
    mailboxInfo?: MailboxInfo
) {
    if (!acquisitions || (acquisitions?.length ?? 0) === 0) {
        return;
    }
    try {
        const m365Apps = await m365AcquisitionsToM365Apps(
            acquisitions as StrictAcquisition[],
            mailboxInfo
        );
        writeQuery(AppBarControlDataDocument, { data: m365Apps });
    } catch (error) {
        errorThatWillCauseAlert('AppCatalogCache_WriteAppBarQuery_Error', error);
    }
}
