import { getConfig } from 'owa-service/lib/config';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type { TokenRequestParams } from '../schema/TokenRequestParams';
import type { ILogger } from './ILogger';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';

export async function getTokenFromMsal(
    tokenRequestParams: TokenRequestParams,
    logger: ILogger
): Promise<TokenResponse | undefined> {
    if (!tokenRequestParams.mailboxInfo) {
        logger.addCustomData(
            'MailboxInfo:',
            tokenRequestParams.apiName + ' fallback to module context'
        );
    }

    const config = getConfig();
    const mailboxInfo = tokenRequestParams.mailboxInfo ?? getModuleContextMailboxInfo();
    var tokenResponse = await config.getMsalToken?.(
        mailboxInfo,
        tokenRequestParams.resource,
        tokenRequestParams.scope,
        tokenRequestParams.requestId,
        tokenRequestParams.wwwAuthenticateHeader
    );

    logger.addCheckpoint('getTokenFromMSAL_FetchedFromMSAL');

    return tokenResponse;
}
