import type { TableView } from 'owa-mail-list-store';
import { mutatorAction } from 'satcheljs';
import { isSingleLineListView } from 'owa-mail-layout';

export default mutatorAction(
    'resetSelectAllState',
    function resetSelectAllState(tableView: TableView) {
        tableView.isInSelectAllState = isSingleLineListView();
    }
);
