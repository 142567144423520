/* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
 * Patch RAF for better performance during PLT
 *	> 'requestAnimationFrame' is restricted from being used. Use safeRequestAnimationFrame in owa-performance instead. */
const originalRequestAnimationFrame = window.requestAnimationFrame;
export function patchRaf() {
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * Patch RAF for better performance during PLT
     *	> 'requestAnimationFrame' is restricted from being used. Use safeRequestAnimationFrame in owa-performance instead. */
    window.requestAnimationFrame = function (callback: FrameRequestCallback) {
        Promise.resolve().then(() => callback(performance?.now?.()));
        return -1;
    };
    return function () {
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * Patch RAF for better performance during PLT
         *	> 'requestAnimationFrame' is restricted from being used. Use safeRequestAnimationFrame in owa-performance instead. */
        window.requestAnimationFrame = originalRequestAnimationFrame;
    };
}
