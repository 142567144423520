import { composeStore } from 'owa-mail-compose-store';
import { lazyDiscardCompose, lazyLoadDraftToCompose } from 'owa-mail-compose-actions';
import { lazyNewMessage } from 'owa-mail-message-actions';
import selectFolderWithFallback from './selectFolderWithFallback';
import getSelectedFolderMailboxInfoOrDefault from 'owa-mail-store/lib/utils/getSelectedFolderMailboxInfoOrDefault';
import { isAccountFeatureEnabled } from 'owa-feature-flags';
import { getQueryStringParametersFromURL } from 'owa-content-handler/lib/utils/queryStringHelper';
import {
    lazyCallCopilotBizChatHandoffService,
    type BizChatHandoffResponse,
} from 'owa-copilot-service/lib/lazyFunctions';
import { type CopilotResponse } from 'owa-copilot-service';
import { createDraftCopilotPluginViewState } from 'owa-copilot-drafts-utils/lib/utils/createDraftCopilotPluginViewState';
import type { DraftCopilotPluginViewState } from 'owa-copilot-drafts-utils';
import processRecipientsFromUrlParameter from 'owa-recipient-email-address/lib/utils/processRecipientsFromUrlParameter';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';

export interface MailFolderRouteParameters {
    folderId?: string;
    draftId?: string;
}

export async function mailComposeNavigationRouteHandler(parameters: MailFolderRouteParameters) {
    selectFolderWithFallback(parameters.folderId);

    if (parameters.draftId) {
        await lazyLoadDraftToCompose.importAndExecute(
            { Id: parameters.draftId, mailboxInfo: getSelectedFolderMailboxInfoOrDefault() },
            null /* sxsId */,
            'Route' /* actionSource */
        );
    } else {
        await lazyNewMessage.importAndExecute('Route');
    }
}

export function mailComposeCleanupRouteHandler(): Promise<boolean> | boolean {
    if (composeStore.primaryComposeId) {
        const viewState = composeStore.viewStates.get(composeStore.primaryComposeId);
        if (viewState) {
            return lazyDiscardCompose.importAndExecute(viewState).then(
                () => false, // Confirm ('Discard draft') means we should not block navigation
                () => true
            ); // Cancel ('Dont' discard') means we should block navigation
        }
    }
    return false;
}

const ApplinkActionSource = 'ApplinkRoute';

export async function mailComposeBizChatRouteHandler() {
    const mailboxInfo = getSelectedFolderMailboxInfoOrDefault();
    if (isAccountFeatureEnabled('cmp-copilot-bizchat', mailboxInfo)) {
        const { h, h_env } = getQueryStringParametersFromURL();

        /* Call API with handoffId and handoffEnv to retrieve the handoff data */
        const response = await lazyCallCopilotBizChatHandoffService.importAndExecute(
            h /*handoffId*/,
            h_env /*handoffEnv*/,
            mailboxInfo
        );
        if (isBizChatResponse(response)) {
            // Apply to Copilot view state
            const draftInitOptions: Partial<DraftCopilotPluginViewState> &
                Pick<DraftCopilotPluginViewState, 'openInfo'> = {
                openInfo: {
                    entryPoint: 'CopilotBizChatHandoff',
                    openMode: 'Unknown',
                },
            };

            const toRecipients: EmailAddressWrapper[] = [];
            const ccRecipients: EmailAddressWrapper[] = [];
            const bccRecipients: EmailAddressWrapper[] = [];

            response.toRecipients.map(recipient =>
                processRecipientsFromUrlParameter(recipient, toRecipients)
            );
            response.ccRecipients.map(recipient =>
                processRecipientsFromUrlParameter(recipient, ccRecipients)
            );
            response.bccRecipients.map(recipient =>
                processRecipientsFromUrlParameter(recipient, bccRecipients)
            );
            // Apply to mail compose init props
            // Open compose with handoff data
            await lazyNewMessage.importAndExecute('ApplinkRoute', {
                subject: response.subject,
                toEmailAddressWrappers: toRecipients,
                ccEmailAddressWrappers: ccRecipients,
                bccEmailAddressWrappers: bccRecipients,
                existingLabelString:
                    response.sensitivityLabel !== null ? response.sensitivityLabel : undefined,
                body: response.emailBody,
                draftCopilotOpenInfo: createDraftCopilotPluginViewState(draftInitOptions),
                bodyType: 'HTML',
                shouldResolveRecipients: true,
            });
        } else {
            await lazyNewMessage.importAndExecute(ApplinkActionSource, {
                initializeInfoBarIds: ['BizChatHandoff'],
                bodyType: 'HTML',
            });
        }
    }
}

function isBizChatResponse(
    response: BizChatHandoffResponse | CopilotResponse
): response is BizChatHandoffResponse {
    return 'emailBody' in response;
}
