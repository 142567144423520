import type {
    InputExtensionCommandContext,
    InputExtensionCommandType,
    M365Acquisition,
} from 'owa-graph-schema';

const defaultCommandContext: Array<InputExtensionCommandContext> = ['Compose', 'CommandBox'];
const defaultCommandType: InputExtensionCommandType = 'query';

/**
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has at least one message extension element
 */
export function hasComposeMessageExtension(acquisition: M365Acquisition): boolean {
    return !!acquisition.titleDefinition?.elementDefinitions?.composeExtensions?.some(extension =>
        extension.commands?.some(
            command =>
                command &&
                (command.context ?? defaultCommandContext).some(
                    ctx => ctx?.toLowerCase() === 'compose'
                )
        )
    );
}

export function hasMessageExtension(acquisition: M365Acquisition): boolean {
    const hasLinkUnfurlingApp = hasLinkUnfurling(acquisition);
    const hasMessageExtensionApp = hasLinkUnfurlingApp || hasComposeMessageExtension(acquisition);

    const onlyActionCommands =
        hasActionCommand(acquisition) &&
        !hasMessageExtensionSearch(acquisition) &&
        !hasLinkUnfurlingApp;
    return !onlyActionCommands && hasMessageExtensionApp;
}

/**
 * Verifies whether a M365 acquisition has a message search launch experience in compose
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has a message search launch experience in compose
 */
export function hasMessageExtensionSearch(acquisition: M365Acquisition): boolean {
    return !!acquisition.titleDefinition.elementDefinitions?.composeExtensions?.some(extension =>
        extension.commands?.some(
            command =>
                command &&
                (command.context ?? defaultCommandContext).some(
                    ctx => ctx?.toLowerCase() === 'compose'
                ) &&
                (command.type ?? defaultCommandType).toLowerCase() === 'query'
        )
    );
}

/**
 * Verifies whether a M365 acquisition has link unfurling launch experience
 * @param acquisition
 * @returns `true` if `M365Acquisition` has link unfurling launch experience
 */
export function hasLinkUnfurling(acquisition: M365Acquisition): boolean {
    return !!acquisition.titleDefinition.elementDefinitions?.composeExtensions?.some(extension =>
        extension.messageHandlers?.some(
            messageHandler => messageHandler?.type?.toLowerCase() === 'link'
        )
    );
}

/**
 * Verifies whether a M365 acquisition has an action command launch experience
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has an action command launch experience
 */
export function hasActionCommand(acquisition: M365Acquisition): boolean {
    return !!acquisition.titleDefinition.elementDefinitions?.composeExtensions?.some(extension =>
        extension.commands?.some(
            command =>
                command &&
                (command.context ?? defaultCommandContext).some(
                    ctx => ctx?.toLowerCase() === 'compose'
                ) &&
                (command.type ?? defaultCommandType).toLowerCase() === 'action'
        )
    );
}
