import type { TableView } from 'owa-mail-list-store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { mutatorAction } from 'satcheljs';
import endSelectMailItemDatapoint from 'owa-mail-logging/lib/utils/endSelectMailItemDatapoint';
import shouldOpenPopout from '../../utils/shouldOpenPopout';
import shouldOpenReadingPaneInSingleLineView from '../../utils/shouldOpenReadingPaneInSingleLineView';
import resetExpansionViewStateInternal from 'owa-mail-list-store/lib/utils/resetExpansionViewStateInternal';
import { getStore as getMessageAdListStore } from 'owa-mail-messagead-list-store';

/**
 * Single select mail row
 * @param tableView where the operation is being performed
 * @param rowKey the rowKey of the item to select
 * @param mailListItemSelectionSource The source of selection on mail item
 */
export default mutatorAction(
    'singleSelectRowInternalMutatorAction',
    function singleSelectRowInternalMutatorAction(
        tableView: TableView,
        rowKey: string,
        mailListItemSelectionSource: MailListItemSelectionSource
    ) {
        // popout scenario not supported in this mutatorAction
        if (shouldOpenPopout(mailListItemSelectionSource)) {
            return;
        }

        const isSingleRowSelected =
            tableView.selectedRowKeys.has(rowKey) && tableView.selectedRowKeys?.size === 1;

        // Simply log and retrun if user is selecting the same item and that item is focused unless
        // user has reading pane setting as hidden (single line view) AND the reading pane should be opened.
        if (
            isSingleRowSelected &&
            rowKey === tableView.focusedRowKey &&
            !shouldOpenReadingPaneInSingleLineView(mailListItemSelectionSource)
        ) {
            // End the SelectMailItemNonCritical datapoint here and invalidate since reading pane
            // is already loaded.
            endSelectMailItemDatapoint('' /* contentType */, true /* shouldInvalidate */);
            return;
        }

        // If this rowKey is the only selected row and its twisty is being toggled, there is no need to reset and set anything new.
        if (
            !(
                mailListItemSelectionSource == MailListItemSelectionSource.MailListItemTwisty &&
                isSingleRowSelected
            )
        ) {
            // Reset list view selection
            tableView.selectedRowKeys.clear();
            tableView.isInCheckedMode = false;
            tableView.isOnlyOptimisticallyInCheckedMode = false;
            resetExpansionViewStateInternal();
            // Reset the message Ad list selection which potentially occupy the right pane
            const messageAdStore = getMessageAdListStore();
            if (messageAdStore) {
                messageAdStore.selectedAdId = '';
            }

            tableView.isInVirtualSelectAllMode = false;
            tableView.virtualSelectAllExclusionList = [];
            tableView.selectAllModeTimeStamp = null;
            tableView.focusedRowKey = rowKey;

            // Set the selection anchor for possible future range, keyboard, or multi selections
            tableView.selectionAnchorRowKey = rowKey;
            tableView.multiSelectionAnchorRowKey = null;

            tableView.selectedRowKeys.set(rowKey, true);
        }
    }
);
