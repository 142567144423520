//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import type * as Types from 'owa-graph-schema';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FolderHierarchyConfigurationQueryQueryVariables = Types.Exact<{
    mailboxInfo: Types.MailboxInfoInput;
}>;
export type FolderHierarchyConfigurationQueryQuery = {
    __typename?: 'Query';
    userConfiguration?: {
        __typename?: 'UserConfiguration';
        id: string;
        UserOptions?: {
            __typename?: 'UserOptions';
            IsMailRootFolderTreeCollapsed?: boolean | null;
        } | null;
        PolicySettings?: {
            __typename?: 'PolicySettings';
            ShowOnlineArchiveEnabled?: boolean | null;
        } | null;
        SessionSettings?: {
            __typename?: 'SessionSettings';
            ArchiveDisplayName?: string | null;
            ArchiveMailboxGuid?: string | null;
            HasArchive?: boolean | null;
            IsExplicitLogon?: boolean | null;
            IsShadowMailbox?: boolean | null;
            LogonEmailAddress?: string | null;
            OrganizationDomain?: string | null;
            UserDisplayName?: string | null;
            UserEmailAddress?: string | null;
        } | null;
    } | null;
};
export const FolderHierarchyConfigurationQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'folderHierarchyConfigurationQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailboxInfo' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MailboxInfoInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userConfiguration' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mailboxInfo' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'mailboxInfo' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'UserOptions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'IsMailRootFolderTreeCollapsed',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'PolicySettings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ShowOnlineArchiveEnabled',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'SessionSettings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'ArchiveDisplayName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'ArchiveMailboxGuid' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'HasArchive' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'IsExplicitLogon' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'IsShadowMailbox' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'LogonEmailAddress' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'OrganizationDomain' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'UserDisplayName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'UserEmailAddress' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FolderHierarchyConfigurationQueryQuery,
    FolderHierarchyConfigurationQueryQueryVariables
>;
