enum FirstPartyAppId {
    Mail = 'ddea774c-382b-47d7-aab5-adc2139a802b',
    Calendar = '8cbeb86f-83e1-43b5-aaba-cd3514322f0b',
    People = '355fbd79-3ba2-4554-8f2d-0300fde91f30',
    Groups = '3961f27f-be9a-447a-b2bd-dc7a6132f763',
    Files = 'd7a12bea-e4fe-49a7-8cd1-c2e6d53722a6',
    ToDo = '59391057-d7d7-49fd-a041-d8e4080f05ec',
    Tasks = 'e9f9b6d1-7948-4b5b-84f2-8a97e3fdb427',
    Word = '34318026-c018-414b-abb3-3e32dfb9cc4c',
    Excel = 'c5251a9b-a95d-4595-91ee-a39e6eed3db2',
    PowerPoint = '48cb9ead-1c19-4e1f-8ed9-3d60a7e52b18',
    OneNote = '6c548c14-5489-42d8-938e-b45a861fefe6',
    OneDrive = '39109bd4-9389-4731-b8d6-7cc1a128d0b3',
}

enum LaunchPageAppId {
    Yammer = 'db5e5970-212f-477f-a3fc-2227dc7782bf',
    VivaEngage = 'db5e5970-212f-477f-a3fc-2227dc7782bf',
    Bookings = '4c4ec2e8-4a2c-4bce-8d8f-00fc664a4e5b',
    ToDoMetaOs = '0d5c91ee-5be2-4b79-81ed-23e6c4580427',
    AppStore = 'f3a6e67f-850d-4dd9-960a-04c6638ded36',
    AppStoreModal = 'b6b18421-3bba-40df-a983-05fbbc9e2d13',
    AppStoreAbout = 'a9750531-44bc-4346-84a7-c4ceda140ef4',
    OrgExplorer = '1f8c20f5-d70f-4f8e-93e1-31d8fce0c8c9',
    OneDriveMetaOs = '377c982d-9686-450e-9a7c-22aeaf1bc162',
    MicrosoftPlaces = 'bae38e8e-7f76-4a31-9bb6-22b75f6dd1bc',
    MicrosoftPlacesSdf = 'd2f8a961-38f9-4452-bc25-e8d42838f570',
    Hoop = '4a6520d6-94a8-4730-9184-24997b9b3401',
    Newsletters = '218795fc-272e-49c0-98b1-a5d648c2d3c7',
    ImmersiveBizChat = 'd870f6cd-4aa5-4d42-9626-ab690c041429',
    OneDriveConsumerMetaOs = '242df6f5-6e64-4e8b-9d45-58502eef7c2d',
    Actions = '05ab9cf7-9baf-4aee-afc4-9ce898f49410',
}

enum ExternalAppId {
    VsbAdmin = 'b47c7387-2807-440c-9fc2-7c8f46147a20',
}

enum AddInsAppId {
    ClientStore = '3b67c06a-10af-48ba-831d-933f0d730b0b',
}

type M365AppId = FirstPartyAppId | LaunchPageAppId | ExternalAppId;
type MetaOsAppId = LaunchPageAppId;

const M365AppId = {
    ...FirstPartyAppId,
    ...LaunchPageAppId,
    ...ExternalAppId,
};

const Office365Apps = [
    M365AppId.Word,
    M365AppId.Excel,
    M365AppId.PowerPoint,
    M365AppId.OneNote,
    M365AppId.OneDrive,
];
const isAppOffice365 = (appId: string) => Office365Apps.some(staticApp => staticApp == appId);

export {
    AddInsAppId,
    FirstPartyAppId,
    LaunchPageAppId,
    M365AppId,
    type MetaOsAppId,
    isAppOffice365,
};
