import type { OwaDate, GlobalCalendarLocale } from 'owa-datetime';
import {
    lazyFormatIntlDate,
    lazyGetIntlMonth,
    lazyGetIntlYear,
} from 'owa-datetime-intl-formatters';

export function formatIntlDate(
    date: OwaDate | null | undefined,
    locale: GlobalCalendarLocale,
    format: string | null | undefined
): string {
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    const formatIntlDateInternal = lazyFormatIntlDate.tryImportForRender();

    if (!formatIntlDateInternal) {
        return '';
    }

    return formatIntlDateInternal(date, locale, format);
}

export function getIntlMonth(
    date: OwaDate,
    locale: GlobalCalendarLocale,
    format: 'numeric' | '2-digit' | 'short' | 'long'
): string {
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    const getIntlMonthInternal = lazyGetIntlMonth.tryImportForRender();

    if (!getIntlMonthInternal) {
        return '';
    }

    return getIntlMonthInternal(date, locale, format);
}

export function getIntlYear(
    date: OwaDate,
    locale: GlobalCalendarLocale,
    format: 'numeric' | '2-digit'
): string {
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    const getIntlYearInternal = lazyGetIntlYear.tryImportForRender();

    if (!getIntlYearInternal) {
        return '';
    }

    return getIntlYearInternal(date, locale, format);
}
