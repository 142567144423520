import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ImmersiveReaderStore" */ './lazyIndex'),
    { name: 'ImmersiveReaderStore' }
);

export const lazyShowInImmersiveReader = new LazyAction(lazyModule, m => m.showInImmersiveReader);

// Non-delayed loaded exports
export { getStore } from './store/Store';
