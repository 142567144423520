//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import type * as Types from 'owa-graph-schema';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MoveFolderPositionMutationVariables = Types.Exact<{
    folderId: Types.Scalars['String'];
    destinationFolderId: Types.Scalars['String'];
    mailboxInfo: Types.MailboxInfoInput;
}>;
export type MoveFolderPositionMutation = {
    __typename?: 'Mutation';
    moveFolderPosition?: {
        __typename?: 'MoveFolderPositionResult';
        success: boolean;
        errorMessage?: string | null;
        errorCode?: Types.Number | null;
    } | null;
};
export const MoveFolderPositionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'MoveFolderPosition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'folderId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'destinationFolderId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailboxInfo' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MailboxInfoInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moveFolderPosition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'folderId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'folderId' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'destinationFolderId' },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'destinationFolderId',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'mailboxInfo' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'mailboxInfo' },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'errorCode' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MoveFolderPositionMutation, MoveFolderPositionMutationVariables>;
