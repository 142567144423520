import { createLazyComponent, LazyModule } from 'owa-bundling';

import type * as imports from './lazyIndex';

const lazyModule = new LazyModule<typeof imports>(
    () => import(/* webpackChunkName: "GroupHeader" */ './lazyIndex'),
    { name: 'GroupHeader' }
);

export const GroupHeader = createLazyComponent(lazyModule, m => m.GroupHeader);
