import type { MailboxInfo } from 'owa-client-types';
import { getApplicationSettings } from 'owa-application-settings';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isOfflinePeopleDataViaPDVEnabled } from './isOfflinePeopleDataViaPDVEnabled';
import { isPDVHeaderInfoAndCardTypeEnabled } from './isPDVHeaderInfoAndCardTypeEnabled';

export function isOnePersonContextualEnhancementEnabled(viewerMailboxInfo: MailboxInfo) {
    // We are intentionally not passing in MailboxInfo to below
    // flight checks because framework sync currently is
    // completely conditioned on the global settings mailbox account's
    // flights since they have some trouble at this time
    // to completely flight offline by account.
    return (
        getApplicationSettings('OnePersonView').enhancedContextualization &&
        ((isOfflinePeopleDataViaPDVEnabled(viewerMailboxInfo) && // For PDV and offline enabled users
            isPDVHeaderInfoAndCardTypeEnabled(viewerMailboxInfo)) ||
            // For online-only users
            /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
             * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
             *	> The parameter mailboxInfo must be undefined. Feature flight: 'peo-one-person-view' */
            isFeatureEnabled('peo-one-person-view', viewerMailboxInfo))
    );
}
