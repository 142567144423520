import { mutatorAction } from 'satcheljs';
import type ClientItem from 'owa-mail-store/lib/store/schema/ClientItem';
import { dispatchReadingPaneCurrentItemToNotesSDK } from '../actions/dispatchReadingPaneCurrentItemToNotesSDK';
import getStore from '../store/store';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const dispatchReadingPaneCurrentItem = mutatorAction(
    'DISPATCH_READINGPANE_CURRENT_ITEM',
    (item: ClientItem) => {
        if (getStore().isPanelOpen) {
            dispatchReadingPaneCurrentItemToNotesSDK(item);
        }
    }
);
