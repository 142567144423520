import type PersonaControlStore from './schema/PersonaControlStore';
import {
    LivePersonaCardInitializationState,
    LivePersonaEditorInitializationState,
} from './schema/PersonaControlStore';
import type PersonaControlViewState from './schema/PersonaControlViewState';
import { createStore } from 'owa-satcheljs';
var initialPersonaControlStore: PersonaControlStore = {
    viewStates: new Map<string, PersonaControlViewState>(),
    livePersonaCardInitializationStatus: {
        state: LivePersonaCardInitializationState.NotInitialized,
        timestamp: Date.now(),
    },
    livePersonaEditorInitializationStatus: {
        state: LivePersonaEditorInitializationState.NotInitialized,
        timestamp: Date.now(),
    },
    isLivePersonaCardInitialized: false,
    isLivePersonaEditorInitialized: false,
    isPersonaCardDisabled: false,
    isPersonaCardOpen: false,
    dynamicBrandSets: {
        unverifiedBrands: new Map(),
        verifiedBrands: new Map(),
    },
};

const store = createStore<PersonaControlStore>('personacontrol', initialPersonaControlStore)();
export default store;
