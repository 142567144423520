import type { TabHeaderStyles, RibbonTopBarStyles } from 'owa-acui';
/**
 * This is the base style for buttons inside ribbon. It resets the background color of inner elements and relies on the overall ribbon BG color.
 */
const root = {
    backgroundColor: 'transparent',
};

const tabStyles: TabHeaderStyles = {
    wrapper: root,
    pivotHeader: {
        root,
    },
    selectedPivotHeader: {
        root,
    },
};

export function getTabStyles() {
    return tabStyles;
}

const topBarCustomStyles: RibbonTopBarStyles = {
    root,
    tabliststyles: {
        pivotStyles: {
            root,
            overflowButton: {
                root,
            },
        },
    },
};

export function getTopBarCustomStyles() {
    return topBarCustomStyles;
}
