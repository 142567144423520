import type Message from 'owa-service/lib/contract/Message';
import type { MailboxInfo } from 'owa-client-types';
import isIRMDownloadAllowed from './isIRMDownloadAllowed';
import checkIfAttachmentsCanBeDownloaded from './checkIfAttachmentsCanBeDownloaded';

export default function isEmailDownloadAllowed(
    message: Message | undefined,
    mailboxInfo: MailboxInfo
): boolean {
    if (!message?.ItemId?.Id) {
        return false;
    }
    return [
        isIRMDownloadAllowed(message, mailboxInfo),
        (message?.HasAttachments && checkIfAttachmentsCanBeDownloaded(message, mailboxInfo)) ||
            !message?.HasAttachments,
    ].every(condition => condition);
}
