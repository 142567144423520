import type { ControlInfo, ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { type MailRibbonControlId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import {
    createMailAppFlyoutAnchor,
    createMailAppSplitButton,
} from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import { getControlInfo } from 'owa-mail-ribbon-utils/lib/getControlInfo';
import { getSharedControlInfo } from 'owa-mail-ribbon-utils/lib/getSharedControlInfo';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import {
    getDefaultSlrAppRibbonButtonStyles,
    getDefaultSlrSplitButtonAppRibbonButtonStyles,
    getSlrHeroAppSplitButtonStyles,
} from 'owa-command-ribbon-styles';
import { onDeleteClicked } from 'owa-mail-ribbon-command-actions/lib/popoutCommandActions';
import {
    onNewEmailSplitButtonExecuted,
    onNewMessage,
    onNewNoteClicked,
    onNewPost,
} from 'owa-mail-ribbon-command-actions/lib/commandActions';
import { resetMoveToSearch } from 'owa-mail-ribbon-command-actions/lib/resetMoveToSearch';
import { onDeleteMenuClicked } from '../../utils/deferredOnDeleteMenuClicked';
import { onMoveToMenuClicked } from '../../utils/deferredOnMoveToMenuClicked';
import { onNewGroupMenuClicked } from '../../utils/deferredOnNewGroupMenuClicked';
import { resetMenuDefinitionMutator } from '../../../mutatorActions/menuDefinitionMutatorActions';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type { MenuOpenOnExecuteParameter, RibbonControlProps } from 'owa-acui';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip } from 'owa-command-ribbon';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getMailMenuItemShouldShow } from 'owa-mail-filterable-menu-behavior';
import { getMenuDefinition } from '../../../store/menuDefinitionStore';
import { getPalette } from 'owa-theme';
import { isPublicFolderSelected } from 'owa-publicfolder-utils';
import { retrieveWindowForRibbon } from 'owa-mail-compose-controls/lib/utils/retrieveEditingInfoForRibbon';

/**
 * This file contains the definitions for all the controls
 * in the Single Line Ribbon (SLR)
 */
export const getNewMessageControl = owaComputedFn(function getNewMessageControl(
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const id = 588;
    const mailboxInfo = getRibbonMailboxInfo(props.mailboxInfo);
    const isNote: boolean = getMailMenuItemShouldShow(57, mailboxInfo);
    const isPublicFolder: boolean = isPublicFolderSelected();
    const { ariaText, iconName, text, tooltip } = getControlInfo(id, props);
    const storeName = 'NewGroupMenuDefinitionStore';

    return createMailAppSplitButton(
        id,
        text,
        ariaText,
        isNote ? undefined : tooltip,
        props,
        getMenuDefinition(storeName),
        isNote
            ? onNewNoteClicked
            : param => {
                  isPublicFolder
                      ? onNewPost(param?.event?.timeStamp)
                      : onNewMessage(param?.event?.timeStamp);
              },
        constructKeytip([keytipTabId], id, true /* hasMenu */),
        iconName,
        getPalette().white,
        getSlrHeroAppSplitButtonStyles(),
        undefined /*itemId*/,
        {
            onMenuButtonExecute: (parameter: MenuOpenOnExecuteParameter) => {
                onNewEmailSplitButtonExecuted(parameter);
                onNewGroupMenuClicked(keytipTabId, props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
            primaryDisabled:
                !getMailMenuItemShouldShow(56, mailboxInfo) &&
                !getMailMenuItemShouldShow(57, mailboxInfo),
        },
        true /* isHero */
    );
});

export const getDeleteControl = owaComputedFn(function getDeleteControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(519, props);
    const { projectionTabId } = props;
    const storeName = 'DeleteMenuDefinitionStore';
    return createMailAppSplitButton(
        519,
        controlInfo.text,
        controlInfo.ariaText,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        getComputedCallback(
            519,
            onDeleteClicked,
            projectionTabId,
            retrieveWindowForRibbon,
            undefined /* itemId */
        ),
        constructKeytip([1], 519, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        getDefaultSlrSplitButtonAppRibbonButtonStyles(),
        undefined /*itemId*/,
        {
            onMenuButtonExecute: () => {
                onDeleteMenuClicked(props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});

export const getMoveControl = owaComputedFn(function getMoveControl(
    keytipTabId: MailRibbonTabId | undefined,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo: ControlInfo = getSharedControlInfo(540, props);
    const storeName = 'MoveToMenuDefinitionStore';

    return createMailAppFlyoutAnchor(
        540,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip(keytipTabId ? [keytipTabId] : [], 540, true /* hasMenu */),
        controlInfo.iconName,
        undefined /**iconColor */,
        getDefaultSlrAppRibbonButtonStyles(),
        undefined /*itemId*/,
        {
            delayUpdateFocusOnHover: true,
            onExecute: () => {
                resetMoveToSearch(true /* onEnter */);
                onMoveToMenuClicked(keytipTabId, props);
            },
            onAfterMenuDismiss: () => {
                resetMoveToSearch(false /* onEnter */);
                resetMenuDefinitionMutator(storeName);
            },
            shouldFocusOnMount: false, // To not override searchbox focus
            shouldFocusOnContainer: false, // To not override searchbox focus
            enableDownArrowNavigation: true, // to allow the searchbox lose its focus on the menu and enable the down arrow key navigation
        }
    );
});
