import type { ThemeResources } from 'owa-theme-shared';
import { LEGACY_PRELOADED_THEME_IDS } from './constants';
import { isTeamsThemeId } from 'owa-teams-theme-utils';
import { LazyAction, LazyBootModule } from 'owa-bundling-light';

const lazyThemesModule = new LazyBootModule(
    () => import(/* webpackChunkName: "Themes"*/ './lazyIndex'),
    { name: 'Themes' }
);

const lazyGetLazyThemes = new LazyAction(lazyThemesModule, m => m.getLazyThemes);

export async function fetchThemeFile(themeDataName: string): Promise<ThemeResources | null> {
    if (
        themeIsPreloaded(themeDataName) ||
        themeIsCopilot(themeDataName) ||
        isTeamsThemeId(themeDataName)
    ) {
        return null;
    }

    const themes = await lazyGetLazyThemes.importAndExecute();
    const themeKey = themeDataName.toLowerCase() as keyof typeof themes;

    return themeKey in themes ? themes[themeKey] : null;
}

function themeIsPreloaded(themeDataName: string) {
    return LEGACY_PRELOADED_THEME_IDS.includes(themeDataName);
}

function themeIsCopilot(themeDataName: string) {
    return themeDataName.startsWith('copilot');
}
