import { isPstFileAccountType } from 'owa-account-source-list-internal';
import { isPstFileMailboxInfo } from 'owa-client-types/lib/PstFileMailboxInfo';
import type { AccountSource } from 'owa-account-source-list-store';
import type { AccountSourceType } from 'owa-account-source-list-types';
import type { MailboxInfo } from 'owa-client-types';

export function isLocalDataBackedAccount(account: AccountSource): boolean {
    return isLocalDataBackedAccountType(account.sourceType);
}

export function isLocalDataBackedAccountType(accountType: AccountSourceType): boolean {
    return isPstFileAccountType(accountType);
}

export function isLocalDataBackedMailboxInfo(mailboxInfo: MailboxInfo): boolean {
    return isPstFileMailboxInfo(mailboxInfo);
}
