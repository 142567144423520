import type { FolderForestTreeType } from 'owa-graph-schema';
import { getListViewTypeString } from 'owa-mail-list-store';
import { getListViewTypeForGroup } from 'owa-group-mail-list-actions';

export default {
    selectPrivateDistributionList: {
        name: 'SelectPrivateDistributionList',
    },
    selectFavoriteCategory: {
        name: 'TnS_SelectFavoriteCategory',
    },
    // This DP measures the loading of Group script
    selectGroup: {
        name: 'SelectGroup',
        options: {},
    },
    switchGroup: {
        customData: (_groupId: string, treeType: FolderForestTreeType) => ({
            TreeType: treeType,
            ListViewType: getListViewTypeString(getListViewTypeForGroup()),
        }),
    },
    navigateFromMeToWe: {
        customData: (_groupId: string, treeType: FolderForestTreeType) => ({
            TreeType: treeType,
            ListViewType: getListViewTypeString(getListViewTypeForGroup()),
        }),
    },
};
