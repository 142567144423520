import type { RibbonControlProps } from 'owa-acui';
import { owaComputedFn } from 'owa-computed-fn';
import { getDefaultRibbonStyles } from 'owa-command-ribbon-styles/lib/util/getDefaultRibbonStyles';
import { getAddIns } from 'owa-mail-compose-controls/lib/components/addins';
import { getM365Apps } from 'owa-mail-compose-controls/lib/components/m365apps';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getIsReadButtonDisabled } from 'owa-mail-ribbon-utils/lib/getIsReadButtonDisabled';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { type MailRibbonControlId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { errorThatWillCauseAlert } from 'owa-trace';
import {
    getDiscoverGroupsControl,
    getPinUnpinControl,
    getRestoreControl,
    getRestoreAllControl,
    getSnoozeControl,
    getUndoControl,
    getViewGroupsPanelControl,
} from '../../sharedControls/readSharedControlDefinitions';
import { getIgnoreControl, getNewMessageControl } from './mlrHomeTabControlDefinitions';
import { getQuickStepsControl } from '../../sharedControls/getQuickStepsControl';
import {
    getArchiveControl,
    getAssignPolicyControl,
    getBlockControl,
    getCategorizeControl,
    getDeleteControl,
    getFlagUnflagControl,
    getPrintControl,
    getUnreadReadControl,
    getReplyWithMeetingControl,
    getCopilotReplyWithMeetingFlyoutControl,
    getCopilotReplyWithMeetingControl,
    getReportControl,
    getForwardControl,
    getReplyControl,
    getReplyAllControl,
    getRespondFlyoutControl,
    getSweepControl,
    getMoveControl,
    getRulesControl,
    getMessageRecallControl,
    getTrackReadReceiptsControl,
    getChatAroundEmailControl,
    getMessageResendControl,
    getShareToTeamsChatControl,
    getReadMessageExtensionsControl,
    getThirdPartyImChatControl,
} from '../../sharedControls/readProjectionSharedControlDefinitions';

/**
 * This function supplies a mapping from RibbonControlId to their respective control definition function.
 * For any new control in a tab, be sure to update the mapping below.
 * Note: This function should *not* have any logic regarding showing/hiding.
 *       That should be in shouldShowRibbonControl.ts instead.
 */
export const getMLRHomeTabControlDefinition = owaComputedFn(function getMLRHomeTabControlDefinition(
    controlId: RibbonControlId,
    tabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps | undefined {
    const isControlDisabled: boolean = getIsReadButtonDisabled(
        controlId,
        props.isPopout,
        getRibbonMailboxInfo(props.mailboxInfo),
        undefined /*itemId*/
    );
    switch (controlId) {
        case 7022:
            return getM365Apps(
                undefined /* editorId */,
                getDefaultRibbonStyles(),
                tabId,
                isControlDisabled,
                props
            );
        case 529:
            return getAddIns(
                undefined /* editorId */,
                getDefaultRibbonStyles(),
                tabId,
                isControlDisabled
            );
        case 7016:
            return getReadMessageExtensionsControl(false /*isSLR*/, 1, props);
        case 505:
            return getArchiveControl(false /*isSLR*/, 1, props);
        case 616:
            return getAssignPolicyControl(false /*isSLR*/, 1, props);
        case 658:
            return getBlockControl(false /*isSLR*/, 1, props);
        case 508:
            return getDiscoverGroupsControl(1, props);
        case 509:
            return getCategorizeControl(false /*isSLR*/, 1, props);
        case 519:
            return getDeleteControl(false /*isSLR*/, 1, props);
        case 527:
            return getFlagUnflagControl(false /*isSLR*/, 1, props);
        case 531:
            return getIgnoreControl(props);
        case 540:
            return getMoveControl(false /*isSLR*/, 1, props);
        case 587:
            return getNewMessageControl(1, props);
        case 548:
            return getPinUnpinControl(false /*isSLR*/, 1, props);
        case 549:
            return getPrintControl(false /*isSLR*/, 1, props);
        case 550:
            return getQuickStepsControl(false /*isSLR*/, props);
        case 552:
            return getUnreadReadControl(false /*isSLR*/, 1, props);
        case 553:
            return getReplyWithMeetingControl(false /*isSLR*/, 1, props);
        case 698:
            return getCopilotReplyWithMeetingFlyoutControl(false /*isSLR*/, 1, props);
        case 696:
            return getCopilotReplyWithMeetingControl(false /*isSLR*/, 1, props);
        case 657:
            return getReportControl(false /*isSLR*/, 1, props);
        case 683:
            return getRestoreControl(false /*isSLR*/, 1, props);
        case 684:
            return getRestoreAllControl(false /*isSLR*/, 1, props);
        case 555:
            return getForwardControl(false /*isSLR*/, 1, props);
        case 556:
            return getReplyControl(false /*isSLR*/, 1, props);
        case 557:
            return getReplyAllControl(false /*isSLR*/, 1, props);
        case 678:
            return getRespondFlyoutControl(false /*isSLR*/, 1, props);
        case 559:
            return getRulesControl(false /*isSLR*/, 1, props);
        case 571:
            return getSnoozeControl(false /*isSLR*/, 1, props);
        case 572:
            return getSweepControl(false /*isSLR*/, 1, props);
        case 580:
            return getUndoControl(false /*isSLR*/, 1, props);
        case 663:
            return getViewGroupsPanelControl(false /*isSLR*/, 1, props);
        case 686:
            return getMessageRecallControl(false /*isSLR*/, 1, props);
        case 697:
            return getTrackReadReceiptsControl(false /*isSLR*/, 1, props);
        case 731:
            return getChatAroundEmailControl(false /*isSLR*/, 1, props);
        case 712:
            return getMessageResendControl(false /*isSLR*/, 1, props);
        case 709:
            return getShareToTeamsChatControl(false /*isSLR*/, 1, props);
        case 719:
            return getThirdPartyImChatControl(false /*isSLR*/, 1, props);

        default:
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * The error name (message) must be a string literal (no variables in it).
             *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
            errorThatWillCauseAlert(
                'Unknown control: ' + controlId + ' searched for in getMLRHomeTabControlDefinition'
            );
            return undefined;
    }
});
