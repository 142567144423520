export enum ModalState {
    Intro,
    SetUp,
    Completed,
    Error,
}

export enum OptInResult {
    NotAttempted = -1,
    Failure = 0,
    Success = 1,
}

interface CopilotCommandCenterPrioritizeData {
    modalState: ModalState;
    isOptInRequestPending: boolean;
    optInResult: OptInResult;
}

export default CopilotCommandCenterPrioritizeData;
