import { LazyAction, LazyModule } from 'owa-bundling-light';

// The toggle may be accessed on boot, to minimize the boot bundle size we will
// lazy load the code for actually getting the toggle state
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GetToggleState" */ './getToggleState'),
    { name: 'GetToggleState' }
);

export const lazyGetToggleState = new LazyAction(lazyModule, m => m.getToggleState);
