import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SurfaceActionsOption" */ './lazyIndex'),
    { name: 'SurfaceActionsOption' }
);

export const lazyInitializeHoverSurfaceAction = new LazyAction(
    lazyModule,
    m => m.initializeHoverSurfaceAction
);
export const lazySaveHoverSurfaceActionService = new LazyAction(
    lazyModule,
    m => m.saveHoverSurfaceActionService
);

export const lazyFetchShowSettingsHoverAction = new LazyAction(
    lazyModule,
    m => m.fetchShowSettingsHoverAction
);

export const lazySaveShowSettingsHoverAction = new LazyAction(
    lazyModule,
    m => m.saveShowSettingsHoverAction
);

export { default as getStore } from './store/store';
export {
    default as getHoverSurfaceAction,
    getHoverActionKeysFromMailTriageActions,
} from './utils/hoverSurfaceActionHelper';

export { default as setShowSettingsHoverActionMutator } from './mutators/setShowSettingsHoverActionMutator';
export { default as SurfaceActionsOptionState } from './store/schema/SurfaceActionsOptionState';
