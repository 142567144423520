import { createAppButton } from 'owa-command-ribbon';
import { getReadRibbonId } from 'owa-mail-ribbon-utils/lib/getReadRibbonId';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { onAddinTrigger, onMessageExtensionAddinsTrigger } from 'owa-nova-actions';
import getTabIdFromWindow from 'owa-addins-store/lib/store/getTabIdFromWindow';
import { errorThatWillCauseAlert } from 'owa-trace';
import { createAddInNovaEvent } from 'owa-nova-event-helpers';
import type { IButtonStyles } from '@fluentui/react';
import type { AppButtonOnExecuteParameter, RibbonControlProps } from 'owa-acui';
import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getSelectedTableView } from 'owa-mail-list-store';
import { getItemIds, getItem } from 'owa-mail-list-store/lib/selectors/mailRowDataPropertyGetter';
import { getIsReadButtonDisabled } from 'owa-mail-ribbon-utils/lib/getIsReadButtonDisabled';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import { owaComputedFn } from 'owa-computed-fn';
import mailStore from 'owa-mail-store/lib/store/Store';
import { getAddinIcon } from 'owa-addins-surface-actions/lib/utils/getAddinIcon';
import {
    isNoItemContext,
    isMultipleSelection,
} from 'owa-message-extension-flyout/lib/utils/messageExtensionItemContext';
import type { MailEntrypoint } from 'owa-m365-acquisitions/lib/types';
import type { RuntimeControlRibbonCapabilites } from 'owa-m365-acquisitions/lib/utils/runtimeControlRibbonCapabilites';

export function createPinnedAddInButton(
    addIn: AddinCommandSurfaceItem,
    composeId: string,
    editorId: string,
    controlId: number,
    targetWindow: Window,
    styles: IButtonStyles
): RibbonControlProps {
    const composeRibbonId = getComposeRibbonId(controlId, editorId);
    return createAppButton(
        composeRibbonId /* controlId */,
        addIn?.title ?? addIn?.name ?? '' /* label */,
        (params?: AppButtonOnExecuteParameter) => {
            if (!params) {
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * The error name (message) must be a string literal (no variables in it).
                 *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                errorThatWillCauseAlert(
                    `Pinned add-in button was clicked without parameters: ${addIn?.name}`
                );
                return;
            }
            const tabId = getTabIdFromWindow(targetWindow);
            const event = createAddInNovaEvent(
                addIn.key /* appId */,
                'MessageCompose' /* entrypoint */,
                tabId /* tabId */,
                params.location === 'Overflow'
                    ? 'RibbonOverflowMenu-overflow'
                    : params.id /* anchorElementId */,
                composeId /* composeId */,
                '' /* itemId */
            );
            onMessageExtensionAddinsTrigger(event);
        } /* onExecute handler */,
        undefined /* keyTip */,
        getAddinIcon(addIn) ?? '' /* iconName */,
        undefined /* iconColor */,
        styles,
        {
            customTooltip: addIn.ariaLabel?.replace(/\s-\s/g, '\n\n'),
            ariaDescription: addIn.ariaLabel,
        } /* overrides */
    );
}

/**
 * OnExecute handler for createAppButton
 *
 * @param {AddinCommandSurfaceItem} addIn
 * @param {(ClientItemId | undefined)} itemId
 * @param {Window} targetWindow
 * @param {?AppButtonOnExecuteParameter} [params]
 */
const onExecuteFn = owaComputedFn(function onExecuteFn(
    addIn: AddinCommandSurfaceItem,
    itemId: string | undefined,
    targetWindow: Window,
    entryPoint: MailEntrypoint,
    params?: AppButtonOnExecuteParameter
): void {
    if (!params) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert(
            `Read add-in button was clicked without parameters: ${addIn?.name}`
        );
        return;
    }
    const tabId = getTabIdFromWindow(targetWindow);

    if (!itemId && itemId !== '') {
        itemId = getItemIdFromTableView();
    }

    const event = createAddInNovaEvent(
        addIn.key /* appId */,
        entryPoint,
        tabId /* tabId */,
        params.location === 'Overflow'
            ? 'RibbonOverflowMenu-overflow'
            : params.id /* anchorElementId */,
        '' /* composeId */,
        itemId /* itemId */
    );
    onAddinTrigger(event);
});

/**
 * Create a button for an add-in in the read ribbon.
 *
 * @export
 * @param {AddinCommandSurfaceItem} addIn
 * @param {number} controlId
 * @param {Window} targetWindow
 * @param {IButtonStyles} styles
 * @param {ReadOnlyRibbonControlDefProps} props
 * @returns {RibbonControlProps}
 */
export function createAddinButtonForReadInRibbon(
    addIn: AddinCommandSurfaceItem,
    controlId: number,
    targetWindow: Window,
    styles: IButtonStyles,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const { isPopout, itemId } = props;
    const readRibbonId = getReadRibbonId(controlId, itemId);
    const params = {} as AppButtonOnExecuteParameter;
    params.id = readRibbonId as string;

    return createAppButton(
        readRibbonId /* controlId */,
        // title holds the label for the button, if not present then fallback to addIn.name
        addIn?.title ?? addIn?.name ?? '' /* label */,
        getComputedCallback(
            controlId,
            onExecuteFn,
            addIn,
            itemId?.Id ?? undefined /* itemId */,
            targetWindow,
            'MessageRead',
            params
        ) /* onExecute handler */,
        undefined /* keyTip */,
        getAddinIcon(addIn) ?? '' /* iconName */,
        undefined /* iconColor */,
        styles,
        {
            disabled: getIsReadButtonDisabled(
                controlId,
                isPopout,
                getRibbonMailboxInfo(props.mailboxInfo),
                itemId
            ),
            customTooltip: addIn.ariaLabel,
            ariaDescription: addIn.ariaLabel,
        } /* overrides */
    );
}

/**
 * Read itemId of the selected row in the table view.
 *
 * @returns {(string | undefined)}
 */
function getItemIdFromTableView(): string | undefined {
    const tableView = getSelectedTableView();

    // Integrated spam is not supported for multiple selection.
    if (tableView?.selectedRowKeys.size > 1) {
        return undefined;
    }
    const selectedRowKeys = [...tableView?.selectedRowKeys.keys()];
    const itemIds = getItemIds(selectedRowKeys[0], tableView);

    // Check if the item exists in the mailStore based on the itemId. If not, then
    // try to get the item from the tableView directly using mailRowDataPropertyGetter
    const itemRetrievedFromStore =
        mailStore.items.get(itemIds[0]) || getItem(selectedRowKeys[0], tableView);

    if (!itemRetrievedFromStore) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert(`No item for: "${selectedRowKeys[0]}"`);
        return undefined;
    }

    return itemRetrievedFromStore.ItemId ? itemRetrievedFromStore.ItemId.Id : undefined;
}

export const getReadRibbonRuntimeControlProps = owaComputedFn(
    function getReadRibbonRuntimeControlProps(
        addIn: AddinCommandSurfaceItem,
        controlId: number,
        targetWindow: Window,
        styles: IButtonStyles,
        props: ReadOnlyRibbonControlDefProps,
        ribbonContext: RuntimeControlRibbonCapabilites
    ): RibbonControlProps {
        const { isPopout, itemId } = props;
        const readRibbonId = getReadRibbonId(controlId, itemId);
        const params = {} as AppButtonOnExecuteParameter;
        params.id = readRibbonId as string;
        const ribbonMailboxInfo = getRibbonMailboxInfo(props.mailboxInfo);

        const isMultiSelect = isMultipleSelection(props.isPopout);
        const isNoContext = isNoItemContext();
        const messageItemId = isMultiSelect || isNoContext ? '' : itemId?.Id ?? undefined;

        const entryPoint = isNoContext
            ? 'NoItemContext'
            : isMultiSelect
            ? 'MultipleSelection'
            : 'MessageRead';

        const isAddinDisabledForMultipleSelection =
            isNoContext && !ribbonContext.isEnabledInNoContext;
        const isAddinDisabledForNoItemContext =
            isMultiSelect && !ribbonContext.isEnabledInMultiSelect;

        return createAppButton(
            readRibbonId /* controlId */,
            // title holds the label for the button, if not present then fallback to addIn.name
            addIn?.title ?? addIn?.name ?? '' /* label */,
            getComputedCallback(
                controlId,
                onExecuteFn,
                addIn,
                messageItemId /* itemId */,
                targetWindow,
                entryPoint,
                params
            ) /* onExecute handler */,
            undefined /* keyTip */,
            getAddinIcon(addIn) ?? '' /* iconName */,
            undefined /* iconColor */,
            styles,
            {
                disabled:
                    getIsReadButtonDisabled(controlId, isPopout, ribbonMailboxInfo, itemId) ||
                    isAddinDisabledForMultipleSelection ||
                    isAddinDisabledForNoItemContext,
                customTooltip: addIn.ariaLabel,
                ariaDescription: addIn.ariaLabel,
            } /* overrides */
        );
    }
);
