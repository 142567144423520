import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isAnalyzeContentDisabledForPrivacy } from 'owa-privacy-utils/lib/selectors/isAnalyzeContentDisabledForPrivacy';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getCoprincipalAccountForIndexerValue } from 'owa-account-source-list-store';
import { isShadowMailboxUser } from 'owa-session-store';
import { isCloudCache } from 'owa-account-source-list-internal';
import { isAccountFeatureEnabled } from 'owa-feature-flags';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const learningToolsCapability: Capability = {
    isEnabled: (mailboxInfo?: MailboxInfo) => {
        if (!mailboxInfo) {
            return false;
        }
        return isAccountFeatureEnabled('rp-immersiveReaderH2', mailboxInfo);
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (!mailboxInfo) {
            return false;
        }

        const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
        const account = getCoprincipalAccountForIndexerValue(indexer);
        return !!(
            account &&
            isServiceRequestSupportedForMailbox(mailboxInfo) &&
            !isAnalyzeContentDisabledForPrivacy() &&
            !isShadowMailboxUser(mailboxInfo) &&
            !isCloudCache(account)
        );
    },
};
