// extracted by mini-css-extract-plugin
export var categoryIcon = "yhzdr";
export var categoryMenuContainer = "DzBLD";
export var checkmarkDisplay = "kWdAs";
export var compact = "FVYoA";
export var datePicker = "Ymip1";
export var divider = "DJ0IP";
export var full = "exIKM";
export var goButton = "jJ5XB";
export var jumpToButton = "NBXW3";
export var jumpToButtonOpened = "hNciW";
export var jumpToButtonWithLabel = "uOxFA";
export var jumpToIcon = "zR9K4";
export var jumpToInput = "QL69V";
export var medium = "ASsdr";
export var popoverSurface = "wABDQ";
export var popoverSurfaceContainer = "YxE1P";
export var popoverSurfaceText = "CXWBA";