import getListViewState from './getListViewState';

export enum ExpansionState {
    COLLAPSED = 0,
    LOADING_BRANCHES = 1,
    BRANCHED = 2,
    LOADING_ITEM_PARTS = 3,
    FULLY_EXPANDED = 4,
}

export default function getRowExpansionState(rowKey: string): ExpansionState {
    const { shouldBeExpanded, expandedRowKey, forks } =
        getListViewState().expandedConversationViewState;

    // Collapsed state
    if (!expandedRowKey) {
        return ExpansionState.COLLAPSED;
    }

    // Loading branches or item parts
    if (expandedRowKey === rowKey && shouldBeExpanded) {
        if (!forks) {
            return ExpansionState.LOADING_ITEM_PARTS;
        } else {
            // forks can be an empty array in this case, so it will be non-nullish
            return ExpansionState.LOADING_BRANCHES;
        }
    }

    // Expanded state - branches or full with item parts
    // In branched case (first level expansion), forks will be an empty array if the conversation has no branches.
    // When chevron is clicked, we need to know whether to expand to branches or to item parts. This is where using an
    // empty array is useful when there are no branches.
    if (expandedRowKey === rowKey && !shouldBeExpanded) {
        return forks ? ExpansionState.BRANCHED : ExpansionState.FULLY_EXPANDED;
    }

    return ExpansionState.COLLAPSED;
}
