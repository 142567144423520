import type { PerformanceDatapoint } from 'owa-analytics';

/**
 * Peformance datapoint that tracks the time from when we request data to load the mail list to when the VirtualizedLoadMoreListView is rendered.
 */
let mailListLoadDp: PerformanceDatapoint | undefined = undefined;

export function getMailListLoadDatapoint(): PerformanceDatapoint | undefined {
    return mailListLoadDp;
}

export function setMailListLoadDatapoint(datapoint: PerformanceDatapoint | undefined) {
    mailListLoadDp = datapoint;
}
