import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import type { ConversationReadingPaneNode } from 'owa-mail-store';

/**
 * Try to get the parent item part of nodeId
 */
export function getParentItemPart(
    nodeId: string,
    itemPartsMap: Map<string, ItemPartViewState>,
    conversationNodes: Map<string, ConversationReadingPaneNode>
): ItemPartViewState | null {
    const node = conversationNodes.get(nodeId);
    if (node?.parentInternetMessageId) {
        return itemPartsMap.get(node.parentInternetMessageId) ?? null;
    }
    return null;
}
