import type BrandSubscriptionModel from '../store/schema/BrandSubscriptionModel';
import type BrandSubscriptionStore from './schema/BrandSubscriptionStore';
import { createStore } from 'owa-satcheljs';

const store: BrandSubscriptionStore = createStore<BrandSubscriptionStore>('BrandSubscription', {
    subscriptions: new Map<string, BrandSubscriptionModel>([]),
    unsubscribedSubscriptions: new Map<string, BrandSubscriptionModel>([]),
})();

export default store;
