import { action } from 'satcheljs';
import type { AddAccountDialogProps } from '../types/AddAccountDialogProps';

/*
 * Dispatch this action to render the account configuration experience
 */
export const showAccountConfigurationDialog = action(
    'showAccountConfigurationDialogInMainWindow',
    (props: AddAccountDialogProps) => ({ ...props })
);

/*
 * Dispatch this action to render the account configuration experience
 * in a projection window, such as Settings
 */
export const showAccountConfigurationDialogInProjection = action(
    'showAccountConfigurationDialogInPopout',
    (props: AddAccountDialogProps) => ({ ...props })
);
