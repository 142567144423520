import { LazyModule, createLazyComponent, registerLazyOrchestrator } from 'owa-bundling';
import { searchSessionEnded, searchSessionStarted, startSearch } from 'owa-search-actions';

const lazyModule = new LazyModule(() => import('./lazyIndex'), {
    name: 'mail/packages/mail/view/owa-mail-search-plus-chat',
});

// Components
export const SearchPlusChatEntryPointContainer = createLazyComponent(
    lazyModule,
    m => m.SearchPlusChatEntryPointContainer
);
export const SearchPlusChatRightPaneContainer = createLazyComponent(
    lazyModule,
    m => m.SearchPlusChatRightPaneContainer
);

// Orchestrators
registerLazyOrchestrator(startSearch, lazyModule, m => m.startSearchOrchestrator);
registerLazyOrchestrator(searchSessionStarted, lazyModule, m => m.searchSessionStartedOrchestrator);
registerLazyOrchestrator(searchSessionEnded, lazyModule, m => m.searchSessionEndedOrchestrator);

// Utils
export { isSearchChatEnabledForSession } from './utils/isSearchChatEnabledForSearchSession';
