import { createStore } from 'owa-satcheljs';
import type { NotificationSettingsSchema } from './schema';

// The 'notif-settings-new' flight uses store instead of the multiAccountStore
export const getStore = createStore<NotificationSettingsSchema>(
    'desktopNotificationsSettingsStore',
    {
        desktopNotificationSettings: new Map(),
    }
);
