import { createStore } from 'owa-satcheljs';
import type { CloudCacheStore } from './cloudCacheStore';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';

const cloudCacheStore: CloudCacheStore = {
    cloudCacheConfigItem: {
        accountType: 0,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (11,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        emailAddress: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (15,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        shadowMailboxPuid: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (19,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        exchangeSmtpAddress: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (23,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        exchangeMailboxPuid: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (27,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        id: null,
    },
    isLoaded: false,
    // Has the user had a cloud cache account active at some point in the session?
    // Needed to make sure that easi id dialog doesn't pop up immediately on account removal
    hadCCInSession: false,
};

export const getStore = createStore<CloudCacheStore>('cloudCacheStore', cloudCacheStore);

export function isCloudCacheLoaded() {
    return getStore().isLoaded;
}

export function userHadCloudCacheInSession() {
    return getStore().hadCCInSession;
}
