import type BusStopState from '../store/schema/BusStopState';
import getListViewState from '../selectors/getListViewState';
export default function resetExpansionViewStateInternal() {
    const expansionState = getListViewState().expandedConversationViewState;

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (10,5): Type 'null' is not assignable to type 'string'.
    // @ts-expect-error
    expansionState.expandedRowKey = null;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (14,5): Type 'null' is not assignable to type 'string'.
    // @ts-expect-error
    expansionState.focusedNodeId = null;
    expansionState.lastAutoFocusedNodeId = null;
    expansionState.anchorNodeId = null;
    expansionState.selectedNodeIds = [];
    expansionState.allNodeIds = [];
    expansionState.shouldBeExpanded = false;
    expansionState.busStopStateMap = new Map<string, BusStopState>([]);
    expansionState.forks = null;
}
