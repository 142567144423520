import type { MailboxFolderTreeData } from './schema/FolderStore';
import { PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import type FolderTreeData from './schema/FolderTreeData';
import FolderTreeLoadStateEnum from '../store/schema/FolderTreeLoadStateEnum';
import type { MailFolder } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import type SharedFolderData from './schema/SharedFolderData';
import type ArchiveInformation from './schema/ArchiveInformation';

/**
 * Gets the default mailbox folder tree data that is initialized to hold data for primary folder tree
 */
export function getDefaultMailboxFolderTreeData(mailboxInfo: MailboxInfo): MailboxFolderTreeData {
    return {
        folderTreeTable: new Map<string, FolderTreeData>([
            [
                PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
                {
                    id: PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
                    rootFolder: <MailFolder>{
                        distinguishedFolderType: PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
                        displayName: PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
                        mailboxInfo,
                    },
                    loadingState: FolderTreeLoadStateEnum.Uninitialized,
                },
            ],
        ]),
        sharedFolderData: new Map<string, SharedFolderData>(),
        sharedArchiveInformation: new Map<string, ArchiveInformation>(),
    };
}
