import type { LazyModule } from 'owa-bundling';
import { LazyImport } from 'owa-bundling';
import type { CoreOption, LazyOption } from '../store/schema/Option';
import type OptionComponentSettings from '../store/schema/OptionComponentSettings';

function createLazyOption<TFullModule>(
    coreOption: CoreOption,
    fullOptionLazyModule?: LazyModule<TFullModule>,
    fullSettingsGetter?: (m: TFullModule) => OptionComponentSettings
): LazyOption {
    const fullLazyImport =
        fullOptionLazyModule && fullSettingsGetter
            ? new LazyImport(fullOptionLazyModule, fullSettingsGetter)
            : null;

    return {
        ...coreOption,
        tryImportFullOptionFormSettingsSync: fullLazyImport
            ? /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
               * BASELINE. Do not copy and paste!
               *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
              () => fullLazyImport.tryImportForRender()
            : undefined,
    };
}

export { createLazyOption };
