import type { HoverActionKey } from 'owa-outlook-service-option-store';

export type HoverActionOrder = {
    [key in HoverActionKey]: number;
};
const hoverActionsOrder: HoverActionOrder = {
    '-': 0,
    None: 0,
    Settings: 0,
    Move: 1,
    ReadUnread: 2,
    FlagUnflag: 3,
    PinUnpin: 4,
    Archive: 5,
    Delete: 6,
};

export default hoverActionsOrder;
