import type SortDirection from 'owa-service/lib/contract/SortDirection';
import {
    sizeTinyOption,
    sizeSmallOption,
    sizeMediumOption,
    sizeLargeOption,
    sizeVeryLargeOption,
    sizeHugeOption,
    sizeEnormousOption,
} from '../components/MailListJumpToPopover.locstring.json';

const SIZE_10KB = 1024 * 10;
const SIZE_25KB = 1024 * 25;
const SIZE_1MB = 1024 * 1024;
const SIZE_5MB = 1024 * 1024 * 5;
const SIZE_10MB = 1024 * 1024 * 10;
const SIZE_25MB = 1024 * 1024 * 25;
const MAX_INT32_VALUE = 2147483647;

export const getJumpToSizeOptions = (sortDirection: SortDirection) => {
    // We need the direction here to detemine what size to pass in the restriction because we each size option is a range of size values
    // and we need to pass the lower bound of the range as the restriction value if the sort direction is Ascending and pass the upper
    // bound of the range if the sort direction is Descending.
    const isAscending = sortDirection === 'Ascending';
    const options = [
        { value: isAscending ? 0 : SIZE_10KB, text: sizeTinyOption },
        { value: isAscending ? SIZE_10KB : SIZE_25KB, text: sizeSmallOption },
        { value: isAscending ? SIZE_25KB : SIZE_1MB, text: sizeMediumOption },
        { value: isAscending ? SIZE_1MB : SIZE_5MB, text: sizeLargeOption },
        { value: isAscending ? SIZE_5MB : SIZE_10MB, text: sizeVeryLargeOption },
        { value: isAscending ? SIZE_10MB : SIZE_25MB, text: sizeHugeOption },
        { value: isAscending ? SIZE_25MB : MAX_INT32_VALUE, text: sizeEnormousOption },
    ];

    return options;
};
