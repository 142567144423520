import React from 'react';

import {
    ArrowSortUpLinesRegular,
    ArrowSortDownLinesRegular,
    TextSortAscendingRegular,
    TextSortDescendingRegular,
    ArrowDownExclamationRegular,
    ArrowUpExclamationRegular,
} from '@fluentui/react-icons';
import { MailSortHelper, type SortColumn } from 'owa-mail-list-store';
import type SortDirection from 'owa-service/lib/contract/SortDirection';

export function getSortColumnIcon(
    sortColumn: SortColumn,
    sortDirection: SortDirection
): JSX.Element {
    switch (sortColumn) {
        case 1:
        case 7:
        case 16:
            return sortDirection === MailSortHelper.DESCENDING_SORT_DIR ? (
                <ArrowSortDownLinesRegular />
            ) : (
                <ArrowSortUpLinesRegular />
            );
        case 14:
        case 8:
        case 3:
        case 9:
            return sortDirection === MailSortHelper.DESCENDING_SORT_DIR ? (
                <TextSortDescendingRegular />
            ) : (
                <TextSortAscendingRegular />
            );
        case 5:
            return sortDirection === MailSortHelper.DESCENDING_SORT_DIR ? (
                <ArrowDownExclamationRegular />
            ) : (
                <ArrowUpExclamationRegular />
            );
        default:
            return <></>;
    }
}
