import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailLayoutOptions } from 'owa-outlook-service-option-store';
import { lazyGetServerOptionsForFeature, getDefaultOptions } from 'owa-outlook-service-options';
import { OwsOptionsFeatureType } from 'owa-outlook-service-option-store';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/Store';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const initializeSingleLineViewWithRightReadingPane = mutatorAction(
    'initializeSingleLineViewWithRightReadingPane',
    () => {
        const valueFromPrimeSettings = getIsEnabledFromPrimeSettings();

        if (valueFromPrimeSettings !== undefined) {
            getStore().useSingleLineMessageListWithRightReadingPane = valueFromPrimeSettings;
        } else {
            // If the value is not present in PrimeSettings, then use the default
            // value to initialize the store (before we fetch the value from the server).
            getStore().useSingleLineMessageListWithRightReadingPane =
                (getDefaultOptions()[OwsOptionsFeatureType.MailLayout] as MailLayoutOptions)
                    ?.useSingleLineMessageListWithRightReadingPane ?? true;

            // If the value is not present in PrimeSettings, then fetch the value
            // from the server and update the store.
            initializeSingleLineViewWithRightReadingPaneWithServiceData();
        }
    }
);

/**
 * Determines if the useSingleLineMessageListWithRightReadingPane option is
 * enabled in PrimeSettings
 *
 * @returns The value of useSingleLineMessageListWithRightReadingPane from
 * PrimeSettings (or undefined if not present)
 */
const getIsEnabledFromPrimeSettings = () => {
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const primeSettingsItems = getAccountScopeUserSettings(mailboxInfo)?.PrimeSettings?.Items;
    const primeListMailLayoutOptions: any[] | undefined = primeSettingsItems?.filter(
        item => item?.Id == 'MailLayoutOptions'
    );

    return primeListMailLayoutOptions?.[0]?.Value?.options?.[0]
        ?.useSingleLineMessageListWithRightReadingPane;
};

/**
 * Fetches the value of useSingleLineMessageListWithRightReadingPane from the server
 * and updates the store (assuming a valid response)
 */
const initializeSingleLineViewWithRightReadingPaneWithServiceData = async () => {
    const getServerOptionsForFeature = await lazyGetServerOptionsForFeature.import();
    const response = await getServerOptionsForFeature<MailLayoutOptions>(
        OwsOptionsFeatureType.MailLayout
    );

    if (typeof response?.useSingleLineMessageListWithRightReadingPane === 'boolean') {
        getStore().useSingleLineMessageListWithRightReadingPane =
            response.useSingleLineMessageListWithRightReadingPane;
    }
};

export default initializeSingleLineViewWithRightReadingPane;
