import loadInitialConversationsFromServer from './helpers/loadInitialConversationsFromServer';
import loadInitialItemsFromServer from './helpers/loadInitialItemsFromServer';
import type { TableView } from 'owa-mail-list-store';
import { type LoadErrorStatus } from 'owa-mail-list-store';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type { PagingInfoInput as GqlPagingInfo } from 'owa-graph-schema';
import { trace } from 'owa-trace';
import { mutatorAction } from 'satcheljs';
import type { OnInitialTableLoadComplete } from 'owa-mail-loading-action-types';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { isFolderInMailboxType } from 'owa-folders';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * @param tableView to load rows in
 * @param OnInitialTableLoadComplete is a callback that is called when we receive the response
 * The callback is handled by table loading
 * @param isTablePrefetched indicates if the table is prefetched
 * @return a promise that resolves when the load table from server has completed
 */
export default function loadInitialRowsFromServer(
    tableView: TableView,
    isTablePrefetched: boolean,
    onInitialTableLoadComplete: OnInitialTableLoadComplete,
    initialSessionData?: SessionData,
    skipGqlTimings?: boolean,
    customPagingInfo?: GqlPagingInfo
): Promise<void> {
    if (
        tableView.isInitialLoadComplete &&
        tableView.loadErrorStatus === 0 &&
        isFolderInMailboxType(tableView.tableQuery.folderId, 'UserMailbox') &&
        isFeatureEnabled('mon-avoid-duplicate-rows-requests')
    ) {
        return Promise.resolve();
    }

    setTableViewLoading(tableView);

    switch (tableView.tableQuery.listViewType) {
        case 0:
            return loadInitialConversationsFromServer(
                tableView,
                isTablePrefetched,
                onInitialTableLoadComplete,
                initialSessionData,
                skipGqlTimings,
                customPagingInfo
            );

        case 1:
            return loadInitialItemsFromServer(
                tableView,
                isTablePrefetched,
                onInitialTableLoadComplete,
                initialSessionData,
                customPagingInfo
            );

        default:
            trace.warn('listViewType: ' + tableView.tableQuery.listViewType + ' is not supported.');
            return Promise.resolve();
    }
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const setTableViewLoading = mutatorAction('setTableViewLoading', (tableView: TableView) => {
    tableView.isLoading = true;
    tableView.loadErrorStatus = 0;
});
