import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import { isEmailAnAlias, isValidSmtpAddress } from './isValidRecipientAddress';
import getDisplayNameAndAddressFromRecipientString from './getDisplayNameAndAddressFromRecipientString';
import parseOneOffEmailAddressFromString from './parseOneOffEmailAddressFromString';
import dangerousCreateSMTPEmailAddressWithoutValidation from './dangerousCreateSMTPEmailAddressWithoutValidation';

const RECIPIENT_SPLIT_REGEX = /[,;]/;

export default function processRecipientsFromUrlParameter(
    rawRecipients: string | undefined,
    recipients: EmailAddressWrapper[]
) {
    if (rawRecipients && rawRecipients.length > 0) {
        const splitRecipients = rawRecipients.split(RECIPIENT_SPLIT_REGEX);
        splitRecipients
            .map(recipient => recipient.trim())
            .forEach(recipient => {
                // The mailto link spec is for mailto:<smtp>. Opt to explicitly check
                // mailto instead of isValidEmailAddress, since isValidEmailAddress also supports exchange-specific
                // recipients with custom routing protocols.
                if (isValidSmtpAddress(recipient)) {
                    recipients.push({ EmailAddress: recipient, Name: recipient });
                } else {
                    // Check if the recipient text includes a display name along with the SMTP address
                    // NOTE: This is not strictly standard, but it's a well-known practice and it helps our own scenarios
                    const addressAndName = getDisplayNameAndAddressFromRecipientString(recipient);
                    const parsedEmail = parseOneOffEmailAddressFromString(
                        addressAndName.address,
                        addressAndName.displayName
                    );

                    // Failed to parse a recipient.
                    if (!parsedEmail) {
                        // in order to support aliases we need to validate if the parsed text
                        // is a malformed SMTP email or just text that can be interpreted as an alias
                        // if so we add it to the recipients well, so the user finishes the process
                        // validating every element
                        if (isEmailAnAlias(recipient)) {
                            const incompleteEmail =
                                dangerousCreateSMTPEmailAddressWithoutValidation(
                                    addressAndName.address
                                );
                            recipients.push(incompleteEmail);
                        }
                        return;
                    }

                    recipients.push(parsedEmail);
                }
            });
    }
}
