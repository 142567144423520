import { LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AuthRemediation"*/ './lazyIndex'),
    { name: 'AuthRemediation' }
);

// Delay loaded action as import since action does not allow to return non-void values
export const lazyRemediateAuth = new LazyImport(lazyModule, m => m.remediateAuth);

export { isPromptForResourceTokenEnabled } from './isPromptForResourceTokenEnabled';
