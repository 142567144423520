import toggleSelectItemPart from './toggleSelectItemPart';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { FocusedItemArea } from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import { getFocusedItemPart } from '../utils/focusedItemPartUtils';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import { getParentItemPart } from '../utils/rollUp/rollUpUtils';
import type { ClientItem, ConversationReadingPaneNode } from 'owa-mail-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import { OOF_ITEM_CLASS_REGEX } from 'owa-mail-store/lib/utils/constants';
import type Item from 'owa-service/lib/contract/Item';
import type Message from 'owa-service/lib/contract/Message';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Using transaction to mitigate a perf issue; this should be refactored to use idiomatic */
import { transaction } from 'mobx';
import { mutatorAction } from 'satcheljs';

export interface SetItemIdToScrollToState {
    conversationNodes: Map<string, ConversationReadingPaneNode>;
    conversationReadingPaneState: ConversationReadingPaneViewState;
    items: Map<string, ClientItem>;
}

/**
 * @param item
 * @param parentNode
 * @param parentItemPart
 * @return true if the item is an OOF message and its trigger message exists in allNodeIds
 */
const shouldScrollToParentNode = (
    item: Item,
    nodeId: string,
    parentItemPart: ItemPartViewState,
    conversationNodes: Map<string, ConversationReadingPaneNode>
): boolean => {
    const node = conversationNodes.get(nodeId);
    const parentNode = node?.parentInternetMessageId
        ? conversationNodes.get(node.parentInternetMessageId)
        : null;
    return !!(
        item &&
        parentItemPart &&
        parentNode &&
        item.ItemClass &&
        OOF_ITEM_CLASS_REGEX.test(item.ItemClass)
    );
};

export default function setItemIdToScrollTo(
    conversationId: string,
    itemId: string,
    shouldNotGrabFocus?: boolean,
    state: SetItemIdToScrollToState = {
        conversationNodes: mailStore.conversationNodes,
        conversationReadingPaneState: getConversationReadingPaneViewState(conversationId),
        items: mailStore.items,
    }
) {
    // In case there is no ReadingPaneState, dont do anything.
    // SxS is an example where the consumer might be in compose or reading mode
    if (!state.conversationReadingPaneState) {
        return;
    }

    transaction(() => {
        setConvItemIdScrollToMutator(state.conversationReadingPaneState, itemId);
        const item = state.items.get(itemId);
        if (item) {
            const nodeId = (<Message>item).InternetMessageId;
            if (nodeId) {
                const itemPart = state.conversationReadingPaneState.itemPartsMap.get(nodeId);
                const focusedItemPart = getFocusedItemPart(conversationId);
                const parentItemPart = getParentItemPart(
                    nodeId,
                    state.conversationReadingPaneState.itemPartsMap,
                    state.conversationNodes
                );
                if (
                    parentItemPart &&
                    shouldScrollToParentNode(item, nodeId, parentItemPart, state.conversationNodes)
                ) {
                    if (itemPart && focusedItemPart != parentItemPart) {
                        setItemToScrollTo(parentItemPart, state.conversationReadingPaneState);
                        setFocusedItemPartMutator(state.conversationReadingPaneState, itemPart);
                    }
                    setParentItemIsOofRollUpExpandedMutator(parentItemPart);
                    return;
                }
                // If the itemPart exists in the conversation that's in view and is not selected, select it.
                // This will expand collapsed itemParts and select already expanded itemParts.
                if (itemPart && focusedItemPart != itemPart) {
                    setItemToScrollTo(itemPart, state.conversationReadingPaneState);
                    toggleSelectItemPart(
                        conversationId,
                        true /*toggleExpandedCollapsed*/,
                        itemPart,
                        undefined /* fromKeyboard */,
                        shouldNotGrabFocus
                    );
                }
            }
        }
    });
}

const setFocusedItemPartMutator = mutatorAction(
    'setFocusedItemPart',
    (
        conversationReadingPaneState: ConversationReadingPaneViewState,
        itemPart: ItemPartViewState
    ) => {
        conversationReadingPaneState.focusedItemPart = {
            focusedItemArea: FocusedItemArea.Oof,
            itemPart,
        };
    }
);

const setParentItemIsOofRollUpExpandedMutator = mutatorAction(
    'setParentItemIsOofRollUpExpanded',
    (parentItemPart: ItemPartViewState) => {
        parentItemPart.oofRollUpViewState.isOofRollUpExpanded = true;
    }
);

const setConvItemIdScrollToMutator = mutatorAction(
    'setConvItemIdScrollTo',
    (conversationReadingPaneState: ConversationReadingPaneViewState, itemId: string) => {
        conversationReadingPaneState.itemIdToScrollTo = itemId;
    }
);

const setItemToScrollTo = (
    itemPart: ItemPartViewState,
    conversationReadingPaneState: ConversationReadingPaneViewState
) => {
    // Set the itemIdToScrollTo now. This will trigger the autorun on the itemParts to check if the itemId matches its own.
    // It also has logic to only call back to the reading pane to scroll if it's already expanded.
    setConvItemIdScrollToMutator(conversationReadingPaneState, itemPart.itemId);
};
