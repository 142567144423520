import { getListViewTypeForFolder } from 'owa-mail-folder-store';
import createMailFolderTableQuery from 'owa-mail-triage-table-utils/lib/createMailFolderTableQuery';
import {
    type TableView,
    getFocusedFilterForTable,
    type MailFolderTableQuery,
} from 'owa-mail-list-store';
import { resetFocus } from 'owa-mail-focus-manager';
import { loadTableViewFromTableQuery } from 'owa-mail-table-loading-actions';

export default async function exitCopilotDigestFilter(tableView: TableView) {
    const tableQuery = createMailFolderTableQuery(
        tableView.tableQuery.folderId,
        getListViewTypeForFolder(tableView.tableQuery.folderId),
        'mail',
        getFocusedFilterForTable(tableView)
    ) as MailFolderTableQuery;

    await loadTableViewFromTableQuery(tableQuery, undefined /* loadTableViewDatapoint */);

    resetFocus('exitCopilotDigestFilter');
}
