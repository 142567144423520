import { mutatorAction } from 'satcheljs';
import type { SearchScenarioId } from 'owa-search-store';
import { getScenarioStore } from 'owa-search-store';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const resetFocusFromSuggestionsToSearchInput = mutatorAction(
    'RESET_FOCUS_FROM_SUGGESTIONS_TO_SEARCH_INPUT',
    (scenarioId: SearchScenarioId) => {
        const searchStore = getScenarioStore(scenarioId);

        searchStore.selectedSuggestionIndex = -1;
    }
);
