import { lazySelectGroup } from 'owa-mail-folder-forest-actions';
import groupHeaderCommandBarAction from 'owa-group-header-store/lib/actions/commandBarAction';
import groupHeaderNavigationButton from 'owa-group-header-store/lib/store/schema/NavigationButton';
import { setSharePointItemsViewNavigationTargetPath } from 'owa-group-files-hub-store';
import type { MailGroupRouteParameters } from './utils/mailGroupRouteSettings';
import { ensureValidGroupSmtpAddress } from './utils/mailGroupRouteSettings';
import { logUsage } from 'owa-analytics';
import { getSourceQueryParam } from 'owa-querystring';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { lazySwitchToGroupsModule } from 'owa-groups-dash-nav-actions/lib/lazyCalendarBoot';
import { lazyLoadGroupsList } from 'owa-groups-left-nav-actions/lib/lazyFunction';
import GroupsDashTabId from 'owa-groups-dash-content-store/lib/store/schema/GroupsDashTabId';
import { isFeatureEnabled } from 'owa-feature-flags';

export default async function mailGroupFilesRouteHandler(
    parameters: MailGroupRouteParameters,
    allParameters?: string[]
) {
    const groupSmtp = ensureValidGroupSmtpAddress(parameters);

    if (!groupSmtp) {
        return;
    }

    logUsage('MailGroupFilesRouteHandlerEvent', { source: getSourceQueryParam() });

    groupHeaderCommandBarAction(groupHeaderNavigationButton.Files);
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();

    if (isFeatureEnabled('grp-WeSpaceDeprecation')) {
        const loadGroupsList = await lazyLoadGroupsList.import();
        await loadGroupsList(mailboxInfo, false /*isReload*/, true /*SubscribeAll */);
        lazySwitchToGroupsModule.importAndExecute(
            mailboxInfo,
            groupSmtp,
            undefined /*folderId*/,
            GroupsDashTabId.Files,
            'MailAppGroupsFilesRouting'
        );
    } else {
        await lazySelectGroup.importAndExecute(groupSmtp, 'groups', mailboxInfo);

        const spPath = allParameters && allParameters.length > 2 ? allParameters[2] : null;
        if (spPath) {
            const realSpPath = '/' + spPath;
            setSharePointItemsViewNavigationTargetPath(groupSmtp, realSpPath);
        } else {
            setSharePointItemsViewNavigationTargetPath(groupSmtp, '');
        }
    }
}
