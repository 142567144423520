import type Message from 'owa-service/lib/contract/Message';
import {
    MailRowDataPropertyGetter,
    getSelectedItemParts,
    getSelectedTableView,
    isItemPartOperation,
} from 'owa-mail-list-store';
import getItemForMailList from 'owa-mail-store/lib/selectors/getItemForMailList';
import { isFirstLevelExpanded } from 'owa-mail-list-store/lib/selectors/isConversationExpanded';
import type { TableView } from 'owa-mail-list-store';
import type { ClientItem } from 'owa-mail-store';

export default function getItemOrMessage(
    tableView: TableView
): Message | ClientItem | undefined | null {
    const selectedRowKeys = [...getSelectedTableView().selectedRowKeys.keys()];
    const rowKey = selectedRowKeys.length == 1 ? selectedRowKeys[0] : '';
    if (isItemPartOperation()) {
        // Conversation view
        const itemId = getSelectedItemParts()[0]; // [0] for single selection
        return getItemForMailList(itemId, isFirstLevelExpanded(rowKey));
    } else if (rowKey) {
        return MailRowDataPropertyGetter.getItem(rowKey, tableView);
    }
    return undefined;
}
