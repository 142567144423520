import { getGlobalSettingsAccount } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import { lazyLoginUserMsal } from './lazyFunctions';
import { InteractionType } from './utils/InteractionType';
import { getClaimsFromHeader } from 'owa-tokenprovider-utils';
import type {
    AtLeastOne,
    AuthenticationTargets,
    promptForResourceTokenFunc,
} from 'owa-auth-callbacks';

/**
 * Prompt for an access token for the resource. Note this may not prompt the user if the token silently acquired.
 * @param action. A helpful string, like the one passed to makeServiceRequest, to help identify the context of the request.
 * @param mailboxInfo. Mailbox context to use for this resource token request.
 * @param resource. Optional resource that caller is requesting to access. Either the resource or the scope must be passed.
 * @param scope. Optional permission string that caller is requesting to access. Either the resource or the scope must be passed.
 * @param wwwAuthenticateHeader. Optional WWW-Authenticate header value received from server.
 */

export const promptForResourceToken: promptForResourceTokenFunc = async function (
    action: string,
    mailboxInfo: MailboxInfo,
    targets: AtLeastOne<AuthenticationTargets>
): Promise<TokenResponse | undefined> {
    if (!action) {
        throw new Error('Action must be provided for proper attribution.');
    }

    const claimChallenge = getClaimsFromHeader(targets.wwwAuthenticateHeader);

    return lazyLoginUserMsal.importAndExecute(
        getGlobalSettingsAccount().sourceType,
        InteractionType.Popup,
        undefined /* msalAccount */,
        mailboxInfo,
        undefined /* username */,
        targets.resource,
        targets.scope,
        undefined /* promptValue */,
        claimChallenge ? atob(claimChallenge) : undefined
    );
};
