import type {
    AddTimeSelectionNovaEvent,
    AddTimeSelectionPayload,
} from './AddTimeSelectionNovaEvent';
import type {
    OpenEventDetailsPayload,
    OpenEventDetailsNovaEvent,
} from './OpenEventDetailsNovaEvent';
import type {
    RemoveTimeSelectionNovaEvent,
    RemoveTimeSelectionPayload,
} from './RemoveTimeSelectionNovaEvent';
import type {
    InvokeRemoteCommandNovaEvent,
    InvokeRemoteCommandPayload,
} from './InvokeRemoteCommandNovaEvent';
import type {
    RespondToMeetingNovaEvent,
    RespondToMeetingPayload,
} from './RespondToMeetingNovaEvent';
import type { LogCalendarTelemetryNovaEvent } from './LogCalendarTelemetryNovaEvent';
import type { CalendarTelemetryEventPayload } from '@outlook/calendar-nova-event-types';
import type {
    InvokeLocalCommandPayload,
    InvokeLocalCommandNovaEvent,
} from './InvokeLocalCommandNovaEvent';

export const CalendarSidePanelEventTypes = {
    addTimeSelection: 'addTimeSelection',
    removeTimeSelection: 'removeTimeSelection',
    openEventDetails: 'openEventDetails',
    invokeRemoteCommand: 'invokeRemoteCommand',
    respondToMeeting: 'respondToMeeting',
    logCalendarTelemetry: 'logCalendarTelemetry',
    invokeLocalCommand: 'invokeLocalCommand',
} as const;

export type CalendarSidePanelEventUnion =
    | AddTimeSelectionNovaEvent
    | RemoveTimeSelectionNovaEvent
    | OpenEventDetailsNovaEvent
    | InvokeRemoteCommandNovaEvent
    | RespondToMeetingNovaEvent
    | LogCalendarTelemetryNovaEvent
    | InvokeLocalCommandNovaEvent;

export const CalendarSidePanelEvents = {
    addTimeSelection: (
        originator: string,
        data: () => AddTimeSelectionPayload
    ): AddTimeSelectionNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.addTimeSelection,
        data,
    }),
    removeTimeSelection: (
        originator: string,
        data: () => RemoveTimeSelectionPayload
    ): RemoveTimeSelectionNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.removeTimeSelection,
        data,
    }),
    openEventDetails: (
        originator: string,
        data: () => OpenEventDetailsPayload
    ): OpenEventDetailsNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.openEventDetails,
        data,
    }),
    invokeRemoteCommand: (
        originator: string,
        data: () => InvokeRemoteCommandPayload
    ): InvokeRemoteCommandNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.invokeRemoteCommand,
        data,
    }),
    respondToMeeting: (
        originator: string,
        data: () => RespondToMeetingPayload
    ): RespondToMeetingNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.respondToMeeting,
        data,
    }),
    logCalendarTelemetry: (
        originator: string,
        data: () => CalendarTelemetryEventPayload
    ): LogCalendarTelemetryNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.logCalendarTelemetry,
        data,
    }),
    invokeLocalCommand: (
        originator: string,
        data: () => InvokeLocalCommandPayload
    ): InvokeLocalCommandNovaEvent => ({
        originator,
        type: CalendarSidePanelEventTypes.invokeLocalCommand,
        data,
    }),
};

export type CalendarSidePanelOwaNovaEventMap = {
    addTimeSelection: AddTimeSelectionNovaEvent;
    removeTimeSelection: RemoveTimeSelectionNovaEvent;
    openEventDetails: OpenEventDetailsNovaEvent;
    invokeRemoteCommand: InvokeRemoteCommandNovaEvent;
    respondToMeeting: RespondToMeetingNovaEvent;
    logCalendarTelemetry: LogCalendarTelemetryNovaEvent;
    invokeLocalCommand: InvokeLocalCommandNovaEvent;
};
