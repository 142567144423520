import {
    getCoprincipalAccounts,
    StartupFilter,
    ContractsSupportedFilter,
    accountSourceDataTypeChecker,
    setSharedSources,
} from 'owa-account-source-list-store';

/**
 * Removes the specified automapped shared mailbox from the list of shared mailboxes
 * @param sourceId Specifies the account source id
 * @param email Specifies the email address of the shared mailbox to be removed
 */
export function removeAutomappedSharedMailbox(sourceId: string, email: string) {
    const account = getCoprincipalAccounts(
        StartupFilter.StartingOrCompleteOrError,
        ContractsSupportedFilter.Any
    ).filter(current => current.sourceId === sourceId)[0];

    if (accountSourceDataTypeChecker.isM365UserMailbox(account)) {
        const updatedSharedSources = account.sharedSources.sources.filter(
            item =>
                accountSourceDataTypeChecker.isM365SharedMailbox(item) &&
                (!item.isAutomapped || item.mailboxInfo.mailboxSmtpAddress !== email)
        );

        if (updatedSharedSources.length !== account.sharedSources.sources.length) {
            // we found and removed the shared source, update the store
            const updated = { ...account.sharedSources, sources: updatedSharedSources };
            setSharedSources(account.sourceId, updated);
        }
    }
}
