//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import type * as Types from 'owa-graph-schema';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TileContextMenuBehavior_FragmentFragment = {
    __typename?: 'TileContextMenu';
    openInNewTabItemLabel: string;
    aboutAppItemLabel: string;
    unpinAppItemLabel: string;
    pinAppItemLabel: string;
    uninstallAppItemLabel: string;
};
export type LaunchTilesBehavior_FragmentFragment = {
    __typename?: 'LaunchTileBehavior';
    transparent?: boolean | null;
    pinningEnabled?: boolean | null;
    useHubColorForSelectedTileLabel?: boolean | null;
    useOutlineIcon?: boolean | null;
    overrideAppBarDefaultSort?: boolean | null;
    platformAppCommands?: {
        __typename?: 'PlatformAppCommands';
        aboutCommandEnabled?: boolean | null;
        uninstallCommandEnabled?: boolean | null;
    } | null;
    tileContextMenu: {
        __typename?: 'TileContextMenu';
        openInNewTabItemLabel: string;
        aboutAppItemLabel: string;
        unpinAppItemLabel: string;
        pinAppItemLabel: string;
        uninstallAppItemLabel: string;
    };
};
export type NovaControlModelQueryVariables = Types.Exact<{
    [key: string]: never;
}>;
export type NovaControlModelQuery = {
    __typename?: 'Query';
    views: {
        __typename?: 'ViewData';
        messageExtensionFlyoutControl?: {
            __typename?: 'MessageExtensionFlyoutControl';
            id: string;
            zIndexOverride?: number | null;
            flyoutAriaLabel?: string | null;
            flyoutEmptyStateMessage?: string | null;
            tileBehavior: {
                __typename?: 'LaunchTileBehavior';
                transparent?: boolean | null;
                shouldShowLabel?: boolean | null;
                pinningEnabled?: boolean | null;
                useHubColorForSelectedTileLabel?: boolean | null;
                useOutlineIcon?: boolean | null;
                overrideAppBarDefaultSort?: boolean | null;
                platformAppCommands?: {
                    __typename?: 'PlatformAppCommands';
                    aboutCommandEnabled?: boolean | null;
                    uninstallCommandEnabled?: boolean | null;
                } | null;
                tileContextMenu: {
                    __typename?: 'TileContextMenu';
                    openInNewTabItemLabel: string;
                    aboutAppItemLabel: string;
                    unpinAppItemLabel: string;
                    pinAppItemLabel: string;
                    uninstallAppItemLabel: string;
                };
            };
            searchBehavior?: {
                __typename?: 'FlyoutSearchBehavior';
                isEnabled?: boolean | null;
                placeholderText?: string | null;
                debounceRate?: number | null;
                noResultsTitle: string;
                noResultsMessage: string;
            } | null;
        } | null;
        appBarControl?: {
            __typename?: 'AppBarControl';
            id: string;
            rootNavigationAriaLabel?: string | null;
            animateSelection?: boolean | null;
            enableMicaV2DesktopStyles?: boolean | null;
            enableMicaV2WebStyles?: boolean | null;
            enableOptimisticMutations?: boolean | null;
            tileBehavior: {
                __typename?: 'LaunchTileBehavior';
                transparent?: boolean | null;
                shouldShowLabel?: boolean | null;
                dragDropEnabled?: boolean | null;
                useOutlineIcon?: boolean | null;
                pinningEnabled?: boolean | null;
                useHubColorForSelectedTileLabel?: boolean | null;
                overrideAppBarDefaultSort?: boolean | null;
                tileContextMenu: {
                    __typename?: 'TileContextMenu';
                    openInNewTabItemLabel: string;
                    aboutAppItemLabel: string;
                    unpinAppItemLabel: string;
                    pinAppItemLabel: string;
                    uninstallAppItemLabel: string;
                };
                platformAppCommands?: {
                    __typename?: 'PlatformAppCommands';
                    aboutCommandEnabled?: boolean | null;
                    uninstallCommandEnabled?: boolean | null;
                } | null;
            };
            flyoutBehavior?: {
                __typename?: 'FlyoutBehavior';
                flyoutSize?: Types.FlyoutSize | null;
                searchBehavior?: {
                    __typename?: 'FlyoutSearchBehavior';
                    isEnabled?: boolean | null;
                    placeholderText?: string | null;
                    debounceRate?: number | null;
                    noResultsTitle: string;
                    noResultsMessage: string;
                } | null;
            } | null;
            appAcquisitionLinkBehavior?: {
                __typename?: 'AppAcquisitionLinkBehavior';
                linkFormat?: Types.AppAcquisitionLinkFormat | null;
            } | null;
            localizedStrings: {
                __typename?: 'LocalizedStrings';
                flyoutButtonText?: string | null;
                flyoutButtonTitle?: string | null;
                flyoutEmptyStateMessage?: string | null;
            };
            flyoutIcon?:
                | {
                      __typename: 'InternalIcon';
                      name: any;
                  }
                | {
                      __typename: 'RemoteImage';
                      src: string;
                      isFullBleed?: boolean | null;
                  }
                | null;
            flyoutIconSelected?:
                | {
                      __typename: 'InternalIcon';
                      name: any;
                  }
                | {
                      __typename: 'RemoteImage';
                      src: string;
                      isFullBleed?: boolean | null;
                  }
                | null;
        } | null;
    };
};
export const TileContextMenuBehavior_FragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TileContextMenuBehavior_fragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TileContextMenu' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'openInNewTabItemLabel' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'aboutAppItemLabel' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'unpinAppItemLabel' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'pinAppItemLabel' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'uninstallAppItemLabel' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TileContextMenuBehavior_FragmentFragment, unknown>;
export const LaunchTilesBehavior_FragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LaunchTilesBehavior_fragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'LaunchTileBehavior' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'transparent' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'pinningEnabled' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'useHubColorForSelectedTileLabel' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'useOutlineIcon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'overrideAppBarDefaultSort' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'platformAppCommands' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aboutCommandEnabled' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uninstallCommandEnabled' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tileContextMenu' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: {
                                        kind: 'Name',
                                        value: 'TileContextMenuBehavior_fragment',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...TileContextMenuBehavior_FragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LaunchTilesBehavior_FragmentFragment, unknown>;
export const NovaControlModelDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'NovaControlModel' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'views' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'messageExtensionFlyoutControl' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'zIndexOverride' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'flyoutAriaLabel' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'flyoutEmptyStateMessage',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tileBehavior' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'transparent',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'shouldShowLabel',
                                                            },
                                                        },
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'LaunchTilesBehavior_fragment',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'searchBehavior' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isEnabled',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'placeholderText',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'debounceRate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'noResultsTitle',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'noResultsMessage',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appBarControl' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'rootNavigationAriaLabel',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'animateSelection' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'enableMicaV2DesktopStyles',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'enableMicaV2WebStyles',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'enableOptimisticMutations',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tileBehavior' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'transparent',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'shouldShowLabel',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dragDropEnabled',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'useOutlineIcon',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'tileContextMenu',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'TileContextMenuBehavior_fragment',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'LaunchTilesBehavior_fragment',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'flyoutBehavior' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'flyoutSize',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'searchBehavior',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isEnabled',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'placeholderText',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'debounceRate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'noResultsTitle',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'noResultsMessage',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'appAcquisitionLinkBehavior',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'linkFormat',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'localizedStrings' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'flyoutButtonText',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'flyoutButtonTitle',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'flyoutEmptyStateMessage',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'flyoutIcon' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: '__typename',
                                                            },
                                                        },
                                                        {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                                kind: 'NamedType',
                                                                name: {
                                                                    kind: 'Name',
                                                                    value: 'InternalIcon',
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                                kind: 'NamedType',
                                                                name: {
                                                                    kind: 'Name',
                                                                    value: 'RemoteImage',
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'src',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isFullBleed',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'flyoutIconSelected' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: '__typename',
                                                            },
                                                        },
                                                        {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                                kind: 'NamedType',
                                                                name: {
                                                                    kind: 'Name',
                                                                    value: 'InternalIcon',
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                                kind: 'NamedType',
                                                                name: {
                                                                    kind: 'Name',
                                                                    value: 'RemoteImage',
                                                                },
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'src',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isFullBleed',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...LaunchTilesBehavior_FragmentFragmentDoc.definitions,
        ...TileContextMenuBehavior_FragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<NovaControlModelQuery, NovaControlModelQueryVariables>;
