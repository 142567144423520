import { getDownloadMessageUrl } from 'owa-files-url/lib/actions/getDownloadMessageUrl';
import mailStore from 'owa-mail-store/lib/store/Store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type ClientItem from 'owa-mail-store/lib/store/schema/ClientItem';
import { isFeatureEnabled, isAccountFeatureEnabled } from 'owa-feature-flags';
import getItemRightsManagementRestrictions from 'owa-mail-store/lib/utils/getItemRightsManagementRestrictions';
import { getRootItemId } from 'owa-root-item-store';
import { isDownloadAllowed } from 'owa-attachment-user-operations/lib/utils/isDownloadAllowed';
import type Message from 'owa-service/lib/contract/Message';
import type { MailboxInfo } from 'owa-client-types';

export default function dragToDownloadEmail(
    dataTransfer: DataTransfer,
    itemId: string,
    subject: string | undefined
): void {
    if (isFeatureEnabled('mon-dnd-download')) {
        const item = mailStore.items.get(itemId);
        const mimeType = 'application/octet-stream';
        const fileName = (item?.Subject || subject || 'File') + '.eml';
        const IRMRestrictions = getItemRightsManagementRestrictions(item);
        /*
        If fileName has a colon we replace all occurences of colon with empty string as colon is not allowed in file name,
        dndDownload will be of the format mimeType:fileName:downloadURLWithUpdatedToken. An extra colon within the file name will break the format.
            */
        const sanitizedFileName = fileName.split(':').join('');
        const mailboxInfo =
            (item as ClientItem)?.MailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
        const fullDownloadUrl: string | undefined = getDownloadMessageUrl(
            itemId,
            'EML',
            mailboxInfo
        );
        const isEmailDownloadDisabled = item ? !allowDownload(item, mailboxInfo) : false;
        const isSaveAsAllowedByIRM = isAccountFeatureEnabled('snc-editIRM-saveAs', mailboxInfo)
            ? IRMRestrictions.EditAllowed
            : IRMRestrictions.PrintAllowed &&
              IRMRestrictions.CopyAllowed &&
              IRMRestrictions.ForwardAllowed;
        const isDownloadDisabled =
            !isSaveAsAllowedByIRM || isEmailDownloadDisabled || !!getRootItemId(itemId);
        if (!isDownloadDisabled && fullDownloadUrl) {
            const dndDownload = [mimeType, sanitizedFileName, fullDownloadUrl].join(':');
            dataTransfer.setData('DownloadURL', dndDownload);
        }
    }
}

function allowDownload(message: Message, mailboxInfo: MailboxInfo): boolean {
    return (
        !message.Attachments ||
        !!message.Attachments?.every(attachment => isDownloadAllowed(attachment, mailboxInfo))
    );
}
