import { isFeatureEnabled } from 'owa-feature-flags';
import { getAccountScopeUserSettings, isConsumer } from 'owa-session-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { getMailboxInfoFromFolderId } from 'owa-mail-mailboxinfo/lib/getMailboxInfo';

export function isSenderScreeningEnabledForFolder(folderId: string): boolean {
    const mailboxInfo = getMailboxInfoFromFolderId(folderId);

    return (
        isConsumer(undefined, mailboxInfo) &&
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'tri-sender-screening' */
        isFeatureEnabled('tri-sender-screening', mailboxInfo) &&
        folderIdToName(folderId) === 'inbox' &&
        (getAccountScopeUserSettings(mailboxInfo).UserOptions?.IsSenderScreeningSettingEnabled ??
            false)
    );
}
