import type { FlattenedGroupFolder } from 'owa-groups-shared-folders-schema/lib/schema/FlattenedGroupFolder';
import { getContextMenuGroupsFolders } from 'owa-groups-shared-folders-store';
import { getSelectedGroupId } from 'owa-group-utils';
import type MoveToFolderType from '../store/schema/MoveToFolderType';

export const DEFAULT_GROUP_FOLDER_MOVETO_DEPTH = 2;

/**
 * converts FlattenedGroupsFolder to MoveToFolder
 * @param groupFolder the group folder
 * @returns the group folder wrapped in MoveToFolderType interface
 */
function convertToMoveToFolder(groupFolder: FlattenedGroupFolder): MoveToFolderType {
    return {
        folderId: groupFolder.Id,
        displayName: groupFolder.DisplayName.trim(),
        parentFolderId: groupFolder.ParentFolderId,
        parentFolderDisplayName: groupFolder.ParentFolderDisplayName ?? '',
        childFolderCount: groupFolder.ChildFolderCount,
        childFolderIds: groupFolder.ChildFolderIds,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (25,9): Type 'number | undefined' is not assignable to type 'number'.
        // @ts-expect-error
        depth: groupFolder.Depth,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (29,9): Type 'null' is not assignable to type 'MailboxInfo'.
        // @ts-expect-error
        mailboxInfo: null,
    };
}

/**
 * @returns Returns all the folders for the selected group wrapped in MoveToFolderType interface
 */
export function getAllMoveToFoldersForSelectedGroup(): MoveToFolderType[] {
    return getAllMoveToFoldersForGroup(getSelectedGroupId());
}

/**
 * @param groupId The id of group for which move to folders are to be returned
 * @returns Returns all the folders for the group received wrapped in MoveToFolderType interface
 */
export function getAllMoveToFoldersForGroup(groupId: string): MoveToFolderType[] {
    const groupFolders: FlattenedGroupFolder[] = getContextMenuGroupsFolders(groupId);
    const allMoveToFolders: MoveToFolderType[] = [];

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    groupFolders.forEach(element => {
        const folder = convertToMoveToFolder(element);
        allMoveToFolders.push(folder);
    });

    return allMoveToFolders;
}
