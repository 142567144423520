import { resetFocus } from 'owa-mail-focus-manager';
import {
    getSelectedTableView,
    type TableQueryType,
    MailRowDataPropertyGetter,
} from 'owa-mail-list-store';
import { lazyShowSweepDialog } from 'owa-mail-sweep-view';
import { getIsSharedMailbox } from 'owa-shared-mailbox';
import type { MailboxInfo } from 'owa-client-types';

export default function onSweep(mailboxInfo: MailboxInfo, targetWindow?: Window, itemId?: string) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];
    let itemIdForSweep: string | undefined = itemId;

    // If it is a search, find the item id from the selected row keys
    if (tableView.tableQuery.type === 1) {
        itemIdForSweep = MailRowDataPropertyGetter.getItemIds(rowKeys[0], tableView)[0];
    }

    getIsSharedMailbox(mailboxInfo);
    lazyShowSweepDialog.importAndExecute(
        rowKeys,
        tableView,
        mailboxInfo,
        targetWindow,
        itemIdForSweep
    );
    resetFocus('sweepCommand');
}
