import tryRemoveFromMailStoreItems, {
    type RemoveItemSource,
} from 'owa-mail-actions/lib/triage/tryRemoveFromMailStoreItems';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import {
    getRowIdsFromRowKeys,
    getTableToRowRelationKey,
    getStore as getListViewStore,
} from 'owa-mail-list-store';
import { lazyRemoveMeetingMessagesFromStore } from 'owa-listview-rsvp';
import { mutatorAction } from 'satcheljs';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Using transaction to mitigate a perf issue; this should be refactored to use idiomatic
 * Satchel/MobX patterns */
import { transaction } from 'mobx';

export default function removeItemData(rowKey: string, tableView: TableView) {
    // For a single row key, it should never map to multiple item ids
    const messageItemId = getRowIdsFromRowKeys([rowKey], tableView.id)[0];

    // 1. Remove table item relation
    const tableItemRelationKey = getTableToRowRelationKey(rowKey, tableView.id);
    if (!getListViewStore().tableViewItemRelations.has(tableItemRelationKey)) {
        throw new Error('Item not found in list view store');
    }

    transaction(() => {
        removeTableViewItemRelation(tableItemRelationKey);
        // Try removing it if the ref is not held by other scenarios
        tryRemoveFromMailStoreItems(messageItemId, 0);
    });

    lazyRemoveMeetingMessagesFromStore.importAndExecute([messageItemId]);
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const removeTableViewItemRelation = mutatorAction(
    'removeTableViewItemRelation',
    (tableItemRelationKey: string) => {
        getListViewStore().tableViewItemRelations.delete(tableItemRelationKey);
    }
);
