import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isFeatureEnabled } from 'owa-feature-flags';
import { logUsage } from 'owa-analytics';
import fetchPoliciesAndSetStore from './fetchPoliciesAndSetStore';
import type { AccountSource } from 'owa-account-source-list-store';
import {
    getCoprincipalAccountSourcesByBootState,
    BootState,
    onCoprincipalBootStateChanged,
    onPreCoprincipalAccountRemoved,
} from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { orchestrator } from 'satcheljs';
import { setOcpsPolicyStoreState } from '../mutators/setOcpsPolicyStoreState';
import { OcpsStoreState } from '../store/schema/OcpsPolicyStore';
import getOcpsPolicyStoreState from '../selectors/getOcpsPolicyStoreState';
import { onAccountRemoved } from './onAccountRemoved';

export default async function initializeOcpsPolicyStore(origin: string) {
    const ocpsPolicyStoreState = getOcpsPolicyStoreState();

    if (ocpsPolicyStoreState !== OcpsStoreState.Fetching) {
        logUsage('InitializeOcpsPolicyStore', { initSource: origin });
        try {
            setOcpsPolicyStoreState(OcpsStoreState.Fetching);
            const userMailboxes: MailboxInfo[] = getCoprincipalAccountSourcesByBootState(
                BootState.StartupComplete
            ).map(accountSource => accountSource.mailboxInfo);

            for (let i = 0; i < userMailboxes.length; i++) {
                await fetchUserOcpsPolicies(userMailboxes[i], origin);
            }

            // Register for boot state change and fetch policies when a new account is added
            /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
             * Move this function to the module scope or wrapped it on a once function
             *	> Function should only be invoked in module scope */
            orchestrator(
                onCoprincipalBootStateChanged,
                async (arg: { updatedAccount: AccountSource; newBootState: BootState }) => {
                    const actualAccount = arg.updatedAccount;
                    const actualBootState = arg.newBootState;
                    if (actualBootState === BootState.StartupComplete) {
                        logUsage('FetchPoliciesAndSetStore_AccountAdded', { initSource: origin });
                        await fetchUserOcpsPolicies(actualAccount.mailboxInfo, origin);
                    }
                }
            );

            /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
             * Move this function to the module scope or wrapped it on a once function
             *	> Function should only be invoked in module scope */
            orchestrator(onPreCoprincipalAccountRemoved, actionMessage => {
                onAccountRemoved(actionMessage.removedAccount.mailboxInfo);
            });

            setOcpsPolicyStoreState(OcpsStoreState.Ready);
        } catch (e) {
            logUsage('InitializeOcpsPolicyStore_Failed', {
                error: e.message,
                stack: e.stack,
                initSource: origin,
            });
            throw e;
        }
    }
}

async function fetchUserOcpsPolicies(mailboxInfo: MailboxInfo, origin: string) {
    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
     * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
     *	> The parameter mailboxInfo must be undefined. Feature flight: 'ocps-policy-check' */
    if (isFeatureEnabled('ocps-policy-check', mailboxInfo) && !isConsumer(undefined, mailboxInfo)) {
        await fetchPoliciesAndSetStore(mailboxInfo, origin);
    }
}
