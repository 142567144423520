import type AccessIssue from 'owa-attachment-policy-access-issue-checker/lib/schema/AccessIssue';
import getCombinedAccessIssue from 'owa-attachment-policy-access-issue-checker/lib/utils/getCombinedAccessIssue';
import getConditionalAccessIssue from 'owa-attachment-policy-access-issue-checker/lib/utils/getConditionalAccessIssue';
import type { MailboxInfo } from 'owa-client-types';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { isConsumer } from 'owa-session-store';
import { isVanityDomain } from 'owa-tokenprovider';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isCapabilityEnabled } from 'owa-capabilities';
import { m365AcquisitionsCapability } from 'owa-capabilities-definitions/lib/m365AcquisitionsCapability';
import { logCoreGreyError, logUsage } from 'owa-analytics';
import { trace, type TraceErrorObject } from 'owa-trace';
import { getApplicationSettings } from 'owa-application-settings';
import { isAccountExplicitLogon } from 'owa-account-shared-mailbox-utils';

export function isSupportedAppServiceEnvironment(mailboxInfo?: MailboxInfo): boolean {
    // Check if the M365Acquisitions capability is enabled
    if (!isCapabilityEnabled(m365AcquisitionsCapability, mailboxInfo)) {
        logUsage('MOS-AppServiceNotSupported', {
            AddInsCapabilitySupported: false,
        });

        trace.warn(`MOS-AppServiceNotSupported- Disabled Capability`, 'addins');

        return false;
    }

    const mboxInfo = mailboxInfo ?? getModuleContextMailboxInfo();
    const isExplicitLogon = isAccountExplicitLogon(mboxInfo);
    const combinedAccessIssue = isFeatureEnabled('mos-disableCombinedAccessCheck')
        ? getConditionalAccessIssue(mboxInfo).shift() ?? 2
        : getCombinedAccessIssue(mboxInfo);
    const isVanityDomainValue = isVanityDomain();
    const isConsumerValue = isConsumer(undefined, mboxInfo);
    if (combinedAccessIssue !== 2) {
        const mosAppServiceNotSupportedError: TraceErrorObject = new Error(
            'MosAppServiceNotSupported'
        );

        logCoreGreyError('MosAppServiceNotSupported', mosAppServiceNotSupportedError, {
            combinedAccessIssue,
            isExplicitLogon,
            isConsumerValue,
            isVanityDomainValue,
        });
        trace.warn(
            `MOS-AppServiceNotSupported-
            combinedAccessIssue-${combinedAccessIssue},
            isExplicitLogon-${isExplicitLogon},
            isConsumer-${isConsumerValue},
            isVanityDomain-${isVanityDomainValue}`,
            'addins'
        );
    }

    const mosAppinVanityDomain =
        !isVanityDomainValue || getApplicationSettings('OwaVanityDomains').enabled;

    const explicitLogonSupport =
        isFeatureEnabled('addin-sharedMailboxNewtab') &&
        isFeatureEnabled('addins-mos-sharedmailboxNewTab')
            ? true
            : !isExplicitLogon;

    return (
        combinedAccessIssue === 2 &&
        explicitLogonSupport &&
        !isConsumerValue &&
        mosAppinVanityDomain &&
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'mos-mos3AppServiceSupportedEnv' */
        isFeatureEnabled('mos-mos3AppServiceSupportedEnv', mailboxInfo)
    );
}
