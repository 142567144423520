import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/store';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateUserConfiguration: (
    updateFunction: (userConfig: OwaUserConfiguration) => void
) => void = mutatorAction(
    'updateUserConfiguration',
    (updateFunction: (userConfig: OwaUserConfiguration) => void) => {
        const { userConfiguration } = getStore();
        if (userConfiguration) {
            updateFunction(userConfiguration);
        }
    }
);
