//Enum with conditions allowed in the rule.
export enum ConditionalFormattingConditionType {
    From,
    OnTheToOrCcLine,
    OnTheToLine,
    NotOnTheToLine,
    OnTheCcLine,
    SubjectIncludes,
    SenderAddressIncludes,
}
