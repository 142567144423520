import type { RibbonConfiguration } from 'owa-mail-ribbon-store-shared-types';
import {
    homeTabValidDefaultConfigIds,
    viewTabValidDefaultConfigIds,
} from './utils/validDefaultConfigIds';
import { validateUserConfigLayout } from './utils/validateUserConfigLayout';

/**
 * This function scans through the user's ribbon configuration and ensures that the RibbonIds
 * are found in defaultConfig. This scenario is possible because a user may log into OWA,
 * customize their Ribbon, and then log into a really old version of Monarch where those RibbonIds
 * don't exist in the product yet. In that case, Monarch would break because it's trying to parse RibbonIds
 * that do not exist yet, and that has no concept of yet.
 *
 * To remedy this, we scan through the user's ribbon configuration, and as long as the RibbonIds exist
 * within defaultConfig, we consider the configuration valid. However, if we find a RibbonId that does not
 * exist at all in defaultConfig, then we consider the config corrupted.
 *
 * @param ribbonConfig the ribbon config that the user currently has
 * @param shouldPrune if true, we will remove ids from the ribbonConfig that are not found in defaultConfig
 * @returns true if the configuration is valid, false if not.
 */
export function ribbonValidityCheck(
    { homeTab, viewTab }: RibbonConfiguration,
    ribbonVersion: number,
    shouldPrune?: boolean
): boolean {
    const homeTabValid = validateUserConfigLayout(
        homeTabValidDefaultConfigIds,
        homeTab.layout,
        ribbonVersion,
        shouldPrune
    );

    const viewTabValid = validateUserConfigLayout(
        viewTabValidDefaultConfigIds,
        viewTab.layout,
        ribbonVersion,
        shouldPrune
    );

    return homeTabValid && viewTabValid;
}
