import type { MailboxInfo } from 'owa-client-types';
import { action } from 'satcheljs';
import type { ActionType } from '../types/ActionType';
import type { LightningId } from '../types/LightningId';

export default action(
    'lighted',
    (id: LightningId, actionType?: ActionType, mailboxInfo?: MailboxInfo) => ({
        id,
        actionType,
        mailboxInfo,
    })
);
