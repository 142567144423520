import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { KNOWN_COPILOT_ADDINS, KNOWN_DEBUG_ADDINS } from '../constants/copilotAddinIds';

export function getSalesAddins(mailboxInfo: MailboxInfo) {
    return KNOWN_COPILOT_ADDINS.concat(
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'mon-copilot-ext-debug-addins' */
        isFeatureEnabled('mon-copilot-ext-debug-addins', mailboxInfo) ? KNOWN_DEBUG_ADDINS : []
    );
}
