import { getCoprincipalAccountSourcesByBootState, BootState } from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import { getApplicationSettings } from 'owa-application-settings';
import { isFeatureEnabled } from 'owa-feature-flags';
import {
    isOcpsStoreReady,
    isOcpsPolicyEnabled,
    OcpsPolicy,
    getOcpsPolicyValue,
} from 'owa-ocps-policy-store';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';

export interface DiagPolicies {
    collectEmailEnabled: boolean;
    collectLogsEnabled: boolean;
    npsSurveyEnabled: boolean;
    screenShotEnabled: boolean;
    contactSupportEnabled: boolean;
    diagnosticsTroubleshootingEnabled: boolean;
    isFeedbackEnabled: boolean;
    isGetDiagnosticsEnabled: boolean;
}

export default function getAggregatePolicyInfo(entrypoint: string): DiagPolicies {
    const accounts = getCoprincipalAccountSourcesByBootState(BootState.StartupComplete);
    const enabledIfPolicyUndefined =
        getApplicationSettings('SupportAndFeedback').policyEnabledIfUndefined;
    let collectEmailEnabled = true; // true by default
    let collectLogsEnabled = true; // true by default
    let npsSurveyEnabled = true; // true by default
    let screenShotEnabled = true; // true by default
    let contactSupportEnabled = true; // true by default
    let diagnosticsTroubleshootingEnabled = true; // true by default
    let isFeedbackEnabled = true; // true by default
    let isGetDiagnosticsEnabled = true; // true by default

    if (!isFeatureEnabled('mon-diag-disableisOCPSStoreReadyChecks')) {
        // Only default these values to true if the store is ready
        // because if the store isn't ready, we can't be sure
        // if the values are undefined or if the store just isn't
        // ready yet
        if (!isOcpsStoreReady()) {
            logUsage('DiagnosticsAndSupport_IsOcpsStoreReady', {
                isStoreReady: false,
                entrypoint,
                isPolicyFlightEnabled: isFeatureEnabled('mon-diag-enableAggregatePolicyChecks'),
            });

            return {
                collectEmailEnabled: false,
                collectLogsEnabled: false,
                npsSurveyEnabled: false,
                screenShotEnabled: false,
                contactSupportEnabled: false,
                diagnosticsTroubleshootingEnabled: false,
                isFeedbackEnabled: false,
                isGetDiagnosticsEnabled: false,
            };
        } else {
            logUsage('DiagnosticsAndSupport_IsOcpsStoreReady', {
                isStoreReady: true,
                entrypoint,
                isPolicyFlightEnabled: isFeatureEnabled('mon-diag-enableAggregatePolicyChecks'),
            });
        }
    }

    for (const account of accounts) {
        const mailboxInfo = account.mailboxInfo;
        let isSupportPolicyEnabled;
        let isFeedbackPolicyEnabled;

        collectEmailEnabled =
            collectEmailEnabled &&
            isOcpsPolicyEnabled(OcpsPolicy.Contact, enabledIfPolicyUndefined, mailboxInfo) &&
            isFeatureEnabled('mon-diag-enableDataCollectionForFeedback');
        collectLogsEnabled =
            collectLogsEnabled &&
            isOcpsPolicyEnabled(OcpsPolicy.Content, enabledIfPolicyUndefined, mailboxInfo) &&
            isFeatureEnabled('mon-diag-enableDataCollectionForFeedback');

        npsSurveyEnabled =
            npsSurveyEnabled &&
            isOcpsPolicyEnabled(OcpsPolicy.NpsSurvey, enabledIfPolicyUndefined, mailboxInfo);
        screenShotEnabled =
            screenShotEnabled &&
            isOcpsPolicyEnabled(OcpsPolicy.ScreenShot, enabledIfPolicyUndefined, mailboxInfo) &&
            isFeatureEnabled('mon-diag-enableDataCollectionForFeedback');
        contactSupportEnabled =
            contactSupportEnabled &&
            (isSupportPolicyEnabled = isOcpsPolicyEnabled(
                OcpsPolicy.ContactSupport,
                enabledIfPolicyUndefined,
                mailboxInfo
            ));

        // TO BE DEPRECATED: DiagnosticsTroubleshooting policy
        diagnosticsTroubleshootingEnabled =
            diagnosticsTroubleshootingEnabled &&
            isOcpsPolicyEnabled(
                OcpsPolicy.DiagnosticsTroubleshooting,
                enabledIfPolicyUndefined,
                mailboxInfo
            );

        isFeedbackEnabled =
            isFeedbackEnabled &&
            (isFeedbackPolicyEnabled = isOcpsPolicyEnabled(
                OcpsPolicy.Feedback,
                enabledIfPolicyUndefined,
                mailboxInfo
            ));

        if (!isConsumer(undefined, mailboxInfo)) {
            // For consumer accounts, assume get diagnostics is true
            // do not update the isGetDiagnosticsEnabled variable in case of consumer accounts
            // NOTE: getOcpsPolicyValue returns undefined if the store isn't ready
            // and it returns "NotConfigured" if the policy is not configured
            const getDiagnosticsPolicyValue = getOcpsPolicyValue(
                OcpsPolicy.GetDiagnostics,
                mailboxInfo
            );
            if (getDiagnosticsPolicyValue == null) {
                // If policy data isn't yet available for this account, assume the policy is enabled for a new account
                // and assume it is disabled for the first account (first run of Monarch)
                if (accounts.length == 1) {
                    isGetDiagnosticsEnabled = false;
                }
            } else if (
                getDiagnosticsPolicyValue &&
                (getDiagnosticsPolicyValue == '0' || getDiagnosticsPolicyValue == '2')
            ) {
                // If the policy value is configured AND set to either 0 (completely turned off) or 2 (offline collection only),
                // or if there's nothing in the local store (probably because we haven't fetched policies for this account yet)
                // set isGetDiagnosticsEnabled to false
                // In all other cases - if it is "NotConfigured" or it is configured and set to "1" (online collection),
                // isGetDiagnosticsEnabled will be true
                isGetDiagnosticsEnabled = false;
            }
        }

        /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops -- (https://aka.ms/OWALintWiki)
         * Baseline, this function can't be used inside a loop, please move it outside
         *	> Function 'logUsage' matches forbidden pattern (/logUsage/) and should not be used inside loops */
        logUsage('DiagnosticsAndSupport_IsSupportPolicyEnabled', {
            accountType: mailboxInfo.type,
            isSupportPolicyEnabled,
            entrypoint,
            isPolicyFlightEnabled: isFeatureEnabled('mon-diag-enableAggregatePolicyChecks'),
        });

        /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops -- (https://aka.ms/OWALintWiki)
         * Baseline, this function can't be used inside a loop, please move it outside
         *	> Function 'logUsage' matches forbidden pattern (/logUsage/) and should not be used inside loops */
        logUsage('DiagnosticsAndSupport_IsFeedbackPolicyEnabled', {
            accountType: mailboxInfo.type,
            isFeedbackPolicyEnabled,
            entrypoint,
            isPolicyFlightEnabled: isFeatureEnabled(
                'mon-diag-enableAggregatePolicyCheckForFeedback'
            ),
        });
    }

    return {
        collectEmailEnabled,
        collectLogsEnabled,
        npsSurveyEnabled,
        screenShotEnabled,
        contactSupportEnabled,
        diagnosticsTroubleshootingEnabled,
        isFeedbackEnabled,
        isGetDiagnosticsEnabled,
    };
}
