import { observer } from 'owa-mobx-react';
import MailListSelectAll from './MailListSelectAll';
import MailListSelectAllSLV from './MailListSelectAllSLV';
import { MailListFilterMenu } from 'owa-mail-list-filter-view';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import React from 'react';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import {
    headerIconContainer,
    headerIconContainerFull,
    headerIconContainerMediumOrCompact,
} from './MailListHeader.scss';
import { isSingleLineListView } from 'owa-mail-layout';
import { MailListSortMenuV2 } from 'owa-mail-list-sort-view';
import { MailListJumpToPopover, shouldShowJumpTo } from 'owa-mail-list-jumpto-view';
import { isFeatureEnabled } from 'owa-feature-flags';
import { LazyCopilotDigestButton } from 'owa-mail-copilot-digest';
import type { MailFolderTableQuery } from 'owa-mail-list-store';
import { isDigestFilter } from 'owa-mail-list-store';

import classnames from 'owa-classnames';

export interface MailListHeaderIconContainerProps {
    tableView: any;
    supportedViewFilters: ViewFilter[];
    filterMenuSource: any;
    tableViewId: string;
}
export default observer(function MailListHeaderIconContainer(
    props: MailListHeaderIconContainerProps
) {
    const { tableView, supportedViewFilters, filterMenuSource, tableViewId } = props;

    if (!tableView) {
        return;
    }

    if (isDigestFilter(tableView.tableQuery as MailFolderTableQuery)) {
        return null;
    }

    return (
        <div
            className={classnames(
                headerIconContainer,
                getDensityModeCssClass(
                    headerIconContainerFull,
                    headerIconContainerMediumOrCompact,
                    headerIconContainerMediumOrCompact
                )
            )}
        >
            {!isFeatureEnabled('tri-reselect-checkbox-behavior') &&
                (isSingleLineListView() ? (
                    <MailListSelectAllSLV tableView={tableView} />
                ) : (
                    <MailListSelectAll tableView={tableView} />
                ))}
            <LazyCopilotDigestButton tableViewId={tableViewId} />
            {filterMenuSource !== 'GroupHeader' && shouldShowJumpTo(tableView) && (
                <MailListJumpToPopover tableViewId={tableViewId} />
            )}
            {filterMenuSource !== 'GroupHeader' && (
                <MailListFilterMenu
                    filterMenuSource={filterMenuSource}
                    supportedViewFilters={supportedViewFilters}
                    tableViewId={tableViewId}
                />
            )}
            {filterMenuSource !== 'GroupHeader' && (
                <MailListSortMenuV2 sortMenuSource={filterMenuSource} tableViewId={tableViewId} />
            )}
        </div>
    );
},
'MailListHeaderIconContainer');
