import { type SortColumn } from '../store/schema/SortBy';
import type SortBy from '../store/schema/SortBy';
import { type TableQueryType } from '../store/schema/TableQuery';

import type {
    SortColumn as GqlSortColumn,
    ViewFilter as GqlViewFilter,
    FocusedViewFilter as GqlFocusedViewFilter,
    MessageSenderScreeningFilter as GqlMessageSenderScreeningFilter,
    ReactListViewType as GqlReactListViewType,
    TableQueryType as GqlTableQueryType,
    PagingInfoInput as GqlPagingInfo,
    RestrictionPropertyType,
    RestrictionComparisonType,
    PagingOriginType,
} from 'owa-graph-schema';
import { assertNever } from 'owa-assert';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type MessageSenderScreeningFilter from 'owa-service/lib/contract/MessageSenderScreeningFilter';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';

export function getGQLPagingInfoForQueryRows(
    numberOfRowsToFetch: number,
    isConversationView: boolean,
    instanceKey?: string,
    pageOrigin: PagingOriginType = 'Beginning',
    offset: number = 0
): GqlPagingInfo {
    const pageInfo: GqlPagingInfo = {
        numberOfRows: numberOfRowsToFetch,
        pageOrigin,
        offset,
    };

    if (instanceKey) {
        pageInfo.restriction = {
            restrictionComparisonType: 'isEqual',
            restrictionProperty: isConversationView ? 'ConversationInstanceKey' : 'InstanceKey',
            restrictionValue: instanceKey,
        };
    }

    return pageInfo;
}

export function getGQLPagingInfoForQueryRowsForJumpTo(
    numberOfRowsToFetch: number,
    isConversationView: boolean,
    matchingString: string,
    sortBy: SortBy
): GqlPagingInfo {
    const pageInfo: GqlPagingInfo = {
        numberOfRows: numberOfRowsToFetch,
    };

    if (matchingString) {
        pageInfo.restriction = {
            restrictionComparisonType: getRestrictionComparisonTypeForQueryRows(sortBy),
            restrictionProperty: getRestrictionPropertyForQueryRows(isConversationView, sortBy),
            restrictionValue: matchingString,
        };
    }

    return pageInfo;
}

// Update this as we support more sort columns in the Jump to feature
export function getRestrictionComparisonTypeForQueryRows(
    sortBy: SortBy
): RestrictionComparisonType {
    if (sortBy.sortColumn === 5) {
        return 'isEqual';
    } else if (
        (sortBy.sortColumn === 1 || sortBy.sortColumn === 7) &&
        sortBy.sortDirection === 'Descending'
    ) {
        return 'isLessThan';
    } else {
        return sortBy.sortDirection === 'Ascending' ? 'isGreaterOrEqual' : 'isLessOrEqual';
    }
}

// Note that the RestrictionPropertyTypes returned by this function must match the sort order of the table in order for us to seek on it.
export function getRestrictionPropertyForQueryRows(
    isConversationView: boolean,
    sortBy: SortBy
): RestrictionPropertyType {
    switch (sortBy.sortColumn) {
        case 3:
            return isConversationView ? 'ConversationUniqueSenders' : 'From';
        case 8:
            return isConversationView ? 'ConversationTopic' : 'Subject';
        case 5:
            return isConversationView ? 'ConversationImportance' : 'Importance';
        case 1:
            if (sortBy.sortDirection === 'Descending') {
                return isConversationView
                    ? 'ConversationLastDeliveryOrRenewTime'
                    : 'ReceivedOrRenewTime';
            } else {
                return isConversationView ? 'ConversationLastDeliveryTime' : 'DateTimeReceived';
            }
        case 14:
            return 'Categories';
        case 7:
            return isConversationView ? 'ConversationSize' : 'Size';
        default:
            return isConversationView ? 'ConversationInstanceKey' : 'InstanceKey';
    }
}

export function getGqlSortColumnFromOwsSortColumn(sortColumn: SortColumn): GqlSortColumn {
    switch (sortColumn) {
        case 1:
            return 'Date';
        case 3:
            return 'From';
        case 5:
            return 'Importance';
        case 7:
            return 'Size';
        case 8:
            return 'Subject';
        case 14:
            return 'Category';
        case 9:
            return 'To';
        case 15:
            return 'ReturnTime';
        case 16:
            return 'CopilotHighPriority';
        default:
            return assertNever(sortColumn);
    }
}

export function getGqlViewFilterFromOwsViewFilter(viewFilter: ViewFilter | null): GqlViewFilter {
    switch (viewFilter) {
        case 'All':
        case 'Flagged':
        case 'HasAttachment':
        case 'ToOrCcMe':
        case 'Unread':
        case 'UserCategory':
        case 'Focused':
        case 'Mentioned':
        case 'HasFile':
        case 'HasCalendarInvite':
        case 'CopilotHighPriority':
            return viewFilter;
        default:
            assertNever(viewFilter as never);
    }
}

export function getGqlMessageSenderScreeningFilterFromOwsMessageSenderScreeningFilter(
    messageSenderScreeningFilter: MessageSenderScreeningFilter
): GqlMessageSenderScreeningFilter {
    switch (messageSenderScreeningFilter) {
        case 'None':
        case 'ScreenedSenders':
        case 'UnscreenedSenders':
            return messageSenderScreeningFilter;
        default:
            assertNever(messageSenderScreeningFilter as never);
    }
}

export function getGqlFocusedViewFilterFromOwsFocusedViewFilter(
    focusedViewFilter: FocusedViewFilter
): GqlFocusedViewFilter {
    switch (focusedViewFilter) {
        case -1:
            return 'None';
        case 0:
            return 'Focused';
        case 1:
            return 'Other';
        default:
            assertNever(focusedViewFilter);
    }
}

export function getGqlReactListViewTypeFromOwsReactListViewType(
    reactListViewType: ReactListViewType
): GqlReactListViewType {
    switch (reactListViewType) {
        case 0:
            return 'Conversation';
        case 1:
            return 'Message';
        case 2:
            return 'CalendarItems';
        default:
            assertNever(reactListViewType);
    }
}

export function getGqlTableQueryTypeFromOwaTableQueryType(type: TableQueryType): GqlTableQueryType {
    switch (type) {
        case 0:
            return 'Folder';
        case 1:
            return 'Search';
        case 2:
            return 'Group';
        case 3:
            return 'GroupFolder';
        default:
            assertNever(type);
    }
}
