import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { lazyGetDefaultRibbonControlInMenuRendererFunction } from 'owa-acui-lazy';
import { appIcons, getAppStrings } from 'owa-acui-theme';
import type { ControlInMenuRendererFunction } from 'owa-acui';

const getEmptyControlInMenuRendererFunction: () => ControlInMenuRendererFunction = () => () => [];

/**
 * Custom hook to lazy load the in-menu renderer function.
 */
export const useControlInMenuRendererFunction = (
    skipResultMemoization: boolean
): ControlInMenuRendererFunction => {
    return useComputedValue(() => {
        const getInMenuRenderer =
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * BASELINE. Do not copy and paste!
             *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
            lazyGetDefaultRibbonControlInMenuRendererFunction.tryImportForRender();

        // Get the renderer function or fallback to empty renderer function
        const rendererFunction = getInMenuRenderer
            ? getInMenuRenderer(appIcons, getAppStrings(), skipResultMemoization)
            : getEmptyControlInMenuRendererFunction();

        // Ensure that we are passing the necessary parameters and invoke the renderer function
        return parameters => rendererFunction(parameters);
    }, [skipResultMemoization]);
};
