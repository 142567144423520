import type { MailboxInfo } from 'owa-client-types';
import { getIdentityKeyForMailboxInfo } from 'owa-auth-types';

/**
 * Determines if the specified MailboxInfo's are the same identity for authentication
 * @param first The first MailboxInfo to be compared
 * @param second The second MailboxInfo to be compared
 * @returns True if they MailboxInfo's are the same identity for authentication, false otherwise
 */
export function isSameIdentityKeyForMailboxInfos(first: MailboxInfo, second: MailboxInfo): boolean {
    // the IdentityKey provides unique values for the user's identity and the provider for the user identity
    const firstIdentityKey = getIdentityKeyForMailboxInfo(first);
    const secondIdentityKey = getIdentityKeyForMailboxInfo(second);
    return (
        firstIdentityKey !== undefined &&
        secondIdentityKey !== undefined &&
        firstIdentityKey === secondIdentityKey
    );
}
