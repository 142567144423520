import type {
    default as PopoutData,
    DeeplinkPopoutData,
    ExtractedPopoutData,
} from '../store/schema/PopoutData';
import { getStore } from '../store/parentStore';
import { logUsage } from 'owa-analytics';

export default function extractData(
    data: DeeplinkPopoutData | PopoutData | undefined | null
): ExtractedPopoutData {
    let projectionTabId: string | undefined;
    let deeplinkData: DeeplinkPopoutData | undefined | null;
    let projectionTargetWindow: Window | undefined;
    let projectionParentWindow: Window | undefined;
    if (data) {
        if (isPopoutData(data)) {
            deeplinkData = data.deeplinkData;
            if (getStore().isAvailable) {
                projectionTabId = data.projectionTabId;
            } else {
                logUsage('ProjectionUnavailable');
            }
            projectionTargetWindow = data.projectionTargetWindow;
            projectionParentWindow = data.projectionParentWindow;
        } else {
            deeplinkData = data;
        }
    }

    let deeplinkCallback: () => Promise<string | object | undefined | null>;
    if (typeof deeplinkData == 'undefined') {
        deeplinkCallback = () => Promise.resolve(null);
    } else if (typeof deeplinkData === 'function') {
        deeplinkCallback = deeplinkData as () => Promise<object | undefined | null>;
    } else {
        deeplinkCallback = () => Promise.resolve(deeplinkData);
    }

    return {
        deeplinkCallback,
        projectionTabId,
        projectionTargetWindow,
        projectionParentWindow,
    };
}

function isPopoutData(data: DeeplinkPopoutData | PopoutData): data is PopoutData {
    return (
        typeof (<PopoutData>data).deeplinkData != 'undefined' ||
        !!(<PopoutData>data).projectionTabId ||
        !!(<PopoutData>data).projectionTargetWindow
    );
}
