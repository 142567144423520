import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () =>
        import(
            /* webpackChunkName: "UserConfiguration"*/ './operation/updateUserConfigurationOperation'
        ),
    { name: 'UserConfiguration' }
);

export const lazyUpdateUserConfigurationOperation = new LazyAction(
    lazyModule,
    m => m.updateUserConfigurationOperation
);
