import type { SearchTableQuery } from 'owa-mail-list-search';
import type { TableQuery, GroupFolderTableQuery } from 'owa-mail-list-store';
import { type TableQueryType } from 'owa-mail-list-store';
import type { SingleGroupSearchScope } from 'owa-search-service';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function isGroupTableQuery(tableQuery: TableQuery): boolean {
    if (!tableQuery) {
        return false;
    }

    return (
        tableQuery.type == 2 ||
        isGroupSearch(tableQuery) ||
        (tableQuery.type == 3 && isFeatureEnabled('grp-loadFolders'))
    );
}

export function isGroupSearch(tableQuery: TableQuery): boolean {
    if (!tableQuery || tableQuery.type != 1) {
        return false;
    }

    const searchTableQuery = tableQuery as SearchTableQuery;
    return searchTableQuery.searchScope.kind == SearchScopeKind.Group;
}

export function getGroupIdFromTableQuery(tableQuery: TableQuery) {
    if (tableQuery.type == 2) {
        return tableQuery.folderId;
    }

    if (isFeatureEnabled('grp-loadFolders') && tableQuery.type == 3) {
        return (tableQuery as GroupFolderTableQuery).groupId;
    }

    if (isGroupSearch(tableQuery)) {
        const singleGroupSearchScope = (tableQuery as SearchTableQuery)
            .searchScope as SingleGroupSearchScope;
        return singleGroupSearchScope.groupId;
    }

    return null;
}
