import { showAccountConfigurationDialog } from 'owa-account-config/lib/actions/showAccountConfigurationDialog';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import {
    getAddAccountOriginContextData,
    stripContextDataFromQueryParam,
} from 'owa-add-account-context/lib/utils/addAccountOriginContextData';
import { lazyAddAccountsToPendingList } from 'owa-add-account-context';

export default function addAccountDialogRouteHandler() {
    const { origin, fallbackData, originContext } = getAddAccountOriginContextData() || {
        origin: 'Referral', // Since this route can only be reached via referral.
    };

    if (isMonarchMultipleAccountsEnabled()) {
        const accountSmtp = fallbackData?.smtpAddress;

        if (accountSmtp) {
            // If the accountSmtp is present, then we need to add the account to the pending list and kick off the remediation.
            lazyAddAccountsToPendingList.importAndExecute(
                [{ smtp: accountSmtp, type: fallbackData.accountType }],
                origin,
                originContext,
                true /* kickOffRemediation */
            );
        } else {
            // Otherwise, we need to show the account config dialog.
            showAccountConfigurationDialog({
                dialogTrigger: origin,
                targetWindow: undefined,
                fallbackData,
                originContext,
            });
        }
    }

    stripContextDataFromQueryParam();
}
