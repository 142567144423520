import type CategoryType from 'owa-service/lib/contract/CategoryType';
import type { FavoriteCategoryData, FavoriteFolderData } from 'owa-favorites-types';
import type { MailFolder } from 'owa-graph-schema';
import { getEffectiveFolderDisplayName } from 'owa-folders';
import type { MailboxInfo } from 'owa-client-types';
import { SEARCH_FOLDER_TYPE_NAME } from 'owa-folders-constants';
import { isLocalFolderFavoriteSupportedForFolder } from './isLocalFolderFavoriteSupported';
import { getOutlookFavoriteTypeFromMailboxInfo } from './getUniqueDataForFavoriteType';

export function createClientFavoriteCategoryData(
    favoriteId: string,
    category: CategoryType,
    isMigration: boolean,
    mailboxInfo: MailboxInfo
): FavoriteCategoryData {
    return {
        treeType: 'favorites',
        type: 'category', // VSO 26094: Replace the custom string with server defined supported type
        favoriteId,
        displayName: category.Name ?? '',
        categoryId: category.Id ?? '',
        client: isMigration ? 'Migration' : 'OWA', // VSO 26094: Replace the custom string with server defined supported type
        mailboxInfo,
    };
}

export function createClientFavoriteFolderData(
    favoriteId: string,
    folder: MailFolder,
    isMigration: boolean
): FavoriteFolderData {
    const isLocalFolderFavorite = isLocalFolderFavoriteSupportedForFolder(folder);
    return {
        treeType: 'favorites',
        type: isLocalFolderFavorite
            ? getOutlookFavoriteTypeFromMailboxInfo(folder.mailboxInfo)
            : 'folder',
        favoriteId,
        displayName: isLocalFolderFavorite
            ? getEffectiveFolderDisplayName(folder)
            : folder.displayName,
        folderId: folder.id,
        client: isMigration ? 'Migration' : 'OWA',
        mailboxInfo: folder.mailboxInfo,
        isSearchFolder: folder.type === SEARCH_FOLDER_TYPE_NAME,
    };
}
