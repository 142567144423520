import { isFeatureEnabled } from 'owa-feature-flags';
import getOptionRouteState from 'owa-options-view/lib/utils/getOptionRouteState';
import { OPTION_ROUTE_KEYWORD } from './optionRoutes';

export default function getCurrentOptionRoute(): string[] {
    let route: string[] = [];

    // In OWA, Settings is opened in a dialog, and we append Options route to the URL,
    //    so that if users refresh the page, they will be taken to the same Options page.
    // In Monarch, we open Settings in Projection Popout, and the projection and main window
    //    don't necessarily need to know about the Options route.
    // So, when it is in projection popout, we just return an empty route.
    const isProjectionPopout = isFeatureEnabled('acct-fullOptionsProjectionPopout');

    const optionState = getOptionRouteState();
    if (
        !isProjectionPopout &&
        optionState.isFullOptionsShown &&
        optionState.areAllAllowedSubCategoriesLoaded &&
        optionState.currentCategoryKey &&
        optionState.currentSubCategoryKey
    ) {
        route = [
            OPTION_ROUTE_KEYWORD,
            optionState.currentCategoryKey,
            optionState.currentSubCategoryKey,
        ];

        if (optionState.currentOptionKey) {
            route.push(optionState.currentOptionKey);
        }

        if (optionState.currentOptionSubKey) {
            route.push(optionState.currentOptionSubKey);
        }
    }

    return route;
}
