import { type ListViewTypeRestrictionType } from '../Behaviors.types';
import { assertNever } from 'owa-assert';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getSafeSelectedTableView } from 'owa-mail-list-store';
import { isReadingPaneStackedView } from 'owa-mail-organization-settings/lib/utils/readingPaneStackedViewUtils';

export const listViewTypeRestrictionType = (restrictionType: ListViewTypeRestrictionType) => () => {
    const tableView = getSafeSelectedTableView();
    const listViewType = tableView?.tableQuery?.listViewType;
    switch (restrictionType) {
        case 2:
            return listViewType === 1 || !isReadingPaneStackedView();

        case 1:
            return listViewType === 0 && isReadingPaneStackedView();

        default:
            throw assertNever(restrictionType);
    }
};
