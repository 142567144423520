import { observer } from 'owa-mobx-react';
import { clearSelection } from './MailListHeaderSelectionRowContent.locstring.json';
import loc from 'owa-localize';
import React from 'react';
import { Link } from '@fluentui/react/lib/Link';
import { resetSelection } from 'owa-mail-actions/lib/mailListSelectionActions';
import { getItemsOrConversationsSelectedText, getIsSearchTableShown } from 'owa-mail-list-store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { isSingleLineListView } from 'owa-mail-layout';
import { resetFocus } from 'owa-mail-focus-manager';
import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import { isFocusedInboxEnabled } from 'owa-mail-triage-common';
import { getMailboxInfoFromFolderId } from 'owa-mail-mailboxinfo/lib/getMailboxInfo';

import {
    customSelectionContainerNoSenderImage,
    customSelectionContainer,
    clearSelectionButton,
    clearSelectionButtonSearch,
} from './MailListHeaderSecondRow.scss';

import {
    mailListSecondRowCustomContainer,
    mailListSecondRowCustomContainerSearchTable,
    full,
    medium,
    compact,
} from '../MailListHeader.scss';

import classnames from 'owa-classnames';

export interface MailListHeaderSelectionRowContentProps {
    numChecked: number;
    containerCssClass: string;
    tableViewId: string;
    folderId: string;
}

export default observer(function MailListHeaderSelectionRowContent(
    props: MailListHeaderSelectionRowContentProps
) {
    const resetSelection_0 = React.useCallback(() => {
        resetSelection(
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2345 (38,13): Argument of type 'TableView | undefined' is not assignable to parameter of type 'TableView'.
            // @ts-expect-error
            getListViewStore().tableViews.get(props.tableViewId),
            MailListItemSelectionSource.SingleLineClearSelection
        );
        resetFocus('MailListHeaderResetSelection');
    }, [props.tableViewId]);
    const mailboxInfo = getMailboxInfoFromFolderId(props.folderId);
    const isSingleLineView = isSingleLineListView();
    const isSenderImageOff = getIsBitSet(8, mailboxInfo);
    const isJunkMail = 'junkemail' === folderIdToName(props.folderId);
    const isInboxWithPivots =
        isFocusedInboxEnabled(mailboxInfo) && 'inbox' === folderIdToName(props.folderId);
    const densityModeString = getDensityModeCssClass(full, medium, compact);
    const itemsOrConversationsText = getItemsOrConversationsSelectedText(props.tableViewId);
    return (
        <div
            className={classnames(
                mailListSecondRowCustomContainer,
                getIsSearchTableShown() && mailListSecondRowCustomContainerSearchTable,
                densityModeString
            )}
            role="heading"
            aria-level={2}
        >
            <span
                className={
                    isSingleLineView && isSenderImageOff && !isJunkMail
                        ? customSelectionContainerNoSenderImage
                        : customSelectionContainer
                }
                title={itemsOrConversationsText}
            >
                {itemsOrConversationsText}
            </span>
            <Link
                className={isInboxWithPivots ? clearSelectionButton : clearSelectionButtonSearch}
                onClick={resetSelection_0}
            >
                {loc(clearSelection)}
            </Link>
        </div>
    );
},
'MailListHeaderSelectionRowContent');
