import type { RibbonControlGroupDefinitionSLR, RibbonTabDefinitionWithStyles } from 'owa-acui';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, SingleLine, getTabStyles } from 'owa-command-ribbon';
import loc from 'owa-localize';
import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import { getMailRibbonConfigStore } from 'owa-mail-ribbon-store/lib/store/store';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getUniqueTabId } from 'owa-mail-ribbon-utils/lib/getUniqueTabId';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getInitialScalingSteps } from '../../../util/getInitialScalingSteps';
import { getScalingSteps } from '../../../util/getScalingSteps';
import { getTabGroups } from '../../../util/getTabGroups';
import { getSLRHomeTabControlDefinition } from './getSLRHomeTabControlDefinition';
import {
    controlsDontDropLabels,
    controlsDontMoveToOverflow,
    controlsWithoutLabels,
    controlsFirstMoveToOverflow,
} from './slrHomeTabScalingSpecialCases';

const getSLRHomeTab = owaComputedFn(function getSLRHomeTab(
    props: ReadOnlyRibbonControlDefProps
): RibbonTabDefinitionWithStyles {
    return {
        type: SingleLine,
        id: getUniqueTabId(1, undefined /* editorId */, props.itemId, props.isPopout),
        title: props.itemId ? loc(Strings.message_tab) : loc(Strings.home_tab),
        controlGroups: { type: SingleLine, groups: getHomeTabGroups(props) },
        initialScalingSteps: getInitialScalingSteps(
            getMailRibbonConfigStore(props.mailboxInfo).singleline.homeTab,
            controlsWithoutLabels,
            undefined /*editorId*/,
            undefined /*itemId */
        ),
        scalingSteps: getScalingSteps(
            getMailRibbonConfigStore(props.mailboxInfo).singleline.homeTab.layout,
            getMailRibbonConfigStore(props.mailboxInfo).singleline.homeTab.showLabelsPreference,
            controlsDontMoveToOverflow,
            controlsFirstMoveToOverflow,
            controlsDontDropLabels,
            undefined /*editorId*/,
            undefined /*itemId */
        ),
        styles: getTabStyles(),
        keytipProps: constructKeytip([], 1, true /* hasMenu */),
    };
});
export default getSLRHomeTab;

/**
 * Retrieves the groups within the home tab, as well as the actions that
 * fall under each group.
 * @returns an object that contains the sub-groups and corresponding actions of the
 * home tab
 */
const getHomeTabGroups = owaComputedFn(function getHomeTabGroups(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlGroupDefinitionSLR[] {
    let homeTabGroups: RibbonControlGroupDefinitionSLR[] = new Array();
    homeTabGroups = getTabGroups(
        1,
        getMailRibbonConfigStore(props.mailboxInfo).singleline.homeTab.layout,
        getSLRHomeTabControlDefinition,
        props
    );
    return homeTabGroups;
});
