import { observer } from 'owa-mobx-react';
import { default as SelectAllOn } from 'owa-fluent-icons-svg/lib/icons/SelectAllOnRegular';
import { default as SelectAllOff } from 'owa-fluent-icons-svg/lib/icons/SelectAllOffRegular';
import { FluentButton } from 'owa-fluent-v9-shims';
import toggleSelectAllState from '../mutators/toggleSelectAllState';
import resetSelectAllState from '../mutators/resetSelectAllState';
import tableExitVirtualSelectAllMode from 'owa-mail-list-selection-actions-v2/lib/internal/mutators/tableExitVirtualSelectAllMode';
import setTableIsInCheckedMode from 'owa-mail-list-selection-actions-v2/lib/internal/mutators/setTableIsInCheckedMode';
import { getDensityModeCssClass, getDensityModeString } from 'owa-fabric-theme';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import loc from 'owa-localize';
import { selectLabel } from 'owa-locstrings/lib/strings/selectlabel.locstring.json';
import { resetSelection } from 'owa-mail-actions/lib/mailListSelectionActions';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import React from 'react';
import {
    selectAllIconFull,
    selectAllIconMediumAndCompact,
    full,
    medium,
    compact,
    selectAllCheckboxButton,
    selectAllCheckboxButtonMedium,
    selectAllCheckboxButtonCompact,
    buttonChecked,
} from './MailListHeader.scss';

import classnames from 'owa-classnames';

export interface MailListSelectAllProps {
    tableView: any;
}

export default observer(function MailListSelectAll(props: MailListSelectAllProps) {
    const { tableView } = props;
    const isFullDensity = getDensityModeString() === 'full';

    const iconButtonProps = useComputedValue(() => {
        return {
            iconName:
                tableView.isInSelectAllState || tableView.isInCheckedMode
                    ? SelectAllOn
                    : SelectAllOff,
            className: classnames(
                isFullDensity ? selectAllIconFull : selectAllIconMediumAndCompact
            ),
        };
    }, [tableView.isInSelectAllState, tableView.isInCheckedMode, isFullDensity]);

    const onCheckboxClick = React.useCallback(() => {
        // Overrides select checkbox on left of mail list header or single mail item checkbox checked - removes from Multi Select state when unchecked
        if (tableView.isInVirtualSelectAllMode || tableView.isInCheckedMode) {
            tableExitVirtualSelectAllMode(tableView);
            setTableIsInCheckedMode(tableView, false);
            resetSelectAllState(tableView);
            resetSelection(tableView, MailListItemSelectionSource.Reset);
        } else {
            toggleSelectAllState(tableView);
        }
    }, [tableView]);

    React.useEffect(() => {
        resetSelectAllState(tableView);
    }, [tableView]);

    React.useEffect(() => {
        if (!tableView.isInCheckedMode) {
            resetSelectAllState(tableView);
        }
    }, [tableView.isInCheckedMode]);

    const onCheckboxKeyDown = React.useCallback(
        (event: React.KeyboardEvent<unknown>) => {
            if (event.key === 'Escape') {
                resetSelectAllState(tableView);
            }
        },
        [tableView]
    );

    return (
        <div className={classnames(getDensityModeCssClass(full, medium, compact))}>
            <TooltipHost content={loc(selectLabel)}>
                <FluentButton
                    appearance="icon"
                    className={classnames(
                        selectAllCheckboxButton,
                        (tableView.isInSelectAllState || tableView.isInCheckedMode) &&
                            buttonChecked,
                        getDensityModeCssClass(
                            undefined,
                            selectAllCheckboxButtonMedium,
                            selectAllCheckboxButtonCompact
                        )
                    )}
                    iconProps={iconButtonProps}
                    onClick={onCheckboxClick}
                    onKeyDown={onCheckboxKeyDown}
                    aria-label={loc(selectLabel)}
                ></FluentButton>
            </TooltipHost>
        </div>
    );
}, 'MailListSelectAll');
