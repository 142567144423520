import { ObservableSet } from 'mobx';
import type { AccountKey, MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { createStore } from 'owa-satcheljs';
import type { NotificationsFilterValues } from '../components/NotificationsFilter';
import type { ActivityFeedItem } from '../service/ActivityFeedItem';
import type { FeedAction } from './feedAction';
import { feedAction } from './feedAction';
import type { TraceErrorObject } from 'owa-trace';

export interface ActivityFeedStore {
    feedAction: FeedAction;
    isActivityFeedOpen: boolean;
    isActivityFeedSettingOpen: boolean;
    isOpx: boolean;
    accountItems: Map<AccountKey, ActivityFeedAccountStore>; // Account key to store of feed items for the account
    focusedItemId: string;
    notificationsDisabled: boolean;
    currentAccountId: string;
}

export interface ActivityFeedAccountStore {
    firstFetchComplete: boolean;
    currentlyRenderedItemIds: ObservableSet<string>; // Filtered list of feed item IDs that are visible
    items: Map<string, ActivityFeedItem>; // Full feed item info
    unseenActivityCount: number;
    loadSucceeded: boolean;
    batchCount: number;
    lastSeenTimestamp: string | null;
    canShowNoNewNotificationsUI: boolean;
    cardFilters: ObservableSet<NotificationsFilterValues>;
}

const activityFeedStore = createStore<ActivityFeedStore>('activityFeedStore', {
    feedAction: feedAction(),
    isActivityFeedOpen: false,
    isActivityFeedSettingOpen: false,
    isOpx: false,
    accountItems: new Map<AccountKey, ActivityFeedAccountStore>(),
    focusedItemId: '',
    notificationsDisabled: false,
    currentAccountId: '',
})();
export const getActivityFeedStore = (): ActivityFeedStore => activityFeedStore;

export const createFeedAccountStore = (accountStoreId: string): ActivityFeedAccountStore => {
    return createStore<ActivityFeedAccountStore>(accountStoreId, {
        firstFetchComplete: false,
        currentlyRenderedItemIds: new ObservableSet<string>(),
        items: new Map<string, ActivityFeedItem>(),
        unseenActivityCount: 0,
        loadSucceeded: false,
        batchCount: 1,
        lastSeenTimestamp: null,
        canShowNoNewNotificationsUI: true,
        cardFilters: new ObservableSet<NotificationsFilterValues>(),
    })();
};

export const getActivityFeedAccountStore = (mailboxInfo: MailboxInfo): ActivityFeedAccountStore => {
    const mailboxIndex = getIndexerValueForMailboxInfo(mailboxInfo);
    const accountStore = activityFeedStore.accountItems.get(mailboxIndex);
    if (!accountStore) {
        const err: TraceErrorObject = new Error(
            'getActivityFeedAccountStore is called for an account where initializeNotificationsPaneForAccount has not been called.'
        );
        err.additionalInfo = { diagnosticMessage: mailboxInfo.mailboxSmtpAddress };
        throw err;
    }
    return accountStore;
};

export const getActivityFeedItem = (
    itemId: string,
    mailboxInfo: MailboxInfo
): ActivityFeedItem | undefined => {
    const accountStore = getActivityFeedAccountStore(mailboxInfo);
    const feedItem = accountStore.items.get(itemId);
    return feedItem;
};
