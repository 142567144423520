import type { default as AdvancedSearchViewState } from '../types/AdvancedSearchViewState';

export default function getDefaultAdvancedSearchViewState(): AdvancedSearchViewState {
    return {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (22,9): Type 'null' is not assignable to type 'SearchScope'.
        // @ts-expect-error
        selectedSearchScope: null,
        subjectFieldText: '',
        keywordsFieldText: '',
        fromDate: undefined,
        toDate: undefined,
        hasAttachments: false,
        fromPeopleSuggestions: [],
        toPeopleSuggestions: [],
        ccPeopleSuggestions: [],
        bccPeopleSuggestions: [],
        isRead: 'None',
        isFlagged: false,
        importance: 'None',
        bodyFieldText: '',
        category: 'None',
    };
}
