import { getTokenCache } from '../getTokenCache';
import type { TokenRequestParams } from '../schema/TokenRequestParams';

export function getCachedTokenIfAvailable(tokenRequestParams: TokenRequestParams) {
    const tokenCache = getTokenCache();
    const cacheKey = tokenCache.getCacheKey(tokenRequestParams);
    const cachedToken = tokenCache.getCachedToken(cacheKey);

    // Check if the cached token is expired
    if (cachedToken?.AccessTokenExpiry) {
        const tokenExpirationTime = new Date(cachedToken.AccessTokenExpiry).getTime();
        if (tokenExpirationTime <= Date.now()) {
            return;
        }
    }

    return cachedToken;
}
