/**
 * This function returns the UI depth of a tree node in pixels. Although a node might be at depth 1,
 * it should be aligned to the root node at depth 0 per redlines. Thus, all other levels should
 * also be decreased by 1 level for the UI only.
 * @param hasIconsForAllFolders A boolean passed in if all folders have icons.
 */
export function getUITreeNodeDepth(depth: number, densityModeString?: string): number {
    return Math.max(0, depth - 1) * getDepthFactor(densityModeString);
}

function getDepthFactor(densityModeString?: string): number {
    switch (densityModeString) {
        case 'full':
            return Math.round(26 / 3);
        case 'medium':
            return Math.round(26 / 3);
        case 'compact':
        default:
            return Math.round(20 / 3);
    }
}

/**
 * Returns the right side padding for a tree node (in LTR mode) and left side padding (in RTL mode)
 */
export function getTreeNodesFarPadding() {
    return '8px';
}
