import { createLazyComponent, LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "InAppNotifications"*/ './lazyIndex'),
    { name: 'InAppNotifications' }
);

export const lazyLaunchTaskReminder = new LazyAction(lazyModule, m => m.launchTaskReminder);

export const NotificationPane = createLazyComponent(lazyModule, m => m.NotificationPane);
export const ProjectionRemindersPane = createLazyComponent(
    lazyModule,
    m => m.ProjectionRemindersPane
);
