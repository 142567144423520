import getOverallPremiumStatusStore from '../store/overallPremiumStatusStore';
import { mutatorAction } from 'satcheljs';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setOverallPremiumStatusBit = mutatorAction(
    'SetOverallPremiumStatusBit',
    (overallPremiumBit: number) => {
        // Set the overallPremiumBit to trigger the store change
        // 2 -- means the premium status
        // 1 -- means the non-premium status
        // -1 -- (default value) means the premium status is not set
        getOverallPremiumStatusStore().overallPremiumBit = overallPremiumBit;
    }
);
