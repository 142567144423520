import type SearchScenarioId from 'owa-search-store/lib/store/schema/SearchScenarioId';
import { createStore } from 'owa-satcheljs';
import type { default as RefinersState, SearchRefinersState } from './schema/SearchRefinersState';

export const createDefaultRefinersStore = (): SearchRefinersState => {
    return {
        appliedRefiners: [],
        suggestedRefiners: [],
        selectedQfRefiners: [],
        refinersInstrumentationContext: undefined,
    };
};

const refinersState: RefinersState = {
    refiners: new Map<SearchScenarioId, SearchRefinersState>([]),
};

export const getSearchRefinersStore = createStore<RefinersState>(
    'searchRefinersStore',
    refinersState
);
