import { mutatorAction } from 'satcheljs';
import type { MailboxFolderTreeData } from '../store/schema/FolderStore';
import getStore from '../store/store';
import { getAccountKeyForMailboxInfo, type MailboxInfo } from 'owa-client-types';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setMailboxFolderTreeData = mutatorAction(
    'setMailboxFolderTreeData',
    (mailboxInfo: MailboxInfo, mailboxFolderTreeData: MailboxFolderTreeData) => {
        getStore().mailboxFolderTreeData.set(
            getAccountKeyForMailboxInfo(mailboxInfo),
            mailboxFolderTreeData
        );
    }
);
