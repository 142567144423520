import { createStore } from 'owa-satcheljs';
import { DialogContentType } from './schema/Dialog';
import type TaskModule from './schema/TaskModule';
import type { DialogInfo } from './schema/Dialog';
import type { Instances, InstanceStore } from './schema/TaskModuleInstances';

// todo: rename to getTaskModulesStore
export const taskModules = createStore<Instances>('taskModuleInstances', {
    instances: new Map<string, InstanceStore>(),
});

export const instanceFactory = (id: string): InstanceStore =>
    createStore<TaskModule>(`taskModuleInstance_${id}`, {
        isOpen: false,
        dialogInfo: {} as DialogInfo,
        userClickTime: 0,
        contentType: DialogContentType.url,
        onSubmit: () => {},
        onDismiss: () => {},
    });
