import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';

import getPrimaryFolderTreeRootFolder from '../selectors/getPrimaryFolderTreeRootFolder';
import getFolderTable from '../selectors/getFolderTable';
import getArchiveFolderTreeRootFolder from '../selectors/getArchiveFolderTreeRootFolder';
import type { MailFolder, MailboxType } from 'owa-graph-schema';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import getSharedFolderRoot from '../selectors/getSharedFolderRoot';
import { isSameMailboxInfo } from 'owa-account-source-list-store';

import { isSameCoprincipalAccountMailboxInfos, type MailboxInfo } from 'owa-client-types';

export function getFolderByDistinguishedId(
    distinguishedId: DistinguishedFolderIdName,
    mailboxInfo: MailboxInfo
): MailFolder | undefined {
    const folderTable = getFolderTable();
    const folderId = folderNameToId(distinguishedId, mailboxInfo);
    return folderId ? folderTable.get(folderId) : undefined;
}

/**
 * get root folder of mailbox
 * @return root MailFolder
 */
export function getRootFolderForMailboxInfo(mailboxInfo: MailboxInfo): MailFolder | undefined {
    // If someone wants to get child folders of a shared folder root,
    // then it must pass mailboxSmtpAddress OR throw error.
    if (mailboxInfo.type === 'SharedMailbox' && !mailboxInfo.mailboxSmtpAddress) {
        throw new Error('To get all the shared folders you must pass principalSMTPAddress');
    }

    switch (mailboxInfo.type) {
        case 'ArchiveMailbox':
            return getArchiveFolderTreeRootFolder(mailboxInfo);
        case 'SharedMailbox':
            return getSharedFolderRoot(mailboxInfo.mailboxSmtpAddress, mailboxInfo);
        default:
            return getPrimaryFolderTreeRootFolder(mailboxInfo);
    }
}

/**
 * Function calculates and compares if the mailboxType of a given folder id is same as given mailboxType
 * @param mailboxType - mailboxType which needs to be checked
 * @param folderId - folderId
 */
export function isFolderInMailboxType(folderId: string, mailboxType: MailboxType): boolean {
    return getFolderMailboxInfo(folderId)?.type === mailboxType;
}

/**
 * Function compares the accounts of 2 folders
 * @param folderId1 - folderId of first folder
 * @param folderId2 - folderId of second folder
 */
export function areFoldersInSameAccount(folderId1: string, folderId2: string): boolean {
    return isSameCoprincipalAccountMailboxInfos(
        getFolderMailboxInfo(folderId1),
        getFolderMailboxInfo(folderId2)
    );
}

/**
 * Function compares the mailbox of 2 folders
 * @param folderId1 - folderId of first folder
 * @param folderId2 - folderId of second folder
 */
export function areFoldersInSameMailbox(folderId1: string, folderId2: string): boolean {
    const folder1MailboxInfo = getFolderMailboxInfo(folderId1);
    const folder2MailboxInfo = getFolderMailboxInfo(folderId2);

    // Same mailbox is local operation
    if (
        folder1MailboxInfo &&
        folder2MailboxInfo &&
        isSameMailboxInfo(folder1MailboxInfo, folder2MailboxInfo)
    ) {
        // This is to handle Archive to Archive, since it can have an Archive->AuxArchive (or vice-versa) configuration
        if (
            folder1MailboxInfo.type === 'ArchiveMailbox' &&
            folder2MailboxInfo.type === 'ArchiveMailbox' &&
            folder1MailboxInfo.auxiliaryMailboxGuid !== folder2MailboxInfo.auxiliaryMailboxGuid
        ) {
            // If the MailboxGuid are different, then it is not on the same mailbox
            return false;
        }

        return true;
    }

    return false;
}

function getFolderMailboxInfo(folderId: string): MailboxInfo | undefined {
    return getFolderTable().get(folderId)?.mailboxInfo;
}
