import type { TokenRequestParams } from '../schema/TokenRequestParams';
import type { HeadersWithoutIterator, RequestOptions } from 'owa-service/lib/RequestOptions';
import { getUserToken } from './userTokenHandler';

export async function getUserTokenIfAvailable(
    params: TokenRequestParams
): Promise<string | undefined> {
    let options: RequestOptions | undefined;
    if (params.wwwAuthenticateHeader) {
        const headers: HeadersWithoutIterator = new Headers();
        headers.set('WWW-Authenticate', params.wwwAuthenticateHeader);
        options = { headers };
    }

    return getUserToken(options, params.mailboxInfo);
}
