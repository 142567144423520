import doesActiveExperiencesInclude from './doesActiveExperiencesInclude';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Checks to see if the user is a student on an EDU tenant
 * @returns true if the user is a student on an EDU tenant (as opposed to EduFaculty for example).
 * @remarks used to show different UI strings for students as opposed to workers on an EDU tenant;
 * For example, students will see strings such as 'school week' instead of 'work week'
 */
export default function isEduStudent(mailboxInfo?: MailboxInfo) {
    return doesActiveExperiencesInclude('EduStudent', mailboxInfo);
}
