import React from 'react';
import { KeytipManager } from '@fluentui/react';
import { addDocumentEventListener } from 'owa-event-listener';
import { isMac } from 'owa-user-agent/lib/userAgent';
import { isFeatureEnabled } from 'owa-feature-flags';

const TWO_SECONDS = 2000;

export const useCustomKeytipEvents = (useF10keydown?: boolean) => {
    const keytipManager = KeytipManager.getInstance();

    let ignoreKeyUp = false;
    let onAltKeyHeldDown: ReturnType<typeof setTimeout> | undefined;

    // Remove and reset timeout when `window` loses focus
    const onBlur = () => {
        if (onAltKeyHeldDown) {
            clearTimeout(onAltKeyHeldDown);
            onAltKeyHeldDown = undefined;
        }
    };

    const onKeyDown = (event: KeyboardEvent) => {
        const { altKey, key, shiftKey, target } = event;

        // Ensuring target is an `HTMLElement` here to avoid type casting
        if (!(target instanceof HTMLElement)) {
            return;
        }

        // `alt` key in combination with another key
        // When a keyboard shortcut that includes `alt` is detected on keydown, we ignore the subsequent
        // keyup in order to:
        // 1. Keep the shortcut working as expected
        // 2. Avoid entering keytip mode
        if (altKey && (key !== 'Alt' || shiftKey)) {
            ignoreKeyUp = true;

            // When tapping Alt + N to open a new event in full compose, we don't want to enter keytip mode;
            // But the "Alt" keyup event doesn't propagate, so we end the `onAltKeyHeldDown` timer here to avoid entering the keytip mode
            if (isFeatureEnabled('cal-surface-inlineEvents') && key === 'n') {
                clearTimeout(onAltKeyHeldDown);
                onAltKeyHeldDown = undefined;
            }
        }

        // SplitButtons use "Alt" + "ArrownDown" to open their menu, but the "Alt" keyup event
        // doesn't propagate. Since we can’t detect the keyup, we need to explicitly avoid starting
        // the `onAltKeyHeldDown` timer when a SplitButton is focused
        const isSplitButtonFocused = target.className.includes('splitButtonContainer');

        // Detecting when the Compose editor has focus
        const isComposeEditorFocused = document.getElementById('editorParent_1')?.contains(target);
        const isComposingOnMac = isComposeEditorFocused && isMac();

        // `alt` key by itself
        // When `alt` is detected on keydown, we start a timer to detect that the key is being held down.
        // When 2 seconds has elapsed, enter keytip mode.
        if (key === 'Alt' && !onAltKeyHeldDown && !isSplitButtonFocused && !isComposingOnMac) {
            onAltKeyHeldDown = setTimeout(() => {
                keytipManager.enterKeytipMode();

                // Need to ignore the next keyup, otherwise keytip mode will be exited.
                ignoreKeyUp = true;
            }, TWO_SECONDS);
        }
    };

    const onKeyUp = (event: KeyboardEvent) => {
        const { key, shiftKey } = event;
        const isAltKey = key === 'Alt';

        if (isAltKey) {
            // Necessary to avoid focus moving to the "Settings and more" three-dot overflow button
            // in Edge on Windows
            event.preventDefault();
        }

        // Remove and reset timeout
        if (onAltKeyHeldDown) {
            clearTimeout(onAltKeyHeldDown);
            onAltKeyHeldDown = undefined;
        }

        if (ignoreKeyUp) {
            if (isAltKey) {
                ignoreKeyUp = false;
            }
            return;
        }

        if (
            (isAltKey && !keytipManager.inKeytipMode) ||
            (useF10keydown && key === 'F10' && !shiftKey)
        ) {
            keytipManager.enterKeytipMode();
        } else if (isAltKey && keytipManager.inKeytipMode) {
            keytipManager.exitKeytipMode();
        }
    };

    const onManualArrowKeyDown = () => {
        ignoreKeyUp = true;
    };

    React.useEffect(() => {
        window.addEventListener('blur', onBlur);
        window.addEventListener('manualArrowKeyDownForKeytips', onManualArrowKeyDown);

        const removeKeyupListener = addDocumentEventListener(
            'useCustomKeytipEvents',
            'keyup',
            onKeyUp
        );

        const removeKeydownListener = addDocumentEventListener(
            'useCustomKeytipEvents',
            'keydown',
            onKeyDown
        );

        return () => {
            window.removeEventListener('blur', onBlur);
            window.removeEventListener('manualArrowKeyDownForKeytips', onManualArrowKeyDown);

            removeKeyupListener?.();
            removeKeydownListener?.();
        };
    }, []);
};
