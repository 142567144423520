import { isCapabilityEnabled } from 'owa-capabilities';
import { copilotThemeCapability } from 'owa-capabilities-definitions/lib/copilotThemeCapability';
import { preloadThemeImage } from 'owa-theme-shared';
import getCopilotThemeFallbackUrl from './getCopilotThemeFallbackUrl';
import disposePreviousCopilotImage from './disposePreviousCopilotImage';

export default async function preloadBackgroundImage(requestUrl: string): Promise<string> {
    const isCopilotTheme = isCapabilityEnabled(copilotThemeCapability);
    let imageUrl = (await preloadThemeImage(requestUrl)) ? requestUrl : null;
    let isFallbackUrl = false;

    // For copilot themes we need to handle the error
    // and try to fetch the image from the cache or settings
    if (!imageUrl && isCopilotTheme) {
        imageUrl = await getCopilotThemeFallbackUrl();
        isFallbackUrl = true; // The fallback URL is a blob URL
    }

    if (!imageUrl) {
        throw new Error('Failed to preload background image');
    }

    if (isCopilotTheme) {
        disposePreviousCopilotImage({ requestUrl, imageUrl, isFallbackUrl });
    }

    return imageUrl;
}
