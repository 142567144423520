import getTheme from '../selectors/getTheme';
import getIsDarkTheme from 'owa-fabric-theme/lib/selectors/getIsDarkTheme';

export function getThemeIdFromParameter(themeId?: string): string {
    return themeId ?? getTheme();
}

export function getIsDarkThemeFromParameter(isDarkTheme?: boolean): boolean {
    return isDarkTheme ?? getIsDarkTheme();
}
