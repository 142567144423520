import type { MailboxInfo } from 'owa-client-types';
import { isMessageRecallEnabled as isMessageRecallEnabledInTenant } from 'owa-nonboot-userconfiguration-manager';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';

export default function isMessageRecallEnabled(
    mailboxInfo: MailboxInfo,
    isConsumerAcct: boolean = isConsumer(undefined /* smtpAddress */, mailboxInfo)
): boolean {
    return !isConsumerAcct && isMessageRecallEnabledInTenant(mailboxInfo);
}
