import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "RootItemStore" */ './lazyIndex'),
    { name: 'RootItemStore' }
);

export { default as getRootItemId } from './selectors/getRootItemId';

export const lazySetRootItemId = new LazyAction(lazyModule, m => m.setRootItemId);
export const lazyRemoveRootItemId = new LazyAction(lazyModule, m => m.removeRootItemId);
