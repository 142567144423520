import { getListViewMaxWidth } from './getMaxWidths';

import { isReadingPanePositionRight } from '../selectors/readingPanePosition';
export function getListViewColumnDefaultWidths(): {
    senderColumnWidth: number;
    subjectColumnWidth: number;
    receivedColumnWidth: number;
} {
    // default sender column width is 2x max iconbar width. default width is also min width ;
    const defaultSenderColumnWidth = 192;
    const defaultReceivedColumnWidth = isReadingPanePositionRight() ? 134 : 80;
    const defaultSubjectColumnWidth =
        getListViewMaxWidth() - defaultSenderColumnWidth - defaultReceivedColumnWidth;

    return {
        senderColumnWidth: defaultSenderColumnWidth,
        subjectColumnWidth: defaultSubjectColumnWidth,
        receivedColumnWidth: defaultReceivedColumnWidth,
    };
}
