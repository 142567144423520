import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/store';
import type { MailboxType } from 'owa-graph-schema';

/**
 * When moveto mbx type has to be changed
 * @param moveToMailbox - the mailbox type for which all folders should be shown (consequently search and new folders also falls in this context)
 */
export const onChangeMoveToMailboxTypeMutator = mutatorAction(
    'ON_CHANGE_MOVE_TO_CONTEXT',
    (moveToMailboxType: MailboxType) => {
        getStore().moveToMailboxType = moveToMailboxType;
    }
);
