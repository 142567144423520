import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';

/**
 * Whether or not the mailbox supports pinning
 *
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const pinItemCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        // If the mailbox supports pinning, they're always allowed
        return true;
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        return (
            !mailboxInfo /*allowed by default*/ ||
            !isLocalDataBackedMailboxInfo(
                mailboxInfo
            ) /* only disallowed for local data backed mailboxes (e.g. PST files) */
        );
    },
};
