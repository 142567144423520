import { LazyAction, LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(() => import('./lazyIndex'), {
    name: 'owa-mail-file-support/lazyFunctions',
});
export const lazyOpenFileMessage = new LazyAction(lazyModule, m => m.openFileMessage);
export const lazyConvertFileToMessageInService = new LazyAction(
    lazyModule,
    m => m.convertFileToMessageInService
);
export const lazyOnDropFile = new LazyAction(lazyModule, m => m.onDropFile);
export const lazyPopoutFileMessage = new LazyAction(lazyModule, m => m.popoutFileMessage);
export const FileOpenErrorView = createLazyComponent(lazyModule, m => m.FileOpenErrorView);
export const lazyCreateCopyItem = new LazyAction(lazyModule, m => m.createCopyItem);
export const lazyCreateCopyItemFailedNotification = new LazyAction(
    lazyModule,
    m => m.createCopyItemFailedNotification
);
export const lazyIsTabFileActivation = new LazyAction(lazyModule, m => m.isTabFileActivation);
export const lazyIsTabFileActivationCached = new LazyAction(
    lazyModule,
    m => m.isTabFileActivationCached
);
