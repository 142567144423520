import { createStore } from 'owa-satcheljs';

interface EmailsWithTasksStore {
    itemsIdsWithSchedulingTasks: string[];
}

export const initialStore: EmailsWithTasksStore = {
    itemsIdsWithSchedulingTasks: [],
};

const getStore = createStore('EmailsWithTasksStore', Object.assign({}, initialStore));

export const store = getStore();
