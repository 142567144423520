import type { DatapointOptionsForPerformanceDatapoint } from 'owa-analytics-types';
import type { AnalyticsCoreEventNames } from 'owa-analytics-events';
import { PerformanceDatapoint } from './PerformanceDatapoint';

export class PerformanceCoreDatapoint extends PerformanceDatapoint {
    constructor(
        eventName: AnalyticsCoreEventNames,
        options?: DatapointOptionsForPerformanceDatapoint
    ) {
        super(eventName, options);

        this.options = { ...this.options, isCore: true };
    }
}
