import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used to determine if mail can be saved
 */
export const saveEmailAsEMLToFileCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (!mailboxInfo) {
            return false; // mailboxInfo is required
        }

        // PST files don't support save email as EML
        return !isLocalDataBackedMailboxInfo(mailboxInfo);
    },
};
