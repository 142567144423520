import type { MailboxInfo } from 'owa-client-types';
import { getStore } from 'owa-mail-store/lib/store/Store';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import type ItemId from 'owa-service/lib/contract/ItemId';
import type Message from 'owa-service/lib/contract/Message';
import { getAccountScopeUserSettings } from 'owa-session-store';
import getAlternativeFromAddress from './getAlternativeFromAddress';
import getSenderInRecipients from './getSenderInRecipients';
import type { ClientItem } from 'owa-mail-store';

/**
 * Get the from address that should be shown in the From well
 * @param referenceItemId, the id for referencing the message
 */
export default function getFromAddressWrapper(
    mailboxInfo: MailboxInfo,
    referenceItemId?: ItemId
): EmailAddressWrapper {
    let fromAddress;
    if (referenceItemId) {
        const mailStore = getStore();
        const items = mailStore?.items;
        const referenceItem =
            items && items.has(referenceItemId.Id) && (items.get(referenceItemId.Id) as Message);
        const itemMailboxInfo =
            (referenceItem && (referenceItem as ClientItem)?.MailboxInfo) || null;
        if (itemMailboxInfo?.type === 'SharedMailbox') {
            fromAddress = itemMailboxInfo.mailboxSmtpAddress;
        } else {
            fromAddress = referenceItem && getSenderInRecipients(referenceItem, mailboxInfo);
        }
    }

    const userConfig = getAccountScopeUserSettings(mailboxInfo);

    return <EmailAddressWrapper>{
        MailboxType: 'Mailbox',
        RoutingType: 'SMTP',
        EmailAddress: fromAddress || getAlternativeFromAddress(mailboxInfo),
        Name: userConfig.SessionSettings?.UserDisplayName ?? '',
    };
}
