import type CopilotPromptType from '../types/CopilotPromptType';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getItem, setItem, itemExists, type LocalStorageKeys } from 'owa-local-storage';

/**
 * Get the copilot prompt for the mailbox
 * @param mailboxInfo the mailbox info for current user
 */
export function getCopilotPromptStore(mailboxInfo: MailboxInfo): CopilotPromptType {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const localStoragePrompt = fetchCopilotPromptFromLocalStorage(key);
    return {
        id: localStoragePrompt?.id ?? key,
        category: localStoragePrompt?.category,
        prompt: localStoragePrompt?.prompt,
        applyToIncomingMessages: localStoragePrompt?.applyToIncomingMessages,
        openAIModel: localStoragePrompt?.openAIModel,
    };
}

/**
 * Get the copilot prompt from local storage
 * @param key the key to fetch the prompt
 */
function fetchCopilotPromptFromLocalStorage(key: string): CopilotPromptType | undefined {
    const localStorageKey: LocalStorageKeys = `mail_copilot_prompt${key}`;
    try {
        return itemExists(window, localStorageKey)
            ? JSON.parse(getItem(window, localStorageKey) as string)
            : undefined;
    } catch (e) {
        return undefined;
    }
}

/**
 * Save the copilot prompt to local storage
 * @param copilotPrompt the copilot prompt object to save
 */
export const saveCopilotPrompt = (copilotPrompt: CopilotPromptType) => {
    const localStorageKey: LocalStorageKeys = `mail_copilot_prompt${copilotPrompt.id}`;
    setItem(window, localStorageKey, JSON.stringify(copilotPrompt));
};
