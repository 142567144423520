import { getCdnUrl } from 'owa-config';
import { getCopilotThemeImageUrl } from 'owa-copilot-themes-config';
import getResourcesForTheme from './getResourcesForTheme';
import getIsCurrentThemeModernImage from './getIsCurrentThemeModernImage';
import getIsCurrentThemeCopilot from './getIsCurrentThemeCopilot';
import { getThemeIdFromParameter } from '../utils/optionalArgumentHelpers';

export default function getBackgroundImageUrl(
    themeId?: string,
    isDarkTheme?: boolean
): string | undefined {
    let imageUrl: string | undefined;

    themeId = getThemeIdFromParameter(themeId);

    if (getIsCurrentThemeModernImage(themeId)) {
        imageUrl = getResourcesForTheme(themeId, isDarkTheme).background?.image;
    } else if (getIsCurrentThemeCopilot(themeId)) {
        imageUrl = getCopilotThemeImageUrl();
    }

    return imageUrl ? `${getCdnUrl()}${imageUrl}` : undefined;
}
