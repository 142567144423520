import { createLazyComponent, LazyModule, LazyAction } from 'owa-bundling';
import store from './store/store';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailRibbonCustomizer"*/ './lazyIndex'),
    { name: 'MailRibbonCustomizer' }
);

export { getStore } from './store/store';

// Delay loaded components
export const LazyRibbonCustomizer = createLazyComponent(
    lazyModule,
    m => m.RibbonCustomizer,
    undefined,
    undefined,
    { onlyImportIf: () => store.isCustomizing }
);

export const lazyOnOpenCustomizer = new LazyAction(lazyModule, m => m.onOpenCustomizer);
