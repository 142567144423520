import {
    importanceHigh,
    importanceNormal,
    importanceLow,
} from '../components/MailListJumpToPopover.locstring.json';

export const getJumpToImportanceOptions = () => {
    return [
        {
            value: 'High',
            text: importanceHigh,
        },
        {
            value: 'Normal',
            text: importanceNormal,
        },
        {
            value: 'Low',
            text: importanceLow,
        },
    ];
};
