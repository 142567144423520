import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { logStartUsage } from 'owa-analytics-start';
import { updateUserConfiguration } from 'owa-session-store';
import setUserThemeRequest from 'owa-service/lib/factory/setUserThemeRequest';
import setUserThemeOperation from 'owa-service/lib/operation/setUserThemeOperation';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';

export default async function migrateThemeId(themeId: string) {
    try {
        updateUserConfiguration(config => {
            if (config?.UserOptions != null) {
                config.UserOptions.ThemeStorageId = themeId;
            }
        });

        await setUserThemeOperation(
            {
                request: setUserThemeRequest({
                    ThemeId: themeId,
                    UserThemeJson: '',
                }),
            },
            getMailboxRequestOptions(getGlobalSettingsAccountMailboxInfo())
        );
    } catch (error) {
        // this shouldn't be throwing, but since this is just a migration from a deprecated themeId,
        // there's no good reason to break bootstrap for it.
        logStartUsage('MigrateThemeIdFailed', {
            stack: error?.stack,
            error: error?.message,
            themeId,
        });
    }
}
