import { createStore } from 'owa-satcheljs';
import { getIsLightRPOnFromStorage } from '../utils/localStorage';
import type LighReadingPaneStore from './schema/LightReadingPaneStore';

const initialLightRPStore: LighReadingPaneStore = {
    isLightRPOn: getIsLightRPOnFromStorage(),
};

export const getStore = createStore<LighReadingPaneStore>(
    'lightReadingPaneStore',
    initialLightRPStore
);
