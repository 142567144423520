import type { MailboxInfo } from 'owa-client-types';
import { LicenseType } from 'owa-copilot-settings-store/lib/services/types/serviceSettingTypes';
import copilotSettingsStore from 'owa-copilot-settings-store/lib/store/store';
import { isFeatureEnabled } from 'owa-feature-flags';
import hasM365CopilotLicense from './hasM365CopilotLicense';

export function isProdigiUser(mailboxInfo: MailboxInfo): boolean {
    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
     * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
     *	> The parameter mailboxInfo must be undefined. Feature flight: 'mon-copilot-prodigi' */
    if (!isFeatureEnabled('mon-copilot-prodigi', mailboxInfo)) {
        return false;
    }

    const store = copilotSettingsStore(mailboxInfo);
    if (store.isEnabled) {
        return (
            store.licenses.some(license => license === LicenseType.M365ConsumerCredits) &&
            !hasM365CopilotLicense(mailboxInfo)
        );
    }

    return false;
}
