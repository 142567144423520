import {
    allFoldersInArchive,
    allFoldersString,
    currentMailboxString,
} from 'owa-locstrings/lib/strings/getSearchScopeDisplayName.locstring.json';
import {
    allFoldersInSharedMailbox,
    allFoldersInPstFile,
} from './getSearchScopeDisplayName.locstring.json';
import { lazyGetGroupDisplayName } from 'owa-group-utils';
import loc from 'owa-localize';
import { allFolders, currentMailbox } from 'owa-locstrings/lib/strings/allfolders.locstring.json';
import type {
    SearchScope,
    SingleGroupSearchScope,
    SingleGroup3sSearchScope,
    MetaOSAppSearchScope,
    PstFileSearchScope,
} from 'owa-search-service';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import folderStore, { getEffectiveFolderDisplayName } from 'owa-folders';
import {
    ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
    PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
} from 'owa-folders-constants';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';
import { getMailboxInfoForMailAccounts } from 'owa-account-source-list-store';
import {
    lazyGetGroupFolderName,
    lazyHasUserCreatedFolders,
} from 'owa-groups-shared-folders-store/lib/lazyFunctions';
import { lazyGetAppDefinition_sync } from 'owa-app-definitions';
import type { MailboxInfo } from 'owa-client-types';
import type { FoldersSearchScope } from '../types/FolderSearchScope';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export default function getSearchScopeDisplayName(
    scope: SearchScope,
    mailboxInfo?: MailboxInfo,
    isSearchFromFolderScope?: boolean
) {
    switch (scope.kind) {
        case SearchScopeKind.PrimaryMailbox:
        case SearchScopeKind.ArchiveMailbox:
        case SearchScopeKind.SharedFolders:
        case SearchScopeKind.PublicFolder:
            return getMailboxSearchScopeDisplayName(scope, isSearchFromFolderScope);
        case SearchScopeKind.Group:
            return getSingleGroupSearchScopeDisplayName(
                scope,
                mailboxInfo ?? getModuleContextMailboxInfo()
            );
        case SearchScopeKind.MetaOSApp:
            return getMetaOSAppSearchScopeDisplayName(
                scope,
                mailboxInfo ?? getModuleContextMailboxInfo()
            );
        case SearchScopeKind.PstFile:
            return getPstFileSearchScopeDisplayName(scope);
        default:
            throw new Error('Unregistered search scope');
    }
}

function getMailboxSearchScopeDisplayName(
    folderSearchScope: FoldersSearchScope,
    isSearchFromFolderScope?: boolean
): string {
    if (!folderSearchScope.folderId) {
        return '';
    }

    const numberOfMailboxes = getMailboxInfoForMailAccounts().length;

    const folderName = folderIdToName(folderSearchScope.folderId);
    if (
        folderName === PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID &&
        folderSearchScope.kind === SearchScopeKind.SharedFolders
    ) {
        return loc(allFoldersInSharedMailbox);
    }
    if (folderName === PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID && isSearchFromFolderScope) {
        return loc(numberOfMailboxes > 1 ? currentMailboxString : allFoldersString);
    } else if (folderName === PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID) {
        return loc(numberOfMailboxes > 1 ? currentMailbox : allFolders);
    } else if (folderName === ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID) {
        return loc(allFoldersInArchive);
    } else {
        let folder = folderStore.folderTable.get(folderSearchScope.folderId);
        if (folderSearchScope.kind === SearchScopeKind.PublicFolder) {
            folder = publicFolderFavoriteStore.folderTable.get(folderSearchScope.folderId);
        }

        return getEffectiveFolderDisplayName(folder);
    }
}

function getSingleGroupSearchScopeDisplayName(
    singleGroupSearchScope: SingleGroupSearchScope,
    mailboxInfo: MailboxInfo
): string {
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    const getGroupDisplayName = lazyGetGroupDisplayName.tryImportForRender();
    if (getGroupDisplayName && singleGroupSearchScope.groupId) {
        const groupName = getGroupDisplayName(singleGroupSearchScope.groupId);
        const singleGroup3sSearchScope = singleGroupSearchScope as SingleGroup3sSearchScope;
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * BASELINE. Do not copy and paste!
         *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
        const hasUserCreatedFolders = lazyHasUserCreatedFolders.tryImportForRender();
        if (hasUserCreatedFolders?.(singleGroup3sSearchScope.groupId, mailboxInfo)) {
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * BASELINE. Do not copy and paste!
             *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
            const getGroupFolderName = lazyGetGroupFolderName.tryImportForRender();
            const groupFolderName = getGroupFolderName
                ? getGroupFolderName(
                      singleGroup3sSearchScope.groupId,
                      singleGroup3sSearchScope.folderId,
                      mailboxInfo
                  )
                : '';
            return groupFolderName;
        }
        return groupName;
    }

    return singleGroupSearchScope.groupId;
}

function getMetaOSAppSearchScopeDisplayName(
    metaOSAppSearchScope: MetaOSAppSearchScope,
    mailboxInfo: MailboxInfo
): string {
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    const getAppDefinition = lazyGetAppDefinition_sync.tryImportForRender();
    if (getAppDefinition) {
        const appDefinition = getAppDefinition(
            metaOSAppSearchScope.appId,
            mailboxInfo
        )?.appDefinition;
        return appDefinition?.name ?? 'App';
    } else {
        return 'App';
    }
}

function getPstFileSearchScopeDisplayName(pstFileSearchScope: PstFileSearchScope): string {
    if (!pstFileSearchScope.folderId) {
        return '';
    }

    const folderName = folderIdToName(pstFileSearchScope.folderId);
    if (folderName === PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID) {
        return loc(allFoldersInPstFile);
    } else {
        const folder = folderStore.folderTable.get(pstFileSearchScope.folderId);
        return getEffectiveFolderDisplayName(folder);
    }
}
