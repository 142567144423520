import type { TabViewState } from 'owa-tab-store';
import { getStore, type TabState, TabType } from 'owa-tab-store';
import { composeStore } from 'owa-mail-compose-store';

export default function getAllComposeTabs(): TabViewState[] | null {
    const store = getStore();
    const composeTabs: TabViewState[] = [];

    for (const tab of store.tabs) {
        if (
            tab.type === TabType.MailCompose &&
            (tab.state === 2 || tab.state === 1 || tab.state === 0) // Hidden state is used for inline compose in conversation view
        ) {
            composeTabs.push(tab);
        } else if (tab.type == TabType.Primary && !!composeStore.primaryComposeId) {
            // To handled draft compose in primary tab
            composeTabs.push(tab);
        }
    }
    return composeTabs;
}
