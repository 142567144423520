import type { CalendarEntry } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import { isLinkedCalendarEntryV2 } from './isLinkedCalendarEntryV2';
import { isLinkedCalendarEntry } from './isLinkedCalendarEntry';
import { isFeatureEnabled } from 'owa-feature-flags';
import { CalendarOperation } from './calendarOperation';

const delegateMailboxOperations = new Set([
    CalendarOperation.DeleteCalendar,
    CalendarOperation.RenameCalendar,
    CalendarOperation.SetCalendarHexColor,
    CalendarOperation.SetCalendarColor,
    CalendarOperation.UpdateCalendarCharm,
    CalendarOperation.GetUserAvailability,
    CalendarOperation.SubscribeNotifications,
]);

/**
 * Returns the mailbox info for the calendar operation based on the calendar entry.
 * For shared calendar, calendar left nav operations should be routed to delegate mailbox and not principal mailbox.
 * @param calendarEntry, the calendar entry
 * @param CalendarOperation, the calendar operation
 * @returns mailbox info with the mailbox identity
 */
export function getMailboxInfoForCalendarOperation(
    calendarEntry: CalendarEntry,
    calendarOperation: CalendarOperation
): MailboxInfo {
    if (
        !isFeatureEnabled('cal-enable-HybridModel-for-calendarSharing') &&
        !isFeatureEnabled('cal-enable-fix-for-OldModelSharedCalendars')
    ) {
        return calendarEntry.calendarId.mailboxInfo;
    }
    if (
        delegateMailboxOperations.has(calendarOperation) &&
        (isLinkedCalendarEntryV2(calendarEntry) || isLinkedCalendarEntry(calendarEntry))
    ) {
        // set the mailbox identity to the delegate identity and reset explicit logon to false
        return {
            ...calendarEntry.calendarId.mailboxInfo,
            mailboxSmtpAddress: calendarEntry.calendarId.mailboxInfo.userIdentity,
            mailboxPrefixedIdentity: null,
            isExplicitLogon: false,
        };
    }

    return calendarEntry.calendarId.mailboxInfo;
}
