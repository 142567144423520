import { SwipeStage } from 'touch-behavior';
import { logUsage, PerformanceDatapoint } from 'owa-analytics';
import { yieldNow } from 'owa-task-queue/lib/schedule';
import { getSwipeRightAction, getSwipeLeftAction } from 'owa-mail-list-swipe-utils';
import type MailSwipeAction from 'owa-service/lib/contract/MailSwipeAction';
import type { MailListItemPartProps, MailListItemWrapperProps } from 'owa-mail-list-item-shared';
import { getStore as getListViewStore } from 'owa-mail-list-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';

export const skipHandleTouchCallback = (moveX?: number): boolean => {
    if (moveX === undefined) {
        return true;
    }
    if (moveX === 0) {
        return false;
    }
    const isSwipeRight = moveX > 0;
    const swipeAction = isSwipeRight ? getSwipeRightAction() : getSwipeLeftAction();

    return swipeAction === 0;
};

export const swipePerfMeasurer = (() => {
    let startTime: number | undefined = undefined;

    function startDatapoint() {
        startTime = performance.now();
    }

    function endDatapoint(endTime: number) {
        if (startTime) {
            const dp = new PerformanceDatapoint('MailListItemSwipePerf');
            dp.end(endTime - startTime);
        }
    }

    return async (_props: MailListItemWrapperProps | MailListItemPartProps, stage: SwipeStage) => {
        switch (stage) {
            case SwipeStage.Started:
                startDatapoint();
                break;
            case SwipeStage.Completed:
                const endTime = performance.now();
                await yieldNow('swipePerfMeasurer');
                endDatapoint(endTime);
                break;
            default:
                break;
        }
    };
})();

export const logSwipeAction = (
    isSwipeRight: boolean,
    swipeAction: MailSwipeAction,
    rowKeyOrNodeId: string,
    tableViewId: string,
    isItemPart: boolean
) => {
    const tableView = getListViewStore().tableViews.get(tableViewId);
    const folderId = tableView?.tableQuery?.folderId;
    const folderName = folderId ? folderIdToName(folderId) : 'none';

    logUsage('SwipeMailListItem', {
        isSwipeRight,
        swipeAction,
        tableType: tableView?.tableQuery?.type,
        listViewType: tableView?.tableQuery?.listViewType,
        folderName,
        rowKeyOrNodeId,
        isItemPart,
    });
};
