import type { MailboxInfo } from 'owa-client-types';
import { isAccountExplicitLogon } from 'owa-account-shared-mailbox-utils';

export default function isFindRelatedConversationEnabled(mailboxInfo: MailboxInfo): boolean {
    if (isAccountExplicitLogon(mailboxInfo)) {
        return true;
    } else if (mailboxInfo.type == 'UserMailbox' || mailboxInfo.type == 'ArchiveMailbox') {
        return true;
    }
    return false;
}
