import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyUpdateEmbedChatOptions } from '../lazyFunctions';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';

export const chatButtonRenderedCallback = () => {
    // Log usage and update the last used date for embed chat for enterprise users
    if (
        isFeatureEnabled('fwk-teamsSDKPreAuth') &&
        isFeatureEnabled('fwk-teamsSuite') &&
        !isConsumer(undefined, getGlobalSettingsAccountMailboxInfo())
    ) {
        logUsage('EmbedChatButtonRendered');
        lazyUpdateEmbedChatOptions.importAndExecute();
    }
};
