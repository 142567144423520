import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupsPanelContent"*/ './lazyIndex'),
    { name: 'GroupsPanelContent' }
);

export const lazySelectTabInPanel = new LazyAction(lazyModule, m => m.selectTabInPanel);

export const lazySelectGroupInPanel = new LazyAction(lazyModule, m => m.selectGroupInPanel);

export const lazyOpenGroupsPanel = new LazyAction(lazyModule, m => m.openGroupsPanel);

export const lazyInitializeGroupsPanel = new LazyAction(lazyModule, m => m.initializeGroupsPanel);
