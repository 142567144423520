import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "RolloutPane"*/ './lazyIndex'),
    { name: 'RolloutPane' }
);

export const RolloutOverridesPane = createLazyComponent(lazyModule, m => m.RolloutOverridesPane);

export const lazyOpenRolloutOverrides = new LazyAction(lazyModule, m => m.openRolloutOverrides);
