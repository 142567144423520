import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { logUsage } from 'owa-analytics';
import { lazyGenerateColorScheme, lazyGetOptimalTextColorString } from 'owa-color-utils';
import DismissFilled from 'owa-fluent-icons-svg/lib/icons/DismissFilled';
import { getDensityMode } from 'owa-fabric-theme';
import type { ButtonProps } from 'owa-fluent-v9-shims';
import { FluentButton } from 'owa-fluent-v9-shims';
import loc, { format } from 'owa-localize';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type CategoryType from 'owa-service/lib/contract/CategoryType';
import React from 'react';
import type { CategoryColorValue } from '../store/schema/CategoryColor';
import getCategoryColorsForCategory from '../utils/getCategoryColorsForCategory';
import getMasterCategoryList from '../utils/getMasterCategoryList';
import { removeCategoryButtonLabel, searchCategoryButtonLabel } from './Category.locstring.json';
import {
    lastCategoryContainer,
    categoryContainer,
    categoryMaxWidth,
    categoryMaxWidthWithButton,
    categoryName as styles_categoryName,
    categoryNameFullDensity,
    removeCategoryButton,
    sparkleIconLast,
    sparkleIcon,
} from './Category.scss';
import { useMouseWithPerfWrapper } from 'owa-react-hooks/lib/useMouseWithPerfWrapper';
import type { MailboxInfo } from 'owa-client-types';
import { makeStyles, Tooltip } from '@fluentui/react-components';
import SparkleFilled from 'owa-fluent-icons-svg/lib/icons/SparkleFilled';
import { Icon } from '@fluentui/react/lib/Icon';
import { isFeatureEnabled } from 'owa-feature-flags';
import isCopilotCategory from '../utils/isCopilotCategory';
import { useBoolean } from '@fluentui/react-hooks';
import { getCopilotCategoryReasonStoreMap } from '../store/helpers/getCopilotCategoriesList';

// Internal CSS variables
const categoryPrimaryColor = '--category-primary-color';
const categorySecondaryColor = '--category-secondary-color';
const categoryTextColor = '--category-text-color';
const categoryOptimalTextColor = '--category-optimal-text-color';

const useStyles = makeStyles({
    root: {
        backgroundColor: `var(${categoryPrimaryColor})`,

        ':hover': {
            backgroundColor: `var(${categorySecondaryColor})`,
            color: `var(${categoryOptimalTextColor})`,
        },
        ':hover:active': {
            backgroundColor: `var(${categorySecondaryColor})`,
            color: `var(${categoryOptimalTextColor})`,
        },
    },
    icon: {
        fontSize: '12px',
        backgroundColor: 'transparent',
        color: `var(${categoryTextColor})`,
    },
});

interface CategoryProperties extends React.CSSProperties {
    [categoryPrimaryColor]?: string;
    [categorySecondaryColor]?: string;
    [categoryTextColor]?: string;
    [categoryOptimalTextColor]?: string;
}

export interface CategoryProps {
    category: string;
    categoryList?: CategoryType[];
    actionSource: string;
    isLastInWell: boolean;
    containerClassName?: string;
    onCategoryClicked?: (
        ev: React.MouseEvent<unknown>,
        category: string,
        actionSource: string
    ) => void;
    showCategoryRemove?: boolean;
    onRemoveCategoryClicked?: (category: string) => void;
    mailboxInfo: MailboxInfo;
    isDarkThemeOverride?: boolean;
    conversationId?: string;
}

export default observer(function Category(props: CategoryProps) {
    const mailboxInfo = props.mailboxInfo;
    const categoryContainerElement = React.useRef<HTMLDivElement>(null);
    const [isTooltipVisible, { toggle: toggleIsTooltipVisible }] = useBoolean(false);
    const categoryColor = useComputedValue((): CategoryColorValue => {
        return getCategoryColorsForCategory(
            props.category,
            props.categoryList ? props.categoryList : getMasterCategoryList(mailboxInfo),
            mailboxInfo,
            props.isDarkThemeOverride
        );
    }, [props.category, props.categoryList, props.isDarkThemeOverride]);
    const { textColor, primaryColor, secondaryColor, hoverColor } = categoryColor;
    const styleClassNames = useStyles();
    const isFullDensityMode = getDensityMode() === 'Full';

    const onCategoryClicked = React.useCallback(
        (ev: React.MouseEvent<unknown>) => {
            if (props.onCategoryClicked) {
                logUsage('Category_Well_Click', [props.actionSource]);
                props.onCategoryClicked(ev, props.category, props.actionSource);
            }
        },
        [props.onCategoryClicked]
    );

    const onMouseEnterCategory = React.useCallback(() => {
        toggleIsTooltipVisible();
        if (props.onCategoryClicked && categoryContainerElement?.current?.style) {
            categoryContainerElement.current.style.borderColor = textColor;
        }
    }, [props.onCategoryClicked, categoryContainerElement?.current]);
    const mouseEnterCb = useMouseWithPerfWrapper(onMouseEnterCategory, 'Category');
    const onMouseLeaveCategory = React.useCallback(() => {
        toggleIsTooltipVisible();
        if (props.onCategoryClicked && categoryContainerElement?.current?.style) {
            categoryContainerElement.current.style.borderColor = secondaryColor;
        }
    }, [props.onCategoryClicked, categoryContainerElement?.current]);
    const mouseLeaveCb = useMouseWithPerfWrapper(onMouseLeaveCategory, 'Category');
    const categoryName = props.category;
    const containerClassnames = classnames(
        props.containerClassName,
        props.isLastInWell ? lastCategoryContainer : categoryContainer,
        props.showCategoryRemove ? categoryMaxWidth : categoryMaxWidthWithButton
    );
    const categoryClassnames = classnames(
        styles_categoryName,
        isFullDensityMode && categoryNameFullDensity
    );
    // Please update the following 'any' type(s) to be accurate to usage
    const onRemoveCategoryClicked = React.useCallback(
        (evt: any) => {
            evt.stopPropagation();
            props.onRemoveCategoryClicked?.(categoryName);
        },
        [props.onRemoveCategoryClicked, props.category]
    );

    const isCopilotFetureEnabled =
        isFeatureEnabled('tri-copilotCategories-dev') ||
        isFeatureEnabled('tri-copilotPrompt-experimental');

    const copilotCategoryReason = useComputedValue(() => {
        if (isCopilotFetureEnabled) {
            const copilotCategoryReasonStore = getCopilotCategoryReasonStoreMap(mailboxInfo);
            //Check if the conversationId is already in the map
            if (copilotCategoryReasonStore.has(props?.conversationId ?? '')) {
                const categoryReason = copilotCategoryReasonStore
                    .get(props?.conversationId ?? '')
                    ?.find(category => category.startsWith(props.category));
                return categoryReason?.replace(props.category, '');
            }
        }
        return undefined;
    }, [props.category, mailboxInfo, props.conversationId]);

    const optimalTextColorOnHover = useComputedValue(() => {
        // Only do the work to get an accessible color if showing category remove button
        if (props.showCategoryRemove) {
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * BASELINE. Do not copy and paste!
             *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
            const getOptimalTextColorString = lazyGetOptimalTextColorString.tryImportForRender();
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * BASELINE. Do not copy and paste!
             *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
            const generateColorScheme = lazyGenerateColorScheme.tryImportForRender();
            if (generateColorScheme && getOptimalTextColorString) {
                return getOptimalTextColorString(
                    secondaryColor,
                    generateColorScheme(textColor),
                    textColor
                );
            }
        }
        return undefined;
    }, [categoryColor, props.showCategoryRemove]);

    const categoryStyle: CategoryProperties = React.useMemo(() => {
        return {
            [categoryPrimaryColor]: primaryColor,
            [categorySecondaryColor]: secondaryColor,
            [categoryTextColor]: textColor,
            [categoryOptimalTextColor]: optimalTextColorOnHover,
            backgroundColor: `var(${categoryPrimaryColor})`,
            borderColor: `var(${categorySecondaryColor})`,
            color: `var(${categoryTextColor})`,
            cursor: props.onCategoryClicked ? 'pointer' : 'initial',
        };
    }, [primaryColor, secondaryColor, textColor, optimalTextColorOnHover, props.onCategoryClicked]);

    // Props for the button that removes the category
    const removeButtonStyles = React.useMemo(() => {
        return {
            icon: {
                color: textColor,
                fontSize: '10px',
                paddingBottom: '5px',
                paddingLeft: '1px',
            },
            rootHovered: {
                backgroundColor: hoverColor,
            },
            rootPressed: {
                backgroundColor: hoverColor,
            },
        };
    }, [textColor, hoverColor]);

    const sparkleIconStyles = React.useMemo(() => {
        return {
            fontSize: '17px',
            width: '0px',
            color: secondaryColor,
        };
    }, [secondaryColor]);

    // Props for the button that removes the category
    const removeButtonProps: ButtonProps = {
        iconProps: {
            iconName: DismissFilled,
            styles: {
                root: {
                    fontSize: '12px',
                },
            },
        },
        className: removeCategoryButton,
        onClick: onRemoveCategoryClicked,
        'aria-label': format(loc(removeCategoryButtonLabel), categoryName),
        title: format(loc(removeCategoryButtonLabel), categoryName),
        styles: removeButtonStyles,
        styleClassNames,
    };

    return (
        <>
            <div
                ref={categoryContainerElement}
                key={categoryName}
                className={containerClassnames}
                style={categoryStyle}
                title={format(loc(searchCategoryButtonLabel), categoryName)}
                onMouseEnter={mouseEnterCb}
                onMouseLeave={mouseLeaveCb}
                onClick={onCategoryClicked}
            >
                <Tooltip
                    content={copilotCategoryReason ?? ''}
                    relationship="description"
                    visible={
                        isCopilotFetureEnabled &&
                        isTooltipVisible &&
                        copilotCategoryReason !== undefined
                    }
                >
                    <span className={categoryClassnames}>{categoryName}</span>
                </Tooltip>
                {props.showCategoryRemove && (
                    <FluentButton appearance="icon" {...removeButtonProps} />
                )}
            </div>
            {isFeatureEnabled('tri-copilotCategories-dev') &&
                isCopilotCategory(categoryName, mailboxInfo) && (
                    <Icon
                        iconName={SparkleFilled}
                        className={props.isLastInWell ? sparkleIconLast : sparkleIcon}
                        style={sparkleIconStyles}
                    />
                )}
        </>
    );
}, 'Category');
