import { type promptForResourceTokenFunc } from './authCallbacks';

// AuthenticationConfig is a configuration object that allows the caller to provide custom
// implementations for common authentication tasks. Instead of hanging things off of the serviceConfig,
// specialized authentication tasks can be provided here.

export interface AuthenticationConfig {
    promptForResourceToken?: promptForResourceTokenFunc;
}

let currentAuthConfig: AuthenticationConfig = {};

export function getAuthConfig(): AuthenticationConfig {
    return currentAuthConfig;
}

export function updateAuthConfig(authConfig?: Partial<AuthenticationConfig>) {
    currentAuthConfig = { ...currentAuthConfig, ...authConfig };
}
