import onRemoveProjection from '../actions/onRemoveProjection';
import { getStore } from '../store/parentStore';
import { mutatorAction } from 'satcheljs';

export default function removeProjection(window: Window) {
    const store = getStore();

    for (let i = 0; i < store.projections.length; i++) {
        if (store.projections[i].window == window) {
            onRemoveProjection(store.projections[i].tabId, window);
            removeProjectionFromStore(i);
            break;
        }
    }
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const removeProjectionFromStore = mutatorAction(
    'removeProjectionFromStore',
    (projectionIndexToRemove: number) => {
        const store = getStore();
        store.projections.splice(projectionIndexToRemove, 1);
    }
);
