import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { makeGetRequest } from 'owa-ows-gateway';
import { CLOUD_SETTING_BASE_URL, COPILOT_THEME_IMAGE_SETTING_KEY } from '../constants/constants';

export default function fetchCopilotThemeImageFromSettings(): Promise<any> {
    return makeGetRequest(
        `${CLOUD_SETTING_BASE_URL}?settingname=${COPILOT_THEME_IMAGE_SETTING_KEY}`,
        undefined /* correlationId */,
        false /* returnFullResponse */,
        {
            'x-islargesetting': true,
        } /* customHeaders */,
        true /* throwServiceError */,
        undefined /* includeCredentials */,
        'getCopilotThemeImage' /* actionName */,
        getGlobalSettingsAccountMailboxInfo(),
        undefined /* isHttpCachingExpected */,
        3 /* retryCount */
    );
}
