import { registerLightIcon } from './registerLightIcon';
import { lightRibbonIconFont } from './lightRibbonIconFont';
import { isFeatureEnabled } from 'owa-feature-flags';

export const registerAllLightIcons = (isMultiLineRibbon: boolean) => {
    if (isFeatureEnabled('mon-ribbon-light-icons-boot') && isMultiLineRibbon) {
        lightRibbonIconFont.forEach(iconName => {
            registerLightIcon(iconName);
        });
    }
};
