import { createStore } from 'owa-satcheljs';
import type CurrentGroupInformation from './schema/CurrentGroupInformation';

const initialState: CurrentGroupInformation = {
    // TODO: 154319 - add current mailboxInfo
    smtpAddress: '',
    groupId: '',
    tenantId: '',
};

export const getCurrentGroupInformationStore = createStore(
    'currentGroupInformationStore',
    initialState
);
