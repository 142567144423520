import getExtensibilityState from '../store/getExtensibilityState';
import type TaskPaneType from '../store/schema/TaskPaneType';
import { mutatorAction } from 'satcheljs';
import getTabIdFromWindow from '../store/getTabIdFromWindow';
import type { MailboxInfo } from 'owa-client-types';

export default mutatorAction(
    'terminateTaskPaneAddin',
    function terminateTaskPaneAddin(
        type: TaskPaneType,
        targetWindow: Window,
        mailboxInfo: MailboxInfo
    ) {
        const { taskPanes } = getExtensibilityState(mailboxInfo);
        const tabId: string = getTabIdFromWindow(targetWindow);

        if (taskPanes.has(tabId)) {
            taskPanes.get(tabId)?.delete(type);
            if (taskPanes.get(tabId)?.size === 0) {
                taskPanes.delete(tabId);
            }
        }
    }
);
