import { createStore } from 'owa-satcheljs';
import type OptionsViewState from './schema/OptionsViewState';

export const getStore = createStore<OptionsViewState>('optionsViewState', {
    isFullOptionsShown: false,
    areAllAllowedSubCategoriesLoaded: false,
    isSaving: false,
    currentCategoryKey: undefined,
    currentSubCategoryKey: undefined,
    subCategoryLoadState: new Map(),
    currentOptionKey: undefined,
    currentOptionSubKey: undefined,
    allowedOptions: [],
    dirtyOptions: [],
});
