import { allFilter } from '../strings.locstring.json';
import { flaggedFilter } from 'owa-locstrings/lib/strings/flaggedfilter.locstring.json';
import {
    hasFilesLabel,
    hasCalendarInviteLabel,
    highPriorityFilterLabel,
} from './getViewFilterDisplay.locstring.json';
import { mentionsMeFilter } from 'owa-locstrings/lib/strings/mentionsFilter.locstring.json';
import { toMeFilter } from 'owa-locstrings/lib/strings/toMeFilter.locstring.json';
import { unreadFilter } from 'owa-locstrings/lib/strings/unreadfilter.locstring.json';
import loc from 'owa-localize';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';

export default function getViewFilterDisplay(filter: ViewFilter): string {
    switch (filter) {
        case 'All':
            return loc(allFilter);
        case 'Unread':
            return loc(unreadFilter);
        case 'Flagged':
            return loc(flaggedFilter);
        case 'ToOrCcMe':
            return loc(toMeFilter);
        case 'HasAttachment':
            return loc(hasFilesLabel);
        case 'HasFile':
            return loc(hasFilesLabel);
        case 'Mentioned':
            return loc(mentionsMeFilter);
        case 'HasCalendarInvite':
            return loc(hasCalendarInviteLabel);
        case 'CopilotHighPriority':
            return loc(highPriorityFilterLabel);
        // Unimplemented filters
        case 'TaskActive':
        case 'TaskOverdue':
        case 'TaskCompleted':
        case 'DeprecatedSuggestions':
        case 'DeprecatedSuggestionsRespond':
        case 'DeprecatedSuggestionsDelete':
        case 'DeprecatedNoClutter':
        case 'DeprecatedClutter':
        case 'SystemCategory':
        case 'UserCategory':
        case 'Pinned':
        case 'Focused':
        case 'Locked':
        case 'Hashtag':
        case 'Liked':
        default:
            return '';
    }
}
