import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PeopleExperiences"*/ './lazyIndex'),
    { name: 'PeopleExperiences' }
);

export type { default as HeaderButtonData } from './models/HeaderButtonData';

export const PersonaHeader = createLazyComponent(lazyModule, m => m.PersonaHeader);
