import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailFolder } from 'owa-graph-schema';
import { isMailboxSharedOrDelegate } from 'owa-account-shared-mailbox-utils';
export function isLocalFolderFavoriteSupported(): boolean {
    return isMonarchMultipleAccountsEnabled() && isFeatureEnabled('fp-local-folder-favorites');
}

export function isLocalFolderFavoriteSupportedForFolder(folder: MailFolder | undefined): boolean {
    if (!folder) {
        return false;
    } else {
        // Local folder favorites are only supported for shared and archive mailboxes
        // and shared or delegate user mailboxes when the shared co-principal account feature is enabled
        return (
            isLocalFolderFavoriteSupported() &&
            folder.mailboxInfo &&
            (isMailboxSharedOrDelegate(folder.mailboxInfo) ||
                folder.mailboxInfo.type === 'ArchiveMailbox' ||
                (isFeatureEnabled('fp-local-pst-favorites') &&
                    folder.mailboxInfo.type === 'PstFile'))
        );
    }
}
