import { isAccountExplicitLogon } from 'owa-account-shared-mailbox-utils';
import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isOptionalExperiencesDisabledForPrivacy } from 'owa-privacy-utils/lib/selectors/isOptionalExperiencesDisabledForPrivacy';

let isAddinsStoreEnabled = false;

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used for show/hide get add-ins button (isCapabilityEnabled) and
 * filtering addins based on OCE privacy settings (isCapabilitySupported)
 */
export const addinsCapability: Capability = {
    isEnabled: (mailboxInfo?: MailboxInfo) => {
        const isExplicitLogon = mailboxInfo ? isAccountExplicitLogon(mailboxInfo) : false;
        const shouldShowstore = isAddinsStoreEnabled && !isExplicitLogon;

        return shouldShowstore; // by default
    },
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        const isDisabledForPrivacy = isOptionalExperiencesDisabledForPrivacy();

        return !isDisabledForPrivacy;
    },
};

export function setAddinsStoreIsEnabled(condition: boolean) {
    isAddinsStoreEnabled = condition;
}
