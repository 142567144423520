import { setMessageMutator, getMessage } from './store';
import loc from 'owa-localize';
import { folderPaneNavigation_noResults } from './FolderPaneNavigation.locstring.json';
import isTextFieldEvent from 'owa-hotkeys/lib/utils/shouldPreventCallback';
import { getStore } from 'owa-mail-folder-store/lib/store/store';
import { logUsage } from 'owa-analytics';

export default class NavigationManager {
    private containerRef: React.RefObject<HTMLElement> | null = null;
    private currentQueryString: string = '';
    private suggestionTimer: ReturnType<typeof setInterval> | null = null;
    private previousQueryString: string = '';
    private previousResults: Element[] | undefined = undefined;
    private previousIndex = -1;

    public init(ref: React.RefObject<HTMLElement>): void {
        this.containerRef = ref;
    }

    public handleInput(event: React.KeyboardEvent): void {
        if (shouldHandleInput(event)) {
            if (shouldStopPropagation(event)) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.currentQueryString += event.key.toLowerCase();
            this.clearSuggestionTimer();
            this.suggestionTimer = setTimeout(() => {
                this.focusNextElementInternal();
                this.clearSuggestionTimer();
            }, 500); // will want to find the best time here
        }
    }

    public resetSearchResults(): void {
        this.previousResults = undefined;
        this.previousIndex = -1;
        this.resetMessage();
    }

    private clearSuggestionTimer = () => {
        if (this.suggestionTimer) {
            clearTimeout(this.suggestionTimer);
            this.suggestionTimer = null;
        }
    };

    private resetMessage() {
        if (getMessage() != undefined) {
            setMessageMutator(undefined);
        }
    }

    private focusNextElementInternal(): void {
        this.resetMessage();
        // If the currrent and previous query strings are different, run a new search
        const isSameQuery = this.currentQueryString == this.previousQueryString;
        // If it's the same query and there are previous results, just focus the next element
        if (isSameQuery && this.previousResults) {
            const newIndex = (this.previousIndex + 1) % this.previousResults.length;
            const nextElement = this.previousResults[newIndex] as HTMLElement;
            nextElement.focus();
            // Save the new index and element
            this.previousIndex = newIndex;
            logUsage('FP_JumpFolder_SameQuery');
        }
        // If the previous results have been cleared (happens if the user expands or collapses), or it's a new query string, run a new search
        else {
            // Search for elements that match the query string plus the focused element
            const selectorString =
                "[data-folder-name^='" + this.currentQueryString + "'], [data-fui-focus-visible]";
            const matches = this.containerRef?.current?.querySelectorAll(selectorString);

            if (matches && matches.length > 0) {
                const focusedElement = this.containerRef?.current?.querySelector(
                    '[data-fui-focus-visible]'
                );

                let focusedElementIsValidSearchResult = false;
                if (focusedElement) {
                    focusedElementIsValidSearchResult =
                        focusedElement
                            .getAttribute('data-folder-name')
                            ?.startsWith(this.currentQueryString) ?? false;
                }

                // If the only element we got back is the focused element and it's not a search result,
                // then there are no results
                if (focusedElement && matches.length == 1 && !focusedElementIsValidSearchResult) {
                    setNoResults();
                }
                // Otherwise, focus on the next element and save state for the next search
                else {
                    this.handleNewSearch(
                        matches,
                        focusedElement,
                        focusedElementIsValidSearchResult
                    );
                }
            } else {
                setNoResults();
            }
        }

        // Reset the query string as the action has been performed
        this.currentQueryString = '';
    }

    private handleNewSearch(
        matches: NodeListOf<Element>,
        focusedElement: Element | null | undefined,
        focusedElementIsValidSearchResult: boolean
    ): void {
        // Default next index is 0 for a new search
        let nextIndex = 0;
        let currentIndex = -1;
        // However, if it's the same query string and we've saved the previous element, navigate to the element after that one
        if (focusedElement) {
            currentIndex = Array.from(matches).indexOf(focusedElement);
            nextIndex = (currentIndex + 1) % matches.length;
        }
        // Focus on the next element
        const nextElement = matches[nextIndex] as HTMLElement;
        nextElement.focus();
        // save the current search to compare against for next time
        this.previousQueryString = this.currentQueryString;

        const arrayToSave = Array.from(matches);
        // If the currently focused element should be part of the search results, then just save the results
        if (focusedElementIsValidSearchResult) {
            this.previousResults = arrayToSave;
            this.previousIndex = nextIndex;
        }
        // Otherwise, we need to remove it from what we're saving so it isn't navigated to in the future
        else {
            if (focusedElement) {
                arrayToSave.splice(currentIndex, 1);
            }
            this.previousResults = arrayToSave;
            this.previousIndex = nextIndex - 1;
        }
        logUsage('FP_JumpFolder_NewQuery_HasResults');
    }
}

const setNoResults = () => {
    setMessageMutator(loc(folderPaneNavigation_noResults));
    logUsage('FP_JumpFolder_NewQuery_NoResults');
};

// This functions is definitely insufficient, need to work through this logic a bit more
const shouldHandleInput = (event: React.KeyboardEvent): boolean => {
    // If there is a text field open, the jump folder behavior should not run
    if (isTextFieldEvent(event, false)) {
        return false;
    }
    // If a context menu is open, don't handle input
    if (getStore().contextMenuState) {
        return false;
    }
    if (event.ctrlKey || event.altKey || event.metaKey) {
        return false;
    }
    switch (event.key) {
        case 'Enter':
        case 'Tab':
        case 'ArrowUp':
        case 'ArrowDown':
        case 'ArrowLeft':
        case 'ArrowRight':
        case 'Del':
        case 'F6':
        case 'Space':
        case 'Shift':
        case 'F9':
        case 'F10':
            return false;
        default:
            return true;
    }
};

const shouldStopPropagation = (event: React.KeyboardEvent): boolean => {
    switch (event.key) {
        case 'n':
        case 'j':
        case 'k':
            return true;
        default:
            return false;
    }
};
