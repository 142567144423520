import {
    timestampSLVWithHeaders,
    scheduleTime,
    timestamp,
    timestampUnread,
    timestampUnreadPinnedItem,
    secondRowThreeColumnRead,
    timestampSLVWithHeaders_deprecated,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';

import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { useMessageListTextStyle } from 'owa-mail-listitem-styles/lib/utils/useMessageListTextStyle';
import { formatRelativeDate } from 'owa-observable-datetime';
import { getConditionalFormattingStylesForText } from 'owa-conditional-formatting';
import { useMailListItemContainerContext } from 'owa-mail-list-item-shared';
import React from 'react';
import { isFeatureEnabled } from 'owa-feature-flags';

export interface MailListItemTimestampProps {
    isPinned: boolean;
    isSnoozed?: boolean;
    isSingleLine?: boolean;
    isUnread?: boolean;
    lastDeliveryTimestamp?: string;
    returnTime: string;
    supportsPinning: boolean;
}

export default observer(function MailListItemTimestamp(props: MailListItemTimestampProps) {
    const {
        isPinned,
        isSnoozed = false,
        isSingleLine = false,
        isUnread = false,
        lastDeliveryTimestamp = '',
        returnTime,
        supportsPinning,
    } = props;

    const showMCLTextStyles = isFeatureEnabled('tri-ml-mcltextstyles');
    const isRelocateHoverActionsFlightEnabled = isFeatureEnabled('tri-mlRelocateHoverActions');
    const { conditionalFormattingModifications } = useMailListItemContainerContext();
    const timestampConditionalFormattingStyles = React.useMemo(() => {
        const conditionalFormattingStyles =
            conditionalFormattingModifications && conditionalFormattingModifications.length > 0
                ? getConditionalFormattingStylesForText(conditionalFormattingModifications)
                : undefined;

        return conditionalFormattingStyles;
    }, [conditionalFormattingModifications]);

    let formattedDate = '';

    // shared styles
    let timestampStyles = classnames(
        isSingleLine
            ? isRelocateHoverActionsFlightEnabled
                ? timestampSLVWithHeaders
                : timestampSLVWithHeaders_deprecated
            : undefined,
        useMessageListTextStyle('Minor')
    );

    /* snoozed item timestamp */
    if (isSnoozed) {
        if (!returnTime) {
            return null;
        }

        try {
            formattedDate = formatRelativeDate(returnTime);
        } catch (_error) {
            formattedDate = '';
        }

        // apply snoozed item timestamp specific styles
        timestampStyles = classnames(timestampStyles, scheduleTime);
    } /* normal timestamp */ else {
        if (returnTime) {
            return null;
        }

        try {
            formattedDate = formatRelativeDate(lastDeliveryTimestamp);
        } catch (_error) {
            formattedDate = '';
        }

        timestampStyles = classnames(
            timestampStyles,
            timestamp,
            (showMCLTextStyles || !isSingleLine) && isUnread ? timestampUnread : undefined,
            (showMCLTextStyles || !isSingleLine) &&
                isUnread &&
                supportsPinning &&
                isPinned &&
                timestampUnreadPinnedItem,
            !isUnread && secondRowThreeColumnRead
        );
    }

    return (
        <span
            className={timestampStyles}
            title={formattedDate}
            style={timestampConditionalFormattingStyles}
        >
            {formattedDate}
        </span>
    );
}, 'MailListItemTimestamp');
