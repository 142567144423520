import { mutatorAction } from 'satcheljs';
import getStore from '../store/Store';

const updateCopilotPrioritizeOnboardedCalloutTargetMutator = mutatorAction(
    'updateCopilotPrioritizeOnboardedCalloutTarget',
    (rowId: string) => {
        if (!getStore().copilotPrioritizeOnboardedCalloutTarget) {
            getStore().copilotPrioritizeOnboardedCalloutTarget = rowId;
        }
    }
);

export default updateCopilotPrioritizeOnboardedCalloutTargetMutator;
