import { getStore } from '../store/store';
import {
    AttachmentFetchStatus,
    type ConversationAttachmentsViewState,
} from '../store/schema/conversationAttachmentsViewState';
import type { MailboxInfo } from 'owa-client-types';

export function getConversationAttachmentsViewState(
    mailboxInfo: MailboxInfo,
    conversationId: string | undefined
): ConversationAttachmentsViewState | undefined {
    if (conversationId) {
        const store = getStore(mailboxInfo);
        if (!store.conversationAttachments.has(conversationId)) {
            store.conversationAttachments.set(conversationId, {
                fetchStatus: AttachmentFetchStatus.NotStarted,
                conversationAttachmentsList: undefined,
                conversationId,
            });
        }
        const conversationAttachmentsViewState = store.conversationAttachments.get(conversationId);
        if (conversationAttachmentsViewState) {
            return conversationAttachmentsViewState;
        }
    }
    return undefined;
}
