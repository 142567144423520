import { createLazyComponent, LazyModule, LazyAction } from 'owa-bundling';

import { launchDomainConnectSetAlias } from './orchestrator/launchCreateEmailFromPopout';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaMailDomainConnect" */ './lazyIndex'),
    { name: 'OwaMailDomainConnect' }
);

export const PremiumDomainConnectFirstStepLightable = createLazyComponent(
    lazyModule,
    m => m.DomainConnectFirstStepLightable
);
export const PremiumDomainConnectFirstStep = createLazyComponent(
    lazyModule,
    m => m.DomainConnectFirstStep
);
export const PremiumDomainConnectSecondStep = createLazyComponent(
    lazyModule,
    m => m.DomainConnectSecondStep
);

export const lazyMountAndShowDCSecondStep = new LazyAction(
    lazyModule,
    m => m.mountAndShowDCSecondStep
);

if (typeof window !== 'undefined' && typeof window.Owa !== 'undefined') {
    window.Owa.launchDomainConnectSetAlias = launchDomainConnectSetAlias;
}
