import type { TableView } from 'owa-mail-list-store';
import { mutatorAction } from 'satcheljs';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setLastAutoFocusedRowKey = mutatorAction(
    'setLastAutoFocusedRowKey',
    function setLastAutoFocusedRowKey(tableView: TableView, lastAutoFocusedRowKey: string) {
        tableView.lastAutoFocusedRowKey = lastAutoFocusedRowKey;
    }
);
