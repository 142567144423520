import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used to determine if mail can be saved
 */
export const saveEmailAsMSGToFileCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        // Check doc-saveAs-EML-MSG and acct-pstFileImportExport feature flag only for PST mailboxInfo
        return (
            isFeatureEnabled('doc-saveAs-EML-MSG') &&
            (mailboxInfo && isLocalDataBackedMailboxInfo(mailboxInfo)
                ? isFeatureEnabled('acct-pstFileImportExport')
                : true)
        );
    },
};
