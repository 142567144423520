import type { MailboxInfo } from 'owa-client-types';
import type CategoryType from 'owa-service/lib/contract/CategoryType';
import { getAccountScopeUserSettings, type ReadOnlyAccountConfiguration } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isCapabilityEnabled } from 'owa-capabilities';
import { categoryListCapability } from 'owa-capabilities-definitions/lib/categoryListCapability';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import updateMasterCategoryListOperation from 'owa-service/lib/operation/updateMasterCategoryListOperation';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { isSharedMailboxInfo } from 'owa-client-types/lib/SharedMailboxInfo';

/**
 * Gets the MasterCategoryList for the given mailboxInfo.
 * @param mailboxInfo The mailbox to fetch the user configuration for
 */

// A map to cache the master category list for shared mailboxes
// to avoid making multiple requests for the same mailbox.
const sharedMailboxMasterCategoryMap = new Map<string, CategoryType[]>();

export default function getMasterCategoryList(mailboxInfo: MailboxInfo): CategoryType[] {
    let userConfiguration: ReadOnlyAccountConfiguration;

    if (!isCapabilityEnabled(categoryListCapability, mailboxInfo)) {
        // if the account doesn't support a category list of its own, fall back to using the
        // category list of the global settings account
        userConfiguration = getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo());
    } else {
        // For shared mailboxes, we should use the cached category list if available
        if (isSharedMailboxInfo(mailboxInfo)) {
            const indexerValue = getKeyForMailboxInfo(mailboxInfo);
            // If not found, return an empty array so we don't show the wrong category data for a different mailbox
            // based on the getAccountScopeUserSettings of the main account mailbox info
            return sharedMailboxMasterCategoryMap.get(indexerValue) || [];
        }

        // if the account supports a category list, use the mailboxInfo to get the user configuration
        userConfiguration = getAccountScopeUserSettings(mailboxInfo);
    }

    const masterCategoryList = userConfiguration?.MasterCategoryList;
    return <CategoryType[]>masterCategoryList?.MasterList || [];
}

export async function initializeMasterCategoryListForSharedMailbox(
    mailboxInfo: MailboxInfo
): Promise<void> {
    // If the mailboxInfo is not a shared mailbox, we don't need to do anything
    if (!isSharedMailboxInfo(mailboxInfo)) {
        return;
    }

    // For now we will use updateMasterCategoryListOperation with an empty request payload to get the master category list
    // since getMasterCategoryList API is deprecated. Once we have a new API to get the master category list, we should use that instead.
    const response = await updateMasterCategoryListOperation(
        { request: {} },
        getMailboxRequestOptions(mailboxInfo)
    );

    // We will set the map even if it fails due to perms so user don't see wrong category
    // data on the dropdown
    const mailboxKey = getKeyForMailboxInfo(mailboxInfo);
    sharedMailboxMasterCategoryMap.set(mailboxKey, response?.MasterList ?? []);
}

// This function is used to generate a unique key for the mailboxInfo object.
// Once getMailboxKeyForMailboxInfo is added from PR: https://outlookweb.visualstudio.com/Outlook%20Web/_git/client-web/pullrequest/187248
// we should use that instead of this function.
function getKeyForMailboxInfo(mailboxInfo: MailboxInfo): string {
    return `${getIndexerValueForMailboxInfo(mailboxInfo)}${mailboxInfo.mailboxSmtpAddress}`;
}
