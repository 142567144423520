import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NativeContextMenu" */ './lazyIndex'),
    { name: 'NativeContextMenu' }
);

export const lazyHandleNativeContextMenu = new LazyAction(
    lazyModule,
    m => m.initializeNativeContextMenu
);
