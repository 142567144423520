import { errorThatWillCauseAlert } from 'owa-trace';
import type {
    ComposeRibbonControlDefProps,
    RibbonControlDefinitionProps,
} from 'owa-mail-ribbon-utils';
import type { MailRibbonTabId, RibbonControlId, RibbonGroupId } from 'owa-ribbon-ids';
import type {
    Layout,
    RibbonControlGroupDefinitionMLR,
    RibbonControlGroupDefinitionSLR,
    RibbonControlProps,
    RibbonFlyoutAnchorProps,
} from 'owa-acui';
import { createRibbonGroupMLR, createRibbonGroupSLR } from './createRibbonGroup';
import type { MailRibbonGroup } from 'owa-mail-ribbon-store-shared-types';
import { owaComputedFn } from 'owa-computed-fn';
import { defaultGroupStringMap } from 'owa-mail-ribbon-utils/lib/getDefaultGroupStringMap';
import { getControlsToDisplay } from './getControlsToDisplay';
import loc from 'owa-localize';
import { shouldShowRibbonGroup } from 'owa-mail-ribbon-utils/lib/shouldShowRibbonGroup';

/**
 * Retrieves the SLR groups within a given tab, as well as the actions that fall under each group.
 * @param tabId the MailRibbonTabId for the tab that is calling this function
 * @param groups the given tab's configuration as is saved in that tab's store
 * @param getControlDefinition function that returns the control definition given a controlId
 * @param props properties for ribbonIdToControlMap
 * @returns an object that contains the sub-groups and corresponding actions of the given tab
 */
export const getTabGroups = owaComputedFn(function getTabGroups<
    T extends RibbonControlDefinitionProps
>(
    tabId: MailRibbonTabId,
    groups: MailRibbonGroup[],
    getControlDefinition: (
        controlId: RibbonControlId,
        tabId: MailRibbonTabId,
        props: T
    ) => RibbonControlProps | undefined,
    props: T
): RibbonControlGroupDefinitionSLR[] {
    const tabGroups: RibbonControlGroupDefinitionSLR[] = new Array();

    for (const group of groups) {
        if (!shouldShowRibbonGroup(tabId, group.groupId, props)) {
            continue;
        }

        const groupDefinition: RibbonControlGroupDefinitionSLR = createRibbonGroupSLR(
            tabId,
            group.groupId,
            (props as ComposeRibbonControlDefProps).composeViewState?.editorId
        );

        groupDefinition.title = getGroupTitle(group);
        groupDefinition.hasOwnOverflow = group.hasOwnOverflow;
        // Get button objects that fall under the current group (stored in group)
        groupDefinition.controls = getControlsToDisplay(tabId, group, getControlDefinition, props);

        tabGroups.push(groupDefinition);
    }

    return tabGroups;
});

/**
 * Retrieves the MLR groups within a given tab, as well as the actions that fall under each group.
 * @param tabId the MailRibbonTabId for the tab that is calling this function
 * @param groups the given tab's configuration as is saved in that tab's store
 * @param getControlDefinition function that returns the control definition given a controlId
 * @param getGroupLayouts function that returns the layout(s) given a controlId, or undefined, if none
 * @param getCommonGroupFlyout function that returns the "collapse" flyoutAnchor definition for a controlId, or undefined, if none
 * @param props properties for ribbonIdToControlMap
 * @returns an object that contains the sub-groups and corresponding actions of the given tab
 */
export const getMLRTabGroups = owaComputedFn(function getMLRTabGroups<
    T extends RibbonControlDefinitionProps
>(
    tabId: MailRibbonTabId,
    groups: MailRibbonGroup[],
    getControlDefinition: (
        controlId: RibbonControlId,
        tabId: MailRibbonTabId,
        props: T
    ) => RibbonControlProps | undefined,
    getGroupLayouts: (groupId: RibbonGroupId, props: T) => Layout[] | undefined,
    getCommonGroupFlyout: (groupId: RibbonGroupId, props: T) => RibbonFlyoutAnchorProps | undefined,
    props: T
): RibbonControlGroupDefinitionMLR[] {
    const tabGroups: RibbonControlGroupDefinitionMLR[] = new Array();

    for (const group of groups) {
        if (!shouldShowRibbonGroup(tabId, group.groupId, props)) {
            continue;
        }

        // Get button objects that fall under the current group (stored in group)
        const controls: RibbonControlProps[] = getControlsToDisplay(
            tabId,
            group,
            getControlDefinition,
            props
        );

        /**
         * Some groups only contain buttons which conditionally appear, whether because of type of account (Enterprise vs. Consumer) or
         * functionality that the admin may opt in/out of. In these cases, we want to hide the MLR group.
         *
         * Note: in SLR, this is only an issue for the customizer, which will show empty groups in these cases. We don't have a great solution
         * for this yet, since Multi-Account support is still being developed, and will impact the solution.
         */
        if (controls.length === 0) {
            continue;
        }

        const groupDefinition: RibbonControlGroupDefinitionMLR = createRibbonGroupMLR(
            tabId,
            group.groupId,
            (props as ComposeRibbonControlDefProps).composeViewState?.editorId
        );

        groupDefinition.title = getGroupTitle(group);
        groupDefinition.controls = controls;

        // Get layouts (if any)
        groupDefinition.layouts = getGroupLayouts(group.groupId, props);

        // Get collapse Flyout info (if any)
        groupDefinition.commonGroupFlyout = getCommonGroupFlyout(group.groupId, props);

        tabGroups.push(groupDefinition);
    }

    return tabGroups;
});

function getGroupTitle(group: MailRibbonGroup): string {
    // If groupName isn't undefined, that means user has changed the name away from the default group name.
    if (group.groupName != undefined) {
        return group.groupName;
    }

    if (defaultGroupStringMap.has(group.groupId)) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return loc(defaultGroupStringMap.get(group.groupId)!);
    }

    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * The error name (message) must be a string literal (no variables in it).
     *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
    errorThatWillCauseAlert(
        'Group ' + group.groupId + ' was not added to defaultGroupStringMap.ts'
    );
    return '';
}
