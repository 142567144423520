import onInitialTableLoadComplete from 'owa-mail-table-loading-actions/lib/actions/onInitialTableLoadComplete';
import loadInitialRowsFromServer from 'owa-mail-list-actions/lib/actions/table/loadInitialRowsFromServer';
import { INITIAL_LOAD_ROW_COUNT } from 'owa-mail-triage-common';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import {
    getGQLPagingInfoForQueryRowsForJumpTo,
    resetTableViewProperties,
} from 'owa-mail-list-store';
import type { MailFolderTableQuery, TableView } from 'owa-mail-list-store';

export default function executeJumpTo(tableView: TableView, matchingString: string) {
    resetTableViewProperties(tableView);

    const tableQuery = tableView.tableQuery as MailFolderTableQuery;

    const isConversationView = tableView.tableQuery.listViewType === 0;

    const customPagingInfo = getGQLPagingInfoForQueryRowsForJumpTo(
        INITIAL_LOAD_ROW_COUNT,
        isConversationView,
        matchingString,
        tableQuery.sortBy
    );

    loadInitialRowsFromServer(
        tableView,
        false /*isTablePrefetched*/,
        onInitialTableLoadComplete,
        undefined /*initialSessionData*/,
        false /*skipGqlTimings*/,
        customPagingInfo
    );
}
