import type { UserOutlookClientsState } from './schema/UserOutlookClientsState';
import { createStore } from 'owa-satcheljs';

const initialUserOutlookClientsState: UserOutlookClientsState = {
    clients: [],
    isCardDismissed: false,
};

// todo: rename to getUserOutlookClientsStore
export const userOutlookClientsStore = createStore<UserOutlookClientsState>(
    'OutlookClientsStore',
    initialUserOutlookClientsState
);
