import type { RibbonTabDefinitionBase } from 'owa-acui';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, Multiline, SingleLine } from 'owa-command-ribbon';
import loc from 'owa-localize';
import { getUniqueTabId } from 'owa-mail-ribbon-utils/lib/getUniqueTabId';
import { ComposeTabs, MailRibbonTabTitleMap } from './composeTabUtils';

const getComposePlaceholderTabs = owaComputedFn(function getComposePlaceholderTabs(
    editorId: string,
    isMlr: boolean
): RibbonTabDefinitionBase[] {
    const tabType = isMlr ? Multiline : SingleLine;

    return ComposeTabs.map(tabId => {
        const tabTitle = MailRibbonTabTitleMap.get(tabId) ?? '';
        return {
            type: tabType,
            id: getUniqueTabId(tabId, editorId, undefined /* itemId*/, undefined /* isPopout */),
            title: loc(tabTitle),
            controlGroups: {
                type: tabType,
                groups: [],
            },
            initialScalingSteps: [],
            scalingSteps: [],
            keytipProps: constructKeytip([], tabId, true /* hasMenu */),
            tabType: 'default',
        };
    });
});
export default getComposePlaceholderTabs;
