import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CustomSearchHeaders" */ './lazyIndex'),
    { name: 'CustomSearchHeaders' }
);

// Delay loaded components
export const SenderGroupingsHeader = createLazyComponent(lazyModule, m => m.SenderGroupingsHeader);
export const MailBulkTriageHeader = createLazyComponent(lazyModule, m => m.MailBulkTriageHeader);

// Utils
export { default as shouldShowSenderGroupingsHeader } from './utils/shouldShowSenderGroupingsHeader';

// Actions
export { onBulkTriageMailListLoaded } from './actions/onBulkTriageMailListLoaded';
