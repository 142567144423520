import type { SharedMailboxInfo } from 'owa-client-types/lib/SharedMailboxInfo';
import {
    accountSourceDataTypeChecker,
    getCoprincipalAccountForMailboxInfo,
} from 'owa-account-source-list-store';

/**
 * Checks for the origins of the shared mailbox, and returns if it is automapped and/or manually added
 * @param mailboxInfo Specifies the MailboxInfo to check
 * @returns Object that describes if the mailbox is automapped or manually added
 */
export function getSharedMailboxOrigin(mailboxInfo: SharedMailboxInfo): {
    isAutomapped: boolean;
    isManuallyAdded: boolean;
} {
    const result = { isAutomapped: false, isManuallyAdded: false };
    const account = getCoprincipalAccountForMailboxInfo(mailboxInfo);
    if (accountSourceDataTypeChecker.isM365UserMailbox(account)) {
        account.sharedSources.sources.forEach(source => {
            if (
                source.mailboxInfo.mailboxSmtpAddress == mailboxInfo.mailboxSmtpAddress &&
                accountSourceDataTypeChecker.isM365SharedMailbox(source)
            ) {
                result.isAutomapped = result.isAutomapped || source.isAutomapped;
                result.isManuallyAdded = result.isManuallyAdded || !source.isAutomapped;
            }
        });
    }

    return result;
}
