import { isFeatureEnabled } from 'owa-feature-flags';
import { getAppBarPinnedAppIdsFromUserSettings } from './getAppBarPinnedAppIdsFromUserSettings';
import type { MailboxInfo } from 'owa-client-types';

export function isAppBarPinnedAppsEnabled(mailboxInfo?: MailboxInfo): boolean {
    return (
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'mos-mos3AppService' */
        isFeatureEnabled('mos-mos3AppService', mailboxInfo) &&
        !!getAppBarPinnedAppIdsFromUserSettings(mailboxInfo)
    );
}
