import { isBootFeatureEnabled } from 'owa-metatags';
import { lazyLogProfilerDatapoint } from '../lazyFunctions';
import type {
    StartSelfProfilerFunction,
    SelfProfilerOptions,
    EndProfileFunction,
} from 'owa-analytics-types';
import { getAnalyticsFlightsAndAppSettings } from 'owa-analytics-core/lib/settings/getAnalyticsFlightsAndAppSettings';

const DEFAULT_MAX_RUNS_PER_SESSION = 25;

let datapointLogged = false;
let isRunning = false;
let runsInSession = 0;

export const startSelfProfiler: StartSelfProfilerFunction = function startSelfProfiler(
    scenario: string,
    options: SelfProfilerOptions
): EndProfileFunction | null {
    // make sure the boot flight is there. This boot flight indicates when we send the
    // document policy header down. And we also need to make sure the browser supports it
    // and we will only run the profiler a limited number of times per session as it might
    // be expensive
    const maxRunsPerPerSession =
        getAnalyticsFlightsAndAppSettings()?.maxPerfProfilesPerSession ??
        DEFAULT_MAX_RUNS_PER_SESSION;
    if (
        runsInSession++ > maxRunsPerPerSession ||
        !isBootFeatureEnabled('jsselfprofiler') ||
        typeof self.Profiler != 'function' ||
        typeof performance?.now != 'function' ||
        datapointLogged ||
        isRunning
    ) {
        return null;
    }

    try {
        isRunning = true;
        const startTime = performance.now();
        const profiler = new self.Profiler({
            sampleInterval: 10, // Target sampling every 10ms
            maxBufferSize: 10 * 100, // Cap at ~10s worth of samples
        });
        return async (shouldLog: boolean) => {
            const duration = Math.floor(performance.now() - startTime);
            const trace = await profiler.stop();
            isRunning = false;
            if (shouldLog && duration > options.minDuration && !datapointLogged) {
                datapointLogged = true;
                try {
                    const traceString = JSON.stringify(trace);
                    lazyLogProfilerDatapoint.importAndExecute(scenario, duration, traceString);
                } catch {
                    // do nothing if we can't parse the json
                }
            }
        };
    } catch {
        // if the profiler is not enabled, then it might throw an error
        return null;
    }
};
