import { createStore } from 'owa-satcheljs';
import type { GroupFoldersStoreByMailboxInfo } from './schema/GroupFoldersStoreByMailboxInfo';
import type { GroupFoldersStore } from './schema/GroupFoldersStore';
import type { AccountKey } from 'owa-client-types';

const defaultLeftNavGroupFoldersByMailboxInfo: GroupFoldersStoreByMailboxInfo = {
    folderTableByMailboxInfo: new Map<AccountKey, GroupFoldersStore>([]),
};

export const getLeftNavGroupFolders = createStore<GroupFoldersStoreByMailboxInfo>(
    'leftNavGroupFoldersStoreByMailboxInfo',
    defaultLeftNavGroupFoldersByMailboxInfo
);
