import { isFeatureEnabled } from 'owa-feature-flags';
import type { StrictLaunchTileBehavior } from '../types';
import { isAppBarPinnedAppsEnabled } from 'owa-m365-acquisitions/lib/pinnedApps/isAppBarPinnedAppsEnabled';
import type { LaunchTileBehaviorParams } from '../launchTileBehaviorParams';
import {
    isAppBarDisplayModeColorfulIconsEnabled,
    isAppBarDisplayModeShowLabelsEnabled,
} from 'owa-m365-acquisitions/lib/utils/isAppBarDisplayModeColorfulIconsEnabled';

export const getLaunchTilesDefaultBehavior: (
    params?: LaunchTileBehaviorParams
) => StrictLaunchTileBehavior = params => ({
    __typename: 'LaunchTileBehavior',
    transparent: true,
    pinningEnabled: isAppBarPinnedAppsEnabled(),
    shouldShowLabel: params?.shouldShowLabel ?? isAppBarDisplayModeShowLabelsEnabled(),
    platformAppCommands: {
        __typename: 'PlatformAppCommands',
        aboutCommandEnabled: true,
        uninstallCommandEnabled: true,
    },
    useHubColorForSelectedTileLabel: true,
    dragDropEnabled: isAppBarPinnedAppsEnabled(),
    useOutlineIcon: params ? !params?.useColorfulIcons : !isAppBarDisplayModeColorfulIconsEnabled(),
    overrideAppBarDefaultSort: isFeatureEnabled('mos-flyoutMRU'),

    // The localized version of these strings is provided by the TileContextMenu type policy
    tileContextMenu: {
        __typename: 'TileContextMenu',
        openInNewTabItemLabel: 'Open in new tab',
        aboutAppItemLabel: 'About',
        pinAppItemLabel: 'Pin',
        unpinAppItemLabel: 'Unpin',
        uninstallAppItemLabel: 'Uninstall',
    },
});
