import { type MailboxInfo, getIndexerValueForMailboxInfo } from 'owa-client-types';
import isAddinMultiAccountEnabled from 'owa-feature-flags/lib/utils/isAddinMultiAccountEnabled';
import getGlobalSettingsAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';
import { store, multiAccountStore } from './store';
import {
    type CachedAcquisitionsState,
    LoadState,
    NetworkCallState,
} from './schema/cachedAcquisitionsState';

export function getCachedAcquisitionsState(mailboxInfo?: MailboxInfo): CachedAcquisitionsState {
    if (!isAddinMultiAccountEnabled()) {
        return store;
    }

    if (!mailboxInfo) {
        mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    }

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    let cachedAcquisitionsState = multiAccountStore.get(key);
    const defaultCachedAcquisitionsState: CachedAcquisitionsState = {
        loadState: LoadState.Unloaded,
        networkCallState: NetworkCallState.Unqueued,
    };

    if (!cachedAcquisitionsState) {
        multiAccountStore.set(key, defaultCachedAcquisitionsState);
        cachedAcquisitionsState = multiAccountStore.get(key) || defaultCachedAcquisitionsState;
    }

    return cachedAcquisitionsState;
}
