import { createLazyComponent, LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "MeetNow" */ './lazyIndex'), {
    name: 'MeetNow',
});

export const MeetNowSuiteHeaderButton = createLazyComponent(
    lazyModule,
    m => m.MeetNowSuiteHeaderButton
);

export const lazyIsMeetNowEnabled = new LazyImport(lazyModule, m => m.isMeetNowEnabled);
