import strings from './getRollupText.locstring.json';
import type InboxViewType from 'owa-service/lib/contract/InboxViewType';
import { getStore } from '../store/store';
import loc, { format } from 'owa-localize';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Get rollup text for current folder
 */
export default function getRollupText(viewType: InboxViewType, mailboxInfo: MailboxInfo): string {
    const unseenCount = getStore(mailboxInfo).unseenCountToDisplay;
    const shouldShowUnseenCount = !isFeatureEnabled('tri-fo-pivot-counts-b');

    const shouldUseNewRollupText =
        isFeatureEnabled('tri-fo-style-updates') && isFeatureEnabled('tri-fo-rollup-text');

    let focusedRollupTextToUse = loc(strings.focusedNewMessagesRollupText); // Control for both experiments
    let otherRollupTextToUse = loc(strings.otherNewMessagesRollupText); // Control for both experiments

    if (shouldUseNewRollupText) {
        if (shouldShowUnseenCount) {
            focusedRollupTextToUse = format(loc(strings.focusedRollupText), unseenCount); // Focused Emails ({0})
            otherRollupTextToUse = format(loc(strings.otherRollupText), unseenCount); // Other Emails ({0})
        } else {
            focusedRollupTextToUse = loc(strings.focusedRollupTextWithoutUnseenCount); // Focused Emails
            otherRollupTextToUse = loc(strings.otherRollupTextWithoutUnseenCount); // Other Emails
        }
    } else {
        if (shouldShowUnseenCount) {
            focusedRollupTextToUse = format(loc(strings.focusedNewMessagesRollupText), unseenCount); // Focused: New messages ({0})
            otherRollupTextToUse = format(loc(strings.otherNewMessagesRollupText), unseenCount); // Other: New messages ({0})
        } else {
            focusedRollupTextToUse = loc(strings.focusedNewMessagesRollupTextWithoutUnseenCount); // Focused: New messages
            otherRollupTextToUse = loc(strings.otherNewMessagesRollupTextWithoutUnseenCount); // Other: New messages
        }
    }

    switch (viewType) {
        case 1:
            return focusedRollupTextToUse;
        case 2:
            return otherRollupTextToUse;
    }

    return '';
}
