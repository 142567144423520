import CategoryIcon from 'owa-categories/lib/components/CategoryIcon';
import { getCategoryIdFromName } from 'owa-categories/lib/utils/categoryIdNameConverter';
import getMasterCategoryList from 'owa-categories/lib/utils/getMasterCategoryList';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import {
    isCategoryInFavorites,
    lazyAddFavoriteCategory,
    lazyRemoveFavoriteCategory,
    ToggleFavoriteButton,
} from 'owa-favorites';
import { isFavoritingInProgress } from 'owa-mail-favorites-store';
import { useComputed } from 'owa-react-hooks/lib/useComputed';
import React from 'react';
import {
    contentContainer,
    categoryIcon,
    categoryName as styles_categoryName,
    medium,
    compact,
    toggleFavoriteButton,
} from './MailListCategoryHeaderSecondRowContent.scss';

import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export interface CategoryHeaderSecondRowProps {
    categoryName: string;
    containerCssClass: string;
}

export default observer(function MailListCategoryHeaderSecondRowContent(
    props: CategoryHeaderSecondRowProps
) {
    const mailboxInfo = getModuleContextMailboxInfo();
    const categoryId = useComputed((): string => {
        return getCategoryIdFromName(props.categoryName, getMasterCategoryList(mailboxInfo));
    });
    const isCategoryFavorite = useComputed((): boolean => {
        return isCategoryInFavorites(categoryId.get());
    });
    const toggleFavoriteState = async (evt: React.MouseEvent<unknown>) => {
        evt.stopPropagation();
        if (isFavoritingInProgress(categoryId.get())) {
            // Do nothing: favoriting is in progress
            return;
        }
        if (isCategoryFavorite.get()) {
            const removeFavoriteCategory = await lazyRemoveFavoriteCategory.import();
            removeFavoriteCategory(categoryId.get(), 'CategoryHeader');
        } else {
            const addFavoriteCategory = await lazyAddFavoriteCategory.import();
            addFavoriteCategory(categoryId.get(), 'CategoryHeader', mailboxInfo);
        }
    };
    const categoryName = props.categoryName;
    const containerClass = classnames(props.containerCssClass, contentContainer);
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onClick = (evt: React.MouseEvent<unknown>) => toggleFavoriteState(evt);
    return (
        <div className={containerClass}>
            <CategoryIcon
                categoryName={categoryName}
                mailboxInfo={mailboxInfo}
                iconClassName={categoryIcon}
            />
            <div
                className={classnames(
                    styles_categoryName,
                    getDensityModeCssClass(undefined, medium, compact)
                )}
                title={categoryName}
            >
                {categoryName}
            </div>

            {categoryId.get() && (
                <ToggleFavoriteButton
                    isInFavorites={isCategoryFavorite.get()}
                    onClick={onClick}
                    buttonStyles={toggleFavoriteButton}
                    isCategory={true}
                    mailboxInfo={mailboxInfo}
                />
            )}
        </div>
    );
},
'MailListCategoryHeaderSecondRowContent');
