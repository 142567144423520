import { getItemToShowFromNodeId } from '../utils/conversationsUtils';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Returns item id corresponding to the id passed for the expanded thread or itempart.
 * @param id node id of itempart for second level expansion, or thread id if first level expansion
 * @param isFirstLevelExpansion
 */
export default function getItemIdForMailList(id: string, isFirstLevelExpansion: boolean): string {
    return isFirstLevelExpansion && !isFeatureEnabled('mon-change-forkIds')
        ? id
        : // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
          // -> Error TS2532 (12,41): Object is possibly 'undefined'.
          // @ts-expect-error
          getItemToShowFromNodeId(id)?.ItemId?.Id;
}
