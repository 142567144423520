import type { CalendarsUnionType } from 'owa-graph-schema';

export function canDelegateManageCategories(calendar: CalendarsUnionType): boolean {
    return (
        !!(
            (calendar.__typename === 'LocalCacheForRemoteCalendarEntry' ||
                calendar.__typename === 'LinkedCalendarEntryV2') &&
            calendar.CanManageCategories
        ) ||
        !!(
            calendar.__typename === 'LinkedCalendarEntry' &&
            calendar.EffectiveRights?.ManageCategories
        )
    );
}
