import { LazyAction, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import {
    onStartChatViaSipProtocol,
    onStartCallViaSipProtocol,
    onStartChatViaImAddressUrl,
    onStartCallViaPhoneUrl,
} from 'owa-nova-actions';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaTeamsIntegration" */ './lazyIndex'),
    { name: 'OwaTeamsIntegration' }
);

registerLazyOrchestrator(onStartChatViaSipProtocol, lazyModule, m => m.startChatViaSipProtocol);
registerLazyOrchestrator(onStartCallViaSipProtocol, lazyModule, m => m.startCallViaSipProtocol);
registerLazyOrchestrator(onStartCallViaPhoneUrl, lazyModule, m => m.startCallViaPhoneUrl);
registerLazyOrchestrator(onStartChatViaImAddressUrl, lazyModule, m => m.startChatViaImAddressUrl);

// Lazily-loaded Components
export const lazyChatAroundEmail = new LazyAction(lazyModule, m => m.chatAroundEmail);
export const lazyShareEmailToTeamsChat = new LazyAction(lazyModule, m => m.shareEmailToTeamsChat);
export const lazyRunTeamsControlChecks = new LazyAction(lazyModule, m => m.runTeamsControlChecks);
export const lazyShowCannotShareNotification = new LazyAction(
    lazyModule,
    m => m.showCannotShareNotification
);
