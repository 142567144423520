export const sortBySortColumnv2 = "vi7fR";
export const sortOrderv2 = "EvgcHe";
export const alphabeticalOrderv2 = "mjoIO";
export const reversedAlphabeticalOrderv2 = "gpj1Yd";
export const oldestOnTopv2 = "Ns0etb";
export const newestOnTopv2 = "E1QZDd";
export const largestOnTopv2 = "GX28re";
export const smallestOnTopv2 = "z3iJLb";
export const highOnTopv2 = "kjKLpe";
export const lowOnTopv2 = "Ysdchc";
export const soonestOnTopv2 = "Bcepie";
export const latestOnTopv2 = "fznsCd";
export const dateSortColumnv2 = "ibb29d";
export const fromSortColumnv2 = "EAOAmc";
export const sizeSortColumnv2 = "VZFxOb";
export const importanceSortColumnv2 = "Cm3j$b";
export const categorySortColumnv2 = "ybGTWb";
export const toSortColumnv2 = "Nw9Vne";
export const returnTimeSortColumnv2 = "dFI8";
export const subjectSortColumn = "Ul3twd";
export const prioritySortColumn = "ENKZZ";
export const sortColumnMenuButtonLabel = "$3mli";
export const sortColumnMenuTooltip = "oZSndb";
export const newLabel = "uaFPde";
export default {sortBySortColumnv2,sortOrderv2,alphabeticalOrderv2,reversedAlphabeticalOrderv2,oldestOnTopv2,newestOnTopv2,largestOnTopv2,smallestOnTopv2,highOnTopv2,lowOnTopv2,soonestOnTopv2,latestOnTopv2,dateSortColumnv2,fromSortColumnv2,sizeSortColumnv2,importanceSortColumnv2,categorySortColumnv2,toSortColumnv2,returnTimeSortColumnv2,subjectSortColumn,prioritySortColumn,sortColumnMenuButtonLabel,sortColumnMenuTooltip,newLabel};