import { getGroupMailboxInfoFromTableQuery, getGroupIdFromTableQuery } from 'owa-group-utils';
import getGroupHeaderCommandBarStore from 'owa-group-header-store/lib/store/CommandBarStore';
import GroupHeaderNavigationButton from 'owa-group-header-store/lib/store/schema/NavigationButton';
import { getGroupsWarmUpTime, wasGroupWarmedUp } from 'owa-group-prefetch-store';
import loadInitialMailTable from './loadInitialMailTable';
import type { TableView } from 'owa-mail-list-store';
import type { ActionSource } from 'owa-mail-store';
import type { OnInitialTableLoadComplete } from 'owa-mail-loading-action-types';
import type { PerformanceDatapoint } from 'owa-analytics';
import { returnTopExecutingActionDatapoint } from 'owa-analytics';
import {
    switchGroupDatapointName,
    navigateFromMeToWeDatapointName,
} from 'owa-group-common/lib/constants';
import { Module } from 'owa-workloads-module';
import getMyOrgGroups from 'owa-groups-left-nav-store/lib/selectors/getMyOrgGroups';
import { isUserGroupOwner } from 'owa-groups-shared-store/lib/selectors/isUserGroupOwner';

/**
 * Performs the first load for a group table
 * @param tableView - the given mail tableView
 * @param onInitialTableLoadComplete - callback for when the initial table load has completed
 * @param actionSource action that initiated the switch folder action
 * @return a promise that resolves when the load initial table has completed either from server or from cache
 */
export default function loadInitialGroupTable(
    tableView: TableView,
    onInitialTableLoadComplete: OnInitialTableLoadComplete,
    actionSource: ActionSource,
    moduleContext?: Module
): Promise<void> {
    // Update SwitchGroup or NavigateFromMeToWe datapoint with
    // information about which group module we are booting into
    const datapoint = returnTopExecutingActionDatapoint((dp: PerformanceDatapoint) => {
        return (
            dp.getEventName() == navigateFromMeToWeDatapointName ||
            dp.getEventName() == switchGroupDatapointName
        );
    });

    const selectedNavButton = getGroupHeaderCommandBarStore().navigationButtonSelected;
    if (datapoint) {
        const groupId = getGroupIdFromTableQuery(tableView.tableQuery);
        if (groupId) {
            const mailboxInfo = getGroupMailboxInfoFromTableQuery(groupId, tableView.tableQuery);

            datapoint?.addCustomProperty('noOfGroups', getMyOrgGroups(mailboxInfo).length);
            datapoint?.addCustomProperty('isGroupOwner', isUserGroupOwner(mailboxInfo, groupId));
        }

        datapoint?.addCustomProperty('GroupModule', GroupHeaderNavigationButton[selectedNavButton]);

        if (groupId && wasGroupWarmedUp(groupId)) {
            const groupsWarmUpTime = getGroupsWarmUpTime();
            datapoint?.addCustomProperty('TimeSinceWarmUp', Date.now() - groupsWarmUpTime);
        }
    }

    // If we are booting into a non-email module, do nothing
    let promiseToReturn = Promise.resolve();

    if (
        selectedNavButton === GroupHeaderNavigationButton.Email ||
        moduleContext === Module.Groups
    ) {
        promiseToReturn = loadInitialMailTable(tableView, onInitialTableLoadComplete, actionSource);
    }

    return promiseToReturn;
}
