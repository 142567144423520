import type CategoryType from 'owa-service/lib/contract/CategoryType';

export const getJumpToCategoryOptions = (categoryList: CategoryType[]) => {
    const categoryOptions: {
        value: string;
        text: string;
    }[] = [];

    categoryList.map(category => {
        if (category.Name) {
            categoryOptions.push({
                value: category.Name,
                text: category.Name,
            });
        }
    });

    return categoryOptions;
};
