import { mutatorAction } from 'satcheljs';
import copilotSettingStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import type { ImplementedCopilotFeatures } from '../store/store';

const onOptInExperienceCompletedForScenarioMutator = mutatorAction(
    'onOptInExperienceCompletedForScenarioMutator',
    (scenario: ImplementedCopilotFeatures, mailboxInfo: MailboxInfo) => {
        const store = copilotSettingStore(mailboxInfo);

        if (!!store && store.isEnabled) {
            const featureInfo = store.featureInfo.get(scenario);

            if (featureInfo) {
                featureInfo.optInEnabled = false;
            }
        }
    }
);

export default onOptInExperienceCompletedForScenarioMutator;
