import type { UndoStore } from '../store/undoStore';
import { getUndoStore } from '../store/undoStore';
import tombstoneOperations from 'owa-mail-list-tombstone';
import type { ActionSource } from 'owa-mail-store';
import type ApplyMessageActionResponseMessage from 'owa-service/lib/contract/ApplyMessageActionResponseMessage';
import type MoveItemResponse from 'owa-service/lib/contract/MoveItemResponse';
import { mutatorAction } from 'satcheljs';
import { PerformanceDatapoint } from 'owa-analytics';
import { isNotificationChannelInitialized } from 'owa-mail-notification-channel-ready/lib/isNotificationChannelInitialized';
import undoWhenNotificationChannelNotReady from 'owa-mail-actions/lib/table-loading/undoWhenNotificationChannelNotReady';

export interface UndoStoreState {
    store: UndoStore;
}

declare type UndoResponseType = ApplyMessageActionResponseMessage | MoveItemResponse | void;

/**
 * Only supporting a "stack" of one (last undo action)
 */
let lastUndoAction: (() => Promise<UndoResponseType>) | null;

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const setUndoableAction = mutatorAction(
    'setUndoableAction',
    (hasUndoableActionToSet: boolean, actionFolderId: string | null) => {
        const store = getUndoStore();
        store.hasUndoableAction = hasUndoableActionToSet;
        store.undoableActionFolderId = actionFolderId;
    }
);

export function addActionToUndoStack(
    undoAction: (() => Promise<UndoResponseType>) | null,
    actionFolderId: string | null
) {
    if (undoAction !== null) {
        lastUndoAction = undoAction;
        setUndoableAction(true, actionFolderId);
    }
}

/**
 * Gets last undo action
 * @return Last undo action
 */
export function getLastUndoAction(): (() => Promise<UndoResponseType>) | null {
    return lastUndoAction;
}

/**
 * Clears last undoable action (to null)
 */
export function clearLastUndoableAction() {
    lastUndoAction = null;
    setUndoableAction(false, null);
}

/**
 * Returns if last undoable action is empty
 * @return If last undoable action is empty
 */
export function hasUndoableAction(): boolean {
    return getUndoStore().hasUndoableAction;
}

/**
 * Undos the last undoable action (if it exists).
 */
export function undo(actionSource: ActionSource): Promise<void> {
    const dp = new PerformanceDatapoint('TnS_Undo');
    dp.addCustomData([actionSource]);
    // Need to remove everything for the folder where the undo action is being taken from tombstone because if the action is being performed on a row that is
    // in tombstone then we will not honor the row notification when the action gets undone so row won't come back.
    const { undoableActionFolderId } = getUndoStore();
    if (undoableActionFolderId) {
        tombstoneOperations.clearMapForFolderMutator(undoableActionFolderId);
    } else {
        // There are cases when we do not have folder id e.g deleteItems which can be performed from various places like RP, fileshub etc.
        // Once (VSO: 45716 - [Refactor] Remove usage of geSelectedTableView from delete item) is fixed we shall pass correct folder id to clear
        tombstoneOperations.removeAllMutator();
    }

    const undoAction = getLastUndoAction();

    // undoAction can be null if lazy loading of undo doesn't finish before user performs another action
    // that clears the undo stack.
    if (undoAction !== null) {
        // Perform undo
        undoAction().then(() => {
            if (!isNotificationChannelInitialized()) {
                undoWhenNotificationChannelNotReady();
            }
        });

        // Must clear stack so user can't try to undo same action again. Also clears UI.
        clearLastUndoableAction();
    }
    dp.end();

    return Promise.resolve();
}

/**
 * This is just exported for test purposes and should not be invoked in product code
 */
export function test_reset() {
    lastUndoAction = null;
}
