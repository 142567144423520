import React from 'react';
import classNames from 'owa-classnames';
import { Virtuoso } from 'react-virtuoso';
import type { VirtuosoHandle, ListRange } from 'react-virtuoso';
import { observer } from 'owa-mobx-react';
import type {
    VirtualizedLoadMoreListViewProps,
    VirtualizedLoadMoreListViewRef,
} from './VirtualizedLoadMoreListView.tsx';
import { rowWrapper, listAutoSizer, row } from './VirtualizedLoadMoreListView.scss';
import { useElementSizeTracker } from 'owa-react-hooks/lib/useElementSizeTracker';
import { isCurrentCultureRightToLeft } from 'owa-localize';
import { safeRequestAnimationFrame } from 'owa-performance';

export default observer(
    React.forwardRef<
        VirtualizedLoadMoreListViewRef,
        VirtualizedLoadMoreListViewProps<any, any> // this shouldn't be 'any' but couldn't figure out how to do the types
    >(function VirtuosoLoadMoreListView<TListProps, TSubComponentProps>(
        props: VirtualizedLoadMoreListViewProps<TListProps, TSubComponentProps>,
        ref: React.Ref<VirtualizedLoadMoreListViewRef>
    ) {
        const {
            // Same as VirtualizedLoadMoreListView version
            className,
            currentLoadedIndex,
            estimatedRowHeight,
            getCanLoadMore,
            getCanLoadMorePrevious,
            isLoadRowsInProgress,
            onLoadMoreRows,
            onScroll,
            focusedRowKeyIndex,
            focusedNodeId,
            updateStartAndEndIndices,
            activeAnimationsCount,
            //loadedStartIndex,

            // New props for VirtuosoLoadMoreListView
            itemIds,
            listProps,
            loadingComponent,
            onRenderRow,
            onRenderHeader,
            PreRowsComponent,
            MidRowsComponent,
            midRowsComponentRowNumber,
            PostRowsComponent,
            subComponentProps,
            hiddenRowIndices,
        } = props;

        const virtuosoRef = React.useRef<VirtuosoHandle>(null);
        const scrollingRegionRef = React.useRef<HTMLElement | Window | null>(null);
        const listAutoSizerRef = React.useRef<HTMLDivElement>(null);

        // const [itemIds, setitemIds] = React.useState<string[]>(
        //     /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
        //      * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
        //      *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
        //     itemIds
        // );

        // React.useEffect(() => {
        //     setitemIds(
        //         itemIds.filter((_item, index) => {
        //             return !hiddenRowIndices.includes(index);
        //         })
        //     );
        // }, [hiddenRowIndices]);

        // hiddenRowIndices;

        React.useEffect(() => {
            safeRequestAnimationFrame(() => {
                if (virtuosoRef.current) {
                    virtuosoRef.current.scrollToIndex(0);
                }
            });
        }, [props.dataSourceId]);

        React.useImperativeHandle(
            ref,
            () => ({
                getScrollRegion: () => scrollingRegionRef.current as HTMLDivElement,
                setFocus: () => scrollingRegionRef.current?.focus(),
                scrollToIndex: (index: number) => {
                    virtuosoRef.current?.scrollToIndex({
                        index,
                        align: 'start',
                        behavior: 'auto',
                    });
                },
            }),
            []
        );

        const isRtl = isCurrentCultureRightToLeft();
        const listStyle: React.CSSProperties = React.useMemo(() => {
            return isRtl
                ? {
                      direction: 'rtl',
                  }
                : {};
        }, [isRtl]);

        const [listAutoSizerRect] = useElementSizeTracker(
            'VirtualizedLoadMoreListView_ET',
            listAutoSizerRef
        );

        const listVisibleRange = React.useRef<ListRange>({
            startIndex: 0,
            endIndex: 0,
        });

        const setVisibleRange = React.useCallback(
            (range: ListRange) => {
                listVisibleRange.current = range;
                updateStartAndEndIndices(range.startIndex, range.endIndex);
            },
            [updateStartAndEndIndices]
        );

        const loadMoreRows = React.useCallback(() => {
            if (getCanLoadMore() && activeAnimationsCount === 0) {
                onLoadMoreRows('NextPage');
            }
        }, [getCanLoadMore, onLoadMoreRows, activeAnimationsCount]);

        const loadMoreRowsPrevious = React.useCallback(() => {
            if (getCanLoadMorePrevious() && activeAnimationsCount === 0) {
                onLoadMoreRows('PreviousPage');
            }
        }, [getCanLoadMorePrevious, onLoadMoreRows, activeAnimationsCount]);

        const renderHeaderAboveRow = React.useCallback(
            (rowToRender: number): JSX.Element | null => {
                if (onRenderHeader) {
                    const previousElementId =
                        rowToRender > 0 && rowToRender <= currentLoadedIndex
                            ? itemIds[rowToRender - 1]
                            : null;
                    const currentElementId = itemIds[rowToRender];
                    return onRenderHeader(previousElementId, currentElementId);
                }
                return null;
            },
            [onRenderHeader, currentLoadedIndex, itemIds]
        );

        const [isScrollingValue, setIsScrollingValue] = React.useState(false);
        const renderRow = React.useCallback(
            (index: number): JSX.Element | null => {
                if (index < 0 || index >= itemIds.length) {
                    return null;
                }

                const isNotVisible =
                    hiddenRowIndices.includes(index) ||
                    index < listVisibleRange.current.startIndex ||
                    index > listVisibleRange.current.endIndex;

                const rowToRender = onRenderRow(
                    itemIds[index],
                    index,
                    listProps,
                    // render minimum if we are scrolling or the row is not visible
                    isScrollingValue || isNotVisible
                );
                const header = renderHeaderAboveRow(index);
                const midRowsComponent =
                    index === midRowsComponentRowNumber && MidRowsComponent ? (
                        <MidRowsComponent {...(subComponentProps as any)} />
                    ) : null;

                const loadingSpinnerComponent =
                    index === itemIds.length - 1 && isLoadRowsInProgress && loadingComponent;

                if (
                    header ||
                    midRowsComponent ||
                    rowToRender /* used to be null, now <></> */ ||
                    loadingSpinnerComponent
                ) {
                    return (
                        <div data-animatable={true} className={row}>
                            {header}
                            {midRowsComponent}
                            {rowToRender}
                            {/* The last row gets a loading spinner if we're actively loading more data */}
                            {loadingSpinnerComponent}
                        </div>
                    );
                } else {
                    return null;
                }
            },
            [
                isLoadRowsInProgress,
                loadingComponent,
                itemIds,
                onRenderRow,
                currentLoadedIndex,
                midRowsComponentRowNumber,
                MidRowsComponent,
                isScrollingValue,
            ]
        );

        // When the tableView's focusedRowKey changes, we want to trigger this useEffect to perform a calculation
        // to determine whether the new focusedRowKey is in view (between the visible indices).
        // If it is not within the current visible indices, we want to scroll to that item.
        React.useEffect(() => {
            const { startIndex, endIndex } = listVisibleRange.current;

            if (
                focusedRowKeyIndex !== null &&
                focusedRowKeyIndex !== undefined &&
                focusedRowKeyIndex !== -1 &&
                !(startIndex === 0 && endIndex === 0) // If the start and end indices are both 0, the VML is either empty or hasn't completed loading so we don't want to scroll.
            ) {
                const indexToScrolTo = focusedRowKeyIndex;
                if (indexToScrolTo < startIndex || indexToScrolTo > endIndex) {
                    virtuosoRef.current?.scrollToIndex({ index: indexToScrolTo });
                }
            }
        }, [focusedRowKeyIndex, focusedNodeId]);

        const handleScrollerRef = React.useCallback((scrollRef: HTMLElement | Window | null) => {
            scrollingRegionRef.current = scrollRef;
        }, []);

        const onListScroll = React.useCallback((isScrolling: boolean) => {
            setIsScrollingValue(isScrolling);
            const scrollingRegion = scrollingRegionRef.current as HTMLDivElement;
            if (!isScrolling && scrollingRegion && onScroll) {
                onScroll(scrollingRegion);
            }
        }, []);

        const virtuosoComponents = React.useMemo(
            () => ({
                Header: () =>
                    PreRowsComponent ? <PreRowsComponent {...(subComponentProps as any)} /> : null,
                Footer: () =>
                    PostRowsComponent ? (
                        <PostRowsComponent {...(subComponentProps as any)} />
                    ) : null,
            }),
            [PreRowsComponent, PostRowsComponent, subComponentProps]
        );

        return (
            <div className={classNames(rowWrapper, props.rowWrapperClass)}>
                <div ref={listAutoSizerRef} className={listAutoSizer}>
                    <Virtuoso
                        components={virtuosoComponents}
                        className={className}
                        scrollerRef={handleScrollerRef}
                        ref={virtuosoRef}
                        style={listStyle}
                        data={itemIds}
                        width={listAutoSizerRect?.width || 0}
                        height={listAutoSizerRect?.height || 0}
                        rangeChanged={setVisibleRange}
                        itemContent={renderRow}
                        increaseViewportBy={200}
                        startReached={loadMoreRowsPrevious}
                        endReached={loadMoreRows}
                        totalCount={itemIds.length}
                        defaultItemHeight={estimatedRowHeight}
                        isScrolling={onListScroll}
                        skipAnimationFrameInResizeObserver={true}
                        computeItemKey={computeItemKey}
                    />
                </div>
            </div>
        );
    }),
    'VirtuosoLoadMoreListView'
);

function computeItemKey(_: number, itemId: string) {
    return itemId;
}
