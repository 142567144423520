import { getIsDataWorkerFaulted, getDataWorkerFault } from './isDataWorkerFaulted';

let fault: Error | undefined;
const callbacks: ((e: Error | undefined) => void)[] = [];

export function getOfflineDataFault() {
    return fault || getDataWorkerFault();
}

export function getIsOfflineDataFaulted() {
    return !!fault || getIsDataWorkerFaulted();
}

export function setIsOfflineDataFaulted(e: Error | undefined) {
    fault = e;
    for (const cb of callbacks) {
        cb(e);
    }
}

/**
 * Set a callback which will be called whenever the offline data fault changes.
 */
export function registerOfflineDataFaultCallback(cb: (e: Error | undefined) => void) {
    callbacks.push(cb);
}
