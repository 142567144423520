import { isFeatureEnabled } from 'owa-feature-flags';
import { type TableView } from 'owa-mail-list-store';
import { mutatorAction } from 'satcheljs';
import { MAX_CACHED_ROWS_TO_SHOW_TABLE_LOAD } from 'owa-mail-triage-common';

export const setCurrentLoadedIndexMutator = mutatorAction(
    'setCurrentLoadedIndex',
    (tableView: TableView) => {
        if (isFeatureEnabled('tri-preserve-ml-selection') || isFeatureEnabled('tri-virtuoso')) {
            tableView.currentLoadedIndex = tableView.rowKeys.length;
        } else {
            // load table from cache by setting the currentLoadedIndex
            tableView.currentLoadedIndex = Math.min(
                MAX_CACHED_ROWS_TO_SHOW_TABLE_LOAD,
                tableView.rowKeys.length
            );
        }
    }
);
