import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type { MailboxInfo } from 'owa-client-types';
import { isConsumer, isPaid, getAccountScopeUserSettings } from 'owa-session-store';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';
import { isCapabilitySupported } from 'owa-capabilities';
import { learningToolsCapability } from 'owa-capabilities-definitions/lib/learningToolsCapability';
import { translatorCapability } from 'owa-capabilities-definitions/lib/translatorCapability';
import { mailboxModificationCapability } from 'owa-capabilities-definitions/lib/mailboxModificationCapability';
import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';
import { mailReplyForwardCapability } from 'owa-capabilities-definitions/lib/mailReplyForwardCapability';
import { saveEmailAsEMLToFileCapability } from 'owa-capabilities-definitions/lib/saveEmailAsEMLToFileCapability';
import { saveEmailAsMSGToFileCapability } from 'owa-capabilities-definitions/lib/saveEmailAsMSGToFileCapability';

let shouldHideMap: {
    [id: number]: {
        (mailboxInfo: MailboxInfo): boolean;
    };
};

export function getMailMenuItemShouldHideForMailbox(item: MenuItemType, mailboxInfo: MailboxInfo) {
    if (!shouldHideMap) {
        initializeMap();
    }
    return shouldHideMap[item]?.(mailboxInfo);
}

function initializeMap() {
    shouldHideMap = {
        [14]: (mailboxInfo: MailboxInfo) =>
            isConsumer(undefined, mailboxInfo) || isLocalDataBackedMailboxInfo(mailboxInfo),

        [43]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isLocalDataBackedMailboxInfo(mailboxInfo),

        [41]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            !isCapabilitySupported(mailReplyForwardCapability, mailboxInfo),

        [42]: (mailboxInfo: MailboxInfo) =>
            !isCapabilitySupported(mailReplyForwardCapability, mailboxInfo),

        [19]: (mailboxInfo: MailboxInfo) =>
            !isCapabilitySupported(mailReplyForwardCapability, mailboxInfo),

        [87]: (mailboxInfo: MailboxInfo) =>
            isFeatureEnabled('grp-enableGrpScheduleWithCopilot')
                ? isLocalDataBackedMailboxInfo(mailboxInfo)
                : isGroupMBX(mailboxInfo) || isLocalDataBackedMailboxInfo(mailboxInfo),

        [44]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isLocalDataBackedMailboxInfo(mailboxInfo),

        [60]: (mailboxInfo: MailboxInfo) =>
            isFeatureEnabled('grp-enableReplyInGroupMailbox')
                ? !isCapabilitySupported(mailReplyForwardCapability, mailboxInfo)
                : isGroupMBX(mailboxInfo) ||
                  !isCapabilitySupported(mailReplyForwardCapability, mailboxInfo),

        [61]: (mailboxInfo: MailboxInfo) =>
            !isCapabilitySupported(mailReplyForwardCapability, mailboxInfo),

        [52]: (mailboxInfo: MailboxInfo) =>
            !isCapabilitySupported(mailReplyForwardCapability, mailboxInfo),

        [62]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isLocalDataBackedMailboxInfo(mailboxInfo),

        [66]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [18]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isMailboxReadOnly(mailboxInfo),

        [74]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isMailboxReadOnly(mailboxInfo),
        [89]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isMailboxReadOnly(mailboxInfo),

        [51]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isMailboxReadOnly(mailboxInfo),

        [6]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isShadowMBX(mailboxInfo) || isMailboxReadOnly(mailboxInfo),

        [39]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isMailboxReadOnly(mailboxInfo),

        [75]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isMailboxReadOnly(mailboxInfo),

        [58]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isMailboxReadOnly(mailboxInfo),

        [11]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isShadowMBX(mailboxInfo) || isMailboxReadOnly(mailboxInfo),

        [3]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isMailboxReadOnly(mailboxInfo),

        [24]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [25]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [22]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [20]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [45]: (mailboxInfo: MailboxInfo) =>
            !isConsumer(undefined, mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [70]: (mailboxInfo: MailboxInfo) =>
            !isUserMBX(mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [54]: (mailboxInfo: MailboxInfo) =>
            isShadowMBX(mailboxInfo) || isLocalDataBackedMailboxInfo(mailboxInfo),

        [5]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [71]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [63]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [13]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isSharedMBX(mailboxInfo) || isMailboxReadOnly(mailboxInfo),

        [46]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),

        [47]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) || isMailboxReadOnly(mailboxInfo),

        [73]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isMailboxReadOnly(mailboxInfo),

        [64]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isMailboxReadOnly(mailboxInfo),

        [21]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [80]: (mailboxInfo: MailboxInfo) =>
            isShadowMBX(mailboxInfo) || isLocalDataBackedMailboxInfo(mailboxInfo),

        [72]: (mailboxInfo: MailboxInfo) =>
            !isCapabilitySupported(translatorCapability, mailboxInfo) ||
            isGroupMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [4]: (mailboxInfo: MailboxInfo) =>
            isConsumer(undefined, mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [49]: (mailboxInfo: MailboxInfo) =>
            isConsumer(undefined, mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [68]: (mailboxInfo: MailboxInfo) =>
            !isCapabilitySupported(learningToolsCapability, mailboxInfo),

        [17]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isPublicMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [15]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isPublicMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [16]: (mailboxInfo: MailboxInfo) => isLocalDataBackedMailboxInfo(mailboxInfo),

        [82]: (mailboxInfo: MailboxInfo) =>
            !isPaid(mailboxInfo) ||
            isGroupMBX(mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [83]: (mailboxInfo: MailboxInfo) =>
            isGroupMBX(mailboxInfo) ||
            isArchiveMBX(mailboxInfo) ||
            isShadowMBX(mailboxInfo) ||
            isSharedMBX(mailboxInfo) ||
            isConsumer(undefined /* smtpAddress */, mailboxInfo) ||
            isLocalDataBackedMailboxInfo(mailboxInfo),

        [88]: (mailboxInfo: MailboxInfo) => isLocalDataBackedMailboxInfo(mailboxInfo),

        [91]: (mailboxInfo: MailboxInfo) => isLocalDataBackedMailboxInfo(mailboxInfo),

        [59]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),

        [53]: (mailboxInfo: MailboxInfo) => isLocalDataBackedMailboxInfo(mailboxInfo),

        [9]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),

        [10]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),

        [29]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),

        [30]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),

        [12]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),

        [50]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),

        [56]: (mailboxInfo: MailboxInfo) => isLocalDataBackedMailboxInfo(mailboxInfo),

        // If none of the capabilities aren't supported, we should hide the menu item
        [65]: (mailboxInfo: MailboxInfo) =>
            !isCapabilitySupported(saveEmailAsEMLToFileCapability, mailboxInfo) &&
            !isCapabilitySupported(saveEmailAsMSGToFileCapability, mailboxInfo),

        [26]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),

        [27]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),

        [96]: (mailboxInfo: MailboxInfo) => isMailboxReadOnly(mailboxInfo),
    };
}

function isGroupMBX(mailboxInfo: MailboxInfo) {
    return mailboxInfo.type == 'GroupMailbox';
}

function isShadowMBX(mailboxInfo: MailboxInfo) {
    const sessionSettings = getAccountScopeUserSettings(mailboxInfo)?.SessionSettings;
    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
     * Adding IsShadowMailbox to restricted properties/methods.
     *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
    return !!sessionSettings?.IsShadowMailbox;
}

function isUserMBX(mailboxInfo: MailboxInfo) {
    return mailboxInfo.type == 'UserMailbox';
}

function isArchiveMBX(mailboxInfo: MailboxInfo) {
    return mailboxInfo.type == 'ArchiveMailbox';
}

function isSharedMBX(mailboxInfo: MailboxInfo) {
    return mailboxInfo.type == 'SharedMailbox';
}

function isPublicMBX(mailboxInfo: MailboxInfo) {
    return mailboxInfo.type == 'PublicMailbox';
}

// See https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/10662/Hide-unsupported-UI-entry-points
function isMailboxReadOnly(mailboxInfo: MailboxInfo): boolean {
    return !isCapabilitySupported(mailboxModificationCapability, mailboxInfo);
}
