import { mutatorAction } from 'satcheljs';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import setExpansionStoreState from '../utils/setExpansionStoreState';

export const rowExpansionStateMutator = mutatorAction(
    'rowExpansionStateMutator',
    (rowKey: string, mailListItemSelectionSource: MailListItemSelectionSource) => {
        setExpansionStoreState(rowKey, mailListItemSelectionSource);
    }
);
