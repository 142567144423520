import type { AppStrings } from 'owa-acui';
import { computed } from 'mobx';
import loc from 'owa-localize';
import CommandRibbonStrings from 'owa-locstrings/lib/strings/commandRibbon.locstring.json';

const computeAppStrings = computed((): AppStrings => {
    return {
        overflowWellKeytip: loc(CommandRibbonStrings.overflowWellKeytip),
        bottomBarOverflowMenuLabel: loc(CommandRibbonStrings.bottomBarOverflowMenuLabel),
        ribbonLabel: loc(CommandRibbonStrings.commandRibbonLabel),
        tabsContainerLabel: loc(CommandRibbonStrings.tabsContainerLabel),
        tabsContainerOverflowLabel: loc(CommandRibbonStrings.tabsContainerOverflowLabel),
        peripheralControlsGroupLabel: loc(CommandRibbonStrings.peripheralControlsGroupLabel),
        closeLabel: loc(CommandRibbonStrings.calloutCloseLabel),
        taskPaneOverflowMenuLabel: loc(CommandRibbonStrings.taskPaneOverflowMenuLabel),
        ribbonModeChevronLabel: loc(CommandRibbonStrings.ribbonModeChevronLabel),
        ribbonModeChevronKeytip: loc(CommandRibbonStrings.ribbonModeChevronKeytip),
        taskPaneTabsContainerLabel: loc(CommandRibbonStrings.taskPaneTabsContainerLabel),
        taskPaneRoleDescription: loc(CommandRibbonStrings.taskPaneRoleDescription),
        taskPaneOpeningString: loc(CommandRibbonStrings.taskPaneOpeningString),
        rightRibbonPaddleLabel: loc(CommandRibbonStrings.rightRibbonPaddleLabel),
        leftRibbonPaddleLabel: loc(CommandRibbonStrings.leftRibbonPaddleLabel),
        search: loc(CommandRibbonStrings.search),
        searchLabel: loc(CommandRibbonStrings.ribbonSearchLabel),
        searchableMenuRegionLabel: loc(CommandRibbonStrings.searchableMenuRegionLabel),
        clearSearch: loc(CommandRibbonStrings.clearSearch),
    };
});

export const getAppStrings = () => computeAppStrings.get();
