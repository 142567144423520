import type {
    PrimaryMailboxSearchScope,
    ArchiveMailboxSearchScope,
    SharedFoldersSearchScope,
    SingleGroupSearchScope,
    SingleGroup3sSearchScope,
    CalendarSearchScope,
    PublicFolderSearchScope,
    MetaOSAppSearchScope,
    PstFileSearchScope,
} from '../../data/schema/SearchScope';

export function getPrimaryMailboxSearchScopeKey(
    primaryMailboxSearchScope: PrimaryMailboxSearchScope
): string {
    return primaryMailboxSearchScope.kind + '-' + primaryMailboxSearchScope.folderId; // Folder Ids are case sensitive.
}

export function getArchiveMailboxSearchScopeKey(
    archiveMailboxSearchScope: ArchiveMailboxSearchScope
): string {
    return archiveMailboxSearchScope.kind + '-' + archiveMailboxSearchScope.folderId; // Folder Ids are case sensitive.
}

/**
 * Helper to return the search scope key of shared folders
 * @param sharedFoldersSearchScope
 */
export function getSharedFoldersSearchScopeKey(
    sharedFoldersSearchScope: SharedFoldersSearchScope
): string {
    return sharedFoldersSearchScope.kind + '-' + sharedFoldersSearchScope.folderId; // Folder Ids are case sensitive.
}

export function getSingleGroupSearchScopeKey(
    singleGroupSearchScope: SingleGroupSearchScope
): string {
    const searchScopeKey =
        singleGroupSearchScope.kind + '-' + singleGroupSearchScope.groupId.toLowerCase(); // Group Ids are SMTP addresses and shouldn't be case sensitive.
    return searchScopeKey + (singleGroupSearchScope as SingleGroup3sSearchScope).folderId;
}

export function getCalendarSearchScopeKey(calendarSearchScope: CalendarSearchScope): string {
    let key = calendarSearchScope.kind + '-';
    if (calendarSearchScope.calendarFolderId) {
        key += calendarSearchScope.calendarFolderId.toLowerCase();
    }
    return key;
}

export function getPublicFolderSearchScopeKey(
    publicFolderSearchScope: PublicFolderSearchScope
): string {
    return publicFolderSearchScope.kind + '-' + publicFolderSearchScope.folderId;
}

export function getMetaOSAppSearchScopeKey(metaOSAppSearchScope: MetaOSAppSearchScope): string {
    return metaOSAppSearchScope.kind + '-' + metaOSAppSearchScope.appId;
}

export function getPstFileSearchScopeKey(pstFileSearchScope: PstFileSearchScope): string {
    return pstFileSearchScope.kind + '-' + pstFileSearchScope.folderId;
}
