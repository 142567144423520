class CacheItem<T> {
    key: string;
    value: T;
    prev: CacheItem<T> | null = null;
    next: CacheItem<T> | null = null;

    constructor(key: string, value: T) {
        this.key = key;
        this.value = value;
    }
}

class LRUCache<T> {
    private readonly maxSize: number;
    private cache: Map<string, CacheItem<T>>;
    private head: CacheItem<T>;
    private tail: CacheItem<T>;

    constructor(maxSize: number) {
        this.maxSize = maxSize;
        this.cache = new Map<string, CacheItem<T>>();
        this.head = new CacheItem<T>('', {} as T);
        this.tail = new CacheItem<T>('', {} as T);
        this.head.next = this.tail;
        this.tail.prev = this.head;
    }

    set(key: string, value: T): void {
        const existingNode = this.cache.get(key);
        if (existingNode) {
            this.remove(existingNode);
        } else if (this.cache.size >= this.maxSize) {
            const lruNode = this.tail.prev;
            if (lruNode) {
                this.cache.delete(lruNode.key);
                this.remove(lruNode);
            }
        }
        const newNode = new CacheItem<T>(key, value);
        this.add(newNode);
        this.cache.set(key, newNode);
    }

    get(key: string): T | undefined {
        const node = this.cache.get(key);
        if (!node) {
            return undefined;
        }
        this.remove(node);
        this.add(node);
        return node.value;
    }

    size(): number {
        return this.cache.size;
    }

    private add(node: CacheItem<T>): void {
        node.next = this.head.next;
        node.prev = this.head;
        if (this.head.next) {
            this.head.next.prev = node;
        }
        this.head.next = node;
    }

    private remove(node: CacheItem<T>): void {
        if (node.prev) {
            node.prev.next = node.next;
        }
        if (node.next) {
            node.next.prev = node.prev;
        }
    }
}
export default LRUCache;
