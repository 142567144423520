import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import getIsCurrentThemeCalendarTeamsTheme from 'owa-theme/lib/selectors/getIsCurrentThemeCalendarTeamsTheme';

export const calendarTeamsBleedThroughCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return getIsCurrentThemeCalendarTeamsTheme();
    },
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        return true;
    },
};
