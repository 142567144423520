import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HeadersRefreshToken"*/ './lazyIndex'),
    { name: 'HeadersRefreshToken' }
);

export const lazyGetAndUpdateAccessToken = new LazyAction(
    lazyModule,
    m => m.getAndUpdateAccessToken
);

export { triggerReInitializeAccount } from './publicActions';
