import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';

interface SharepointUrls {
    mySiteHostUrl: string | undefined;
    rootSiteUrl: string | undefined;
}

const sharepointUrlsMap = new Map<string, SharepointUrls | undefined>();

export function setSharepointUrls(
    mySiteHostUrl: string | undefined,
    rootSiteUrl: string | undefined,
    mailboxInfo?: MailboxInfo
) {
    const key = getMapKey(mailboxInfo);

    if (!sharepointUrlsMap.has(key)) {
        sharepointUrlsMap.set(key, { mySiteHostUrl, rootSiteUrl });
    }
}

export function getSharepointUrls(mailboxInfo?: MailboxInfo): SharepointUrls | undefined {
    const key = getMapKey(mailboxInfo);
    return sharepointUrlsMap.get(key) ?? undefined;
}

function getMapKey(mailboxInfo?: MailboxInfo): string {
    return mailboxInfo ? getIndexerValueForMailboxInfo(mailboxInfo) : 'default_global_account';
}
