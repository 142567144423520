import type { RibbonControlDefinitionProps } from './RibbonControlDefinitionProps';
import type { MailboxInfo } from 'owa-client-types';
import { owaComputedFn } from 'owa-computed-fn';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

/**
 * Since the `mailboxInfo` property on `RibbonControlDefinitionProps` can be `undefined`, this function can be used to ensure that `mailboxInfo` is always defined.
 * @param mailboxInfo - The mailbox information from RibbonControlDefinitionProps or undefined
 * @returns mailboxInfo
 */
export const getRibbonMailboxInfo = owaComputedFn(function getRibbonMailboxInfo(
    mailboxInfo: RibbonControlDefinitionProps['mailboxInfo']
): MailboxInfo {
    return mailboxInfo ?? getModuleContextMailboxInfo();
});
