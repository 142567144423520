import { LazyImport, LazyModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "RibbonHelp"*/ './lazyIndex'), {
    name: 'RibbonHelp',
});

export const lazyGetSLRHelpTab = new LazyImport(lazyModule, m => m.getSLRHelpTab);
export const lazyGetMLRHelpTab = new LazyImport(lazyModule, m => m.getMLRHelpTab);
export const lazyGetSLRHelpTabGroups = new LazyImport(lazyModule, m => m.getSLRHelpTabGroups);
export const lazyGetMLRHelpTabGroups = new LazyImport(lazyModule, m => m.getMLRHelpTabGroups);
export const lazyRenderFeedbackPanel = new LazyAction(lazyModule, m => m.renderFeedbackPanel);
