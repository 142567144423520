import { isEdu } from 'owa-bpos-utils';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';

export enum Account {
    Business = 'Business',
    Consumer = 'Consumer',
    Edu = 'EDU',
}

export function getAccountKind(mailboxInfo?: MailboxInfo): Account {
    if (!isConsumer(undefined /*smtpAddress*/, mailboxInfo)) {
        return isEdu(mailboxInfo) ? Account.Edu : Account.Business;
    }
    return Account.Consumer;
}
