import { logGreyError } from 'owa-analytics';
import {
    getItem,
    getLocalStorage,
    getOwaPrefixedKey,
    type LocalStorageKeys,
    removeItem,
    setItem,
} from 'owa-local-storage';
import { isCachingEnabled } from './isCachingEnabled';

const LOCAL_STORAGE_KEY_PREFIX = 'AccountBootstrapCache';

interface VersionedCache<T> {
    version: number;
    value: T;
}

function getLocalStorageKey(persistenceId: string, name: string): LocalStorageKeys {
    return `${LOCAL_STORAGE_KEY_PREFIX}_${persistenceId}_${name}`;
}

export function tryGetVersionedCacheValue<T>(
    persistenceId: string,
    name: string,
    version: number
): T | undefined {
    try {
        const key = getLocalStorageKey(persistenceId, name);
        const cachedValue = getItem(self, key);
        if (cachedValue) {
            const parsedValue = JSON.parse(cachedValue) as VersionedCache<T>;
            if (parsedValue.version === version) {
                return parsedValue.value;
            }
        }
    } catch (error) {
        logGreyError('Error reading account bootstrap cache', error, {
            persistenceId,
            name,
            version,
        });
    }

    return undefined;
}

export function setVersionedCacheValue<T>(
    persistenceId: string,
    name: string,
    version: number,
    value: T
) {
    const key = getLocalStorageKey(persistenceId, name);
    if (isCachingEnabled()) {
        const versionedValue: VersionedCache<T> = { value, version };
        setItem(self, key, JSON.stringify(versionedValue));
    } else {
        removeItem(self, key);
    }
}

/**
 * Find and delete any cache entries left behind for accounts that no longer exist.
 */
export function removeOrphanedCacheEntries(persistentIdsToKeep: string[]) {
    const storage = getLocalStorage(self);
    const prefix = getOwaPrefixedKey(LOCAL_STORAGE_KEY_PREFIX);
    for (const key of Object.keys(storage)) {
        if (key.startsWith(prefix)) {
            const pieces = key.split('_');
            if (pieces.length === 3 && persistentIdsToKeep.indexOf(pieces[1]) === -1) {
                removeItem(self, key as LocalStorageKeys);
            }
        }
    }
}
