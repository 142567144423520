import ___resources_fabricColorVariablesThemeBaseJson from '../resources/fabric.color.variables.theme.base.json';
import ___resources_fabricColorVariablesThemeBaseDarkJson from '../resources/fabric.color.variables.theme.base.dark.json';

import { createStore } from 'owa-satcheljs';
import type ThemeStore from './schema/ThemeStore';
import type { ThemeResources } from 'owa-theme-shared';
import { ThemeConstants } from 'owa-theme-shared';

const initialStore: ThemeStore = {
    theme: ThemeConstants.BASE_THEME_ID,
    themeData: new Map<string, ThemeResources>([
        [ThemeConstants.BASE_THEME_ID, ___resources_fabricColorVariablesThemeBaseJson],
        [ThemeConstants.BASE_DARK_THEME_ID, ___resources_fabricColorVariablesThemeBaseDarkJson],
    ]),
    useSystemDarkModeSettings: false,
};
export const store = createStore<ThemeStore>('themeStore', initialStore)();
