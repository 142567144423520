import type { ConditionalFormattingModification } from '../store/schema/ConditionalFormattingRule';
import { ConditionalFormattingModificationType } from '../store/schema/ConditionalFormattingModificationType';
import { lightColorCodeValueMap } from 'owa-categories/lib/store/helpers/getLightThemeColorCodeValueMap';
import { darkColorCodeValueMap } from 'owa-categories/lib/store/helpers/getDarkThemeColorCodeValueMap';

// This will be called whenever we need to apply conditional formatting modifications to any icon (eg. twisty chevron)
export function getConditionalFormattingStylesForIcon(
    conditionalFormattingModifications: ConditionalFormattingModification[],
    isDarkTheme: boolean
): React.CSSProperties | undefined {
    // Currently building support to only check the first item in the array since we can only apply one modification as we only support one modification type.
    const { type, value } = conditionalFormattingModifications[0];

    switch (type) {
        case ConditionalFormattingModificationType.Color:
            const iconColor = isDarkTheme
                ? darkColorCodeValueMap[value].iconColor
                : lightColorCodeValueMap[value].iconColor;
            return {
                color: iconColor,
            };

        default:
            return undefined;
    }
}
