import { LazyImport, LazyModule, LazyAction, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaPersona" */ './lazyIndex'),
    { name: 'OwaPersona' }
);

export const lazyPerformShadowPdvValidation = new LazyAction(
    lazyModule,
    m => m.performShadowPdvValidation
);

export const lazyGetLPCAccountCallbacks = new LazyAction(lazyModule, m => m.getLPCAccountCallbacks);

export const lazyLpcV2CallbacksEnhancer = new LazyAction(lazyModule, m => m.lpcV2CallbacksEnhancer);

export const lazyIsCAPIv3Enabled = new LazyAction(lazyModule, m => m.isCAPIv3Enabled);

export const lazyMakeAddOrEditContactV3Callback = new LazyAction(
    lazyModule,
    m => m.makeAddOrEditContactV3Callback
);

export const lazyCheckAndInitializeLivePersonaEditor = new LazyAction(
    lazyModule,
    m => m.checkAndInitializeLivePersonaEditor
);

export const lazyOnPersonaUpdated = new LazyImport(lazyModule, m => m.onPersonaUpdated);

export const lazyGetContactEditorRenderer = new LazyAction(
    lazyModule,
    m => m.getContactEditorRenderer
);

export const lazyGetEmbeddedExpandedViewAccountId = new LazyAction(
    lazyModule,
    m => m.getEmbeddedExpandedViewAccountId
);

export const PersonaControl = createLazyComponent(lazyModule, m => m.PersonaControl);
export const PersonaCardBehavior = createLazyComponent(lazyModule, m => m.PersonaCardBehavior);
