import type ExtensibilityState from './schema/ExtensibilityState';
import type TaskPaneRunningInstance from './schema/TaskPaneRunningInstance';
import { createStore } from 'owa-satcheljs';
import type { ExtensibilityHostItem } from './schema/ExtensibilityHostItem';
import { initializeCompliance } from './schema/Compliance';
import type { ContextualCalloutState } from './schema/ContextualCalloutState';
import type RunningInstance from './schema/RunningInstance';
import type IExtendedAddinCommand from './schema/interfaces/IExtendedAddinCommand';
import type TaskPaneType from './schema/TaskPaneType';
import type { ActiveDialog } from './schema/interfaces/Dialog';
import type { SoftBlockDialogObservableState } from './schema/enums/SoftBlockOnSendConstants';
import type { OwaDate } from 'owa-datetime';
import type { Extension, AddInAcquisition } from 'owa-addins-contracts';
import type { ExtensibilityHostItemInstance } from './schema/ExtensibilityHostItemInstance';
import type { MailboxInfo, MailboxType } from 'owa-client-types';

//remove store object creation below when mos-multiAccount flight is removed.
const store: ExtensibilityState = createStore<ExtensibilityState>('extensibility', {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (17,5): Type 'null' is not assignable to type 'IEnabledAddinCommands'.
    // @ts-expect-error
    EnabledAddinCommands: null,
    HostItems: new Map<string, ExtensibilityHostItem>(),
    IsPreinstalledTeachingUIAvailable: false,
    Context: null,
    taskPanes: new Map<string /*key: tabId*/, Map<TaskPaneType, TaskPaneRunningInstance>>(),
    activeDialogs: new Map<string /*key: tabId*/, ActiveDialog>(),
    ExtensibilityStateIsDirty: false,
    contextualCalloutState: new Map<string /*key: tabId*/, ContextualCalloutState>(),
    runningContextualAddinCommand: new Map<string /*key: tabId*/, RunningInstance>(),
    runningUILessExtendedAddinCommands: new Map<
        string /*key: tabId*/,
        Map<string, IExtendedAddinCommand>
    >(),
    autoRunAddinCommandWaitingQueue: new Map<string /*key: tabId*/, Array<IExtendedAddinCommand>>(),
    compliance: initializeCompliance(),
    activeAutorunUilessFrames: new Map<string, any>(),
    currentSoftBlockDialogState: new Map<string /*key: tabId*/, SoftBlockDialogObservableState>(),
    telemetryLoggedInfo: new Map<string, Map<string, OwaDate>>(),
    addinsMap: new Map<string, Extension | AddInAcquisition>(),
    multiSelectEnabledAddinsCommand: [],
    innerPersistedAddins: {},
    HostItemInstance: new Map<string, ExtensibilityHostItemInstance>(),
    isOnSendPromptUserAddinInstalled: false,
    onSendAddinsCompletedExecutionForHostItemIndex: new Map<string, string[]>(),
    hasAppDeeplink: false,
})();

type MultiAccountExtensibilityStateStore = Map<string, ExtensibilityState>;
const initialStore: MultiAccountExtensibilityStateStore = new Map<string, ExtensibilityState>();

const multiAccountStore: MultiAccountExtensibilityStateStore =
    createStore<MultiAccountExtensibilityStateStore>('multiAccountExtStore', initialStore)();

const extensibilityFrameworkComponentMailboxInfo: MailboxInfo = createStore<MailboxInfo>(
    'MailboxInfo',
    /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
     * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
     *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
    {
        type: '' as MailboxType,
        userIdentity: '' as string,
        mailboxSmtpAddress: '' as string,
    }
)();

export { store, multiAccountStore, extensibilityFrameworkComponentMailboxInfo };
