import React from 'react';
import type { LightningProps } from 'owa-lightning-core-v2';
import { Lightable } from 'owa-lightning-core-v2/lib/lazyFunctions';

/**
 * React decorator to enhance a class component with Lightning functionality
 */
export function lightable<P extends object, TRef = {}>(
    InnerComponent: React.ComponentType<P>,
    forwardRef: true
): React.ForwardRefExoticComponent<
    React.PropsWithoutRef<P & LightningProps> & React.RefAttributes<TRef>
>;
export function lightable<P extends object>(
    InnerComponent: React.ComponentType<P>
): React.ComponentType<P & LightningProps>;
export function lightable<P extends object>(
    InnerComponent: React.ComponentType<P>,
    forwardRef?: true
) {
    const wrappedComponent = (props: P & LightningProps) => (
        <Lightable {...props}>
            <InnerComponent {...props} />
        </Lightable>
    );
    wrappedComponent.displayName = InnerComponent.displayName;

    if (forwardRef) {
        /* eslint-disable-next-line owa-custom-rules/require-two-arg-forwardRef -- (https://aka.ms/OWALintWiki)
         * Please fix this usage of forwardRef to accept an inline or arrow function that takes two arguments: one for the props, and one for the ref.
         *	> Please call forwardRef on an arrow function or function declaration */
        return React.forwardRef(wrappedComponent);
    } else {
        return wrappedComponent;
    }
}
