import { getDensityMode, getDensityModeCssClass, getPalette } from 'owa-fabric-theme';
import {
    Button,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuGroupHeader,
    MenuItemRadio,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
    makeStyles,
    Badge,
} from '@fluentui/react-components';
import type { MenuProps, MenuOpenChangeData } from '@fluentui/react-components';
import { resetFocus } from 'owa-mail-focus-manager';
import { getListViewDimensions, isSingleLineListView } from 'owa-mail-layout';
import { lazySelectSort } from 'owa-mail-filter-actions';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import type { ActionSource } from 'owa-mail-store';
import { observer } from 'owa-mobx-react';
import React from 'react';
import {
    sortButton,
    sortButtonCompact,
    sortButtonCompactMCL,
    sortButtonFull,
    sortButtonFullMCL,
    sortButtonMedium,
    sortButtonMediumMCL,
    sortButtonOpened,
    sortButtonsContainer,
    sortDirectionButton,
    sortDirectionButtonCompact,
    sortDirectionButtonMedium,
    sortLabel,
    sortMenuItemLabelFull,
    sortMenuItemLabelMediumOrCompact,
    viewSortMenuOption,
    newTag,
} from './MailListSortMenu.scss';
import { ArrowSortRegular } from '@fluentui/react-icons';
import classnames from 'owa-classnames';
import { mergeStyles } from '@fluentui/merge-styles';
import {
    getStore as getListViewStore,
    getSortByForTable,
    MailSortHelper,
    type SortColumn,
    MailRowDataPropertyGetter,
} from 'owa-mail-list-store';
import { addMailListLog, getMailListLogObjectToAddToStore } from 'owa-mail-list-logging';
import {
    alphabeticalOrderv2,
    highOnTopv2,
    largestOnTopv2,
    latestOnTopv2,
    lowOnTopv2,
    newestOnTopv2,
    oldestOnTopv2,
    reversedAlphabeticalOrderv2,
    smallestOnTopv2,
    soonestOnTopv2,
    sortBySortColumnv2,
    sortOrderv2,
    dateSortColumnv2,
    fromSortColumnv2,
    sizeSortColumnv2,
    importanceSortColumnv2,
    subjectSortColumn,
    categorySortColumnv2,
    toSortColumnv2,
    returnTimeSortColumnv2,
    prioritySortColumn,
    sortColumnMenuTooltip,
    sortColumnMenuButtonLabel,
    newLabel,
} from './MailListSortMenu.locstring.json';
import type SortDirection from 'owa-service/lib/contract/SortDirection';
import { getSortColumnIcon } from '../utils/getSortColumnIcon';
import loc, { format } from 'owa-localize';
import type { ResourceId } from 'owa-localize';
import { DEFAULT_LIST_VIEW_WIDTH } from 'owa-mail-layout/lib/mailLayoutConstants';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import getCopilotPrioritizeSettings from 'owa-mail-copilot-settings/lib/selectors/getCopilotPrioritizeSettings';
import PrioritySortCallout from './PrioritySortCallout';
import { isCopilotHighPriority } from 'owa-mail-copilot-inbox-shared';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';

export interface MailListSortMenuProps {
    sortButtonClassName?: string;
    sortMenuSource: ActionSource;
    tableViewId: string;
}

const sortColumnMap: {
    [key: number]: {
        menuDisplay: ResourceId;
        sortDirections: {
            ascendingString: ResourceId;
            descendingString: ResourceId;
        };
    };
} = {
    [1]: {
        menuDisplay: dateSortColumnv2,
        sortDirections: {
            ascendingString: oldestOnTopv2,
            descendingString: newestOnTopv2,
        },
    },
    [3]: {
        menuDisplay: fromSortColumnv2,
        sortDirections: {
            ascendingString: alphabeticalOrderv2,
            descendingString: reversedAlphabeticalOrderv2,
        },
    },
    [7]: {
        menuDisplay: sizeSortColumnv2,
        sortDirections: {
            ascendingString: smallestOnTopv2,
            descendingString: largestOnTopv2,
        },
    },
    [5]: {
        menuDisplay: importanceSortColumnv2,
        sortDirections: {
            ascendingString: lowOnTopv2,
            descendingString: highOnTopv2,
        },
    },
    [8]: {
        menuDisplay: subjectSortColumn,
        sortDirections: {
            ascendingString: alphabeticalOrderv2,
            descendingString: reversedAlphabeticalOrderv2,
        },
    },
    [14]: {
        menuDisplay: categorySortColumnv2,
        sortDirections: {
            ascendingString: alphabeticalOrderv2,
            descendingString: reversedAlphabeticalOrderv2,
        },
    },
    [9]: {
        menuDisplay: toSortColumnv2,
        sortDirections: {
            ascendingString: alphabeticalOrderv2,
            descendingString: reversedAlphabeticalOrderv2,
        },
    },
    [15]: {
        menuDisplay: returnTimeSortColumnv2,
        sortDirections: {
            ascendingString: soonestOnTopv2,
            descendingString: latestOnTopv2,
        },
    },
    [16]: {
        menuDisplay: prioritySortColumn,
        sortDirections: {
            ascendingString: lowOnTopv2,
            descendingString: highOnTopv2,
        },
    },
};

const useSortButtonIconStyles = makeStyles({
    full: {
        fontSize: '20px !important',
        marginTop: '2px',
        fontWeight: 'normal',
    },
    mediumOrCompact: {
        fontSize: '16px !important',
        marginTop: '2px',
        fontWeight: 'normal',
    },
});

export default observer(function MailListSortMenuV2(props: MailListSortMenuProps) {
    const { sortButtonClassName, sortMenuSource, tableViewId } = props;
    const tableView = React.useMemo(() => {
        return getListViewStore().tableViews.get(tableViewId);
    }, [tableViewId]);

    const mailboxInfo = getMailboxInfo(tableView);

    // Returns whether the current view is in single line list view OR width is > 100px
    const shouldShowExpandedHeaderButtons = React.useMemo(() => {
        return (
            getListViewDimensions().listViewWidth >= DEFAULT_LIST_VIEW_WIDTH + 100 ||
            isSingleLineListView()
        );
    }, [getListViewDimensions(), isSingleLineListView()]);

    const selectedSortForTableView = React.useMemo(() => {
        const sortBy = tableView ? getSortByForTable(tableView) : null;
        return {
            sortColumn: sortBy ? sortBy.sortColumn : 1,
            sortDirection: sortBy ? sortBy.sortDirection : MailSortHelper.DESCENDING_SORT_DIR,
        };
    }, [tableView]);

    // Returns the selected sort column and direction values for the given table view
    // to be used for the menu's checkedValues prop

    const selectedSortStringsForTableView = React.useMemo(() => {
        const { sortColumn, sortDirection } = selectedSortForTableView;

        const sortDirections = sortColumnMap[sortColumn].sortDirections;
        const selectedSortDirection =
            sortDirection == MailSortHelper.ASCENDING_SORT_DIR
                ? sortDirections.ascendingString
                : sortDirections.descendingString;

        return {
            sortColumn: sortColumn ? loc(sortColumnMap[sortColumn].menuDisplay) : '',
            sortDirection: selectedSortDirection ? loc(selectedSortDirection) : '',
        };
    }, [selectedSortForTableView]);

    // The "open" state of the Sort Menu
    const [open, setOpen] = React.useState(false);
    const [openedFromCallout, setOpenedFromCallout] = React.useState(false);
    const onOpenChange: MenuProps['onOpenChange'] = React.useCallback(
        (_e: any, data: MenuOpenChangeData) => {
            setOpen(data.open);

            if (!data.open) {
                setOpenedFromCallout(false);
            }
        },
        []
    );

    const onOpenFromCallout = React.useCallback(() => {
        setOpenedFromCallout(true);
        setOpen(true);
    }, []);

    const closeSortMenu = React.useCallback(() => {
        setOpenedFromCallout(false);
        setOpen(false);
    }, []);

    // The selected values of menu (column and direction)
    /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
     * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
     *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
    const [checkedValues, setCheckedValues] = React.useState<Record<string, string[]>>(() => {
        return {
            sortColumn: [selectedSortStringsForTableView.sortColumn],
            sortDirection: [selectedSortStringsForTableView.sortDirection],
        };
    });

    const onCheckedValueChange = React.useCallback<
        Exclude<MenuProps['onCheckedValueChange'], undefined>
    >((_e, { name, checkedItems }) => {
        setCheckedValues(s => ({ ...s, [name]: checkedItems }));
    }, []);

    // Update selected values of the menu when the table view changes
    React.useEffect(() => {
        setCheckedValues(s => ({
            ...s,
            sortColumn: [selectedSortStringsForTableView.sortColumn],
            sortDirection: [selectedSortStringsForTableView.sortDirection],
        }));
    }, [selectedSortStringsForTableView]);

    const onSortColumnOptionSelected = React.useCallback(
        (sortColumnDisplayName: string) => {
            return () => {
                closeSortMenu();
                setCheckedValues(s => ({
                    ...s,
                    sortColumn: [sortColumnDisplayName],
                }));
            };
        },
        [closeSortMenu]
    );

    const onSortDirectionOptionSelected = React.useCallback(
        (sortDirectionDisplayName: string) => {
            return () => {
                closeSortMenu();
                setCheckedValues(s => ({
                    ...s,
                    sortDirection: [sortDirectionDisplayName],
                }));
            };
        },
        [closeSortMenu]
    );

    const sortButtonIconStyles = useSortButtonIconStyles();
    const sortIcon = React.useMemo(() => {
        const { sortColumn, sortDirection } = selectedSortForTableView;
        const iconElement = shouldShowExpandedHeaderButtons ? (
            getSortColumnIcon(sortColumn, sortDirection)
        ) : (
            <ArrowSortRegular />
        );

        return React.cloneElement(iconElement, {
            className: classnames(
                getDensityModeCssClass(
                    sortButtonIconStyles.full,
                    sortButtonIconStyles.mediumOrCompact,
                    sortButtonIconStyles.mediumOrCompact
                )
            ),
        });
    }, [selectedSortForTableView, shouldShowExpandedHeaderButtons, getDensityMode()]);

    const prioritySortCalloutTarget = React.createRef<HTMLButtonElement>();

    // Show the callout if there are at least 20 unread messages and at least
    // one of them is high priority
    const showPrioritySortCallout = useComputedValue(() => {
        const prioritizationEnabled =
            isCopilotFeatureEnabled('Inbox', mailboxInfo, true /* skipLanguageCheck */) &&
            getCopilotPrioritizeSettings(mailboxInfo).prioritizationEnabled;

        if (!prioritizationEnabled) {
            return false;
        }

        if (!tableView) {
            return false;
        }

        const rowKeys = tableView.rowKeys ?? [];

        let unreadCount = 0;
        for (const rowKey of rowKeys) {
            if (!tableView) {
                continue;
            }

            const isUnread = MailRowDataPropertyGetter.getUnreadCount(rowKey, tableView) > 0;
            if (isUnread) {
                unreadCount++;
            }

            if (unreadCount >= 20) {
                break;
            }
        }

        const hasHighPriorityMessages = rowKeys.some((rowKey: string) => {
            if (!tableView) {
                return false;
            }

            return isCopilotHighPriority(
                MailRowDataPropertyGetter.getCopilotInboxScore(rowKey, tableView)
            );
        });

        return unreadCount >= 20 && hasHighPriorityMessages;
    }, [tableView, mailboxInfo]);

    const prioritySortCallout = useComputedValue(() => {
        return showPrioritySortCallout ? (
            <PrioritySortCallout
                id="PrioritySortCallout"
                target={prioritySortCalloutTarget}
                isMCL={isSingleLineListView()}
                onTryItClicked={onOpenFromCallout}
            />
        ) : null;
    }, [showPrioritySortCallout, prioritySortCalloutTarget, onOpenFromCallout]);

    const menuButton = (
        <MenuButton
            icon={!shouldShowExpandedHeaderButtons ? sortIcon : null}
            className={classnames(
                open ? sortButtonOpened : sortButton,
                getDensityModeCssClass(
                    shouldShowExpandedHeaderButtons ? sortButtonFullMCL : sortButtonFull,
                    shouldShowExpandedHeaderButtons ? sortButtonMediumMCL : sortButtonMedium,
                    shouldShowExpandedHeaderButtons ? sortButtonCompactMCL : sortButtonCompact
                ),
                sortButtonClassName,
                mergeStyles({
                    color: `${getPalette().neutralDark}`,
                })
            )}
            menuIcon={null}
            id="mailListSortMenu"
            ref={prioritySortCalloutTarget}
        >
            {shouldShowExpandedHeaderButtons
                ? format(loc(sortColumnMenuButtonLabel), selectedSortStringsForTableView.sortColumn)
                : null}
        </MenuButton>
    );

    // Sort Direction Button is only available in the single line list view
    const onChangeSortDirectionButtonClicked = React.useCallback(() => {
        const { sortColumn, sortDirection } = selectedSortForTableView;
        const { ascendingString, descendingString } = sortColumnMap[sortColumn].sortDirections;

        const newSortDirection =
            sortDirection == MailSortHelper.ASCENDING_SORT_DIR
                ? MailSortHelper.DESCENDING_SORT_DIR
                : MailSortHelper.ASCENDING_SORT_DIR;
        const sortDirectionDisplay =
            newSortDirection == MailSortHelper.ASCENDING_SORT_DIR
                ? loc(ascendingString)
                : loc(descendingString);
        onSortDirectionOptionSelected(sortDirectionDisplay);
        lazySelectSort.importAndExecute(sortColumn, newSortDirection, tableViewId, sortMenuSource);
    }, [tableViewId, selectedSortForTableView, sortMenuSource]);

    // The JSX elements that make up the menu returned in 2 arrays (1 for the sort
    // column options and 1 for the sort direction options)
    const sortMenuItems = useComputedValue(() => {
        const sortColumnGroupElements: JSX.Element[] = [];
        const sortDirectionGroupElements: JSX.Element[] = [];

        if (!tableView) {
            return [[], []];
        }

        const currentSortBy = getSortByForTable(tableView);
        const currentSortColumn = currentSortBy.sortColumn;
        const supportedSortColumns = MailSortHelper.getSupportedSortColumns(
            tableView.tableQuery.folderId ?? '',
            mailboxInfo
        );

        // Add the sort column options (i.e. Date, From, etc.) to the list
        sortColumnGroupElements.push(
            <MenuGroupHeader key="sortColumnHeader">{loc(sortBySortColumnv2)}</MenuGroupHeader>
        );

        for (const sortColumn of supportedSortColumns) {
            const sortColumnDisplayName = loc(sortColumnMap[sortColumn].menuDisplay);
            const sortDirection =
                currentSortColumn == sortColumn /* isSelected */
                    ? currentSortBy.sortDirection
                    : MailSortHelper.getDefaultSortDirectionForSortColumn(sortColumn);

            const onSortColumnOptionSelectedCallback =
                onSortColumnOptionSelected(sortColumnDisplayName);

            sortColumnGroupElements.push(
                <MailListSortMenuOption
                    key={sortColumn}
                    onSortOptionSelected={onSortColumnOptionSelectedCallback}
                    displayName={sortColumnDisplayName}
                    sortMenuSource={sortMenuSource}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    tableViewId={tableViewId}
                    type="column"
                    openedFromCallout={openedFromCallout}
                />
            );
        }

        const { ascendingString, descendingString } =
            sortColumnMap[currentSortColumn].sortDirections;
        const sortDirectionOptions = [
            {
                displayName: loc(ascendingString),
                sortDirection: MailSortHelper.ASCENDING_SORT_DIR,
            },
            {
                displayName: loc(descendingString),
                sortDirection: MailSortHelper.DESCENDING_SORT_DIR,
            },
        ];

        // Add the sort direction options (i.e. Ascending, Descending) to the list
        sortDirectionGroupElements.push(
            <MenuGroupHeader key="sortDirectionHeader">{loc(sortOrderv2)}</MenuGroupHeader>
        );

        for (const sortDirectionOption of sortDirectionOptions) {
            const { displayName, sortDirection } = sortDirectionOption;

            const onSortDirectionOptionSelectedCallback =
                onSortDirectionOptionSelected(displayName);

            sortDirectionGroupElements.push(
                <MailListSortMenuOption
                    key={sortDirection}
                    onSortOptionSelected={onSortDirectionOptionSelectedCallback}
                    displayName={displayName}
                    sortMenuSource={sortMenuSource}
                    sortColumn={currentSortColumn}
                    sortDirection={sortDirection}
                    tableViewId={tableViewId}
                    type="direction"
                />
            );
        }

        return [sortColumnGroupElements, sortDirectionGroupElements];
    }, [tableView, tableViewId, sortMenuSource, mailboxInfo, openedFromCallout]);

    const menuPopover = (
        <MenuPopover>
            <MenuList>
                <MenuGroup>{...sortMenuItems[0]}</MenuGroup>
                <MenuDivider key="sortColumnDivider" />
                <MenuGroup>{...sortMenuItems[1]}</MenuGroup>
            </MenuList>
        </MenuPopover>
    );

    return shouldShowExpandedHeaderButtons ? (
        <div className={sortButtonsContainer}>
            <Tooltip
                content={format(
                    loc(sortColumnMenuTooltip),
                    selectedSortStringsForTableView.sortDirection
                )}
                relationship="label"
            >
                <Button
                    className={classnames(
                        sortDirectionButton,
                        getDensityModeCssClass(
                            undefined,
                            sortDirectionButtonMedium,
                            sortDirectionButtonCompact
                        )
                    )}
                    icon={sortIcon}
                    onClick={onChangeSortDirectionButtonClicked}
                ></Button>
            </Tooltip>
            <Menu
                open={open}
                onOpenChange={onOpenChange}
                checkedValues={checkedValues}
                onCheckedValueChange={onCheckedValueChange}
            >
                <MenuTrigger disableButtonEnhancement={true}>{menuButton}</MenuTrigger>
                {menuPopover}
            </Menu>
            {prioritySortCallout}
        </div>
    ) : (
        <>
            <Menu
                open={open}
                onOpenChange={onOpenChange}
                checkedValues={checkedValues}
                onCheckedValueChange={onCheckedValueChange}
            >
                <MenuTrigger disableButtonEnhancement={true}>
                    {
                        <Tooltip
                            content={format(
                                loc(sortColumnMenuTooltip),
                                format(
                                    loc(sortColumnMenuButtonLabel),
                                    selectedSortStringsForTableView.sortColumn
                                )
                            )}
                            relationship="label"
                        >
                            {menuButton}
                        </Tooltip>
                    }
                </MenuTrigger>
                {menuPopover}
            </Menu>
            {prioritySortCallout}
        </>
    );
}, 'MailListSortMenuV2');

const MailListSortMenuOption = (props: {
    displayName: string;
    sortMenuSource: ActionSource;
    onSortOptionSelected: () => void;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    tableViewId: string;
    type: 'column' | 'direction';
    openedFromCallout?: boolean;
}) => {
    const {
        displayName,
        sortMenuSource,
        onSortOptionSelected,
        sortColumn,
        sortDirection,
        tableViewId,
        type,
        openedFromCallout = false,
    } = props;

    const onSortOptionClicked = React.useCallback(() => {
        lazySelectSort.importAndExecute(
            sortColumn,
            sortDirection,
            tableViewId,
            sortMenuSource ?? 'MailSortMenu' /* default */
        );

        onSortOptionSelected();
        resetFocus('MailListSortMenuOptionClicked');

        addMailListLog(
            getMailListLogObjectToAddToStore('ChangeListViewSortCriterion', {
                tableView: tableViewId,
                sortColumn,
                sortOrder: sortDirection,
            })
        );
    }, [onSortOptionSelected, sortColumn, sortDirection, tableViewId, sortMenuSource]);

    const shouldShowNewTag = openedFromCallout && sortColumn === 16 && type === 'column';

    return (
        <MenuItemRadio
            key={sortDirection}
            className={viewSortMenuOption}
            name={type === 'column' ? 'sortColumn' : 'sortDirection'}
            onClick={onSortOptionClicked}
            title={displayName}
            value={displayName}
        >
            <span
                className={classnames(
                    getDensityModeCssClass(
                        sortMenuItemLabelFull,
                        sortMenuItemLabelMediumOrCompact,
                        sortMenuItemLabelMediumOrCompact
                    ),
                    sortLabel
                )}
            >
                {displayName}
                {sortColumn === 16 && type === 'column' && shouldShowNewTag && (
                    <Badge shape="rounded" appearance="tint" className={newTag}>
                        {loc(newLabel)}
                    </Badge>
                )}
            </span>
        </MenuItemRadio>
    );
};
