import { isBrowserSafari, isBrowserFirefox } from 'owa-user-agent/lib/userAgent';
import { isFeatureEnabled } from 'owa-feature-flags';

export async function checkClipboardPermissions(
    permission: PermissionName,
    mutatorFunction: (clipboardPermission: boolean) => void,
    errorCallback?: (e: any) => void
) {
    try {
        const permissionStatus = await navigator?.permissions?.query?.({
            name: permission,
            allowWithoutGesture: true,
        } as PermissionDescriptor);

        if (!permissionStatus) {
            mutatorFunction(false);
        } else {
            mutatorFunction(
                permissionStatus.state === 'granted' || permissionStatus.state === 'prompt'
            );

            permissionStatus.addEventListener('change', () => {
                mutatorFunction(
                    permissionStatus.state === 'granted' || permissionStatus.state === 'prompt'
                );
            });
        }
    } catch (e) {
        errorCallback?.(e);
    }
}

export function checkSafariAndFirefoxClipboardPermissions(
    mutatorFunction: (clipboardPermission: boolean) => void
) {
    if (
        isFeatureEnabled('cal-surface-copy-paste-events') &&
        (isBrowserFirefox() || isBrowserSafari())
    ) {
        mutatorFunction(true);
        return true;
    }
    return false;
}
