import { getAccountByMailboxInfo } from 'owa-account-source-list-store';
import { shouldShowAccountDescription } from 'owa-account-source-list';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { isMailboxSharedOrDelegate } from 'owa-account-shared-mailbox-utils';

export const getMailboxLabelForFavorite = (mailboxInfo: MailboxInfo): string | undefined => {
    if (mailboxInfo) {
        if (mailboxInfo.type === 'ArchiveMailbox') {
            const userConfig = getAccountScopeUserSettings(mailboxInfo);
            return userConfig?.SessionSettings?.ArchiveDisplayName;
        }
        const accountSource = getAccountByMailboxInfo(mailboxInfo);
        if (accountSource && shouldShowAccountDescription(accountSource)) {
            if (isMailboxSharedOrDelegate(mailboxInfo)) {
                return accountSource.displayName;
            } else {
                return accountSource.accountDescription;
            }
        }
    }

    return undefined;
};
