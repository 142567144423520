import { DirectionalHint } from '@fluentui/react/lib/Callout';
import { logUsage } from 'owa-analytics';
import { lightable } from 'owa-lightning-v2';
import { OutlookTeachingBubble } from 'owa-lightning-views';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import React from 'react';
import {
    prioritySortCalloutHeading,
    prioritySortCalloutDescription,
    prioritySortCalloutSecondaryButtonText,
    prioritySortCalloutTryNowText,
} from './PrioritySortCallout.locstring.json';
import type { LightningProps } from 'owa-lightning-core-v2';

export interface PrioritySortCalloutProps extends LightningProps {
    id: 'PrioritySortCallout';
    target: React.RefObject<any>;
    isMCL: boolean;
    onTryItClicked: () => void;
}

const PrioritySortCallout = (props: PrioritySortCalloutProps) => {
    const { target, isMCL, onTryItClicked } = props;

    // Show the callout after 5 seconds of the component being mounted to
    // give the app time to load/settle so the callout's anchor point isn't
    // lost
    const [isCalloutVisible, setIsCalloutVisible] = React.useState(false);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setIsCalloutVisible(true);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    React.useEffect(() => {
        logUsage('PrioritySortCallout_Shown');
    }, []);

    const onClickPrimaryButton = React.useCallback(() => {
        onTryItClicked();
        logUsage('FP_MoveFolderPos_TC_GoToSetting');
    }, [onTryItClicked]);

    const onClickSecondaryButton = React.useCallback(() => {
        logUsage('PrioritySortCallout_NotInterested');
    }, []);

    const onDismissCallout = React.useCallback(() => {
        logUsage('PrioritySortCallout_Dismiss');
    }, []);

    const preventDismissOnEvent = React.useCallback(
        (ev: Event | React.FocusEvent | React.KeyboardEvent | React.MouseEvent): boolean => {
            if (ev.type === 'resize') {
                return false;
            }

            return true;
        },
        []
    );

    if (!isCalloutVisible) {
        return null;
    }

    return (
        <OutlookTeachingBubble
            target={target}
            directionalHint={isMCL ? DirectionalHint.leftTopEdge : DirectionalHint.rightTopEdge}
            calloutTitle={loc(prioritySortCalloutHeading)}
            calloutDescription={loc(prioritySortCalloutDescription)}
            primaryButtonText={loc(prioritySortCalloutTryNowText)}
            secondaryButtonText={loc(prioritySortCalloutSecondaryButtonText)}
            lightningId="PrioritySortCallout"
            actionButtonOnClick={onClickPrimaryButton}
            notInterestedButtonOnClick={onClickSecondaryButton}
            onDismiss={onDismissCallout}
            setInitialFocus={true}
            preventDismissOnEvent={preventDismissOnEvent}
        ></OutlookTeachingBubble>
    );
};

export default lightable(observer(PrioritySortCallout, 'PrioritySortCallout'));
