import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isOfflineSyncEnabled } from './isOfflineSyncEnabled';

export function isOfflineSearchEnabled(mailboxInfo: MailboxInfo) {
    return (
        isOfflineSyncEnabled(mailboxInfo) &&
        isFeatureEnabled('sea-backgroundOfflineSearchIndexing-v2') &&
        isFeatureEnabled('sea-offlineSearch') &&
        !!Intl.Segmenter
    );
}
