import { createStore } from 'owa-satcheljs';
import type SharedFolderState from './schema/SharedFolderViewState';
import type RetentionPolicyTag from 'owa-service/lib/contract/RetentionPolicyTag';
const sharedFolderState: SharedFolderState = {
    showPeoplePickerDialog: false,
    mailboxInfo: undefined,
    retentionPolicyTags: new Map<string, RetentionPolicyTag[]>([]),
};

const store = createStore<SharedFolderState>('sharedFolderState', sharedFolderState)();
export default store;
export const getStore = () => store;
