import { createStore } from 'owa-satcheljs';
import type { ClientItem } from 'owa-mail-store';
import type { Point } from '@fluentui/react/lib/Utilities';

interface SelectionPopupStore {
    point?: Point | HTMLElement;
    showPopup: boolean;
    selectedText?: string;
    element?: HTMLElement;
    item?: ClientItem;
    isForExistingUserHighlight: boolean;
    isForExistingUserNote: boolean;
    selectionRange?: Range;
    selectionInstance?: number;
    targetWindow?: Window | null;
}

const defaultSelectionPopupStore: SelectionPopupStore = {
    showPopup: false,
    isForExistingUserHighlight: false,
    isForExistingUserNote: false,
};

export default createStore<SelectionPopupStore>('SelectionPopupStore', defaultSelectionPopupStore);
