import type FolderStore from './schema/FolderStore';

import { createStore } from 'owa-satcheljs';
export const folderStore = createStore<FolderStore>('folderStore', {
    folderTable: new Map(),
    mailboxFolderTreeData: new Map(),
    foldersInLoadingState: [],
    isAlphabetizeFolderList: false,
})();
const getStore = () => folderStore;
export default getStore;
