import type ConversationReadingPaneViewState from './schema/ConversationReadingPaneViewState';
import type ItemReadingPaneViewState from './schema/ItemReadingPaneViewState';
import type ReadingPaneStore from './schema/ReadingPaneStore';
import conversationCache from 'owa-mail-store/lib/store/conversationCache';
import { createStore } from 'owa-satcheljs';
import type ReactionSkinTone from 'owa-service/lib/contract/ReactionSkinTone';

const initialReadingPaneStore: ReadingPaneStore = {
    loadedItemReadingPaneViewStates: new Map<string, ItemReadingPaneViewState>([]),
    loadedConversationReadingPaneViewStates: new Map<string, ConversationReadingPaneViewState>([]),
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (17,5): Type 'null' is not assignable to type 'ItemReadingPaneViewState'.
    // @ts-expect-error
    itemPreviewPaneViewState: null,
    itemPrintPaneViewStates: new Map<string, ItemReadingPaneViewState>([]),
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (25,5): Type 'null' is not assignable to type 'DeeplinkId'.
    // @ts-expect-error
    deeplinkId: null,
    shouldShowMobileUpsellEmptyState: false,
    primaryReadingPaneTabId: null,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (34,5): Type 'null' is not assignable to type 'string'.
    // @ts-expect-error
    sxsItemId: null, // used for conversation reading pane
    loadingProjectionIds: [],
    reactionsSkinTone: 0,
    hidePopout: false,
    blockScreenAPIExecuted: false,
};

const store = createStore<ReadingPaneStore>('readingpane', initialReadingPaneStore)();
export default store;
export const getStore = () => store;

// Register the reading pane conversation with the conversation cache
conversationCache.registerReference(() => [
    ...store.loadedConversationReadingPaneViewStates.keys(),
]);
