import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import type { Module } from 'owa-workloads-module';

export default action(
    'SET_MODULE_CONTEXT_MAILBOXINFO',
    (module: Module, mailboxInfo: MailboxInfo) => ({
        module,
        mailboxInfo,
    })
);
