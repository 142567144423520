import {
    getCoprincipalAccounts,
    isGlobalSettingsMailbox,
    StartupFilter,
    ContractsSupportedFilter,
} from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import { localStorageExists } from 'owa-local-storage';
import { tryBootstrapApplicationSettingsForAccount } from './applicationSettingsCache';
import { tryBootstrapFeatureFlagsForAccount } from './featureFlagsCache';
import { isCachingEnabled } from './isCachingEnabled';
import { removeOrphanedCacheEntries } from './versionedCache';

const countTrue = (count: number, value: boolean) => count + (value ? 1 : 0);

export function bootstrapSecondaryAccountSettings() {
    if (!localStorageExists(self) || !isCachingEnabled()) {
        return;
    }

    const secondaryAccounts = getCoprincipalAccounts(
        StartupFilter.StartingOrCompleteOrError,
        ContractsSupportedFilter.Any
    ).filter(account => !isGlobalSettingsMailbox(account.mailboxInfo));
    const featureFlagResults = secondaryAccounts.map(tryBootstrapFeatureFlagsForAccount);
    const appSettingsResults = secondaryAccounts.map(tryBootstrapApplicationSettingsForAccount);

    removeOrphanedCacheEntries(secondaryAccounts.map(account => account.persistenceId));

    logUsage('BootstrapSecondaryAccountSettings', {
        numSecondaryAccounts: secondaryAccounts.length,
        featureFlagSuccess: featureFlagResults.reduce(countTrue, 0),
        appSettingsSuccess: appSettingsResults.reduce(countTrue, 0),
    });
}
