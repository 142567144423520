/**
 * Collection of "Regular" and "Filled" icons that are to be replaced with "Light" icon variation.
 *
 * The full list of icon names can be found here:
 * - "Regular" icons: https://github.com/microsoft/fluentui-system-icons/blob/main/icons_regular.md
 * - "Filled" icons: https://github.com/microsoft/fluentui-system-icons/blob/main/icons_filled.md
 */
const fluentUiSystemIcons: string[] = [
    'AccessibilityCheckmarkRegular',
    'AddRegular',
    'AppFolderRegular',
    'ArchiveRegular',
    'ArchiveSettingsRegular',
    'ArrowClockwiseRegular',
    'ArrowDownFilled',
    'ArrowDownRegular',
    'ArrowDownloadRegular',
    'ArrowForwardRegular',
    'ArrowHookUpLeftRegular',
    'ArrowRedoRegular',
    'ArrowReplyAllRegular',
    'ArrowReplyRegular',
    'ArrowUndoRegular',
    'AttachRegular',
    'AutocorrectRegular',
    'AutoFitHeightRegular',
    'BorderAllRegular',
    'BreakoutRoomRegular',
    'BroomRegular',
    'Calendar3DayRegular',
    'CalendarClockRegular',
    'CalendarDataBarRegular',
    'CalendarDayRegular',
    'CalendarEmptyRegular',
    'CalendarLtrRegular',
    'CalendarMonthRegular',
    'CalendarMultipleRegular',
    'CalendarPatternRegular',
    'CalendarReplyRegular',
    'CalendarSparkleRegular',
    'CalendarTodoRegular',
    'CalendarWorkWeekRegular',
    'ChatRegular',
    'CheckmarkCircleRegular',
    'CheckmarkRegular',
    'ClassificationRegular',
    'ClipboardPasteRegular',
    'ClockAlarmRegular',
    'ClockRegular',
    'ColorFillRegular',
    'ColorRegular',
    'CommentAddRegular',
    'ComposeRegular',
    'CopyRegular',
    'CropRegular',
    'CursorRegular',
    'DeleteRegular',
    'DismissCircleRegular',
    'DismissRegular',
    'DocumentLightningRegular',
    'DocumentSignatureRegular',
    'DoorArrowRightRegular',
    'EditRegular',
    'EmojiRegular',
    'FilterRegular',
    'FlagOffRegular',
    'FlagRegular',
    'FolderArrowRightRegular',
    'FolderMailRegular',
    'HandDrawRegular',
    'ImageAddRegular',
    'ImageAltTextRegular',
    'ImageCopyRegular',
    'ImageReflectionRegular',
    'ImageShadowRegular',
    'ImmersiveReaderRegular',
    'ImportantRegular',
    'LassoRegular',
    'LightbulbRegular',
    'LinkRegular',
    'LockClosedRegular',
    'LockOpenRegular',
    'MailAlertRegular',
    'MailArrowClockwiseRegular',
    'MailArrowDoubleBackFilled',
    'MailArrowDoubleBackRegular',
    'MailListRegular',
    'MailMultipleRegular',
    'MailReadMultipleRegular',
    'MailReadRegular',
    'MailRegular',
    'MailRewindRegular',
    'MailSettingsRegular',
    'MailUnreadRegular',
    'MicFilled',
    'MicRegular',
    'MoleculeRegular',
    'NoteRegular',
    'OptionsRegular',
    'PaintBrushRegular',
    'PanelLeftFilled',
    'PanelLeftFocusRightFilled',
    'PenSparkleRegular',
    'PeopleAddRegular',
    'PeopleCommunityRegular',
    'PeopleEditRegular',
    'PeopleListRegular',
    'PeopleRegular',
    'PeopleSettingsRegular',
    'PeopleSyncRegular',
    'PersonAddRegular',
    'PersonAvailableRegular',
    'PersonFeedbackRegular',
    'PersonMailRegular',
    'PersonProhibitedRegular',
    'PersonSupportRegular',
    'PhoneRegular',
    'PictureInPictureRegular',
    'PinOffRegular',
    'PinRegular',
    'PollRegular',
    'PrintRegular',
    'QuestionRegular',
    'ReadAloudRegular',
    'RectangleLandscapeRegular',
    'RotateLeftRegular',
    'SaveRegular',
    'SendClockRegular',
    'SettingsRegular',
    'ShareRegular',
    'ShieldErrorRegular',
    'SignatureRegular',
    'SquareArrowForwardRegular',
    'StarAddRegular',
    'StarArrowRightEndRegular',
    'TabAddRegular',
    'TableCellsMergeRegular',
    'TableCellsSplitRegular',
    'TableDismissRegular',
    'TableMoveAboveRegular',
    'TableMoveBelowRegular',
    'TableMoveLeftRegular',
    'TableMoveRightRegular',
    'TableRegular',
    'TableSettingsRegular',
    'TableSimpleRegular',
    'TagRegular',
    'TextboxAlignTopLeftRegular',
    'TextClearFormattingRegular',
    'TextCollapseRegular',
    'TextDensityRegular',
    'TextEditStyleRegular',
    'TextExpandRegular',
    'TextRegular',
    'TextTRegular',
    'ToolboxRegular',
    'TranslateRegular',
    'VideoClipRegular',
    'VideoRegular',
    'WindowFilled',
    'WeatherMoonRegular',
    'WeatherSunnyRegular',
    'WrenchScrewdriverRegular',
    'ZoomInRegular',
    'ZoomOutRegular',
];

/**
 * Collection of product/brand icons that are to be replaced with "Light" icon variation
 *
 * Available from the `@fluentui/react-brand-icons` package as SVGs. Also known as `fluent-product-icons`:
 * https://dev.azure.com/microsoftdesign/Design%20System/_git/fluent-product-icons
 */
const reactBrandIcons: string[] = ['EditorRegular', 'Loop', 'TeamsColor'];

export const lightRibbonIconFont = new Set([...fluentUiSystemIcons, ...reactBrandIcons]);
