import type { MailboxInfo } from 'owa-client-types';
import type { Module } from 'owa-workloads-module';
import { default as setModuleContextMailboxInfoAction } from '../actions/setModuleContextMailboxInfo';
import { checkThatMailboxInfoIsForStartedAccount } from 'owa-mailbox-info-checks';

/**
 * Checks that the MailboxInfo is for a started account
 * @param module Module for which mailbox info is being set
 * @param mailboxInfo MailboxInfo to be set as the active context for the module
 */
export function setModuleContextMailboxInfo(module: Module, mailboxInfo: MailboxInfo) {
    checkThatMailboxInfoIsForStartedAccount('SetModuleContext', mailboxInfo);
    setModuleContextMailboxInfoAction(module, mailboxInfo);
}
