import { OutlookTeachingBubble } from 'owa-lightning-views';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import type { Target } from '@fluentui/react';
import { observer } from 'owa-mobx-react';
import { lightable } from 'owa-lightning-v2';
import { lighted } from 'owa-lightning-core-v2';
import type { LightningProps } from 'owa-lightning-core-v2';
import loc from 'owa-localize';
import React from 'react';
import {
    embedChatTeachingCalloutTitle,
    embedChatTeachingCalloutDesc,
    embedChatcalloutPrimaryButtonText,
    embedChatcalloutSecondaryButtonText,
} from './EmbedChatTeachingCallout.locstring.json';
import { logUsage } from 'owa-analytics';

interface EmbedChatTeachingCalloutProps extends LightningProps {
    target: Target;
    onDismiss: () => void;
}

const EmbedChatTeachingCallout = (props: EmbedChatTeachingCalloutProps) => {
    const { target, onDismiss, id } = props;
    const onClickPrimaryButton = React.useCallback(() => {
        logUsage('EmbedChatTeachingCalloutOkButtonClicked');
        onDismissBubble();
    }, []);

    const onClickSecondaryButton = React.useCallback(() => {
        logUsage('EmbedChatTeachingCalloutNotInterestedButtonClicked');
        onDismissBubble();
    }, []);

    const onDismissBubble = () => {
        lighted(id);
        onDismiss();
    };

    return (
        <OutlookTeachingBubble
            target={target}
            directionalHint={DirectionalHint.leftTopEdge}
            calloutTitle={loc(embedChatTeachingCalloutTitle)}
            calloutDescription={loc(embedChatTeachingCalloutDesc)}
            primaryButtonText={loc(embedChatcalloutPrimaryButtonText)}
            secondaryButtonText={loc(embedChatcalloutSecondaryButtonText)}
            lightningId={id}
            gotItButtonOnClick={onClickPrimaryButton}
            notInterestedButtonOnClick={onClickSecondaryButton}
        ></OutlookTeachingBubble>
    );
};

export default lightable(observer(EmbedChatTeachingCallout, 'EmbedChatTeachingCallout'));
