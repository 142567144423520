import { owaComputedFn } from 'owa-computed-fn';
import type { AppGalleryButtonOnExecuteParameter, AppIconGalleryButtonProps } from 'owa-acui';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { lazyOnExecuteQuickStep } from 'owa-mail-quick-step-actions';
import { lazyMountAndShowFullOptions } from 'owa-options-view';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';

export const onExecuteQuickStep = (id: string) => {
    lazyOnExecuteQuickStep.importAndExecute(id);
};

export const onManageQuickStepsClicked = ({ mailboxInfo }: ReadOnlyRibbonControlDefProps) => {
    lazyMountAndShowFullOptions.importAndExecute(
        'mail',
        'quickSteps',
        undefined /*options*/,
        undefined /*targetWindow*/,
        getRibbonMailboxInfo(mailboxInfo)
    );
};

export const onQuickStepGalleryButtonExecute = owaComputedFn(
    function onQuickStepGalleryButtonExecute(props: ReadOnlyRibbonControlDefProps) {
        return ({
            galleryButton,
        }: AppGalleryButtonOnExecuteParameter<AppIconGalleryButtonProps>) => {
            const { id } = galleryButton;

            if (id === (550).toString()) {
                onManageQuickStepsClicked(props);
            } else {
                lazyOnExecuteQuickStep.importAndExecute(id);
            }
        };
    }
);
