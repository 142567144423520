import { mutatorAction } from 'satcheljs';
import getListViewState from '../selectors/getListViewState';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateStartAndEndIndicesInVLV = mutatorAction(
    'updateStartAndEndIndicesInVLV',
    (start: number, end: number) => {
        const listViewState = getListViewState();

        // Subtracting both of these by 1 to account for the pre-rows component which takes index 0 in the VLV.
        // We want these indices to match the indices of the rows in the tableView.rowKeys.
        listViewState.startIndexVLV = start - 1;
        listViewState.endIndexVLV = end - 1;
    }
);
