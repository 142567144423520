import { createStore } from 'owa-satcheljs';
import type { OwaTeamsPoliciesStore } from './schema';
import { FetchState } from './schema';

const owaTeamsPoliciesStore: OwaTeamsPoliciesStore = {
    policies: null,
    fetchState: FetchState.NotStarted,
};

export const getStore = createStore<OwaTeamsPoliciesStore>(
    'OwaTeamsPoliciesStore',
    owaTeamsPoliciesStore
);
