import {
    getSelectMailItemDatapoint,
    setSelectMailItemDatapoint,
    getSmiCustomDataRawArguments,
    resetSmiCustomDataRawArguments,
} from './selectMailItemDatapointGetterAndSetter';
import { addTimingsAndEndDatapointOnRender } from 'owa-analytics-shared';
import { DatapointStatus } from 'owa-analytics-types';
import { addExtrasCheckpointToSMI, resetExtrasCheckpoint } from './appendCheckpointToSMI';
import {
    MailRowDataPropertyGetter,
    type TableQueryType,
    getListViewState,
} from 'owa-mail-list-store';
import { isReadingPanePositionOff } from 'owa-mail-layout';
import { getAggregationBucket } from 'owa-analytics-actions';
import { isReadingPaneConversationEnabled } from 'owa-mail-unstacked';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { userDate, differenceInDays, now } from 'owa-datetime';
import { getShouldAnimate } from 'owa-mail-list-item-animation-store';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getCopilotPrioritizePriority } from 'owa-mail-copilot-inbox-shared';
import type { PerformanceCoreDatapoint, PerformanceDatapoint } from 'owa-analytics';
import isMessageListBranchExpansionType from 'owa-mail-organization-settings/lib/utils/isMessageListBranchExpansionType';
import getRowExpansionState, {
    ExpansionState,
} from 'owa-mail-list-store/lib/selectors/getRowExpansionState';

export default function endSelectMailItemDatapoint(
    contentType: string = '',
    shouldInvalidate?: boolean,
    overrideStatus?: DatapointStatus,
    error?: Error
) {
    const selectMailItemDp = getSelectMailItemDatapoint();
    if (selectMailItemDp && !selectMailItemDp.hasEnded) {
        // Empty content type means there is no active selection at the time of ending the datapoint.
        if (shouldInvalidate || contentType === 'Empty') {
            selectMailItemDp.invalidate();
        } else {
            addCustomDataToSelectMailItemDatapoint(selectMailItemDp, contentType);
            if (
                overrideStatus === DatapointStatus.ServerError ||
                overrideStatus === DatapointStatus.ClientError
            ) {
                selectMailItemDp.endWithError(overrideStatus, error);
            } else {
                if (window.document && window.document.visibilityState == 'visible') {
                    addExtrasCheckpointToSMI(selectMailItemDp);
                    // Passing logOnly as false ends the datapoint after logging render end time if it has not already ended.
                    addTimingsAndEndDatapointOnRender(selectMailItemDp, false /* logOnly */);
                } else {
                    selectMailItemDp.end(
                        undefined /* duration */,
                        DatapointStatus.BackgroundSuccess
                    );
                }
            }
        }
    }
    if (selectMailItemDp) {
        setSelectMailItemDatapoint(undefined);
        resetSmiCustomDataRawArguments();
        resetExtrasCheckpoint();
    }
}

function addCustomDataToSelectMailItemDatapoint(
    dp: PerformanceCoreDatapoint | PerformanceDatapoint,
    contentType: string
) {
    const smiRawArgs = getSmiCustomDataRawArguments();
    if (smiRawArgs) {
        const { mailListItemSelectionSource, tableView, rowKey } = smiRawArgs;
        const timeStamp = MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp(
            rowKey,
            tableView
        );

        const daysAgo = timeStamp ? Math.max(0, differenceInDays(now(), userDate(timeStamp))) : -1;
        const autoSelectRow = getUserConfiguration()?.UserOptions?.ShowReadingPaneOnFirstLoad;
        const isConversationOnReadingPane =
            tableView.tableQuery.listViewType == 1 ? false : isReadingPaneConversationEnabled();
        const prioritizeScore = MailRowDataPropertyGetter.getCopilotInboxScore(rowKey, tableView);
        const priority = getCopilotPrioritizePriority(prioritizeScore);

        dp.addCustomData({
            mls: mailListItemSelectionSource,
            lv: tableView.tableQuery.listViewType,
            tq: tableView.tableQuery.type,
            search:
                tableView.tableQuery.type === 1 && (tableView.tableQuery as any).searchScope.kind,
            pri: priority,
            rpOn: !isReadingPanePositionOff(),
            rki: getAggregationBucket({
                value: tableView.rowKeys.indexOf(rowKey),
                buckets: [10, 25, 50, 75, 100, 150, 200, 250, 300, 500],
                exactMatches: [-1, 0],
            }), // Row key index
            rpS: isConversationOnReadingPane, // Reading pane is stacked
            fName: tableView.tableQuery.type === 0 && folderIdToName(tableView.tableQuery.folderId),
            age: daysAgo,
            ani: getShouldAnimate(),
            auto: autoSelectRow,
            contentType,
        });

        if (isMessageListBranchExpansionType()) {
            const rowExpansionState = getRowExpansionState(rowKey);
            const numberOfBranches =
                getListViewState().expandedConversationViewState?.forks?.length ?? 1;
            if (rowExpansionState === ExpansionState.BRANCHED && numberOfBranches > 1) {
                dp.addCustomData({
                    ip: 'fork', // item part
                });
            }
            if (rowExpansionState === ExpansionState.FULLY_EXPANDED) {
                dp.addCustomData({
                    ip: 'item', // item part
                });
            }
            // Add the number of forks to the datapoint
            if (numberOfBranches > 1) {
                dp.addCustomData({
                    forks: numberOfBranches,
                });
            }
        }
    }
}
