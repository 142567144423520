import { isGroupTableQuery } from 'owa-group-utils';
import type { MailFolderTableQuery, TableQuery } from 'owa-mail-list-store';
import { MailSortHelper, type TableQueryType, isDigestFilter } from 'owa-mail-list-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import type { TraceErrorObject } from 'owa-trace';
import { logUsage } from 'owa-analytics';

export function shouldFolderSortByRenewTime(folderId: string): boolean {
    // Folder id should not be null if not in search mode
    if (!folderId) {
        // If folderId is empty, then we don't know enough about the table to
        // determine if it should sort by renew time (so we default to false).
        if (folderId === '') {
            logUsage('shouldFolderSortByRenewTime: folderId is an empty string');
            return false;
        }

        const error: TraceErrorObject = new Error('Current folder id should not be null');
        error.additionalInfo = { ty: typeof folderId };
        throw error;
    }

    const folderName = folderIdToName(folderId);
    return (
        'deleteditems' != folderName &&
        'drafts' != folderName &&
        'junkemail' != folderName &&
        'archive' != folderName
    );
}

export default function shouldTableSortByRenewTime(tableQuery: TableQuery) {
    if (isGroupTableQuery(tableQuery)) {
        return false;
    }

    if (tableQuery.type == 0) {
        const tableSortby = (tableQuery as MailFolderTableQuery).sortBy;
        const renewTimeSort = MailSortHelper.getSortBySupportingPin();
        return (
            tableSortby.sortColumn == renewTimeSort.sortColumn &&
            tableSortby.sortDirection == renewTimeSort.sortDirection &&
            shouldFolderSortByRenewTime(tableQuery.folderId) &&
            !isDigestFilter(tableQuery as MailFolderTableQuery)
        );
    } else {
        return tableQuery.type == 1;
    }
}
