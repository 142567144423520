import { getStore } from '../store/store';
import { SearchTab } from 'owa-search-types/lib/types/SearchTab';
import isTabbedSearchEnabled from '../utils/isTabbedSearchEnabled';

export default function getIsNonMailEntitySearchTab(): boolean {
    if (!isTabbedSearchEnabled(false /*isFromQfScopeTabs*/)) {
        return false;
    }
    const activeSearchTab = getStore().activeSearchTab;
    return !!(
        activeSearchTab &&
        activeSearchTab !== SearchTab.Mail &&
        activeSearchTab !== SearchTab.All
    );
}
