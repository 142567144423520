import type { PropertyIconProps } from '../types/IconProps';

export default function addPropertyIconToIconBar(
    propertyIconProps: PropertyIconProps[],
    spotsToFill: boolean[],
    remainingSpots: {
        value: number;
    },
    propertyIconProp: PropertyIconProps,
    iconIndex?: number
) {
    const MAX_PROPERTY_ICONS = 4;
    if (remainingSpots.value > 0) {
        // If iconIndex is provided, use it to place the icon
        if (iconIndex != undefined && iconIndex < MAX_PROPERTY_ICONS && iconIndex > -1) {
            propertyIconProps[iconIndex] = propertyIconProp;
            spotsToFill[iconIndex] = true;
            remainingSpots.value--;
        } else {
            // otherwise, find the next available spot
            let nextAvailableSpot = -1;
            for (let i = MAX_PROPERTY_ICONS - 1; i >= 0; i--) {
                if (spotsToFill[i] === false) {
                    nextAvailableSpot = i;
                    break;
                }
            }
            if (nextAvailableSpot != -1) {
                propertyIconProps[nextAvailableSpot] = propertyIconProp;
                spotsToFill[nextAvailableSpot] = true;
                remainingSpots.value--;
            }
        }
    }
}
