import {
    AppStoreTitles,
    getLaunchPageTitles,
    getNativeTitles,
    MessageExtensionTitles,
} from './data';
import { M365AcquisitionsInvariants } from './data/M365AcquisitionsInvariants';
import { getBposNavBarData, getBposNavBarDataAsync } from 'owa-bpos-store/lib/bposNavBarData';
import { getPinnedAppBarTitles } from './pinnedApps/getPinnedAppBarTitles';
import { BootIndicatorTitle } from './data/titles/BootIndicatorTitle';
import { isMOSLaunchPagesEnabled } from './utils/isMOSLaunchPagesEnabled';
import { getAppBposData } from './utils/getAppBposData';
import { isAppBarPinnedAppsEnabled } from './pinnedApps/isAppBarPinnedAppsEnabled';
import { isMOS3AppServiceAvailable } from './utils/isMOS3AppServiceAvailable';

import type { StrictAcquisition, StrictM365AcquisitionsQuery, StrictTitle } from './types';
import type { M365AppId } from './data/M365AppId';
import type { MailboxInfo } from 'owa-client-types';
import { isConsumer } from 'owa-session-store';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';

/**
 * Filter that selects which static titles should be added based on runtime invariants.
 * @param  {StrictTitle} title
 * @returns StrictTitle
 */
function filterValidApps(title: StrictTitle, mailboxInfo?: MailboxInfo): boolean {
    let shouldShowTile = false;
    const appId = title.ingestionId as M365AppId;
    const appInvariant = M365AcquisitionsInvariants[appId];
    if (M365AcquisitionsInvariants.hasOwnProperty(appId) && !!appInvariant) {
        shouldShowTile = appInvariant(mailboxInfo);
    } else {
        // User pinned 3P apps will not have invariants
        shouldShowTile =
            title.ingestionSource === 'UserPinnedAcquisition' ||
            title.ingestionSource === 'BootIndicator';
    }

    if (
        title.categories.includes('m365_app') &&
        !isMOSLaunchPagesEnabled(mailboxInfo) &&
        !getAppBposData(appId, mailboxInfo)?.Url
    ) {
        shouldShowTile = false;
    }

    return !!shouldShowTile;
}

/**
 * Memoizes so we don't unnecessarily need to re-run the filtering
 * and generating functions.
 * @param {MailboxInfo} mailboxInfo
 */
async function getBootM365Titles(mailboxInfo?: MailboxInfo) {
    // Warm-up license data store to generate license-dependent titles.
    if (
        !getBposNavBarData(mailboxInfo) &&
        !isConsumer(undefined, mailboxInfo) &&
        isServiceRequestSupportedForMailbox(mailboxInfo)
    ) {
        await getBposNavBarDataAsync(
            'getStaticTiles',
            'worker',
            true /* ignore auth error */,
            mailboxInfo
        );
    }

    const bootApps: StrictTitle[] = [
        ...AppStoreTitles,
        ...getLaunchPageTitles(mailboxInfo),
        ...getNativeTitles(mailboxInfo),
        ...MessageExtensionTitles,
        BootIndicatorTitle,
    ].filter(app => filterValidApps(app, mailboxInfo));

    // If MOS3 is unavailable, skip adding 3P app tiles. We don't want to add 3P tiles
    // to the app bar if we can't fetch the full app from MOS3 post-boot.
    if (isAppBarPinnedAppsEnabled(mailboxInfo) && isMOS3AppServiceAvailable(mailboxInfo)) {
        bootApps.push(...getPinnedAppBarTitles(bootApps, mailboxInfo));
    }

    return bootApps;
}

/**
 * Returns the static acquisition list defined in data directory, filtered by each
 * title's invariant.
 * @returns Promise
 */
async function getBootM365Acquisitions(mailboxInfo?: MailboxInfo): Promise<StrictAcquisition[]> {
    const staticTitles: StrictTitle[] = await getBootM365Titles(mailboxInfo);

    const staticAcquisitions: StrictAcquisition[] = staticTitles.map((title: StrictTitle) => ({
        __typename: 'M365Acquisition',
        appId: title.ingestionId,
        titleId: title.ingestionId,
        titleDefinition: title,
        acquisitionState: 'Acquired',
        canBeUnacquired: false,
        acquisitionContext: 'Tenant',
        manifestId: 'NA',
    }));
    return staticAcquisitions;
}

/**
 * Returns the static acquisition list in a M365Acquisitions query format.
 * @returns Promise
 */
function toBootM365AcquisitionsQuery(
    acquisitions: StrictAcquisition[]
): StrictM365AcquisitionsQuery {
    return {
        __typename: 'Query',
        m365Acquisitions: {
            __typename: 'M365AcquisitionsQueryResult',
            edges: acquisitions,
            nextInterval: -1,
            source: 'BOOTSTRAP',
        },
    };
}

export { toBootM365AcquisitionsQuery, getBootM365Acquisitions, getBootM365Titles };
