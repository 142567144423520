import type DensityOptionState from './schema/DensityOptionState';
import { createStore } from 'owa-satcheljs';

export default createStore<DensityOptionState>('densityOptionState', {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (8,5): Type 'null' is not assignable to type 'DisplayDensityMode'.
    // @ts-expect-error
    densityModeSelected: null,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (12,5): Type 'null' is not assignable to type 'boolean'.
    // @ts-expect-error
    useSingleLineMessageStyle: null,
});
