export function getQuotedValueFromHeader(header: string, valueName: string): string | undefined {
    if (header && valueName) {
        const valueStringInHeader: string = valueName + '="';
        let ind = header.indexOf(valueStringInHeader);
        if (ind >= 0) {
            ind += valueStringInHeader.length;
        } else {
            return undefined;
        }

        const endIndex = header.indexOf('"', ind);
        if (endIndex >= 0) {
            const headerValue: string = header.substring(ind, endIndex);
            return headerValue;
        }
    }

    return undefined;
}
