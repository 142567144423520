import { cleanupPreloadedThemeImage } from 'owa-theme-shared';

interface CopilotImageUrl {
    requestUrl: string;
    imageUrl: string;
    isFallbackUrl: boolean;
}

let currentCopilotImageUrl: CopilotImageUrl;

export default async function disposePreviousCopilotImage(copilotImageUrl: CopilotImageUrl) {
    if (
        currentCopilotImageUrl &&
        currentCopilotImageUrl.requestUrl !== copilotImageUrl.requestUrl
    ) {
        await cleanupPreloadedThemeImage(currentCopilotImageUrl.requestUrl);

        // Fallback URL is a blob URL and needs to be revoked
        if (currentCopilotImageUrl.isFallbackUrl) {
            URL.revokeObjectURL(currentCopilotImageUrl.imageUrl);
        }
    }

    currentCopilotImageUrl = copilotImageUrl;
}
