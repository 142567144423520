import {
    getGlobalSettingsAccountMailboxInfo,
    getAccountByMailboxInfo,
} from 'owa-account-source-list-store';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { isCloudCache } from 'owa-account-source-list-internal';
import { isAddinMultiAccountEnabled } from 'owa-feature-flags';

//Returns true if multi account is not enabled and if multi acount is enabled returns true when account is primary.
//This will not be required when we enable add-ins for all accounts
//Added check to return true if addins for multi account is enabled.
export function shouldPopulateAddinsForAccount(selectedMailboxInfo: MailboxInfo): boolean {
    if (isMonarchMultipleAccountsEnabled() && isAddinMultiAccountEnabled()) {
        return true;
    }

    if (!isMonarchMultipleAccountsEnabled()) {
        return true;
    }

    return (
        getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo()) ===
        getIndexerValueForMailboxInfo(selectedMailboxInfo)
    );
}

//Returns true if the account is a cloud cache account in Monarch and OWA.
//Add-ins need to be disabled for cloud cache accounts (google, yahoo and iCloud)
export function isCloudCacheAccount(mailboxInfo: MailboxInfo) {
    const accountSource = getAccountByMailboxInfo(mailboxInfo);
    const isCloudCacheUser = !!accountSource && isCloudCache(accountSource);
    return isCloudCacheUser;
}
