import type { HotkeyCommand, Hotkeys } from '../types/HotkeyCommand';

// Helper function to create the definition for shortcuts that are the same across all types
export default function createUniversalHotkeyCommand(
    category: string,
    description: string,
    key: Hotkeys
): HotkeyCommand {
    return {
        category,
        description,
        hotmail: key,
        yahoo: key,
        gmail: key,
        owa: key,
        outlook: key,
    };
}
