import type { TableView } from 'owa-mail-list-store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import endSelectMailItemDatapoint from 'owa-mail-logging/lib/utils/endSelectMailItemDatapoint';
import { isFeatureEnabled } from 'owa-feature-flags';
import { mutatorAction } from 'satcheljs';
import getListViewState from 'owa-mail-list-store/lib/selectors/getListViewState';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import {
    isFirstLevelExpanded,
    isSecondLevelExpanded,
} from 'owa-mail-list-store/lib/selectors/isConversationExpanded';
import type { ConversationFork } from 'owa-graph-schema';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import { getConversationNodeIds } from 'owa-mail-list-item-shared';

export default mutatorAction(
    'toggleSelectRowInternalMutatorAction',
    function toggleSelectRowInternalMutatorAction(
        tableView: TableView,
        rowKey: string,
        mailListItemSelectionSource: MailListItemSelectionSource
    ): void {
        const isCheckboxSelect =
            mailListItemSelectionSource === MailListItemSelectionSource.MailListItemCheckbox;
        const isTargetRowSelected = tableView.selectedRowKeys.has(rowKey);

        // If this was a select on an existing major row
        // just enter checked mode and set all conversation children as checked.
        // No other selection state should change.
        if (isTargetRowSelected && !tableView.isInCheckedMode) {
            tableView.isInCheckedMode = true;

            // If the reselect flight is enabled and we are collapsing the conversation
            // We want to show the checkmark optimistically
            tableView.isOnlyOptimisticallyInCheckedMode =
                isFeatureEnabled('tri-reselect-checkbox-behavior') &&
                mailListItemSelectionSource == MailListItemSelectionSource.MailListItemTwisty &&
                isSecondLevelExpanded(rowKey);

            // If the table is only optimistically in checked mode, it is collapsing the conversation
            // We don't want to select the children
            if (!tableView.isOnlyOptimisticallyInCheckedMode) {
                updateCheckedStateOfConversationChildren(tableView, rowKey, true);
            }
            return;
        }

        // Invalidate select mail item since there is no RP load if already in checked mode:
        if (tableView.isInCheckedMode) {
            endSelectMailItemDatapoint('' /* contentType */, true /* shouldInvalidate */);
        }

        const hasOneSelection = tableView.selectedRowKeys.size == 1;

        // If the table currently has a single item selected (and non-checked) and we're clicking checkbox to select
        // another item, we want to unselect the existing selected item first.
        if (
            hasOneSelection &&
            !tableView.isInCheckedMode &&
            !isTargetRowSelected &&
            isCheckboxSelect
        ) {
            tableView.selectedRowKeys.delete([...tableView.selectedRowKeys.keys()][0]);
        }
        // Proceed with toggling selection on the target row
        const shouldSelect = !isTargetRowSelected;

        // If only the target row is selected and is checked, clicking on the same row should uncheck it, but keep the selection
        if (
            isFeatureEnabled('tri-reselect-checkbox-behavior') &&
            hasOneSelection &&
            isTargetRowSelected &&
            tableView.isInCheckedMode &&
            mailListItemSelectionSource != MailListItemSelectionSource.MailListItemTwisty &&
            mailListItemSelectionSource != MailListItemSelectionSource.MailListItemCheckbox
        ) {
            tableView.isInCheckedMode = false;
            updateCheckedStateOfConversationChildren(tableView, rowKey, shouldSelect);
            return;
        }

        if (shouldSelect && !tableView.isInCheckedMode) {
            tableView.isInCheckedMode = true;
        }

        if (tableView.isInVirtualSelectAllMode) {
            if (tableView.virtualSelectAllExclusionList) {
                // Update row exclusion list in select all mode when a user selects/unselects any row.
                const rowIndex = tableView.virtualSelectAllExclusionList.indexOf(rowKey);
                if (rowIndex > -1) {
                    tableView.virtualSelectAllExclusionList.splice(rowIndex, 1);
                } else {
                    tableView.virtualSelectAllExclusionList.push(rowKey);
                }
            }
        } else {
            if (shouldSelect) {
                tableView.selectedRowKeys.set(rowKey, true);
            } else {
                tableView.selectedRowKeys.delete(rowKey);
            }
        }
        updateCheckedStateOfConversationChildren(tableView, rowKey, shouldSelect);
    }
);

function updateCheckedStateOfConversationChildren(
    tableView: TableView,
    rowKey: string,
    shouldSelect: boolean
) {
    const listViewState = getListViewState();
    const expansionState = listViewState.expandedConversationViewState;
    if (rowKey !== expansionState.expandedRowKey || tableView.tableQuery.listViewType !== 0) {
        return;
    }

    if (shouldSelect) {
        if (isFirstLevelExpanded(rowKey)) {
            const forks: ConversationFork[] | null =
                listViewState.expandedConversationViewState.forks;
            const forkNodeIds = [];

            if (forks) {
                for (const fork of forks) {
                    if (fork.id) {
                        forkNodeIds.push(fork.id);
                    }
                }
            }

            expansionState.selectedNodeIds = forkNodeIds;
        } else if (isSecondLevelExpanded(rowKey)) {
            const conversationId = MailRowDataPropertyGetter.getConversationId(rowKey, tableView);
            const conversationNodeIds = getConversationNodeIds(conversationId);
            const nodeIds = [];
            if (conversationNodeIds) {
                for (const nodeId of conversationNodeIds) {
                    nodeIds.push(nodeId);
                }
            }
            expansionState.selectedNodeIds = nodeIds;
        }
    } else {
        expansionState.selectedNodeIds = [];
    }
}
