import { lazyMarkReadOnTableViewChange } from 'owa-mail-mark-read-actions';
import type { TableView } from 'owa-mail-list-store';
import shouldFirstRowBeSelectedOnLoad from 'owa-mail-list-store/lib/selectors/shouldFirstRowBeSelectedOnLoad';
import {
    type TableQueryType,
    getViewFilterForTable,
    isUnreadSearchFolder,
} from 'owa-mail-list-store';
import { lazyCleanSearchTableState } from 'owa-mail-execute-search-actions';
import tombstoneOperations from 'owa-mail-list-tombstone';
import { isReadingPanePositionOff } from 'owa-mail-layout';

/**
 * Called after loading the new table. The reason we are passing the selectedRowKeys
 * and not reading them from the oldTableView is because the reset selection has already
 * been called in onBeforeTableLoad and it has to happen synchronously before the new
 * table loads else we end up in not resetting the LV/RP views in certain scenarios.
 * @param selectedRowKeysForOldTable the selectedRowKeys for the old tableview
 * @param oldTableView the old table view
 * @param newTableView the new tableView we are switching to
 */
export async function onAfterTableLoad(
    selectedRowKeysForOldTable: string[],
    oldTableView: TableView,
    newTableView: TableView
) {
    // Clear entries from the tombstone for the old table
    if (oldTableView.tableQuery.type === 0) {
        tombstoneOperations.clearMapForFolderMutator(oldTableView.serverFolderId);
    }

    if (shouldMarkReadOnTableViewChange(selectedRowKeysForOldTable, newTableView)) {
        const markReadOnTableChange = await lazyMarkReadOnTableViewChange.import();
        markReadOnTableChange(selectedRowKeysForOldTable, oldTableView);
    }

    // Clean the old table View's state.
    // Currently we do not have to clean up anything on a non-search (or Folder) table
    // as we are caching them and the only clean up that we perform is the resetting the selection
    if (oldTableView.tableQuery.type === 1 && oldTableView.id !== newTableView.id) {
        /**
         * The reason we want to wait on markReadOnTableChange promise is to make sure the
         * mark read operation completes correctly on the row that was selected in the search table,
         * before we try to clear the rows in the table
         */
        lazyCleanSearchTableState(oldTableView);
    }
}

/**
 * Return true if all of these conditions are met:
 * 1) Single row was selected in the old table
 * 2) Reading pane is on
 * 3) Not moving to unread filter
 * 4) First row should not be selected on load
 * */
function shouldMarkReadOnTableViewChange(
    oldSelectedRowKeys: string[],
    newTableView: TableView
): boolean {
    return (
        oldSelectedRowKeys.length == 1 &&
        !isReadingPanePositionOff() &&
        getViewFilterForTable(newTableView) !== 'Unread' &&
        !isUnreadSearchFolder(newTableView) &&
        !shouldFirstRowBeSelectedOnLoad(newTableView)
    );
}
