import type FolderTreeViewStateStore from './schema/FolderTreeViewStateStore';
import type MailFolderNodeViewState from './schema/MailFolderNodeViewState';
import { createStore } from 'owa-satcheljs';
import { getShouldOverrideToSortBySizeBasedOnURL } from '../utils/getShouldOverrideToSortBySizeBasedOnURL';

// Import mutators so they are initialized at the same time as the store
import '../mutators/updateFolderCountsMutator';

const initialFolderTreeViewStateStore: FolderTreeViewStateStore = {
    folderNodeViewStates: new Map<string, MailFolderNodeViewState>([]),
    contextMenuState: null,
    folderTextFieldViewState: null,
    isDraggedOver: false,
    withContextMenuFolderId: null,
    shouldOverrideToSortBySizeBasedOnURL: getShouldOverrideToSortBySizeBasedOnURL(),
    customOrderCalloutState: null,
};

export const getStore = createStore<FolderTreeViewStateStore>(
    'folderViewState',
    initialFolderTreeViewStateStore
);
