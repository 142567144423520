import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailCommandActions" */ './lazyIndex'),
    { name: 'MailCommandActions' }
);

export const lazyOnClearMsgPermissions = new LazyAction(lazyModule, m => m.onClearMsgPermissions);
export const lazyOnCopyToClipboard = new LazyAction(lazyModule, m => m.onCopyToClipboard);
export const lazyOnDelete = new LazyAction(lazyModule, m => m.onDelete);
export const lazyOnFlagUnflag = new LazyAction(lazyModule, m => m.onFlagUnflag);
export const lazyOnMarkAsReadUnread = new LazyAction(lazyModule, m => m.onMarkAsReadUnread);
export const lazyOnResponse = new LazyAction(lazyModule, m => m.onResponse);
export const lazyOnSensitivity = new LazyAction(lazyModule, m => m.onSensitivity);
export const lazyOnToggleMessageClassification = new LazyAction(
    lazyModule,
    m => m.onToggleMessageClassification
);
export const lazyOnToggleRmsTemplate = new LazyAction(lazyModule, m => m.onToggleRmsTemplate);
export const lazyOnSensitivityLabelChanged = new LazyAction(
    lazyModule,
    m => m.onSensitivityLabelChanged
);

// non lazy exports
export { ResponseType } from './enums/ResponseType';
