import type { Config } from 'dompurify';
import { getApplicationSettings } from 'owa-application-settings';

/**
 * This list of safe protocols is used to prevent XSS attacks by only allowing certain protocols in href and src attributes.
 *
 * This list changes so infrequently (only once in few years) that it's safe to hardcode it, must match the list in
 * https://o365exchange.visualstudio.com/O365%20Core/_git/Substrate?path=/sources/dev/CTS/src/Server/TextConverters/Html/UrlSchemaChecker.cs
 *
 */
const defaultSafeProtocols = [
    'about',
    'blob',
    'callto',
    'cid',
    'codeflow',
    'conf',
    'file',
    'ftp',
    'gopher',
    'groove',
    'http',
    'https',
    'im',
    'ipp',
    'mailto',
    'meet',
    'mhtml',
    'mms',
    'msdaipp',
    'ms-excel',
    'ms-powerpoint',
    'ms-word',
    'ms-outlook',
    'news',
    'notes',
    'onenote',
    'sip',
    'tel',
    'wais',
];

let applicationSettings:
    | {
          whiteListedSchemas: string[];
      }
    | undefined;

// Centralized default config for the sanitizer
const defaultTrustedTypesConfig: Config = {
    USE_PROFILES: { html: true },
    // This attributes would be allowed on every html of the code
    ADD_ATTR: ['target', 'originalsrc', 'summary', 'name', 'content'],
};

const getGlobalSanitizerConfig = (extendedConfig: Config): Config => {
    // Merge USE_PROFILES
    const USE_PROFILES = {
        ...defaultTrustedTypesConfig.USE_PROFILES,
        ...extendedConfig.USE_PROFILES,
    };

    // Merge ADD_ATTR and remove duplicates
    const ADD_ATTR = Array.from(
        new Set([...(defaultTrustedTypesConfig.ADD_ATTR || []), ...(extendedConfig.ADD_ATTR || [])])
    );

    if (!extendedConfig.ALLOW_UNKNOWN_PROTOCOLS) {
        if (!applicationSettings) {
            applicationSettings = getApplicationSettings('UrlValidationSettings');
        }

        const safeProtocols = [...defaultSafeProtocols, ...applicationSettings.whiteListedSchemas];

        extendedConfig = {
            ...extendedConfig,
            ALLOWED_URI_REGEXP: new RegExp(
                `^(?:(?:${safeProtocols.join('|')}):|[^a-z]|[a-z+.\\-]+(?:[^a-z+.\\-:]|$))`,
                'i'
            ),
        };
    }

    return {
        ...defaultTrustedTypesConfig,
        ...extendedConfig,
        ...{ USE_PROFILES, ADD_ATTR },
    };
};

export default getGlobalSanitizerConfig;
