import type { MailListRowDragData } from 'owa-mail-types/lib/types/MailListRowDragData';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import { lazyCopyMailListRows, lazyMoveMailListRows } from 'owa-mail-triage-action';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { isPublicFolderSelected } from 'owa-publicfolder-utils';
import { getGroupFolderAndRulePermission } from 'owa-groups-shared-store/lib/selectors/groupFolderRulePermissionStoreSelectors';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { lazyDropMailListRowsOnGroup } from 'owa-group-mail-left-nav-actions';
import {
    getStore as getListViewStore,
    type TableQueryType,
    getSelectedTableView,
    isConversationView,
} from 'owa-mail-list-store';
import { isSearchFolderForFolderId } from 'owa-favorites';
import { logUsage } from 'owa-analytics';
import ClipboardErrorRegular from 'owa-fluent-icons-svg/lib/icons/ClipboardErrorRegular';
import { getListViewNotificationDimensions } from 'owa-mail-layout';
import { lazyShowNotification } from 'owa-notification-bar';
import loc from 'owa-localize';
import {
    onCopyFailedNotificationText,
    onMoveFailedNotificationText,
} from './onPaste.locstring.json';
import { OWA_ITEM_CLIPBAORD_MIME } from 'owa-clipboard-utils';
import { isMoveCopyAllowed } from 'owa-mail-triage-action/lib/utils/copyMoveItemUtils';
import { lazyReloadTable } from 'owa-mail-triage-table-load-extra';

export default async function onPaste(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'v' && !!window.navigator.clipboard.read) {
        event.preventDefault();
        const clipboardItems = await window.navigator.clipboard.read();
        for (const clipboardItem of clipboardItems) {
            for (const type of clipboardItem.types) {
                if (!(type === OWA_ITEM_CLIPBAORD_MIME)) {
                    return;
                }
                const blob = await clipboardItem.getType(type);
                const dragInfo: MailListRowDragData = JSON.parse(await blob.text());
                const dragItemType = dragInfo.itemType;

                // Copy/Move item part is not supported
                if (
                    dragItemType !== DraggableItemTypes.MailListRow &&
                    dragItemType !== DraggableItemTypes.MultiMailListConversationRows &&
                    dragItemType !== DraggableItemTypes.MultiMailListMessageRows
                ) {
                    return;
                }

                const isCopy = dragInfo.isCopy;
                const originalTableView = getListViewStore().tableViews.get(dragInfo.tableViewId);
                const destinationTableView = getSelectedTableView();

                if (!originalTableView || !destinationTableView) {
                    const logInfo = {
                        fromSearch: dragInfo.tableViewId.includes('query'),
                        isOriginalTableViewDefined: !!originalTableView,
                        isDestinationTableViewDefined: !!destinationTableView,
                    };
                    if (isCopy) {
                        /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops -- (https://aka.ms/OWALintWiki)
                         * Baseline, this function can't be used inside a loop, please move it outside
                         *	> Function 'logUsage' matches forbidden pattern (/logUsage/) and should not be used inside loops */
                        logUsage('TnS_Copy_OnPasteFailed', logInfo);
                    } else {
                        /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops -- (https://aka.ms/OWALintWiki)
                         * Baseline, this function can't be used inside a loop, please move it outside
                         *	> Function 'logUsage' matches forbidden pattern (/logUsage/) and should not be used inside loops */
                        logUsage('TnS_Move_OnPasteFailed', logInfo);
                    }

                    const notificationDimensions = getListViewNotificationDimensions();

                    lazyShowNotification.importAndExecute(
                        'pasteErrorNotification',
                        'MailModuleNotificationBarHost',
                        window,
                        loc(isCopy ? onCopyFailedNotificationText : onMoveFailedNotificationText),
                        {
                            icon: ClipboardErrorRegular,
                            minWidth: notificationDimensions.minWidth,
                            maxWidth: notificationDimensions.maxWidth,
                        },
                        undefined /* renderContent */,
                        true /* useV9 */
                    );
                    return;
                }

                const sourceMailboxInfo = getMailboxInfo(originalTableView);
                const destinationMailboxInfo = getMailboxInfo(destinationTableView);

                // Paste in Folder
                if (destinationTableView.tableQuery.type === 0) {
                    const destinationFolderId = destinationTableView.tableQuery.folderId;

                    // Checks based on source and destination mailboxes to determine if the move/copy is allowed
                    // Conversations can't be copied to Public Folders
                    // Copy/Move not allowed in Search Folders
                    if (
                        !isMoveCopyAllowed(sourceMailboxInfo, destinationMailboxInfo) ||
                        (isConversationView(originalTableView) && isPublicFolderSelected()) ||
                        isSearchFolderForFolderId(destinationFolderId)
                    ) {
                        return;
                    }

                    // Archive mailboxes and shared mailboxes don't support notifications
                    // so we will reload the table after the copy/move so the view is updated
                    // and the user can see the changes
                    const shouldReloadTable =
                        destinationMailboxInfo.type === 'ArchiveMailbox' ||
                        destinationMailboxInfo.type === 'SharedMailbox';
                    if (isCopy) {
                        lazyCopyMailListRows
                            .importAndExecute(
                                dragInfo.rowKeys,
                                originalTableView,
                                destinationFolderId,
                                'Keyboard'
                            )
                            .then(_result => {
                                if (shouldReloadTable) {
                                    lazyReloadTable.importAndExecute(destinationTableView);
                                }
                            });
                    } else {
                        /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops -- (https://aka.ms/OWALintWiki)
                         * Baseline, this function can't be used inside a loop, please move it outside
                         *	> Function 'logUsage' matches forbidden pattern (/logUsage/) and should not be used inside loops */
                        logUsage('TnS_MoveWithShortcut', {
                            listViewType: originalTableView.tableQuery.listViewType,
                        });
                        lazyMoveMailListRows
                            .importAndExecute(
                                dragInfo.rowKeys,
                                destinationFolderId,
                                dragInfo.tableViewId,
                                'Keyboard'
                            )
                            .then(_result => {
                                if (shouldReloadTable) {
                                    lazyReloadTable.importAndExecute(destinationTableView);
                                }
                            });
                    }
                    // Paste in Group
                } else if (
                    (destinationTableView.tableQuery.type === 2 ||
                        destinationTableView.tableQuery.type === 3) &&
                    (dragItemType === DraggableItemTypes.MultiMailListMessageRows ||
                        dragItemType === DraggableItemTypes.MailListRow)
                ) {
                    const folderRulePermission = getGroupFolderAndRulePermission(
                        destinationMailboxInfo,
                        destinationTableView.tableQuery.folderId // folderId == groupId
                    );

                    // Copy/Move not allowed if user doesn't have copy/move permission, across Group mailboxes, or across accounts
                    if (
                        (isCopy && !folderRulePermission?.canCopyMessage) ||
                        (!isCopy && folderRulePermission?.canMoveMessage) ||
                        (sourceMailboxInfo.type === 'GroupMailbox' &&
                            sourceMailboxInfo.mailboxSmtpAddress !==
                                destinationTableView.tableQuery.folderId) ||
                        getIndexerValueForMailboxInfo(destinationMailboxInfo) !==
                            getIndexerValueForMailboxInfo(sourceMailboxInfo)
                    ) {
                        return;
                    }

                    if (!isCopy) {
                        /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops -- (https://aka.ms/OWALintWiki)
                         * Baseline, this function can't be used inside a loop, please move it outside
                         *	> Function 'logUsage' matches forbidden pattern (/logUsage/) and should not be used inside loops */
                        logUsage('TnS_MoveWithShortcut', {
                            listViewType: originalTableView.tableQuery.listViewType,
                        });
                    }

                    lazyDropMailListRowsOnGroup.importAndExecute(
                        dragInfo,
                        destinationTableView.tableQuery.folderId /* groupId */,
                        isCopy /* copy */,
                        'Keyboard'
                    );
                }
            }
        }
    }
}
