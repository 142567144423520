export enum LoadState {
    Unloaded,
    Loading,
    Loaded,
}

export enum NetworkCallState {
    Unqueued,
    Queued,
    Completed,
}

export interface CachedAcquisitionsState {
    loadState: LoadState;
    networkCallState: NetworkCallState;
}
