import { EntityType } from '@nova/types';

import type { LinkedEntity } from '@nova/types';
import type { MailEntrypoint } from 'owa-m365-acquisitions/lib/types';
import type { MailboxInfo } from 'owa-client-types';

type ComposeLinkedEntityId = {
    scenario: Extract<'MessageCompose', MailEntrypoint>;
    anchorElementId: string;
    activeContentTabId: string;
    formId: string;
    mailboxInfo?: MailboxInfo;
};
type ReadLinkedEntityId = {
    scenario: Extract<'MessageRead', MailEntrypoint>;
    anchorElementId: string;
    activeContentTabId: string;
    readingPaneItemId: string;
};

type MultipleSelectionEntityId = {
    scenario: Extract<'MultipleSelection', MailEntrypoint>;
    anchorElementId: string;
    activeContentTabId: string;
    mailboxInfo?: MailboxInfo;
};

type NoItemContextEntityId = {
    scenario: Extract<'NoItemContext', MailEntrypoint>;
    anchorElementId: string;
    activeContentTabId: string;
    mailboxInfo?: MailboxInfo;
};

export type DecodedLinkedEntityId =
    | ReadLinkedEntityId
    | ComposeLinkedEntityId
    | MultipleSelectionEntityId
    | NoItemContextEntityId;

export type EncodedLinkedEntity = LinkedEntity & {
    id: string;
    type: EntityType.m365_messageextension;
};

export function encodeLinkedEntity(decodedParams: DecodedLinkedEntityId): EncodedLinkedEntity {
    switch (decodedParams.scenario) {
        case 'MessageCompose':
            return {
                id: JSON.stringify({
                    scenario: decodedParams.scenario,
                    formId: decodedParams.formId,
                    anchorElementId: decodedParams.anchorElementId,
                    activeContentTabId: decodedParams.activeContentTabId,
                    mailboxInfo: decodedParams.mailboxInfo,
                }),
                type: EntityType.m365_messageextension,
            };
        case 'MessageRead':
            return {
                id: JSON.stringify({
                    scenario: decodedParams.scenario,
                    readingPaneItemId: decodedParams.readingPaneItemId,
                    anchorElementId: decodedParams.anchorElementId,
                    activeContentTabId: decodedParams.activeContentTabId,
                }),
                type: EntityType.m365_messageextension,
            };
        case 'MultipleSelection':
            return {
                id: JSON.stringify({
                    scenario: decodedParams.scenario,
                    anchorElementId: decodedParams.anchorElementId,
                    activeContentTabId: decodedParams.activeContentTabId,
                    mailboxInfo: decodedParams.mailboxInfo,
                }),
                type: EntityType.m365_messageextension,
            };
        case 'NoItemContext':
            return {
                id: JSON.stringify({
                    scenario: decodedParams.scenario,
                    anchorElementId: decodedParams.anchorElementId,
                    activeContentTabId: decodedParams.activeContentTabId,
                    mailboxInfo: decodedParams.mailboxInfo,
                }),
                type: EntityType.m365_messageextension,
            };
    }
}
