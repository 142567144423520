import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CalendarBootTimePanel"*/ './index'),
    { name: 'CalendarBootTimePanel' }
);

export const lazyInitializeTimePanelProxy = new LazyAction(
    lazyModule,
    m => m.lazyInitializeTimePanel.importAndExecute
);

export const lazyOpenTasksViewProxy = new LazyAction(
    lazyModule,
    m => m.lazyOpenTasksView.importAndExecute
);

export const lazyOpenTimePanelInCalendarViewProxy = new LazyAction(
    lazyModule,
    m => m.lazyOpenTimePanelInCalendarView.importAndExecute
);

export const lazyOpenConflictsViewProxy = new LazyAction(
    lazyModule,
    m => m.lazyOpenConflictsView.importAndExecute
);

export const lazyCloseConflictsViewProxy = new LazyAction(
    lazyModule,
    m => m.lazyCloseConflictsView.importAndExecute
);

export const lazyOpenEventDetailsViewProxy = new LazyAction(
    lazyModule,
    m => m.lazyOpenEventDetailsView.importAndExecute
);

export const TimePanel = createLazyComponent(lazyModule, m => m.TimePanel);
export const TimePanelHeaderBar = createLazyComponent(lazyModule, m => m.TimePanelHeaderBar);
