import type { FluidOwaSource } from '../enums/FluidEnums';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isEdu } from 'owa-session-store';
import isEduFromBpos from 'owa-bpos-utils/lib/isEdu';
import { isCollabSpaceReadEnabled } from './isCollabSpaceReadEnabled';
import type { MailboxInfo } from 'owa-client-types';

export function isCollabSpaceEnabledForSource(
    fluidOwaSource: FluidOwaSource,
    mailboxInfo: MailboxInfo
): boolean {
    if (
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'cal-collab-useEduCheckFromBpos' */
        isFeatureEnabled('cal-collab-useEduCheckFromBpos', mailboxInfo)
            ? isEduFromBpos(mailboxInfo)
            : isEdu(mailboxInfo)
    ) {
        return false;
    }
    switch (fluidOwaSource) {
        case 'CalendarReadingPane':
        case 'MailCalendarCard':
            return isCollabSpaceReadEnabled();
        case 'CalendarCompose':
            return (
                isFeatureEnabled('cmp-prague') &&
                isFeatureEnabled('cal-cmp-fluidCollaborativeSpace')
            );
        default:
            return false;
    }
}
