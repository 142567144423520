import type { MailFolder } from 'owa-graph-schema';
import folderStore, { getEffectiveFolderDisplayName } from 'owa-folders';
import { getStore as getFavoritesStore } from 'owa-favorites';
import type { FavoriteFolderData } from 'owa-favorites-types';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';
import loc, { format } from 'owa-localize';
import {
    duplicateFavoriteDisplayNameTemplate,
    duplicateFavoriteTooltipTemplate,
} from './FavoriteDisplayNameForMultiAccounts.locstring.json';
import { getMailboxLabelForFavorite } from './getMailboxLabelForFavorite';
import { isMailboxSharedOrDelegate } from 'owa-account-shared-mailbox-utils';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';

function getFolder(folderId: string): MailFolder {
    if (publicFolderFavoriteStore.folderTable.has(folderId)) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return publicFolderFavoriteStore.folderTable.get(folderId)!;
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return folderStore.folderTable.get(folderId)!;
}

export default function getFavoriteFolderDisplayNameForMultiAccount(
    currentFolderId: string
): string {
    const currentFolder = getFolder(currentFolderId);
    const currentFolderDisplayName = getEffectiveFolderDisplayName(currentFolder);
    const useBothIdentifiers =
        currentFolder.mailboxInfo?.type === 'UserMailbox' &&
        !isMailboxSharedOrDelegate(currentFolder.mailboxInfo);

    if (!isMonarchMultipleAccountsEnabled()) {
        return currentFolderDisplayName;
    }

    const favoriteFolderIds = [...getFavoritesStore().outlookFavorites.values()]
        .filter(favorite => favorite.type === 'folder' || favorite.type?.startsWith('localFolder'))
        .map(favorite => (favorite as FavoriteFolderData).folderId);

    let folderName = currentFolderDisplayName;

    for (const folderId of favoriteFolderIds) {
        if (
            currentFolderId !== folderId &&
            currentFolderDisplayName.localeCompare(
                getEffectiveFolderDisplayName(getFolder(folderId)),
                undefined,
                { sensitivity: 'accent' }
            ) === 0
        ) {
            const mailboxInfo = currentFolder.mailboxInfo;
            const mailboxLabel = getMailboxLabelForFavorite(mailboxInfo);
            if (
                useBothIdentifiers &&
                mailboxLabel &&
                mailboxLabel !== mailboxInfo.mailboxSmtpAddress
            ) {
                folderName = format(
                    loc(duplicateFavoriteTooltipTemplate),
                    currentFolderDisplayName,
                    mailboxLabel,
                    mailboxInfo.mailboxSmtpAddress
                );
            } else {
                folderName = format(
                    loc(duplicateFavoriteDisplayNameTemplate),
                    currentFolderDisplayName,
                    mailboxLabel ?? mailboxInfo.mailboxSmtpAddress
                );
            }
            break;
        }
    }

    return folderName;
}
