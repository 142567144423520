import { LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupsDiscover" */ './lazyIndex'),
    { name: 'GroupsDiscover' }
);

// Exported actions
export const lazyGroupsDiscover = new LazyImport(lazyModule, m => m.discoverGroups);

/* eslint-disable-next-line owa-custom-rules/forbid-package-reexport  -- (https://aka.ms/OWALintWiki)
 * Baseline comment; do not copy-paste. Rexporting from a separate package complicates the package graph.
 *	> Do not re-export from a separate package */
export { UserType } from 'owa-groups-discover/lib/store/schema/groupsDiscoverInfo';
/* eslint-disable-next-line owa-custom-rules/forbid-package-reexport  -- (https://aka.ms/OWALintWiki)
 * Baseline comment; do not copy-paste. Rexporting from a separate package complicates the package graph.
 *	> Do not re-export from a separate package */
export type {
    EnterpriseGroup,
    GroupInfo,
} from 'owa-groups-discover/lib/store/schema/groupsDiscoverInfo';
