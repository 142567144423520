import { getStore } from '../store/findInMessageStore';

export default function getTotalNumberOfMatches(context: string): number {
    const store = getStore();
    const viewState = store.findInMessageViewStates.get(context);
    if (viewState) {
        let numberMatches = 0;
        if (viewState.matchesPerItemId) {
            for (const [_, value] of viewState.matchesPerItemId.entries()) {
                numberMatches += value.numberOfMatches;
            }
        }

        return numberMatches;
    }

    return 0;
}
