import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isArchiveMailboxInfo } from 'owa-client-types/lib/ArchiveMailboxInfo';
import { isPublicFolderMailboxInfo } from 'owa-client-types/lib/PublicFolderMailboxInfo';
import { isSharedMailboxInfo } from 'owa-client-types/lib/SharedMailboxInfo';
import { isPstFileMailboxInfo } from 'owa-client-types/lib/PstFileMailboxInfo';
import { isSharedMailboxAutomapped } from 'owa-account-shared-mailbox-utils';

export const conversationViewCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        // We don't support conversation view for the following account types
        // 1. Archive mailbox
        // 2. Public folder mailbox
        // 3. Shared mailbox that is not automapped (for auto mapped, user has full perms and FindConversation API is supported)
        // 4. PST file when the feature flag is off
        if (
            mailboxInfo &&
            (isArchiveMailboxInfo(mailboxInfo) ||
                isPublicFolderMailboxInfo(mailboxInfo) ||
                (isSharedMailboxInfo(mailboxInfo) && !isSharedMailboxAutomapped(mailboxInfo)) ||
                (isPstFileMailboxInfo(mailboxInfo) &&
                    !isFeatureEnabled('mon-pst-conversationView')))
        ) {
            return false;
        }

        return true;
    },
};
