import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import { getStore } from '../store/store';

export default function folderIdToName(
    folderId: string,
    shouldSearchShared?: boolean
): DistinguishedFolderIdName {
    const store = getStore();

    if (!folderId) {
        return 'none';
    }

    if (shouldSearchShared) {
        let folderName = store.defaultFolderIdToNameMap?.get(folderId);
        if (!folderName) {
            folderName = store.sharedMailboxFolderIdToNameMap?.get(folderId);
        }
        return folderName || 'none';
    } else {
        return store.defaultFolderIdToNameMap?.get(folderId) || 'none';
    }
}
