import { createStore } from 'owa-satcheljs';
import getInitialSxSViewState from 'owa-sxsdata/lib/utils/getInitialSxSViewState';
import type RecentFilesState from './schema/RecentFilesState';
import type RecentFilesListState from './schema/RecentFilesListState';

const initialState: RecentFilesState = {
    recentFilesPerGroup: new Map<string, RecentFilesListState>(),
    fileInformation: {},
    sxsState: {
        datapoint: null,
        viewState: getInitialSxSViewState(),
    },
    shouldShowCallout: false,
    calloutShown: false,
};

export const getRecentFilesStore = createStore<RecentFilesState>('recentFilesStore', initialState);
