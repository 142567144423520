import React from 'react';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import {
    Attach24Regular,
    Calendar24Regular,
    Flag24Regular,
    Mail24Regular,
    MailUnread24Regular,
    Mention24Regular,
    Send24Regular,
    ArrowCircleUpSparkle24Regular,
} from '@fluentui/react-icons';

export function getViewFilterIcon(filter: ViewFilter | null): JSX.Element {
    switch (filter) {
        case 'All':
            return <Mail24Regular />;
        case 'Unread':
            return <MailUnread24Regular />;
        case 'Flagged':
            return <Flag24Regular />;
        case 'ToOrCcMe':
            return <Send24Regular />;
        case 'HasAttachment':
            return <Attach24Regular />;
        case 'HasFile':
            return <Attach24Regular />;
        case 'Mentioned':
            return <Mention24Regular />;
        case 'HasCalendarInvite':
            return <Calendar24Regular />;
        case 'CopilotHighPriority':
            return <ArrowCircleUpSparkle24Regular />;
        case 'TaskActive':
        case 'TaskOverdue':
        case 'TaskCompleted':
        case 'DeprecatedSuggestions':
        case 'DeprecatedSuggestionsRespond':
        case 'DeprecatedSuggestionsDelete':
        case 'DeprecatedNoClutter':
        case 'DeprecatedClutter':
        case 'SystemCategory':
        case 'UserCategory':
        case 'Pinned':
        case 'Focused':
        case 'Locked':
        case 'Hashtag':
        case 'Liked':
        default:
            return <></>;
    }
}
