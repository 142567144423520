import { createStore } from 'owa-satcheljs';

export interface PublicFolderPickerState {
    showPanel: boolean; // to show/hide the picker.
    selectedFolderId: string; // folderId of the currently selected folder.
    showErrorDialog: boolean; // to show a dialog box instead of picker, in case there is no public folder deployment.
}

export default createStore<PublicFolderPickerState>('publicFolderPickerStore', {
    showPanel: false,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (14,5): Type 'null' is not assignable to type 'string'.
    // @ts-expect-error
    selectedFolderId: null,
    showErrorDialog: false,
})();
