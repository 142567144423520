import { createStore } from 'owa-satcheljs';
import type { PersonaPhotoState, PersonaPhotoStateByMailboxInfo } from './schema/PersonaPhotoState';
import type { AccountKey, MailboxInfo } from 'owa-client-types';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';

const getStore = createStore<PersonaPhotoStateByMailboxInfo>('personaPhotoStore', {
    personaPhotoStateByMailboxInfo: new Map<AccountKey, PersonaPhotoState>(),
});

export function getPersonaPhotoUrlsMap(mailboxInfo: MailboxInfo): Map<string, string> {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const personaPhotoState = getStore().personaPhotoStateByMailboxInfo.get(accountKey);

    if (!personaPhotoState) {
        const defaultPersonaPhotoUrls = {
            personaPhotoUrls: new Map<string, string>(),
        };
        getStore().personaPhotoStateByMailboxInfo.set(accountKey, defaultPersonaPhotoUrls);

        return defaultPersonaPhotoUrls.personaPhotoUrls;
    }

    return personaPhotoState.personaPhotoUrls;
}
