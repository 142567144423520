import { createStore } from 'owa-satcheljs';
import type AccountScopeCategoryProperties from './schema/AccountScopeCategoryProperties';
import type CategoryStore from './schema/CategoryStore';
import type CopilotCategoryType from './schema/CopilotCategoryType';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getItem, itemExists, type LocalStorageKeys } from 'owa-local-storage';
import { trace } from 'owa-trace';

const defaultCategoryStore = {
    categoryMenuViewState: {
        findText: '',
        isFocusInSearchBox: false,
        shouldShowAllCategories: false,
    },
    categoryColorPickerViewState: {
        colorPickerTarget: null,
        shouldShowColorPicker: false,
    },
    accountCategoryProps: new Map<string, AccountScopeCategoryProperties>([]),
    categoryDialogViewState: null,
    isApplyingCategoryOperation: false,
    categoriesVersion: 0,
};

// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
// -> Error TS2345 (30,64): Argument of type '{ categoryIdToNameMap: {}; categoryMenuViewState: { findText: string; isFocusInSearchBox: boolean; shouldShowAllCategories: boolean; }; categoryColorPickerViewState: { colorPickerTarget: null; shouldShowColorPicker: boolean; }; ... 6 more ...; blockedCategoryNames: never[]; }' is not assignable to parameter of type 'CategoryStore'.
// @ts-expect-error
const store = createStore<CategoryStore>('category', defaultCategoryStore)();
export default store;
export const getStore = () => store;

interface CopilotCategoriesStoreMap {
    entries: Map<string, CopilotCategoryType[]>;
}

const initialCopilotCategoriesStoreMap: CopilotCategoriesStoreMap = {
    entries: new Map(),
};

export const getCopilotCategoryListStore = createStore<CopilotCategoriesStoreMap>(
    'copilotCategoryList',
    initialCopilotCategoriesStoreMap
);

//Initializes the store to get copilot category list.
export function getCopilotCategoriesList(mailboxInfo: MailboxInfo): CopilotCategoryType[] {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const copilotCategoryStore = getCopilotCategoryListStore().entries.get(key);
    if (!copilotCategoryStore) {
        getCopilotCategoryListStore().entries.set(key, fetchCopilotCategoriesFromLocalStorage(key));
        return getCopilotCategoryListStore().entries.get(key) ?? [];
    }
    return copilotCategoryStore;
}

function fetchCopilotCategoriesFromLocalStorage(key: string): CopilotCategoryType[] {
    const localStorageKey: LocalStorageKeys = `mail_copilot_categories${key}`;
    try {
        return itemExists(window, localStorageKey)
            ? JSON.parse(getItem(window, localStorageKey) as string)
            : [];
    } catch (e) {
        trace.warn(e.message + getItem(window, localStorageKey));
        return [];
    }
}
