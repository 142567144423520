import { action } from 'satcheljs';

/**
 * When sharedFolderRootId is changed
 * @param sharedFolderRootId - the folderId of the shared folder root
 */
export const onChangeMoveToSharedFolderRootId = action(
    'ON_CHANGE_MOVE_TO_SHARED_FOLDER_ROOT_ID',
    (sharedFolderRootId: string) => ({ sharedFolderRootId })
);

/**
 * when focus or blur happens for search box in moveTo mailbox menu
 * @param isFocusInSearchBox - a boolean to say if seach box is focused or not focused(blurred)
 */
export const setIsFocusInSearchBox = action(
    'SET_FOCUS_IN_SEARCH_BOX',
    (isFocusInSearchBox: boolean) => ({ isFocusInSearchBox })
);

// Clicking on new folder menu item will dispatch this action
export const onNewFolderMenuClicked = action('NEW_FOLDER_MENU_CLICK');

// This action is called to reset the new folder menu item state
export const resetNewFolderViewState = action('RESET_NEW_FOLDER_VIEWSTATE');
