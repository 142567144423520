import { createStore } from 'owa-satcheljs';
import type { PrivacySettingsStore } from './schema/PrivacySettings';
import { DiagnosticDataLevel } from './schema/PrivacySettings';

export const getStore = createStore<PrivacySettingsStore>('privacyOption', {
    downloadContentEnabled: true,
    analyzeContentEnabled: true,
    optionalExperiencesEnabled: true,
    diagnosticDataLevel: DiagnosticDataLevel.RequiredAndOptional,
    isInitialized: false,
    optionalConnectedExperiencesNoticeVersion: 0,
    connectedExperiencesNoticeVersion: 0,
    requiredDiagnosticDataNoticeVersion: 0,
    optionalDiagnosticDataConsentVersion: 0,

    rawAnalyzeContentEnabled: undefined,
    rawConnectedExperiencesNoticeVersion: undefined,
    rawDiagnosticDataLevel: undefined,
    rawDownloadContentEnabled: undefined,
    rawOptionalConnectedExperiencesNoticeVersion: undefined,
    rawOptionalDiagnosticDataConsentVersion: undefined,
    rawOptionalExperiencesEnabled: undefined,
    rawRequiredDiagnosticDataNoticeVersion: undefined,
});
export default getStore;
