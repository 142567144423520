import { isFeatureEnabled } from 'owa-feature-flags';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { isGulpOrBranching } from 'owa-config';
import type { OfflineDisabledReason } from './OfflineDisabledReason';

export function isOfflineSyncAvailable(): boolean {
    return getOfflineSyncUnavailableReason() === undefined;
}

export function getOfflineSyncUnavailableReason(): OfflineDisabledReason | undefined {
    if (!self.BroadcastChannel) {
        return 'LegacyBrowser';
    }

    if (!isAnyOfflineModuleEnabled()) {
        return 'FeatureFlags';
    }

    return getOfflineSyncUnavailableReasonDueToPolicy();
}

export function getOfflineSyncUnavailableReasonDueToPolicy(): OfflineDisabledReason | undefined {
    const policies = getUserConfiguration().PolicySettings;

    /*
    offlineDataAllowed is only enabled for native
    localCacheAllowed is only enabled for web
    */
    if (isHostAppFeatureEnabled('offlineDataAllowed')) {
        return !policies?.OfflineEnabledWin ? 'Policy' : undefined;
    }

    if (isHostAppFeatureEnabled('localCacheAllowed')) {
        if (isGulpOrBranching()) {
            return undefined;
        }

        if (!policies?.OfflineEnabledWeb) {
            return 'Policy';
        }

        if (!policies?.SignInState?.includes('kmsi')) {
            return 'DataNotAllowed';
        }

        return undefined;
    }

    return 'DataNotAllowed';
}

function isAnyOfflineModuleEnabled() {
    return (
        isFeatureEnabled('fwk-offline-mail') ||
        isFeatureEnabled('fwk-offline-calendar') ||
        isFeatureEnabled('peo-offline') ||
        isFeatureEnabled('peo-offline-via-pdv') ||
        isFeatureEnabled('peo-offline-shadow-pdv') ||
        isFeatureEnabled('msplaces-offline')
    );
}
