import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import type ArchiveInformation from '../store/schema/ArchiveInformation';
import { getMailboxFolderTreeData } from '../selectors/getMailboxFolderTreeDataTable';

export const setSharedFolderArchiveInformationMutator = mutatorAction(
    'setSharedFolderArchiveInformation',
    (
        sharedFolderUserEmail: string,
        mailboxInfo: MailboxInfo,
        archiveInformation: ArchiveInformation
    ) => {
        const mailboxFolderTreeDataTable = getMailboxFolderTreeData(mailboxInfo);
        if (mailboxFolderTreeDataTable) {
            mailboxFolderTreeDataTable.sharedArchiveInformation.set(
                sharedFolderUserEmail,
                archiveInformation
            );
        }
    }
);
