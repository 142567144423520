import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';
import getStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import isCopilotPrioritizeSettingsInitialized from './isCopilotPrioritizeSettingsInitialized';

export default function getCopilotPrioritizeSettings(
    mailboxInfo: MailboxInfo
): CopilotPrioritizeSettings {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const store = getStore().copilotPrioritizeSettingsStoreMap.get(key);

    if (!isCopilotPrioritizeSettingsInitialized(mailboxInfo) || !store) {
        const defaultSettings: CopilotPrioritizeSettings = {
            version: 1,
            // Feature is off if not supported
            prioritizationEnabled: false,
            moreImportantRules: [],
            lessImportantRules: [],
            miscellaneousRules: [],
            applyLowPriorityLabel: false,
            experimentalSetting: null,
            replacePreviewWithMicroSummary: true,
        };

        return defaultSettings;
    }

    return store.settings;
}
