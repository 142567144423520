import { isFolderInMailboxType } from 'owa-folders';
import isSharedMailboxExplicitLogon from './isSharedMailboxExplicitLogon';
import type ClientItem from '../store/schema/ClientItem';
import type { MailboxInfo } from 'owa-client-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export function isSharedReadItemCheckForAddins(
    item: ClientItem,
    mailboxInfo: MailboxInfo
): boolean {
    if (!item || !item.ParentFolderId) {
        return false;
    }

    const folderId = item.ParentFolderId.Id;
    return (
        isFolderInMailboxType(folderId, 'SharedMailbox') ||
        isSharedMailboxExplicitLogon(
            item.MailboxInfo ?? mailboxInfo ?? getModuleContextMailboxInfo() // Fallback to module context mailbox info if not available
        )
    );
}

export function isSharedComposeItemCheckForAddins(
    viewStateIsInSharedFolder: boolean,
    mailboxInfo: MailboxInfo
): boolean {
    return viewStateIsInSharedFolder || isSharedMailboxExplicitLogon(mailboxInfo);
}
