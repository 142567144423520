import { createStore } from 'owa-satcheljs';
import type { ResourceId } from '../ResourceId';
import type { LocalizedString } from '../LocalizedString';
import { isBootFeatureEnabled } from 'owa-metatags';

export const getLocalizedStringStore = createStore('localizedStrings', {
    currentLocale: '',
    currentCulture: '',
    version: 0,
    localizedStringsV1: new Map<ResourceId, LocalizedString>([]),
});

export const getLocalizedStringStoreV2 = () => {
    if (isBootFeatureEnabled('fwk-loc-single-v2')) {
        return getLocalizedStringStore();
    } else {
        return getLocalizedStringStore().localizedStringsV1;
    }
};
