import { LazyAction, LazyModule, LazyImport } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OutlookServiceOptions" */ './lazyIndex'),
    { name: 'OutlookServiceOptions' }
);

export const lazyCreateOrUpdateOptionsForFeature = new LazyAction(
    lazyModule,
    m => m.createOrUpdateOptionsForFeature
);

export const lazyUpdateAddinPinStatus = new LazyAction(lazyModule, m => m.updateAddinPinStatus);

// Synchronous exports
export { default as getDefaultOptions } from './data/defaultOptions';

export const lazyGetServerOptionsForFeature = new LazyImport(
    lazyModule,
    m => m.getServerOptionsForFeature
);

// Load the options after bootstrap
// We may, in the future, move this to bootstrap and block boot on its resolution
//
// 'loadOptions' will be called by the following
// 'runAfterInitialRender'. It returns a promise, so if any consumer wants
// to act after this is completed, it can wait on its completion and do work
export const lazyLoadOptions = new LazyAction(lazyModule, m => m.loadOptions);

export { isOptionsLoaded } from './selectors/isOptionsLoaded';
export type { default as AddinPinModes } from './utils/AddinPinModes';
