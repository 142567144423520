import type { MailboxInfo } from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import getGroupActivityState from '../selectors/getGroupActivityState';
import { type LoadState } from '../schema/GroupMemberRequestData';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setGroupActivityState = mutatorAction(
    'SET_GROUP_ACTIVITY_STATE',
    (mailboxInfo: MailboxInfo, loadState: LoadState) => {
        const groupActivityState = getGroupActivityState(mailboxInfo);
        groupActivityState.loadState = loadState;
    }
);

export default setGroupActivityState;
