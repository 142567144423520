import type FavoritesStore from './schema/FavoritesStore';
import { createStore } from 'owa-satcheljs';

const defaultFavoritesStore: FavoritesStore = {
    orderedCombinedOutlookFavoritesIds: [],
    outlookFavorites: new Map(),
    favoriteSecondaryKeyMap: new Map(),
    favoritingInProgressMap: new Map(),
    favoriteTreeData: new Map(),
    hideFavoritesList: false,
    unusedFavorites: new Map(),
    unusedFavoriteKeyMap: new Map(),
    shouldShowPinFavoritesTC: false,
};

const store = createStore<FavoritesStore>('favorites', defaultFavoritesStore)();
export default store;
export const getStore = () => store;
