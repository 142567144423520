import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isOfflineSyncEnabled } from './isOfflineSyncEnabled';
import { getIsDataWorkerFaulted, getIsOfflineDataFaulted, getSessionId } from 'owa-config';
import { getItem } from 'owa-local-storage';
import {
    getFallbackToNOPGALInSessionStorageKey,
    getFallbackToNOPGALLocalStorageKey,
} from './utils/toggleOfflineNoPGAL';

export type PDVStateDetails = {
    isOfflinePeopleDataViaPDVEnabledAllUp?: boolean;
    isOfflineSyncEnabled?: boolean;
    isPdvEnabled?: boolean;
    isDataWorkerFaulted?: boolean;
    isOfflineDataFaulted?: boolean;
    isAccountFallbackToNoPGAL?: boolean;
    isSessionFallbackToNoPGAL?: boolean;
    isOfflineTemplateEnabled?: boolean;
};

export function isOfflinePeopleDataViaPDVEnabled(
    viewerMailboxInfo: MailboxInfo,
    pdvStateDetails?: PDVStateDetails
) {
    // account check for fallback to no pgal sync module
    const fallbackToNoPGAL = getItem(self, getFallbackToNOPGALLocalStorageKey(viewerMailboxInfo));
    const isAccountFallbackToNoPGAL: boolean =
        !!fallbackToNoPGAL && fallbackToNoPGAL.toLocaleLowerCase() == 'true'; // this logic because its a string value

    // session check for fallback to online
    const fallbackToNoPGALSession: string =
        getItem(self, getFallbackToNOPGALInSessionStorageKey(viewerMailboxInfo)) || '';
    const isSessionFallbackToNoPGAL: boolean =
        !!fallbackToNoPGALSession && getSessionId() === fallbackToNoPGALSession;

    // We are intentionally not passing in MailboxInfo to below
    // flight checks because framework sync currently is
    // completely conditioned on the global settings mailbox account's
    // flights since they have some trouble at this time
    // to completely flight offline by account.
    const isOfflineSyncEnabledFlag = isOfflineSyncEnabled(viewerMailboxInfo);
    const isPdvEnabledFlag = isFeatureEnabled('peo-offline-via-pdv');
    const isDataWorkerFaultedFlag = getIsDataWorkerFaulted();
    const isOfflineDataFaultedFlag = getIsOfflineDataFaulted();
    const isOfflinePeopleDataViaPDVEnabledAllUp =
        isOfflineSyncEnabledFlag &&
        !isAccountFallbackToNoPGAL &&
        !isSessionFallbackToNoPGAL &&
        isPdvEnabledFlag &&
        !isDataWorkerFaultedFlag &&
        !isOfflineDataFaultedFlag;

    if (pdvStateDetails) {
        pdvStateDetails.isOfflinePeopleDataViaPDVEnabledAllUp =
            isOfflinePeopleDataViaPDVEnabledAllUp;
        pdvStateDetails.isOfflineSyncEnabled = isOfflineSyncEnabledFlag;
        pdvStateDetails.isPdvEnabled = isPdvEnabledFlag;
        pdvStateDetails.isDataWorkerFaulted = isDataWorkerFaultedFlag;
        pdvStateDetails.isOfflineDataFaulted = isOfflineDataFaultedFlag;
        pdvStateDetails.isAccountFallbackToNoPGAL = isAccountFallbackToNoPGAL;
        pdvStateDetails.isSessionFallbackToNoPGAL = isSessionFallbackToNoPGAL;
    }

    return isOfflinePeopleDataViaPDVEnabledAllUp;
}
