import { getFolderTable } from 'owa-folders';
import type { Permission } from 'owa-graph-schema';

/**
 * This function is wrapper over getUserPermissionForFolderIdWithErr function that can handle error.
 * In case of error this function will return null to denote that there are no permissions.
 * @param folderId - folder for which the permissions will be calculated
 * @param userEmailAddress - user email for which permissions will be filtered
 */
export function getUserPermissionForFolderId(
    folderId: string | undefined,
    userEmailAddress: string | undefined
): Permission {
    try {
        return getUserPermissionForFolderIdWithErr(folderId, userEmailAddress);
    } catch (e) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (20,9): Type 'null' is not assignable to type 'Permission'.
        // @ts-expect-error
        return null;
    }
}

/**
 * Function return user assigned permission for the given folderId and user email address
 * If no permissions are present for user then return undefined
 * If there is no value in store for PermissionSet or Permissions then let the function throw error implicitly
 * @param folderId - folder for which the permissions will be calculated
 * @param userEmailAddress - user email for which permissions will be filtered
 */
export function getUserPermissionForFolderIdWithErr(
    folderId: string | undefined,
    userEmailAddress: string | undefined
): Permission {
    if (!folderId || !userEmailAddress) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (39,9): Type 'null' is not assignable to type 'Permission'.
        // @ts-expect-error
        return null;
    }
    const selectedFolder = getFolderTable().get(folderId);
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2532 (45,38): Object is possibly 'undefined'.
    // @ts-expect-error
    const filteredFolderPermission = selectedFolder.PermissionSet.Permissions.filter(
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2531 (49,23): Object is possibly 'null'.
        // @ts-expect-error
        permission => permission.UserId.PrimarySmtpAddress === userEmailAddress
    );
    const assignedFolderPermission = filteredFolderPermission?.[0];
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (55,5): Type 'Maybe<Permission>' is not assignable to type 'Permission'.
    // @ts-expect-error
    return assignedFolderPermission;
}
