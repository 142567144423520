import ActionState from '../schema/ActionState';
import followButtonStore from '../FollowButtonStore';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export function getFollowButtonStateMap(mailboxInfo: MailboxInfo) {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const follwButtonState = followButtonStore.groupActionStateByMailboxInfo.get(accountKey);

    if (!follwButtonState) {
        const defaultFollowButtonState = {
            groupActionState: new Map<string, ActionState>(),
        };

        followButtonStore.groupActionStateByMailboxInfo.set(accountKey, defaultFollowButtonState);
        return defaultFollowButtonState.groupActionState;
    }

    return follwButtonState.groupActionState;
}

export function getFollowButtonState(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string
): ActionState {
    groupSmtpAddress = transformGroupSmtp(groupSmtpAddress);

    const groupActionState = getFollowButtonStateMap(mailboxInfo);

    if (groupActionState.has(groupSmtpAddress)) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (13,9): Type 'ActionState | undefined' is not assignable to type 'ActionState'.
        // @ts-expect-error
        return groupActionState.get(groupSmtpAddress);
    }
    return ActionState.None;
}
