import type { MailboxInfo } from 'owa-client-types';
import { isSharedMailboxForCategories } from './isSharedMailboxForCategories';
import getMasterOrRemoteCategoryList from './getMasterOrRemoteCategoryList';
import { getCalendarEntryForCategories } from 'owa-categories-option-store';
import getMasterCategoryList from './getMasterCategoryList';
import type CategoryType from 'owa-service/lib/contract/CategoryType';

export function getMasterOrSharedCategoryList(mailboxInfo: MailboxInfo): CategoryType[] {
    return isSharedMailboxForCategories(mailboxInfo)
        ? getMasterOrRemoteCategoryList(
              getCalendarEntryForCategories(mailboxInfo.mailboxSmtpAddress),
              mailboxInfo
          ).categoryList
        : getMasterCategoryList(mailboxInfo);
}
