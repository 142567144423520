import { observer } from 'owa-mobx-react';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import React from 'react';
import getMailListItemDataProps from '../utils/getMailListItemDataProps';
import MailListItemContainer from './MailListItemContainer';
import type { MailListTableProps } from 'owa-mail-list-item-shared';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getTableItemRelation, getTableConversationRelation } from 'owa-mail-list-store';

export interface MailListItemDataProviderProps {
    rowKey: string;
    mailListTableProps: MailListTableProps;
    resetListViewContentTabIndex: () => void;
    getIsFocusInMailList: () => boolean;
    styleSelectorAsPerUserSettings: string;
    renderMinimum: boolean;
}

function useMemoizedMailListItemDataProps(
    rowKey: string,
    mailListTableProps: MailListTableProps,
    hasTableRelationInformation: boolean
) {
    return React.useMemo(() => {
        return internalGetMailListItemDataProps(
            rowKey,
            mailListTableProps,
            hasTableRelationInformation
        );
    }, [
        /**
         * Object.values required its input to be an object, so in the case where
         * null is returned from getMailListItemDataProps, we need to make sure
         * we fallback to an empty object.
         */
        ...Object.values(
            internalGetMailListItemDataProps(
                rowKey,
                mailListTableProps,
                hasTableRelationInformation
            ) ?? {}
        ),
        hasTableRelationInformation,
    ]);
}

function internalGetMailListItemDataProps(
    rowKey: string,
    mailListTableProps: MailListTableProps,
    hasTableRelationInformation: boolean
) {
    // Bug 1051: Investigate mobx behavior of calling render for a single removed item before calling render on the list
    // For conversation we check for the tableRelation existence as both tableRelation and the conversation are deleted same time
    // For item we check for the tableRelation as well as the item's existence that are in separate stores
    // Mobx calls render on this item before calling the mail list's render.
    if (!hasTableRelationInformation) {
        return null;
    }

    return getMailListItemDataProps(rowKey, mailListTableProps);
}

export default observer(function MailListItemDataProvider(props: MailListItemDataProviderProps) {
    const tableConversationRelation = useComputedValue(() => {
        return getTableConversationRelation(props.rowKey, props.mailListTableProps.tableViewId);
    }, [props.rowKey, props.mailListTableProps.tableViewId]);
    const tableItemRelation = useComputedValue(() => {
        return getTableItemRelation(props.rowKey, props.mailListTableProps.tableViewId);
    }, [props.rowKey, props.mailListTableProps.tableViewId]);

    // Remark: The reason we have this wrapper component around MailListItem is to minimize the number of re-renders.
    // Since getting mail list item data props will bind to all item props for the row, we don't want to do this in MailListContent and cause
    // the list of re-render when a single item's prop changes. We also don't want to do this inside MailListItem because we want to pass this as props
    // so that computed properties can use these properties for computed states.
    const hasTableRelationInformation =
        props.mailListTableProps.listViewType == 0
            ? !!tableConversationRelation
            : !!tableItemRelation;
    const mailListItemDataProps = useMemoizedMailListItemDataProps(
        props.rowKey,
        props.mailListTableProps,
        hasTableRelationInformation
    );

    return (
        // VSO 25058: Investigate why conversationItem not found in store in getMailListItemDataProps
        mailListItemDataProps && (
            <MailListItemContainer
                mailListTableProps={props.mailListTableProps}
                mailListItemDataProps={mailListItemDataProps}
                resetListViewContentTabIndex={props.resetListViewContentTabIndex}
                getIsFocusInMailList={props.getIsFocusInMailList}
                styleSelectorAsPerUserSettings={props.styleSelectorAsPerUserSettings}
                renderMinimum={props.renderMinimum}
            />
        )
    );
}, 'MailListItemDataProvider');
