import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import { isFeatureEnabled } from 'owa-feature-flags';
import isSenderImageEnabled from 'owa-sender-image-option/lib/utils/isSenderImageEnabled';

export default function showPersonaCheckbox(
    isSearchTable: boolean,
    tableView: TableView | undefined
): boolean {
    // Always show the checkbox in search table
    if (isSearchTable) {
        return true;
    }

    const senderImageEnabled = isSenderImageEnabled();

    // If the reselect flight is enabled show the checkbox if sender images are enabled
    // Do not show the checkbox if we are optimistically in checked mode
    if (isFeatureEnabled('tri-reselect-checkbox-behavior')) {
        if (senderImageEnabled) {
            return true;
        } else {
            return !!tableView?.isInCheckedMode && !tableView?.isOnlyOptimisticallyInCheckedMode;
        }
    }

    // Otherwise show the checkbox if sender images are enabled, we are in select all state, or in checked mode
    return senderImageEnabled || !!tableView?.isInSelectAllState || !!tableView?.isInCheckedMode;
}
