import { LazyAction, LazyBootModule } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "OwaCloudCacheSyncHealthCheck" */ './lazyIndex'),
    { name: 'OwaCloudCacheSyncHealthCheck' }
);

export const lazyHandleSyncHealthCheckResponse = new LazyAction(
    lazyModule,
    m => m.handleSyncHealthCheckResponse
);

export const lazyGetMailboxStatus = new LazyAction(lazyModule, m => m.getMailboxStatus);

export const lazyRegisterForSyncHealthCheck = new LazyAction(
    lazyModule,
    m => m.registerForSyncHealthCheck
);
