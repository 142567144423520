import type { ItemRow } from 'owa-graph-schema';
import { store } from '../store/store';
import type { MailListRowDataType } from 'owa-mail-list-store';
import { convertRestIdToEwsId } from 'owa-identifiers';
import { logUsage } from 'owa-analytics';

export const filterEmailsWithScheduleTasks = (rows: ItemRow[]): MailListRowDataType[] => {
    const rowsAsItems = rows;
    const itemsIdsWithSchedulingTasks = store.itemsIdsWithSchedulingTasks;
    if (itemsIdsWithSchedulingTasks.length === 0) {
        rows = [];
    } else {
        rows = rowsAsItems.filter(row => {
            if (!row.ItemId.Id) {
                return false;
            }

            return itemsIdsWithSchedulingTasks.some(
                id => convertRestIdToEwsId(id) === row.ItemId.Id
            );
        });
    }

    logUsage('CopilotDigestScheduleFilterRowCount', { count: rows.length });
    return rows as MailListRowDataType[];
};
