import React from 'react';
import type { AppBootstrapOptions } from './types/AppBootstrapOptions';
import type { BootstrapOptions } from 'owa-bootstrap';
import { bootstrap } from 'owa-bootstrap/lib/bootstrap';
import { registerBootstrapOptions } from './optionsRegistry';
import AppModule from './components/AppModule';
import { initializeModule } from 'owa-app-module-store';
import { getServiceWorkerConfig } from './getServiceWorkerConfig';
import type { ModuleConfigMap } from 'owa-shared-start-types';
import { setModuleToConfigMap } from './changeModuleIfEnabledOrchestrator';
import { autoUpdateFavIcon } from './autoUpdateFavIcon';
import type { SessionData } from 'owa-service/lib/types/SessionData';

export type AppBootstrapFunction = (
    options: AppBootstrapOptions,
    configRegistry?: ModuleConfigMap<BootstrapOptions>
) => Promise<void>;

export const appBootstrap: AppBootstrapFunction = async (
    options: AppBootstrapOptions,
    configRegistry?: ModuleConfigMap<BootstrapOptions>
) => {
    if (configRegistry) {
        setModuleToConfigMap(configRegistry);
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    const mod = options.appModuleProps!.module;
    initializeModule(mod);
    const serviceWorkerConfig = getServiceWorkerConfig(mod);
    if (serviceWorkerConfig) {
        options.swConfig = serviceWorkerConfig;
    }

    registerBootstrapOptions(options);

    const suiteWrapper = options.strategies?.suiteWrapper;
    const components = suiteWrapper ? await suiteWrapper.import().then(i => i()) : undefined;
    let wrapSuiteHeader: ((suiteHeader: React.ReactNode) => React.ReactNode) | undefined;
    if (components) {
        options.windowIcons = components.windowIcons;
        wrapSuiteHeader = suiteHeader => <components.suiteHeader suiteHeader={suiteHeader} />;
    }

    options.renderMainComponent = () => <AppModule wrapSuiteHeader={wrapSuiteHeader} />;

    return bootstrap({
        ...options,
        initializeState: (sessionData?: SessionData) => {
            // Auto update fav icon in initializeState to make sure
            // feature flags are initialized
            autoUpdateFavIcon();
            return options.initializeState?.(sessionData);
        },
    });
};
