enum CopilotPriority {
    None = 0,
    High = 1,
    Normal = 2,
    Low = 3,
}

export function isCopilotHighPriority(score: number | undefined | null) {
    return getCopilotPrioritizePriority(score) === CopilotPriority.High;
}

export function isCopilotLowPriority(score: number | undefined | null) {
    return getCopilotPrioritizePriority(score) === CopilotPriority.Low;
}

export function getCopilotPrioritizePriority(score: number | undefined | null): CopilotPriority {
    if (score === 4 || score === 5) {
        return CopilotPriority.High;
    } else if (score === 3) {
        return CopilotPriority.Normal;
    } else if (score === 1 || score === 2) {
        return CopilotPriority.Low;
    }

    return CopilotPriority.None;
}
