import { mutator } from 'satcheljs';
import { resetSelectionOnTable } from 'owa-mail-actions/lib/mailListSelectionActions';
import resetExpansionViewStateInternal from 'owa-mail-list-store/lib/utils/resetExpansionViewStateInternal';

export default mutator(resetSelectionOnTable, actionMessage => {
    const tableView = actionMessage.tableView;
    tableView.selectedRowKeys.clear();
    tableView.isInCheckedMode = false;
    tableView.isOnlyOptimisticallyInCheckedMode = false;
    resetExpansionViewStateInternal();
});
