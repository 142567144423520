import type { ClientAttachmentId } from 'owa-client-ids';
import type EventEmitter from 'owa-event-emitter';
import type { MeetingMessageAddToCalendarStatus } from 'owa-meeting-message-utils';
import type { SxSViewState } from 'owa-sxsdata';
import type PendingOperationState from './PendingOperationState';
import type SigsInfo from './SigsInfo';
import type SxSExtendedViewState from './SxSExtendedViewState';
import type SxSReadingPaneState from './SxSReadingPaneState';

export const SXSID_MAIN_WINDOW: string = 'sxsIdMainWindow';
export const SXSV2_CONTAINER_ID: string = 'owaSxSV2';

export enum SxSCustomization {
    Mail,
    FilesHub,
    DeepLink,
    GroupFilesHub,
    PopOut /* SxS that is popped out from itself */,
}

export interface SxSStoreData {
    shouldShow: boolean;
    viewState: SxSViewState;
    extendedViewState: SxSExtendedViewState;
    customPreviewState: any;
    eventManager: EventEmitter;
    pendingPreviewOperation: PendingOperationState | null;
    pendingNavigation: {
        toAttachmentId: ClientAttachmentId;
        triggerTime: number;
    } | null;

    readingPaneState: SxSReadingPaneState | null;
    composeId: string | null;
    customization: SxSCustomization | null;
    isInvisible: boolean;

    shouldUseFakeRightHandSide: boolean;
    isUsingFakeRightHandSide: boolean;

    attachmentViewStateIdFromListView: string | null;

    sessionPreviewCount: number;
    sessionStartTime: number;

    meetingMessageAddToCalendarStatus: MeetingMessageAddToCalendarStatus;

    sigsInfo: null | SigsInfo;
}

export interface SxSStore {
    sxsStoreMapping: Map<string, SxSStoreData>;
}
