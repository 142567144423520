import React from 'react';
import { useWindowEvent } from 'owa-react-hooks/lib/useWindowEvent';
import createDropViewState from 'owa-dnd/lib/utils/createDropViewState';
import Droppable from 'owa-dnd/lib/components/Droppable';
import MailLeftPane from './MailLeftPane';
import MailView from './MailView';
import SearchView from './SearchView';
import setTypeOfItemBeingDragged from 'owa-mail-store/lib/actions/setTypeOfItemBeingDragged';
import store from 'owa-mail-store/lib/store/Store';
import { AdsPanelStub } from 'owa-mail-ads-stub';
import type { SearchBoxContainerHandle } from 'owa-search';
import { isGroupSelected } from 'owa-group-utils';
import { isAppPaneUnderlayExpanded } from 'owa-application';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isGroupsEnabled } from 'owa-account-capabilities/lib/isGroupsEnabled';
import { isFeatureEnabled } from 'owa-feature-flags';
import { triggerResizeEvent } from 'owa-resize-event';
import { observer } from 'owa-mobx-react';
import { resetFocus } from 'owa-mail-focus-manager';
import { ResizeHandle, ResizeHandleScenario } from 'owa-resize-handle';
import {
    leftPaneResized,
    getLeftNavWidth,
    shouldShowFolderPaneAsOverlay,
    shouldShowFolderPane,
} from 'owa-mail-layout';
import { getRightPaneStyles } from 'owa-left-pane-layout/lib/utils/getRightPaneStyles';
import { setDraggedItemType as setDraggedItemTypeInModule } from 'owa-module-dnd-store';
import { Announced } from 'owa-controls-announced';
import { useLazyKeydownHandler } from 'owa-hotkeys';
import { lazySetupMailModuleKeys, GroupView } from './lazy/lazyFunctions';
import { useDragHelpers } from './hooks/useDragHelpers';
import { TopUpsellBannerComponent } from 'owa-upsell-components-placeholder';

import onPageVisibilityChanged from 'owa-mail-favicon/lib/orchestrators/onPageVisibilityChanged';
import { APP_BAR_STATIC_WIDTH } from 'owa-layout';
import { TimeZoneTravelNotification } from 'owa-time-zone-travel-dialog';
import { useNavigationManager } from './hooks/useNavigationManager';
import { getIsSearchTableShown, getSelectedTableView } from 'owa-mail-list-store';
import {
    mailMain,
    allPaneContainer,
    innerPaneContent,
    panes,
    leftResizeHandler,
    rightPaneContainer,
    positionRelative,
} from './MailModule.scss';
import { lazySetupTriageActionKeys } from 'owa-mail-list-view';
import { getStore as getCategoryStore } from 'owa-categories/lib/store/store';

import classnames from 'owa-classnames';

// Replace this with an interface when we have real props
// For now leaving it as named so that the generic DragDropContext
// has a named type to use
export interface MailModuleProps {
    searchBoxRef: React.RefObject<SearchBoxContainerHandle>;
}

export default observer(function MailModule(props: MailModuleProps) {
    const rightPaneRef = React.useRef<HTMLDivElement>(null);
    useLazyKeydownHandler(undefined, lazySetupMailModuleKeys.importAndExecute, props.searchBoxRef);

    const tableViewId = getSelectedTableView()?.id;
    const categoriesVersion = getCategoryStore().categoriesVersion;
    const lazyKeyProps = React.useMemo(
        () => ({
            tableViewId,
            categoriesVersion,
        }),
        [tableViewId, categoriesVersion]
    );
    useLazyKeydownHandler(rightPaneRef, lazySetupTriageActionKeys.importAndExecute, lazyKeyProps);

    useWindowEvent(
        'focus',
        () => {
            onPageVisibilityChanged();
        },
        'MailModule_focus',
        []
    );
    useWindowEvent(
        'blur',
        () => {
            onPageVisibilityChanged();
        },
        'MailModule_blur',
        []
    );
    useNavigationManager();

    const dragHelpers = useDragHelpers(setDataItemType);

    React.useEffect(() => {
        // Set initial focus after component is mounted
        resetFocus('MailModule');
    }, []);

    const emptyDropViewState = createDropViewState();
    const shouldShowAds = isConsumer() && !isFeatureEnabled('ads-shutdown-displayAds');
    const showFolderPane = shouldShowFolderPane();
    const isSearchTable = getIsSearchTableShown();

    return (
        <>
            {isFeatureEnabled('cal-roaming-timeZones-mail') && <TimeZoneTravelNotification />}
            <Droppable
                classNames={mailMain}
                {...dragHelpers}
                dropViewState={emptyDropViewState}
                shouldIgnoreTransientOnDragLeave={true}
                bypassActOnDrop={true}
            >
                {<TopUpsellBannerComponent />}
                <div className={allPaneContainer} id="MainModule">
                    <div className={innerPaneContent}>
                        <div className={panes}>
                            <MailLeftPane />
                            {
                                // Add resize handler only when folder pane is not shown as an overlay
                                !shouldShowFolderPaneAsOverlay() && showFolderPane && (
                                    <ResizeHandle
                                        className={leftResizeHandler}
                                        onResized={leftNavResizeHandler}
                                        scenario={ResizeHandleScenario.FolderPane}
                                        onResizeViaKeyboardCompleted={
                                            onFolderPaneResizeViaKeyboardCompleted
                                        }
                                    />
                                )
                            }
                            <div
                                // This value is given so that the resize handler does not automatically calculate max for this div
                                // Else the max is calculated as window width - the max of left pane which may lead to not being
                                // able to resize the left pane when both left and right divs reach their max widths.
                                data-max-width={2400}
                                className={classnames(
                                    rightPaneContainer,
                                    getRightPaneStyles(
                                        showFolderPane && !shouldShowFolderPaneAsOverlay(),
                                        getLeftNavWidth()
                                    )
                                )}
                                ref={rightPaneRef}
                            >
                                {isInGroupsView() ? (
                                    <GroupView />
                                ) : isSearchTable ? (
                                    <SearchView />
                                ) : (
                                    <MailView />
                                )}
                            </div>
                            {shouldShowAds && (
                                <AdsPanelStub
                                    isBottom={false}
                                    isHidden={isAppPaneUnderlayExpanded()}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {shouldShowAds && <AdsPanelStub isBottom={true} />}
            </Droppable>
            {/* position:relative to work around bug #308192 */}
            <div className={positionRelative}>
                <Announced
                    aria-live={store.triageAnnouncement.politenessSetting}
                    message={store.triageAnnouncement.message}
                />
            </div>
        </>
    );
}, 'MailModule');

function isInGroupsView() {
    return isGroupsEnabled() && isGroupSelected();
}

/**
 * An onResize callback used to update the user config with the new value for folder list's width.
 * @param fullLeftNavWidth - the measured width from the left of the screen to the resize handle
 */
function leftNavResizeHandler(fullLeftNavWidth: number) {
    fullLeftNavWidth += APP_BAR_STATIC_WIDTH;
    leftPaneResized(fullLeftNavWidth);
    triggerResizeEvent();
}

function setDataItemType(newValue: string | null) {
    if (store.typeOfItemBeingDragged != newValue) {
        setTypeOfItemBeingDragged(newValue);
        setDraggedItemTypeInModule(newValue);
    }
}

function onFolderPaneResizeViaKeyboardCompleted() {
    resetFocus('onFolderPaneResizeViaKeyboardCompleted');
}
