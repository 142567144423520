import store from '../store/store';
import type OwaWorkload from '../store/schema/OwaWorkload';
import { mutatorAction } from 'satcheljs';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const setOwaWorkload = mutatorAction('setOwaWorkload', function (workload: OwaWorkload) {
    store.currentWorkload = workload;
});

export default setOwaWorkload;
