import {
    isGroupTableQuery,
    lazyGetRetentionPolicyTagListForGroupTableQuery,
    lazyGetRetentionPolicyTagsForGroup,
} from 'owa-group-utils';
import type { TableView } from 'owa-mail-list-store';
import getSharedFolderPolicyTagList from 'owa-shared-folder/lib/selectors/getSharedFolderPolicyTagList';
import { getMailboxInfoFromTableQuery } from 'owa-mail-mailboxinfo';
import { getUserMailboxInfo } from 'owa-client-ids';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';

export function getRetentionPolicyTagList(
    tableView: TableView,
    mailboxInfo: MailboxInfo,
    groupId?: string
) {
    if ((tableView && isGroupTableQuery(tableView.tableQuery)) || groupId) {
        if (groupId) {
            const getRetentionPolicyTagsForGroup =
                /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                 * BASELINE. Do not copy and paste!
                 *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
                lazyGetRetentionPolicyTagsForGroup.tryImportForRender();
            return getRetentionPolicyTagsForGroup
                ? getRetentionPolicyTagsForGroup(
                      getMailboxInfoFromTableQuery(tableView.tableQuery),
                      groupId
                  )
                : [];
        } else {
            const getRetentionPolicyTagListForGroupTableQuery =
                /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                 * BASELINE. Do not copy and paste!
                 *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
                lazyGetRetentionPolicyTagListForGroupTableQuery.tryImportForRender();
            return getRetentionPolicyTagListForGroupTableQuery
                ? getRetentionPolicyTagListForGroupTableQuery(
                      tableView.tableQuery,
                      getMailboxInfoFromTableQuery(tableView.tableQuery)
                  )
                : [];
        }
    }

    if (mailboxInfo.type === 'SharedMailbox') {
        return getSharedFolderPolicyTagList(mailboxInfo.mailboxSmtpAddress);
    }

    return getAccountScopeUserSettings(mailboxInfo).RetentionPolicyTags;
}

export function getRetentionPolicyTagListForItem(
    mailboxInfo: MailboxInfo,
    folderId: string | null
) {
    if (mailboxInfo.type === 'GroupMailbox') {
        const getRetentionPolicyTagsForGroup =
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * BASELINE. Do not copy and paste!
             *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
            lazyGetRetentionPolicyTagsForGroup.tryImportForRender();
        return getRetentionPolicyTagsForGroup
            ? /* eslint-disable-next-line owa-custom-rules/no-default-methods  -- (https://aka.ms/OWALintWiki)
               * Methods that use the default mailbox should not be used, see https://aka.ms/multiaccountlinter
               *	> Do Not Use Default: The getUserMailboxInfo function should not be used for Respond multi-account support, user identity is not enough to identify account information. */
              getRetentionPolicyTagsForGroup(getUserMailboxInfo(), folderId)
            : [];
    }

    if (mailboxInfo.type === 'SharedMailbox') {
        return getSharedFolderPolicyTagList(mailboxInfo.mailboxSmtpAddress);
    }

    return getAccountScopeUserSettings(mailboxInfo).RetentionPolicyTags;
}
