import type { MailboxInfo } from 'owa-client-types';
import { getApplicationSettings } from 'owa-application-settings';
import {
    isOfflinePeopleDataViaPDVEnabled,
    type PDVStateDetails,
} from './isOfflinePeopleDataViaPDVEnabled';

export function isPDVHeaderInfoAndCardTypeEnabled(
    viewerMailboxInfo: MailboxInfo,
    pdvStateDetails?: PDVStateDetails
) {
    // We are intentionally not passing in MailboxInfo to below
    // flight checks because framework sync currently is
    // completely conditioned on the global settings mailbox account's
    // flights since they have some trouble at this time
    // to completely flight offline by account.
    const isOfflineTemplateEnabled = getApplicationSettings('PDV').peoOfflineTemplate;
    const isOfflinePeopleDataViaPDVEnabledFlag = isOfflinePeopleDataViaPDVEnabled(
        viewerMailboxInfo,
        pdvStateDetails
    );

    if (pdvStateDetails) {
        pdvStateDetails.isOfflineTemplateEnabled = isOfflineTemplateEnabled;
    }

    return isOfflinePeopleDataViaPDVEnabledFlag && isOfflineTemplateEnabled;
}
