import type { default as SearchStore, SearchScenarioStore } from './schema/SearchStore';
import type { PillSuggestion } from 'owa-search-service/lib/data/schema/SuggestionSet';
import { createStore } from 'owa-satcheljs';

export const createDefaultSearchStore = (): SearchScenarioStore => {
    return {
        alteredQuery: '',
        alterationProviderId: '',
        answerPlaceholderId: '',
        currentSearchQueryId: '',
        currentSuggestions: undefined,
        displayedQFRequestTime: new Date(),
        flaggedTokens: [],
        isAnswerRendered: false,
        isLastKeyPressedDeletion: false,
        isSuggestionsCalloutClickable: false,
        isSuggestionsDropdownVisible: false,
        latestQFRequestId: '',
        latestRenderedQFTraceId: '',
        latestQFTraceId: '',
        latestTraceId: '',
        logicalId: '',
        nextSearchQueryId: '',
        recourseQuery: '',
        searchBoxHasFocus: false,
        searchBoxWidth: 0,
        searchSessionGuid: '',
        searchText: '',
        searchTextForSuggestion: '',
        selectedPillIndex: -1,
        selectedSuggestionIndex: -1,
        selectedSuggestionQuickActionSelected: false,
        showSearchBoxInCompactMode: false,
        isFocusOnScopeTabs: false,
        suggestedSearchTerm: '',
        suggestedSearchTermReferenceId: '',
        suggestionPillIds: [],
        transientSuggestionPillId: '',
        suggestionPills: new Map<string, PillSuggestion>(),
        queryAlterationType: null,
        queryActionSource: null,
        queryAlterationLogicalId: '',
        alterationDisplayText: '',
        traceIdToLogicalIdMap: new Map<string, string>(), // Map of Trace Ids to Logical Ids
        tableViewId: '',
        isScopePickerVisible: false,
        filters: [],
        placeholderIdToIsRenderedMap: new Map<string, boolean>(),
        interactionStartTime: -1,
        inKeyboardSelectionMode: false,
        suggestionsQuickActions: new Map<number, string[]>(),
        selectedSuggestionActiveQuickActionIndex: -1,
    };
};

const searchStore: SearchStore = {
    scenarioStores: new Map<string, SearchScenarioStore>(),
};

export default createStore<SearchStore>('commonSearchStore', searchStore);
