import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';
import { areFeatureFlagsInitialized } from '../actions/initializeFeatureFlags';
import { type MailboxInfo } from 'owa-client-types';
import isAnonymousFlightingEnabled from './anonymousFlighting';
import getFeatureFlagsIndexerForMailboxInfo from './getFeatureFlagsIndexerForMailboxInfo';
import { accountKeyForAddAccount } from 'owa-account-source-list-types';
import { getDiagnosticInfo } from './isFeatureEnabled';

/**
 * To help identify the context in which the check is being made the caller must supply a context, this
 * context is a typed union of the possible strings so that we know it is safe for including in the
 * alert that is raised.
 */
export type FeatureFlagLoadCheckContext = 'M365AcquisitionsWrite' | 'M365AcquisitionsQuery';

/**
 * Checks to see if the feature flags are initialized for the specified MailboxInfo. This function
 * was created to allow checking a MailboxInfo that was to be passed to an async function or another
 * worker thread so that we know if the feature flags are initialized for that MailboxInfo.
 * @param mailboxInfo Specifies the MailboxInfo to be checked
 */
export default function checkFeatureFlagLoadStateForMailboxInfo(
    checkContext: FeatureFlagLoadCheckContext,
    mailboxInfo?: MailboxInfo
) {
    if (
        areFeatureFlagsInitialized(mailboxInfo) ||
        isAnonymousFlightingEnabled() ||
        accountKeyForAddAccount == getFeatureFlagsIndexerForMailboxInfo(mailboxInfo)
    ) {
        return;
    }

    // The feature flags are not initialized yet, collect information and report an error
    const error: TraceErrorObject = new Error('FeatureFlags are not initialized');
    error.additionalInfo = { ...getDiagnosticInfo(undefined, mailboxInfo), checkContext };

    errorThatWillCauseAlert(error);
}
