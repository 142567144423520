import { mutatorAction } from 'satcheljs';
import type { PublicFolderStore } from '../store/publicFolderFavoriteStore';
import type { MailFolder } from 'owa-graph-schema';
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const addPublicFolderToStore = mutatorAction(
    'ADD_PUBLIC_FOLDER_TO_STORE',
    (state: PublicFolderStore, item: MailFolder) => {
        const folderId = item.id;
        if (!state.folderTable.has(folderId)) {
            state.folderTable.set(folderId, item);
        }
    }
);
export default addPublicFolderToStore;
