import { createStore } from 'owa-satcheljs';
import type {
    MailHandlingOptionState,
    MailHandlingOptionStateMap,
} from './schema/MailHandlingOptionState';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import { getStoredMailboxJunkEmailConfiguration } from 'owa-junkmail-configuration';
import { getAccountScopeUserSettings } from 'owa-session-store';

export function getInitialMailHandlingOptionState(mailboxInfo: MailboxInfo) {
    const mailboxJunkEmailConfiguration = getStoredMailboxJunkEmailConfiguration(mailboxInfo);
    const isSenderScreeningUserOptionEnabled =
        getAccountScopeUserSettings(mailboxInfo).UserOptions?.IsSenderScreeningSettingEnabled ??
        false;
    const mailHandlingOption: string =
        mailboxJunkEmailConfiguration.TrustedListsOnly || isSenderScreeningUserOptionEnabled
            ? 'Strict'
            : 'Standard';
    return {
        MailHandlingOption: mailHandlingOption,
        SenderScreeningEnabled: isSenderScreeningUserOptionEnabled,
    };
}

const getMailHandlingOptionStateMap = createStore<MailHandlingOptionStateMap>(
    'mailHandlingOptionState',
    {
        entries: new Map(),
    }
);

export default function getMailHandlingOptionState(
    mailboxInfo: MailboxInfo
): MailHandlingOptionState {
    const mailHandlingOptionStateMap = getMailHandlingOptionStateMap();
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    let store = mailHandlingOptionStateMap.entries.get(key);
    if (!store) {
        store = getInitialMailHandlingOptionState(mailboxInfo);
        mailHandlingOptionStateMap.entries.set(key, store);
    }
    return store;
}
