import getO365ShellShim from './getO365ShellShim';
import type { CustomHeaderButtonBadgeColors } from '@1js/suiteux-shell-react';

export default function setShellButtonCustomBadgeColors(
    buttonId: string,
    badgeColors: CustomHeaderButtonBadgeColors | null
) {
    // Call after RAF to prevent changing state during render
    /* eslint-disable-next-line no-restricted-globals -- (https://aka.ms/OWALintWiki)
     * BASELINE. DO NOT COPY AND PASTE!
     *	> Unexpected use of 'requestAnimationFrame'. Use safeRequestAnimationFrame in owa-performance instead. */
    requestAnimationFrame(() =>
        getO365ShellShim().Extensibility.SetCustomHeaderButtonBadgeColor(buttonId, badgeColors)
    );
}
