import { useWindowEvent } from 'owa-react-hooks/lib/useWindowEvent';
import { lazyOnKeyDownEvent } from 'owa-accessibility-loop-nav';

export function useNavigationManager() {
    useWindowEvent(
        'keydown',
        (event: KeyboardEvent) => {
            lazyOnKeyDownEvent.importAndExecute(event);
        },
        'useNavigationManager_keydown',
        []
    );
}
