import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getApplicationSettings } from 'owa-application-settings';

/**
 * Whether or not the mailbox supports shortcuts that can apply a specific
 * category to an item.
 */
export const categorizeShortcutsCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return isFeatureEnabled('tri-specific-category-shortcuts');
    },
    // Capability is only available in the native client
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        return getApplicationSettings('PlatformType').isMonarch;
    },
};
