import { LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "QuickSteps" */ './lazyIndex'),
    { name: 'QuickSteps' }
);

// Lazy action
export const lazyAddQuickStep = new LazyImport(lazyModule, m => m.addQuickStep);
export const lazyRemoveQuickStep = new LazyImport(lazyModule, m => m.removeQuickStep);
export const lazyMoveQuickStep = new LazyImport(lazyModule, m => m.moveQuickStep);
export const lazySetQuickStepsRunOnce = new LazyImport(lazyModule, m => m.setQuickStepsRunOnce);

// non lazy exports
export { getQuickSteps } from './selectors/getQuickSteps';
export { getQuickStepActionsByHotkey } from './selectors/getQuickStepActionsByHotkey';
export { getQuickStepActionsById } from './selectors/getQuickStepById';
export { getQuickStepHotkeyText } from './util/getQuickStepHotkeyText';
export { getQuickStepsRunOnce } from './selectors/getQuickStepsRunOnce';

// types
export type { QuickStepReturnInfo } from './selectors/getQuickStepById';
