import { isFeatureEnabled } from 'owa-feature-flags';

let loadedScriptsCallbacks: {
    [id: string]: Promise<void>;
} = {};

let successfulLoadedScripts: string[];

export function loadScript(url: string, addCrossOriginAnonymous = true): Promise<void> {
    // If the ads-xandrsdk-direct-testonly feature flag is not enabled, skip the script loading as we will use the npm one.
    if (!isFeatureEnabled('ads-xandrsdk-direct-testonly')) {
        return Promise.resolve();
    }

    if (
        !loadedScriptsCallbacks[url] ||
        !successfulLoadedScripts ||
        successfulLoadedScripts.indexOf(url) == -1
    ) {
        loadedScriptsCallbacks[url] = new Promise((resolve, reject) => {
            var element = window.document.createElement('script');
            element.src = url;
            element.type = 'text/javascript';
            if (addCrossOriginAnonymous) {
                element.crossOrigin = 'anonymous';
            }
            element.onload = () => {
                successfulLoadedScripts
                    ? successfulLoadedScripts.push(url)
                    : (successfulLoadedScripts = [url]);
                resolve();
            };
            element.onerror = () => {
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * Error constructor names can only be a string literals.
                 *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                reject(new Error('OnError ' + url));
            };
            window.document.head.appendChild(element);
        });
    }

    return loadedScriptsCallbacks[url];
}

export function test_clearScriptLoadTestHook() {
    loadedScriptsCallbacks = {};
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (39,5): Type 'undefined' is not assignable to type 'string[]'.
    // @ts-expect-error
    successfulLoadedScripts = undefined;
}
