import { getResourcePathUrl } from 'owa-resource-url';
import { addProtocol } from 'owa-config/lib/bootstrapOptions';
import { logUsage } from 'owa-analytics';

const FAVICON_REL = 'shortcut icon';
const FAVICON_TYPE = 'image/x-icon';

export default function applyFavicon(relativeFavIconPath: string, overlayIconPath?: string) {
    if (!document) {
        // We may be in SSR? Bail either way
        return;
    }

    function applyFaviconInternal() {
        const favIconUrl = getResourcePathUrl(relativeFavIconPath);
        let favIconElement = document.querySelector(
            `link[rel="${FAVICON_REL}"]`
        ) as HTMLLinkElement;

        if (!favIconElement) {
            favIconElement = document.createElement('link');
            favIconElement.rel = FAVICON_REL;
            favIconElement.type = FAVICON_TYPE;

            document.head.appendChild(favIconElement);
        }

        const fullfavIconUrl = addProtocol(favIconUrl);

        if (favIconElement.href != fullfavIconUrl || overlayIconPath !== undefined) {
            for (const cb of favIconCallbacks) {
                cb(fullfavIconUrl, overlayIconPath);
                /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops -- (https://aka.ms/OWALintWiki)
                 * Baseline, this function can't be used inside a loop, please move it outside
                 *	> Function 'logUsage' matches forbidden pattern (/logUsage/) and should not be used inside loops */
                logUsage('FaviconCallback', {
                    FullIcon: fullfavIconUrl,
                    OverlayIcon: overlayIconPath,
                });
            }
        }

        favIconElement.href = favIconUrl; //protocol automatically gets added in the href
    }

    if (window.document.readyState != 'loading') {
        applyFaviconInternal();
    } else if (window.document.addEventListener as any) {
        addDomLoadedEvent(applyFaviconInternal);
    } else {
        window.onload = () => applyFaviconInternal();
    }
}

const domLoadedEvent = 'DOMContentLoaded';
function addDomLoadedEvent(applyFaviconInternal: () => void) {
    function domLoaded() {
        window.document.removeEventListener(domLoadedEvent, domLoaded);
        applyFaviconInternal();
    }
    window.document.addEventListener(domLoadedEvent, domLoaded, false);
}

type FavIconCallback = (iconUrl: string, overlayIconUrl?: string) => void;
const favIconCallbacks: FavIconCallback[] = [];
export function registerFavIconCallback(cb: FavIconCallback) {
    favIconCallbacks.push(cb);
}
