import { type MailboxInfo } from 'owa-client-types';
import copilotSettingsStore from '../store/store';
import type { ImplementedCopilotFeatures } from '../store/store';

export default function isOptInExperienceEnabledForScenario(
    scenario: ImplementedCopilotFeatures,
    mailboxInfo: MailboxInfo
): boolean {
    const store = copilotSettingsStore(mailboxInfo);

    if (!!store && store.isEnabled) {
        return store.featureInfo.get(scenario)?.optInEnabled ?? false;
    }

    return false;
}
