//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import type * as Types from 'owa-graph-schema';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TileBadge_FragmentFragment = {
    __typename?: 'M365ApplicationNumericBadge';
    count: number;
    countDescription: string;
};
export type LaunchTile_Fragment_M365HubApplication_Fragment = {
    __typename?: 'M365HubApplication';
    id: string;
    platformType?: Types.PlatformType | null;
    name: string;
    accessibleName?: string | null;
    brandingColor?: string | null;
    isInUse?: boolean | null;
    canLaunchOut?: boolean | null;
    enablePlatformCommands?: boolean | null;
    canBeUnacquired?: boolean | null;
    isPinnable: boolean;
    lockedIndex?: number | null;
    pinnedIndex?: number | null;
    allowedExternalDropContent?: Array<string> | null;
    icon:
        | {
              __typename: 'InternalIcon';
              name: any;
          }
        | {
              __typename: 'RemoteImage';
              src: string;
              isFullBleed?: boolean | null;
          };
    outlineIcon?:
        | {
              __typename: 'InternalIcon';
              name: any;
          }
        | {
              __typename: 'RemoteImage';
              src: string;
              isFullBleed?: boolean | null;
          }
        | null;
    badge?: {
        __typename: 'M365ApplicationNumericBadge';
        count: number;
        countDescription: string;
    } | null;
    selectedStateIconOverride?:
        | {
              __typename: 'InternalIcon';
              name: any;
          }
        | {
              __typename: 'RemoteImage';
              src: string;
              isFullBleed?: boolean | null;
          }
        | null;
    secondaryActions?: Array<{
        __typename?: 'AppSecondaryAction';
        id: string;
        text: string;
        eventType: string;
        eventData: string;
        icon?: {
            __typename?: 'InternalIcon';
            name: any;
        } | null;
    }> | null;
};
export type LaunchTile_Fragment_M365PlatformApplication_Fragment = {
    __typename?: 'M365PlatformApplication';
    id: string;
    platformType?: Types.PlatformType | null;
    name: string;
    accessibleName?: string | null;
    brandingColor?: string | null;
    isInUse?: boolean | null;
    canLaunchOut?: boolean | null;
    enablePlatformCommands?: boolean | null;
    canBeUnacquired?: boolean | null;
    isPinnable: boolean;
    lockedIndex?: number | null;
    pinnedIndex?: number | null;
    allowedExternalDropContent?: Array<string> | null;
    icon:
        | {
              __typename: 'InternalIcon';
              name: any;
          }
        | {
              __typename: 'RemoteImage';
              src: string;
              isFullBleed?: boolean | null;
          };
    outlineIcon?:
        | {
              __typename: 'InternalIcon';
              name: any;
          }
        | {
              __typename: 'RemoteImage';
              src: string;
              isFullBleed?: boolean | null;
          }
        | null;
    badge?: {
        __typename: 'M365ApplicationNumericBadge';
        count: number;
        countDescription: string;
    } | null;
    selectedStateIconOverride?:
        | {
              __typename: 'InternalIcon';
              name: any;
          }
        | {
              __typename: 'RemoteImage';
              src: string;
              isFullBleed?: boolean | null;
          }
        | null;
    secondaryActions?: Array<{
        __typename?: 'AppSecondaryAction';
        id: string;
        text: string;
        eventType: string;
        eventData: string;
        icon?: {
            __typename?: 'InternalIcon';
            name: any;
        } | null;
    }> | null;
};
export type LaunchTile_FragmentFragment =
    | LaunchTile_Fragment_M365HubApplication_Fragment
    | LaunchTile_Fragment_M365PlatformApplication_Fragment;
export type AppBarControlDataQueryVariables = Types.Exact<{
    [key: string]: never;
}>;
export type AppBarControlDataQuery = {
    __typename?: 'Query';
    m365Apps: {
        __typename?: 'M365ApplicationConnection';
        edges: Array<{
            __typename?: 'M365ApplicationEdge';
            node:
                | {
                      __typename?: 'M365HubApplication';
                      id: string;
                      name: string;
                      isInUse?: boolean | null;
                      brandingColor?: string | null;
                      lockedIndex?: number | null;
                      pinnedIndex?: number | null;
                      platformType?: Types.PlatformType | null;
                      accessibleName?: string | null;
                      canLaunchOut?: boolean | null;
                      enablePlatformCommands?: boolean | null;
                      canBeUnacquired?: boolean | null;
                      isPinnable: boolean;
                      allowedExternalDropContent?: Array<string> | null;
                      icon:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            };
                      outlineIcon?:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            }
                          | null;
                      badge?: {
                          __typename: 'M365ApplicationNumericBadge';
                          count: number;
                          countDescription: string;
                      } | null;
                      selectedStateIconOverride?:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            }
                          | null;
                      secondaryActions?: Array<{
                          __typename?: 'AppSecondaryAction';
                          id: string;
                          text: string;
                          eventType: string;
                          eventData: string;
                          icon?: {
                              __typename?: 'InternalIcon';
                              name: any;
                          } | null;
                      }> | null;
                  }
                | {
                      __typename?: 'M365PlatformApplication';
                      id: string;
                      name: string;
                      isInUse?: boolean | null;
                      brandingColor?: string | null;
                      lockedIndex?: number | null;
                      pinnedIndex?: number | null;
                      platformType?: Types.PlatformType | null;
                      accessibleName?: string | null;
                      canLaunchOut?: boolean | null;
                      enablePlatformCommands?: boolean | null;
                      canBeUnacquired?: boolean | null;
                      isPinnable: boolean;
                      allowedExternalDropContent?: Array<string> | null;
                      icon:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            };
                      outlineIcon?:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            }
                          | null;
                      badge?: {
                          __typename: 'M365ApplicationNumericBadge';
                          count: number;
                          countDescription: string;
                      } | null;
                      selectedStateIconOverride?:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            }
                          | null;
                      secondaryActions?: Array<{
                          __typename?: 'AppSecondaryAction';
                          id: string;
                          text: string;
                          eventType: string;
                          eventData: string;
                          icon?: {
                              __typename?: 'InternalIcon';
                              name: any;
                          } | null;
                      }> | null;
                  };
        }>;
        appAcquisitionLinks?: Array<{
            __typename?: 'AppAcquisitionLink';
            id: string;
            text: string;
            accessibleName?: string | null;
            icon?: {
                __typename?: 'InternalIcon';
                name: any;
            } | null;
        } | null> | null;
    };
};
export const TileBadge_FragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TileBadge_fragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'M365ApplicationBadge' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'M365ApplicationNumericBadge' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'countDescription' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TileBadge_FragmentFragment, unknown>;
export const LaunchTile_FragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LaunchTile_fragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'M365Application' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'platformType' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'accessibleName' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'InternalIcon' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'RemoteImage' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'src' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isFullBleed' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outlineIcon' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'InternalIcon' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'RemoteImage' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'src' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isFullBleed' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'badge' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: {
                                            kind: 'Name',
                                            value: 'M365ApplicationNumericBadge',
                                        },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'count' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'TileBadge_fragment' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectedStateIconOverride' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'InternalIcon' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'RemoteImage' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'src' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isFullBleed' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'brandingColor' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isInUse' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'canLaunchOut' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enablePlatformCommands' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'canBeUnacquired' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isPinnable' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lockedIndex' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'pinnedIndex' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondaryActions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'icon' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'eventData' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'allowedExternalDropContent' } },
                ],
            },
        },
        ...TileBadge_FragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<LaunchTile_FragmentFragment, unknown>;
export const AppBarControlDataDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'AppBarControlData' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'm365Apps' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isInUse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'brandingColor',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lockedIndex',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'pinnedIndex',
                                                            },
                                                        },
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'LaunchTile_fragment',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appAcquisitionLinks' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'text' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'accessibleName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'icon' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...LaunchTile_FragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AppBarControlDataQuery, AppBarControlDataQueryVariables>;
