import React, { useReducer } from 'react';
import { observer } from 'owa-mobx-react';
import { AppBar } from '@1js/office-start-appbar-control';
import { AccountSwitcher } from 'owa-account-switcher';
import { isFeatureEnabled, isAddinMultiAccountEnabled } from 'owa-feature-flags';
import { isCloudCacheScenario } from 'owa-left-rail-utils/lib/isCloudCacheScenario';
import { isEasiIdUser } from 'owa-cloud-cache-accounts-option';
import { FluentProvider } from '@fluentui/react-components';
import { AppStoreModal, AppStoreDetailsDialog } from 'owa-app-host-components';
import { getPalette } from 'owa-theme';
import { isCurrentCultureRightToLeft } from 'owa-localize';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { createFluentTheme } from '../utils/createFluentTheme';
import { RegisterForAccountStateChange } from 'owa-account-source-list';
import { bootstrapM365AcquisitionsCache } from 'owa-m365-acquisitions-cache';
import type { AccountSource } from 'owa-account-source-list-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { monarchAwareLeftRail, leftRail, multiAccountContainer, bleedThrough } from './AppBar.scss';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { trySetBposNavBarData } from 'owa-bpos-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { lazyInitializeAppBarPinnedAppsUserSetting } from 'owa-appbar-pinnedapps';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import className from 'owa-classnames';
import { isCopilotStoreReady } from 'owa-copilot-settings-store';
import { isMChatEnabled } from 'owa-copilot-settings-utils';
import { checkThatMailboxInfoIsForStartedAccount } from 'owa-mailbox-info-checks';
import { lazyWriteToAcquisitionsCacheFromIndexedDb } from 'owa-m365-acquisitions-database/lib/lazyFunctions';
import { writeAcquisitionQueries } from '../utils/writeAcquisitionQueries';
import { getAppBarTilesOrder } from 'owa-nova-cache/lib/data/getAppBarTilesIndices';
import { logUsage } from 'owa-analytics';
import { M365AppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { lazyGetM365Acquisition } from 'owa-m365-acquisitions/lib/lazyFunction';
import { getApplicationSettings } from 'owa-application-settings';

const FluentProviderStyle = { backgroundColor: 'transparent', height: '100%' };
const ocsBoostrappedAccounts = new Set<string>();

export default observer(function WrappedAppBar() {
    const isCloudCacheMultiAccount = isCloudCacheScenario() || isEasiIdUser();
    const mailboxInfo = isAddinMultiAccountEnabled() ? getModuleContextMailboxInfo() : undefined;
    const ocsMailboxInfo = mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    const copilotStoreReady = isCopilotStoreReady(ocsMailboxInfo);
    const mChatEnabled = isMChatEnabled(ocsMailboxInfo);
    const [loggedMailboxInfos, dispatch] = useReducer(reducer, []);

    /**
     * This effect serves two purposes:
     *  1. When switching accounts, re-render the app bar with the new account's apps,
     *  2. With mos-BizChatOCS, re-render the app bar with BizChat when the Copilot store is ready.
     * When mos-mos3AppService is disabled, we do not need to fire this, as multiaccount is off, and
     * the acquisitions layer is disabled, so only bootstrap apps are available to the user.
     */
    React.useEffect(() => {
        if (isFeatureEnabled('mos-mos3AppService')) {
            (async () => {
                if (mailboxInfo) {
                    checkThatMailboxInfoIsForStartedAccount('AppBarRefresh', mailboxInfo);
                }

                if (isFeatureEnabled('mos-BizChatOCS') && copilotStoreReady) {
                    const mailboxInfoIndexer = getIndexerValueForMailboxInfo(ocsMailboxInfo);
                    if (!ocsBoostrappedAccounts.has(mailboxInfoIndexer)) {
                        // refresh hardcoded apps once store is ready for BizChat license check
                        ocsBoostrappedAccounts.add(mailboxInfoIndexer);
                        await bootstrapM365AcquisitionsCache(mailboxInfo);
                    }
                }

                // on account switch, redraw the app bar with the cached acquisitions
                await writeAcquisitionQueries(mailboxInfo);
            })();
        }
    }, [mailboxInfo?.userIdentity, copilotStoreReady, mChatEnabled]);

    React.useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout> | undefined;
        const userIdentity = mailboxInfo?.userIdentity || '';

        if (!loggedMailboxInfos.includes(userIdentity)) {
            timeoutId = setTimeout(async () => {
                const { lockedApps, pinnedApps, flyoutApps } = getAppBarTilesOrder();
                const pinnedAppsList = [...lockedApps, ...pinnedApps, ...flyoutApps].map(
                    app => app.id
                );
                const appsList = await Promise.all(
                    pinnedAppsList.map(async (appId, index) => {
                        const appIndex = index < lockedApps.length + pinnedApps.length ? index : -1;
                        if (!Object.values(M365AppId).includes(appId)) {
                            if (mailboxInfo) {
                                const { acquisition } =
                                    await lazyGetM365Acquisition.importAndExecute(
                                        appId,
                                        'cache-only',
                                        mailboxInfo
                                    );
                                const scope = acquisition?.titleDefinition.scope;
                                return {
                                    appIndex,
                                    appId: scope === 'Public' ? appId : scope ?? 'unknown',
                                };
                            } else {
                                return { appIndex, appId: 'unknown' };
                            }
                        } else {
                            return { appIndex, appId };
                        }
                    })
                );

                logUsage('AppBar_impressions', {
                    pinnedApps: JSON.stringify(appsList),
                });

                dispatch({ type: 'ADD_LOGGED_MAILBOX_INFO', payload: userIdentity });
            }, 1 * 60 * 1000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [mailboxInfo?.userIdentity]);

    React.useEffect(() => {
        if (isAddinMultiAccountEnabled()) {
            // After initial render, initialze app bar data for each connected account
            // 1. Initialize pinned apps settings
            // 2. Populate bpos store with data from user settings
            // 3. Prime acquisitions cache with static data
            return RegisterForAccountStateChange(
                (accountSource: AccountSource) => {
                    const accountMailboxInfo = accountSource.mailboxInfo;
                    lazyInitializeAppBarPinnedAppsUserSetting.importAndExecute(accountMailboxInfo);
                    // The global settings account's static acquisitions data is already in cache. It's done pre-render
                    if (
                        getIndexerValueForMailboxInfo(accountMailboxInfo) !==
                        getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo())
                    ) {
                        const userSettings = getAccountScopeUserSettings(accountMailboxInfo);
                        if (userSettings?.NavBarData) {
                            trySetBposNavBarData(userSettings.NavBarData, accountMailboxInfo);
                        }

                        if (isFeatureEnabled('mos-ribbonSurfaceAppCache')) {
                            // Prime acquisitions cache with  data from indexedDB
                            lazyWriteToAcquisitionsCacheFromIndexedDb
                                .importAndExecute(accountMailboxInfo)
                                .then(acquisitionsFromIndexedDb => {
                                    if (acquisitionsFromIndexedDb.length === 0) {
                                        // If no data was written from indexedDB, we should boostrap with static apps
                                        bootstrapM365AcquisitionsCache(accountMailboxInfo);
                                    }
                                });
                        } else {
                            // Prime acquisitions cache with static data for each connected account
                            bootstrapM365AcquisitionsCache(accountMailboxInfo);
                        }
                    }
                },
                (_accountSource: AccountSource) => {} // no-op the pre-account removed callback
            );
        }

        return undefined;
    }, []);

    const useMonarchAwareStyles = getApplicationSettings('PlatformType').isMonarch;
    const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);

    return (
        <>
            <AppStoreModal mailboxInfo={mailboxInfo} />
            <AppStoreDetailsDialog mailboxInfo={mailboxInfo} />
            <div
                className={className(
                    useMonarchAwareStyles ? monarchAwareLeftRail : leftRail,
                    isBleedThroughEnabled && bleedThrough
                )}
                id="LeftRail"
            >
                {isCloudCacheMultiAccount && (
                    <div className={multiAccountContainer}>{<AccountSwitcher />}</div>
                )}
                <FluentProvider
                    theme={createFluentTheme(getPalette())}
                    style={FluentProviderStyle}
                    dir={isCurrentCultureRightToLeft() ? 'rtl' : 'ltr'}
                >
                    <AppBar />
                </FluentProvider>
            </div>
        </>
    );
}, 'WrappedAppBar');

function reducer(
    state: string[],
    action: {
        type: string;
        payload: string;
    }
) {
    switch (action.type) {
        case 'ADD_LOGGED_MAILBOX_INFO':
            return [...state, action.payload];
        default:
            return state;
    }
}
