import type BundlingStore from './schema/BundlingStore';
import { createStore } from 'owa-satcheljs';
const initialBundlingStore: BundlingStore = {
    loadedImports: new Map<string, boolean>(),
};

export const getStore = createStore('bundlingStore', initialBundlingStore);

export default {
    getStore,
};
