import type { SharePointResourceTokenPrefetchState } from '../Schema/SharePointResourceTokenPrefetchState';
import { createStore } from 'owa-satcheljs';

export interface SharePointResourceTokenPrefetchStateStore {
    tokenStatusPerResource: Map<string, SharePointResourceTokenPrefetchState>;
}

export const getInitialState = (): SharePointResourceTokenPrefetchStateStore => {
    return {
        tokenStatusPerResource: new Map<string, SharePointResourceTokenPrefetchState>(),
    };
};

// tokenStatusPerResource is a map from keys (sharepoint resource URL) to values (SharePointResourceTokenPrefetchState)
export const getSharePointResourceTokenPrefetchStateStore = createStore(
    'sharePointResourceTokenPrefetchStateStore',
    getInitialState()
);
