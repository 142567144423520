import { mutatorAction } from 'satcheljs';
import type DisplayDensityMode from 'owa-service/lib/contract/DisplayDensityMode';
import getDensityOptionState from '../store/store';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const displayDensityOptionSelected = mutatorAction(
    'DISPLAY_DENSITY_OPTION_SELECTED',
    function displayDensityOptionSelected(newDensityModeSelected: DisplayDensityMode) {
        const densityOption = getDensityOptionState();
        densityOption.densityModeSelected = newDensityModeSelected;
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const messageListLineOptionSelected = mutatorAction(
    'MESSAGE_LIST_LINE_OPTION_SELECTED',
    function messageListLineOptionSelected(showSingleLineView: boolean) {
        const densityOption = getDensityOptionState();
        densityOption.useSingleLineMessageStyle = showSingleLineView;
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const discardDensityOption = mutatorAction(
    'DISCARD_DENSITY_OPTION',
    function discardDensityOption() {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (27,9): Type 'null' is not assignable to type 'DisplayDensityMode'.
        // @ts-expect-error
        getDensityOptionState().densityModeSelected = null;
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (31,9): Type 'null' is not assignable to type 'boolean'.
        // @ts-expect-error
        getDensityOptionState().useSingleLineMessageStyle = null;
    }
);
