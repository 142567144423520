export enum NotificationType {
    HierarchyNotification = 'HierarchyNotification',
    ReminderNotification = 'ReminderNotification',
    RowNotification = 'RowNotification',
    CalendarItemNotification = 'CalendarItemNotification',
    InstantMessageNotification = 'InstantMessageNotification',
    NewMailNotification = 'NewMailNotification',
    OwaUserConfigurationNotification = 'OwaUserConfigurationNotification',
    UnseenEmailNotification = 'UnseenEmailNotification',
    CalendarFoldersNotification = 'CalendarFoldersNotification',
    SearchNotification = 'SearchNotification',
    UnseenItemNotification = 'UnseenItemNotification',
    GroupAssociationNotification = 'GroupAssociationNotification',
    PeopleIKnowNotification = 'PeopleIKnowNotification',
    InstantSearchNotification = 'InstantSearchNotification',
    BackgroundSyncNotification = 'BackgroundSyncNotification',
    GroupCreateNotification = 'GroupCreateNotification',
    CreateAttachmentNotification = 'CreateAttachmentNotification',
    AttachmentOperationCorrelationIdNotification = 'AttachmentOperationCorrelationIdNotification',
    SaveToCloudNotificationPayload = 'SaveToCloudNotificationPayload',
    PeopleCentricConversationNotification = 'PeopleCentricConversationNotification',
    SocialActivityNotification = 'SocialActivityNotification',
    GetUserAvailabilityNotification = 'GetUserAvailabilityNotification',
    ConvertClassicAttachmentToLinkNotificationPayload = 'ConvertClassicAttachmentToLinkNotificationPayload',
    ConvertLocalToRefAttachmentNotificationPayload = 'ConvertLocalToRefAttachmentNotificationPayload',
    ConvertRefToLocalAttachmentNotificationPayload = 'ConvertRefToLocalAttachmentNotificationPayload',
    UnseenCountNotification = 'UnseenCountNotification',
    OwaVersionChangeNotification = 'OwaVersionChangeNotification',
    SuiteNotification = 'SuiteNotification',
    UploadFileToCloudNotificationPayload = 'UploadFileToCloudNotificationPayload',
    HashtagsNotification = 'HashtagsNotification',
    UnreadCountNotification = 'UnreadCountNotification',
    BulkActionNotification = 'BulkActionNotification',
    CategoriesNotification = 'CategoriesNotification',
    ReactionNotification = 'ReactionNotification',
    RebootNotification = 'RebootNotification',
    ActivityFeedNotification = 'ActivityFeedNotification',
    GroupFolderHierarchyNotification = 'GroupFolderHierarchyNotification',
    FlexibleWorkingHoursNotification = 'FlexibleWorkingHoursNotification',
    RemoteSyncNotification = 'RemoteSyncNotification',
    IC3RegistrarNotification = 'IC3RegistrarNotification',
    MessageItemNotification = 'MessageItemNotification',
    CalendarAINotification = 'CalendarAINotification',
    RoundTrip = 'RoundTrip',
    PGALSyncStateNotification = 'PGALSyncStateNotification',
    ConversationChanges = 'ConversationChanges',
    PersonasChangeNotification = 'PersonasChangeNotification',
    Reminder = 'Reminder',
    ContactsChangeNotification = 'ContactsChangeNotification',
    SettingsChangeNotification = 'SettingsChangeNotification',
}
