import type { RibbonControlGroupDefinitionMLR, RibbonControlGroupDefinitionSLR } from 'owa-acui';
import { Multiline, SingleLine, constructKeytip } from 'owa-command-ribbon';
import { type RibbonTabId, type RibbonGroupId } from 'owa-ribbon-ids';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { bottomBarOverflowMenuLabel } from 'owa-locstrings/lib/strings/commandRibbon.locstring.json';
import { basicTextGroupOverflowMenuLabel } from './overflowMenuLabels.locstring.json';
import { loc } from 'owa-localize/lib/loc';
import { type MailRibbonTabId, type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';

/**
 * Helper function to be used for creating ribbon groups in MLR.
 * @param tabId is the id of the current tab
 * @param groupId is the RibbonGroupId for this group. Must be unique, @see {RibbonGroupId}.
 * @param editorId is the id of the current editorViewState
 * @returns RibbonControlGroupDefinitionMLR object. Searchable in 1JS Repo, in `Ribbon.Props.ts`
 */
export const createRibbonGroupMLR = (
    tabId: RibbonTabId,
    groupId: RibbonGroupId,
    editorId?: string
): RibbonControlGroupDefinitionMLR => {
    return {
        type: Multiline,
        id: getComposeRibbonId(groupId, editorId).toString(),
        title: '',
        controls: [],
        layouts: [],
        commonGroupFlyout: undefined,
        keytipProps: constructKeytip([tabId], tabId),
    };
};

/**
 * Helper function to be used for creating ribbon groups in SLR.
 * Importantly:
 *   - `hasOwnOverflow` should be false and
 *   - `shouldAllowAllControlsInOverflow` should be true and
 *   - keytipProps matching the tabId needs to be provided
 * Otherwise, Customizer gets into mismatched states with the Ribbon.
 * @param tabId is the id of the current tab
 * @param groupId is the RibbonGroupId for this group. Must be unique, @see {RibbonGroupId}.
 * @param editorId is the id of the current editorViewState
 * @returns RibbonControlGroupDefinitionSLR object. Searchable in 1JS Repo, in `Ribbon.Props.ts`
 */
export const createRibbonGroupSLR = (
    tabId: RibbonTabId,
    groupId: RibbonGroupId,
    editorId?: string
): RibbonControlGroupDefinitionSLR => {
    return {
        type: SingleLine,
        id: getComposeRibbonId(groupId, editorId).toString(),
        title: '',
        overflowMenuLauncherLabel: getAriaLabelForOverflowMenu(tabId, groupId),
        hasOwnOverflow: false,
        shouldAllowAllControlsInOverflow: true,
        controls: [],
        keytipProps: constructKeytip([tabId], tabId),
    };
};

function getAriaLabelForOverflowMenu(tabId: RibbonTabId, groupId: RibbonGroupId): string {
    if (tabId === 10 && groupId === 157) {
        return loc(basicTextGroupOverflowMenuLabel);
    }

    return loc(bottomBarOverflowMenuLabel);
}
