import type MailboxInfo from './MailboxInfo';

/**
 * Specialization of the MailboxInfo type for group mailboxes
 */
export interface GroupMailboxInfo extends MailboxInfo {
    readonly type: 'GroupMailbox';
}

/**
 * Predicate function to check if the mailboxInfo is a GroupMailboxInfo
 */
export function isGroupMailboxInfo(mailboxInfo: MailboxInfo): mailboxInfo is GroupMailboxInfo {
    return mailboxInfo.type === 'GroupMailbox';
}
