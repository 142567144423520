import type { FavoriteTreeData } from './schema/FavoritesStore';
import { FolderTreeLoadStateEnum } from 'owa-folders';
import type { AccountKey } from 'owa-client-types';

/**
 * Gets the default favorite tree data
 */
export function getDefaultFavoriteTreeData(accountKey: AccountKey): FavoriteTreeData {
    return {
        accountKey,
        orderedOutlookFavoritesIds: [],
        loadingState: FolderTreeLoadStateEnum.Uninitialized,
    };
}
