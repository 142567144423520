import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isAnalyzeContentDisabledForPrivacy } from 'owa-privacy-utils/lib/selectors/isAnalyzeContentDisabledForPrivacy';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { isMailboxSharedOrDelegate } from 'owa-account-shared-mailbox-utils';

export function shouldDisableCopilot(mailboxInfo: MailboxInfo): boolean {
    // Respect the privacy settings based on the global(primary) mailbox
    if (isFeatureEnabled('mon-copilot-respectG1') && isAnalyzeContentDisabledForPrivacy()) {
        return true;
    }

    return shouldDisableCopilotBasedOnMailbox(mailboxInfo);
}

export function shouldDisableCopilotBasedOnMailbox(mailboxInfo: MailboxInfo): boolean {
    return (
        mailboxInfo.type === 'ArchiveMailbox' ||
        isMailboxSharedOrDelegate(mailboxInfo) || // Disable copilot for shared and delegate mailboxes
        !isServiceRequestSupportedForMailbox(mailboxInfo)
    );
}
