import type MailboxInfo from './MailboxInfo';

/**
 * Specialization of the MailboxInfo type for shared mailboxes, these mailboxes are used
 * for accessing shared/delegate folders, calendars, and mailboxes.
 */
export interface SharedMailboxInfo extends MailboxInfo {
    readonly type: 'SharedMailbox';
}

/**
 * Predicate function to check if the mailboxInfo is a SharedMailboxInfo
 */
export function isSharedMailboxInfo(mailboxInfo: MailboxInfo): mailboxInfo is SharedMailboxInfo {
    return mailboxInfo.type === 'SharedMailbox';
}
