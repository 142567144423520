import { LazyModule, LazyAction } from 'owa-bundling';
import { GovernPriority } from 'owa-client-types/lib/GovernPriority';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "DiagnosticsDataCollection" */ './lazyIndex'),
    { name: 'DiagnosticsDataCollection', govern: GovernPriority.Default }
);

export const lazyRegisterDataCollectorIfNotRegistered = new LazyAction(
    lazyModule,
    m => m.RegisterDataCollectorIfNotRegistered
);

export type { DataCollectorSchema as DataCollector } from './schema/DataCollectorSchema';

export const lazyRegisterRecoveryAction = new LazyAction(lazyModule, m => m.RegisterRecoveryAction);

export const lazyRunRecoveryAction = new LazyAction(lazyModule, m => m.runRecoveryAction);

export type { RecoveryActionSchema as RecoveryAction } from './schema/RecoveryActionSchema';

export { default as getAvailableRecoveryActions } from './getAvailableRecoveryActions';
