import type FavoriteNodeViewState from './schema/FavoriteNodeViewState';
import type FavoritesViewState from './schema/FavoritesViewState';
import type MailFavoritesStore from './schema/MailFavoritesStore';
import { createStore } from 'owa-satcheljs';

const defaultMailFavoritesStore: MailFavoritesStore = {
    mailFavoritesViewState: new Map<string, FavoritesViewState>([]),
    favoritePersonasRootFolderId: undefined,
    isFavoritingInProgress: new Map<string, boolean>([]),
    favoriteNodeViewStates: new Map<string, FavoriteNodeViewState>([]),
};

export const getStore = createStore<MailFavoritesStore>('mailFavorites', defaultMailFavoritesStore);
