import { action } from 'satcheljs';
import type { FolderForestTreeType } from 'owa-graph-schema';
import type ActionSource from '../store/schema/ActionSource';

export default action(
    'onSelectFolder',
    (folderId: string, treeType: FolderForestTreeType, actionSource: ActionSource) => ({
        folderId,
        treeType,
        actionSource,
    })
);
