import { logUsage } from 'owa-analytics';
import type { ConditionalFormattingCondition } from 'owa-conditional-formatting';
import { ConditionalFormattingConditionType } from 'owa-conditional-formatting';

export default function isConditionalFormattingConditionSatisfied(
    condition: ConditionalFormattingCondition,
    messageSubject: string,
    messageToMe: boolean | undefined | null,
    messageCcMe: boolean | undefined | null,
    senderEmailAddress: string
): boolean {
    switch (condition.type) {
        case ConditionalFormattingConditionType.From:
            return (
                !!senderEmailAddress &&
                doesAnySenderMatchWithMessageSender(senderEmailAddress, condition.value as string[])
            );
        case ConditionalFormattingConditionType.OnTheToOrCcLine:
            return !!messageToMe || !!messageCcMe;
        case ConditionalFormattingConditionType.OnTheToLine:
            return !!messageToMe;
        case ConditionalFormattingConditionType.OnTheCcLine:
            return !!messageCcMe;
        case ConditionalFormattingConditionType.NotOnTheToLine:
            if (messageToMe === undefined) {
                return false;
            }
            return !messageToMe;
        case ConditionalFormattingConditionType.SubjectIncludes:
            return doesTextIncludeAnyKeywords(messageSubject, condition.value as string[]);
        case ConditionalFormattingConditionType.SenderAddressIncludes:
            return doesTextIncludeAnyKeywords(senderEmailAddress, condition.value as string[]);
        default:
            logUsage('TnS_ConditionalFormatting_InvalidConditionType', {
                conditionType: condition.type,
            });
            return false;
    }
}

function doesTextIncludeAnyKeywords(text: string, keywords: string[]): boolean {
    for (const keyword of keywords) {
        if (text.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())) {
            return true;
        }
    }

    return false;
}

// We will need to update senders type here to a type that Jorge/I decide on for this value. For now I just assumed a string array of emailAddresses to check.
function doesAnySenderMatchWithMessageSender(
    senderEmailAddress: string,
    senders: string[]
): boolean {
    for (const sender of senders) {
        // Added logging for null or undefined sender values to help debug a component error
        if (sender === null || sender === undefined) {
            /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops -- (https://aka.ms/OWALintWiki)
             * Baseline, this function can't be used inside a loop, please move it outside
             *	> Function 'logUsage' matches forbidden pattern (/logUsage/) and should not be used inside loops */
            logUsage('TnS_ConditionalFormatting_Error', {
                message:
                    'sender is null or undefined when evaluating a conditional formatting rule.',
            });
            continue;
        } else if (senderEmailAddress.toLocaleLowerCase() === sender.toLocaleLowerCase()) {
            return true;
        }
    }

    return false;
}
