import { LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CopilotCommandCenter" */ './lazyIndex'),
    { name: 'CopilotCommandCenter' }
);

export const CopilotCommandCenter = createLazyComponent(lazyModule, m => m.CopilotCommandCenter);
export const CopilotPrioritizeFreContent = createLazyComponent(
    lazyModule,
    m => m.CopilotPrioritizeFreContent
);
export { OptInResult } from './store/types/CopilotCommandCenterPrioritizeData';
export const CopilotCommandCenterChat = createLazyComponent(
    lazyModule,
    m => m.CopilotCommandCenterChat
);
export const CopilotCommandCenterSettings = createLazyComponent(
    lazyModule,
    m => m.CopilotCommandCenterSettings
);
export const CopilotCommandCenterGenericMenuItem = createLazyComponent(
    lazyModule,
    m => m.CopilotCommandCenterGenericMenuItem
);
