export enum GroupAccessType {
    Private,
    Public,
}

export interface Classification {
    Name?: string;
    Description?: string;
}

export interface NamingPolicySettings {
    DisplayNameDecorationPrefix?: string;
    DisplayNameDecorationSuffix?: string;
    AliasDecorationPrefix?: string;
    AliasDecorationSuffix?: string;
}

export interface UnifiedGroupsSettingsState {
    supportedClassifications: Classification[];
    namingPolicySettings: NamingPolicySettings;
    groupsGuidelinesLink: string;
    orgAllowAddGuests?: boolean;
    groupCreationEnabled?: boolean;
    isSensitivityLabelsEnabled?: boolean;
    IsGroupFoldersAndRulesEnabled?: boolean;
    BlockMoveMessagesForGroupFolders?: boolean;
    IsGroupMemberAllowedToEditContent?: boolean;
    defaultClassification: string;
    defaultGroupAccessType?: GroupAccessType;
}

export interface UnifiedGroupsSettingsByMailboxInfo {
    // AccountKey is the index which comes from getAccountKeyForMailboxInfo(mailboxInfo)
    unifiedGroupsSettingsByMailboxInfo: Map<string, UnifiedGroupsSettingsState>;
}
