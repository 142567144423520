import { createLazyComponent, LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Storage" */ './lazyIndex'), {
    name: 'Storage',
});

export const LazyLeftNavStorageBar = createLazyComponent(lazyModule, m => m.LeftNavStorageBar);

export const StorageUpsellBanner = createLazyComponent(lazyModule, m => m.StorageUpsellBanner);

export const lazyOpenStorageWarning = new LazyImport(lazyModule, m => m.openStorageWarning);

export const lazyNavigateToStoragePage = new LazyImport(lazyModule, m => m.navigateToStoragePage);

export const lazyShowFreeUpMSQSpaceTeachingMoment = new LazyImport(
    lazyModule,
    m => m.showFreeUpMSQSpaceTeachingMoment
);
