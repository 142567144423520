import { isFeatureEnabled } from 'owa-feature-flags';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { isBootFeatureEnabled } from 'owa-metatags';

export function isMonarchAccountManagementEnabled(): boolean {
    return isHostAppFeatureEnabled('acctmonaccounts');
}

export function isOWAAccountManagementEnabled() {
    return isFeatureEnabled('settings-showAccountSettingsInOWA');
}

/**
 * To avoid having to fork the code between Monarch and OWA support is provided for initializing
 * the account-source-list-store when not in Monarch. This method checks the needed flights
 * returns true if the the non-Monarch source list support is enabled.
 * @returns true if the the non-Monarch source list support is enabled.
 */
export function isNonMonarchSourceListSupportEnabled() {
    return !isHostAppFeatureEnabled('acctmonaccounts');
}

/**
 * The source-list API includes the store in which account information is stored, and the owa-account-source-list
 * pacakge which provides support for getting this data from the source list
 * @returns true if the source-list api is enabled
 */
export function isSourceListSupportEnabled(): boolean {
    return isMonarchAccountManagementEnabled() || isNonMonarchSourceListSupportEnabled();
}

/**
 * To making the coding for multiple accounts easier this check is used to determine if
 * the API for multiple accounts should be enabled. This will return true when we are either
 * in Monarch with full multiple account support or if we are not in monarch and the 'accountsapi'
 * feature is enabled.
 * @returns true if the API for multiple accounts should be enabled
 */
export function isMonarchMultipleAccountsEnabled(): boolean {
    return (
        isMonarchAccountManagementEnabled() ||
        (!isHostAppFeatureEnabled('acctmonaccounts') && isBootFeatureEnabled('accountsapi'))
    );
}

/**
 * Returns true if the account source list and related account infra supports handling PST file accounts
 */
export function isPstFileAccountInfraEnabled(): boolean {
    return isFeatureEnabled('acct-pstFileSupport');
}

/**
 * Returns true if the account infra supports handling PST file accounts and Mail message mutations
 * are enabled.
 */
export function isPstFileMutationEnabled(): boolean {
    return isPstFileAccountInfraEnabled() && isFeatureEnabled('acct-pstFileMutation');
}
