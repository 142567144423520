import { addClass } from 'owa-griffel-utils';
import { getMarkJS, SPAN_ELEMENT_NAME, UNMARK_ELEMENT_LABEL } from './constants';
import createMarkId from './createMarkId';

export type EachElementCallback = (
    keyword: string,
    element: HTMLElement,
    instance?: number
) => void;
export type Accuracy = 'partially' | 'prefix' | 'exactly';

// used to bypass loading markjs if no
// search terms are ever passed in
let hasEverMarked = false;

export default async function markElements(
    element: HTMLElement,
    keywords: string[],
    eachCallback: EachElementCallback,
    useRegExp: boolean,
    accuracy: Accuracy,
    doneCallback?: (count: number) => void
) {
    if (keywords.length || hasEverMarked) {
        hasEverMarked = true;
        const MarkJS = await getMarkJS();
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (26,36): Argument of type 'typeof Mark' is not assignable to parameter of type 'Mark'.
        // @ts-expect-error
        const marker = MarkJS.call(MarkJS, element);

        for (const keyword of keywords) {
            let count = 0;

            if (accuracy == 'prefix') {
                const options = {
                    element: SPAN_ELEMENT_NAME,
                    className: createMarkId(),
                    // Each element belonging to the same keyword should have the same identifier
                    // This is needed for unmarking user highlights on keywords across elements (VSO20847)
                    acrossElements: true,
                    each: (matchedElement: HTMLElement) => {
                        if (!isEmptyElement(matchedElement)) {
                            eachCallback(keyword, matchedElement, count);
                            count++;
                        }
                    },
                    done: () => {
                        doneCallback?.(count);
                    },
                };
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2339 (48,24): Property 'markRegExp' does not exist on type 'void'.
                // @ts-expect-error
                marker.markRegExp(new RegExp(`(\\b)(${keyword})`, 'gmi'), options);
            } else {
                const options = {
                    element: SPAN_ELEMENT_NAME,
                    className: createMarkId(),
                    // Each element belonging to the same keyword should have the same identifier
                    // This is needed for unmarking user highlights on keywords across elements (VSO20847)
                    acrossElements: true,
                    separateWordSearch: false,
                    accuracy,
                    each: (matchedElement: HTMLElement) => {
                        if (!isEmptyElement(matchedElement)) {
                            eachCallback(keyword, matchedElement, count);
                            count++;
                        }
                    },
                    done: () => {
                        doneCallback?.(count);
                    },
                };

                if (useRegExp) {
                    let str = '';
                    for (let i = 0; i < keyword.length; i++) {
                        if (isStartingWithSpecialChar(keyword[i])) {
                            str += '\\';
                        }
                        str += keyword[i];
                    }
                    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS2339 (77,24): Property 'markRegExp' does not exist on type 'void'.
                    // @ts-expect-error
                    marker.markRegExp(new RegExp(str), options);
                } else {
                    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS2339 (82,24): Property 'mark' does not exist on type 'void'.
                    // @ts-expect-error
                    marker.mark(keyword, options);
                }
            }
        }

        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2339 (90,16): Property 'unmark' does not exist on type 'void'.
        // @ts-expect-error
        marker.unmark({ className: UNMARK_ELEMENT_LABEL });
    }
}

function isEmptyElement(element: HTMLElement): boolean {
    const content = element.innerHTML.replace(/&nbsp;/gi, '').trim();
    if (!content) {
        // Elements whose content is blank (i.e. breaks, empty table elements,
        // or spans whose content just include whitespace) should not be highlighted
        // Add class name denoting should be removed later
        addClass(element, UNMARK_ELEMENT_LABEL);
        return true;
    }

    return false;
}

function isStartingWithSpecialChar(regex: string): boolean {
    const regex_char = regex[0];
    if (
        regex_char == '+' ||
        regex_char == '\\' ||
        regex_char == '*' ||
        regex_char == '.' ||
        regex_char == '[' ||
        regex_char == ']' ||
        regex_char == '-' ||
        regex_char == '(' ||
        regex_char == ')' ||
        regex_char == '{' ||
        regex_char == '}' ||
        regex_char == '?' ||
        regex_char == '^' ||
        regex_char == '$'
    ) {
        return true;
    }
    return false;
}
