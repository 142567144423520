import { LazyModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "LeftNavLazyActions" */ './lazyIndex'),
    { name: 'LeftNavLazyActions' }
);

export const lazyLoadGroupsList = new LazyAction(lazyModule, m => m.loadGroupsList);

export const lazyUnsubscribeFromGroupUnreadNotifications = new LazyAction(
    lazyModule,
    m => m.unsubscribeFromGroupUnreadNotifications
);
