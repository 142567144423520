import { isFeatureEnabled } from 'owa-feature-flags';
import favoritesStore from '../../store/store';
import {
    type FavoritesBitFlagsMasks,
    getIsBitEnabled,
} from '../../actions/helpers/favoritesBitFlagsActions';
import { shouldHideFavoritesList } from '../../selectors/shouldHideFavoritesList';

export const setShowPinFavoritesTeachingCalloutHelper = () => {
    if (
        isFeatureEnabled('fp-pin-favorites') &&
        !getIsBitEnabled(64) &&
        !shouldHideFavoritesList()
    ) {
        favoritesStore.shouldShowPinFavoritesTC = true;
    }
};
