import store from '../store/Store';
import type { ClientItemId } from 'owa-client-ids';
import { mutatorAction, mutator } from 'satcheljs';
import { onSelectFolderComplete } from 'owa-mail-shared-actions/lib/onSelectFolderComplete';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updatePrimaryReadingPaneTabId = mutatorAction(
    'updatePrimaryReadingPaneTabId',
    function (id: ClientItemId | null) {
        store.primaryReadingPaneTabId = id;
    }
);

mutator(onSelectFolderComplete, actionMessage => {
    if (!actionMessage.shouldFirstRowBeSelectedOnLoad) {
        store.primaryReadingPaneTabId = null;
    }
});
