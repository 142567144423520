import { SearchTab } from 'owa-search-types/lib/types/SearchTab';
import { lazyGetActiveSearchTab, isTabbedSearchEnabled } from 'owa-tabbed-search';
import type SearchScenarioId from 'owa-search-store/lib/store/schema/SearchScenarioId';
import type { Refiner } from 'owa-search-types';
import getSearchRefinersState from './getSearchRefinersState';

export default function getSuggestedRefiners(scenarioId: SearchScenarioId): Refiner[] {
    let currentTab: SearchTab | undefined = SearchTab.Mail;
    if (isTabbedSearchEnabled(false /*isFromQfScopeTabs*/)) {
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * BASELINE. Do not copy and paste!
         *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
        const getActiveSearchTab = lazyGetActiveSearchTab.tryImportForRender();
        if (getActiveSearchTab) {
            currentTab = getActiveSearchTab();
        }
    }

    // Return only those suggested refiners which pertain to the current tab
    if (currentTab !== undefined) {
        return getSearchRefinersState(scenarioId).suggestedRefiners.filter(
            refiner => refiner.applicableTabs.indexOf(currentTab as SearchTab) !== -1
        );
    }
    return [];
}
