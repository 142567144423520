import type {
    CalendarsUnionType,
    LinkedCalendarEntry,
    LinkedCalendarEntryV2,
    LocalCacheForRemoteCalendarEntry,
} from 'owa-graph-schema';

export function isSupportingRemoteCategories(
    calendar: CalendarsUnionType
): calendar is LocalCacheForRemoteCalendarEntry | LinkedCalendarEntryV2 | LinkedCalendarEntry {
    return (
        calendar.__typename === 'LocalCacheForRemoteCalendarEntry' ||
        calendar.__typename === 'LinkedCalendarEntryV2' ||
        calendar.__typename === 'LinkedCalendarEntry'
    );
}
