import type SigsInfo from '../store/schema/SigsInfo';
import type SxSExtendedViewState from '../store/schema/SxSExtendedViewState';
import type SxSReadingPaneState from '../store/schema/SxSReadingPaneState';
import type { SxSCustomization } from '../store/schema/SxSStore';
import { action } from 'satcheljs';

export const onSxSReadingPaneChange = action(
    'onSxSReadingPaneChange',
    (readingPaneState: SxSReadingPaneState | null, sxsId: string | null) => ({
        readingPaneState,
        sxsId,
    })
);

export const onBeforeSxSReadingPaneChange = action(
    'onBeforeSxSReadingPaneChange',
    (id: string) => ({
        id,
    })
);

export const showSxS = action(
    'showSxS',
    (
        customization: SxSCustomization | null,
        extendedViewState: SxSExtendedViewState,
        sxsId: string | null,
        sigsInfo?: SigsInfo
    ) => ({
        customization,
        extendedViewState,
        sxsId,
        sigsInfo,
    })
);

export const closeSxS = action('closeSxS');

export const onComposeStateChange = action(
    'onComposeStateChange',
    (composeId: string | null, sxsId: string | null) => ({
        composeId,
        sxsId,
    })
);

export const onCustomizationChange = action(
    'onCustomizationChange',
    (customization: SxSCustomization | null, sxsId: string | null) => ({
        customization,
        sxsId,
    })
);
