import { createStore } from 'owa-satcheljs';
interface SelectedCalendarsStore {
    selectedCalendars: Map<string, string[]>;
}
const selectedCalendarsStoreData: SelectedCalendarsStore = {
    selectedCalendars: new Map<string, string[]>(),
};

export const getSelectedCalendarsStore = createStore<SelectedCalendarsStore>(
    'selectedCalendarsStore',
    selectedCalendarsStoreData
);
