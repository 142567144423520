import React from 'react';
import { leftColumn, leftColumnBleedThrough } from './MailModule.scss';
import classnames from 'owa-classnames';
import { getShouldShowStorageNotification } from '../utils/getShouldShowStorageNotification';
import { observer } from 'owa-mobx-react';
import { getCurrentCulture } from 'owa-localize';
import { FolderPane } from 'owa-mail-folder-forest-view';
import { LeftnavStorageNotification } from 'owa-mail-leftnav-storage-notification';
import { LeftnavUpsell } from 'owa-mail-leftnavupsell';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getNativeadsUpsellEffectStore } from 'owa-nativeadsupselleffect-store';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { lazyGetStorageStore } from 'owa-storage-store';
import { LazyLeftNavStorageBar } from 'owa-storage-quota';
import { IsChildConsumerUser, IsShadowMailboxUser } from 'owa-mail-ads-shared';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';

export default observer(function FolderPaneContainer() {
    const mailboxInfo = getModuleContextMailboxInfo();
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    const getStorageStore = lazyGetStorageStore.tryImportForRender();
    const usagePercentage = getStorageStore?.(mailboxInfo).emailStorage.usagePercentage ?? 0;
    const isNewStorageUIEnabled: boolean = isConsumer(undefined, mailboxInfo);

    // Value deciding whether to show the storage notification
    const showStorageNotification: boolean =
        !IsShadowMailboxUser(mailboxInfo) &&
        getShouldShowStorageNotification(getStorageStore?.(mailboxInfo), mailboxInfo);

    const showLeftNavPremiumUpsell =
        isConsumer(undefined, mailboxInfo) &&
        !IsShadowMailboxUser(mailboxInfo) &&
        !IsChildConsumerUser(mailboxInfo) &&
        getCurrentCulture().indexOf('jp') === -1 &&
        !showStorageNotification &&
        !getNativeadsUpsellEffectStore().isEffective;

    const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);
    const customClassNames = classnames(
        leftColumn,
        isBleedThroughEnabled && leftColumnBleedThrough
    );

    return (
        <div className={customClassNames}>
            <FolderPane />
            {showStorageNotification && !isNewStorageUIEnabled && (
                <LeftnavStorageNotification
                    mailboxInfo={mailboxInfo}
                    usagePercentage={usagePercentage}
                />
            )}

            {showStorageNotification && isNewStorageUIEnabled && getStorageStore?.(mailboxInfo) && (
                <LazyLeftNavStorageBar
                    mailboxInfo={mailboxInfo}
                    storageInfos={getStorageStore?.(mailboxInfo)}
                />
            )}
            {showLeftNavPremiumUpsell && <LeftnavUpsell mailboxInfo={mailboxInfo} />}
        </div>
    );
}, 'FolderPaneContainer');
