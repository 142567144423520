import { isFeatureEnabled } from 'owa-feature-flags';
import { isComposePopoutEnabled } from 'owa-compose-popout-option';
import { getIsNonMailEntitySearchTab } from 'owa-tabbed-search';
import { getIsSearchTableShown } from 'owa-mail-list-store';

function isPopoutForSearch(): boolean {
    if (!getIsSearchTableShown()) {
        return false;
    }

    return getIsNonMailEntitySearchTab();
}

export const shouldPopoutNewMessage = (): boolean => {
    const shouldPopoutForSearch: boolean = isPopoutForSearch();
    if (shouldPopoutForSearch) {
        return true;
    } else if (isFeatureEnabled('cmp-popout-setting')) {
        return isComposePopoutEnabled();
    } else {
        return false;
    }
};
