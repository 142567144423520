import { owaComputedFn } from 'owa-computed-fn';
import {
    lazyOpenAppStoreModal,
    lazyFetchAndOpenLaunchPageMetaOsApp,
} from 'owa-app-host-state/lib/lazyFunctions';
import { createAppButton, constructKeytip } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import AddSquareRegular from 'owa-fluent-icons-svg/lib/icons/AddSquareRegular';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getControlInfo } from 'owa-mail-ribbon-utils/lib/getControlInfo';
import { isFeatureEnabled } from 'owa-feature-flags';
import { M365AppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonControlProps } from 'owa-acui';
import type { IButtonStyles } from '@fluentui/react';

export const getM365Apps = owaComputedFn(
    (
        editorId: string | undefined,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean,
        props: ReadOnlyRibbonControlDefProps
    ): RibbonControlProps => {
        const controlInfo = getControlInfo(7022, props);
        return createAppButton(
            getComposeRibbonId(7022, editorId),
            controlInfo.text,
            getComputedCallback(7022, () =>
                isFeatureEnabled('mos-inContextStore')
                    ? lazyOpenAppStoreModal.importAndExecute()
                    : lazyFetchAndOpenLaunchPageMetaOsApp.importAndExecute({
                          callerFn: 'Ribbon',
                          appId: M365AppId.AppStore,
                      })
            ),
            keyTipTabId ? constructKeytip([keyTipTabId], 7022) : undefined,
            AddSquareRegular,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: controlInfo.tooltip,
                disabled: isDisabled,
            }
        );
    }
);
