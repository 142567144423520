import { createStore } from 'owa-satcheljs';
import type {
    GroupSiteStatusInformation,
    GroupSiteStatusInformationByMailboxInfo,
} from './schema/GroupSiteStatusInformation';
import type { AccountKey } from 'owa-client-types';

export const getGroupSiteStatusStore = createStore<GroupSiteStatusInformationByMailboxInfo>(
    'getGroupSiteStatusStore',
    {
        groupSiteStatusByMailboxInfo: new Map<AccountKey, GroupSiteStatusInformation>(),
    }
);
