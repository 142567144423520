import fetchCopilotThemeImage from 'owa-copilot-themes-utils/lib/fetchCopilotThemeImage';
import { preloadThemeImage } from 'owa-theme-shared';

export default async function getCopilotThemeFallbackUrl(): Promise<string> {
    // Try to fetch the image from the cache or settings
    const blobUrl = await fetchCopilotThemeImage();

    if (blobUrl && (await preloadThemeImage(blobUrl))) {
        return blobUrl;
    }

    throw new Error('Failed to preload background image');
}
