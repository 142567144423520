import type { MailboxInfo } from 'owa-client-types';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import { getGroupSiteStatusStore } from '../GroupSiteStatusStore';
import type { GroupSiteStatusInformation } from '../schema/GroupSiteStatusInformation';
import type GroupSiteStatus from 'owa-groups-sharepoint-commands/lib/schema/GroupSiteStatus';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export function getGroupSiteStatusMap(mailboxInfo: MailboxInfo) {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const groupSiteStatusForThisMailboxinInfo =
        getGroupSiteStatusStore().groupSiteStatusByMailboxInfo.get(accountKey);

    if (!groupSiteStatusForThisMailboxinInfo) {
        const defaultGroupSiteStatus: GroupSiteStatusInformation = {
            groupSiteStatus: new Map<string, GroupSiteStatus>(),
        };
        getGroupSiteStatusStore().groupSiteStatusByMailboxInfo.set(
            accountKey,
            defaultGroupSiteStatus
        );
        return defaultGroupSiteStatus.groupSiteStatus;
    }

    return groupSiteStatusForThisMailboxinInfo.groupSiteStatus;
}

export function getGroupSiteStatus(mailboxInfo: MailboxInfo, groupSmtp: string) {
    return getGroupSiteStatusMap(mailboxInfo).get(transformGroupSmtp(groupSmtp));
}
