import loc from 'owa-localize';
import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, Multiline, getTabStyles } from 'owa-command-ribbon';
import { getInitialScalingSteps } from '../../../util/getInitialScalingSteps';
import { getMailRibbonConfigStore } from 'owa-mail-ribbon-store/lib/store/store';
import { getMLRNotesTabCommonGroupFlyout } from './getMLRNotesTabCommonGroupFlyout';
import { getMLRNotesTabControlDefinition } from './getMLRNotesTabControlDefinition';
import { getMLRNotesTabGroupLayouts } from './getMLRNotesTabGroupLayouts';
import { getMLRScalingSteps } from '../../../util/getScalingSteps';
import { getMLRTabGroups } from '../../../util/getTabGroups';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { RibbonControlGroupDefinitionMLR, RibbonTabDefinitionWithStyles } from 'owa-acui';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getUniqueTabId } from 'owa-mail-ribbon-utils/lib/getUniqueTabId';
import type { RibbonControlId } from 'owa-ribbon-ids';

const emptyCtrlList: RibbonControlId[] = [];

const getMLRNotesTab = owaComputedFn(function getMLRNotesTabfunction(
    props: ReadOnlyRibbonControlDefProps
): RibbonTabDefinitionWithStyles {
    return {
        type: Multiline,
        tabType: 'contextual',
        id: getUniqueTabId(6, undefined /* editorId */, props.itemId, props.isPopout),
        title: loc(Strings.notes_tab),
        controlGroups: { type: Multiline, groups: getNotesTabGroups(props) },
        initialScalingSteps: getInitialScalingSteps(
            getMailRibbonConfigStore(props.mailboxInfo).multiline.notesTab,
            emptyCtrlList /*controlsWithoutLabels*/,
            undefined /*editorId*/,
            undefined /*itemId*/
        ),
        scalingSteps: getMLRScalingSteps(6),
        styles: getTabStyles(),
        keytipProps: constructKeytip([], 6, true /* hasMenu */),
    };
});
export default getMLRNotesTab;

/**
 * Retrieves the groups within the Notes tab, as well as the actions that fall under each group.
 * @returns an object that contains the sub-groups and corresponding actions of the Notes tab
 */
const getNotesTabGroups = owaComputedFn(function getNotesTabGroups(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlGroupDefinitionMLR[] {
    let notesTabGroups: RibbonControlGroupDefinitionMLR[] = new Array();
    notesTabGroups = getMLRTabGroups(
        6,
        getMailRibbonConfigStore(props.mailboxInfo).multiline.notesTab.layout,
        getMLRNotesTabControlDefinition,
        getMLRNotesTabGroupLayouts,
        getMLRNotesTabCommonGroupFlyout,
        props
    );
    return notesTabGroups;
});
