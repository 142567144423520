import { createStore } from 'owa-satcheljs';
import type OwaLayoutStore from './schema/owaLayoutStore';

const owaLayoutStore: OwaLayoutStore = {
    browserHeightBucket: null,
    browserWidthBucket: null,
    areDisplayAdsEnabled: false,
};

export const getStore = createStore<OwaLayoutStore>('OwaLayout', owaLayoutStore);
