import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "NativeAuthInterop" */ './lazyIndex'),
    { name: 'NativeAuthInterop' }
);

export const lazyGetAuthTokenForAccount = new LazyAction(lazyModule, m => m.getAuthTokenForAccount);

export const lazyGetAuthTokenForNewAccount = new LazyAction(
    lazyModule,
    m => m.getAuthTokenForNewAccount
);

export const lazyGetUniqueIdentifierForLicensingAccount = new LazyAction(
    lazyModule,
    m => m.getUniqueIdentifierForLicensingAccount
);
