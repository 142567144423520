import { getGlobalSettingsAccountIndexer } from 'owa-account-source-list-store';
import { logStartGreyError } from 'owa-analytics-start';
import type { MailboxInfo, AccountKey } from 'owa-client-types';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import { errorThatWillCauseAlert } from 'owa-trace';
import { findFirstDiff } from './findFirstDiff';

type GlobalSettingsAccountKeyData = {
    accountKey: AccountKey;
    initialStack: string;
    currentLogCount: number;
};

const maxLogCount = 3;

const globalSettingsData: GlobalSettingsAccountKeyData = {
    accountKey: '' as AccountKey,
    initialStack: '',
    currentLogCount: 0,
};

/**
 * Reset the global settings data to the default values, this is exposed for unit testing purposes
 */
export function resetGlobalSettingsData() {
    globalSettingsData.accountKey = '' as AccountKey;
    globalSettingsData.initialStack = '';
    globalSettingsData.currentLogCount = 0;
}

/**
 * The persistence id based implementation of getFeatureFlagsIndexerForMailboxInfo
 * @param mailboxInfo Specifies the mailbox info for which the indexer is being requested, undefined or null for global account
 * @param errorIfNoAccountKey Optionally specifies if an error should be raised if the account key is not initialized
 * @returns Indexer value to use for feature flags
 */
export function getFeatureFlagsIndexerForMailboxInfoPersistenceIdImpl(
    mailboxInfo: MailboxInfo | undefined | null,
    errorIfNoAccountKey?: boolean
): string {
    const currentGlobalSettingsAccountKey = getGlobalSettingsAccountIndexer(
        /*throwIfNotInitialized*/ false
    );

    if (mailboxInfo && !mailboxInfo.isAnonymous) {
        const mailboxAccountKey = getAccountKeyForMailboxInfo(mailboxInfo);
        if (mailboxAccountKey !== currentGlobalSettingsAccountKey) {
            return mailboxAccountKey;
        }
    }

    // This code is here to work around an issue seen in OWA where the global settings account key changes
    // after the first time it is accessed. This is a temporary fix until the root cause is identified and
    // the global settings account key is consistent.
    if (!globalSettingsData.accountKey) {
        globalSettingsData.accountKey = currentGlobalSettingsAccountKey;
        globalSettingsData.initialStack = new Error('GlobalAccountKeyInitialized').stack || '';
    }

    if (!globalSettingsData.accountKey && errorIfNoAccountKey) {
        // We should not be trying to access flights or application settings before the global account is initialized
        errorThatWillCauseAlert(
            'FeatureFlagsIndexerForMailboxInfo: Global account not initialized'
        );
    }

    if (
        globalSettingsData.accountKey !== currentGlobalSettingsAccountKey &&
        globalSettingsData.currentLogCount < maxLogCount
    ) {
        const initial = `${typeof globalSettingsData.accountKey}(${
            globalSettingsData.accountKey.length
        })`;

        const current = `${typeof currentGlobalSettingsAccountKey}(${
            currentGlobalSettingsAccountKey.length
        })`;

        const firstDiff = findFirstDiff(initial, current);

        logStartGreyError('MismatchedGlobalAccountKey', new Error('MismatchedGlobalAccountKey'), {
            initial,
            current,
            firstDiff,
            initialStack: globalSettingsData.initialStack,
        });

        globalSettingsData.currentLogCount++;
    }

    return globalSettingsData.accountKey;
}
