import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import type { UserConfigurationArgs } from 'owa-graph-schema';
import { type default as WebKeyboardShortcutsMode } from 'owa-service/lib/contract/KeyboardShortcutsMode';
import { type default as WebReactListViewType } from 'owa-service/lib/contract/ReactListViewType';

export function createUserConfigurationUpdateFunction(updates: UserConfigurationArgs) {
    return (userConfig: OwaUserConfiguration) => {
        internalUserConfigurationUpdateFunction(userConfig, updates);
    };
}

function internalUserConfigurationUpdateFunction(
    userConfig: OwaUserConfiguration,
    updates: UserConfigurationArgs
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    Object.keys(updates).forEach(key => {
        const configKey = key as keyof UserConfigurationArgs;
        const internalUserConfig = userConfig[configKey];
        const internalUpdate = updates[configKey];
        if (internalUserConfig && internalUpdate) {
            Object.keys(internalUpdate).forEach(subKey => {
                const configSubKey = subKey as never;
                const value = applyUpdateConversionIfNecessary(
                    configSubKey,
                    internalUpdate[configSubKey]
                );
                if (value !== undefined && value !== null) {
                    internalUserConfig[configSubKey] = value as never;
                }
            });
        }
    });
}

function applyUpdateConversionIfNecessary(configKey: string, value: any) {
    if (configKey === 'KeyboardShortcutsMode' && typeof value === 'string') {
        switch (value) {
            case 'Gmail':
                return 3;
            case 'Hotmail':
                return 0;
            case 'Off':
                return 1;
            case 'Owa':
                return 4;
            case 'Yahoo':
                return 2;
        }
    }

    if (configKey == 'GlobalListViewTypeReact' && typeof value === 'string') {
        switch (value) {
            case 'Conversation':
                return 0;
            case 'Message':
                return 1;
            case 'CalendarItems':
                return 2;
        }
    }

    return value;
}
