import { observer } from 'owa-mobx-react';
import { otherViewFilterText } from 'owa-locstrings/lib/strings/otherviewfiltertext.locstring.json';
import { focusedViewFilterText } from 'owa-locstrings/lib/strings/focusedviewfiltertext.locstring.json';
import {
    otherViewFilterTextWithUnseenCount,
    focusedViewFilterTextWithUnseenCount,
} from './FocusedInboxPivot.locstring.json';
import loc from 'owa-localize';
import React from 'react';
import Droppable from 'owa-dnd/lib/components/Droppable';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import { selectFocusedViewFilter } from 'owa-mail-triage-table-utils';
import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import type { IPivotItemProps } from '@fluentui/react/lib/Pivot';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import {
    getFocusedOtherDropViewState,
    getFocusedFilterForTable,
    getStore as getListViewStore,
} from 'owa-mail-list-store';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { getInboxPivotTheme } from 'owa-mail-densities/lib/utils/getInboxPivotTheme';
import { getDensityModeCssClass, getDensityModeString } from 'owa-fabric-theme';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type InboxViewType from 'owa-service/lib/contract/InboxViewType';
import { getFocusedInboxRollupStore } from 'owa-mail-focused-inbox-rollup-store';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { isFeatureEnabled } from 'owa-feature-flags';

import {
    full,
    medium,
    compact,
    nonSelectedPivotLink,
    selectedPivotLink,
    linkContent,
    pivotText,
    pivotRoot,
    pivotItemContainer,
    dropContainer,
    dropHoverTreatment,
    pivotItem,
    pivotContainerParent,
} from './InboxPivotStyles.scss';

import classnames from 'owa-classnames';

export interface FocusedInboxPivotProps {
    tableViewId: string;
    onDropFocused: (dragInfo: DragData) => void;
    onDropOther: (dragInfo: DragData) => void;
    onKeyDown: (ev: React.KeyboardEvent<HTMLElement>, tableViewId: string) => void;
    isSupportedItemTypeForDragDrop: boolean;
    shouldShowOnDropHoverTreatment: boolean;
}

export default observer(function FocusedInboxPivot(props: FocusedInboxPivotProps) {
    const tableView = getListViewStore().tableViews.get(props.tableViewId);
    const densityModeCssClass = getDensityModeCssClass(full, medium, compact);
    const pivotElementStyles = useComputedValue(
        () => ({
            link: classnames(nonSelectedPivotLink, densityModeCssClass),
            linkIsSelected: classnames(selectedPivotLink, densityModeCssClass),
            linkContent,
            text: classnames(pivotText, densityModeCssClass),
            root: classnames(pivotRoot, densityModeCssClass),
        }),
        [densityModeCssClass]
    );
    const shouldShowUnreadCount =
        isFeatureEnabled('tri-fo-style-updates') &&
        (isFeatureEnabled('tri-fo-pivot-counts-a') || isFeatureEnabled('tri-fo-pivot-counts-b'));

    const focusedPivotLabel = useComputedValue(() => {
        const { viewType, unseenCountToDisplay } = getFocusedInboxRollupStore(
            getMailboxInfo(tableView)
        );

        if (shouldShowUnreadCount && viewType === 1 && unseenCountToDisplay) {
            return loc(focusedViewFilterTextWithUnseenCount, unseenCountToDisplay);
        }

        return loc(focusedViewFilterText);
    }, [shouldShowUnreadCount, tableView]);

    const otherPivotLabel = useComputedValue(() => {
        const { viewType, unseenCountToDisplay } = getFocusedInboxRollupStore(
            getMailboxInfo(tableView)
        );

        if (shouldShowUnreadCount && viewType === 2 && unseenCountToDisplay) {
            return loc(otherViewFilterTextWithUnseenCount, unseenCountToDisplay);
        }

        return loc(otherViewFilterText);
    }, [shouldShowUnreadCount, tableView]);

    const renderDroppablePivot = (
        link: IPivotItemProps,
        defaultRenderer: (link: IPivotItemProps) => JSX.Element,
        pivotKey: string,
        selectedPivotKey: string,
        onDrop: (dragInfo: DragData) => void
    ): JSX.Element => {
        const pivotStyles = classnames(
            pivotItemContainer,
            pivotKey !== selectedPivotKey && props.isSupportedItemTypeForDragDrop && dropContainer,
            props.shouldShowOnDropHoverTreatment && dropHoverTreatment,
            densityModeCssClass
        );
        return (
            <Droppable
                classNames={classnames(pivotItem, densityModeCssClass)}
                dropViewState={getFocusedOtherDropViewState()}
                onDrop={onDrop}
            >
                <div className={pivotStyles}>{defaultRenderer(link)}</div>
            </Droppable>
        );
    };

    const onPivotItemClicked = React.useCallback(
        (
            item?: PivotItem | undefined,
            _ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
        ) => {
            if (item?.props.itemKey) {
                const focusedViewFilterToSelect = convertPivotKeyToFocusedViewFilter(
                    item.props.itemKey
                );
                selectFocusedViewFilter(focusedViewFilterToSelect, 'FocusedOtherPivot');
            }
        },
        []
    );
    /**
     * Convert the focused view filter to a string that used as the key in Pivot fabric component
     * We need to do this instead of just using the FocusedViewFilter value, because FocusedViewFilter.None(-1) is ignored by the Fabric Pivot
     */
    const getKeyForSelectedFocusedViewFilter = (): string => {
        const focusedFilterForTable = getFocusedFilterForTable(tableView);
        let selectedKey = '';
        switch (focusedFilterForTable) {
            case -1:
                selectedKey = 'inbox';
                break;
            case 0:
                selectedKey = 'focused';
                break;
            case 1:
                selectedKey = 'other';
                break;
        }
        return selectedKey;
    };
    /**
     * Convert pivot key string back to the focused view filter to select
     * @param key of the pivot component
     */
    const convertPivotKeyToFocusedViewFilter = (key: string) => {
        switch (key) {
            case 'focused':
                return 0;
            case 'other':
                return 1;
            default:
            case 'inbox':
                return -1;
        }
    };

    const onKeyDownHandler = React.useCallback(
        (ev: React.KeyboardEvent<HTMLElement>) => {
            props.onKeyDown(ev, props.tableViewId);
        },
        [props]
    );
    const selectedKey = getKeyForSelectedFocusedViewFilter();
    const onRenderItemLinkFocusedHandler = React.useCallback(
        (link: IPivotItemProps, defaultRenderer: (link: IPivotItemProps) => JSX.Element) =>
            renderDroppablePivot(
                link,
                defaultRenderer,
                'focused',
                selectedKey,
                props.onDropFocused
            ),
        [props, selectedKey]
    );
    const renderFocusedPivot = (): JSX.Element => {
        return (
            <PivotItem
                headerText={focusedPivotLabel}
                itemKey={'focused'}
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (182,17): Type '(link: IPivotItemProps, defaultRenderer: (link: IPivotItemProps) => JSX.Element) => JSX.Element' is not assignable to type 'IRenderFunction<IPivotItemProps>'.
                // @ts-expect-error
                onRenderItemLink={onRenderItemLinkFocusedHandler}
            />
        );
    };
    const onRenderItemLinkOtherHandler = React.useCallback(
        (link: IPivotItemProps, defaultRenderer: (link: IPivotItemProps) => JSX.Element) =>
            renderDroppablePivot(link, defaultRenderer, 'other', selectedKey, props.onDropOther),
        [props, selectedKey]
    );
    const renderOtherPivot = (): JSX.Element => {
        return (
            <PivotItem
                headerText={otherPivotLabel}
                itemKey={'other'}
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (206,17): Type '(link: IPivotItemProps, defaultRenderer: (link: IPivotItemProps) => JSX.Element) => JSX.Element' is not assignable to type 'IRenderFunction<IPivotItemProps>'.
                // @ts-expect-error
                onRenderItemLink={onRenderItemLinkOtherHandler}
            />
        );
    };

    // Focused, Other
    return (
        <ThemeProvider
            className={classnames(pivotContainerParent, densityModeCssClass)}
            applyTo="none"
            theme={getInboxPivotTheme(getDensityModeString())}
        >
            <div onKeyDown={onKeyDownHandler}>
                <Pivot
                    styles={pivotElementStyles}
                    selectedKey={`${selectedKey}`}
                    onLinkClick={onPivotItemClicked}
                >
                    {renderFocusedPivot()}
                    {renderOtherPivot()}
                </Pivot>
            </div>
        </ThemeProvider>
    );
}, 'FocusedInboxPivot');
