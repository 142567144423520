import type { Module } from 'owa-workloads-module';
import { getCurrentModule } from 'owa-app-module-store';
import type ListViewState from '../store/schema/ListViewState';
import type BusStopState from '../store/schema/BusStopState';
import getListViewStore from '../store/Store';

function getDefaultListViewState(): ListViewState {
    return {
        selectedTableViewId: '',
        focusedOtherDropViewState: null,
        itemContextMenuState: null,
        expandedConversationViewState: {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (34,9): Type 'null' is not assignable to type 'string'.
            // @ts-expect-error
            expandedRowKey: null,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (38,9): Type 'null' is not assignable to type 'string'.
            // @ts-expect-error
            focusedNodeId: null,
            lastAutoFocusedNodeId: null,
            selectedNodeIds: [],
            allNodeIds: [],
            shouldBeExpanded: false,
            busStopStateMap: new Map<string, BusStopState>([]),
            forks: null,
            anchorNodeId: null,
        },
        startIndexVLV: 0,
        endIndexVLV: 0,
        shouldAutoOpenJumpTo: false,
    };
}

export default function getListViewState(moduleContext?: Module) {
    const currentModule = moduleContext ?? getCurrentModule();
    const appName = currentModule === 'Groups' ? 'Groups' : 'Mail';
    let cachedState = getListViewStore().listViewStateByModule.get(appName);
    if (!cachedState) {
        cachedState = getDefaultListViewState();
        getListViewStore().listViewStateByModule.set(appName, cachedState);
    }
    return cachedState;
}
