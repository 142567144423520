import { observer } from 'owa-mobx-react';
import { useComputed, useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { newFavoritePickedText, renameFavoriteLabel } from './FavoritesList.locstring.json';
import loc, { format } from 'owa-localize';
import { getStore as getSharedFavoritesStore } from 'owa-favorites';
import { FavoritesPicker } from 'owa-mail-favorites-picker';
import { FavoriteNodeContextMenu } from '../lazyFunctions';
import { setContextMenuState } from 'owa-mail-favorites-store/lib/actions/favoritesContextMenu';
import { getMailFavoritesViewState } from 'owa-mail-favorites-store';
import folderStore, { getEffectiveFolderDisplayName, getFolderTable } from 'owa-folders';
import { FAVORITE_FOLDERS_TREE_TYPE } from 'owa-folders-constants';
import React from 'react';
import FavoriteNode from './FavoriteNode';
import type { FavoriteFolderData } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { getStore } from 'owa-mail-folder-store/lib/store/store';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';
import { getMailboxInfoFromFolderId } from 'owa-mail-mailboxinfo';
import { FolderOperationNode } from 'owa-mail-folder-view';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';
import { full, medium, nodeHeight } from 'owa-tree-node/lib/components/NodeHeight.scss';

import classnames from 'owa-classnames';

export interface FavoritesListProps {
    mailboxInfo: MailboxInfo;
}

export default observer(function FavoritesList(props: FavoritesListProps) {
    const favoritesStore = useComputed(() => {
        return getSharedFavoritesStore();
    });

    const accountKey = getAccountKeyForMailboxInfo(props.mailboxInfo);

    const orderedOutlookFavoritesIds = isMonarchMultipleAccountsEnabled()
        ? favoritesStore.get().orderedCombinedOutlookFavoritesIds
        : favoritesStore.get().favoriteTreeData.get(accountKey)?.orderedOutlookFavoritesIds;

    if (!orderedOutlookFavoritesIds) {
        return null;
    }

    return (
        <>
            {orderedOutlookFavoritesIds?.map(favoriteId => (
                <FavoriteNodeWrapper key={favoriteId} favoriteId={favoriteId} />
            ))}
            <AddFavoriteWrapper mailboxInfo={props.mailboxInfo} />
            <FavoritesContextMenuWrapper mailboxInfo={props.mailboxInfo} />
        </>
    );
}, 'FavoritesList');

const shouldRenderNodeInEditMode = (favoriteId: string): boolean => {
    const folderTextFieldViewState = getStore().folderTextFieldViewState;
    return (
        !!folderTextFieldViewState &&
        folderTextFieldViewState.folderId == favoriteId &&
        folderTextFieldViewState.folderTreeType === FAVORITE_FOLDERS_TREE_TYPE
    );
};

const FavoriteNodeWrapper = observer(function FavoriteNodeWrapper(props: { favoriteId: string }) {
    const { favoriteId } = props;
    const favoritesStore = useComputed(() => {
        return getSharedFavoritesStore();
    });
    const renderInEditMode = useComputedValue((): boolean => {
        return shouldRenderNodeInEditMode(favoriteId);
    }, [favoriteId]);

    if (renderInEditMode) {
        const folderId = (
            favoritesStore.get().outlookFavorites.get(favoriteId) as FavoriteFolderData
        ).folderId;
        const currentFolderName = getEffectiveFolderDisplayName(getFolderTable().get(folderId));
        return (
            <FolderOperationNode
                key="favoriteTextField"
                folderId={folderId}
                treeType={FAVORITE_FOLDERS_TREE_TYPE}
                nestDepth={0}
                operationType={'rename'}
                originalValue={currentFolderName}
                mailboxInfo={getMailboxInfoFromFolderId(folderId)}
                ariaAnnouncementLabel={renameFavoriteLabel}
            />
        );
    }
    return <FavoriteNode key={favoriteId} favoriteId={favoriteId} ellipsesOnHover={true} />;
}, 'FavoriteNodeWrapper');

const AddFavoriteWrapper = observer(function AddFavoriteWrapper(props: {
    mailboxInfo: MailboxInfo;
}) {
    const accountKey = getAccountKeyForMailboxInfo(props.mailboxInfo);
    const [favoriteUpdateText, setFavoriteUpdateText] = React.useState<string>('');

    const onItemSelected = React.useCallback((displayName: string) => {
        setFavoriteUpdateText(format(loc(newFavoritePickedText), displayName));
    }, []);

    const shouldShowFavoritesPicker =
        getMailFavoritesViewState(accountKey)?.shouldShowFindFavoritesPicker;
    return (
        <>
            {shouldShowFavoritesPicker && (
                <div
                    className={classnames(getDensityModeCssClass(full, medium, medium), nodeHeight)}
                >
                    <FavoritesPicker
                        mailboxInfo={props.mailboxInfo}
                        onItemSelected={onItemSelected}
                    />
                </div>
            )}
            <span className="screenReaderOnly" aria-live="assertive" aria-atomic="true">
                {favoriteUpdateText}
            </span>
        </>
    );
},
'AddFavoriteWrapper');

const FavoritesContextMenuWrapper = observer(function FavoritesContextMenuWrapper(props: {
    mailboxInfo: MailboxInfo;
}) {
    const accountKey = getAccountKeyForMailboxInfo(props.mailboxInfo);
    const contextMenuState = getMailFavoritesViewState(accountKey)?.contextMenuState;

    const onContextMenuDismissed = React.useCallback(() => {
        setContextMenuState(accountKey, null);
    }, [accountKey]);

    // Render the context menu if it is set to be visible
    if (!contextMenuState || contextMenuState.showRootMenu) {
        return null;
    }

    let folder;
    if (contextMenuState.folderId) {
        if (contextMenuState.nodeType == 5) {
            folder = publicFolderFavoriteStore.folderTable.get(contextMenuState.folderId);
        } else {
            folder = folderStore.folderTable.get(contextMenuState.folderId);
        }
    }

    return (
        <FavoriteNodeContextMenu
            anchorPoint={contextMenuState.anchor}
            nodeId={contextMenuState.nodeId}
            nodeType={contextMenuState.nodeType}
            folder={folder}
            onDismiss={onContextMenuDismissed}
            mailboxInfo={props.mailboxInfo}
        />
    );
},
'FavoritesContextMenuWrapper');
