//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import type * as Types from 'owa-graph-schema';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConversationTypeFragmentFragmentDoc } from './ConversationTypeFragment.interface';
export type ConversationRowsQueryVariables = Types.Exact<{
    folderId: Types.Scalars['String'];
    searchFolderId?: Types.InputMaybe<Types.Scalars['String']>;
    sortBy: Types.SortByInput;
    pagingInfo: Types.PagingInfoInput;
    viewFilter: Types.ViewFilter;
    focusedViewFilter: Types.FocusedViewFilter;
    messageSenderScreeningFilter?: Types.InputMaybe<Types.MessageSenderScreeningFilter>;
    category?: Types.InputMaybe<Types.Scalars['String']>;
    mailboxInfo: Types.MailboxInfoInput;
    shapeName: Types.Scalars['String'];
    isScheduledFolder: Types.Scalars['Boolean'];
    pausedInboxTime?: Types.InputMaybe<Types.Scalars['String']>;
    shouldFilterToMail?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;
export type ConversationRowsQuery = {
    __typename?: 'Query';
    conversationRows?: {
        __typename?: 'ConversationRowConnection';
        searchFolderId?: string | null;
        folderId?: string | null;
        totalConversationRowsInView?: number | null;
        indexedOffset?: number | null;
        edges: Array<{
            __typename?: 'ConversationRowEdge';
            cursor: string;
            node: {
                __typename?: 'ConversationType';
                id: string;
                ConversationId: Types.ItemId;
                ConversationTopic: string;
                UniqueRecipients?: Array<string> | null;
                UniqueSenders?: Array<string> | null;
                LastDeliveryTime: string;
                Categories?: Array<string> | null;
                FlagStatus?: Types.FlagStatus | null;
                HasAttachments?: boolean | null;
                MessageCount?: number | null;
                GlobalMessageCount?: number | null;
                UnreadCount: number;
                GlobalUnreadCount?: number | null;
                Size?: number | null;
                ItemClasses?: Array<string> | null;
                Importance?: Types.ImportanceType | null;
                ItemIds?: Array<Types.BaseItemId> | null;
                GlobalItemIds?: Array<Types.BaseItemId> | null;
                LastModifiedTime?: string | null;
                InstanceKey?: string | null;
                Preview?: string | null;
                IconIndex?: Types.IconIndexType | null;
                DraftItemIds?: Array<Types.BaseItemId> | null;
                HasIrm?: boolean | null;
                IsTaggedForBigScreen?: boolean | null;
                LastDeliveryOrRenewTime: string;
                LastSentTime?: string | null;
                GlobalMentionedMe?: boolean | null;
                GlobalAtAllMention?: boolean | null;
                SortOrderSource?: Types.ExecuteSearchSortOrder | null;
                SpotlightIsVisible?: boolean | null;
                LastSender?: Types.SingleRecipientType | null;
                From?: Types.SingleRecipientType | null;
                EntityNamesMap?: Types.Number | null;
                HasExternalEmails?: boolean | null;
                ReturnTime?: string | null;
                HasSharepointLink?: boolean | null;
                HasAttachmentPreviews?: boolean | null;
                HasProcessedSharepointLink?: boolean | null;
                ConversationToMe?: boolean | null;
                ConversationCcMe?: boolean | null;
                ReactionsCount?: number | null;
                ConversationAntispamUnauthenticatedSender?: boolean | null;
                ConversationCopilotInboxScore?: number | null;
                ParentFolderId?: {
                    __typename?: 'FolderId';
                    Id: string;
                    ChangeKey?: string | null;
                } | null;
            };
        }>;
        pageInfo: {
            __typename?: 'PageInfoWithCursorPagination';
            endCursor?: string | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string | null;
        };
    } | null;
};
export const ConversationRowsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ConversationRows' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'folderId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchFolderId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortByInput' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagingInfo' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PagingInfoInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewFilter' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewFilter' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'focusedViewFilter' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FocusedViewFilter' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'messageSenderScreeningFilter' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'MessageSenderScreeningFilter' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'category' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailboxInfo' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MailboxInfoInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'shapeName' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isScheduledFolder' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pausedInboxTime' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldFilterToMail' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversationRows' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'options' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'searchFolderId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'searchFolderId' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'viewFilter' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'viewFilter' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'focusedViewFilter' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'focusedViewFilter' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'messageSenderScreeningFilter',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'messageSenderScreeningFilter',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'category' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'category' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'pausedInboxTime' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'pausedInboxTime' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'pagingInfo' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'pagingInfo' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'commonRowsOptions' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'folderId' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'folderId',
                                                            },
                                                        },
                                                    },
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sortBy' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'sortBy' },
                                                        },
                                                    },
                                                    {
                                                        kind: 'ObjectField',
                                                        name: {
                                                            kind: 'Name',
                                                            value: 'mailboxInfo',
                                                        },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mailboxInfo',
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'shape' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'ShapeName' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'shapeName',
                                                            },
                                                        },
                                                    },
                                                    {
                                                        kind: 'ObjectField',
                                                        name: {
                                                            kind: 'Name',
                                                            value: 'IsScheduledFolder',
                                                        },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isScheduledFolder',
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'shouldFilterToMail' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'shouldFilterToMail' },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'ConversationTypeFragment',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'cursor' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'searchFolderId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'folderId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalConversationRowsInView' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'indexedOffset' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'endCursor' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hasNextPage' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hasPreviousPage' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'startCursor' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...ConversationTypeFragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ConversationRowsQuery, ConversationRowsQueryVariables>;
