import { mutatorAction } from 'satcheljs';
import type { MailFolder } from 'owa-graph-schema';
import getFolderTable from '../selectors/getFolderTable';

// We are using a mutator action because when we retrieve folders from the folder table to update the folder information(DisplayName, childrenFolderIds), we are changing
// stored observable values.
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateFolderInformation = mutatorAction(
    'updateFolderInformation',
    (
        rootFolder: MailFolder,
        displayName: string | undefined,
        folders: {
            [id: string]: MailFolder;
        },
        folderIds: string[],
        defaultChildIdsForRootFolder: string[]
    ) => {
        if (displayName) {
            rootFolder.displayName = displayName; // The passed in folder, if it existed in the table, is a mobx observable.
        }

        if (defaultChildIdsForRootFolder.length > 0) {
            rootFolder.childFolderIds = defaultChildIdsForRootFolder;
        }

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        folderIds.forEach(folderId => {
            const childFolder = folders[folderId];
            // Parent maybe already fetched and did not come as part of this request, so get from store
            const parent =
                folders[childFolder.parentFolderId] ||
                getFolderTable().get(childFolder.parentFolderId); // The folder retreived from getFolderTable is an observable.
            if (parent?.childFolderIds && parent.childFolderIds.indexOf(folderId) == -1) {
                parent.childFolderIds.push(folderId);
            }
        });
    }
);
