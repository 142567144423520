import type { MailboxInfo, MailboxInfoSource } from 'owa-client-types';
import type {
    M365UserMailboxAccountSource,
    M365PublicFolderMailboxAccountSource,
} from 'owa-account-source-list-store';
import { isRemovedAccount } from 'owa-account-source-list-types';
import {
    accountSourceDataTypeChecker,
    getAccountBySourceId,
    setPublicFolderAccounts,
} from 'owa-account-source-list-store';
import { buildM365PublicFolderMailboxAccountSource } from 'owa-account-source-list/lib/utils/accountSourceBuilder';
import getUserAccount from './utils/getUserAccount';
import {
    runActionWithAsyncFallback,
    type ActionExecution,
} from './utils/runActionWithAsyncFallback';
import { logMailboxInfoNotFromStore } from './utils/logMailboxInfoNotFromStore';

/**
 * Adds the public folder to the account
 * @param account Specifies the account to which the public folder is to be added
 * @param publicFolderMailbox Specifies the public folder that is to be added
 */
function addPublicFolderMailboxToAccount(
    account: M365UserMailboxAccountSource,
    publicFolderMailbox: M365PublicFolderMailboxAccountSource
): ActionExecution {
    const sources: M365PublicFolderMailboxAccountSource[] = [publicFolderMailbox];
    account.teamsSources.sources.forEach(source => {
        if (accountSourceDataTypeChecker.isM365PublicFolderMailbox(source)) {
            sources.push(source);
        }
    });

    return runActionWithAsyncFallback('PublicFolders', () => {
        setPublicFolderAccounts(account.sourceId, account.sharedSources.loadState, sources);
    });
}

/**
 * Returns the MailboxInfo for the public folder mailbox
 * @param mailboxInfoInAccount Used to determine the coprincipal account, see getIndexerForMailboxInfo for more details
 * @param smtpAddress SMTP address of the public folder mailbox
 * @returns MailboxInfo for the public folder mailbox
 */
export function getMailboxInfoForPublicFolder(
    mailboxInfoInAccount: MailboxInfo,
    smtpAddress: string
): MailboxInfoSource {
    const account = getUserAccount(mailboxInfoInAccount);

    // We build the shared mailbox account source that would need to be added, this will get us the sourceId
    // for the shared mailbox which we can use to find if it already exists or needs to be added
    const publicFolderMailbox = buildM365PublicFolderMailboxAccountSource(
        smtpAddress,
        smtpAddress,
        account.mailboxInfo,
        account.persistenceId,
        {}
    );

    if (isRemovedAccount(account)) {
        // Mark the MailboxInfo as coming from a removed account
        return { ...publicFolderMailbox.mailboxInfo, isRemoved: true, mailboxRank: 'Resource' };
    }

    let existing = getAccountBySourceId(publicFolderMailbox.sourceId);
    if (!existing) {
        const execution = addPublicFolderMailboxToAccount(account, publicFolderMailbox);
        existing = getAccountBySourceId(publicFolderMailbox.sourceId);
        if (!existing) {
            logMailboxInfoNotFromStore(execution, publicFolderMailbox.mailboxInfo.type);
            return publicFolderMailbox.mailboxInfo;
        }
    }

    return existing.mailboxInfo;
}
