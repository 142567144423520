import { setLogDatapointFunc } from 'owa-performance';
import { logUsage } from './api/logUsage';
setLogDatapointFunc(logUsage);

export {
    lazyOneDSFlush,
    lazyGetResourceTimingForUrl,
    lazyLogDatapoint,
    lazyLogAddinsCustomerContent,
    lazyLogAddinsTelemetryEvent,
} from './lazyFunctions';
/* eslint-disable-next-line owa-custom-rules/forbid-package-reexport  -- (https://aka.ms/OWALintWiki)
 * Baseline comment; do not copy-paste. Rexporting from a separate package complicates the package graph.
 *	> Do not re-export from a separate package */
export { DatapointVariant, DatapointStatus } from 'owa-analytics-types';
export { AriaDatapoint } from './datapoints/AriaDatapoint';
export { PerformanceCoreDatapoint } from './datapoints/PerformanceCoreDatapoint';
export {
    PerformanceDatapoint,
    defaultDatapointTimeout,
    incrementReactionCount,
} from './datapoints/PerformanceDatapoint';
export { default as VerboseDatapoint } from './datapoints/VerboseDatapoint';
export { default as UsageDatapoint } from './datapoints/UsageDatapoint';
export { UsageCoreDatapoint } from './datapoints/UsageCoreDatapoint';
export {
    addDatapointMiddleware,
    returnTopExecutingActionDatapoint,
    wrapFunctionForDatapoint,
    wrapFunctionForCoreDatapoint,
} from './DatapointMiddleware';
export { logCoreUsage } from './api/logCoreUsage';
export { logUsage };
export { logCoreGreyError } from './api/logCoreGreyError';
export { logGreyError } from './api/logGreyError';
export { logDatapoint } from './api/logDatapoint';
export { logVerboseUsage } from './api/logVerboseUsage';
