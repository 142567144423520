import type { MailboxInfo } from 'owa-client-types';
import { getMailboxInfoForPublicFolder } from 'owa-mailbox-info/lib/getMailboxInfoForPublicFolder';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export default function getPublicFolderMailboxInfoForSmtpAddress(
    publicFolderSmtpAddress: string
): MailboxInfo {
    return getMailboxInfoForPublicFolder(
        getGlobalSettingsAccountMailboxInfo(),
        publicFolderSmtpAddress
    );
}
