import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';

/**
 * The isExplicitLogon property of SessionSettings is set to true if the user principal name
 * (UPN or userIdentity) used to logon is not the owner of the mailbox. Originally in OWA this
 * only needed to be checked on the default account, with multi-account suport for shared/delegate
 * mailboxes this check needs to be done on an account basis.
 *
 * Checking isExplicitingLogon property has a linter associated with it to make sure it is always
 * checked in an account specific way. The isAccountExplicitLogon is the most common way
 * of doing this which is why it has an approved linter suppression. Calls should either call
 * this funciton or get approval for an isExplicitLogon check elsewhere in the code where this
 * function cannot be used. For example, if an isExplicitLogon check was needed during the adding
 * or starting of account this funciton could not be called because the owa-session-store would not
 * be populated yet. *
 * @param mailboxInfo Any MailboxInfo object in the account to be checked
 * @returns True if the account is shared or delegate logon, false otherwise
 */
export function isAccountExplicitLogon(mailboxInfo: MailboxInfo): boolean {
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * This is the common location for checking if an account is a shared or delegate account. */
    return !!getAccountScopeUserSettings(mailboxInfo).SessionSettings?.IsExplicitLogon;
}
