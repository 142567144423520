import { observer } from 'owa-mobx-react';
import React from 'react';
import { KeyCodes } from '@fluentui/react/lib/Utilities';
import { Input, Button, type InputOnChangeData, Tooltip } from '@fluentui/react-components';
import { getUITreeNodeDepth } from 'owa-tree-node/lib/utils/treeNodeUIUtils';
import loc, { isCurrentCultureRightToLeft, isStringNullOrWhiteSpace } from 'owa-localize';
import { getDensityModeString, getDensityModeCssClass } from 'owa-fabric-theme';
import { CheckmarkRegular, FolderRegular } from '@fluentui/react-icons';
import { isFeatureEnabled } from 'owa-feature-flags';

import { FluentButton } from 'owa-fluent-v9-shims';
import type { ITextField } from '@fluentui/react/lib/TextField';
import { TextField } from '@fluentui/react/lib/TextField';
import { saveButtonText } from 'owa-locstrings/lib/strings/savebuttontext.locstring.json';

import {
    enabled,
    container,
    innerTextField,
    newFolderSaveButton,
    innerTextFieldOld,
    iconsNext,
    iconsNextFull,
} from './FolderTextField.scss';
import classnames from 'owa-classnames';

const SaveIcon = <CheckmarkRegular />;

enum densityPadding {
    'compact' = 24,
    'medium' = 29,
    'full' = 28,
}

export interface FolderTextFieldProps {
    depth: number; // root is 0, every sub node increase this number by 1.
    onEntry: (value: string, folderId?: string) => void;
    onDismiss: () => void;
    folderId?: string;
    defaultValue?: string;
    className?: string;
    styles?: object; // remove when fp-new-folder-node has shipped
}

/**
 * Component that renders a TextField that is focused when mounted,
 * and issues a callback with the current value when either blurred or
 * "Enter" is pressed.
 */
export default observer(function FolderTextField(props: FolderTextFieldProps) {
    if (isFeatureEnabled('fp-new-folder-node')) {
        return <FolderTextFieldInner {...props} />;
    } else {
        return <FolderTextFieldOld {...props} />;
    }
}, 'FolderTextField');

const FolderTextFieldInner = observer(function FolderTextFieldInner(props: FolderTextFieldProps) {
    const textField = React.useRef<any>(null);
    const [textFieldValue, setTextFieldValue] = React.useState(props.defaultValue);
    const density = getDensityModeString();
    const getTextboxContainerStyles = () => {
        const treeNodePadding = getUITreeNodeDepth(props.depth);
        const nearTextPadding = treeNodePadding + densityPadding[density] + 'px';
        return isCurrentCultureRightToLeft()
            ? {
                  paddingRight: nearTextPadding,
              }
            : {
                  paddingLeft: nearTextPadding,
              };
    };

    React.useEffect(() => {
        textField?.current?.focus();
        /**
         * If FolderTextField is rendered with a non-empty defaultValue prop
         * (in "Rename" mode), select all text so user can quickly rename
         * without having to move cursor position manually.
         */
        if (props.defaultValue) {
            textField?.current?.select();
        }
    }, []);

    const onBlur = React.useCallback(() => {
        if (textFieldValue) {
            props.onEntry(textFieldValue, props.folderId);
        } else {
            props.onDismiss();
        }
    }, [props.onDismiss, props.onEntry, textFieldValue, props.folderId]);

    const onKeyDown = React.useCallback(
        (evt: React.KeyboardEvent<unknown>) => {
            switch (evt.which) {
                case KeyCodes.enter:
                    if (textFieldValue) {
                        props.onEntry(textFieldValue, props.folderId);
                    }
                    break;
                case KeyCodes.escape:
                    props.onDismiss();
                    break;
            }
        },
        [textFieldValue, props.onEntry, props.folderId, props.onDismiss]
    );

    const onChange = React.useCallback(
        (_ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            setTextFieldValue(data.value);
        },
        []
    );

    const onClickSaveButton = React.useCallback(
        (ev: React.MouseEvent<unknown>) => {
            ev.stopPropagation();
            textFieldValue && props.onEntry(textFieldValue, props.folderId);
        },
        [textFieldValue, props.onEntry, props.folderId]
    );

    const size = React.useMemo(() => {
        switch (density) {
            case 'full':
                return 'medium';
            default:
                return 'small';
        }
    }, [density]);

    const isSaveButtonDisabled = isStringNullOrWhiteSpace(textFieldValue);

    const saveButton = React.useMemo(() => {
        return (
            <Tooltip content={loc(saveButtonText)} relationship="label">
                <Button
                    disabled={isSaveButtonDisabled}
                    size="small"
                    onClick={onClickSaveButton}
                    appearance="transparent"
                    className={isSaveButtonDisabled ? undefined : enabled}
                    icon={SaveIcon}
                />
            </Tooltip>
        );
    }, [isSaveButtonDisabled, size, onClickSaveButton]);

    const densityModeCssClass = getDensityModeCssClass(iconsNextFull, undefined, undefined);

    const classNameForIcon = classnames(iconsNext, densityModeCssClass);

    return (
        <div className={classnames(props.className, container)} style={getTextboxContainerStyles()}>
            <FolderRegular className={classNameForIcon} />
            <Input
                className={innerTextField}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={onChange}
                defaultValue={props.defaultValue}
                ref={textField}
                value={textFieldValue}
                size={size}
                contentAfter={saveButton}
            />
        </div>
    );
}, 'FolderTextField');

const FolderTextFieldOld = observer(function FolderTextFieldOld(props: FolderTextFieldProps) {
    const textField = React.useRef<ITextField>(null);
    const [textFieldValue, setTextFieldValue] = React.useState(props.defaultValue);
    const getTextboxContainerStyles = () => {
        const treeNodePadding = getUITreeNodeDepth(props.depth);
        const nearTextPadding = treeNodePadding + 33 + 'px';
        return isCurrentCultureRightToLeft()
            ? {
                  paddingRight: nearTextPadding,
              }
            : {
                  paddingLeft: nearTextPadding,
              };
    };

    React.useEffect(() => {
        textField?.current?.focus();
        /**
         * If FolderTextField is rendered with a non-empty defaultValue prop
         * (in "Rename" mode), select all text so user can quickly rename
         * without having to move cursor position manually.
         */
        if (props.defaultValue) {
            textField?.current?.select();
        }
    }, []);

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onBlur = () => {
        if (textField?.current?.value) {
            props.onEntry(textField.current.value, props.folderId);
        } else {
            props.onDismiss();
        }
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onKeyDown = (evt: React.KeyboardEvent<unknown>) => {
        switch (evt.which) {
            case KeyCodes.enter:
                if (textFieldValue) {
                    props.onEntry(textFieldValue, props.folderId);
                }
                break;
            case KeyCodes.escape:
                props.onDismiss();
                break;
        }
    };

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onChange = (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        setTextFieldValue(newValue);
    };

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onClickSaveButton = (ev: React.MouseEvent<unknown>) => {
        ev.stopPropagation();
        textFieldValue && props.onEntry(textFieldValue, props.folderId);
    };

    return (
        <div className={classnames(props.className, container)} style={getTextboxContainerStyles()}>
            <TextField
                className={classnames(innerTextFieldOld)}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={onChange}
                defaultValue={props.defaultValue}
                componentRef={textField}
                styles={props.styles}
                value={textFieldValue}
            />
            <FluentButton
                disabled={!textFieldValue}
                onClick={onClickSaveButton}
                text={loc(saveButtonText)}
                appearance="transparent"
                className={classnames(newFolderSaveButton, textFieldValue && enabled)}
            />
        </div>
    );
}, 'FolderTextField');
