import { logUsage } from 'owa-analytics';
import { getCopilotThemeTimestamp } from 'owa-copilot-themes-config';
import createImageBlob from './utils/createImageBlob';
import fetchCopilotThemeImageFromCache from './utils/fetchCopilotThemeImageFromCache';
import fetchCopilotThemeImageFromSettings from './utils/fetchCopilotThemeImageFromSettings';
import setCopilotThemeImageInCache from './utils/setCopilotThemeImageInCache';

export default async function fetchCopilotThemeImage(): Promise<string | undefined> {
    const timestamp = getCopilotThemeTimestamp();
    const cachedResponse = await fetchCopilotThemeImageFromCache(timestamp);

    const imageBlob = cachedResponse?.ok
        ? await cachedResponse.blob()
        : await fetchImageFromSettingsAndSave(timestamp);

    if (!imageBlob) {
        logUsage('VisualTheming_CopilotThemeImageNotFound');
        return undefined;
    }

    return URL.createObjectURL(imageBlob);
}

async function fetchImageFromSettingsAndSave(timestamp: string): Promise<Blob | undefined> {
    const settingResponse = await fetchCopilotThemeImageFromSettings();
    const imageData = settingResponse[0]?.value;

    if (!imageData) {
        return undefined;
    }

    const imageBlob = await createImageBlob(imageData);
    setCopilotThemeImageInCache(imageBlob, timestamp);
    return imageBlob;
}
