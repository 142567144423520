export const sortAscendingLabel = "q$Iz2c";
export const sortDescendingLabel = "ehwpte";
export const fromColumnLabel = "k$MCoc";
export const toColumnLabel = "PN6c3b";
export const subjectColumnLabel = "GRqhzd";
export const receivedColumnLabel = "JhTb7";
export const sentColumnLabel = "kQydGb";
export const returnTimeColumnLabel = "dD1RQd";
export const modifiedColumnLabel = "DuwSFd";
export const columnHeaderAriaLabel = "MZnM5d";
export const resizeButtonLabel = "AUgtpc";
export default {sortAscendingLabel,sortDescendingLabel,fromColumnLabel,toColumnLabel,subjectColumnLabel,receivedColumnLabel,sentColumnLabel,returnTimeColumnLabel,modifiedColumnLabel,columnHeaderAriaLabel,resizeButtonLabel};