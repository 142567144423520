import type { SharedMailboxInfo } from 'owa-client-types/lib/SharedMailboxInfo';
import { getAccountBySourceId, accountSourceDataTypeChecker } from 'owa-account-source-list-store';
import { getSharedMailboxOrigin } from './getSharedMailboxOrigin';

/**
 * Checks to see if the MailboxInfo is an automapped shared mailbox
 * @param mailboxInfo Specifies the MailboxInfo to check
 * @returns True if it is an automapped shared mailbox, false otherwise
 */
export function isSharedMailboxAutomapped(mailboxInfo: SharedMailboxInfo): boolean {
    if (!!mailboxInfo.sourceId) {
        const source = getAccountBySourceId(mailboxInfo.sourceId);
        if (
            source &&
            accountSourceDataTypeChecker.isM365SharedMailbox(source) &&
            source.isAutomapped
        ) {
            return true;
        }

        // When the same shared mailbox is both automapped and manually added there will be multiple entries
        // in the owa-account-source-list store. If the one we found is not automapped, we want to check to
        // see if a second entry for the same mailbox exists that is automapped. This is done by calling through
        // to getSharedMailboxOrigin which will check the source list for us.
    }

    return getSharedMailboxOrigin(mailboxInfo).isAutomapped;
}
