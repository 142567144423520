import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailOrganizationSettings"*/ './lazyIndex'),
    { name: 'MailOrganizationSettings' }
);

export const lazyInitializeMailOrganizationSettings = new LazyAction(
    lazyModule,
    m => m.initializeMailOrganizationSettings
);

export { default as ViewType } from './store/schema/ViewType';
export { default as MailOrganizationViewTypes } from './store/schema/MailOrganizationViewTypes';
