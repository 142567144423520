// Scenario name for Feedback
export const feedbackScenarioName: string = 'Feedback';

// App name for Eventify
export const eventifyAppName: string = 'Eventify';

// App name for Places
export const placesAppName: string = 'Places';

// App name for Bookable Time
export const bookabletimeAppName: string = 'Bookabletime';

// Scenario name for Bookable Time feedback
export const bookableTimeScenarioName: string = 'BookableTimeAutoFeedback';

// App name for Bookings Product
export const bookingsAppName: string = 'Bookings';

// Scenario name for Bookings Mobile Feedback
export const bookingsMobileFeedbackScenario: string = 'BookingsMobileFeedback';

// This is the entrypoint string we use for telemetry both in the toggle
// and in this package. So, exporting for use in the toggle package
export const entrypointStringActiveWin32User = 'ArtificialWin32Toggle';

// Scenario name for Monarch Teams Toggle Feedback
export const monarchTeamsToggleFeedbackScenario: string = 'M365CalendarToggleFeedback';

// App name for Teams Calendar Product
export const monarchTeamsAppName: string = 'M365CalendarTeams';

// App name for M365 Calendar in MetaOS hubs
export const metaOSCalendarAppName: string = 'M365CalendarMetaOS';
