import { mutatorAction } from 'satcheljs';
import type { ThemeResources } from 'owa-theme-shared';
import { store } from '../store/store';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const addThemeData = mutatorAction(
    'addThemeData',
    (themeDataName: string, themeData: ThemeResources | null) => {
        // don't add the theme if we already have the resources or if the resource are null
        if (!store.themeData.get(themeDataName) && themeData) {
            store.themeData.set(themeDataName, themeData);
        }
    }
);
