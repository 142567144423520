import type { MailboxInfo } from 'owa-client-types';
import {
    copilotSettingsStore,
    lazyFetchCopilotSettings,
    FetchState,
    type ImplementedCopilotFeatures,
    type CopilotSettingsStore,
} from 'owa-copilot-settings-store';
import { toJS } from 'mobx';
import { type DatapointVariant, logUsage, logGreyError } from 'owa-analytics';
import { getCurrentLanguage } from 'owa-localize';
import { areFeatureFlagsInitialized } from 'owa-feature-flags';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

type GenericEnablementCheckFunction<T extends ImplementedCopilotFeatures, K> = (
    store: CopilotSettingsStore,
    language: string,
    scenario: T,
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean,
    shouldIgnoreUserPreferences?: boolean
) => K;

export default function createStoreCheckForGenericEnablement<
    T extends ImplementedCopilotFeatures,
    K
>(
    funcName: string,
    checkFunc: GenericEnablementCheckFunction<T, K>
): (
    scenario: T,
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean,
    shouldIgnoreUserPreferences?: boolean
) => K | undefined {
    return (
        scenario: T,
        mailboxInfo: MailboxInfo,
        skipLanguageCheck?: boolean,
        shouldIgnoreUserPreferences?: boolean
    ) => {
        const language = getCurrentLanguage();
        const store = copilotSettingsStore(mailboxInfo);
        switch (store.fetchState) {
            case FetchState.NOT_STARTED:
                checkFeatureFlagInitialized(mailboxInfo);

                // Make sure we do this in a separate microtask as this will call a mutator
                // and this getter should not call mutators to avoid mutators being called
                // within mutators
                Promise.resolve().then(() => {
                    lazyFetchCopilotSettings.importAndExecute(mailboxInfo);
                });
            /* falls through to IN_Progress stage*/
            case FetchState.IN_PROGRESS:
                if (store.isEnabled) {
                    // The copilot settings has existed before, but the service call is in progress.
                    // We should still check the feature enablement based on the existing settings.
                    return checkFunc(
                        store,
                        language,
                        scenario,
                        mailboxInfo,
                        skipLanguageCheck,
                        shouldIgnoreUserPreferences
                    );
                } else {
                    // The copilot settings has not existed before, and the service call is in progress.
                    // We should return undefined to indicate that the feature enablement is unknown.
                    logUsage(
                        'Copilot_CheckedBeforeStorePopulated',
                        {
                            funcName,
                            scenario_1: scenario,
                            state_2: toJS(store.fetchState),
                        },
                        {
                            variant: 2,
                        }
                    );
                    return undefined; //Need a separate undefined state when service call is in progress or not started so that perf optimizations can be made in the calling code
                }
            case FetchState.SUCCEEDED:
            case FetchState.FAILED:
                return checkFunc(
                    store,
                    language,
                    scenario,
                    mailboxInfo,
                    skipLanguageCheck,
                    shouldIgnoreUserPreferences
                );
            case FetchState.STARTUPCACHE:
                // If the fetch state is STARTUPCACHE, we will use the current store but we still fire as async fetch copilot setting without waiting for it to complete
                checkFeatureFlagInitialized(mailboxInfo);
                // Make sure we do this in a separate microtask as this will call a mutator
                // and this getter should not call mutators to avoid mutators being called
                // within mutators
                Promise.resolve().then(() => {
                    lazyFetchCopilotSettings.importAndExecute(mailboxInfo);
                });
                return checkFunc(
                    store,
                    language,
                    scenario,
                    mailboxInfo,
                    skipLanguageCheck,
                    shouldIgnoreUserPreferences
                );
        }
    };
}

function checkFeatureFlagInitialized(mailboxInfo: MailboxInfo): void {
    if (!areFeatureFlagsInitialized(mailboxInfo)) {
        // Log a grey error to collect a callstack before the async jump
        const isPrimaryAccount =
            getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo()) ===
            getIndexerValueForMailboxInfo(mailboxInfo);
        logGreyError('FetchCopilotSettingsWithoutFF', undefined /* error */, {
            isPrimaryAccount,
        });
    }
}
