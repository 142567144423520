import type { MailboxInfoSource } from 'owa-client-types';

// Define the sourceId used for the place holder mailbox info
const anonymousSourceId = '00000000-0000-0000-0000-000000000000';

// The one and only anonymous mailbox info
const anonymousMailboxInfo: MailboxInfoSource = Object.freeze({
    sourceId: anonymousSourceId,
    type: 'UserMailbox',
    userIdentity: '',
    mailboxSmtpAddress: '',
    isAnonymous: true,
    diagnosticData: 'AnonymousMailboxInfo',
    mailboxRank: 'Anonymous',
    mailboxProvider: 'Unknown',
});

/**
 * Gets the Anonymous MailboxInfo
 * @returns The Anonymous MailboxInfo for the application
 */
export function getAnonymousMailboxInfo(): MailboxInfoSource {
    return anonymousMailboxInfo;
}
