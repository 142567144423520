import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailListJumpToLazy"*/ './lazyIndex'),
    { name: 'MailListJumpToLazy' }
);

// Delayed Loaded Components
export const LazyJumpToDateCalloutContent = createLazyComponent(
    lazyModule,
    m => m.JumpToDateCalloutContent
);
