import { createStore } from 'owa-satcheljs';
import type {
    GroupFolderRulePermission,
    GroupFolderRulePermissionByMailboxInfo,
} from './schema/GroupFolderRulePermissionStore';
import type { AccountKey } from 'owa-client-types';

export const getGroupFolderRulePermissionStore =
    createStore<GroupFolderRulePermissionByMailboxInfo>('GroupFolderRulePermission', {
        groupFolderRulePermissionByMailboxInfo: new Map<AccountKey, GroupFolderRulePermission>(),
    });
