import { mutatorAction } from 'satcheljs';
import getStore from '../store/Store';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateShouldShowSelectedRowInRp = mutatorAction(
    'updateShouldShowSelectedRowInRp',
    (shouldShowSelectedRowInRp: boolean) => {
        getStore().shouldShowSelectedRowInRp = shouldShowSelectedRowInRp;
    }
);
