import { getFolderIdForSelectedNode } from 'owa-mail-folder-forest-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';

export const customRestriction =
    (
        customRestrictionFunction: (
            selectedFolderDistinguishedId: DistinguishedFolderIdName
        ) => boolean
    ) =>
    () => {
        const selectedFolderId = getFolderIdForSelectedNode();
        return customRestrictionFunction(folderIdToName(selectedFolderId));
    };
