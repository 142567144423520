import type MailboxInfo from './MailboxInfo';

/**
 * Archive mailbox infos will have the type 'ArchiveMailbox'
 */
export interface ArchiveMailboxInfo extends MailboxInfo {
    readonly type: 'ArchiveMailbox';
}

/**
 * Predicate function to check if the mailboxInfo is an ArchiveMailboxInfo
 */
export function isArchiveMailboxInfo(mailboxInfo: MailboxInfo): mailboxInfo is ArchiveMailboxInfo {
    return mailboxInfo.type === 'ArchiveMailbox';
}
