import { lazyRegisterDataCollectorIfNotRegistered } from 'diagnostics-data-collection';
import {
    getAccountByMailboxInfo,
    getCoprincipalAccountForAccountKey,
} from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';
import { logUsage } from 'owa-analytics';
import type { AccountKey, MailboxInfo } from 'owa-client-types';
import {
    getAccountKeyForMailboxInfo,
    isSameCoprincipalAccountByAccountKeys,
} from 'owa-client-types';
import {
    lazyGetMailboxStatus,
    lazyHandleSyncHealthCheckResponse,
} from 'owa-cloudcache-sync-healthcheck';
import { isFeatureEnabled, isAccountFeatureEnabled } from 'owa-feature-flags';
import loc from 'owa-localize';
import type {
    MailboxNotificationSubscription,
    NotificationCallback,
} from 'owa-notification-manager';
import {
    lazyLogNotificationConnectionStatus,
    lazySubscribe,
    NotificationType,
} from 'owa-notification-manager';
import type NotificationPayloadBase from 'owa-service/lib/contract/NotificationPayloadBase';
import { getAccountScopeUserSettings, isShadowMailboxUser } from 'owa-session-store';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { type OwaWorkload, getOwaWorkload } from 'owa-workloads';
import activityFeedNotificationAction from './actions/activityFeedNotificationAction';
import {
    agentMessagesNotificationAction,
    flexibleWorkingHoursNotificationAction,
} from 'owa-app-notifications-actions';
import initializeNotificationsPaneForAccount from './actions/initializeNotificationsPaneForAccountAction';
import reactionNotificationAction from './actions/reactionNotificationAction';
import reminderNotificationAction from './actions/reminderNotificationAction';
import { notificationChannelDiagnosticDataDescription } from './strings/notificationDiagnosticStrings.locstring.json';
import areNotificationsEnabledForAccount from 'owa-notifications-settings/lib/selectors/areNotificationsEnabledForAccount';

const PAYLOADS_RECEIVED_LOGGING_GAP: number = 20;

const subscriptionInitializedAccounts: AccountKey[] = [];
let payloadsReceived = 0;

export async function subscribeToNotifications(mailbox: MailboxInfo) {
    registerDataCollectors();
    const accountKey = getAccountKeyForMailboxInfo(mailbox);
    if (
        subscriptionInitializedAccounts.find(key =>
            isSameCoprincipalAccountByAccountKeys(key, accountKey)
        )
    ) {
        // Already subscribed for this account so return
        return;
    }
    subscriptionInitializedAccounts.push(accountKey);

    // Reminders subscription
    const reminderSubscription: MailboxNotificationSubscription = createSubscription(
        NotificationType.ReminderNotification,
        mailbox,
        'ReminderNotifications'
    );
    await lazySubscribe.importAndExecute(
        reminderSubscription,
        (notification: NotificationPayloadBase, mailboxInfo: MailboxInfo) =>
            logAndActOnNotificationReceived(reminderNotificationAction, notification, mailboxInfo)
    );

    const accountSource = getCoprincipalAccountForAccountKey(accountKey);
    if (
        isAccountFeatureEnabled('auth-remoteSyncNotification', mailbox) &&
        accountSource &&
        isShadowMailboxUser(mailbox)
    ) {
        const remoteSyncNotification: MailboxNotificationSubscription = createSubscription(
            NotificationType.RemoteSyncNotification,
            mailbox
        );
        const syncFailed = 99;
        const syncAutoSuspended = 3;
        await lazySubscribe.importAndExecute(
            remoteSyncNotification,
            async (notification: NotificationPayloadBase, mailboxInfo: MailboxInfo) => {
                const syncNotification = JSON.parse(JSON.stringify(notification));
                // For now, we act only when the syncHealth Status is either Failed (99) or AutoSuspended (3).
                // Coming from the Enum RequestStatus defined in
                // https://o365exchange.visualstudio.com/O365%20Core/_git/Substrate?path=/sources/dev/data/src/directory/Recipient/EnumTypes.cs&version=GBmaster&line=1567&lineEnd=1567&lineStartColumn=17&lineEndColumn=30&lineStyle=plain&_a=contents
                if (
                    syncNotification.Status == syncFailed ||
                    syncNotification.Status == syncAutoSuspended
                ) {
                    const mailboxStatus = await lazyGetMailboxStatus.importAndExecute(
                        syncNotification.Status
                    );
                    lazyHandleSyncHealthCheckResponse.importAndExecute(mailboxInfo, {
                        Status: mailboxStatus,
                        FailureType: syncNotification.FailureType,
                        LastSuccessfulSyncTimestamp: syncNotification.LastSuccessfulSyncTimestamp,
                        LastUpdateTimestamp: syncNotification.LastUpdateTimestamp,
                    });
                }
            }
        );
    }

    // Notifications (activity feed) subscription
    if (
        !isConsumer(undefined, mailbox) ||
        (isFeatureEnabled('notif-showNotificationsForMSA') &&
            getAccountByMailboxInfo(mailbox)?.sourceType === AccountSourceType.OutlookDotCom)
    ) {
        initNotificationsPaneAndSubscribeToNotifications(
            false /* isOpx */,
            mailbox,
            (notification: NotificationPayloadBase, mailboxInfo: MailboxInfo) =>
                logAndActOnNotificationReceived(
                    reactionNotificationAction,
                    notification,
                    mailboxInfo
                ),
            (notification: NotificationPayloadBase, mailboxInfo: MailboxInfo) =>
                logAndActOnNotificationReceived(
                    activityFeedNotificationAction,
                    notification,
                    mailboxInfo
                )
        );
    } else {
        // Even though we're not subscribing to the notifications feed for this account, we
        // still need to add the account to the notifications store. Some of the UI checks
        // notifications for this account even though it has no feed; for example, the bell badge count,
        // saving settings, and the empty feed UI.
        initializeNotificationsPaneForAccount(false /* isOpx */, mailbox);
    }

    // Flexible working hours subscription
    subscribeToFlexibleWorkingHoursNotification(mailbox);

    if (isFeatureEnabled('cal-agent-messages-store')) {
        subscribeToAgentMessagesNotification(mailbox);
    }
}

export function logAndActOnNotificationReceived(
    notificationCallback: NotificationCallback,
    notification: NotificationPayloadBase,
    mailboxInfo: MailboxInfo
) {
    payloadsReceived++;

    if (payloadsReceived % PAYLOADS_RECEIVED_LOGGING_GAP == 0) {
        logUsage(
            'SignalRNotification',
            {
                PayloadsReceivedCount: payloadsReceived,
            },
            {
                mailbox: mailboxInfo,
            }
        );
    }
    if (
        notificationCallback === reminderNotificationAction || // Reminders are cached and shown later so check if they're enabled at show time
        areNotificationsEnabledForAccount(mailboxInfo)
    ) {
        if (notificationCallback === reminderNotificationAction) {
            logUsage('Reminder-notificationReceived');
        }
        notificationCallback(notification, mailboxInfo);
    }
}

export function initNotificationsPaneAndSubscribeToNotifications(
    isOpx: boolean,
    mailboxInfo: MailboxInfo,
    reactionCallback: NotificationCallback,
    activityFeedNotificationCallBack: NotificationCallback
) {
    // Initialize the notifications pane and settings for this mailbox.
    initializeNotificationsPaneForAccount(isOpx, mailboxInfo, async () => {
        if (
            (isOpx || getOwaWorkload() == 1) &&
            /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
             * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
             *	> The parameter mailboxInfo must be undefined. Feature flight: 'rp-reactions' */
            isFeatureEnabled('rp-reactions', mailboxInfo)
        ) {
            const reactionNotification: MailboxNotificationSubscription = createSubscription(
                NotificationType.ReactionNotification,
                mailboxInfo
            );
            await lazySubscribe.importAndExecute(reactionNotification, reactionCallback);
        }

        const activityFeedNotification: MailboxNotificationSubscription = createSubscription(
            NotificationType.ActivityFeedNotification,
            mailboxInfo
        );
        await lazySubscribe.importAndExecute(
            activityFeedNotification,
            activityFeedNotificationCallBack
        );
    });
}

export function subscribeToFlexibleWorkingHoursNotification(mailbox: MailboxInfo) {
    const flexibleWorkingHoursSubscription: MailboxNotificationSubscription = createSubscription(
        NotificationType.FlexibleWorkingHoursNotification,
        mailbox
    );
    lazySubscribe.importAndExecute(
        flexibleWorkingHoursSubscription,
        (notification: NotificationPayloadBase, mailboxInfo: MailboxInfo) =>
            logAndActOnNotificationReceived(
                flexibleWorkingHoursNotificationAction,
                notification,
                mailboxInfo
            )
    );
}

export function subscribeToAgentMessagesNotification(mailbox: MailboxInfo) {
    const AgentMesssagesSubscription: MailboxNotificationSubscription = createSubscription(
        NotificationType.CalendarAINotification,
        mailbox
    );

    lazySubscribe.importAndExecute(
        AgentMesssagesSubscription,
        (notification: NotificationPayloadBase, mailboxInfo: MailboxInfo) =>
            logAndActOnNotificationReceived(
                agentMessagesNotificationAction,
                notification,
                mailboxInfo
            )
    );
}

export function createSubscription(
    notificationType: NotificationType,
    mailboxInfo: MailboxInfo,
    id?: string
): MailboxNotificationSubscription {
    const isSenderScreeningEnabled =
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'tri-sender-screening' */
        isFeatureEnabled('tri-sender-screening', mailboxInfo) &&
        getAccountScopeUserSettings(mailboxInfo).UserOptions?.IsSenderScreeningSettingEnabled;

    return {
        subscriptionId: id || notificationType,
        requiresExplicitSubscribe: true,
        subscriptionParameters: {
            NotificationType: notificationType,
            SenderScreeningFilter: isSenderScreeningEnabled ? 'ScreenedSenders' : undefined,
        },
        mailboxInfo,
    };
}

async function registerDataCollectors() {
    const notificationChannelDataCollector = {
        dataCollectionAction: notificationChannelDataCollectionAction,
        name: 'NotificationChannelData',
        odsFileDescription: 'Notification channel data',
        description: loc(notificationChannelDiagnosticDataDescription),
    };

    await lazyRegisterDataCollectorIfNotRegistered.importAndExecute(
        notificationChannelDataCollector
    );
}

function notificationChannelDataCollectionAction(): Promise<string> {
    return lazyLogNotificationConnectionStatus.importAndExecute();
}
