import { isMailboxSharedOrDelegate } from 'owa-account-shared-mailbox-utils';
import { type MailboxInfo } from 'owa-client-types';
import type {
    FavoriteData,
    FavoriteCategoryData,
    FavoriteFolderData,
    FavoritePersonaData,
    FavoriteGroupData,
    FavoriteSearchData,
    FavoritePublicFolderData,
    OutlookFavoriteKind,
} from 'owa-favorites-types';

export function getUniqueDataForFavoriteType(favoriteData: FavoriteData): string {
    let uniqueId;
    switch (favoriteData.type) {
        case 'localFolderArchive':
        case 'localFolderShared':
        case 'localFolderPst':
        case 'folder':
            uniqueId = (favoriteData as FavoriteFolderData).folderId;
            break;
        case 'category':
            uniqueId = (favoriteData as FavoriteCategoryData).categoryId;
            break;
        case 'persona':
            uniqueId = (favoriteData as FavoritePersonaData).mainEmailAddress;
            break;
        case 'group':
            uniqueId = (favoriteData as FavoriteGroupData).groupSmtp;
            break;
        case 'search':
            uniqueId = (favoriteData as FavoriteSearchData).searchQuery;
            break;
        case 'publicFolder':
            uniqueId = (favoriteData as FavoritePublicFolderData).publicFolderId;
            break;
        case 'news':
            break; // News is not supported in combined favorites
        default:
            break; // Unknown favorite type, will be logged in loadOutlookFavorites
    }
    return uniqueId ?? '';
}

export function getOutlookFavoriteTypeFromMailboxInfo(
    mailboxInfo: MailboxInfo
): OutlookFavoriteKind {
    switch (mailboxInfo.type) {
        case 'ArchiveMailbox':
            return 'localFolderArchive';
        case 'SharedMailbox':
            return 'localFolderShared';
        case 'PstFile':
            return 'localFolderPst';
        case 'UserMailbox':
            if (isMailboxSharedOrDelegate(mailboxInfo)) {
                return 'localFolderShared';
            }
            return 'folder';
        default:
            return 'folder';
    }
}
