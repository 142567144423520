import { LazyModule, LazyAction, createLazyComponent } from 'owa-bundling';

export const lazyModule = new LazyModule(() => import('./lazyIndex'), {
    name: 'OwaOfficeaiCopilotChatShared',
});

export const lazyOpenCopilotChatAndAddPrompt = new LazyAction(
    lazyModule,
    m => m.openCopilotChatAndAddPrompt
);
export const lazyAddChatInitialConversation = new LazyAction(
    lazyModule,
    m => m.addChatInitialConversation
);
export const lazyConstructCopilotChatPromptFromText = new LazyAction(
    lazyModule,
    m => m.constructCopilotChatPromptFromText
);

export const lazyOpenCopilotChatAndSubmitPodcastRequest = new LazyAction(
    lazyModule,
    m => m.openCopilotChatAndSubmitPodcastRequest
);

export const LazyCopilotChatImagePopup = createLazyComponent(
    lazyModule,
    m => m.CopilotChatImagePopup
);
