import type { ImplementedCopilotFeatures, CopilotSettingsStore } from 'owa-copilot-settings-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import checkLanguage from './checkLanguage';
import createStoreCheckForGenericEnablement from './createStoreCheckForGenericEnablement';
import type { MailboxInfo } from 'owa-client-types';

type TonePersonalizationScenarios = Extract<ImplementedCopilotFeatures, 'Elaborate'>;
const isTonePersonalizationEnabled = createStoreCheckForGenericEnablement<
    TonePersonalizationScenarios,
    boolean
>('TonePersonalization', isTonePersonalizationEnabledInternal);

export default isTonePersonalizationEnabled;

function isTonePersonalizationEnabledInternal(
    store: CopilotSettingsStore,
    language: string,
    scenario: TonePersonalizationScenarios,
    mailboxInfo: MailboxInfo
): boolean {
    if (store.isEnabled) {
        const featureEnabled = store.featureInfo.get(scenario);
        if (featureEnabled?.isEnabled) {
            return (
                checkLanguage(language, ['en']) && // only enabled for english currently
                /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
                 * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
                 *	> The parameter mailboxInfo must be undefined. Feature flight: 'mon-copilot-elaborate-tonePersonalization' */
                isFeatureEnabled('mon-copilot-elaborate-tonePersonalization', mailboxInfo) &&
                !!featureEnabled.capabilities &&
                'tonePersonalization' in featureEnabled.capabilities &&
                featureEnabled.capabilities.tonePersonalization
            );
        }
    }
    return false;
}
