import { composeStore } from 'owa-mail-compose-store';
import getSelectedTableView from 'owa-mail-list-store/lib/utils/getSelectedTableView';
import { getConversationId } from 'owa-mail-list-store/lib/selectors/mailRowDataPropertyGetter';

/**
 * Finds the compose ID that was triggered by an add-in's send event.
 *
 * This function checks if the primary compose ID has an add-in triggered send event
 * and if the last auto-focused row key is different from the currently focused row key.
 * If so, it returns the primary compose ID.
 *
 * If the primary compose ID does not meet the criteria, it iterates through all compose view states
 * to find any compose ID with an add-in triggered send event and returns it.
 *
 * @returns {string | undefined} The compose ID that was triggered by an add-in's send event, or undefined if none is found.
 */
export function findAddinTriggeredComposeId(): string | undefined {
    const { primaryComposeId, viewStates } = composeStore;

    if (primaryComposeId) {
        const tableView = getSelectedTableView();
        const primaryComposeViewState = viewStates.get(primaryComposeId);
        if (primaryComposeViewState) {
            const isOnSendEventTriggered = primaryComposeViewState.addin.isOnSendEventTriggered;
            const lastAutoFocusedRowKey = tableView?.lastAutoFocusedRowKey;
            const focusedRowKey = tableView?.focusedRowKey;
            const conversationIdFromRowKey =
                tableView && getConversationId(focusedRowKey ?? '', tableView);

            if (isOnSendEventTriggered) {
                // If lastAutoFocusedRowKey is null, then differentiate items based on conversationId
                // this happens when the user logs in and the lastAutoFocusedRowKey is not set
                if (
                    lastAutoFocusedRowKey === null &&
                    primaryComposeViewState.conversationId === conversationIdFromRowKey
                ) {
                    return undefined;
                }

                if (lastAutoFocusedRowKey !== focusedRowKey) {
                    return primaryComposeId;
                }
            }
        }
    } else {
        // If primaryComposeId is not set, iterate through all compose view states to find any compose ID with an add-in triggered send event
        for (const [composeId, viewState] of viewStates) {
            if (viewState.addin.isOnSendEventTriggered) {
                return composeId;
            }
        }
    }

    return undefined;
}
