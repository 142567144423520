import loc from 'owa-localize';
import { appCalendar } from 'owa-locstrings/lib/strings/appcalendar.locstring.json';
import { appFiles } from 'owa-locstrings/lib/strings/appFiles.locstring.json';
import { appGroups } from 'owa-locstrings/lib/strings/appgroups.locstring.json';
import { appPeople } from 'owa-locstrings/lib/strings/apppeople.locstring.json';
import { appTasks } from 'owa-locstrings/lib/strings/apptasks.locstring.json';
import { appToDo } from 'owa-locstrings/lib/strings/apptodo.locstring.json';
import { mailAppName } from 'owa-locstrings/lib/strings/mailAppName.locstring.json';

import word_24x from 'owa-appbar-icons/lib/word_24x.svg';
import excel_24x from 'owa-appbar-icons/lib/excel_24x.svg';
import powerpoint_24x from 'owa-appbar-icons/lib/powerpoint_24x.svg';
import onenote_24x from 'owa-appbar-icons/lib/onenote_24x.svg';
import todo_metaos_32 from 'owa-appbar-icons/lib/todo_metaos_32.png';
import onedrive_24x from 'owa-appbar-icons/lib/onedrive_24x.svg';
import Mail_22px from 'owa-appbar-icons/lib/Mail_22px.svg';
import Calendar_22px from 'owa-appbar-icons/lib/Calendar_22px.svg';
import People_22px from 'owa-appbar-icons/lib/People_22px.svg';
import Files_22px from 'owa-appbar-icons/lib/Files_22px.svg';
import { createNativeTitle } from '../createNativeTitle';
import { getAppBposData } from '../../utils/getAppBposData';
import { M365AppId } from '../M365AppId';
import { getCdnUrl } from 'owa-config/lib/bootstrapOptions';

import type { StrictTitle } from '../../types';
import type { MailboxInfo } from 'owa-client-types';

const getMail = () =>
    createNativeTitle({
        id: M365AppId.Mail,
        nameResolver: () => loc(mailAppName) || 'Mail',
        icons: {
            normalIcon: Mail_22px,
            outlineIcon: `https:${getCdnUrl()}assets/metaos/mail_monoline_20px.svg`,
        },
        platformType: 'outlook_app',
    });

const getCalendar = () =>
    createNativeTitle({
        id: M365AppId.Calendar,
        nameResolver: () => loc(appCalendar) || 'Calendar',
        icons: {
            normalIcon: Calendar_22px,
            outlineIcon: `https:${getCdnUrl()}assets/metaos/calendar_monoline_20px.svg`,
        },
        platformType: 'outlook_app',
    });

const getPeople = () => {
    return createNativeTitle({
        id: M365AppId.People,
        nameResolver: () => loc(appPeople) || 'People',
        icons: {
            normalIcon: People_22px,
            outlineIcon: `https:${getCdnUrl()}assets/metaos/people_monoline_20px.svg`,
        },
        platformType: 'outlook_app',
    });
};

const getGroups = () => {
    return createNativeTitle({
        id: M365AppId.Groups,
        nameResolver: () => loc(appGroups) || 'Groups',
        icons: {
            normalIcon: `https:${getCdnUrl()}assets/groups/icons/v2/GroupsNew.svg`,
            outlineIcon: `https:${getCdnUrl()}assets/groups/icons/v2/GroupsNewOutline.svg`,
        },
        platformType: 'outlook_app',
    });
};

const getFiles = () => {
    return createNativeTitle({
        id: M365AppId.Files,
        nameResolver: () => loc(appFiles) || 'Files',
        icons: {
            normalIcon: Files_22px,
            outlineIcon: `https:${getCdnUrl()}assets/metaos/files_monoline_20px.svg`,
        },
        platformType: 'outlook_app',
    });
};

const getToDo = () =>
    createNativeTitle({
        id: M365AppId.ToDo,
        nameResolver: () => loc(appToDo) || 'To Do',
        icons: {
            normalIcon: todo_metaos_32,
            outlineIcon:
                'https://statics.teams.cdn.office.net/evergreen-assets/apps/0d5c91ee-5be2-4b79-81ed-23e6c4580427_smallImage.png?v=2',
        },
        platformType: 'outlook_app',
    });

const getTasks = () =>
    createNativeTitle({
        id: M365AppId.Tasks,
        nameResolver: () => loc(appTasks) || 'Tasks',
        icons: {
            normalIcon: `https:${getCdnUrl()}assets/metaos/tasks_monoline_20px.svg`,
            outlineIcon: `https:${getCdnUrl()}assets/metaos/tasks_monoline_20px.svg`,
        },
        platformType: 'outlook_app',
    });

const getWord = (mailboxInfo?: MailboxInfo) =>
    createNativeTitle({
        id: M365AppId.Word,
        nameResolver: () => getAppBposData(M365AppId.Word, mailboxInfo)?.Title ?? 'Word',
        icons: {
            normalIcon: word_24x,
            outlineIcon:
                'https://statics.teams.cdn.office.net/evergreen-assets/apps/d7958adf-f419-46fa-941b-1b946497ef84_smallImage.png?v=1.0.3',
        },
        platformType: 'm365_native_app',
    });

const getExcel = (mailboxInfo?: MailboxInfo) =>
    createNativeTitle({
        id: M365AppId.Excel,
        nameResolver: () => getAppBposData(M365AppId.Excel, mailboxInfo)?.Title ?? 'Excel',
        icons: {
            normalIcon: excel_24x,
            outlineIcon:
                'https://statics.teams.cdn.office.net/evergreen-assets/apps/Excel20x20_smallimage.png?v=0.0.2',
        },
        platformType: 'm365_native_app',
    });

const getPowerpoint = (mailboxInfo?: MailboxInfo) =>
    createNativeTitle({
        id: M365AppId.PowerPoint,
        nameResolver: () =>
            getAppBposData(M365AppId.PowerPoint, mailboxInfo)?.Title ?? 'Powerpoint',
        icons: {
            normalIcon: powerpoint_24x,
            outlineIcon:
                'https://statics.teams.cdn.office.net/evergreen-assets/apps/Powerpoint20x20_smallimage.png?v=0.0.2',
        },
        platformType: 'm365_native_app',
    });

const getOneNote = (mailboxInfo?: MailboxInfo) =>
    createNativeTitle({
        id: M365AppId.OneNote,
        nameResolver: () => getAppBposData(M365AppId.OneNote, mailboxInfo)?.Title ?? 'OneNote',
        icons: {
            normalIcon: onenote_24x,
            outlineIcon:
                'https://statics.teams.cdn.office.net/evergreen-assets/apps/onenote_v2_smallimage.png?v=1.0.0',
        },
        platformType: 'm365_native_app',
    });

const getOneDrive = (mailboxInfo?: MailboxInfo) =>
    createNativeTitle({
        id: M365AppId.OneDrive,
        nameResolver: () => getAppBposData(M365AppId.OneDrive, mailboxInfo)?.Title ?? 'OneDrive',
        icons: {
            normalIcon: onedrive_24x,
            outlineIcon:
                'https://statics.teams.cdn.office.net/app-assets/ring1/5af6a76b-40fc-4ba1-af29-8f49b08e44fd/5af6a76b-40fc-4ba1-af29-8f49b08e44fd_smallImage.png?v=1.0.21',
        },
        platformType: 'm365_native_app',
    });

const getOutlookTitles = () => [
    getCalendar(),
    getFiles(),
    getGroups(),
    getMail(),
    getPeople(),
    getTasks(),
    getToDo(),
];
const getOffice365Titles = (mailboxInfo?: MailboxInfo): StrictTitle[] => [
    getExcel(mailboxInfo),
    getPowerpoint(mailboxInfo),
    getOneNote(mailboxInfo),
    getOneDrive(mailboxInfo),
    getWord(mailboxInfo),
];
const getNativeTitles = (mailboxInfo?: MailboxInfo): StrictTitle[] => [
    ...getOutlookTitles(),
    ...getOffice365Titles(mailboxInfo),
];

export { getNativeTitles };
