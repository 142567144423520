import { mutatorAction } from 'satcheljs';
import getListViewState from '../selectors/getListViewState';

export const setShouldAutoOpenJumpToMutator = mutatorAction(
    'setShouldAutoOpenJumpToMutator',
    (value: boolean) => {
        const listViewState = getListViewState();

        listViewState.shouldAutoOpenJumpTo = value;
    }
);
