import type { TimeZoneRange } from './TimeZoneRange';
import { MAX_JAVASCRIPT_TIMESTAMP, MIN_JAVASCRIPT_TIMESTAMP } from 'owa-date-constants';
import type TimeZoneEntry from 'owa-service/lib/contract/TimeZoneEntry';

import { createStore } from 'owa-satcheljs';
export type LoadState =
    | 'Uninitialized'
    | 'Initializing'
    | 'Initialized'
    | 'RequestFailure'
    | 'ResponseEmpty'
    | 'Skipped';

export let timeZoneRangeLoadState = 'Uninitialized';

export function setTimeZoneRangeLoadState(loadState: LoadState) {
    timeZoneRangeLoadState = loadState;
}

export const getStore = createStore('owaDateTime', {
    AllTimeZones: [] as TimeZoneEntry[],
    DateFormat: '',
    LocalTimeZone: 'UTC',
    TimeFormat: '',
    TimeZoneRanges: {
        UTC: [
            {
                start: MIN_JAVASCRIPT_TIMESTAMP,
                end: MAX_JAVASCRIPT_TIMESTAMP,
                localStart: MIN_JAVASCRIPT_TIMESTAMP,
                localEnd: MAX_JAVASCRIPT_TIMESTAMP,
                offset: 0,
            },
        ],
    } as {
        [timeZoneId: string]: TimeZoneRange[];
    },
    TimeZoneAlternateNames: new Map<string, string[]>(),
});
