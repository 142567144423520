import { getGroupIdFromTableQuery, isGroupTableQuery, lazyIsGroupOwner } from 'owa-group-utils';
import type { TableView } from 'owa-mail-list-store';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import { doesUserHaveSharedFolderPermissionFor } from '../actions/getMailMenuItemShouldShowMap';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import getMailboxInfo from 'owa-mail-mailboxinfo/lib/getMailboxInfo';
import { isCapabilitySupported } from 'owa-capabilities';
import { mailboxModificationCapability } from 'owa-capabilities-definitions/lib/mailboxModificationCapability';

export default function shouldAllowDelete(tableView: TableView, rowKey?: string): boolean {
    if (!isCapabilitySupported(mailboxModificationCapability, getMailboxInfo(tableView))) {
        return false;
    }

    // Deletion is disabled in a shared folder and user doesn't have delete permissions
    if (!doesUserHaveSharedFolderPermissionFor(12)) {
        return false;
    }

    const tableQuery = tableView.tableQuery;

    if (isGroupTableQuery(tableQuery) && rowKey) {
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * BASELINE. Do not copy and paste!
         *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
        const isGroupOwner = lazyIsGroupOwner.tryImportForRender();
        if (
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2345 (19,29): Argument of type 'string | null' is not assignable to parameter of type 'string'.
            // @ts-expect-error
            !isGroupOwner?.(getGroupIdFromTableQuery(tableQuery)) &&
            !MailRowDataPropertyGetter.getCanDelete(rowKey, tableView)
        ) {
            return false;
        }
    }

    return true;
}
