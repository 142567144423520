import { mutatorAction } from 'satcheljs';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import getItemReadingPaneViewState from '../utils/getItemReadingPaneViewState';
import type ConversationReadingPaneViewState from '../store/schema/ConversationReadingPaneViewState';
import type ItemReadingPaneViewState from '../store/schema/ItemReadingPaneViewState';
import mailStore from 'owa-mail-store/lib/store/Store';
import { addReadingPaneLog } from 'owa-mail-reading-pane-logging';

export let delayShimmerTimer: ReturnType<typeof setTimeout> | undefined;

const setShowLoadingShimmerMutator = mutatorAction(
    'setShowLoadingShimmer',
    (viewState: ConversationReadingPaneViewState | ItemReadingPaneViewState) => {
        if (viewState?.loadingState) {
            viewState.loadingState.showLoadingShimmer = true;
        }
    }
);

export function setShowLoadingShimmerOnDelay(id: string, isConversation: boolean) {
    delayShimmerTimer = setTimeout(() => {
        //If after timeout, mail store still does not have the loaded conversation or item and it is still loading, set showLoadingShimmer to true.
        const loadedConvOrItem = id
            ? isConversation
                ? mailStore.conversations.get(id)
                : mailStore.items.get(id)
            : undefined;
        const viewState = isConversation
            ? getConversationReadingPaneViewState(id)
            : getItemReadingPaneViewState(id);
        if (
            (viewState?.loadingState.isLoading && !viewState.loadingState.hasLoadFailed) ||
            !loadedConvOrItem
        ) {
            addReadingPaneLog('ShimmerShown', {
                id,
            });
            setShowLoadingShimmerMutator(viewState);
        }
    }, 350);
}

export function clearDelayShimmerTimer() {
    if (delayShimmerTimer) {
        clearTimeout(delayShimmerTimer);
        delayShimmerTimer = undefined;
    }
}
