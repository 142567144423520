import { mutatorAction } from 'satcheljs';
import brandSubscriptionStore from '../store/Store';
import getBrandsSubscriptionsService from '../services/getBrandsSubscriptionsService';
import { getSubscriptionFromCache } from '../selectors/subscriptionsCacheOperations';
import type { MailboxInfo } from 'owa-client-types';
import { trace } from 'owa-trace';
import type BrandSubscriptionModel from '../store/schema/BrandSubscriptionModel';

export default (smtpAddress: string, mailboxInfo: MailboxInfo) => {
    const subscription = getSubscriptionFromCache(smtpAddress);
    if (subscription) {
        unsubscribeFromCache(subscription, smtpAddress);
    } else {
        // If there is no item in Cache, maybe it got stale so reload the Cache
        getBrandsSubscriptionsService(mailboxInfo, undefined /* dontLoadBrandsInfo */).catch(
            error => trace.warn(error)
        );
    }
};

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const unsubscribeFromCache = mutatorAction(
    'unsubscribeFromCache',
    (subscription: BrandSubscriptionModel, smtpAddress: string) => {
        brandSubscriptionStore.subscriptions.delete(smtpAddress.toLowerCase());
        brandSubscriptionStore.unsubscribedSubscriptions.set(
            smtpAddress.toLowerCase(),
            subscription
        );
    }
);
