import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/findInMessageStore';
import getTotalNumberOfMatches from '../selectors/getTotalNumberOfMatches';
import type { LastActionPerformed } from '../store/schema/LastActionPerformed';

export const showFindInMessageCalloutMutator = mutatorAction(
    'showFindInMessageCalloutMutator',
    (context: string) => {
        const store = getStore();
        const viewState = store.findInMessageViewStates.get(context);
        if (viewState) {
            viewState.isCalloutVisible = true;
        }
    }
);

export const hideFindInMessageCalloutMutator = mutatorAction(
    'hideFindInMessageCalloutMutator',
    (context: string) => {
        const store = getStore();
        const viewState = store.findInMessageViewStates.get(context);
        if (viewState) {
            viewState.isCalloutVisible = false;
        }
    }
);

export const removeContextFromStoreMutator = mutatorAction(
    'removeContextFromStoreMutator',
    (context: string) => {
        const store = getStore();
        store.findInMessageViewStates.delete(context);
    }
);

export const setSearchStringMutator = mutatorAction(
    'setSearchStringMutator',
    (context: string, searchString: string) => {
        const store = getStore();
        const viewState = store.findInMessageViewStates.get(context);
        if (viewState) {
            viewState.searchString = searchString;
        }
    }
);

export const setNumberOfMatchesMutator = mutatorAction(
    'setNumberOfMatchesMutator',
    (context: string, numberOfMatches: number) => {
        const store = getStore();
        const viewState = store.findInMessageViewStates.get(context);
        if (viewState) {
            viewState.numberOfMatches = numberOfMatches;
        }
    }
);

export const setNumberOfMatchesPerItemIdMutator = mutatorAction(
    'setNumberOfMatchesPerItemIdMutator',
    (context: string, itemId: string, numberOfMatches: number) => {
        const store = getStore();
        const viewState = store.findInMessageViewStates.get(context);
        if (viewState?.matchesPerItemId) {
            for (const [_, value] of viewState.matchesPerItemId?.entries()) {
                if (value.itemId === itemId) {
                    value.numberOfMatches = numberOfMatches;
                    viewState.numberOfMatches = getTotalNumberOfMatches(context);
                    return;
                }
            }
        }
    }
);

export const setIsExpandedItemPartMutator = mutatorAction(
    'setIsExpandedItemPartMutator',
    (context: string, itemId: string, isExpanded: boolean) => {
        const store = getStore();
        const viewState = store.findInMessageViewStates.get(context);
        if (viewState?.matchesPerItemId) {
            for (const [_, value] of viewState.matchesPerItemId?.entries()) {
                if (value.itemId === itemId) {
                    value.isExpanded = isExpanded;
                    if (!isExpanded) {
                        value.numberOfMatches = 0;
                        viewState.numberOfMatches = getTotalNumberOfMatches(context);
                    }
                    viewState.itemPartCollapsedExpandedIncrementor =
                        (viewState.itemPartCollapsedExpandedIncrementor ?? 0) + 1;
                    return;
                }
            }
        }
    }
);

export const setCurrentMatchIndexMutator = mutatorAction(
    'setCurrentMatchIndexMutator',
    (context: string, currentMatchIndex: number) => {
        const store = getStore();
        const viewState = store.findInMessageViewStates.get(context);
        if (viewState) {
            viewState.currentMatchIndex = currentMatchIndex;
        }
    }
);

export const initializeMatchesPerItemIdMutator = mutatorAction(
    'initializeMatchesPerItemIdMutator',
    (context: string, conversationNodeIds: string[]) => {
        const store = getStore();
        const viewState = store.findInMessageViewStates.get(context);
        if (viewState) {
            conversationNodeIds.forEach(conversationNodeId => {
                viewState.matchesPerItemId?.push({
                    itemId: conversationNodeId,
                    numberOfMatches: 0,
                    isExpanded: true,
                });
            });
        }
    }
);

export const setLastActionPerformedMutator = mutatorAction(
    'setLastActionPerformedMutator',
    (context: string, lastActionPerformed: LastActionPerformed) => {
        const store = getStore();
        const viewState = store.findInMessageViewStates.get(context);
        if (viewState) {
            viewState.lastActionPerformed = lastActionPerformed;
        }
    }
);
