import { writeQuery } from 'owa-apollo';
import { errorThatWillCauseAlert } from 'owa-trace';
import { MessageExtensionsFlyoutDocument } from '../graphql/query/__generated__/MessageExtensionsData.interface';
import {
    MessageExtensionFlyoutTypes,
    getMessageExtensionSurfaceArgs_addins,
} from '../utils/getMessageExtensionSurfaceArgs';
import type { DataProxy } from '@apollo/client';
import type { StrictMessageExtensionsFlyoutQuery, StrictM365ApplicationEdge } from '../types';
import type { MessageExtensionsFlyoutQueryVariables } from '../graphql/query/__generated__/MessageExtensionsData.interface';
import type { IEnabledAddinCommands } from 'owa-addins-types';
import { convertAddinToM365Application } from './convertAddinToM365Application';
import type { MessageExtensionTypeFilterMapAddins } from '../utils/getMessageExtensionSurfaceArgs';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { getAddInAppAcquisitionLink } from '../data/getAddinAppAcquisitionLink';
import { isCapabilityEnabled } from 'owa-capabilities';
import { addinsCapability } from 'owa-capabilities-definitions/lib/addinsCapability';

type MessageExtensionWriteQueryArguments = Omit<
    DataProxy.WriteQueryOptions<
        StrictMessageExtensionsFlyoutQuery,
        MessageExtensionsFlyoutQueryVariables
    >,
    'query'
>;

/**
 * Converts the results of a `m365Acquisitions` query into `m365MessageExtensions` query, which feeds
 * the Message Extension Flyout control.
 * @param queryResult
 * @returns
 */
export function addinsToM365MessageExtensions(
    enabledAddins: IEnabledAddinCommands,
    mailboxInfo?: MailboxInfo
): MessageExtensionWriteQueryArguments[] {
    const writeQueryArgs: MessageExtensionWriteQueryArguments[] = [];
    const MessageExtensionsQueryArgumentMap: MessageExtensionTypeFilterMapAddins =
        getMessageExtensionSurfaceArgs_addins();

    // Create a write query for each possible argument of m365MessageExtensions,
    // i.e., for each MessageExtensionType
    for (let i = 0; i < MessageExtensionFlyoutTypes.length; i++) {
        const messageExtensionType = MessageExtensionFlyoutTypes[i];
        const { appFilter } = MessageExtensionsQueryArgumentMap[messageExtensionType];
        // Write query for each mailbox (e.g. 'ReadMessageExtensions:user@outlook.com')
        const queryMessageExtensionType: string = mailboxInfo
            ? `${messageExtensionType}:${getIndexerValueForMailboxInfo(mailboxInfo)}`
            : messageExtensionType;

        const flyoutSurfaceEdges: StrictM365ApplicationEdge[] = appFilter(enabledAddins)
            .map(convertAddinToM365Application)
            .map(app => ({ node: app, __typename: 'M365ApplicationEdge' }));

        const writeQueryArg: MessageExtensionWriteQueryArguments = {
            data: {
                __typename: 'Query',
                m365MessageExtensions: {
                    __typename: 'M365ApplicationConnection',
                    appAcquisitionLinks: isCapabilityEnabled(addinsCapability, mailboxInfo)
                        ? [getAddInAppAcquisitionLink()]
                        : [],
                    edges: flyoutSurfaceEdges,
                },
            },
            variables: {
                messageExtensionType: queryMessageExtensionType,
            },
        };
        writeQueryArgs.push(writeQueryArg);
    }

    return writeQueryArgs;
}

/**
 * Note: Function below is used as a transitionary step, gated by a feature flag, to the new app catalog
 * schema.
 *
 * Converts a list of acquisitions cache references to its underlying
 * object value, converts to AppDefinition, then to M365Application type and writes to the `m365MessageExtensions`
 * query in Apollo cache.
 * @param enabledAddins incoming list of cached addins to write
 * @param mailboxInfo current flyout account, or undefined if mos-multiAccount is off
 */
export function writeM365MessageExtensionsQueryFromEnabledAddins(
    enabledAddins: IEnabledAddinCommands,
    mailboxInfo?: MailboxInfo
) {
    try {
        const writeQueryArgs: MessageExtensionWriteQueryArguments[] = addinsToM365MessageExtensions(
            enabledAddins,
            mailboxInfo
        );
        /* To effectively write to m365MessageExtensions' cache entry, we need
           to write to all possible argument values for a query's input */
        writeQueryArgs.forEach(writeQueryArg => {
            writeQuery(MessageExtensionsFlyoutDocument, writeQueryArg);
        });
    } catch (error) {
        errorThatWillCauseAlert('AppCatalogCache_WriteMessageExtensionsQuery_Error', error);
    }
}
