import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AccountSourceList" */ './lazyIndex'),
    { name: 'AccountSourceList' }
);

export const lazyAccountSourceListPostRender = new LazyAction(
    lazyModule,
    m => m.accountSourceListPostRender
);

export const lazyEnsureAllAdditionalAccountInfoData = new LazyAction(
    lazyModule,
    m => m.ensureAllAdditionalAccountInfoData
);

export const lazyEnsureSharedMailboxData = new LazyAction(
    lazyModule,
    m => m.ensureSharedMailboxData
);

export const lazyEnsureSharedMailboxDataForMailboxInfo = new LazyAction(
    lazyModule,
    m => m.ensureSharedMailboxDataForMailboxInfo
);

export const lazyEnsurePreRenderForAccount = new LazyAction(
    lazyModule,
    m => m.ensurePreRenderForAccount
);

export const lazyOobeInitForNewlyAddedGlobalSettingsAccount = new LazyAction(
    lazyModule,
    m => m.oobeInitForNewlyAddedGlobalSettingsAccount
);

export const lazyRetryPreRenderForAccount = new LazyAction(
    lazyModule,
    m => m.retryPreRenderForAccount
);

export const lazyAreNewAccountAndExistingAccountsMutuallyCompliant = new LazyAction(
    lazyModule,
    m => m.areNewAccountAndExistingAccountsMutuallyCompliant
);

export const lazyUpdateAccountStateBasedOnPolicy = new LazyAction(
    lazyModule,
    m => m.updateAccountStateBasedOnPolicy
);

export const lazyUpdateLicensingIdentitiesStateBasedOnAccountPolicy = new LazyAction(
    lazyModule,
    m => m.updateLicensingIdentitiesStateBasedOnAccountPolicy
);

export const lazyGetNonOfficeProPlusAccounts = new LazyAction(
    lazyModule,
    m => m.getNonOfficeProPlusAccounts
);

export const lazyIsLastLicenseCompliantMailboxInfo = new LazyAction(
    lazyModule,
    m => m.isLastLicenseCompliantMailboxInfo
);

export const lazyIsLicenseRequirementsCompliant = new LazyAction(
    lazyModule,
    m => m.isLicenseRequirementsCompliant
);

export const lazyAreAllAccountsPolicyAllowingConsumerAccounts = new LazyAction(
    lazyModule,
    m => m.areAllAccountsPolicyAllowingConsumerAccounts
);

export const lazyBuildMailboxContracts = new LazyAction(lazyModule, m => m.buildMailboxContracts);
