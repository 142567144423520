import { mutatorAction } from 'satcheljs';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';
import { mailListItemAnimationStore } from '../store/Store';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const addActiveAnimation = mutatorAction(
    'addActiveAnimation',
    (animationToAdd: MailListItemAnimation) => {
        mailListItemAnimationStore.activeAnimations = [
            ...mailListItemAnimationStore.activeAnimations,
            animationToAdd,
        ];
    }
);
