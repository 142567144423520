import type { ImplementedCopilotFeatures } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import copilotSettingsStore from '../store/store';

export function hasCopilotEnabledFeatures(mailboxInfo: MailboxInfo): boolean {
    const store = copilotSettingsStore(mailboxInfo);
    if (store.isEnabled && store.featureInfo.size > 0) {
        return [...store.featureInfo.entries()].some(([, featureInfo]) => {
            return featureInfo.isEnabled;
        });
    }

    return false;
}

export function hasCopilotEnabledFeaturesInFeatureList(
    mailboxInfo: MailboxInfo,
    featureList: ImplementedCopilotFeatures[]
): boolean {
    const store = copilotSettingsStore(mailboxInfo);
    if (store.isEnabled && store.featureInfo.size > 0) {
        return [...store.featureInfo.entries()].some(([featureName, featureInfo]) => {
            return featureInfo.isEnabled && featureList.includes(featureName);
        });
    }

    return false;
}
