import { initializeCommandingViewModeInternal } from '../mutators/initializeCommandingViewModeInternal';
import { onViewModeInitialized } from '../actions/onViewModeInitialized';
import { once } from 'owa-lodash';
import { isSingleLineRibbon } from '../selectors/isSingleLineRibbon';
import { registerAllLightIcons } from 'owa-command-ribbon-light-icon-font';

export const initializeCommandingViewMode = once(function initializeCommandingViewMode(
    primeBootSettingsOptions: any[]
) {
    initializeCommandingViewModeInternal(primeBootSettingsOptions);
    registerAllLightIcons(!isSingleLineRibbon());
    onViewModeInitialized();
});
