import { addToFavoritesText } from 'owa-locstrings/lib/strings/addtofavoritestext.locstring.json';
import { removeFromFavoritesText } from 'owa-locstrings/lib/strings/removefromfavoritestext.locstring.json';
import { addToFavoritesCategoryText } from 'owa-locstrings/lib/strings/addtofavoritescategorytext.locstring.json';
import { removeFromFavoritesCategoryText } from 'owa-locstrings/lib/strings/removefromfavoritescategorytext.locstring.json';
import loc from 'owa-localize';
import { StarCharm } from 'owa-star-charm';
import { observer } from 'owa-mobx-react';
import { isAccountExplicitLogon } from 'owa-account-shared-mailbox-utils';
import type { MailboxInfo } from 'owa-client-types';
import React from 'react';
import {
    addToFavoritesCategoryTextWithCategoryName,
    removeFromFavoritesCategoryTextWithCategoryName,
} from './ToggleFavoriteButton.locstring.json';

export interface ToggleFavoriteButtonProps {
    isInFavorites: boolean;
    isClickDisabled?: boolean;
    animate?: boolean;
    iconStyles?: string;
    buttonStyles?: string;
    spinnerStyles?: string;
    onClick: (evt: React.MouseEvent<unknown>) => void;
    isCategory?: boolean;
    categoryName?: string;
    isDisabled?: boolean;
    mailboxInfo: MailboxInfo;
}

const ToggleFavoriteButton = observer(function ToggleFavoriteButton(
    props: ToggleFavoriteButtonProps
) {
    const {
        isInFavorites,
        isClickDisabled,
        animate,
        iconStyles,
        buttonStyles,
        spinnerStyles,
        onClick,
        isCategory,
        categoryName,
        isDisabled,
        mailboxInfo,
    } = props;

    const starredText = isCategory
        ? loc(removeFromFavoritesCategoryText)
        : loc(removeFromFavoritesText);
    const unstarredText = isCategory ? loc(addToFavoritesCategoryText) : loc(addToFavoritesText);
    const tooltipPropValue = React.useMemo(() => {
        return {
            starred: starredText,
            unstarred: unstarredText,
        };
    }, [starredText, unstarredText]);

    // We dont support add/remove favorites in explicit logon scenarios
    if (isAccountExplicitLogon(mailboxInfo)) {
        return null;
    }

    return (
        <StarCharm
            isStarred={isInFavorites}
            onClick={onClick}
            isClickDisabled={isClickDisabled}
            animate={animate}
            iconStyles={iconStyles}
            buttonStyles={buttonStyles}
            spinnerStyles={spinnerStyles}
            ariaLabelText={
                isInFavorites
                    ? loc(removeFromFavoritesCategoryTextWithCategoryName, categoryName)
                    : loc(addToFavoritesCategoryTextWithCategoryName, categoryName)
            }
            tooltip={tooltipPropValue}
            isDisabled={isDisabled}
        />
    );
},
'ToggleFavoriteButton');
export default ToggleFavoriteButton;
