import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { logUserAdConfigLazy } from 'owa-aduser-log';
import { getEmailadsOptInStore } from 'owa-emailadsoptin-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getNativeadsUpsellEffectStore } from 'owa-nativeadsupselleffect-store';
import { isPremiumConsumer } from 'owa-session-store';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import {
    IsChildConsumerUser,
    IsShadowMailboxUser,
    embargoedMarkets,
    getAdMarketPublishGroupCode,
    getCachedEUEmailPolicyOptInBit,
    isEUEmailAdsPolicyMarket,
    shouldHideAdsForWin32ToggledUsers,
} from 'owa-mail-ads-shared';

// enableDisplayAds has additional check for display ads only such as if the session is a Monarch session or there is a shutdown switch/flight to shut down display ads specifically. This might not impact native ads.
var enableDisplayAds: boolean;

export function areDisplayAdsShown(): boolean {
    // If the Ad is not enabled due to premium status or flight, etc, we do not need to check other dynamic properties
    if (!areDisplayAdsEnabled()) {
        return false;
    }

    // For EU Market, check if user has opted-in the optimized ads, if so, we turn off display Ad. This is a dynamic check during one session
    if (isEUEmailAdsPolicyMarket()) {
        const emailadsOptInStore = getEmailadsOptInStore();
        if (emailadsOptInStore.emailAdsOptInBit == 2) {
            return false;
        } else if (
            emailadsOptInStore.emailAdsOptInBit == -1 &&
            getCachedEUEmailPolicyOptInBit() == 2
        ) {
            return false;
        }
    }

    // When the user is in the upsell effective state, we hide the display Ad.
    if (getNativeadsUpsellEffectStore().isEffective) {
        return false;
    }

    return true;
}

export function areDisplayAdsEnabled(): boolean {
    // For display ads, we only need to check the primary global account.
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    // caching this value as it doesn't change per session
    if (typeof enableDisplayAds != 'undefined') {
        return enableDisplayAds;
    }

    // Turn off the display Ad when one of the following conditions are met
    // 1. Not consumer user
    if (!isConsumer(undefined, mailboxInfo)) {
        enableDisplayAds = false;
    } else {
        let enableAds = false;

        let displayAdsNotShownString = null;

        const adMarket = getAdMarketPublishGroupCode();

        if (isPremiumConsumer(mailboxInfo)) {
            displayAdsNotShownString = 'PremiumUser';
        } else if (embargoedMarkets.includes(adMarket)) {
            displayAdsNotShownString = 'EmbargoedMarket';
        } else if (IsChildConsumerUser(mailboxInfo)) {
            displayAdsNotShownString = 'ChildAccount';
        } else if (shouldHideAdsForWin32ToggledUsers()) {
            displayAdsNotShownString = 'ToggleFromWin32';
        } else if (IsShadowMailboxUser(mailboxInfo)) {
            displayAdsNotShownString = 'ShadowMailboxUser';
            enableAds = true; // Monarch shadow mailbox could see the native ads.
        } else if (isFeatureEnabled('ads-shutdown-displayAds')) {
            displayAdsNotShownString = 'AdsFlightShutdownDisplayAds';
            enableAds = true; // The flight only shuts down display ads but not native ads.
        } else if (isFeatureEnabled('ads-hideDisplayAds')) {
            displayAdsNotShownString = 'NativeHostUser';
            enableAds = true; // Native host user could see the native ads.
        }

        // If enableAds is false, it means both native ads and displays ads are turned off.
        // If enableAds is true, potentially native ads could be turned on. Whether or not native ads is actually turned on is determined by the native ads condition checks.
        enableAds = enableAds || displayAdsNotShownString == null;
        enableDisplayAds = displayAdsNotShownString == null;

        logUserAdConfigLazy.importAndExecute(
            enableAds,
            displayAdsNotShownString || '',
            adMarket,
            enableDisplayAds
        );
    }

    return enableDisplayAds;
}

export function setShowAds(value: any) {
    enableDisplayAds = value;
}
