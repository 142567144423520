import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import {
    getGroupIdFromTableQuery,
    isGroupTableQuery,
    lazyIsGroupOwner,
    lazyIsPrivateUnjoinedGroup,
    isMessageActionFromGroup,
    getGroupMailboxInfoFromTableQuery,
} from 'owa-group-utils';
import { composeStore } from 'owa-mail-compose-store';
import { isDumpsterSearchTable } from 'owa-mail-list-search';
import type { TableView } from 'owa-mail-list-store';
import {
    isDumpsterTable,
    MailRowDataPropertyGetter,
    getSelectedTableView,
} from 'owa-mail-list-store';
import { isBulkActionRunning } from 'owa-bulk-action-store';
import { getGroupFolderAndRulePermission } from 'owa-groups-shared-store/lib/selectors/groupFolderRulePermissionStoreSelectors';
import { isFeatureEnabled } from 'owa-feature-flags';
import shouldDisableMessageRecall from 'owa-mail-message-recall/lib/utils/shouldDisableMessageRecall';
import { shouldDisableMessageResend } from 'owa-message-resend';
import type Message from 'owa-service/lib/contract/Message';
import shouldDisabledReadReceipts from 'owa-track-read-receipts/lib/utils/shouldDisabledReadReceipts';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import isEmailDownloadAllowed from 'owa-attachment-user-operations/lib/utils/isEmailDownloadAllowed';
import getItemOrMessage from '../utils/getItemOrMessage';
import { getCopilotPromptStore } from 'owa-copilot-mail-option/lib/util/copilotPromptUtils';

export default function getMailMenuItemShouldDisable(menuItem: MenuItemType): boolean {
    const tableView = getSelectedTableView();

    switch (menuItem) {
        case 33:
            return isNewMessageDisabled(tableView);
        case 12:
            return !!tableView && isDeleteDisabled(tableView);
        case 29:
            return !!tableView && isMoveDisabled(tableView);
        case 9:
            return !!tableView && isCopyDisabled(tableView);
        case 83:
            const mailboxInfo = getMailboxInfo(tableView);
            return (
                !!tableView &&
                shouldDisableMessageRecall(getItemOrMessage(tableView) as Message, mailboxInfo)
            );
        case 86:
            const item = getItemOrMessage(tableView);
            return !!tableView && !!item && shouldDisabledReadReceipts(item as Message);
        case 65:
            const message = getItemOrMessage(tableView) as Message;
            if (!message) {
                return true;
            }
            return !isEmailDownloadAllowed(message, getMailboxInfo(getSelectedTableView()));

        case 92:
            return (
                !!tableView &&
                shouldDisableMessageResend(
                    getItemOrMessage(tableView) as Message,
                    getMailboxInfo(tableView)
                )
            );
        case 98:
            const copilotPromptStore = getCopilotPromptStore(
                getMailboxInfo(getSelectedTableView())
            );
            return !copilotPromptStore?.prompt || !copilotPromptStore?.category;
        default:
            return false;
    }
}

// Show new message as disabled
// - when we in a dumpster table
// - when we are in a private unjoined group
// - when a new message is still in the process of being created
function isNewMessageDisabled(tableView: TableView): boolean {
    const tableQuery = tableView.tableQuery;
    const isDumpsterOrDumpsterSearchTable =
        isDumpsterTable(tableQuery) || isDumpsterSearchTable(tableQuery);

    if (isDumpsterOrDumpsterSearchTable) {
        return true;
    }

    if (isGroupTableQuery(tableQuery)) {
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * BASELINE. Do not copy and paste!
         *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
        const isPrivateUnjoinedGroup = lazyIsPrivateUnjoinedGroup.tryImportForRender();
        const groupId = getGroupIdFromTableQuery(tableQuery);
        return (
            (!!groupId &&
                isPrivateUnjoinedGroup?.(
                    groupId,
                    getGroupMailboxInfoFromTableQuery(groupId, tableQuery)
                )) ??
            false
        );
    }

    if (composeStore.newMessageCreationInProgress) {
        return true;
    }

    return false;
}

// Show delete as disabled
// - if tableview is in selectAll mode and a bulk action is currently already running
// - when we are in a group, we are not the group owner, AND
// --- we have multiple rows selected OR
// --- we do not have canDelete permissions on the message
function isDeleteDisabled(tableView: TableView): boolean {
    const tableQuery = tableView.tableQuery;
    const groupId = getGroupIdFromTableQuery(tableQuery);

    if (isBulkActionRunning(tableView.serverFolderId) && tableView.isInVirtualSelectAllMode) {
        return true;
    }

    if (groupId && isGroupTableQuery(tableQuery)) {
        const folderRulePermission = getGroupFolderAndRulePermission(
            getGroupMailboxInfoFromTableQuery(groupId, tableQuery),
            groupId
        );

        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * BASELINE. Do not copy and paste!
         *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
        const isGroupOwner = lazyIsGroupOwner.tryImportForRender();
        if (groupId && !isGroupOwner?.(groupId)) {
            const selectedKeys = [...tableView.selectedRowKeys.keys()];
            if (
                selectedKeys.length !== 1 ||
                !(
                    MailRowDataPropertyGetter.getCanDelete(selectedKeys[0], tableView) ||
                    (isFeatureEnabled('grp-loadFolders') &&
                        !!folderRulePermission?.canDeleteMessage)
                )
            ) {
                return true;
            }
        }
    }

    return false;
}

// Show Move as disabled
// - if action is invoked inside a group, AND permissions are not allowed
function isMoveDisabled(tableView: TableView): boolean {
    if (isMessageActionFromGroup()) {
        const groupId = getGroupIdFromTableQuery(tableView.tableQuery) ?? '';
        const mailboxInfo = getGroupMailboxInfoFromTableQuery(groupId, tableView.tableQuery);

        const folderRulePermission = getGroupFolderAndRulePermission(mailboxInfo, groupId);
        return !folderRulePermission?.canMoveMessage;
    }

    return false;
}

// Show Copy as disabled
// - if action is invoked inside a group, AND permissions are not allowed
function isCopyDisabled(tableView: TableView): boolean {
    if (isMessageActionFromGroup()) {
        const groupId = getGroupIdFromTableQuery(tableView.tableQuery) ?? '';
        const mailboxInfo = getGroupMailboxInfoFromTableQuery(groupId, tableView.tableQuery);

        const folderRulePermission = getGroupFolderAndRulePermission(mailboxInfo, groupId);
        return !folderRulePermission?.canCopyMessage;
    }

    return false;
}
