import { LazyAction, LazyModule } from 'owa-bundling';
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AttPreviews" */ './lazyIndex'),
    { name: 'AttPreviews' }
);

export const lazyGetAttachmentPreviews = new LazyAction(lazyModule, m => m.getPreviews);
export const lazyFetchAttachmentsForConversaion = new LazyAction(
    lazyModule,
    m => m.fetchAttachmentsForConversaion
);
export const lazyAddAttachmentPreviews = new LazyAction(lazyModule, m => m.add);
export const lazyGetAttachmentsForConversationWell = new LazyAction(
    lazyModule,
    m => m.getAttachmentsForConversationWell
);
export const lazyRemoveAttachmentPreviews = new LazyAction(
    lazyModule,
    m => m.removeAttachmentPreviewsForRow
);

export {
    shouldShowAttachmentPreviewsForConversation,
    shouldShowAttachmentPreviewsForItem,
} from './helpers/shouldShowAttachmentPreviews';
export { getConversationAttachmentsViewState } from './selectors/getConversationAttachmentsViewState';
export {
    AttachmentFetchStatus,
    type ConversationAttachmentsViewState,
} from './store/schema/conversationAttachmentsViewState';
