import type { InfoBarHostViewState } from '../schema/InfoBarMessageViewState';
import { infoBarMessageCreatorsMap } from '../utils/infoBarMessageCreatorsMap';
import type InfoBarMessageViewStateCreator from '../schema/InfoBarMessageViewStateCreator';
import { mutatorAction } from 'satcheljs';

/**
 * @param viewState the host viewstate storing the list of messageIds to render
 * @param messageId the message Id of the infobar to remove
 * @param key if the message was added with a key, the message is stored in a bucket with that key (e.g. itemId, or composeId)
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const addInfoBarMessage = mutatorAction(
    'addInfoBarMessage',
    function addInfoBarMessage(
        viewState: InfoBarHostViewState | undefined,
        messageIdOrIds: string[] | string | null,
        infoBarMessageViewStateCreator?: InfoBarMessageViewStateCreator
    ) {
        const messageIds = Array.isArray(messageIdOrIds) ? messageIdOrIds : [messageIdOrIds];
        for (const messageId of messageIds) {
            if (viewState && messageId) {
                if (viewState.infoBarIds.indexOf(messageId) == -1) {
                    viewState.infoBarIds.push(messageId);
                } else {
                    // clone infoBarIds to force re-render
                    viewState.infoBarIds = [...viewState.infoBarIds];
                }

                const key = viewState.infoBarHostKey;

                if (infoBarMessageViewStateCreator) {
                    if (!infoBarMessageCreatorsMap[key]) {
                        infoBarMessageCreatorsMap[key] = {};
                    }
                    infoBarMessageCreatorsMap[key][messageId] = infoBarMessageViewStateCreator;
                }
            }
        }
    }
);

export default addInfoBarMessage;
