import type TabbedSearchStore from './TabbedSearchStore';
import { createStore } from 'owa-satcheljs';
import { TabbedSearchLoadingState } from './TabbedSearchStore';

export const defaultTabbedSearchStore: TabbedSearchStore = {
    activeSearchTab: undefined,
    activeScopeTab: undefined,
    fileSearchResults: [],
    teamsSearchResults: [],
    peopleSearchResults: [],
    loadingState: TabbedSearchLoadingState.NotStarted,
    queryString: '',
    isLoadingMoreResults: false,
    isTeamsChatEnabled: false,
    shouldSuppressTabs: false,
    searchTerms: [],
    selectedSearchResultId: undefined,
    appliedRefiners: [],
    arrowsScrollIndex: 0,
};

const initialStore = { ...defaultTabbedSearchStore };
const store = createStore<TabbedSearchStore>('tabbedSearchStore', initialStore)();
export default store;
export const getStore = () => store;
