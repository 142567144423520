import {
    IsAnyUserPremiumConsumerOrBusiness,
    IsChildConsumerUser,
    IsShadowMailboxUser,
    embargoedMarkets,
    getAdMarketPublishGroupCode,
} from './sharedAdsUtils';
import { isConsumer } from 'owa-session-store';
import type NativeAdPlacement from './NativeAdPlacement';
import { getEmailadsOptInStore } from 'owa-emailadsoptin-store';
import getCachedEUEmailPolicyOptInBit from './getCachedEUEmailPolicyOptInBit';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import isEUEmailAdsPolicyMarket from './isEUEmailAdsPolicyMarket';
import { isFeatureEnabled } from 'owa-feature-flags';
import getOverallPremiumStatusStore from './store/overallPremiumStatusStore';
import isHeaderAdSpecialMarket from './isHeaderAdSpecialMarket';
import { logUsage } from 'owa-analytics';
import { getImportHistory } from 'owa-account-import-history/lib/getImportHistory';
import { getItem, type LocalStorageKeys } from 'owa-local-storage';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { isInMigrationAdsFreePeriod } from './isInMigrationAdsFreePeriod';
import { PremiumStatusEnum } from './store/schema/OverallPremiumState';

let nativeAdEnableOrNot: boolean | undefined;
let nativeAdInitialPlacement: NativeAdPlacement | undefined;
let secondaryPaidAccountLogged: boolean | undefined;

// To avoid bundle size increase and duplication, copy the LegacyApp enum from outlookwebcontext.g.ts
enum LegacyApp {
    OutlookDesktop = 'OutlookDesktop',
    Universal = 'Universal',
}

let showAdsBanner: boolean;

export function getNativeAdPlacement(): NativeAdPlacement {
    const nativeAdSessionNonChangePlacement = getNativeAdInitialPlacement();

    // If the native ad initial placement is none, we do not need to check the premium status & EU Ads policy as the Ad is disabled anyway
    if (nativeAdSessionNonChangePlacement == 0) {
        return 0;
    }

    // The following properties could change in one app session
    // 1. Premium status
    // 2. EU Ads policy

    // For premium status, the order of the check is
    // 1. Check the premium status store value as this is source of the truth. If the value is 2 (isPremium), set the ads placement to None.
    // 2. If the premium status store value is -1 (not set), retrieve from the cached premium status value.
    // 3. If the cached premium status value is 1 (NonPremium), let the nativeAdSessionNonChangePlacement continue to flow.
    const overallPremiumStatusStore = getOverallPremiumStatusStore();
    if (
        overallPremiumStatusStore.overallPremiumBit == PremiumStatusEnum.Premium ||
        (overallPremiumStatusStore.overallPremiumBit == PremiumStatusEnum.Unset &&
            IsAnyUserPremiumConsumerOrBusiness())
    ) {
        // Log the metrics if ads is hidden due to the seconary account premium status.
        if (!secondaryPaidAccountLogged) {
            logUsage('NoAdsSecondaryPaidAccount', {});
            secondaryPaidAccountLogged = true;
        }

        return 0;
    }

    if (
        isEUEmailAdsPolicyMarket() &&
        (getEmailadsOptInStore().emailAdsOptInBit == 1 ||
            getEmailadsOptInStore().emailAdsOptInBit == 0 ||
            (getEmailadsOptInStore().emailAdsOptInBit == -1 &&
                getCachedEUEmailPolicyOptInBit() == 1))
    ) {
        // If any special treatment market, we will check a seperate flight for the header ad.
        // Otherwise, we will show the ad text in the top of the header.
        if (isHeaderAdSpecialMarket()) {
            return 3;
        } else {
            return 1;
        }
    }

    return nativeAdSessionNonChangePlacement;
}

/*
Get the Ad initial placement. The flow is below:
1. Users which are in the following categories do not get native Ads
-- None of the native ads flight is enabled for this user
-- Child account
-- User is in a non-supported market
2. Regular user gets OtherPrefer treatment.
   When the user has Focused Inbox on, the ads is only shown in the Other folder. For
   For Non Focus/Other Inbox enabled users, we will show the Ad in the Inbox as well.
Note: Premium status needs to be calculated outside of this function as it could change during the app session.
*/
export function getNativeAdInitialPlacement(): NativeAdPlacement {
    if (!nativeAdInitialPlacement) {
        if (!getNativeAdEnabledOrNotFromUserProperties()) {
            nativeAdInitialPlacement = 0;
        } else {
            nativeAdInitialPlacement = 4;
        }
    }

    return nativeAdInitialPlacement || 0;
}

export function shouldShowAdsInFocusedPivot(): boolean {
    // Show the focus ads when all the following conditions are met
    // 1. User has not seen any ads excluding the ad in Focused for 14 days, which means most likely they have not visited Other in 14 days.
    // 2. user has not seen an ad at all in the last 2 days
    // 3. User is not new to client in the last 14 days
    const daysDaysSinceLastAdSeenExFocused = getDaysDifference('dlaef');
    const daysSinceLastAdSeen = getDaysDifference('dla');
    const IntervalDaysSeeNonFocusAd = isFeatureEnabled('adsexp-focus7d') ? 7 : 14;
    if (
        daysDaysSinceLastAdSeenExFocused > IntervalDaysSeeNonFocusAd &&
        daysSinceLastAdSeen > 2 &&
        isNewUserGreater14Days()
    ) {
        return true;
    } else {
        return false;
    }
}

function getDaysDifference(lastAdSeenDateProperty: LocalStorageKeys): number {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const currentDate = new Date();

    // getItem will always return a string even if storage saves the number.
    // We need to convert the string to a number before converting to date as new Date() will not work with string in the ticks format.
    // If the storage key is not set, we will return -1 as the default value which is 12/31/1969 in JavaScript which indicates that the user has never seen an ad.
    const lastSeenDateTickString = getItem(window, lastAdSeenDateProperty) || '-1';
    const lastSeenDateTick = Number(lastSeenDateTickString);
    const lastAdSeenDate = new Date(lastSeenDateTick);
    const timeDifference = currentDate.getTime() - lastAdSeenDate.getTime();

    return Math.floor(timeDifference / millisecondsPerDay);
}

function isNewUserGreater14Days() {
    // milliseconds in 14 days
    const FOURTEEN_DAYS_TIMESPAN_IN_MS = 1000 * 60 * 60 * 24 * 14;
    const mailboxCreateDateString = getUserConfiguration().MailboxCreateDate;
    if (!mailboxCreateDateString) {
        return false;
    }

    const mailboxCreateDate = new Date(mailboxCreateDateString);
    return Date.now() - mailboxCreateDate.getTime() > FOURTEEN_DAYS_TIMESPAN_IN_MS;
}

/*
Some checks are on the user which will never change during an OWA session
Cache this value so that it will not be calculated again and again
*/
function getNativeAdEnabledOrNotFromUserProperties(): boolean {
    if (typeof nativeAdEnableOrNot != 'undefined') {
        return nativeAdEnableOrNot;
    }

    nativeAdEnableOrNot = isNativeAdsEnabledPrimaryAccount();

    return nativeAdEnableOrNot;
}

// The following check checks the primary account's properties to determine if the native ads is enabled or not
// These properties will not change during the whole session and we can cache these values.
function isNativeAdsEnabledPrimaryAccount(): boolean {
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    if (
        !isConsumer(undefined, mailboxInfo) ||
        isFeatureEnabled('ads-shutdown-nativeAds') ||
        (!isFeatureEnabled('ads-cloudcacheaccount-honor-check') &&
            IsShadowMailboxUser(mailboxInfo)) ||
        IsChildConsumerUser(mailboxInfo) ||
        embargoedMarkets.indexOf(getAdMarketPublishGroupCode()) > -1 ||
        shouldHideAdsForWin32ToggledUsers()
    ) {
        return false;
    }

    return true;
}

export function shouldHideAdsForWin32ToggledUsers(): boolean {
    showAdsBanner = false; // Always reset when calculating ads placement to ensure that this is only set to true when in the win32 migration grace period.
    // If the user is not a win32 user, no need to hide ads.
    if (getImportHistory().lastImportApp != LegacyApp.OutlookDesktop) {
        return false;
    }

    // Hide ads and show the ads banner if we are in the 30 day win32 migration grace period
    if (isFeatureEnabled('settings-otherMicrosoftAccountManagement')) {
        showAdsBanner = isInMigrationAdsFreePeriod();
        return showAdsBanner;
    }

    return true;
}

// To have the banner shown, the following conditions need to be met:
// 1. The user is a Win32 migration user
// 2. The user is in the 30-day grace period after the first migration(toggle in)
// 3. The user is not ads free based on the ads check
export function shouldShowAdsGracePeriodBanner(): boolean {
    if (!isFeatureEnabled('settings-otherMicrosoftAccountManagement')) {
        return false;
    }

    return isInMigrationAdsFreePeriod() && showAdsBanner;
}

// This is only used for test
export function resetNativeAdCacheValueForTest() {
    nativeAdEnableOrNot = undefined;
    nativeAdInitialPlacement = undefined;
}
