import { createStore } from 'owa-satcheljs';
import type FindInMessageStore from './schema/FindInMessageStore';
import type FindInMessageViewState from './schema/FindInMessageViewState';

const initialFindInMessageStore: FindInMessageStore = {
    findInMessageViewStates: new Map<string, FindInMessageViewState>(),
};

const store = createStore<FindInMessageStore>('findInMessageStore', initialFindInMessageStore)();
export default store;
export const getStore = () => store;
