import React from 'react';
import { sharedIcon, sharedText, sharedContainer } from './RenderSharedRootWithIcon.scss';
import { PeopleFilled } from '@fluentui/react-icons';

export const RenderSharedRootWithIcon = function RenderSharedRootWithIcon(
    displayName: string
): ((className: string) => JSX.Element) | undefined {
    return (className: string): JSX.Element => {
        return (
            <div className={className}>
                <div className={sharedContainer}>
                    <span className={sharedText}>{displayName}</span>
                    <PeopleFilled className={sharedIcon} />
                </div>
            </div>
        );
    };
};
