import { getCopilotCategoriesList } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

/**
 * Check if the category is a copilot category
 * @param categoryName  the client category name
 * @param mailboxInfo the mailbox info
 */
export default function isCopilotCategory(
    categoryName: string,
    mailboxInfo?: MailboxInfo
): boolean {
    return getCopilotCategoriesList(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo())?.some(
        category => category.Name === categoryName
    );
}
