import { FolderTextFieldWrapper } from 'owa-folders-common';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import React from 'react';
import { lazyDismissMailFolderTextField } from 'owa-mail-folder-store';
import { observer } from 'owa-mobx-react';

import * as styles from 'owa-tree-node/lib/components/NodeHeight.scss';

import classnames from 'owa-classnames';

interface FolderTextFieldProps {
    nestDepth: number;
    onEntry: (value: string, folderId?: string) => void;
    folderId?: string;
    defaultValue?: string;
    styles?: object;
}

export default observer(function FolderTextField(props: FolderTextFieldProps) {
    return (
        <FolderTextFieldWrapper
            className={classnames(
                getDensityModeCssClass(styles.full, styles.medium, styles.compact),
                styles.nodeHeight
            )}
            depth={props.nestDepth}
            defaultValue={props.defaultValue}
            key="folderTextField"
            onEntry={props.onEntry}
            onDismiss={onDismissFolderTextFieldEntry}
            folderId={props.folderId}
            styles={props.styles}
        />
    );
}, 'FolderTextField');

function onDismissFolderTextFieldEntry() {
    lazyDismissMailFolderTextField.importAndExecute();
}
