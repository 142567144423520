import { addMailListLog, getMailListLogObjectToAddToStore } from 'owa-mail-list-logging';
import { FilterRegular } from '@fluentui/react-icons';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { getPalette } from 'owa-theme';
import { getStore as getListViewStore, getViewFilterForTable } from 'owa-mail-list-store';
import { getViewFilterIcon } from '../helpers/getViewFilterIcon';
import { isSingleLineListView } from 'owa-mail-layout';
import { lazyRemoveFilterOverrideAndReloadFolder, lazySelectFilter } from 'owa-mail-filter-actions';
import { lazyShowFreeUpMSQSpaceTeachingMoment } from 'owa-storage-quota';
import { logUsage } from 'owa-analytics';
import { mailFilter, sortBySize } from '../strings.locstring.json';
import { mergeStyles } from '@fluentui/merge-styles';
import { observer } from 'owa-mobx-react';
import { resetFocus } from 'owa-mail-focus-manager';
import classnames from 'owa-classnames';
import getViewFilterDisplay from '../helpers/getViewFilterDisplay';
import loc from 'owa-localize';
import React from 'react';
import type { ActionSource } from 'owa-mail-store';
import type { MailFolderTableQuery } from 'owa-mail-list-store';
import type { MenuProps, MenuOpenChangeData } from '@fluentui/react-components';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import {
    makeStyles,
    Menu,
    MenuButton,
    MenuItemRadio,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
} from '@fluentui/react-components';
import {
    filterButton,
    filterButtonCompact,
    filterButtonCompactMCL,
    filterButtonFull,
    filterButtonFullMCL,
    filterButtonMedium,
    filterButtonMediumMCL,
    filterButtonOpened,
    filterMenuItemLabelFull,
    filterMenuItemLabelMediumOrCompact,
    viewFilterMenuOption,
} from './MailListFilterMenu.scss';
import { shouldShowViewFilterForMailbox } from '../helpers/shouldShowViewFilterForMailbox';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import getCopilotPrioritizeSettings from 'owa-mail-copilot-settings/lib/selectors/getCopilotPrioritizeSettings';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';

// Styles for the filter button icon that sits on top of the ML
// The !important bit is required to override default Fluent styling that is
// applied as a style directly to the icon element
const useFilterButtonIconStyles = makeStyles({
    full: {
        fontSize: '20px !important',
    },
    mediumOrCompact: {
        fontSize: '16px !important',
        marginTop: '2px',
    },
});

// Styles for the icons within the filter menu
// The !important bit is required to override default Fluent styling that is
// applied as a style directly to the icon element
const useMenuItemFilterIconStyles = makeStyles({
    full: {
        display: 'flex',
        fontSize: '20px !important',
        width: '20px',
    },
    mediumOrCompact: {
        display: 'flex',
        fontSize: '16px !important',
    },
});

export interface MailListFilterMenuProps {
    filterButtonClassName?: string;
    filterMenuSource: ActionSource;
    supportedViewFilters: ViewFilter[];
    tableViewId: string;
}

export default observer(function MailListFilterMenu(props: MailListFilterMenuProps) {
    const { filterButtonClassName, filterMenuSource, supportedViewFilters, tableViewId } = props;

    const tableView = getListViewStore().tableViews.get(tableViewId);
    const mailboxInfo = getMailboxInfo(tableView);
    const currentSelectedFilter: ViewFilter | null = getViewFilterForTable(tableView);
    const mailFolderTableQuery = tableView?.tableQuery as MailFolderTableQuery | undefined;

    const prioritizationEnabled =
        isCopilotFeatureEnabled('Inbox', mailboxInfo, true /* skipLanguageCheck */) &&
        getCopilotPrioritizeSettings(mailboxInfo).prioritizationEnabled;

    // The "open" state of the filter menu
    const [open, setOpen] = React.useState(false);
    const onOpenChange: MenuProps['onOpenChange'] = React.useCallback(
        (_e: any, data: MenuOpenChangeData) => {
            if (!data.open) {
                setOpen(data.open);
            }
        },
        []
    );

    // The selected view filter values in the filter menu
    /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
     * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
     *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
    const [checkedValues, setCheckedValues] = React.useState<Record<string, string[]>>({
        viewFilter: ['All'],
    });
    const onCheckedValueChange = React.useCallback<
        Exclude<MenuProps['onCheckedValueChange'], undefined>
    >((_e, { name, checkedItems }) => {
        setCheckedValues(s => ({ ...s, [name]: checkedItems }));
    }, []);

    // Update the checked values when the selected view filter changes
    React.useEffect(() => {
        setCheckedValues(s => ({ ...s, viewFilter: [currentSelectedFilter || 'All'] }));
    }, [currentSelectedFilter]);

    // Reset checked values when the tableView.id changes
    React.useEffect(() => {
        setCheckedValues(s => ({ ...s, viewFilter: ['All'] }));
    }, [tableViewId]);

    // Sets up ref and effect for USQ teaching moment if necessary
    const filterIconButtonRef: React.RefObject<HTMLDivElement> = React.createRef();
    const isUsingOverrideToSortBySize = React.useMemo(() => {
        return mailFolderTableQuery?.scenarioType === 'mailFolderSortBySize';
    }, [mailFolderTableQuery?.scenarioType]);

    React.useEffect(() => {
        const filterIconButtonElement: HTMLDivElement | null = filterIconButtonRef.current;
        if (filterIconButtonElement && isUsingOverrideToSortBySize) {
            displayUSQTeachingMoment(filterIconButtonElement);
        }
    }, []);

    // The label to display for the filter button above the ML (if any)
    const showFilterButtonLabel = isSingleLineListView() || isUsingOverrideToSortBySize;
    const filterMenuLabel = React.useMemo(() => {
        if (isUsingOverrideToSortBySize) {
            return loc(sortBySize);
        }

        if (currentSelectedFilter == 'All' /* isAllFilterSelected */) {
            return loc(mailFilter);
        }

        return currentSelectedFilter ? getViewFilterDisplay(currentSelectedFilter) : '';
    }, [currentSelectedFilter, isUsingOverrideToSortBySize]);

    const onFilterButtonClicked = React.useCallback(
        (evt: React.MouseEvent<unknown> | React.KeyboardEvent<HTMLElement>) => {
            evt.stopPropagation();

            if (isUsingOverrideToSortBySize) {
                lazyRemoveFilterOverrideAndReloadFolder.importAndExecute(tableViewId);
                return;
            }

            // If the user clicks on the filter button while the "All" filter is selected,
            // then we want to dismiss the activated filter and reset the focus to the
            // mail list. Otherwise, we want to open the filter menu.
            const isAllFilterSelected = currentSelectedFilter === 'All';
            if (!isAllFilterSelected) {
                logUsage('FilterMenuDismissActivatedFilter', {
                    dismissedFilter: currentSelectedFilter,
                });

                lazySelectFilter.importAndExecute('All', filterMenuSource);
                addMailListLog(
                    getMailListLogObjectToAddToStore('RemoveActivatedListViewFilter', {
                        tableView: tableViewId,
                        dismissedFilter: currentSelectedFilter,
                    })
                );

                // Reset checked values here because we're programmatically unselecting
                // whatever the user had selected
                setCheckedValues(s => ({ ...s, viewFilter: ['All'] }));

                setOpen(false);
                resetFocus('FilterButtonClicked');
            } else if (!open) {
                logUsage('FilterMenuExpand');
                setOpen(true);
            }
        },
        [
            currentSelectedFilter,
            filterMenuSource,
            isUsingOverrideToSortBySize,
            mailFolderTableQuery?.scenarioType,
            open,
            tableViewId,
        ]
    );

    const onViewFilterClicked = React.useCallback(
        (viewFilter: ViewFilter) => {
            return () => {
                lazySelectFilter.importAndExecute(viewFilter, filterMenuSource);

                setOpen(false);
                resetFocus('MailListViewFilterClicked');

                addMailListLog(
                    getMailListLogObjectToAddToStore('ApplyListViewFilter', {
                        tableView: tableViewId,
                        filterApplied: viewFilter,
                    })
                );
            };
        },
        [filterMenuSource, tableViewId]
    );

    const menuItemFilterIconStyles = useMenuItemFilterIconStyles();
    const viewFilterMenuItems = useComputedValue(() => {
        const viewFiltersToDisplay: ViewFilter[] = supportedViewFilters.filter(
            (viewFilter: ViewFilter) => shouldShowViewFilterForMailbox(viewFilter, mailboxInfo)
        );

        if (prioritizationEnabled) {
            viewFiltersToDisplay.splice(1, 0, 'CopilotHighPriority');
        }

        return viewFiltersToDisplay.map((viewFilter: ViewFilter) => {
            const viewFilterDisplayText = getViewFilterDisplay(viewFilter);
            const onViewFilterClickedCallback = onViewFilterClicked(viewFilter);

            // Clone the icon element so we can update the classes that are applied
            // to it so that we can effectively resize the icon from Fluent based
            // on density (required to unset font-size as it's applied directly
            // as a style and has highest specificity)
            const iconElement = getViewFilterIcon(viewFilter);
            const styledIconElement = React.cloneElement(iconElement, {
                key: viewFilter,
                className: classnames(
                    getDensityModeCssClass(
                        menuItemFilterIconStyles.full,
                        menuItemFilterIconStyles.mediumOrCompact,
                        menuItemFilterIconStyles.mediumOrCompact
                    )
                ),
                style: { fontSize: 'unset' },
            });

            return (
                <MenuItemRadio
                    key={viewFilter}
                    className={viewFilterMenuOption}
                    icon={styledIconElement}
                    name="viewFilter"
                    onClick={onViewFilterClickedCallback}
                    title={viewFilterDisplayText}
                    value={viewFilter}
                    ref={filterIconButtonRef}
                >
                    <span
                        className={getDensityModeCssClass(
                            filterMenuItemLabelFull,
                            filterMenuItemLabelMediumOrCompact,
                            filterMenuItemLabelMediumOrCompact
                        )}
                    >
                        {viewFilterDisplayText}
                    </span>
                </MenuItemRadio>
            );
        });
    }, [
        currentSelectedFilter,
        filterMenuSource,
        mailboxInfo,
        supportedViewFilters,
        tableViewId,
        menuItemFilterIconStyles,
        prioritizationEnabled,
    ]);

    const filterButtonIconStyles = useFilterButtonIconStyles();
    const iconElement =
        currentSelectedFilter == 'All' ? (
            <FilterRegular />
        ) : (
            getViewFilterIcon(currentSelectedFilter)
        );
    const filterButtonIconElement = React.cloneElement(iconElement, {
        className: classnames(
            getDensityModeCssClass(
                filterButtonIconStyles.full,
                filterButtonIconStyles.mediumOrCompact,
                filterButtonIconStyles.mediumOrCompact
            )
        ),
    });

    const menuButton = (
        <MenuButton
            icon={filterButtonIconElement}
            onClick={onFilterButtonClicked}
            className={classnames(
                open ? filterButtonOpened : filterButton,
                getDensityModeCssClass(
                    isSingleLineListView() ? filterButtonFullMCL : filterButtonFull,
                    isSingleLineListView() ? filterButtonMediumMCL : filterButtonMedium,
                    isSingleLineListView() ? filterButtonCompactMCL : filterButtonCompact
                ),
                filterButtonClassName,
                mergeStyles({
                    color: `${getPalette().neutralDark}`,
                })
            )}
            menuIcon={null}
            id="mailListFilterMenu"
        >
            {showFilterButtonLabel && filterMenuLabel}
        </MenuButton>
    );

    // If the filter button is being used to display the label, then we don't want
    // to wrap it in a Tooltip. Otherwise, we do so that we can display the label
    // when the user hovers over the button.
    const wrappedMenuButton = showFilterButtonLabel ? (
        menuButton
    ) : (
        <Tooltip content={filterMenuLabel} relationship="label">
            {menuButton}
        </Tooltip>
    );

    return (
        <Menu
            hasIcons={true} // Use this property if any of the items in the menu has an icon so items are aligned
            open={open}
            onOpenChange={onOpenChange}
            checkedValues={checkedValues}
            onCheckedValueChange={onCheckedValueChange}
        >
            <MenuTrigger disableButtonEnhancement={true}>{wrappedMenuButton}</MenuTrigger>
            <MenuPopover>
                <MenuList>{viewFilterMenuItems}</MenuList>
            </MenuPopover>
        </Menu>
    );
}, 'MailListFilterMenu');

async function displayUSQTeachingMoment(anchor: HTMLDivElement) {
    // Add 3 second delay as the biz bar will  render later than this compoonent and
    // put the anchor is ready. We want to render teaching moment after the UI
    // stops moving.
    const showFreeUpMSQSpaceTeachingMoment = await lazyShowFreeUpMSQSpaceTeachingMoment.import();
    setTimeout(() => {
        showFreeUpMSQSpaceTeachingMoment(anchor);
    }, 3000);
}
