import { observer } from 'owa-mobx-react';
import type { AriaProperties } from 'owa-accessibility';
import { AriaRoles, generateDomPropertiesForAria } from 'owa-accessibility';
import StarFilled from 'owa-fluent-icons-svg/lib/icons/StarFilled';
import StarRegular from 'owa-fluent-icons-svg/lib/icons/StarRegular';
import React from 'react';

import { ToggleButton, Tooltip, Spinner } from '@fluentui/react-components';
import { Icon } from '@fluentui/react/lib/Icon';
import {
    clickDisabled,
    container,
    noTextAnimation,
    saved,
    starIconBase,
    showOnlyOnHover as styles_showOnlyOnHover,
    starIcon as styles_starIcon,
} from './StarCharm.scss';

import classnames from 'owa-classnames';

export interface StarCharmProps {
    isStarred: boolean;
    onClick: (evt: React.MouseEvent<unknown>) => void;
    isClickDisabled?: boolean;
    ariaLabelText?: string;
    tooltip?: {
        starred: string;
        unstarred: string;
    };
    animate?: boolean;
    iconStyles?: string;
    buttonStyles?: string;
    spinnerStyles?: string;
    showOnlyOnHover?: boolean;
    isInFolderPane?: boolean;
    useNativeTooltip?: boolean;
    isDisabled?: boolean;
}

export default observer(function StarCharm(props: StarCharmProps) {
    props = {
        tooltip: { starred: '', unstarred: '' },
        iconStyles: styles_starIcon,
        ...props,
    };
    const starIcon = props.isStarred ? StarFilled : StarRegular;
    const starIconClassNames = classnames(
        props.iconStyles,
        starIconBase,
        props.animate && props.isStarred ? saved : undefined,
        props.animate && noTextAnimation
    );

    const tooltip = props.isStarred ? props.tooltip?.starred : props.tooltip?.unstarred;
    const ariaProps: AriaProperties = {
        role: AriaRoles.button,
        label: props.ariaLabelText,
    };
    const buttonClassNames = classnames(
        props.showOnlyOnHover && styles_showOnlyOnHover,
        container,
        props.isClickDisabled && clickDisabled,
        props.buttonStyles
    );
    const onClick = React.useCallback(
        (evt: React.MouseEvent<unknown>) => {
            if (props.isInFolderPane) {
                evt.stopPropagation();
                evt.preventDefault();
            }
            props.onClick(evt);
        },
        [props.isInFolderPane, props.onClick]
    );

    const starButtonIcon = React.useMemo(
        () => <Icon iconName={starIcon} className={starIconClassNames} />,
        [starIcon, starIconClassNames]
    );
    const starButton = React.useMemo(
        () => (
            <ToggleButton
                appearance="transparent"
                className={buttonClassNames}
                title={props.useNativeTooltip ? tooltip : undefined}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                icon={starButtonIcon}
                disabled={props.isDisabled}
                {...generateDomPropertiesForAria(ariaProps)}
            />
        ),
        [starButtonIcon, props.isDisabled]
    );

    // Use Fluent's Tooltip component if:
    // - The button isn't disabled
    // - The native tooltip isn't used
    // - A tooltip is provided
    const shouldUseTooltipWrapper = !props.isDisabled && !props.useNativeTooltip && tooltip;

    return props.isClickDisabled ? (
        <div className={props.spinnerStyles}>
            <Spinner size="tiny" />
        </div>
    ) : shouldUseTooltipWrapper ? (
        <Tooltip content={tooltip} withArrow={true} relationship="label">
            {starButton}
        </Tooltip>
    ) : (
        starButton
    );
}, 'StarCharm');

function onDoubleClick(evt: React.MouseEvent<unknown>) {
    evt.stopPropagation();
    evt.preventDefault();
}
