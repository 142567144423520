let bundleLoadTimeStart: number = 0;

export function getBundleLoadTimeStart() {
    return bundleLoadTimeStart;
}

// Measure the time from the opening of the FlexPane to the start of the Time Panel load
export function onBundleLoadTimeStart(startTime: number) {
    bundleLoadTimeStart = startTime;
}

export function clearBundleLoadTimeStart() {
    bundleLoadTimeStart = 0;
}
