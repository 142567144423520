import type { MailboxInfo } from 'owa-client-types';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';
import { IsShadowMailboxUser } from 'owa-mail-ads-shared/lib/sharedAdsUtils';

let shouldShowMap: Partial<Record<ViewFilter, (mailboxInfo: MailboxInfo) => boolean>>;

function initializeShouldShowMap() {
    shouldShowMap = {
        All: _ => true,
        Flagged: _ => true,
        HasAttachment: _ => true,
        HasCalendarInvite: (mailboxInfo: MailboxInfo) => !isLocalDataBackedMailboxInfo(mailboxInfo),
        HasFile: _ => true,
        Mentioned: (mailboxInfo: MailboxInfo) =>
            !IsShadowMailboxUser(mailboxInfo) && !isLocalDataBackedMailboxInfo(mailboxInfo),
        ToOrCcMe: (mailboxInfo: MailboxInfo) =>
            !IsShadowMailboxUser(mailboxInfo) && !isLocalDataBackedMailboxInfo(mailboxInfo),
        Unread: _ => true,
    };
}

export function shouldShowViewFilterForMailbox(
    viewFilter: ViewFilter,
    mailboxInfo: MailboxInfo
): boolean {
    if (!shouldShowMap) {
        initializeShouldShowMap();
    }

    return (
        shouldShowMap[viewFilter]?.(mailboxInfo) ??
        false /* default to disallow showing if no explicit check exists */
    );
}
