import {
    previewContainer,
    previewDisplayText,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { highlightTermsInHtmlElement } from 'owa-highlight';
import { useMessageListTextStyle } from 'owa-mail-listitem-styles/lib/utils/useMessageListTextStyle';
import type { Accuracy } from 'owa-mark/lib/utils/markElements';
import React from 'react';
import CopilotPrioritizeOnboardedCallout from './CopilotPrioritizeOnboardedCallout';
import { getStore as getMailListStore } from 'owa-mail-list-store';
import getCopilotPrioritizeSettings from 'owa-mail-copilot-settings/lib/selectors/getCopilotPrioritizeSettings';
import type { MailboxInfo } from 'owa-client-types';

export interface MailListItemPreviewDisplayProps {
    highlightTerms: string[];
    previewDisplay: string;
    highlightAccuracy?: Accuracy;
    rowId: string;
    mailboxInfo: MailboxInfo;
}

export default observer(function MailListItemPreviewDisplay(
    props: MailListItemPreviewDisplayProps
) {
    const { highlightTerms, previewDisplay, highlightAccuracy, rowId } = props;
    const rowRef = React.useRef<HTMLDivElement>(null);
    const targetRowId = getMailListStore().copilotPrioritizeOnboardedCalloutTarget;
    const prioritizeEnabled = getCopilotPrioritizeSettings(props.mailboxInfo).prioritizationEnabled;

    const highLightTerms = React.useCallback(
        (element: HTMLElement) => {
            highlightTermsInHtmlElement(
                element,
                highlightTerms,
                false /* separateWordSearch */,
                highlightAccuracy /* highlightAccuracy */
            );
        },
        [highlightTerms, highlightAccuracy]
    );

    return (
        <div className={previewContainer} ref={rowRef}>
            <span
                className={classnames(previewDisplayText, useMessageListTextStyle('Major'))}
                ref={highLightTerms}
            >
                {previewDisplay}
            </span>
            {prioritizeEnabled && targetRowId === rowId && (
                <CopilotPrioritizeOnboardedCallout
                    target={rowRef}
                    id="CopilotPrioritizeOnboarded"
                />
            )}
        </div>
    );
},
'MailListItemPreviewDisplay');
