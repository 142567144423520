import { owaComputedFn } from 'owa-computed-fn';
import getStore from '../store/store';
import type ViewType from '../store/schema/ViewType';
import { isFeatureEnabled } from 'owa-feature-flags';
import shouldShowUnstackedReadingPane from './shouldShowUnstackedReadingPane';

const isMessageListBranchExpansion = owaComputedFn(() => {
    if (isFeatureEnabled('rp-momocoSettings')) {
        const store = getStore();

        // If the new settings are not initialized, fallback to the legacy settings as a hint
        if (store.readingPaneViewType != 3 && store.messageListExpansionType != 3) {
            if (store.messageListExpansionType == 1) {
                return true;
            }

            // If the mac conversation style flight is not on, treat that as branch style
            if (
                !isFeatureEnabled('rp-momocoMacConversations') &&
                store.messageListExpansionType == 0 &&
                store.readingPaneViewType == 2
            ) {
                return true;
            }
        }

        return false;
    }

    return shouldShowUnstackedReadingPane();
});

export default isMessageListBranchExpansion;
