import { createLazyComponent, LazyModule, LazyAction } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ReadWithMe" */ './lazyIndex'),
    { name: 'ReadWithMe' }
);

export const LazyCopilotReadWithMe = createLazyComponent(lazyModule, m => m.CopilotReadWithMe);
export const LazyCopilotSkittle = createLazyComponent(lazyModule, m => m.CopilotSkittle);
export const lazySelectContent = new LazyAction(lazyModule, m => m.onSelectionContentMutator);
export const lazySetShowSkittle = new LazyAction(lazyModule, m => m.setShowSkittleMutator);
export const lazyInitializeReadWithMeSession = new LazyAction(
    lazyModule,
    m => m.initializeReadWithMeSession
);
export const lazyShowReadWithMe = new LazyAction(lazyModule, m => m.showReadWithMeMutator);
export const lazySendReadWithMeRequest = new LazyAction(lazyModule, m => m.sendReadWithMeRequest);
