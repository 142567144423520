import { createStore } from 'owa-satcheljs';

export interface AppPaneUnderlayViewState {
    isShown: Map<string, boolean>;
    shrinkable: Map<string, boolean | undefined>;
    isPlaceholder: Map<string, boolean | undefined>;
}

const appPaneUnderlayViewStateStore = createStore<AppPaneUnderlayViewState>(
    'appPaneUnderlayViewState',
    {
        isShown: new Map(),
        shrinkable: new Map(),
        isPlaceholder: new Map(),
    }
)();

const getAppPaneUnderlayViewStateStore = () => appPaneUnderlayViewStateStore;
export default getAppPaneUnderlayViewStateStore;
