import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { lazyGetAttachmentPreviews } from 'owa-mail-attachment-previews';
import { type TableView } from 'owa-mail-list-store';
import getSelectedTableViewId from 'owa-mail-list-store/lib/utils/getSelectedTableViewId';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { lazyTryPrefetchMeetingMessage } from 'owa-listview-rsvp';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { lazyGovern, GovernPriority } from 'owa-tti';
import { lazyCreateSuggestedUnsubscribeItems } from 'owa-mail-suggested-unsubscribe-store';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';

/**
 * Fetch RichContentForRows on the tableview
 * @param tableView to perform the operation on
 */
export default function fetchRichContentForRows(tableView: TableView) {
    const listViewType = tableView.tableQuery.listViewType;
    const mailboxInfo = getMailboxInfo(tableView);

    if (!isServiceRequestSupportedForMailbox(mailboxInfo)) {
        return;
    }

    if (getUserConfiguration()?.UserOptions?.ShowInlinePreviews) {
        // Get the attachment previews
        lazyGetAttachmentPreviews.importAndExecute(mailboxInfo, listViewType);
    }

    const shouldDelayPrefetch = tableView.id !== getSelectedTableViewId();

    lazyGovern.importAndExecute(
        {
            task: () =>
                lazyTryPrefetchMeetingMessage.importAndExecute(
                    mailboxInfo,
                    listViewType,
                    shouldDelayPrefetch
                ),
            priority: GovernPriority.Messages,
            name: 'tryPrefetchMeetingMessage',
        },
        {
            task: () => lazyCreateSuggestedUnsubscribeItems.importAndExecute(tableView),
            condition:
                isConsumer(undefined /* smtpAddress */, mailboxInfo) &&
                !getAccountScopeUserSettings(mailboxInfo)?.UserOptions?.IsFocusedInboxEnabled,
            priority: GovernPriority.IdleInbox,
            name: 'createSuggestedUnsubscribeItems',
        }
    );
}
