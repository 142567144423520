import {
    type ImplementedCopilotFeatures,
    OUTLOOK_ADDIN_TITLE_ID,
    isSalesCopilotAddin,
} from 'owa-copilot-settings-store';
import { isGeneric1PAddinSupported } from 'owa-copilot-capabilityparams';
import { type LocalizedString, format } from 'owa-localize';
import { getStringsForScenarioAndTitleId } from './getAddinsAvailableForFeature';
import { type MailboxInfo } from 'owa-client-types';

export function getAddinPickerTeachingBubbleMessage(
    currentAddinId: string | undefined,
    scenarioName: ImplementedCopilotFeatures,
    outlookModeMessage: LocalizedString,
    addinModeGenericMessage: LocalizedString,
    salesSpecificMessage: LocalizedString,
    mailboxInfo: MailboxInfo
) {
    if (currentAddinId !== OUTLOOK_ADDIN_TITLE_ID) {
        const addinName = !!currentAddinId
            ? getStringsForScenarioAndTitleId(scenarioName, currentAddinId, mailboxInfo)
                  ?.fallbackAddinName
            : undefined;
        if (addinName) {
            if (isGeneric1PAddinSupported(mailboxInfo)) {
                return format(addinModeGenericMessage, addinName);
            } else if (currentAddinId && isSalesCopilotAddin(currentAddinId, mailboxInfo)) {
                return salesSpecificMessage;
            }
        }
    }

    return outlookModeMessage;
}
