import type { MailboxInfo } from 'owa-client-types';
import { default as folderStore } from 'owa-folders';
import { isMailboxSameAccountAsGlobalSettingsAccount } from 'owa-account-source-list-store';

export function isPrimaryAccountFromMailboxInfo(mailboxInfo?: MailboxInfo): boolean {
    // default to primary account in case mailboxInfo is null or undefined
    return mailboxInfo ? isMailboxSameAccountAsGlobalSettingsAccount(mailboxInfo) : true;
}

export function isPrimaryAccountFromFolderId(folderId: string): boolean {
    const folder = folderStore.folderTable.get(folderId);

    return isPrimaryAccountFromMailboxInfo(folder?.mailboxInfo);
}
