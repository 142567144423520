import type { NovaEvent } from '@nova/types';
import type { PageNames } from './OpenPersonSources';
import type { AppHostServiceEventOriginator } from './originator';
import type { FluentIcon } from '@fluentui/react-icons';

export const ShowToastEventType = 'showToast' as const;

export interface ShowToastEvent extends NovaEvent<ShowToastEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof ShowToastEventType;
    data: () => ShowToastEventData;
}

export interface ShowToastEventData {
    intent: 'success' | 'warning' | 'error' | 'info' | 'progress';
    message: string;
    action?: string;
    duration?: number;
    targetWindowId?: string;
    toastId?: string;
    activePage?: PageNames;
    toastIcon?: FluentIcon;
    toastDescription?: string;
}
