import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import isBusiness from 'owa-session-store/lib/utils/isBusiness';
import { isMailboxSharedOrDelegate } from 'owa-account-shared-mailbox-utils';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used for show/hide the archive
 */
export const archiveCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (
            mailboxInfo &&
            isMailboxSharedOrDelegate(mailboxInfo) &&
            isMonarchMultipleAccountsEnabled()
        ) {
            // Archives are only supported in multi account mode if this flight is on
            return isFeatureEnabled('fp-archive-shared-mailboxes');
        }

        if (isBusiness(mailboxInfo)) {
            // Business accounts that support service request will have
            // archive mailboxes
            return isServiceRequestSupportedForMailbox(mailboxInfo);
        }

        return false;
    },
};
