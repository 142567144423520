import { createStore } from 'owa-satcheljs';
import type { AppHostStore } from './schema/AppHostStore';
export default createStore<AppHostStore>('AppHostStore', {
    hostedApp: undefined,
    selectedEntityId: '',
    selectedSubEntityId: '',
    subEntityLabel: '',
    isInError: false,
    sharePointSiteInfo: new Map(),
    userClickTime: 0,
});
