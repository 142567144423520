import { LazyModule, LazyAction, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Options" */ './lazyIndex'), {
    name: 'Options',
});

export const lazyConfirmOptionNavigation = new LazyAction(
    lazyModule,
    m => m.confirmOptionNavigation
);
export const OptionsEditor = createLazyComponent(lazyModule, m => m.OptionsEditor);

export type {
    default as Option,
    CoreOption,
    LazyOption,
    SearchTermMap,
} from './store/schema/Option';
export type { default as OptionCategory } from './store/schema/OptionCategory';
export type { default as OptionSubCategory } from './store/schema/OptionSubCategory';
export type {
    default as OptionComponentSettings,
    OptionComponentCallbackProps,
} from './store/schema/OptionComponentSettings';
export { default as SubCategoryState } from './store/schema/SubCategoryState';

// Export service helpers
export { default as setMailboxMessageConfiguration } from './services/setMailboxMessageConfiguration';

// Export utils
export {
    default as getFallbackValueIfNull,
    getFallbackValueIfNullOrUndefined,
} from './utils/getFallbackValueIfNull';
export { createLazyOption } from './utils/createLazyOption';
export { createOption } from './utils/createOption';
export { tryLoadAllFullOptionsSync, type FullOption } from './utils/tryLoadAllFullOptionsSync';
export { getIsConfirmationDialogOpen } from './utils/getIsConfirmationDialogOpen';

// Export public actions
export { hideFullOptions } from './actions/publicActions';
export type { ExternalMessageEditorProps } from './components/OptionsEditor';
export { setIsConfirmationDialogOpenMutator } from './actions/mutators/setIsConfirmationDialogOpen';
