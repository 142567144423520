import { rangeSelectRow, toggleSelectRow } from 'owa-mail-actions/lib/mailListSelectionActions';
import { singleSelectRow } from 'owa-mail-actions/lib/singleSelectRow';
import { onSingleMailItemSelected } from 'owa-mail-actions/lib/mailListActions';
import { getIsSearchTableShown } from 'owa-mail-list-store';
import type { TableView } from 'owa-mail-list-store';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import type React from 'react';
import { isMac } from 'owa-user-agent';
import { isReadingPanePositionOff } from 'owa-mail-layout/lib/selectors/readingPanePosition';
import { setInteractionTimestamp } from 'owa-mail-logging/lib/utils/selectMailItemDatapointUtils';
import { isFeatureEnabled } from 'owa-feature-flags';
import { addToTimingMap } from 'owa-performance/lib/utils/timingMap';
import { isSecondLevelExpanded } from 'owa-mail-list-store/lib/selectors/isConversationExpanded';

/**
 * A handler for events created by user clicking mail list item(s)
 * @param evt the mouse event
 * @param selectionSource the source of event
 * @param rowKey the given conversation rowKeys
 * @param tableViewId - the table view id that this conversation belong to (or null if we don't care)
 * @returns a promise with boolean value based on if we have handled the event here
 */
export default function onMailListItemClickHandler(
    evt: React.MouseEvent<unknown> | KeyboardEvent | React.KeyboardEvent<unknown>,
    selectionSource: MailListItemSelectionSource,
    rowKey: string,
    tableViewId: string
): void {
    evt.stopPropagation();

    debounce(
        (simulateDoubleClick?: boolean) => {
            const selectionSourceToUse = simulateDoubleClick
                ? MailListItemSelectionSource.MailListItemSimulatedDoubleClick
                : selectionSource;
            onMailListItemClickHandlerInternal(evt, selectionSourceToUse, rowKey, tableViewId);
        },
        selectionSource === MailListItemSelectionSource.MailListItemBodyDoubleClick,
        rowKey
    );
}

function onMailListItemClickHandlerInternal(
    evt: React.MouseEvent<unknown> | KeyboardEvent | React.KeyboardEvent<unknown>,
    selectionSource: MailListItemSelectionSource,
    rowKey: string,
    tableViewId: string
) {
    // If the OS is a Mac, use evt.metaKey to detect Cmd key
    const isCtrlOrCmdKeyDown = isMac() ? evt.metaKey : evt.ctrlKey;
    const tableView = getListViewStore().tableViews.get(tableViewId);
    const eventTimeStamp = (evt as React.MouseEvent<unknown> | React.KeyboardEvent<unknown>)
        .nativeEvent.timeStamp;

    // If this is a shift click, do a range select
    if (evt.shiftKey) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (40,24): Argument of type 'TableView | undefined' is not assignable to parameter of type 'TableView'.
        // @ts-expect-error
        rangeSelectRow(tableView, rowKey, selectionSource, isCtrlOrCmdKeyDown);
        return;
    }

    // Check the row if the user is holding down the Ctrl key, clicked on the checkbox, or is reselecting the current row
    if (
        isCtrlOrCmdKeyDown ||
        selectionSource === MailListItemSelectionSource.MailListItemCheckbox ||
        shouldToggleSelectRowForReselectBehavior(tableView, selectionSource, rowKey)
    ) {
        tableView &&
            toggleSelectRow(tableView, rowKey, true /* isUserNavigation */, selectionSource);
        return;
    }

    if (tableView) {
        setInteractionTimestamp(eventTimeStamp);
        singleSelectRow(
            tableView,
            rowKey,
            true /* isUserNavigation */,
            selectionSource,
            evt.timeStamp
        );
    }

    const isExplicitSelect =
        selectionSource !== MailListItemSelectionSource.MailListItemRichPreview;

    if (isExplicitSelect && !getIsSearchTableShown()) {
        onSingleMailItemSelected();
    }
}

let timer: ReturnType<typeof setInterval>;
let pendingClickedRowKey: string = ''; // The rowKey of the clicked item waiting to be processed
const DEBOUNCE_DELAY = 200; // in ms

/**
 * Execute the callback and reset the pendingClickedRowKey (since the click is
 * no longer pending).
 *
 * @param callback the callback to execute
 * @param simulateDoubleClick if the user is double clicking
 */
const executeCallback = (callback: any, simulateDoubleClick: boolean = false) => {
    callback(simulateDoubleClick);
    pendingClickedRowKey = '';
    clearTimeout(timer);
};

/**
 * Debounce the callback if the user is clicking on the same row.
 *
 * @param callback the callback to execute
 * @param isDoubleClick if the user is double clicking
 * @param rowKey the rowKey of the clicked item
 */
const debounce = (callback: any, isDoubleClick: boolean, rowKey: string) => {
    // If there is a pending click, we need to check if the user is double clicking.
    if (pendingClickedRowKey) {
        clearTimeout(timer);

        // If the user is double clicking, we don't need to debounce.
        if (rowKey === pendingClickedRowKey) {
            executeCallback(callback, true /* simulateDoubleClick */);
            return;
        }
    }

    // If reading pane is on (or if an actual double click), we don't need
    // to debounce.
    if (isDoubleClick || !isReadingPanePositionOff()) {
        executeCallback(callback);
    } else {
        // Track the rowKey of the clicked item and debounce the callback.
        pendingClickedRowKey = rowKey;

        // If the user doesn't click again within DEBOUNCE_DELAY ms, we'll
        // process the click.
        timer = setTimeout(() => {
            addToTimingMap('setTimeout', 'onMailListItemClickHandler');
            executeCallback(callback);
        }, DEBOUNCE_DELAY);
    }
};

const shouldToggleSelectRowForReselectBehavior = (
    tableView: TableView | undefined,
    selectionSource: MailListItemSelectionSource,
    rowKey: string
) => {
    // If the reselect flight is on and one item is selected and the user clicks on the same item
    // We should toggle the selection if the user is not clicking on the twisty or double clicking (opening in popout)
    if (
        isFeatureEnabled('tri-reselect-checkbox-behavior') &&
        tableView &&
        tableView.selectedRowKeys?.size == 1 &&
        tableView.selectedRowKeys?.has(rowKey)
    ) {
        if (
            selectionSource !== MailListItemSelectionSource.MailListItemBodyDoubleClick &&
            selectionSource !== MailListItemSelectionSource.MailListItemSimulatedDoubleClick &&
            selectionSource !== MailListItemSelectionSource.MailListItemTwisty &&
            !isReadingPanePositionOff()
        ) {
            return true;
        } else if (
            !tableView.isInCheckedMode &&
            selectionSource == MailListItemSelectionSource.MailListItemTwisty &&
            isSecondLevelExpanded(rowKey)
        ) {
            return true;
        }
    }

    return false;
};
