import { AccountSourceType } from 'owa-account-source-list-types';
import { isBootFeatureEnabled } from 'owa-metatags';

export enum SyncOptions {
    LocalOnly = 'Local',
    ApplicationOnly = 'App',
    AllOutlooks = 'AllOutlooks',
}

export function syncOptionsFromAccountSourceType(accountSourceType: AccountSourceType) {
    if (isBootFeatureEnabled('acct-roaming')) {
        return accountSourceType === AccountSourceType.PstFile
            ? SyncOptions.LocalOnly
            : SyncOptions.ApplicationOnly;
    }

    return SyncOptions.LocalOnly;
}
