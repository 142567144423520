import getSearchProvider from '../selectors/getSearchProvider';
import { SearchProvider } from 'owa-search-service/lib/data/schema/SearchProvider';
import store from '../store/store';

export default function isAnswerFeatureEnabled() {
    return true;
    // While this is now always true and the calls to it could be removed,
    // we are keeping it to avoid unnecessary code changes, given that full answers code removal is imminent
}

export function isSingleCallForAnswersAndSerpEnabled(): boolean {
    return getSearchProvider(store.staticSearchScope) === SearchProvider.SubstrateV2;
}
