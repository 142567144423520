import { owaComputedFn } from 'owa-computed-fn';
import { getComposeHostItemIndex } from 'owa-addins-store/lib/utils/hostItemIndexUtils';
import getAddinCollection from 'owa-addins-surface-actions/lib/utils/getAddinCollection';
import {
    storePollFilter,
    invalidAddInsFilter,
} from 'owa-addins-surface-actions/lib/utils/getAddinFilters';
import { ExtensibilityModeEnum } from 'owa-addins-types';
import { getDefaultRibbonStyles } from 'owa-command-ribbon-styles/lib/util/getDefaultRibbonStyles';
import {
    createPinnedAddInButton,
    getReadRibbonRuntimeControlProps,
} from 'owa-mail-compose-controls/lib/components/pinnedAddIns';
import {
    retrieveCommonInfoForRibbon,
    retrieveWindowForRibbon,
} from 'owa-mail-compose-controls/lib/utils/retrieveEditingInfoForRibbon';
import getServerOptionsForFeature from 'owa-outlook-service-option-store/lib/selectors/getOptionsForFeature';
import { OwsOptionsFeatureType } from 'owa-outlook-service-option-store/lib/store/schema/OwsOptionsFeatureType';

import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import type { SurfaceActionsOptions } from 'owa-outlook-service-option-store';
import type { ComposeViewState, SharedFolderComposeViewState } from 'owa-mail-compose-store';
import type { RuntimeControlId } from 'owa-ribbon-ids';
import type { RibbonRuntimeControlsGroup, RibbonRuntimeControl } from './getRuntimeControls';
import { isSharedComposeItemCheckForAddins } from 'owa-mail-store/lib/utils/sharedFolderUtilsForAddins';
import createMessageExtensionCommandSurfaceItem from 'owa-addins-view/lib/utils/createMessageExtensionCommandSurfaceItem';
import { readM365AcquisitionsFromCache } from 'owa-m365-acquisitions/lib/readM365AcquisitionsFromCache';
import { hasComposeMessageExtension } from 'owa-m365-acquisitions/lib/utils/hasMessageExtension';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isAddinMultiAccountEnabled } from 'owa-feature-flags';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';
import { logUsage } from 'owa-analytics';
import { getExtensionId } from 'owa-m365-acquisitions/lib/utils/getExtensionId';
import {
    hasExchangeAddIn,
    hasExchangeAddInComposeCommand,
    hasExchangeAddInReadCommand,
    hasExchangeAddInMultiSelectCommand,
    hasExchangeAddinNoContextCommand,
} from 'owa-m365-acquisitions/lib/utils/hasExchangeAddIn';
import {
    hasExtensionAddInComposeCommand,
    hasExtensionAddInReadCommand,
    hasExtensionAddinSharedFolders,
    hasExtensionAddInMultiSelectCommand,
    hasExtensionAddinNoContextCommand,
} from 'owa-m365-acquisitions/lib/utils/hasExtensionAddIn';
import type { M365Acquisition } from 'owa-m365-acquisitions/lib/types';
import type { MailboxInfo } from 'owa-client-types';
import getExtensibilityState from 'owa-addins-store/lib/store/getExtensibilityState';
import type { Extension } from 'owa-addins-contracts';
import {
    LoadState,
    NetworkCallState,
} from 'owa-m365-acquisitions/lib/store/schema/cachedAcquisitionsState';
import { getCachedAcquisitionsState } from 'owa-m365-acquisitions/lib/store/getCachedAcquisitionsState';
import type { RuntimeControlRibbonCapabilites } from 'owa-m365-acquisitions/lib/utils/runtimeControlRibbonCapabilites';
import { isAccountExplicitLogon } from 'owa-account-shared-mailbox-utils';

const getComposeRibbonPinnedApps = owaComputedFn(function getRibbonPinnedAddIns(
    viewState: ComposeViewState,
    targetWindow: Window
): AddinCommandSurfaceItem[] {
    const mailboxInfo = viewState.mailboxInfo;
    let pinnedApps: AddinCommandSurfaceItem[] = [];

    const pinnedAddInsIds: string[] = getServerOptionsForFeature<SurfaceActionsOptions>(
        OwsOptionsFeatureType.SurfaceActions,
        viewState.mailboxInfo
    ).composeSurfaceAddins;

    // if MOS3 is enabled but unavailable, we need addins instead of acquisitions
    // (and readM365AcquisitionsFromCache should return nothing below)
    if (!isMOS3AppServiceAvailable(mailboxInfo)) {
        const hostItemIndex = getComposeHostItemIndex(viewState.composeId);
        const isSharedItem = isSharedComposeItemCheckForAddins(
            (viewState as SharedFolderComposeViewState)?.isInSharedFolder,
            viewState.mailboxInfo
        );

        pinnedApps = getAddinCollection(
            ExtensibilityModeEnum.MessageCompose,
            isSharedItem,
            hostItemIndex,
            viewState.mailboxInfo,
            targetWindow
        ).filter(
            item =>
                invalidAddInsFilter(item) &&
                storePollFilter(item, viewState.mailboxInfo) &&
                pinnedAddInsIds.includes(item.key)
        );
    }

    // this function is gated by isMessageExtensionsFlyoutEnabled which checks mos-mos3AppService
    const acquisitions = readM365AcquisitionsFromCache(
        undefined /* datapoint */,
        isAddinMultiAccountEnabled() ? mailboxInfo : undefined
    );

    for (const acquisition of acquisitions) {
        const extensionId = getExtensionId(acquisition);
        if (
            (hasComposeMessageExtension(acquisition) ||
                hasExchangeAddInComposeCommand(acquisition) ||
                hasExtensionAddInComposeCommand(acquisition)) &&
            pinnedAddInsIds.some(surfaceId => surfaceId.toLowerCase() === extensionId) &&
            isExtensionEnabledForMailboxType(extensionId, acquisition, mailboxInfo)
        ) {
            // We only add the add-in if it doesn't have any duplicate in the list
            if (!pinnedApps.some(addIn => addIn.key.toLowerCase() === extensionId)) {
                pinnedApps.push(
                    createMessageExtensionCommandSurfaceItem({
                        id: extensionId,
                        name: acquisition.titleDefinition.name ?? '',
                        description: acquisition.titleDefinition.shortDescription ?? '',
                        iconUrl:
                            acquisition.titleDefinition.iconLarge?.uri ??
                            acquisition.titleDefinition.iconSmall?.uri ??
                            '',
                    })
                );
            } else {
                /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops -- (https://aka.ms/OWALintWiki)
                 * Baseline, this function can't be used inside a loop, please move it outside
                 *	> Function 'logUsage' matches forbidden pattern (/logUsage/) and should not be used inside loops */
                logUsage('Duplicate_AddIn_Found_RibbonPinnedList');
            }
        }
    }

    return pinnedApps;
});

let previousPinnedApps:
    | {
          addIn: AddinCommandSurfaceItem;
          ribbonContext: RuntimeControlRibbonCapabilites;
      }[]
    | null = null;
let previousKeys: Set<string> | null = null;

const getReadRibbonPinnedApps = owaComputedFn(function getReadRibbonPinnedAddIns(): {
    addIn: AddinCommandSurfaceItem;
    ribbonContext: RuntimeControlRibbonCapabilites;
}[] {
    const pinnedApps: {
        addIn: AddinCommandSurfaceItem;
        ribbonContext: RuntimeControlRibbonCapabilites;
    }[] = [];
    const currentKeys = new Set<string>();

    const mailboxInfo = getModuleContextMailboxInfo();

    const pinnedAddInsIds: string[] = getServerOptionsForFeature<SurfaceActionsOptions>(
        OwsOptionsFeatureType.SurfaceActions,
        mailboxInfo
    ).readSurfaceAddins;

    // Only readM365AcquisitionsFromCache if we have access to the pinnedAddInsIds
    if (pinnedAddInsIds.length != 0) {
        const cachedAcquisitionsState = getCachedAcquisitionsState(mailboxInfo);

        const acquisitions = readM365AcquisitionsFromCache(
            undefined /* datapoint */,
            isAddinMultiAccountEnabled() ? mailboxInfo : undefined
        );

        if (
            cachedAcquisitionsState.loadState === LoadState.Loaded ||
            cachedAcquisitionsState.networkCallState === NetworkCallState.Completed
        ) {
            for (const acquisition of acquisitions) {
                const extensionId = getExtensionId(acquisition);
                if (
                    (hasExtensionAddInReadCommand(acquisition) ||
                        hasExchangeAddInReadCommand(acquisition)) &&
                    pinnedAddInsIds.some(surfaceId => surfaceId.toLowerCase() === extensionId) &&
                    isExtensionEnabledForMailboxType(extensionId, acquisition, mailboxInfo)
                ) {
                    pinnedApps.push({
                        addIn: createMessageExtensionCommandSurfaceItem({
                            id: extensionId,
                            name: acquisition.titleDefinition.name ?? '',
                            description: acquisition.titleDefinition.shortDescription ?? '',
                            iconUrl:
                                acquisition.titleDefinition.iconLarge?.uri ??
                                acquisition.titleDefinition.iconSmall?.uri ??
                                '',
                        }),
                        ribbonContext: {
                            isEnabledInNoContext:
                                hasExchangeAddinNoContextCommand(acquisition) ||
                                hasExtensionAddinNoContextCommand(acquisition),
                            isEnabledInMultiSelect:
                                hasExchangeAddInMultiSelectCommand(acquisition) ||
                                hasExtensionAddInMultiSelectCommand(acquisition),
                        },
                    });

                    currentKeys.add(extensionId);
                }
            }
        }
    }

    if (previousKeys && arePinnedAppsEqual(previousKeys, currentKeys) && previousPinnedApps) {
        // If keys are the same, return the cached pinnedApps
        return previousPinnedApps;
    }

    // Update the cache
    previousKeys = currentKeys;
    previousPinnedApps = pinnedApps;

    return pinnedApps;
});

function arePinnedAppsEqual(setA: Set<string>, setB: Set<string>): boolean {
    if (setA.size !== setB.size) {
        return false;
    }
    for (const item of setA) {
        if (!setB.has(item)) {
            return false;
        }
    }
    return true;
}

function isExtensionEnabledForMailboxType(
    extensionId: string,
    acquisition: M365Acquisition,
    mailboxInfo: MailboxInfo
): boolean {
    if (mailboxInfo.type !== 'SharedMailbox' && !isAccountExplicitLogon(mailboxInfo)) {
        return true;
    }

    if (hasExchangeAddIn(acquisition)) {
        const addinsMap = getExtensibilityState(mailboxInfo).addinsMap;
        const addin = addinsMap.get(extensionId.toLowerCase()) as Extension;
        return addin?.ExtensionPointCollection?.SupportsSharedFolders ?? false;
    }

    return hasExtensionAddinSharedFolders(acquisition);
}

export function getPinnedAddInsRuntimeControls(): RibbonRuntimeControlsGroup {
    const ribbonPinnedAddIns = new Map<string, RibbonRuntimeControl>();
    let PINNED_ADDIN_CONTROL_ID: RuntimeControlId.ComposePinnedAddIns = 90000;

    const runtimeControlGroup: RibbonRuntimeControlsGroup = {
        controlsGroupName: 'ComposeAddInsPinned',
        shouldAddScalingSteps: () => {
            return true;
        },
        getComposeControlsProps: (props: { composeViewState?: ComposeViewState }) => {
            const viewState = props.composeViewState;
            if (!viewState) {
                return [];
            }
            const defaultStyles = getDefaultRibbonStyles();
            const targetWindow =
                retrieveCommonInfoForRibbon(viewState.composeId)?.targetWindow ?? window;

            const pinnedAddins = getComposeRibbonPinnedApps(viewState, targetWindow);
            return pinnedAddins.map(addIn => {
                let controlId = ribbonPinnedAddIns.get(addIn.key)?.controlId;
                if (!controlId) {
                    controlId = ++PINNED_ADDIN_CONTROL_ID;
                }

                const runtimeControl: RibbonRuntimeControl = {
                    controlId,
                    buttonProps: createPinnedAddInButton(
                        addIn,
                        viewState.composeId,
                        viewState.editorId,
                        controlId,
                        targetWindow,
                        defaultStyles
                    ),
                };
                ribbonPinnedAddIns.set(addIn.key, runtimeControl);
                return runtimeControl;
            });
        },
        getReadControlsProps() {
            return [];
        },
        getControlIds() {
            return Array.from(ribbonPinnedAddIns.values()).map(({ controlId }) => controlId);
        },
        getNumControls() {
            return ribbonPinnedAddIns.size;
        },
    };
    return runtimeControlGroup;
}

export function getReadPinnedAppsRuntimeControls(): RibbonRuntimeControlsGroup {
    const ribbonPinnedApps = new Map<string, RibbonRuntimeControl>();
    let PINNED_ADDIN_CONTROL_ID: RuntimeControlId.ReadPinnedAddins = 90500;

    const runtimeControlGroup: RibbonRuntimeControlsGroup = {
        controlsGroupName: 'ReadAppsPinned',
        shouldAddScalingSteps: () => {
            return true;
        },
        getComposeControlsProps: () => {
            return [];
        },
        getReadControlsProps: owaComputedFn(props => {
            const defaultStyles = getDefaultRibbonStyles();
            const targetWindow = retrieveWindowForRibbon(props.projectionTabId) ?? window;

            const pinnedApps = getReadRibbonPinnedApps();
            return pinnedApps.map(({ addIn, ribbonContext }) => {
                let controlId = ribbonPinnedApps.get(addIn.key)?.controlId;
                if (!controlId) {
                    controlId = ++PINNED_ADDIN_CONTROL_ID;
                }

                const runtimeControl: RibbonRuntimeControl = {
                    controlId,
                    buttonProps: getReadRibbonRuntimeControlProps(
                        addIn,
                        controlId,
                        targetWindow,
                        defaultStyles,
                        props,
                        ribbonContext
                    ),
                };
                ribbonPinnedApps.set(addIn.key, runtimeControl);
                return runtimeControl;
            });
        }),
        getControlIds() {
            return Array.from(ribbonPinnedApps.values()).map(({ controlId }) => controlId);
        },
        getNumControls() {
            return getReadRibbonPinnedApps().length;
        },
    };
    return runtimeControlGroup;
}
