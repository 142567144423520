import { getStoreByMailboxInfo } from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import type ReportSubmissionPolicy from '../types/ReportSubmissionPolicy';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const onReportSubmissionPolicyFetched = mutatorAction(
    'onReportSubmissionPolicyFetched',
    (mailboxInfo: MailboxInfo, reportSubmissionPolicy: ReportSubmissionPolicy) => {
        const reportingStore = getStoreByMailboxInfo(mailboxInfo);
        reportingStore.reportSubmissionPolicy = reportSubmissionPolicy;
    }
);

export default onReportSubmissionPolicyFetched;
