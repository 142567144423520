import { LazyAction, LazyBootModule } from 'owa-bundling-light';

const lazyBootModule = new LazyBootModule(
    () => import(/* webpackChunkName: "NonBootConfigGql" */ './lazyIndex'),
    { name: 'NonBootConfigGql' }
);

export const lazyGetNonBootUserConfigurationViaGql = new LazyAction(
    lazyBootModule,
    m => m.getNonBootUserConfigurationViaGql
);
