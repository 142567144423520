import { owaComputedFn } from 'owa-computed-fn';
import { getComposeHostItemIndex } from 'owa-addins-store/lib/utils/hostItemIndexUtils';
import getAddinCollection from 'owa-addins-surface-actions/lib/utils/getAddinCollection';
import {
    storePollFilter,
    invalidAddInsFilter,
} from 'owa-addins-surface-actions/lib/utils/getAddinFilters';
import { ExtensibilityModeEnum } from 'owa-addins-types';
import { getDefaultRibbonStyles } from 'owa-command-ribbon-styles/lib/util/getDefaultRibbonStyles';
import { createPinnedAddInButton } from 'owa-mail-compose-controls/lib/components/pinnedAddIns';
import { retrieveCommonInfoForRibbon } from 'owa-mail-compose-controls/lib/utils/retrieveEditingInfoForRibbon';

import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import type { ComposeViewState, SharedFolderComposeViewState } from 'owa-mail-compose-store';
import type { RuntimeControlId } from 'owa-ribbon-ids';
import type { RibbonRuntimeControlsGroup, RibbonRuntimeControl } from './getRuntimeControls';
import { isSharedComposeItemCheckForAddins } from 'owa-mail-store/lib/utils/sharedFolderUtilsForAddins';

const getInstalledAddIns = owaComputedFn(function getInstalledAddIns(
    viewState: ComposeViewState,
    targetWindow: Window
): AddinCommandSurfaceItem[] {
    const hostItemIndex = getComposeHostItemIndex(viewState.composeId);
    const isSharedItem = isSharedComposeItemCheckForAddins(
        (viewState as SharedFolderComposeViewState)?.isInSharedFolder,
        viewState.mailboxInfo
    );

    return getAddinCollection(
        ExtensibilityModeEnum.MessageCompose,
        isSharedItem,
        hostItemIndex,
        viewState.mailboxInfo,
        targetWindow
    )
        .filter(invalidAddInsFilter)
        .filter(item => storePollFilter(item, viewState.mailboxInfo));
});

export function getInstalledAddInsRuntimeControls(): RibbonRuntimeControlsGroup {
    const ribbonInstalledAddIns = new Map<string, RibbonRuntimeControl>();
    let INSTALLED_ADDIN_CONTROL_ID: RuntimeControlId.InstalledAddIns = 91000;

    const runtimeControlGroup: RibbonRuntimeControlsGroup = {
        controlsGroupName: 'ComposeAddInsInstalled',
        shouldAddScalingSteps() {
            return false;
        },
        getComposeControlsProps: owaComputedFn(
            function getControlsPropsFromGetInstalledAddinsRuntimeControls(props) {
                const viewState = props.composeViewState;
                if (!viewState) {
                    return [];
                }
                const defaultStyles = getDefaultRibbonStyles();
                const targetWindow =
                    retrieveCommonInfoForRibbon(viewState.composeId)?.targetWindow ?? window;

                const installedAddins = getInstalledAddIns(viewState, targetWindow);
                return installedAddins.map(addIn => {
                    let controlId = ribbonInstalledAddIns.get(addIn.key)?.controlId;
                    if (!controlId) {
                        controlId = ++INSTALLED_ADDIN_CONTROL_ID;
                    }

                    const runtimeControl: RibbonRuntimeControl = {
                        controlId,
                        buttonProps: createPinnedAddInButton(
                            addIn,
                            viewState.composeId,
                            viewState.editorId,
                            controlId,
                            targetWindow,
                            defaultStyles
                        ),
                    };
                    ribbonInstalledAddIns.set(addIn.key, runtimeControl);
                    return runtimeControl;
                });
            }
        ),
        getReadControlsProps() {
            return [];
        },
        getControlIds() {
            return Array.from(ribbonInstalledAddIns.values()).map(({ controlId }) => controlId);
        },
        getNumControls() {
            return ribbonInstalledAddIns.size;
        },
    };
    return runtimeControlGroup;
}
