export type MeetingChatEnabledType = 'Disabled' | 'Enabled' | 'EnabledExceptAnonymous';

export interface OwaTeamsPolicies {
    teamsUpgraded: boolean;
    allowUserChat: boolean;
    allowMeetNow: boolean;
    meetingChatEnabledType: MeetingChatEnabledType;
    allowPrivateMeetingScheduling: boolean;
    teamsFeatureEnabled: boolean;
    allowOutlookAddIn: boolean;
    allowPrivateMeetNow: boolean;
    allowChannelMeetingScheduling: boolean;
    allowWebinars: string;
    allowTownhalls: string;
    allowBroadcastScheduling: boolean;
}

export enum FetchState {
    NotStarted,
    Fetching,
    Succeeded,
    Failed,
    Retry,
}

// Add new enum values at the end of this enum for new scenarios
export enum TeamsPoliciesScenario {
    MailPostBoot = 'MailPostBoot',
    MailEmbedChat = 'MailEmbedChat',
    MailMeetNow = 'MailMeetNow',
    CalendarMyDayInit = 'CalendarMyDayInit',
    CalendarReadingPane = 'CalendarReadingPane',
    CalendarPeekEvent = 'CalendarPeekEvent',
    CalendarItemContextMenu = 'CalendarItemContextMenu',
    CalendarMeetingTemplate = 'CalendarMeetingTemplate',
    EmbedChatCard = 'EmbedChatCard',
    MeetingChatCard = 'MeetingChatCard',
    CalFormsTeamsChatCard = 'CalFormsTeamsChatCard',
    SkypeForBusiness = 'SkypeForBusiness',
    OWATabSearch = 'OWATabSearch',
    Presence = 'Presence',
    SyncModule = 'SyncModule',
    CalendarPostBoot = 'CalendarPostBoot',
}

export interface OwaTeamsPoliciesStore {
    policies: OwaTeamsPolicies | null;
    fetchState: FetchState;
}
