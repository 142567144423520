import { createStore } from 'owa-satcheljs';
import type { GroupListNodesViewStateStore } from './schema/GroupListNodesViewStateStore';
import type { GroupListNodesViewStateByMailBoxInfo } from './schema/GroupListNodesViewStateByMailBoxInfo';
import type { AccountKey } from 'owa-client-types';

const initialGroupListNodesViewStateByMailboxInfo: GroupListNodesViewStateByMailBoxInfo = {
    groupListNodesViewStatesByMailBoxInfo: new Map<AccountKey, GroupListNodesViewStateStore>([]),
};

export const getGroupListNodesViewState = createStore<GroupListNodesViewStateByMailBoxInfo>(
    'groupListNodesViewStateByMailBoxInfo',
    initialGroupListNodesViewStateByMailboxInfo
);
