import { ConvertableIdFormat } from '../schema';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import getIsImmutableIdFeatureOnForConnectedAccount from '../selectors/getIsImmutableIdFeatureOnForConnectedAccount';
import { isMonarchMultipleAccountsEnabled } from 'owa-accounts-store';
import { isGlobalSettingsMailbox } from 'owa-account-source-list-store';

export function getTargetFormat(mailboxInfo: MailboxInfo): ConvertableIdFormat {
    if (isMonarchMultipleAccountsEnabled() || isGlobalSettingsMailbox(mailboxInfo)) {
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'fwk-immutable-ids' */
        return isFeatureEnabled(
            'fwk-immutable-ids',
            mailboxInfo,
            true /* dontThrowErrorIfNotInitialized */
        )
            ? ConvertableIdFormat.EwsImmutableId
            : ConvertableIdFormat.EwsId;
    }

    // OWA connected account
    return getIsImmutableIdFeatureOnForConnectedAccount()
        ? ConvertableIdFormat.EwsImmutableId
        : ConvertableIdFormat.EwsId;
}
