import { createStore } from 'owa-satcheljs';
import type { DialogHostStore } from '../store/schema/DialogHostStore';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Required for performance due to JSX usage
import { makeObservable, observable } from 'mobx';

export function createDialogHostStore(): DialogHostStore {
    const store: DialogHostStore = {
        dialogs: new Map(),
    };

    makeObservable(store, {
        dialogs: observable.shallow,
    });

    return store;
}

export const getStore = createStore<DialogHostStore>('DialogHostStore', createDialogHostStore());
