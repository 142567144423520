import { mutatorAction, action } from 'satcheljs';
import categoryStore, { getCopilotCategoriesList } from '../store/store';
import getMasterCategoryList from '../utils/getMasterCategoryList';
import type { MailboxInfo } from 'owa-client-types';
import type CopilotCategoryType from '../store/schema/CopilotCategoryType';
import { setItem, type LocalStorageKeys } from 'owa-local-storage';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

/**
 * Sets the checkbox value for enabling copilot category
 * @param isChecked the checkbox value
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setCopilotCategoryEnabled = mutatorAction(
    'setCopilotCategoryEnabled',
    (isChecked: boolean) => {
        categoryStore.categoryDialogViewState.isCopilotCategory = isChecked;
    }
);

/**
 * Sets the category description for copilot category
 * @param copilotCategoryDescription the prompt text
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setCopilotCategoryDescription = mutatorAction(
    'setCopilotTeachingText',
    (copilotCategoryDescription: string) => {
        categoryStore.categoryDialogViewState.copilotCategoryDescription =
            copilotCategoryDescription;
    }
);

/**
 * Saves the client category type
 * @param ClientCategoryType the client category type
 * @param mailboxInfo the mailbox info
 */
export const saveCopilotCategory = (
    copilotCategory: CopilotCategoryType,
    mailboxInfo: MailboxInfo
) => {
    saveCopilotCategoryInternal(copilotCategory, mailboxInfo);
    const localStorageKey: LocalStorageKeys = `mail_copilot_categories${getIndexerValueForMailboxInfo(
        mailboxInfo
    )}`;
    setItem(window, localStorageKey, JSON.stringify(getCopilotCategoriesList(mailboxInfo)));
    addCopilotCategoryToTableView(
        mailboxInfo,
        copilotCategory?.Name ?? '',
        copilotCategory?.copilotCategoryDescription ?? ''
    );
};

/**
 * Saves the client category type in the store
 * @param ClientCategoryType the client category type
 * @param mailboxInfo the mailbox info
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const saveCopilotCategoryInternal = mutatorAction(
    'saveCopilotCategoryInternal',
    (copilotCategory: CopilotCategoryType, mailboxInfo: MailboxInfo) => {
        const copilotCategoryList = getCopilotCategoriesList(mailboxInfo);
        const index = copilotCategoryList.findIndex(
            (category: CopilotCategoryType) => category.Name === copilotCategory.Name
        );
        if (index > -1) {
            copilotCategoryList[index] = copilotCategory;
        } else {
            copilotCategoryList.push(copilotCategory);
        }
    }
);

/**
 * Removes the client category type
 * @param ClientCategoryType the client category type
 * @param mailboxInfo the mailbox info
 */
export const removeCopilotCategory = (categoryNames: string[], mailboxInfo: MailboxInfo) => {
    for (const category of categoryNames) {
        removeCopilotCategoryInternal(category, mailboxInfo);
    }
    const localStorageKey: LocalStorageKeys = `mail_copilot_categories${getIndexerValueForMailboxInfo(
        mailboxInfo
    )}`;
    setItem(window, localStorageKey, JSON.stringify(getCopilotCategoriesList(mailboxInfo)));
};

/**
 * Removes the client category type in store
 * @param categoryName  the client category name
 * @param mailboxInfo the mailbox info
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const removeCopilotCategoryInternal = mutatorAction(
    'removeCopilotCategoryInternal',
    (categoryName: string, mailboxInfo: MailboxInfo) => {
        const categoryList = getCopilotCategoriesList(mailboxInfo);
        const index = categoryList.findIndex(
            (category: CopilotCategoryType) => category.Name === categoryName
        );
        if (index > -1) {
            categoryList.splice(index, 1);
        }
    }
);

/**
 * Gets the client category description
 * @param mailboxInfo the mailbox info
 * @param categoryName  the client category name
 */
export function getCopilotCategoryDescription(
    mailboxInfo: MailboxInfo,
    categoryName?: string
): string | undefined {
    const [copilotCategory] = getCopilotCategoriesList(mailboxInfo).filter(
        (category: CopilotCategoryType) => category.Name === categoryName
    );
    return copilotCategory?.copilotCategoryDescription;
}

/**
 * Copy the client category type
 * @param categoryName  the client category name
 * @param mailboxInfo the mailbox info
 */
export function copyCopilotCategory(
    categoryName: string,
    mailboxInfo: MailboxInfo
): CopilotCategoryType {
    const [newCategory] = getMasterCategoryList(mailboxInfo).filter(
        category => category.Name === categoryName
    );
    return {
        Id: newCategory.Id,
        Name: newCategory.Name,
        Color: newCategory.Color,
        LastTimeUsed: newCategory.LastTimeUsed,
        copilotCategoryDescription:
            categoryStore.categoryDialogViewState?.copilotCategoryDescription?.trim(),
    };
}

/**
 * Action triggered when a copilot category is created and try to apply to the row
 * @param mailboxInfo the mailbox info
 * @param categoryName  the client category name
 * @param categoryDescription the client category description
 */
export const addCopilotCategoryToTableView = action(
    'ADD_COPILOT_CATEGORY_TO_TABLE_VIEW',
    (mailboxInfo: MailboxInfo, categoryName: string, categoryDescription: string) => ({
        mailboxInfo,
        categoryName,
        categoryDescription,
    })
);
