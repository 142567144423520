import type ProtectionStore from 'owa-mail-protection-types/lib/schema/ProtectionStore';
import { createStore } from 'owa-satcheljs';
import getDefaultProtectionStore from '../utils/getDefaultProtectionStore';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

const protectionStores = new Map<string, ProtectionStore>();

export function getStore(mailboxInfo: MailboxInfo): ProtectionStore {
    const name = `${getIndexerValueForMailboxInfo(mailboxInfo)}_protection`;
    let cachedStore = protectionStores.get(name);
    if (!cachedStore) {
        const store = createProtectionStore(name);
        protectionStores.set(name, store);
        cachedStore = store;
    }

    return cachedStore;
}

function createProtectionStore(name: string) {
    const initialStore: ProtectionStore = getDefaultProtectionStore();
    return createStore<ProtectionStore>(name, initialStore)();
}
