import { logUsage } from 'owa-analytics';

export type AppHostEntryPoint =
    | 'AccountSwitch'
    | 'ActionableMessages'
    | 'AppBar'
    | 'LaunchPageAppStore'
    | 'AppHostHeader'
    | 'ComposeAppStore'
    | 'DeepLinkService'
    | 'DispatchActionWrapper'
    | 'PageService'
    | 'Routing'
    | 'ToDoListManageTasks'
    | 'NewLargeEventFromMailRibbon'
    | 'NewLargeEventFromCalendarRibbon'
    | 'ToDoContextMenu'
    | 'TaskReminderNotification'
    | 'MyDayHeader'
    | 'VivaEngageEmailNotification'
    | 'Ribbon'
    | 'AddisonReadingPaneIntegration'
    | 'CalendarPeekOthersInOfficeMos'
    | 'OpenVanityDomainNewTab';

export type AppHostError =
    | 'AppManifest_Null'
    | 'StaticTabs_Empty'
    | 'Unknown'
    | 'Empty_Routing'
    | 'Invalid_Url'
    | AppHostNavigationError;

export enum AppHostNavigationError {
    InvalidAppId = 'Invalid appId.',
    InvalidEntityId = 'Invalid entityId. Default static tab does not have a valid entityId value.',
    AppManifestNotFound = 'App manifest not found in catalog',
    AppManifestWithoutStaticTabs = 'App manifest does not have any static tab defined',
    EntityIdNotFoundInManifest = 'Navigation entityId was not found in app manifest',
}

type NavigateToAppTelemetry = {
    callerFn: AppHostEntryPoint;
    appId: string;
    entityId?: string;
    subEntityId?: string;
    appStaticTabs?: string;
    appName?: string;
};

type NavigateToAppErrorTelemetry = NavigateToAppTelemetry & {
    error: AppHostError;
};

export function logError(params: NavigateToAppErrorTelemetry) {
    logUsage('AppHost_NavigateToApp_Failure', params);
}

export function log(params: NavigateToAppTelemetry) {
    logUsage('AppHost_NavigateToApp_Usage', params);
}
