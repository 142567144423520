import datapoints from './datapoints';
import selectNodeInFolderForest from './selectNodeInFolderForest';
import onAfterSelectingNode from './helpers/onAfterSelectingNode';
import {
    lazyLoadGroupAction,
    lazyOnAfterNewGroupSelected,
    lazyOnAfterGroupDetailsSucceeded,
} from 'owa-group-shared-actions';
import { lazyLoadGroupFolders } from 'owa-groups-shared-folders-actions';
import { lazySetGroupHeaderCallbacks } from 'owa-group-header-actions';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import type { FolderForestNode, GroupFolderForestNode, GroupForestNode } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type { FolderForestTreeType } from 'owa-graph-schema';
import { mutatorAction } from 'satcheljs';
import { wrapFunctionForDatapoint, wrapFunctionForCoreDatapoint } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';
import { isSameCoprincipalAccountMailboxInfos } from 'owa-client-types';
import type { ActionSource } from 'owa-mail-store';
import { updatePrimaryReadingPaneTabId } from 'owa-mail-reading-pane-store/lib/mutators/primaryReadingPaneTabIdMutators';
import { isFeatureEnabled } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import {
    navigateFromMeToWeDatapointName,
    switchGroupDatapointName,
} from 'owa-group-common/lib/constants';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export interface SelectGroupState {
    selectedNode: FolderForestNode;
}

/**
 * Switch Group action, extracted to accurately track the SwitchGroup CTQ
 * by returning a promise for selecting the node
 * @param groupId the groupId
 */
/* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
 * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
 *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
const switchGroup = wrapFunctionForCoreDatapoint(
    {
        name: switchGroupDatapointName,
        ...datapoints.switchGroup,
    },
    function switchGroup(
        groupId: string,
        treeType: FolderForestTreeType,
        mailboxInfo: MailboxInfo,
        actionSource?: ActionSource
    ): Promise<void> {
        return selectGroupInternal(groupId, treeType, mailboxInfo, actionSource);
    }
);

/**
 * NavigateFromMeToWe action, extracted to accurately track the
 * NavigateFromMeToWe CTQ by returning a promise for selecting the node
 * @param groupId the groupId
 */
/* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
 * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
 *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
const navigateFromMeToWe = wrapFunctionForCoreDatapoint(
    {
        name: navigateFromMeToWeDatapointName,
        ...datapoints.navigateFromMeToWe,
    },
    function navigateFromMeToWe(
        groupId: string,
        treeType: FolderForestTreeType,
        mailboxInfo: MailboxInfo,
        actionSource?: ActionSource
    ): Promise<void> {
        return selectGroupInternal(groupId, treeType, mailboxInfo, actionSource);
    }
);

/**
 * General select group action
 * @param groupId
 */
function selectGroupInternal(
    groupId: string,
    treeType: FolderForestTreeType,
    mailboxInfo: MailboxInfo,
    actionSource?: ActionSource
): Promise<void> {
    return selectNodeInFolderForest(
        <GroupForestNode>{
            id: groupId,
            type: 2,
            treeType,
            mailboxInfo,
        },
        actionSource
    ).then(() => {
        updatePrimaryReadingPaneTabId(null);
    });
}

/**
 * Select a group
 * @param groupId the groupId
 */
/* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
 * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
 *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
export default wrapFunctionForDatapoint(
    datapoints.selectGroup,
    async function selectGroup(
        groupId: string,
        treeType: FolderForestTreeType,
        mailboxInfo: MailboxInfo,
        actionSource?: ActionSource,
        onLoadGroupSuccess?: () => void,
        onLoadGroupError?: () => void
    ) {
        const currentNode = getSelectedNode() as GroupForestNode;
        let groupLoadPromises = Promise.resolve();
        if (
            currentNode.id &&
            transformGroupSmtp(currentNode.id) === transformGroupSmtp(groupId) &&
            isSameCoprincipalAccountMailboxInfos(currentNode.mailboxInfo, mailboxInfo)
        ) {
            // (Switch between favorites and groups list in the left nav)
            setSelectedNodeTreeTypeMutator(treeType);
        } else if (
            currentNode.type === 6 &&
            transformGroupSmtp((currentNode as GroupFolderForestNode).groupId) ===
                transformGroupSmtp(groupId)
        ) {
            // Navigate from groupfolder node to its parent group node
            selectGroupInternal(groupId, treeType, mailboxInfo, actionSource);
        } else {
            // route the action based on whether or not it should be logged
            // as switchGroup or navigateFromMeToWe
            if (currentNode.type === 2 || currentNode.type === 6) {
                // Switching between groups can happen either by selection of GroupNode or GroupFolderNode of the other group.
                switchGroup(groupId, treeType, mailboxInfo, actionSource);
            } else {
                navigateFromMeToWe(groupId, treeType, mailboxInfo, actionSource);
            }

            const onSuccess = () => {
                lazyOnAfterGroupDetailsSucceeded.importAndExecute(groupId, mailboxInfo);
                if (onLoadGroupSuccess) {
                    onLoadGroupSuccess();
                }
            };
            const promises = [
                lazyLoadGroupAction.import().then(loadGroupAction => {
                    loadGroupAction(
                        groupId,
                        mailboxInfo,
                        true /* skipGetMembers */,
                        onSuccess,
                        onLoadGroupError
                    );
                }),
                lazySetGroupHeaderCallbacks.importAndExecute(mailboxInfo),
            ];

            if (isFeatureEnabled('grp-loadFolders') && !isConsumer(undefined, mailboxInfo)) {
                promises.push(lazyLoadGroupFolders.importAndExecute(groupId, mailboxInfo));
            }

            groupLoadPromises = Promise.all(promises).then(() =>
                lazyOnAfterNewGroupSelected.importAndExecute(groupId, mailboxInfo)
            );
        }
        // Called after selecting same/different group
        // #15945 - We should try to merge SelectFolder, SelectPersona, SelectGroup logic and move this method into a common place
        onAfterSelectingNode();
        await groupLoadPromises;
    }
);

const setSelectedNodeTreeTypeMutator = mutatorAction(
    'setSelectedNodeTreeType',
    (treeType: FolderForestTreeType) => {
        getSelectedNode().treeType = treeType;
    }
);
