import { isGroupsEnabled } from 'owa-account-capabilities/lib/isGroupsEnabled';
import { isEdu, doesActiveExperiencesInclude } from 'owa-bpos-utils';
import { isAccountFeatureEnabled, isFeatureEnabled } from 'owa-feature-flags';
import { getModuleForTasks, ModuleForTasks } from 'owa-todo-module-utils';
import { M365AppId } from './M365AppId';
import { getAppBposData } from '../utils/getAppBposData';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isFluidEnabledForSource, LoopType } from 'owa-fluid-validations';
import type { MailboxInfo } from 'owa-client-types';
import { isUnifiedAppStoreEnabled } from '../utils/isUnifiedAppStoreEnabled';
import { isMosPlacesEnabledForProd, isMosPlacesEnabledForSdf } from '../utils/isMosPlacesEnabled';
import { isImmersiveBizChatEnabled } from '../utils/isImmersiveBizChatEnabled';
import isOutlookNewslettersEnabled from 'owa-session-store/lib/utils/isOutlookNewslettersEnabled';
import { isToDoMetaOsEnabled } from '../utils/isToDoMetaOsEnabled';
import {
    isOneDriveMetaOsEnabled,
    isOneDriveMetaOsConsumerEnabled,
} from '../utils/isOneDriveMetaOsEnabled';
import { isMChatEnabled } from 'owa-copilot-settings-utils';

export const M365AcquisitionsInvariants: {
    [appId in M365AppId]: (mailboxInfo?: MailboxInfo) => boolean;
} = {
    // Outlook core modules
    [M365AppId.Mail]: () => true,
    [M365AppId.Calendar]: () => true,
    [M365AppId.People]: () => true,
    // Special case
    [M365AppId.AppStore]: (mailboxInfo?: MailboxInfo) => isUnifiedAppStoreEnabled(mailboxInfo),
    [M365AppId.AppStoreModal]: (mailboxInfo?: MailboxInfo) => isUnifiedAppStoreEnabled(mailboxInfo),
    [M365AppId.AppStoreAbout]: (mailboxInfo?: MailboxInfo) => isUnifiedAppStoreEnabled(mailboxInfo),
    [M365AppId.OrgExplorer]: (mailboxInfo?: MailboxInfo) =>
        !isEdu(mailboxInfo) &&
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'mos-orgExplorer' */
        isFeatureEnabled('mos-orgExplorer', mailboxInfo) &&
        doesActiveExperiencesInclude('PeopleInViva', mailboxInfo),
    [M365AppId.Groups]: (mailboxInfo?: MailboxInfo) => !!isGroupsEnabled(mailboxInfo),
    [M365AppId.Files]: () => false,
    [M365AppId.ToDo]: (mailboxInfo?: MailboxInfo) =>
        getModuleForTasks(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()) ===
            ModuleForTasks.ToDo && !isToDoMetaOsEnabled(mailboxInfo),
    [M365AppId.ToDoMetaOs]: (mailboxInfo?: MailboxInfo) =>
        getModuleForTasks(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()) ===
            ModuleForTasks.ToDo && isToDoMetaOsEnabled(mailboxInfo),
    [M365AppId.Tasks]: (mailboxInfo?: MailboxInfo) =>
        getModuleForTasks(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()) ===
        ModuleForTasks.LegacyTasks,
    [M365AppId.MicrosoftPlaces]: (mailboxInfo?: MailboxInfo) =>
        isMosPlacesEnabledForProd(mailboxInfo),
    [M365AppId.MicrosoftPlacesSdf]: (mailboxInfo?: MailboxInfo) =>
        isMosPlacesEnabledForSdf(mailboxInfo),
    [M365AppId.ImmersiveBizChat]: (mailboxInfo?: MailboxInfo) =>
        (isFeatureEnabled('mos-BizChatMOS3')
            ? /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
               * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
               *	> The parameter mailboxInfo must be undefined. Feature flight: 'mos-mos3AppServiceSupportedEnv' */
              !isFeatureEnabled('mos-mos3AppServiceSupportedEnv', mailboxInfo)
            : true) &&
        (isFeatureEnabled('mos-BizChatOCS')
            ? !!isMChatEnabled(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo())
            : isImmersiveBizChatEnabled(mailboxInfo)),
    // License dependent
    [M365AppId.Word]: (mailboxInfo?: MailboxInfo) => !!getAppBposData(M365AppId.Word, mailboxInfo),
    [M365AppId.Excel]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.Excel, mailboxInfo),
    [M365AppId.PowerPoint]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.PowerPoint, mailboxInfo),
    [M365AppId.OneNote]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.OneNote, mailboxInfo),
    [M365AppId.Yammer]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.Yammer, mailboxInfo) &&
        isAccountFeatureEnabled(
            'mos-builtinApps',
            mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()
        ),
    [M365AppId.Bookings]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.Bookings, mailboxInfo) &&
        isAccountFeatureEnabled(
            'mos-builtinApps',
            mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()
        ),
    [M365AppId.OneDrive]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.OneDrive, mailboxInfo) &&
        !isOneDriveMetaOsEnabled(mailboxInfo) &&
        !isConsumer(undefined, mailboxInfo),
    [M365AppId.OneDriveMetaOs]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.OneDrive, mailboxInfo) &&
        isOneDriveMetaOsEnabled(mailboxInfo) &&
        !isConsumer(undefined, mailboxInfo),
    [M365AppId.OneDriveConsumerMetaOs]: (mailboxInfo?: MailboxInfo) =>
        !!getAppBposData(M365AppId.OneDriveConsumerMetaOs, mailboxInfo) &&
        isOneDriveMetaOsConsumerEnabled(mailboxInfo) &&
        !isOneDriveMetaOsEnabled(mailboxInfo) &&
        isConsumer(undefined, mailboxInfo),
    [M365AppId.Hoop]: (mailboxInfo?: MailboxInfo) =>
        isFluidEnabledForSource(
            'MOS',
            mailboxInfo ?? getGlobalSettingsAccountMailboxInfo(),
            LoopType.Fluid
        ),
    [M365AppId.Newsletters]: () => isOutlookNewslettersEnabled(),
    [M365AppId.VsbAdmin]: () => true,
    // The feature rollout is restricted till MSIT, so additional checks for Copilot users aren't needed here.
    [M365AppId.Actions]: (mailboxInfo?: MailboxInfo) =>
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'mon-copilot-ext-enableGeneric1PAddinSupport' */
        isFeatureEnabled('mos-actionsApp', mailboxInfo),
};
