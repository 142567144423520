import { mutatorAction } from 'satcheljs';
import { getStore, initialStore } from '../store/store';
import { MAIN_WINDOW_ID } from '../utils/constants';

/**
 * Action executed when user changes the text of moveTo mailbox menu search box
 * @param searchText - the searched text
 */
export const onSearchTextChangedMutator = mutatorAction(
    'ON_MOVETO_SEARCH_TEXT_CHANGES',
    (currentSearchText: string, newSearchText: string, projectionTabId?: string) => {
        const moveToStore = getStore();
        const id = projectionTabId || MAIN_WINDOW_ID;

        moveToStore.findTextMap.set(id, newSearchText);
        // resets new folder view state to initial value (using main here works for both cases)
        moveToStore.newFolderViewStateMap.set(
            id,
            initialStore.newFolderViewStateMap.get(MAIN_WINDOW_ID) ?? null
        );

        // Only if we are in Primary mbx mode we will reset shouldShowAllFolders
        // In IE11, focusing on the search box on first mount fires this action.
        // We need an additional check to ensure the store only updates when text is added or removed
        if (
            moveToStore.moveToMailboxType === 'UserMailbox' &&
            currentSearchText !== newSearchText
        ) {
            // if search text is not empty then all folders should be shown, else default view will be shown
            moveToStore.shouldShowAllFolders = newSearchText.length > 0;
        }
    }
);
