import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import getStore from '../store/store';
import { default as createFolderId } from 'owa-service/lib/factory/folderId';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';

export default function getFullFolderId(
    folderIdOrName: string | DistinguishedFolderIdName
): string {
    let folderId = folderNameToId(folderIdOrName as DistinguishedFolderIdName);
    if (!folderId) {
        // This is a non-distinguished folder
        folderId = folderIdOrName;
    }
    const folder = getStore().folderTable.get(folderId);
    if (folder) {
        return folder.id;
    }

    const createdFolderId = createFolderId({
        Id: folderId,
        __type: 'FolderId:#Exchange',
    });

    return createdFolderId.Id;
}
