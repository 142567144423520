import AbstractTaskQueue from './AbstractTaskQueue';
export interface TaskQueueConfig<T> {
    taskCallback: (value: T) => Promise<any>;
    maxParallelTasks?: number;
    taskDelay?: number;
    taskTimeout?: number;
}

/**
 * Task queue class to queue the service calls.
 */
export class TaskQueue<T> extends AbstractTaskQueue<T> {
    protected taskList: T[] = []; // ordered as a FIFO
    protected config: Required<TaskQueueConfig<T>>;

    constructor(config: TaskQueueConfig<T>) {
        super();
        this.config = {
            maxParallelTasks: 1,
            taskDelay: 0,
            taskTimeout: 60000,
            ...config,
        };
    }

    add(value: T) {
        this.taskList.unshift(value);
        this.scheduleTask();
    }

    remove(): T | undefined {
        return this.taskList.pop();
    }

    clear() {
        this.taskList.splice(0, this.taskList.length);
    }

    protected get canRunMoreTasks(): boolean {
        // If the number of running tasks are less than the number of max tasks that can be run
        return this.numberOfTasksRunning < this.config.maxParallelTasks && this.taskList.length > 0;
    }

    protected runTask(task: T): Promise<void> {
        return this.config.taskCallback(task);
    }
}
