import { logUsage } from 'owa-analytics';
import type { CustomDataMap } from 'owa-analytics-types';
/**
 * Sample Event
 *
 *  {
 *      "hostItemIndex": "PRIVATE",
 *      "userClickedButton": "Wait",
 *   }
 * @param telemetryData
 * @param props
 */
export function logSmartAlertItemSwitchTelemetryEvent(
    smartAlertItemSwitchTelemetryData: CustomDataMap
): void {
    logUsage('SmartAlertItemSwitchEvent', {
        ...smartAlertItemSwitchTelemetryData,
    });
}
