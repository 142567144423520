import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolderViewState } from '../selectors/getGroupFolderViewState';
import type { Point } from '@fluentui/react/lib/Utilities';
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const showGroupFolderTreeContextMenu = mutatorAction(
    'showGroupFolderTreeContextMenu',
    (groupId: string, folderId: string, anchor: Point, mailboxInfo: MailboxInfo): void => {
        const groupFolderViewState = getGroupFolderViewState(groupId, folderId, mailboxInfo);
        if (groupFolderViewState) {
            groupFolderViewState.groupFolderContextMenuState = {
                groupId,
                folderId,
                anchor,
            };
        }
    }
);
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const hideGroupFolderTreeContextMenu = mutatorAction(
    'hideGroupFolderTreeContextMenu',
    (groupId: string, folderId: string, mailboxInfo: MailboxInfo): void => {
        const groupFolderViewState = getGroupFolderViewState(groupId, folderId, mailboxInfo);
        if (groupFolderViewState) {
            groupFolderViewState.groupFolderContextMenuState = null;
        }
    }
);
