import type ArchiveInformation from '../store/schema/ArchiveInformation';
import { getMailboxFolderTreeData } from './getMailboxFolderTreeDataTable';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Selector for a particular mailbox shared resource archive information
 * @param sharedFolderUserEmail identifies the shared resource tree uniquely
 * @param mailboxInfo identifies which primary mailbox the shared item is associated with, used to retrieve the folder tree data
 */
export default function getSharedFolderArchiveInformation(
    sharedFolderUserEmail: string,
    mailboxInfo: MailboxInfo
): ArchiveInformation | undefined {
    const mailboxFolderTreeDataTable = getMailboxFolderTreeData(mailboxInfo);
    const archiveData =
        mailboxFolderTreeDataTable?.sharedArchiveInformation.get(sharedFolderUserEmail);
    return archiveData;
}
