import { createStore } from 'owa-satcheljs';

export interface UndoStore {
    hasUndoableAction: boolean;
    undoableActionFolderId: string | null;
}

const undoStoreData = {
    hasUndoableAction: false,
    undoableActionFolderId: null,
};

export const getUndoStore = createStore<UndoStore>('undo', undoStoreData);
