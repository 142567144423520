import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import type { TableQuery } from 'owa-mail-list-store';
import { MailSortHelper, type TableQueryType } from 'owa-mail-list-store';
import type { SearchTableQuery } from 'owa-mail-list-search';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

/**
 *
 * Returns a flag indicating whether to show the circle personas in this table
 * 1. Hide sender images in message list option is disabled
 * 2. if in search state, current scope is not drafts or sentitems
 * 3. current folder is not drafts, sentitems
 * 4. current table is not sorted by a multi value sort such as "From"
 * @param tableQuery - the table query
 */
export default function shouldTableShowCirclePersonas(tableQuery: TableQuery): boolean {
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    if (
        getIsBitSet(8, mailboxInfo) ||
        !getAccountScopeUserSettings(mailboxInfo)?.SegmentationSettings?.DisplayPhotos
    ) {
        return false;
    } else if (tableQuery.type == 1) {
        const searchTableQuery = tableQuery as SearchTableQuery;
        const scenarioType = (tableQuery as SearchTableQuery).scenarioType;

        if (scenarioType === 'persona' || scenarioType === 'privateDistributionList') {
            // For persona and PDL scenario we want to align to results from persona search folder and show circle personas always
            return true;
        }

        let isNotSentItemsOrDraftsScopeOnPrimaryMailbox = false;
        if (searchTableQuery.searchScope.kind === SearchScopeKind.PrimaryMailbox) {
            const folderScopeName = folderIdToName(searchTableQuery.searchScope.folderId);
            isNotSentItemsOrDraftsScopeOnPrimaryMailbox =
                folderScopeName !== 'drafts' && folderScopeName !== 'sentitems';
        }

        return (
            isNotSentItemsOrDraftsScopeOnPrimaryMailbox ||
            searchTableQuery.searchScope.kind == SearchScopeKind.ArchiveMailbox ||
            searchTableQuery.searchScope.kind == SearchScopeKind.SharedFolders
        );
    } else {
        const folderName =
            tableQuery?.folderId &&
            folderIdToName(tableQuery.folderId, true /* shouldSearchShared */);
        return (
            folderName != 'drafts' &&
            folderName != 'sentitems' &&
            !MailSortHelper.isTableMultiValueSort(tableQuery)
        );
    }
}
