import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'unmountComponentAtNode' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { unmountComponentAtNode, render } from 'owa-react-dom';
import EmbedChatTeachingCallout from './EmbedChatTeachingCallout';

const EmbedChatButtonId = 'O365_MainLink_Chat';
const EmbedChatCalloutId = 'EmbedChatCallout';
export function triggerEmbedChatCallout() {
    const embedChatButton = document.getElementById(EmbedChatButtonId);
    const onDismiss = () => {
        const div = window.document.getElementById(EmbedChatCalloutId);
        if (div) {
            unmountComponentAtNode(div);
            div?.parentNode?.removeChild(div);
        }
    };
    if (embedChatButton !== null) {
        const embedChatCalloutContainer = document.createElement('div');
        embedChatCalloutContainer.id = EmbedChatCalloutId;
        embedChatButton.appendChild(embedChatCalloutContainer);

        render(
            <>
                <EmbedChatTeachingCallout
                    target={`#${EmbedChatButtonId}`}
                    onDismiss={onDismiss}
                    id={EmbedChatCalloutId}
                />
            </>,
            embedChatCalloutContainer
        );
    }
}
