import type { MoveFolderDialogStore } from './schema/MoveFolderDialogStore';
import { createStore } from 'owa-satcheljs';

/**
 * Selector to get UI store for folder permissions package
 */
const store = createStore<MoveFolderDialogStore>('moveFolderDialogStore', {
    shouldShow: false,
    sourceFolder: undefined,
})();
export default store;
export const getStore = () => store;
