import type ComplianceConfiguration from 'owa-service/lib/contract/ComplianceConfiguration';
import type MessageClassificationType from 'owa-service/lib/contract/MessageClassificationType';
import type GetAttachmentLabelResponseMessage from 'owa-service/lib/contract/GetAttachmentLabelResponseMessage';
import type { DLPRuleResult } from 'owa-mail-protection-types/lib/schema/DLPPolicies';

export default function getDefaultProtectionStore() {
    return {
        clpLabels: [],
        learnMoreUrl: '',
        hasMandatoryLabel: false,
        hasMandatoryTeamworkLabel: false,
        labelInheritanceType: '',
        rmsTemplates: new Map<string, ComplianceConfiguration>(),
        messageClassifications: new Map<string, MessageClassificationType>(),
        dlpRuleConfig: {
            isAnyPolicyEnforced: true,
            dlpRuleResults: new Map<string, DLPRuleResult>(),
        },
        attachmentLabelsCache: new Map<string, Map<string, GetAttachmentLabelResponseMessage>>(),
        retainMessageHeaderInOutlook: '',
    };
}
