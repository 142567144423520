import {
    type TabState,
    TabType,
    getStore as getTabStore,
    lazyActivateTab,
    lazyCloseTab,
} from 'owa-tab-store';
import type {
    SecondaryReadingPaneTabData,
    SecondaryReadingPaneTabViewState,
    MailComposeTabViewState,
} from 'owa-tab-store';
import type { ComposeViewState } from 'owa-mail-compose-store';
import { composeStore, type SaveSource, type SendSource } from 'owa-mail-compose-store';
import getProjection from 'owa-popout-v2/lib/utils/getProjection';
import { getComposeHostItemIndex } from 'owa-addins-core';
import {
    lazyInitializeAddinsForItem,
    lazyDeinitializeAddinsForItem,
} from 'owa-addins-boot/lib/lazyFunctions';
import { lazyOnSendAddinItemSwitch, lazySetSoftBlockDialogState } from 'owa-addins-view';
import isPopout from 'owa-popout-v2/lib/utils/isPopout';
import { DialogResponse } from 'owa-confirm-dialog/lib/actions/DialogResponse';
import setIsOnSendEventTriggered from 'owa-addins-editor-plugin/lib/actions/setIsOnSendEventTriggered';
import { lazyCreateMailComposeAdapter } from 'owa-mail-addins';
import {
    lazyTrySaveAndCloseCompose,
    lazyPopoutComposeAndWaitForProjection,
    lazyMoveComposeToTab,
    lazyTrySaveMessage,
    send,
} from 'owa-mail-compose-actions';
import { trace } from 'owa-trace';
import type { CustomDataMap } from 'owa-analytics-types';
import { logSmartAlertItemSwitchTelemetryEvent } from './smartAlertItemSwitchTelemetryUtils';
import { type SoftBlockOnSendDialogTypeEnum } from 'owa-addins-store';
import { getTabIdFromTargetWindow } from 'owa-mail-reading-pane-store/lib/utils/getTabIdFromTargetWindow';

// Define a global variable to store the last tabComposeId
let lastTabComposeId: string | null = null;

export default async function handleComposeTab(activeComposeTab: MailComposeTabViewState) {
    const composeId = activeComposeTab.data ?? composeStore.primaryComposeId;
    const composeViewState = composeStore.viewStates.get(composeId) as ComposeViewState;

    // Check if the active compose tab is hidden, is of type MailCompose, and if the current compose ID matches the last tab compose ID
    if (
        activeComposeTab.state === 0 &&
        activeComposeTab.type === TabType.MailCompose &&
        composeId === lastTabComposeId
    ) {
        return; // Return early if they are the same
    }
    // Update the global variable with the new tabComposeId
    lastTabComposeId = composeId ?? null;

    let targetWindow = getProjection(activeComposeTab?.id)?.window || window;
    if (
        composeViewState?.isComposeRendered &&
        composeViewState?.addin.isOnSendEventTriggered &&
        activeComposeTab.state !== 3
    ) {
        const hostItemIndex = getComposeHostItemIndex(composeViewState?.composeId);
        const isInlineCompose = composeViewState?.isInlineCompose;
        // In case of inline compose, store the value of isInlineCompose before we move the compose to a tab
        if (!isPopout(targetWindow)) {
            //If the compose is not in a popout, move the compose to a tab
            if (composeStore.primaryComposeId !== null) {
                const updatedTabId = await lazyMoveComposeToTab.importAndExecute(
                    composeViewState,
                    false /* isShown */,
                    false /* makeActive */
                );
                activeComposeTab = getTabStore().tabs.find(
                    tab => tab.id === updatedTabId
                ) as MailComposeTabViewState;
            }

            if (isInlineCompose) {
                const tabId = getTabIdFromTargetWindow(targetWindow);
                const setSoftBlockDialogState = await lazySetSoftBlockDialogState.import();
                setSoftBlockDialogState(tabId, 2, composeViewState.mailboxInfo);
                const moveComposeToTab = await lazyMoveComposeToTab.import();
                moveComposeToTab(composeViewState, true /* isShown */, true /* makeActive */);
                setIsOnSendEventTriggered(composeViewState.addin, false);
                // If the compose view state has not been saved before, save the message before proceeding
                if (composeViewState.lastSaveTimeStamp === null) {
                    const trySaveMessage = await lazyTrySaveMessage.import();
                    await trySaveMessage(composeViewState, 23);
                }
                // In case of inline compose we first move the compose to an active tab and trigger the
                // send again. While doing this, we want to make sure that the OnSend add-ins that were
                // running before are deinitialized and reinitialized again before the send is triggered.
                // so that there are no side effects of the previous OnSend add-ins that were running.
                lazyDeinitializeAddinsForItem
                    .import()
                    .then(deinitializeAddinsForItem =>
                        deinitializeAddinsForItem(
                            hostItemIndex,
                            window,
                            composeViewState?.mailboxInfo
                        )
                    );
                await initializeComposeAddins(composeViewState, hostItemIndex, targetWindow);

                // Wait for the isSending to be false before sending the message as part of the onSend event
                waitForIsSendingToBeFalse(composeViewState).then(() => {
                    send(composeViewState, targetWindow ?? window, 9);
                });
            } else {
                lazyDeinitializeAddinsForItem
                    .import()
                    .then(deinitializeAddinsForItem =>
                        deinitializeAddinsForItem(
                            hostItemIndex,
                            window,
                            composeViewState?.mailboxInfo
                        )
                    );
                lazyActivateTab.importAndExecute(activeComposeTab);
                closeSecondaryReadingPaneTab(composeViewState);
            }

            // Launch the dialog only if the compose is not inline

            const telemetryData: CustomDataMap = {};
            !isInlineCompose &&
                lazyOnSendAddinItemSwitch
                    .importAndExecute(composeViewState.mailboxInfo)
                    .then(async (response: DialogResponse) => {
                        switch (response) {
                            case DialogResponse.ok:
                                setIsOnSendEventTriggered(composeViewState.addin, false);
                                const popoutComposeAndWaitForProjection =
                                    await lazyPopoutComposeAndWaitForProjection.import();
                                try {
                                    // Close the secondary reading pane tabs before popping out the compose
                                    closeSecondaryReadingPaneTab(composeViewState);
                                    await popoutComposeAndWaitForProjection(
                                        composeViewState,
                                        targetWindow
                                    );
                                    telemetryData.userClickedButton = 'Wait';
                                    telemetryData.hostItemIndex = hostItemIndex;
                                } catch (error) {
                                    trace.warn(error.message, 'addins');
                                }
                                targetWindow =
                                    (activeComposeTab &&
                                        getProjection(activeComposeTab?.id)?.window) ||
                                    window;

                                await initializeComposeAddins(
                                    composeViewState,
                                    hostItemIndex,
                                    targetWindow
                                );
                                send(composeViewState, targetWindow ?? window, 9);
                                break;
                            case DialogResponse.continue:
                                targetWindow =
                                    (activeComposeTab &&
                                        getProjection(activeComposeTab?.id)?.window) ||
                                    window;

                                // When send anyway is clicked, we should not trigger onSend event again
                                setIsOnSendEventTriggered(composeViewState.addin, true);
                                send(composeViewState, targetWindow ?? window, 9);

                                telemetryData.userClickedButton = 'SendAnyway';
                                telemetryData.hostItemIndex = hostItemIndex;
                                break;
                            case DialogResponse.cancel:
                                // Close the secondary reading pane tab before closing the compose and saving the draft
                                closeSecondaryReadingPaneTab(composeViewState);

                                await lazyTrySaveAndCloseCompose.importAndExecute(composeViewState);
                                telemetryData.userClickedButton = 'SaveAsDraft';
                                telemetryData.hostItemIndex = hostItemIndex;
                                break;
                            case DialogResponse.dismiss:
                                // Re-initialize the add-ins for the item if the dialog is dismissed and the item is not sent
                                await initializeComposeAddins(
                                    composeViewState,
                                    hostItemIndex,
                                    targetWindow
                                );
                                // Close the secondary reading pane tab if the dialog is dismissed
                                closeSecondaryReadingPaneTab(composeViewState);

                                setIsOnSendEventTriggered(composeViewState.addin, false);

                                telemetryData.userClickedButton = 'Dismiss';
                                telemetryData.hostItemIndex = hostItemIndex;
                                break;
                            default:
                                // Handle any other cases or do nothing
                                break;
                        }

                        // Log telemetry for the dialog button clicked
                        logSmartAlertItemSwitchTelemetryEvent(telemetryData);
                    });
        }
    }
}

/**
 * Closes all secondary reading pane tabs that match the given compose view state's conversation ID.
 *
 * @param composeViewState - The state of the compose view, which contains the conversation ID to match against.
 */
function closeSecondaryReadingPaneTab(composeViewState: any) {
    const tabStore = getTabStore();
    for (const tab of tabStore.tabs) {
        if (
            tab.type === TabType.SecondaryReadingPane &&
            composeViewState.conversationId ===
                ((tab as SecondaryReadingPaneTabViewState).data as SecondaryReadingPaneTabData)?.id
                    ?.Id
        ) {
            lazyCloseTab.importAndExecute(tab);
        }
    }
}

/**
 * Initializes compose add-ins for the given compose view state.
 *
 * This function asynchronously imports the necessary modules to create a mail compose adapter
 * and initialize add-ins for the specified item. It then creates the adapter and initializes
 * the add-ins with the provided parameters.
 *
 * @param composeViewState - The state of the compose view.
 * @param hostItemIndex - The index of the host item.
 * @param targetWindow - The target window where the add-ins should be initialized.
 *
 * @returns A promise that resolves when the add-ins have been initialized.
 */
async function initializeComposeAddins(
    composeViewState: ComposeViewState,
    hostItemIndex: string,
    targetWindow: Window
) {
    const [createMailComposeAdapter, initializeAddinsForItem] = await Promise.all([
        lazyCreateMailComposeAdapter.import(),
        lazyInitializeAddinsForItem.import(),
    ]);

    const adapter = createMailComposeAdapter(composeViewState, targetWindow ?? window);

    await initializeAddinsForItem(
        hostItemIndex,
        adapter,
        true, // renderTaskPaneInPanel
        targetWindow,
        composeViewState.mailboxInfo,
        composeViewState?.itemId?.Id
    );
}

/**
 * Waits for the `isSending` property of the provided `ComposeViewState` to become false.
 * This function repeatedly checks the `isSending` property at 100ms intervals and resolves the promise once it is false.
 *
 * @param viewState - The `ComposeViewState` object to monitor.
 * @returns A promise that resolves when `viewState.isSending` becomes false.
 */
function waitForIsSendingToBeFalse(viewState: ComposeViewState): Promise<void> {
    return new Promise(resolve => {
        const interval = setInterval(() => {
            if (!viewState.isSending) {
                clearInterval(interval);
                resolve();
            }
        }, 100); // Check every 100ms
    });
}
