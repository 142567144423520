import type { MailboxInfo } from 'owa-client-types';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import { getGroupListNodesViewState } from '../store/groupListNodesViewStateStore';
import type { GroupListNodesViewStateStore } from '../store/schema/GroupListNodesViewStateStore';
import type { GroupFolderTreeViewState } from '../store/schema/GroupFolderTreeViewState';

export default function getGroupListNodesViewStateStore(
    mailboxInfo: MailboxInfo
): GroupListNodesViewStateStore {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const listNodesViewStates =
        getGroupListNodesViewState().groupListNodesViewStatesByMailBoxInfo.get(accountKey);

    if (!listNodesViewStates) {
        const defaultListNodesViewStates: GroupListNodesViewStateStore = {
            groupListNodesViewStates: new Map<string, GroupFolderTreeViewState>(),
        };

        getGroupListNodesViewState().groupListNodesViewStatesByMailBoxInfo.set(
            accountKey,
            defaultListNodesViewStates
        );

        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We just set this value on the line above,
         * but we need to fetch it again after it's inserted into the store */
        return getGroupListNodesViewState().groupListNodesViewStatesByMailBoxInfo.get(accountKey)!;
    }

    return listNodesViewStates;
}
