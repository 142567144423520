import { isConsumer } from 'owa-session-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';

export default function isSenderScreeningFeatureEnabled(mailboxInfo: MailboxInfo): boolean {
    return (
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'tri-sender-screening' */
        isConsumer(undefined, mailboxInfo) && isFeatureEnabled('tri-sender-screening', mailboxInfo)
    );
}
