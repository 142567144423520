import type { RibbonControlId, MailRibbonTabId } from 'owa-ribbon-ids';
import type { RibbonControlProps } from 'owa-acui';
import type { RibbonControlDefinitionProps } from 'owa-mail-ribbon-utils';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';

/*
 * Helper function to push ribbon controls into an array. Used in getControlsToDisplay functions.
 */
export default function pushRibbonControl<T extends RibbonControlDefinitionProps>(
    menuItems: RibbonControlProps[],
    ribbonIdToPush: RibbonControlId,
    tabId: MailRibbonTabId,
    getControlDefinition: (
        controlId: RibbonControlId,
        tabId: MailRibbonTabId,
        props: T
    ) => RibbonControlProps | undefined,
    props: T
) {
    const control: RibbonControlProps | undefined = getControlDefinition(
        ribbonIdToPush,
        tabId,
        props
    );

    if (control) {
        menuItems.push(control);
    } else {
        debugErrorThatWillShowErrorPopupOnly(
            'pushRibbonControl, cannot find control: ' + ribbonIdToPush
        );
    }
}
