import { owaComputedFn } from 'owa-computed-fn';
import { createLazyRibbonTabDefinition } from 'owa-command-ribbon';
import { type HelpRibbonTabId } from 'owa-ribbon-ids/lib/helpControlId';
import Strings from 'owa-locstrings/lib/strings/ribbon_app_buttons_and_tabs.locstring.json';
import loc from 'owa-localize';
import type { RibbonId } from 'owa-ribbon-ids';
import { lazyGetSLRHelpTabGroups, lazyGetMLRHelpTabGroups } from './lazyFunctions';

export const getHelpTab = owaComputedFn((currentTab: RibbonId) => {
    return createLazyRibbonTabDefinition(
        currentTab,
        11000,
        loc(Strings.help_tab),
        lazyGetSLRHelpTabGroups,
        lazyGetMLRHelpTabGroups
    );
});
