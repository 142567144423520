import type { MailboxInfo } from 'owa-client-types';
import copilotSettingsStore from '../store/store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { LicenseType } from '../services/types/serviceSettingTypes';

export function isSalesLicensePresent(mailboxInfo: MailboxInfo): boolean {
    return (
        copilotSettingsStore(mailboxInfo).licenses.indexOf(LicenseType.SalesCopilot) >= 0 ||
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'mon-copilot-ext-debug-addins' */
        isFeatureEnabled('mon-copilot-ext-debug-addins', mailboxInfo)
    );
}
