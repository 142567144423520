import { isRunningInMetaOSHub } from 'owa-config';
import { getConfig } from 'owa-service/lib/config';
import type MailboxInfo from 'owa-client-types/lib/MailboxInfo';
import type RequestOptions from 'owa-service/lib/RequestOptions';

export async function getUserToken(
    options?: RequestOptions,
    mailboxInfo?: MailboxInfo
): Promise<string | undefined> {
    let token: string | undefined = undefined;
    const svcConfig = getConfig();
    // get user token from metaOS hub
    if (isRunningInMetaOSHub()) {
        token = await svcConfig.getAuthToken?.(undefined, mailboxInfo);
    } else {
        // get user token from host app
        token =
            svcConfig.getUserToken && (await svcConfig.getUserToken(options?.headers, mailboxInfo));
    }

    if (token && token.length > 0) {
        if (token?.split(' ')[0]?.toLowerCase() === 'bearer') {
            return token.split(' ')[1]; // drop bearer prefix and just return the token value for JWT tokens
        } else if (token?.split(' ')[0]?.toLowerCase() === 'msauth1.0') {
            return token; // return the token value with auth scheme for MSA tokens
        }
    }
    return undefined;
}
