import React from 'react';
import { wrapForcedLayout } from 'owa-performance';
import { useResizeObserver, type ResizeObserverOptions } from './useResizeObserver';

export interface ElementSizeTrackerOptions extends ResizeObserverOptions {
    includeLayoutEffect?: boolean;
}

/**
 * Given a ref for an HTMLElement, stores that element's DOMRect and tracks changes to it.
 *
 * Note that if only needing to update state based on certain changes or specific sizes, it'll be better to
 * use useResizeObserver directly, as this will update component state every time the rectangle changes size
 * instead of just calling your resizeCallback, which can cause performance issues.
 */
export function useElementSizeTracker(
    source: string,
    ref: React.RefObject<HTMLDivElement>,
    options?: ElementSizeTrackerOptions
) {
    /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
     * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
     *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
    const [rect, setRect] = React.useState<DOMRectReadOnly | undefined>();
    const resizeCallback = React.useCallback((domRect: DOMRectReadOnly) => {
        setRect(domRect);
    }, []);
    React.useLayoutEffect(() => {
        // useElementSizeTracker is more aggressive that useResizeObserver
        // because it will block the main thread to get the dimensions
        // some calls to useElementSizeTracker might be too aggressive so
        // we will allow the caller to opt out of this behavior. Hopefully
        // with a flight in case of regressions and to track any performance
        // benefitis
        if (options?.includeLayoutEffect) {
            const measuredRect = wrapForcedLayout('useElementSizeTracker', () =>
                /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                 * This is baseline exception, if you edit this file you need to fix this exception.
                 *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
                ref.current?.getBoundingClientRect?.()
            );
            setRect(measuredRect);
        }
    }, []);
    useResizeObserver(source, ref, resizeCallback);

    return [rect] as const;
}
