import { onViewAllMenuClickedMutator } from '../actions/onViewAllMenuClickedMutator';
import { onChangeMoveToMailboxTypeMutator } from '../actions/onChangeMoveToMailbox';
import { lazyGetArchiveFolders, lazyGetSharedFoldersForSpecificRoot } from 'owa-folders';
import type { MailboxInfo } from 'owa-client-types';

const VIEW_ALL_MOVE_TO_MENU = 'VIEW_ALL_MOVE_TO_MENU';

// gets called when user clicks on view all menu item for any mailbox
export const onViewAllMenuClicked = async (mailboxInfo: MailboxInfo) => {
    onViewAllMenuClickedMutator(mailboxInfo);
    onChangeMoveToMailboxTypeMutator(mailboxInfo.type);
    switch (mailboxInfo.type) {
        case 'ArchiveMailbox':
            (await lazyGetArchiveFolders.import())(
                mailboxInfo,
                VIEW_ALL_MOVE_TO_MENU /* Archive mailbox folders loading source */
            );
            break;
        case 'SharedMailbox':
            (await lazyGetSharedFoldersForSpecificRoot.import())(
                mailboxInfo,
                'moveToControl' /* actionSource */
            );
            break;
        default:
            return;
    }
};
