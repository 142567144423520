import type { DraftCopilotPluginViewState } from '../types/DraftCopilotPluginViewState';

export function createDraftCopilotPluginViewState(
    init?: Partial<DraftCopilotPluginViewState> & Pick<DraftCopilotPluginViewState, 'openInfo'>
): DraftCopilotPluginViewState {
    return {
        suggestedDraftsShown: init?.suggestedDraftsShown,
        isInitialized: false,
        isShown: init?.isShown || false,
        openInfo: init?.openInfo || {
            openMode: 'CopilotElaboratePolaris',
            entryPoint: 'ComposeOpen',
        },
    };
}
