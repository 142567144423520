import { isAccountFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { isAccountExplicitLogon } from 'owa-account-shared-mailbox-utils';
import {
    getIsBitSet,
    type FolderPaneBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/folderPaneBitFlagsUtil';

export const getIsCustomSortEnabled = (mailboxInfo: MailboxInfo) => {
    const isExplicitLogon = isAccountExplicitLogon(mailboxInfo);
    const isBitSet = getIsBitSet(1, mailboxInfo);
    return (
        !isExplicitLogon &&
        isAccountFeatureEnabled('fwk-folders-custom-sorted-on-boot', mailboxInfo) &&
        !isBitSet
    );
};
