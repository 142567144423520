import type { RibbonControlProps } from 'owa-acui';
import type { MailRibbonGroup } from 'owa-mail-ribbon-store-shared-types';
import type { RuntimeControlId } from 'owa-ribbon-ids';
import { getInstalledReadSurfaceAddInRuntimeControls } from './getInstalledReadSurfaceAddInRuntimeControls';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';

type RibbonReadSurfaceRuntimeControlGroup = 'ReadAddInsInstalled';

export type RibbonRuntimeControl = {
    controlId: RuntimeControlId;
    buttonProps: RibbonControlProps;
};
export interface RibbonReadSurfaceRuntimeControlsGroup {
    /* Identifies the runtime control group */
    controlsGroupName: RibbonReadSurfaceRuntimeControlGroup;

    /* Callback that gates whether the runtime control group should run at all */
    isControlGroupEnabledForSpamIntegration: () => boolean;

    /* Callback that gates whether this control group should get scaling steps */
    shouldAddScalingSteps: () => boolean;

    /* Callback that gates in which group the runtime controls should be added to */
    shouldAddControlsToTabGroup: (group: MailRibbonGroup) => boolean;

    /* Generate a list of a runtime control group ribbon's button props */
    getControlsProps: (props: ReadOnlyRibbonControlDefProps) => RibbonRuntimeControl[];

    /* Get a list of a runtime control group's controlIds; needs to be called after getControlsProps */
    getControlsId: () => RuntimeControlId[];
}

// List of all runtime controls.
const runtimeReadSurfaceControlsGroups = [getInstalledReadSurfaceAddInRuntimeControls()];

export function getReadSurfaceRuntimeControls(): RibbonReadSurfaceRuntimeControlsGroup[] {
    return runtimeReadSurfaceControlsGroups;
}
