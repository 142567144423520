import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';

/**
 * Indicates whether the mailbox supports restoring items,
 * such as moving an item or conversation from the Deleted Items folder
 * back to the default folder.
 *
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const restoreItemCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        // If the mailbox supports restore item, it is always allowed
        return true;
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        return !mailboxInfo /*allowed by default*/ || !isLocalDataBackedMailboxInfo(mailboxInfo);
    },
};
