import { mutatorAction } from 'satcheljs';
import { saveCommandingViewModeOption } from 'owa-commanding-option';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { type CommandingViewMode } from 'owa-outlook-service-option-store/lib/store/schema/options/CommandingOptions';
import { OwsOptionsFeatureType } from 'owa-outlook-service-option-store';
import { getStore } from '../store/store';
import { logUsage } from 'owa-analytics';

let isViewModeInitialized: boolean = false;

/**
 * Initializes owa-command-ribbon-store's viewMode using either OWS'
 * settings or the default values.
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const initializeCommandingViewModeInternal = mutatorAction(
    'initializeCommandingViewMode',
    (primeBootSettingsOptions: any[]) => {
        if (isViewModeInitialized) {
            return;
        }

        let viewMode: CommandingViewMode | undefined =
            getDefaultCommandingViewModeOnBoot(primeBootSettingsOptions);
        viewMode = validateViewMode(viewMode);

        getStore().viewMode = viewMode;
        isViewModeInitialized = true;

        logUsage('InitializeCommandingViewMode', { viewMode });
    }
);

/**
 * This function gets the default CommandingViewMode to render with
 * at boot. The value will either be from OWS' settings or the default values (in
 * the case where the user hasn't modified this preference yet, or the service
 * returns an unknown value).
 *
 * Note that we're getting these values from the UserConfiguration object instead
 * of from the "owa-outlook-service-options" package for boot bundle size health.
 */
const getDefaultCommandingViewModeOnBoot = (
    primeBootSettingsOptions: any[]
): CommandingViewMode | undefined => {
    const primeCommandingOptions: any[] = primeBootSettingsOptions.filter(
        item => item?.feature == OwsOptionsFeatureType.Commanding
    );

    const viewMode = primeCommandingOptions[0]?.viewMode;

    // Validate server is returning a client-supported value
    const validViewModes = [undefined, 1, 2];
    if (validViewModes.includes(viewMode)) {
        return viewMode;
    }

    // viewMode is an unknown value or the wrong type. Returning
    // undefined ensures our default value logic will be used.
    return undefined;
};

/**
 * This function validates the viewMode, ensuring that the viewMode is in the correct state given the user's settings.
 * If it isn't, it will fix the state and persist the change.
 * @param viewMode The current viewMode that is not validity checked
 */
const validateViewMode = (viewMode: CommandingViewMode | undefined): CommandingViewMode => {
    if (viewMode === undefined) {
        // No persisted viewMode, or invalid response from server. Fallback to client default
        viewMode = 1;

        if (isHostAppFeatureEnabled('ribbonPersistDefault')) {
            saveCommandingViewModeOption(viewMode);
        }
    }

    return viewMode;
};
