import type { MailboxInfo } from 'owa-client-types';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';
import { isAccountExplicitLogon } from './isAccountExplicitLogon';

/**
 * Returns true if the mailbox is shared or a delegate mailbox either the main user mailbox
 * of the account or an other mailbox in the account.
 */
export function isMailboxSharedOrDelegate(mailboxInfo: MailboxInfo): boolean {
    if (mailboxInfo.type === 'UserMailbox') {
        return isSharedCoprincipalAccountEnabled()
            ? isMailboxSharedOrDelegateUserMailbox(mailboxInfo)
            : isAccountExplicitLogon(mailboxInfo);
    }

    return mailboxInfo.type === 'SharedMailbox';
}
