import type TabStore from './schema/TabStore';
import type { PrimaryReadingPaneTabViewState } from './schema/TabViewState';
import { TabType, type TabState } from './schema/TabViewState';
import { createStore } from 'owa-satcheljs';

const primaryTabInitialState: PrimaryReadingPaneTabViewState = {
    id: 'primaryTab',
    type: TabType.Primary,
    state: 2,
    blink: false,
    sxsId: 'primaryTab',
    data: null,
};

const tabStoreData: TabStore = {
    tabs: [primaryTabInitialState],
    tabBarWidth: 0,
    isOverflowMenuShown: false,
    isTabBarAvailable: false,
};

const tabStore = createStore<TabStore>('tab', tabStoreData)();
export const getStore = () => tabStore;
export default tabStore;

// Export the reference from the store (http://aka.ms/mobx4)
export const primaryTab = getStore().tabs[0];
