import {
    messageListMinorTextNext,
    messageListMajorTextNext,
    firstLineThreeCol,
    full,
    medium,
} from './messageListTextStyles.scss';

import { getDensityModeCssClass } from 'owa-fabric-theme';

import classnames from 'owa-classnames';

export const useMessageListTextStyle = (
    textType: 'Minor' | 'Major',
    isFirstLine?: boolean,
    isSingleLineView?: boolean
) => {
    // Get the Proper text styles
    return classnames(
        textType == 'Minor' ? messageListMinorTextNext : messageListMajorTextNext,
        isSingleLineView !== undefined && !isSingleLineView && isFirstLine && firstLineThreeCol,
        getDensityModeCssClass(full, medium, undefined)
    );
};
