import getNativeContextMenuStateStore from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { IContextualMenuItem, Point } from '@fluentui/react';
import { addDocumentEventListener } from 'owa-event-listener';

const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
        hideNativeContextMenu();
    }
};

let removeKeyDownEventListener: (() => any) | undefined;

export const displayNativeContextMenu = (
    mouseEvent: MouseEvent,
    contextualMenuItems: IContextualMenuItem[]
) => {
    // We want to dismiss the native context menu when the user scrolls
    // This needs to be done in the capture phase because a stopPropagation would prevent the event from reaching the window.document
    document.addEventListener('scroll', hideNativeContextMenu, true);
    // We want to dismiss the native context menu when the user presses the escape key
    removeKeyDownEventListener = addDocumentEventListener(
        'displayNativeContextMenu',
        'keydown',
        handleKeyDown,
        document,
        true
    );

    displayNativeContextMenuAction(mouseEvent, contextualMenuItems);
};

export const hideNativeContextMenu = () => {
    // There is no need to keep this event listener around if the native context menu is not displayed
    document.removeEventListener('scroll', hideNativeContextMenu, true);
    removeKeyDownEventListener?.();

    hideNativeContextMenuAction();
};

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const displayNativeContextMenuAction = mutatorAction(
    'displayNativeContextMenuAction',
    (mouseEvent: MouseEvent, contextualMenuItems: IContextualMenuItem[]) => {
        const mouseEventPoint: Point = { x: mouseEvent.x, y: mouseEvent.y };
        const store = getNativeContextMenuStateStore();
        store.point = mouseEventPoint;
        store.contextualMenuItems = contextualMenuItems;
        store.showContextMenu = true;
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const hideNativeContextMenuAction = mutatorAction('hideNativeContextMenuAction', () => {
    getNativeContextMenuStateStore().showContextMenu = false;
});
