import type { Reference } from '@apollo/client';
import type { M365Acquisition, IngestionSource } from 'owa-graph-schema';
import { getClientTitleId } from 'owa-m365-acquisitions/lib/utils/getClientTitleId';

/**
 * Merges two arrays of references removing duplicate entries.
 * We only add values from `incoming` if they don't exist on `existing`.
 *
 * ```js
 * >>> existing = [ {__ref: 'A'}, {__ref: 'B'}]
 * >>> incoming = [ {__ref: 'C'}, {__ref: 'B'}]
 * >>> mergeAcquisitionsDedupe(existing, incoming)
 * [ {__ref: 'A'}, {__ref: 'B'}, {__ref: 'C'} ]
 * ```
 *
 * @param  {Reference[]} incoming
 * @param  {Reference[]} existing
 */
export function mergeAcquisitionsDedupe(
    existing: M365Acquisition[],
    incoming: M365Acquisition[]
): M365Acquisition[] {
    const uniqueAcquisitionMap = new Map<string, M365Acquisition>();
    const unionSet = [...incoming, ...existing];

    for (const element of unionSet) {
        const clientId = getClientTitleId(element);
        if (shouldReplaceAcquisition(uniqueAcquisitionMap.get(clientId), element)) {
            uniqueAcquisitionMap.set(clientId, element);
        }
    }

    return [...uniqueAcquisitionMap.values()];
}

const ingestionSourcePriority: ReadonlyArray<IngestionSource | undefined> = [
    'Hardcoded',
    'UserPinnedAcquisition',
    'BootIndicator',
    undefined,
];

function shouldReplaceAcquisition(
    existing: M365Acquisition | undefined,
    incoming: M365Acquisition
): boolean {
    // only replace if the incoming acquisition's ingestionSource is of higher priority (smaller number) than the existing one's.
    // If priorities are the same, do not replace. If priority is missing from ingestionSourcePriority (-1), it's from the service and therefore
    // higher priority.
    return (
        ingestionSourcePriority.indexOf(incoming.titleDefinition.ingestionSource ?? undefined) <
        ingestionSourcePriority.indexOf(existing?.titleDefinition.ingestionSource ?? undefined)
    );
}

/**
 * Get a diff, using the left (in {A;B} sets) set as reference.
 * ```
 * >>> fullSet = [ {__ref: 'A'}, {__ref: 'B'}, {__ref: 'C'}]
 * >>> existing = [ {__ref: 'A'}, {__ref: 'B'}]
 * >>> getLeftSetDiff(fullSet, existing)
 * [ {__ref: 'C'} ]
 * ```
 *
 * @param  {Reference[]} fullSet
 * @param  {Reference[]} existing
 */
export function getLeftSetDiff(fullSet: Reference[], existing: Reference[]) {
    const diff: Reference[] = [];
    const existingRefs = new Set((existing ?? []).map(ref => ref.__ref));
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    (fullSet ?? []).forEach(element => {
        const ref = element?.__ref;
        if (ref && !existingRefs.has(ref)) {
            diff.push(element);
        }
    });
    return diff;
}

/**
 * Maps a concrete cache object to its reference.
 */
export function mapConcreteToReference<
    T extends Record<string, unknown> = Record<string, unknown>,
    KeyFieldGetter extends (concreteType: T) => string = (concreteType: T) => string
>(concreteType: T | T[], getKeyField: KeyFieldGetter): Reference | Reference[] {
    const createRef = (t: T): Reference => ({
        __ref: getKeyField(t),
    });
    return Array.isArray(concreteType) ? concreteType.map(createRef) : createRef(concreteType);
}
