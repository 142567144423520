import { LazyModule, LazyAction } from 'owa-bundling';

import type {
    GetAppDefinitionCatalog,
    GetAppDefinition,
    GetAppDefinition_sync,
} from './platformAppDefinitionQueries';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AppDefinitions" */ './lazyIndex'),
    { name: 'AppDefinitions' }
);

export const lazyGetAppDefinition = new LazyAction<GetAppDefinition, typeof lazyModule>(
    lazyModule,
    m => m.getAppDefinition
);

export const lazyGetAppDefinition_sync = new LazyAction<GetAppDefinition_sync, typeof lazyModule>(
    lazyModule,
    m => m.getAppDefinition_sync
);

export const lazyGetAppDefinitionCatalog = new LazyAction<
    GetAppDefinitionCatalog,
    typeof lazyModule
>(lazyModule, m => m.getAppDefinitionCatalog);
