import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { LightIcon } from '../components/LightIcon/LightIcon';
import { getLightIconName } from './getLightIconName';

export const registerLightIcon = (iconName: string): string => {
    const name = getLightIconName(iconName);

    registerIcons({
        icons: {
            [name]: <LightIcon {...{ iconName }} />,
        },
    });

    return name;
};
