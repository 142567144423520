import type { RibbonId } from 'owa-ribbon-ids';
import { type MailRibbonGroup, defaultConfig } from 'owa-mail-ribbon-store-shared-types';

/**
 * Returns a set of valid config Ids from a specific tab layout. Useful to be called for different tabs.
 * @param layout `TabScalingAndLayoutConfig['layout']`, should be from defaultConfig
 */
function getValidDefaultConfigIds(layout: MailRibbonGroup[]): Set<RibbonId> {
    const validIds: Set<RibbonId> = new Set();

    for (const { controlIds, groupId } of layout) {
        validIds.add(groupId);

        for (const controlId of controlIds) {
            validIds.add(controlId);
        }
    }

    return validIds;
}

const { homeTab, viewTab } = defaultConfig.singleline;
export const homeTabValidDefaultConfigIds = getValidDefaultConfigIds(homeTab.layout);
export const viewTabValidDefaultConfigIds = getValidDefaultConfigIds(viewTab.layout);
