import {
    highPriorityGroupLabel,
    normalPriorityGroupLabel,
    lowPriorityGroupLabel,
} from './getGroupHeaderForCopilotPriority.locstring.json';
import loc from 'owa-localize';
import type GroupHeader from '../type/GroupHeader';
import type { TableView } from 'owa-mail-list-store';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import {
    isCopilotHighPriority,
    isCopilotLowPriority,
} from 'owa-mail-copilot-inbox-shared/lib/utils/priorityUtils';

/**
 * Get GroupHeader for row
 * @param rowKey item key for which we want to lookup Copilot Priority group range
 * @param tableView tableView
 * @return group header for this row
 */
export default function getGroupHeaderForCopilotPriority(
    rowKey: string,
    tableView: TableView
): GroupHeader {
    const copilotPriorityScore = MailRowDataPropertyGetter.getCopilotInboxScore(rowKey, tableView);
    const isHighPriority = isCopilotHighPriority(copilotPriorityScore);
    const isLowPriority = isCopilotLowPriority(copilotPriorityScore);

    if (isHighPriority) {
        return {
            headerText: () => loc(highPriorityGroupLabel),
            headerId: 'HighPriority',
        };
    }

    if (isLowPriority) {
        return {
            headerText: () => loc(lowPriorityGroupLabel),
            headerId: 'LowPriority',
        };
    }

    return {
        headerText: () => loc(normalPriorityGroupLabel),
        headerId: 'NormalPriority',
    };
}
