import { mutatorAction } from 'satcheljs';
import type { SharePointResourceTokenPrefetchError } from '../Schema/SharePointResourceTokenPrefetchState';
import { type SharePointResourceTokenPrefetchStatus } from '../Schema/SharePointResourceTokenPrefetchState';

// eslint-disable-next-line node/no-deprecated-api  -- (https://aka.ms/OWALintWiki) * This should be fixed, but "new URL()" has somewhat different behavior that makes automated migration difficult. *	> 'url.parse' was deprecated since v11.0.0. Use 'url.URL' constructor instead. */
import { parse as parseUrl } from 'url';
import { getSharePointResourceTokenPrefetchStateStore } from '../store/SharePointResourceTokenPrefetchStateStore';

const getAuthority = (url: string): string => {
    const webUri = parseUrl(url);
    return `${webUri.protocol}//${webUri.host}`;
};

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateSharePointResourceTokenPrefetchStateAction = mutatorAction(
    'updateSharePointResourceTokenPrefetchStateAction',
    function updateSharePointResourceTokenPrefetchState(
        resourceUrl: string,
        status: SharePointResourceTokenPrefetchStatus
    ) {
        resourceUrl = resourceUrl.toLowerCase();
        const authorityUrl = getAuthority(resourceUrl);
        const sharePointResourceTokenStateStore = getSharePointResourceTokenPrefetchStateStore();

        // Update or add new entry to the resource token map
        sharePointResourceTokenStateStore.tokenStatusPerResource.set(authorityUrl.toLowerCase(), {
            status,
        });
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateSharePointResourceTokenPrefetchErrorStateAction = mutatorAction(
    'updateSharePointResourceTokenPrefetchErrorStateAction',
    function updateSharePointResourceTokenPrefetchErrorState(
        resourceUrl: string,
        error?: SharePointResourceTokenPrefetchError
    ) {
        resourceUrl = resourceUrl.toLowerCase();
        const authorityUrl = getAuthority(resourceUrl);
        const sharePointResourceTokenStateStore = getSharePointResourceTokenPrefetchStateStore();

        // Update or add new entry to the resource token map
        sharePointResourceTokenStateStore.tokenStatusPerResource.set(authorityUrl.toLowerCase(), {
            status: 3,
            error,
        });
    }
);
