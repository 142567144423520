import React from 'react';
import { touchBehavior, type TouchState, type TouchThreshold } from 'touch-behavior';
import type MailSwipeAction from 'owa-service/lib/contract/MailSwipeAction';
import { getSwipeRightAction, getSwipeLeftAction } from 'owa-mail-list-swipe-utils';
import { getSwipeHandler } from 'owa-mail-list-item-shared';
import type { MailListItemWrapperProps } from 'owa-mail-list-item-shared';

import { TOUCH_THRESHOLD } from '../utils/constants';
import { logSwipeAction, skipHandleTouchCallback, swipePerfMeasurer } from '../utils/helpers';
import { SwipeInProgressPlaceholder } from './SwipeInProgressPlaceholder';

export default function mailListItemTouchBehavior(
    MailListItemComponent: React.FC<MailListItemWrapperProps>
): React.ComponentClass<MailListItemWrapperProps> {
    return touchBehavior<MailListItemWrapperProps>({
        Component: MailListItemComponent,
        originalLeftSwipeRenderer: swipeRenderer(true /*isSwipeRight*/), // Left swipe renderer in TouchBehavior is a "Swipe from left" action
        originalRightSwipeRenderer: swipeRenderer(false /*isSwipeRight*/), // Right swipe renderer in TouchBehavior is a "Swipe from right" action
        originalGetLeftSwipeAction: getLeftSwipeAction,
        originalGetRightSwipeAction: getRightSwipeAction,
        skipHandleTouchCallback,
        threshold: TOUCH_THRESHOLD,
        notifyOnSwipe: swipePerfMeasurer,
        shouldHandleRTLScenario: false,
        renderStaticSwipeRenderer: true,
    });
}

const getLeftSwipeAction = () => (props: MailListItemWrapperProps) => {
    const swipeAction = getSwipeRightAction(); // The getLeftSwipeAction callback in TouchBehavior is really "Swipe from left"
    if (swipeAction !== 0) {
        const rowKey = props.mailListItemDataProps.rowKey;
        const tableViewId = props.mailListTableProps.tableViewId;
        logSwipeAction(
            true /*isSwipeRight*/,
            swipeAction,
            rowKey,
            tableViewId,
            false /*isItemPart*/
        );
        getSwipeHandler(swipeAction)?.(rowKey, tableViewId, 'Swipe');
    }
};

const getRightSwipeAction = () => (props: MailListItemWrapperProps) => {
    const swipeAction = getSwipeLeftAction(); // The getRightSwipeAction callback in TouchBehavior is really "Swipe from right"
    if (swipeAction !== 0) {
        const rowKey = props.mailListItemDataProps.rowKey;
        const tableViewId = props.mailListTableProps.tableViewId;
        logSwipeAction(
            false /*isSwipeRight*/,
            swipeAction,
            rowKey,
            tableViewId,
            false /*isItemPart*/
        );
        getSwipeHandler(swipeAction)?.(rowKey, tableViewId, 'Swipe');
    }
};

const swipeRenderer =
    (isSwipeRight: boolean) =>
    (props: MailListItemWrapperProps, state: TouchState, threshold: TouchThreshold) => {
        return (
            <SwipeInProgressPlaceholder
                isSwipeRight={isSwipeRight}
                state={state}
                threshold={threshold}
                isUnread={props.mailListItemDataProps.unreadCount > 0}
            />
        );
    };
