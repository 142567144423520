import type { MailboxInfo } from 'owa-client-types';
import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';
import getAccountDiagnosticDataForMailboxInfo from 'owa-account-source-list-store/lib/utils/getAccountDiagnosticDataForMailboxInfo';
import { getStoreDiagnosticInfo } from '../store/store';
import { accountKeyForAddAccount } from 'owa-account-source-list-types';
import { getFeatureFlagsIndexerForMailboxInfo } from 'owa-feature-flags';

// Indicates if the default application settings are allowed
let allowDefaultApplicationSettings: boolean = false;

// When set allows the default application settings to be returned without raising an alert
export function setAllowDefaultApplicationSettings(isAllowed: boolean) {
    allowDefaultApplicationSettings = isAllowed;
}

export function getAllowDefaultApplicationSettings(): boolean {
    return allowDefaultApplicationSettings;
}

/**
 * Determines if the specified MailboxInfo was created for adding an account
 * @param mailboxInfo True if the MailboxInfo was created for adding an account
 * @returns false otherwise
 */
function isAddAccountMailboxInfo(mailboxInfo?: MailboxInfo): boolean {
    return (
        !!mailboxInfo &&
        accountKeyForAddAccount == getFeatureFlagsIndexerForMailboxInfo(mailboxInfo)
    );
}

// Raises an alert because default settings will be used because the application settings
// was accessed before the store is initialzied
export function raiseAlertForDefaultSettings(
    mailboxInfo: MailboxInfo | undefined,
    settingsGroup: string,
    dontErrorIfNotInitialized: boolean = false
) {
    if (
        !allowDefaultApplicationSettings &&
        !dontErrorIfNotInitialized &&
        !isAddAccountMailboxInfo(mailboxInfo)
    ) {
        const accountDiagnostic = getAccountDiagnosticDataForMailboxInfo(mailboxInfo);
        const storeDiagnosticInfo = getStoreDiagnosticInfo();
        const info = {
            settingsGroup,
            hasMailboxInfo: !!mailboxInfo,
            mailboxType: mailboxInfo?.type,
            hasUserId: !!mailboxInfo?.userIdentity,
            hasSmtp: !!mailboxInfo?.mailboxSmtpAddress,
            rank: mailboxInfo?.mailboxRank,
            isAnonymous: mailboxInfo?.isAnonymous,
            isRemoved: mailboxInfo?.isRemoved,
            ...storeDiagnosticInfo,
            ...accountDiagnostic,
        };

        const error: TraceErrorObject = new Error(
            'Attempted to read settings before application settings were initialized.'
        );
        error.additionalInfo = info;
        errorThatWillCauseAlert(error);
    }
}
