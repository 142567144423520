import { owaComputedFn } from 'owa-computed-fn';
import getStore from '../store/store';
import type ViewType from '../store/schema/ViewType';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getUserConfiguration } from 'owa-session-store';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import shouldShowUnstackedReadingPane from './shouldShowUnstackedReadingPane';

export const isReadingPaneStackedView = owaComputedFn(() => {
    if (isFeatureEnabled('rp-momocoSettings')) {
        const store = getStore();
        return store.readingPaneViewType != 2;
    } else {
        const globalListViewTypeReact =
            getUserConfiguration()?.UserOptions?.GlobalListViewTypeReact;
        return globalListViewTypeReact != 1 && !shouldShowUnstackedReadingPane();
    }
});
