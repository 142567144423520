import getExtensibilityState from './getExtensibilityState';
import type { IEnabledAddinCommands } from 'owa-addins-types';
import type { MailboxInfo } from 'owa-client-types';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';

export default function isExtensibilityContextInitialized(mailboxInfo?: MailboxInfo): boolean {
    const isMosEnabled = isMOS3AppServiceAvailable(mailboxInfo);
    const enabledAddinCommands: IEnabledAddinCommands =
        getExtensibilityState(mailboxInfo).EnabledAddinCommands;
    return !!enabledAddinCommands
        ? isMosEnabled
            ? enabledAddinCommands.isInitialized && enabledAddinCommands.isMOSAddinsInitialized
            : enabledAddinCommands.isInitialized
        : false;
}
