import getDateSortTimeStampFromRowData from './getDateSortTimeStampFromRowData';
import { addMailListLog, getMailListLogObjectToAddToStore } from 'owa-mail-list-logging';
import tombstoneOperations, { type TombstoneReasonType } from 'owa-mail-list-tombstone';
import type { MailListRowDataType, TableView } from 'owa-mail-list-store';
import arrayEquals from 'array-equal';
import type { ConversationType } from 'owa-graph-schema';
import type ItemId from 'owa-service/lib/contract/ItemId';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Function that evaluates if the row payload should be processed when it is
 * received from the server, whether from notification or from Find calls
 * @param serverRow row payload received from server
 * @param tableView to which the row belongs
 * @returns a flag indicating whether the row should be processed further or not
 */
export default function shouldSkipProcessRow(
    serverRow: MailListRowDataType,
    tableView: TableView
): boolean {
    /**
     * Do not process the row if it was earlier than the lastEmptiedTime
     * in case we did an empty folder in this tableView.
     */
    const timeStamp = getDateSortTimeStampFromRowData(serverRow, tableView.tableQuery.listViewType);
    if (
        tableView.lastEmptiedTime &&
        timeStamp.lastModifiedTime &&
        tableView.lastEmptiedTime > new Date(timeStamp.lastModifiedTime)
    ) {
        addMailListLog(
            getMailListLogObjectToAddToStore('shouldSkipProcessRow:FolderEmptiedAfterRowModified', {
                rowInstanceKey: serverRow.InstanceKey,
                lastEmptiedTime: tableView.lastEmptiedTime.toDateString(),
                lastModifiedTime: timeStamp.lastModifiedTime,
            })
        );

        return true;
    }

    /**
     * Row is in tombstoned for remove reason so do not process it further
     */
    const tombstoneReasons = serverRow.InstanceKey
        ? tombstoneOperations.getTombstonedReasons(serverRow.InstanceKey, tableView.serverFolderId)
        : [];

    const rowHasTombstoneRemoveReason = tombstoneReasons.indexOf(3) > -1;

    // If we are processing a conversation that's in the tombstone for a remove row reason AND if it has some corresponding customData (itemIds),
    // we need to compare the itemIds from the tombstone entry and the server row to see if they are the same. If they are the same, we know
    // that the row's itemIds have been updated on the server since the item was removed on the client so we remove the row from the tombstone
    // and process the RowModified notification. Until then, we don't want to process the row modified notification because it's a stale update
    // and we don't want the row popping back into an incorrect position in the list.
    const conversation = serverRow as ConversationType;
    if (
        rowHasTombstoneRemoveReason &&
        serverRow.InstanceKey &&
        conversation &&
        isFeatureEnabled('tri-itemPartRemoval-tombstone')
    ) {
        const customData = tombstoneOperations.getTombstonedReasonCustomData(
            serverRow.InstanceKey,
            tableView.serverFolderId,
            3
        );

        try {
            // We need to parse the customData we receive from the tombstone entry because it's a JSON string.
            const parsedData = customData && JSON.parse(customData);
            if (
                !!parsedData &&
                arrayEquals(
                    conversation.ItemIds?.map(itemId => (itemId as ItemId).Id) ?? [],
                    (parsedData as string[]) ?? []
                )
            ) {
                tombstoneOperations.removeMutator(
                    serverRow.InstanceKey,
                    tableView.serverFolderId,
                    3
                );
                return false;
            }
        } catch {
            // Invalid JSON string in customData. Since there's no data to compare, keep the row in the tombstone.
            return true;
        }
    }

    addMailListLog(
        getMailListLogObjectToAddToStore('shouldSkipProcessRow:IsRowInTombstoneForRemoveReason', {
            rowInstanceKey: serverRow.InstanceKey,
            shouldSkipProcessRow: rowHasTombstoneRemoveReason,
        })
    );

    return rowHasTombstoneRemoveReason;
}
