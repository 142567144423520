import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CopilotDigest" */ './lazyIndex'),
    { name: 'CopilotDigest' }
);

// Components
export const LazyCopilotDigestButton = createLazyComponent(lazyModule, m => m.CopilotDigestButton);
export const LazyCopilotDigestRollup = createLazyComponent(lazyModule, m => m.CopilotDigestRollup);
export const LazyCopilotDigestNavigation = createLazyComponent(
    lazyModule,
    m => m.CopilotDigestNavigation
);
export const LazyCopilotDigestCard = createLazyComponent(lazyModule, m => m.CopilotDigestCard);

// Lazy Actions
export const lazyOnCopilotDigestButtonClickedMutator = new LazyAction(
    lazyModule,
    m => m.onCopilotDigestButtonClickedMutator
);

export const lazyGenerateDigest = new LazyAction(lazyModule, m => m.generateDigest);
