import { mergeAcquisitionsDedupe } from '../../utils/apolloCacheUtils';
import { filterAcquisitions } from '../../utils/filterAcquisitions';
import { disableNormalizationForTypes } from 'owa-nova-cache/lib/utils/disableNormalizationForTypes';
import { writeM365MessageExtensionsQuery } from 'owa-nova-cache/lib/transform/writeM365MessageExtensionsQuery';
import type { MailboxInfo } from 'owa-client-types';
import type { M365Acquisition, M365AcquisitionsQueryResult } from 'owa-graph-schema';
import type { TypedTypePolicies } from 'owa-graph-schema-type-policies';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';
import { lazyUpdateDatabaseCachedAcquisitions } from 'owa-m365-acquisitions-database/lib/lazyFunctions';
import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';

export const getM365AcquisitionsQueryTypePolicy: () => TypedTypePolicies = () => ({
    /**
     * Force field to be non-normalized (i.e. nested), as it has an (non-unique) `id` property, which Apollo,
     * by default, normalizes the field and uses the `id` as its key.
     * Because many 3rd party apps have generic `id` values, e.g., "search", it is a sure-way for collisions.
     */
    ...disableNormalizationForTypes([
        'M365Acquisition',
        'InputExtensionCommand',
        'Runtime',
        'RuntimeContextualLaunch',
        'AddInExtensionTab',
        'AutoRunEvent',
        'AddInExtensionTabGroup',
        'AddInButtonShowTaskPane',
        'AddInButtonExecuteFunction',
        'AddInControlMenu',
        'WebApplicationInfo',
    ]),
    Query: {
        fields: {
            m365MessageExtensions: {
                keyArgs(_args, context) {
                    const messageExtensionTypeVariable = context?.variables?.messageExtensionType;
                    return `${context.fieldName}:${messageExtensionTypeVariable ?? ''}`;
                },
            },
            m365TitleLaunchInfo: {
                keyArgs(_args, context) {
                    return context?.variables?.mailboxInfo
                        ? `${context.fieldName}:${getIndexerValueForMailboxInfo(
                              context.variables.mailboxInfo
                          )}:${context?.variables?.titleId}`
                        : `${context.fieldName}:${context?.variables?.titleId}`;
                },
            },
            m365Acquisitions: {
                keyArgs(_args, context) {
                    return context?.variables?.mailboxInfo
                        ? `${context.fieldName}:${getIndexerValueForMailboxInfo(
                              context.variables.mailboxInfo
                          )}`
                        : `${context.fieldName}`;
                },
                merge(
                    existing: M365AcquisitionsQueryResult | undefined,
                    incoming: M365AcquisitionsQueryResult | undefined,
                    { canRead, variables }
                ): M365AcquisitionsQueryResult {
                    if (!incoming?.edges || incoming?.edges.length === 0) {
                        return {
                            __typename: 'M365AcquisitionsQueryResult',
                            edges: existing?.edges ?? [],
                            nextInterval: existing?.nextInterval ?? -1,
                            source: existing?.source ?? 'BOOTSTRAP',
                        };
                    }

                    const incomingAcquisitions = incoming.edges.filter(canRead);
                    const existingAcquisitions = existing?.edges.filter(canRead);

                    // Merge and create valid set.
                    const mergedAcquisitions = existingAcquisitions
                        ? mergeAcquisitionsDedupe(existingAcquisitions, incomingAcquisitions)
                        : incomingAcquisitions;

                    // Pick which result is highest priority (to determine source and nextInterval)
                    const bestResult = selectBestResult(existing, incoming);

                    // Run filtering step. Skip running in the bootstrap scenario (no existing acquisitions
                    // in cache, or both existing and incoming acquisitions came from bootstrap)
                    const validAcquisitions =
                        bestResult.source !== 'BOOTSTRAP'
                            ? filterAcquisitions(mergedAcquisitions)
                            : mergedAcquisitions;

                    // Side-effect writes.
                    tryWriteNovaQueries(validAcquisitions, variables?.mailboxInfo);

                    if (
                        isFeatureEnabled('mos-ribbonSurfaceAppCache') &&
                        incoming.source === 'SERVICE'
                    ) {
                        void lazyUpdateDatabaseCachedAcquisitions.importAndExecute(
                            validAcquisitions,
                            variables?.mailboxInfo
                        );
                    }

                    return {
                        __typename: 'M365AcquisitionsQueryResult',
                        edges: validAcquisitions,
                        nextInterval: bestResult.nextInterval,
                        source: bestResult.source,
                    };
                },
            },
        },
    },
});

/**
 * Select the highest priority result from the two given results based on their source.
 * Ordered priority: SERVICE > CACHE > BOOTSTRAP > none
 */
function selectBestResult(
    existing: M365AcquisitionsQueryResult | undefined,
    incoming: M365AcquisitionsQueryResult
): M365AcquisitionsQueryResult {
    const resultSourcePriority = ['BOOTSTRAP', 'CACHE', 'SERVICE'] as const;
    return !existing ||
        resultSourcePriority.indexOf(incoming.source) >=
            resultSourcePriority.indexOf(existing.source)
        ? incoming
        : existing;
}

function tryWriteNovaQueries(acquisitions: M365Acquisition[], queryMailboxInfo?: MailboxInfo) {
    if (isMOS3AppServiceAvailable(queryMailboxInfo)) {
        // only write message extensions query if the flyouts are using MOS3/acquisitions. Without MOS3, flyout data
        // comes from addins infra/GetExtensibilityContext, and uses writeM365MessageExtensionsQueryFromEnabledAddins
        writeM365MessageExtensionsQuery(acquisitions, queryMailboxInfo);
    }
}
