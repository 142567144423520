import type { MailboxInfo } from 'owa-client-types';
import { getIsSearchTableShown } from 'owa-mail-list-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { areFoldersInSameMailbox } from 'owa-folders';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isPstFileMutationEnabled } from 'owa-account-source-list/lib/flights';

export default function doesTableSupportUndo(
    sourceFolderId: string,
    mailboxInfo: MailboxInfo,
    destinationFolderId?: string
): boolean {
    return !!(
        sourceFolderId &&
        !getIsSearchTableShown() &&
        mailboxInfo &&
        (mailboxInfo.type === 'UserMailbox' ||
            (isPstFileMutationEnabled() &&
                mailboxInfo.type === 'PstFile' &&
                folderIdToName(sourceFolderId) != 'deleteditems') ||
            (isFeatureEnabled('grp-loadFolders') && mailboxInfo.type === 'GroupMailbox')) &&
        folderIdToName(sourceFolderId) != 'recoverableitemsdeletions' &&
        folderIdToName(sourceFolderId) != 'outbox' &&
        areMailboxesSupported(sourceFolderId, destinationFolderId)
    );
}

function areMailboxesSupported(sourceFolderId: string, destinationFolderId?: string) {
    // Some operations (Move) we need the source and destination folder
    // So we need to check the mailbox types, since Undo operate on the ItemIds/ConversationIds
    // and they are not immutable across mailboxes, then we cant undo them
    if (destinationFolderId) {
        return areFoldersInSameMailbox(sourceFolderId, destinationFolderId);
    }

    return true;
}
