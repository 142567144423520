import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailSearchActions" */ './lazyIndex'),
    { name: 'MailSearchActions' }
);

export const lazyLoadSearchTable = new LazyAction(lazyModule, m => m.loadSearchTable);

export const lazyCleanSearchTableState = new LazyAction(lazyModule, m => m.cleanSearchTableState)
    .importAndExecute;

export const lazyLoadMoreFindItemSearch = new LazyAction(lazyModule, m => m.loadMoreFindItemSearch);
export const lazyLoadMoreSubstrateSearch = new LazyAction(
    lazyModule,
    m => m.loadMoreSubstrateSearch
);
