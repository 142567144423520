import type { ModulesEnabledForAppBootstrap } from 'owa-workloads-module';
import { onModuleClick } from 'owa-workloads';
import { Module } from 'owa-workloads-module';
import { getBootstrapOptions, registerBootstrapOptions } from './optionsRegistry';
import type { AppBootstrapOptions } from './types/AppBootstrapOptions';
import { getCurrentModule } from 'owa-app-module-store';
import { updateModuleForVdirChange } from './utils/updateModuleForVdirChange';
import { registerFontSubsets } from 'owa-icons';
import { getResourcePath } from 'owa-config';
import { preloadStrategies } from 'owa-shared-bootstrap/lib/preloadStrategies';
import { orchestrator } from 'satcheljs';
import { isModuleSwitchEnabled } from 'owa-left-rail-utils';
import { onAppHostHeaderStartSearch } from 'owa-app-host-search';
import { registerRouterRoutes } from 'owa-router';
import { setApp } from 'owa-config/lib/bootstrapOptions';
import { getBposNavBarDataAsync } from 'owa-bpos-store/lib/bposNavBarData';
import onOpenMetaOsApp from 'owa-app-host-state/lib/actions/onOpenMetaOsApp';
import { wrapFunctionForCoreDatapoint, returnTopExecutingActionDatapoint } from 'owa-analytics';
import { setInlineModuleSwitchDatapoint } from 'owa-performance-datapoints';
import { lazyUpdateAnalyticsSetup } from 'owa-shared-post-boot';
import onUninstallM365Acquisition from 'owa-m365-acquisitions/lib/actions/onUninstallM365Acquisition';
import { getSelectedApp } from 'owa-appbar-state';
import type { ModuleConfigMap } from 'owa-shared-start-types';
import type { BootstrapOptions } from 'owa-bootstrap';
import { setModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import type { MailboxInfo } from 'owa-client-types';
import { unblockLazyLoadCallbacks, blockLazyLoadCallbacks } from 'owa-bundling-light';
import { getLeftNavInitState } from './utils/getLeftNavInitState';
import { isFeatureEnabled } from 'owa-feature-flags';

let isRunning = false;

orchestrator(onModuleClick, ({ module: mod, currentlySelectedModule, mailboxInfo }) => {
    changeModuleIfEnabled(mod, currentlySelectedModule, mailboxInfo);
});

orchestrator(onOpenMetaOsApp, actionMessage => {
    if (actionMessage.mailboxInfo) {
        setModuleContextMailboxInfo(Module.AppHost, actionMessage.mailboxInfo);
    }
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    changeModuleIfEnabled(Module.AppHost, getCurrentModule()!, actionMessage.mailboxInfo);
});

orchestrator(onAppHostHeaderStartSearch, () => {
    changeModuleIfEnabled(Module.Mail, Module.AppHost);
});

orchestrator(onUninstallM365Acquisition, actionMessage => {
    const selectedApp = getSelectedApp();
    const appId = actionMessage.appId ?? '';
    if (appId == selectedApp) {
        changeModuleIfEnabled(Module.Mail, Module.AppHost);
    }
});

let moduleToConfigMap: ModuleConfigMap<BootstrapOptions> | undefined;
export function setModuleToConfigMap(map: ModuleConfigMap<BootstrapOptions>) {
    moduleToConfigMap = map;
}

function resetIsRunning() {
    isRunning = false;
}

function changeModuleIfEnabled(
    newModule: Module,
    currentlySelectedModule: Module | undefined,
    mailboxInfo?: MailboxInfo
) {
    if (
        currentlySelectedModule != newModule &&
        isModuleSwitchEnabled(currentlySelectedModule, newModule) &&
        !isRunning
    ) {
        isRunning = true;
        changeModuleInternal(newModule, currentlySelectedModule, mailboxInfo).then(
            resetIsRunning,
            resetIsRunning
        );
    }
}
type PreBootstrapType = any;
const changeModuleInternal = wrapFunctionForCoreDatapoint(
    {
        name: 'InlineModuleSwitch',
        customData: (newModule: Module, currentlySelectedModule: Module | undefined) => [
            newModule,
            currentlySelectedModule,
        ],
        skipAutoEndDatapoint: (newModule: Module, _currentlySelectedModule: Module | undefined) => {
            // skip datapoint auto end when switching to Calendar & Groups
            return newModule === Module.Calendar || newModule === Module.Groups;
        },
    },
    async function changeModuleInternal(
        newModule: Module,
        currentlySelectedModule: Module | undefined,
        mailboxInfo?: MailboxInfo
    ) {
        const dp = returnTopExecutingActionDatapoint('InlineModuleSwitch');
        setInlineModuleSwitchDatapoint(dp);
        dp?.addCustomData({
            isEventsCacheFlightEnabled: isFeatureEnabled('cal-surface-eventsCache'),
        });

        let options = getBootstrapOptions(newModule);
        if (!options) {
            if (dp) {
                dp.madeNetworkRequest = true;
            }
            setApp(newModule);
            if (!moduleToConfigMap) {
                throw new Error('CM_MissingAppRegistration');
            }
            const lazyConfig = moduleToConfigMap[newModule as ModulesEnabledForAppBootstrap];

            if (lazyConfig.preBootstrap) {
                lazyConfig.preBootstrap.importAndExecute();
                dp?.addCustomData({ prebootstrapType: 1 });
            } else {
                dp?.addCustomData({ prebootstrapType: 0 });
            }

            if (dp && lazyConfig.options) {
                lazyConfig.options.addPerfDatapoint(dp);
            }

            if (newModule === Module.Calendar || newModule === Module.AppHost) {
                dp?.addCustomData({ leftNavInitState: getLeftNavInitState() });
            }

            blockLazyLoadCallbacks();

            // Just in case there is an error, we will only block for 10 seconds
            // as a fallback
            setTimeout(unblockLazyLoadCallbacks, 10000);

            const sessionPromise = Promise.resolve(undefined);
            const newOptions =
                typeof lazyConfig.optionsSync === 'function'
                    ? (lazyConfig.optionsSync(sessionPromise) as AppBootstrapOptions)
                    : ((await lazyConfig.options.importAndExecute(
                          sessionPromise
                      )) as AppBootstrapOptions);
            registerBootstrapOptions(newOptions);
            const promises = preloadStrategies(newOptions.strategies)
                .filter(p => p)
                .map(p => p.promise);
            const statePromise = newOptions.initializeState(undefined, true /* isModuleSwitch */);
            if (statePromise) {
                promises.push(statePromise);
            }
            const routePromise =
                newOptions.routerOptions &&
                registerRouterRoutes(newOptions.routerOptions, true /* registerAll */);
            if (routePromise) {
                promises.push(routePromise);
            }

            promises.push(
                getBposNavBarDataAsync('changeModuleInternal', undefined, undefined, mailboxInfo)
            );
            registerFontSubsets(getResourcePath(), newOptions.iconFonts);
            const postLazyAction = newOptions.postLazyAction;
            if (postLazyAction) {
                postLazyAction.importAndExecute.apply(
                    postLazyAction,
                    newOptions.postLazyArgs || []
                );
            }
            await Promise.all(promises);
            options = newOptions;
        }

        updateModuleForVdirChange(options);
        lazyUpdateAnalyticsSetup.importAndExecute(
            newModule,
            currentlySelectedModule,
            options?.analyticsOptions
        );

        // unblockLazyLoadCallbacks is not necessary for the render and can be expensive
        setTimeout(unblockLazyLoadCallbacks, 0);
    }
);
