import type { MailFolder } from 'owa-graph-schema';
import getRootFolderForFolderTree from './getRootFolderForFolderTree';
import { ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';

function isSharedOrDelegateMailbox(mailboxInfo: MailboxInfo) {
    return isSharedCoprincipalAccountEnabled() && isMailboxSharedOrDelegateUserMailbox(mailboxInfo);
}

/**
 * Archive mailbox folder tree root folder selector
 * @param userIdentity identifies the mailbox uniquely
 */
export default function getArchiveFolderTreeRootFolder(
    mailboxInfo: MailboxInfo
): MailFolder | undefined {
    const id =
        isFeatureEnabled('fp-archive-shared-mailboxes') &&
        mailboxInfo.isExplicitLogon &&
        !isSharedOrDelegateMailbox(mailboxInfo)
            ? mailboxInfo.mailboxSmtpAddress
            : ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID;
    return getRootFolderForFolderTree(id, mailboxInfo);
}
