import type { RibbonScalingStep, RibbonScalingTransformation } from 'owa-acui';

/**
 * Applies a scaling function to a set of control ids. If only one control id is passed in, a single RibbonScalingTransformation is returned.
 * If multiple control ID's are passed in, an array of Scaling Transformations is returned as the RibbonScalingStep.
 */
function applyScalingTransformationOnIds(
    scalingFunction: (id: string) => RibbonScalingTransformation,
    ...ids: (number | string)[]
): RibbonScalingStep {
    if (ids.length === 1) {
        return scalingFunction(ids[0].toString());
    }
    return ids.map((id: number | string) => scalingFunction(id.toString()));
}

export function changeGroupLayout(...groupIds: number[]): RibbonScalingStep {
    return applyScalingTransformationOnIds(
        (groupId: string) => ({
            type: 'ChangeGroupLayout',
            groupId,
        }),
        ...groupIds
    );
}

export function dropLabel(...controlIds: (number | string)[]): RibbonScalingStep {
    return applyScalingTransformationOnIds(
        (controlId: string) => ({
            type: 'DropLabel',
            controlId,
        }),
        ...controlIds
    );
}

// This utility is not currently being used, but could be in the future. Commenting out, but leaving here
// export function dropPreviewItemFromGallery(...controlIds: (number | string)[]): RibbonScalingStep {
//     return applyScalingTransformationOnIds(
//         (controlId: string) => ({
//             type: 'DropPreviewItemFromGallery',
//             controlId,
//         }),
//         ...controlIds
//     );
// }

// This utility is not currently being used, but could be in the future. Commenting out, but leaving here
// export function previewGalleryToFlyoutAnchor(previewGalleryId: number): RibbonScalingStep {
//     return applyScalingTransformationOnIds(
//         (controlId: string) => ({
//             type: 'GalleryToFlyout',
//             controlId,
//         }),
//         previewGalleryId
//     );
// }

// This utility is not currently being used, but could be in the future. Commenting out, but leaving here
// export function convertGroupToFlyout(...groupIds: number[]): RibbonScalingStep {
//     return applyScalingTransformationOnIds(
//         (groupId: string) => ({
//             type: 'GroupToFlyout',
//             groupId,
//         }),
//         ...groupIds
//     );
// }

// This utility is not currently being used, but could be in the future. Commenting out, but leaving here
// export function moveGroupToSingleOverflow(...groupIds: number[]): RibbonScalingStep {
//     return applyScalingTransformationOnIds(
//         (groupId: string) => ({
//             type: 'MoveGroupToSingleOverflow',
//             groupId,
//         }),
//         ...groupIds
//     );
// }

export function moveToOverflowWell(...controlIds: (number | string)[]): RibbonScalingStep {
    return applyScalingTransformationOnIds(
        (controlId: string) => ({
            type: 'MoveToOverflow',
            controlId,
        }),
        ...controlIds
    );
}
