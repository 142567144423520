import type { MailFolder } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import { ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import FolderTreeLoadStateEnum from '../store/schema/FolderTreeLoadStateEnum';
import { setFolderTreeData } from '../mutators/setFolderTreeData';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getMailboxInfoForAuxArchive } from 'owa-mailbox-info/lib/getMailboxInfoForAuxArchive';

export default async function initializeArchiveFolderTree(mboxInfo: MailboxInfo) {
    // Initialize the archive root folder using the data received in session data such as display name and set it in the store.
    const mailboxInfo = mboxInfo;
    const sessionSettings = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
    const archiveMailboxInfo = getMailboxInfoForAuxArchive(
        mailboxInfo,
        sessionSettings?.ArchiveMailboxGuid ?? ''
    );

    const folder = <MailFolder>{
        distinguishedFolderType: ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
        displayName: sessionSettings?.ArchiveDisplayName,
        mailboxInfo: archiveMailboxInfo,
    };

    // update the archive folder tree root and loading state.
    setFolderTreeData(
        ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
        folder,
        FolderTreeLoadStateEnum.Uninitialized,
        false,
        0,
        mailboxInfo,
        undefined
    );
}
