import type {
    PrimaryMailboxSearchScope,
    SharedFoldersSearchScope,
    ArchiveMailboxSearchScope,
    SingleGroupSearchScope,
    MetaOSAppSearchScope,
    SingleGroup3sSearchScope,
    SearchScope,
    PstFileSearchScope,
} from '../../data/schema/SearchScope';
import type BaseSearchScopeType from 'owa-service/lib/contract/BaseSearchScopeType';
import type UnifiedGroupIdentityType from 'owa-service/lib/contract/UnifiedGroupIdentityType';
import distinguishedFolderId from 'owa-service/lib/factory/distinguishedFolderId';
import folderId from 'owa-service/lib/factory/folderId';
import primaryMailboxSearchScopeType from 'owa-service/lib/factory/primaryMailboxSearchScopeType';
import largeArchiveSearchScopeType from 'owa-service/lib/factory/largeArchiveSearchScopeType';
import type SearchScopeArchives from 'owa-service/lib/contract/SearchScopeArchives';
import singleLargeArchiveSearchScopeType from 'owa-service/lib/factory/singleLargeArchiveSearchScopeType';
import searchFolderScopeType from 'owa-service/lib/factory/searchFolderScopeType';
import getSingleGroup3sSearchScopeType from './getSingleGroup3sSearchScopeType';
import sharedFoldersSearchScopeType from './sharedFoldersSearchScopeType';
import pstFileSearchScopeType from './pstFileSearchScopeType';
import unifiedGroupIdentity from 'owa-service/lib/factory/unifiedGroupIdentity';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';

export function getPrimaryMailboxSearchScopeType(
    primaryMailboxSearchScope: PrimaryMailboxSearchScope
): BaseSearchScopeType[] {
    const distinguishedFolder = folderIdToName(primaryMailboxSearchScope.folderId);
    let folderScopeId;
    if (distinguishedFolder != 'none') {
        folderScopeId = distinguishedFolderId({
            Id: distinguishedFolder,
        });
    } else {
        folderScopeId = folderId({
            Id: primaryMailboxSearchScope.folderId,
        });
    }

    return [
        primaryMailboxSearchScopeType({
            FolderScope: searchFolderScopeType({
                BaseFolderId: folderScopeId,
            }),
            IsDeepTraversal: true,
        }),
    ];
}

export function getArchiveMailboxSearchScopeType(
    archiveMailboxSearchScope: ArchiveMailboxSearchScope
): BaseSearchScopeType[] {
    const distinguishedFolder = folderIdToName(archiveMailboxSearchScope.folderId);
    let folderScopeId;
    if (distinguishedFolder != 'none') {
        folderScopeId = distinguishedFolderId({
            Id: distinguishedFolder,
        });
    } else {
        folderScopeId = folderId({
            Id: archiveMailboxSearchScope.folderId,
        });
    }

    if (distinguishedFolder == 'archivemsgfolderroot') {
        return [largeArchiveSearchScopeType({ ArchiveTypes: 16777215 })];
    } else {
        return [
            singleLargeArchiveSearchScopeType({
                FolderScope: searchFolderScopeType({
                    BaseFolderId: folderScopeId,
                }),
            }),
        ];
    }
}

/**
 * Helper to return the search scope type of shared folders
 * @param sharedFoldersSearchScope
 */
export function getSharedFoldersSearchScopeType(
    sharedFoldersSearchScope: SearchScope
): BaseSearchScopeType[] {
    const distinguishedFolder = folderIdToName(
        (sharedFoldersSearchScope as SharedFoldersSearchScope).folderId,
        true /* shouldSearchShared */
    );
    let folderScopeId;
    if (distinguishedFolder != 'none') {
        folderScopeId = distinguishedFolderId({
            Id: distinguishedFolder,
        });
    } else {
        folderScopeId = folderId({
            Id: (sharedFoldersSearchScope as SharedFoldersSearchScope).folderId,
        });
    }

    return [
        sharedFoldersSearchScopeType({
            FolderScope: searchFolderScopeType({
                BaseFolderId: folderScopeId,
            }),
        }),
    ];
}

/**
 * Helper to return the search scope type of public folders
 * @param publicFolderSearchScope
 */
export function getPublicFolderSearchScopeType(): BaseSearchScopeType[] {
    return [];
}

export function getSingleGroupSearchScopeType(
    singleGroupSearchScope: SingleGroupSearchScope
): BaseSearchScopeType[] {
    const folderScopeId = folderId({
        Id: (singleGroupSearchScope as SingleGroup3sSearchScope).folderId,
    });
    return [
        getSingleGroup3sSearchScopeType({
            GroupIdentity: unifiedGroupIdentity({
                Type: 0,
                Value: singleGroupSearchScope.groupId,
            }),
            FolderScope: searchFolderScopeType({
                BaseFolderId: folderScopeId,
            }),
        }),
    ];
}

export function getCalendarSearchScopeType(): BaseSearchScopeType[] {
    return [];
}

export function getMetaOSAppSearchScopeType(
    _metaOSAppSearchScope: MetaOSAppSearchScope
): BaseSearchScopeType[] {
    return [];
}

export function getPstFileSearchScopeType(
    pstFileSearchScope: PstFileSearchScope
): BaseSearchScopeType[] {
    const distinguishedFolder = folderIdToName(pstFileSearchScope.folderId);
    let folderScopeId;
    if (distinguishedFolder != 'none') {
        folderScopeId = distinguishedFolderId({
            Id: distinguishedFolder,
        });
    } else {
        folderScopeId = folderId({
            Id: pstFileSearchScope.folderId,
        });
    }

    return [
        pstFileSearchScopeType({
            FolderScope: searchFolderScopeType({
                BaseFolderId: folderScopeId,
            }),
        }),
    ];
}
