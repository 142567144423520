import type { MailboxInfo } from 'owa-client-types';
import { action, orchestrator } from 'satcheljs';
import {
    getCoprincipalAccounts,
    ContractsSupportedFilter,
    StartupFilter,
} from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import { M365AcquisitionsDocument } from '../graphql/query/__generated__/M365Acquisitions.interface';
import { query } from 'owa-apollo';
import { getFeatureFlagsIndexerForMailboxInfo } from 'owa-feature-flags';
import { type ResolverContext } from 'owa-graph-schema';
import type { M365Acquisition, M365AcquisitionsQuery } from '../types';
import type { ApolloQueryResult } from '@apollo/client';

// Global timer reference for MOS3 polling.
let timerRef: ReturnType<typeof setTimeout> | undefined;
const OWA_STEADY_STATE_INTERVAL_IN_SEC = 14400; // 4 hours
const onAcquisitionsRemoteRefresh = action(
    'onAcquisitionsRemoteRefresh',
    (
        nextInterval: number,
        m365AcquisitionsEdges: M365Acquisition[],
        isInitializationFetch: boolean,
        mailboxInfo?: MailboxInfo
    ) => ({
        nextInterval,
        m365AcquisitionsEdges,
        isInitializationFetch,
        mailboxInfo,
    })
);

orchestrator(onAcquisitionsRemoteRefresh, actionMessage => {
    const initialTimer = actionMessage.nextInterval;
    const mailboxInfo = actionMessage.mailboxInfo;
    if (timerRef) {
        clearTimeout(timerRef);
    }
    const refreshFunction = async () => {
        const queryResult = await dispatchNetworkOnlyQueryToApollo(mailboxInfo);
        const newIntervalInSec = queryResult.data.m365Acquisitions?.nextInterval;

        if (newIntervalInSec == 0) {
            /*
             * Refresh interval should never be 0. OWS` has logic to handle this case,
             * which indicates that MOS3 has paginated results. Prime will repeatedly call MOS3
             * and only return when the interval is >0 (meaning we've fetched all apps).
             */
            logUsage('M365AcquisitionsQueryWeb_RefreshIntervalZero');
        }
        const nextIntervalInSec = getOwaIntervalInSeconds(newIntervalInSec);
        timerRef = setTimeout(refreshFunction, nextIntervalInSec * 1000);
    };
    timerRef = setTimeout(refreshFunction, getOwaIntervalInSeconds(initialTimer) * 1000);
});

function dispatchNetworkOnlyQueryToApollo(
    mailboxInfo?: MailboxInfo
): Promise<ApolloQueryResult<M365AcquisitionsQuery>> {
    if (timerRef) {
        clearTimeout(timerRef);
    }
    return query(M365AcquisitionsDocument, {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
            mailboxInfo,
        },
        context: {
            coprincipalAccountSourceIds: getCoprincipalAccounts(
                StartupFilter.StartingOrCompleteOrError,
                ContractsSupportedFilter.Any
            ).map(account => account.sourceId),
            featureFlagIndexer: getFeatureFlagsIndexerForMailboxInfo(mailboxInfo),
        } as ResolverContext,
    });
}

function getOwaIntervalInSeconds(nextInterval: number) {
    return nextInterval >= 1800 || nextInterval <= 0
        ? OWA_STEADY_STATE_INTERVAL_IN_SEC
        : nextInterval;
}

export default onAcquisitionsRemoteRefresh;
