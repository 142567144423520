import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import type { TableView } from 'owa-mail-list-store';

export default action(
    'ADD_COPILOT_CATEGORY_TO_ROW',
    (
        mailboxInfo: MailboxInfo,
        sender: string,
        recipients: string[],
        subject: string,
        previewText: string,
        existingCategories: string[],
        rowKeys: string[],
        tableView: TableView
    ) => ({
        mailboxInfo,
        sender,
        recipients,
        subject,
        previewText,
        existingCategories,
        rowKeys,
        tableView,
    })
);
