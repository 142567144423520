import { startSearch } from 'owa-search-actions/lib/actions/startSearch';
import { setSearchText } from 'owa-search-actions/lib/actions/setSearchText';
import { exitSearch } from 'owa-search-actions/lib/actions/exitSearch';
import { LazyModule, LazyImport, LazyAction, registerLazyOrchestrator } from 'owa-bundling';
import onCloseSearch from './actions/onCloseSearch';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AppHostSearch" */ './lazyIndex'),
    { name: 'AppHostSearch' }
);

export { default as onAppHostHeaderStartSearch } from './actions/onAppHostHeaderStartSearch';

export const lazyOnAppAddSearchEventListener = new LazyAction(
    lazyModule,
    m => m.onAppAddSearchEventListener
);
export const lazyOnAppClearSearchListeners = new LazyAction(
    lazyModule,
    m => m.onAppClearSearchListeners
);
export const lazyOnCloseSearch = new LazyAction(lazyModule, m => m.onCloseSearch);

export const lazyShouldUseAppHostSearch = new LazyImport(lazyModule, m => m.shouldUseAppHostSearch);

// AppHost orchestrators
registerLazyOrchestrator(startSearch, lazyModule, m => m.startSearchOrchestrator);
registerLazyOrchestrator(setSearchText, lazyModule, m => m.setSearchTextOrchestrator);
registerLazyOrchestrator(exitSearch, lazyModule, m => m.exitSearchOrchestrator);
registerLazyOrchestrator(onCloseSearch, lazyModule, m => m.closeSearchOrchestrator);

import './mutators/isAppSearchEnabledMutators';
import './mutators/searchEventRegistrationsMutators';
