import { createLazyComponent, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Dictation" */ './lazyIndex'), {
    name: 'Dictation',
});

export const lazyToggleDictation = new LazyImport(lazyModule, m => m.toggleDictation);
export { getDictationStore } from './store/store';
export { DictationButtonStateEnum } from './store/schema/DictationButtonStateEnum';
export { DictationViewState } from './store/schema/DictationViewState';
export type { DictationUITelemetry } from './components/DictationUITelemetry';
export const DictationHelpPane = createLazyComponent(lazyModule, m => m.DictationHelpPane);
