import type { M365Acquisition } from 'owa-m365-acquisitions/lib/types';
import { NovaControl, type StrictAppSecondaryAction } from '../types';

import {
    taskbarPinningApps,
    getPinShortcutToTaskbarSecondaryAction,
} from '../data/getPinShortcutSecondaryAction';
import { lazyIsPinningAvailable } from 'native-controls';
import { getPinMessageExtensionSecondaryAction } from '../data/getPinMessageExtensionSecondaryAction';
import { isPollAddinAppId } from 'owa-m365-acquisitions/lib/utils/isPollAddinAppId';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { isAcquisitionAdminPinned } from 'owa-m365-acquisitions/lib/utils/isAcquisitionAdminPinned';
import { isFluidEnabledForSource, LoopType } from 'owa-fluid-validations';
import { getExtensionId } from 'owa-m365-acquisitions/lib/utils/getExtensionId';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';

export async function getAppSecondaryActions(
    acquisition: M365Acquisition,
    novaSurface: NovaControl,
    mailboxInfo?: MailboxInfo
): Promise<StrictAppSecondaryAction[]> {
    const secondaryActions: StrictAppSecondaryAction[] = [];

    const hasPinTaskbarShortcutEnabled = await shouldEnablePinTaskbarShortcut(acquisition);
    if (hasPinTaskbarShortcutEnabled) {
        secondaryActions.push(getPinShortcutToTaskbarSecondaryAction(acquisition, mailboxInfo));
    }

    const showPinContextMenuItem =
        isMOS3AppServiceAvailable(mailboxInfo) &&
        novaSurface === NovaControl.MessageExtension &&
        canPinAcquisition(acquisition, mailboxInfo);

    const appId = getExtensionId(acquisition);

    if (showPinContextMenuItem) {
        secondaryActions.push(getPinMessageExtensionSecondaryAction(appId ?? '', mailboxInfo));
    }

    return secondaryActions;
}

async function shouldEnablePinTaskbarShortcut(acquisition: M365Acquisition): Promise<boolean> {
    const initialChecks =
        isFeatureEnabled('nh-shortcuts-pintaskbarshortcut') &&
        acquisition.appId &&
        taskbarPinningApps.includes(acquisition.appId);

    if (initialChecks) {
        const isAvail = await lazyIsPinningAvailable.importAndExecute();
        return isAvail;
    }

    return false;
}

function canPinAcquisition(acquisition: M365Acquisition, mailboxInfo?: MailboxInfo): boolean {
    const appId = acquisition.appId ?? acquisition.manifestId;

    if (!appId) {
        return false;
    }

    const isNativePollsPresent =
        isFeatureEnabled('cmp-fluid-chapterThree') &&
        mailboxInfo &&
        isFluidEnabledForSource('MailCompose', mailboxInfo, LoopType.Poll);

    if (isNativePollsPresent && isPollAddinAppId(appId)) {
        return false;
    }

    if (isAcquisitionAdminPinned(acquisition)) {
        return false;
    }

    return true;
}
