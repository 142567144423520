import { addDatapointConfig } from 'owa-analytics-actions';
import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/lightRPStore';
import { saveIsLightRPOnToStorage } from '../utils/localStorage';

export default mutatorAction('toggleLightRPState', () => {
    const lightRPStore = getStore();
    addDatapointConfig(
        {
            name: lightRPStore.isLightRPOn ? 'ToggleLightRPOff' : 'ToggleLightRPOn',
        },
        { isLightRPOn: !lightRPStore.isLightRPOn }
    );

    lightRPStore.isLightRPOn = !lightRPStore.isLightRPOn;
    saveIsLightRPOnToStorage(lightRPStore.isLightRPOn);
});
