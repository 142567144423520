import { getStore } from '../store/store';
import type { FavoritePersonaData, FavoritePersonaNode } from 'owa-favorites-types';
import getFavoriteIdFromGroupId from '../actions/v2/helpers/getFavoriteIdFromGroupId';
import getFavoriteIdFromCategoryId from '../selectors/v2/getFavoriteIdFromCategoryId';
import getFavoriteIdFromFolderId from '../selectors/v2/getFavoriteIdFromFolderId';

/**
 * Checks if there is a favorite node in favorite store that has the same id
 * @param folderId the specified folder id
 * @return true if the favorite store has the folder with the same id, false otherwise
 */
export function isFolderInFavorites(folderId: string): boolean {
    return !!getFavoriteIdFromFolderId(folderId);
}

/**
 * Checks if there is a favorite node in favorite store that has the same id
 * @param groupId the specified group id
 * @return true if the favorite store has the group with the same id, false otherwise
 */
export function isGroupInFavorites(groupId: string): boolean {
    return !!getFavoriteIdFromGroupId(groupId);
}

/**
 * Checks if there is a favorite category in favorite store that has the same id
 * @param categoryId the guid of the category which is sent down from the server
 * @return true if the favorite store has the category  with the same categoryId, false otherwise
 */
export function isCategoryInFavorites(categoryId: string): boolean {
    return !!getFavoriteIdFromCategoryId(categoryId);
}

/**
 * Checks whether any node in the store has the given personaId as field, or the given email address
 *
 * LivePersonaCard scenario - we might have personaId but it's not mandatory
 * Search scenario - we have only email
 * Favorite person column scenario - we have personaId
 * People Hub scenario, we might have only personaId and no email
 */
export function isPersonaInFavorites(personaId?: string, personaEmailAddress?: string): boolean {
    return getPersonaFromPersonIdOrEmailAddress(personaId, personaEmailAddress) !== undefined;
}

/**
 * Checks if there is a favorite node in favorite store that has the same id
 * @param folderId the specified public folder id
 * @return true if the favorite store has the public folder with the same id, false otherwise
 */
export function isPublicFolderInFavorites(folderId: string): boolean {
    return getStore().favoriteSecondaryKeyMap.has(folderId);
}

export function getPersonaFromPersonIdOrEmailAddress(
    personaId?: string,
    personaEmailAddress?: string
): FavoritePersonaData | FavoritePersonaNode | undefined {
    const state = getStore();

    let filteredPersona = null;
    const allFavorites = [...state.outlookFavorites.values()];
    filteredPersona = allFavorites.filter(
        favorite =>
            favorite.type === 'persona' &&
            personaDetailsFoundInNodeInfo(
                favorite as FavoritePersonaData,
                personaId,
                personaEmailAddress
            )
    ) as FavoritePersonaData[];

    if (filteredPersona && filteredPersona.length > 0) {
        return filteredPersona[0];
    }

    return undefined;
}

function personaDetailsFoundInNodeInfo(
    node: FavoritePersonaNode | FavoritePersonaData,
    personaId?: string,
    personaEmailAddress?: string
): boolean {
    return (
        (node?.personaId && node.personaId === personaId) ||
        (node?.allEmailAddresses &&
            node.allEmailAddresses.some(
                emailAddress =>
                    emailAddress &&
                    emailAddress.toLocaleLowerCase() === personaEmailAddress?.toLocaleLowerCase()
            ))
    );
}
