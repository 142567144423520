import {
    type ConversationAttachmentsStore,
    type ConversationAttachmentsViewState,
} from './schema/conversationAttachmentsViewState';
import { createStore } from 'owa-satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

// separate store per mailbox
const stores = new Map<string, ConversationAttachmentsStore>();

export function getStore(mailboxInfo: MailboxInfo): ConversationAttachmentsStore {
    const name = `${getIndexerValueForMailboxInfo(mailboxInfo)}_conversationAttachmentsStore`;
    let cachedStore = stores.get(name);
    if (!cachedStore) {
        const store = createConversationAttachmentsStore(name);
        stores.set(name, store);
        cachedStore = store;
    }

    return cachedStore;
}

function createConversationAttachmentsStore(name: string) {
    const initialStore: ConversationAttachmentsStore = {
        conversationAttachments: new Map<string, ConversationAttachmentsViewState>(),
    };
    return createStore<ConversationAttachmentsStore>(name, initialStore)();
}
