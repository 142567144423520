// extracted by mini-css-extract-plugin
export var newTag = "fbPqq";
export var sortButton = "EzCrC";
export var sortButtonCompact = "VDEla";
export var sortButtonCompactMCL = "xwZ_U";
export var sortButtonFull = "w91i7";
export var sortButtonFullMCL = "DJi5A";
export var sortButtonMedium = "yKod4";
export var sortButtonMediumMCL = "EtcMU";
export var sortButtonOpened = "hR5UE";
export var sortButtonsContainer = "tbgM1";
export var sortDirectionButton = "uflrh";
export var sortDirectionButtonCompact = "uyLah";
export var sortDirectionButtonMedium = "j_2ZW";
export var sortLabel = "i8yyV";
export var sortMenuItemLabelFull = "w4FE0";
export var sortMenuItemLabelMediumOrCompact = "C8Znb";
export var viewSortMenuOption = "ZM3nO";