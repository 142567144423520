import { mergeRowResponseFromTop } from 'owa-mail-list-response-processor';
import type { MailListRowDataType, TableView } from 'owa-mail-list-store';
import { type TableQueryType, canTableBeOutOfSyncWithServer } from 'owa-mail-list-store';
import type FolderId from 'owa-service/lib/contract/FolderId';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Using transaction to deprecate legacy satchel actions */
import { transaction } from 'mobx';
import { mutatorAction } from 'satcheljs';
import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyFetchReactionDataForMessageList } from 'owa-listview-reactions';
import type { PagingInfoInput as GqlPagingInfo } from 'owa-graph-schema';
import { setCurrentLoadedIndexMutator } from './setCurrentLoadedIndexMutator';

const serverFolderIdMutator = mutatorAction(
    'serverFolderIdMutator',
    (tableView: TableView, serverFolderId: string) => {
        tableView.serverFolderId = serverFolderId;
    }
);

const loadedStartIndexMutator = mutatorAction(
    'loadedStartIndexMutator',
    (tableView: TableView, index: number) => {
        tableView.loadedStartIndex = index;
    }
);

/**
 * Callback when load initial rows request succeeds
 * @param tableView to load
 * @param newRows to be added
 * @param totalRowsInView the number of total rows in the view/folder
 * @param indexOfLastRowInView the index of the last row in view
 * @param searchFolderId the searchFolderId contained in the response message
 */
export default function onLoadInitialRowsSucceeded(
    tableView: TableView,
    responseRows: MailListRowDataType[],
    totalRowsInView: number,
    indexOfLastRowInView: number,
    searchFolderId?: FolderId,
    folderId?: FolderId,
    pagingInfo?: GqlPagingInfo
) {
    transaction(() => {
        // In case of groups, we don't initially have the actual folder id when the tableView is created (similar to search folders in inbox),
        // So we get it in the response for findConversation call and update the serverFolderId.
        if (
            (tableView.tableQuery.type == 2 ||
                (isFeatureEnabled('grp-loadFolders') && tableView.tableQuery.type == 3)) &&
            folderId
        ) {
            serverFolderIdMutator(tableView, folderId.Id);
        }

        // If SearchFolderId is returned in response, set it on the tableView
        if (searchFolderId) {
            serverFolderIdMutator(tableView, searchFolderId.Id);
        }

        // Invalidate the remaining rows if the client table is out of sync with the server
        const shouldRemoveRemainingRowsAfterMerge = canTableBeOutOfSyncWithServer(tableView);

        // Update rows in listview store
        // also log isDataUptodate only if the table is not being loaded for first time
        mergeRowResponseFromTop(
            responseRows,
            tableView,
            totalRowsInView,
            shouldRemoveRemainingRowsAfterMerge /* removeRemainingRowsAfterMerge */,
            tableView.isInitialLoadComplete /* shouldLogIsDataUptodate */,
            'MergeOnInitialRowsFetch'
        );

        setCurrentLoadedIndexMutator(tableView);

        // Set the loaded start index to be the last element index - the number of rows
        // The number of rows is the same as tableView.rowKeys.length since it is the initial load
        if (isFeatureEnabled('tri-jumpToInMl')) {
            if (pagingInfo?.pageOrigin === 'End') {
                loadedStartIndexMutator(
                    tableView,
                    Math.max(0, totalRowsInView - tableView.rowKeys.length)
                );
            } else {
                loadedStartIndexMutator(
                    tableView,
                    Math.max(0, indexOfLastRowInView - tableView.rowKeys.length)
                );
            }
        }

        if (isFeatureEnabled('tri-message-list-reactions')) {
            // Iterate over new rows and determine if any of them have a non-zero
            // ReactionsCount property. If so, add them to the targetRowKeys array
            // for the fetchReactionDataForMessageList orchestrator to process.
            const targetRowKeys: string[] = [];

            responseRows.forEach((row: MailListRowDataType) => {
                if ((row.ReactionsCount ?? 0) > 0 && row.InstanceKey) {
                    targetRowKeys.push(row.InstanceKey);
                }
            });

            if (targetRowKeys.length > 0) {
                lazyFetchReactionDataForMessageList.importAndExecute(tableView, targetRowKeys);
            }
        }
    });
}
