import { NovaLogger } from 'owa-analytics-nova-logger';

export const RingAppName = 'PeopleHighlightsRing';

export default function initializeRingNovaLogger() {
    // Initialize nova logger for Reading Pane Persona Ring.
    NovaLogger.associateIngestionKeyWithAppName(getRingIngestionKey(), RingAppName);
}

function getRingIngestionKey() {
    // People highlights aria tenant
    return '136931d8fe8a4f5ab06063c9a693c8e6-1bf92fb9-223c-4b4b-8807-4114496f98af-7433';
}
