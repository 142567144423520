import React, { useLayoutEffect } from 'react';

interface CallbackOnMountProps {
    callback?: () => void;
}

export const CallbackOnMount: React.FC<React.PropsWithChildren<CallbackOnMountProps>> = ({
    children,
    callback,
}) => {
    useLayoutEffect(() => {
        callback?.();
    }, [callback]);
    return <>{children}</>;
};
