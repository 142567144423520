import type { MailboxInfo } from 'owa-client-types';
import { validateMailboxInfoIsForStartedAccount } from './validateMailboxInfoIsForStartedAccount';
import { isValidationResultValidForStartedAccount } from './isValidationResultValidForStartedAccount';
import { logGreyErrorFromAccounts } from 'owa-account-analytics';

/**
 * Provides the context for the caller of the check, This is exporte as a typed union of strings
 * so that we can ensure that the context is safe to include in the logging below.
 */
export type StartedCheckContext = 'AppBarRefresh' | 'SetModuleContext';

/**
 * Checks that the MailboxInfo is for a started account
 * @param mailboxInfo MailboxInfo to be checked
 */
export function checkThatMailboxInfoIsForStartedAccount(
    context: StartedCheckContext,
    mailboxInfo: MailboxInfo
) {
    const validationResult = validateMailboxInfoIsForStartedAccount(mailboxInfo);
    if (!isValidationResultValidForStartedAccount(validationResult)) {
        const error = new Error('MailboxInfo is not for a started account');
        logGreyErrorFromAccounts('MailboxInfoNotForStartedAccount', error, {
            ...validationResult,
            context,
        });
    }
}
