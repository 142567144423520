import { getDefaultLogonEmailAddress, getDefaultUserEmailAddress } from 'owa-session-store';
import getSelectedAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getSelectedAccountMailboxInfo';
import logDefaultOrReflowCall from 'owa-account-source-list-store/lib/utils/logDefaultOrReflowCall';
import isAccountSourceListStoreInitialized from 'owa-account-source-list-store/lib/utils/isAccountSourceListStoreInitialized';
import { logStartCoreGreyError } from 'owa-analytics-start';

import type { MailboxInfo, MailboxType } from 'owa-client-types';
import getStore from '../store';

let altGetUserMailboxInfo: ((userIdentity?: string | null) => MailboxInfo | undefined) | undefined =
    undefined;

/**
 * REFLOW TECHNICAL DEBT
 *
 * When we ge to the Respond approach for multi-account the getUserMailboxInfo will be removed and all
 * mailboxes will be gotten via a sourceId. In order to support the Reflow approach we need to have the
 * getUserMailboxInfo to return the mailbox based on the currently selected account.
 *
 * We cannot directly import owa-account-source-list because it needs to import the owa-client-ids to
 * get the MailboxInfo type, and importing owa-account-source-list here would create a circular dependency.
 * One way to fix this would be to move the MailboxInfo into another package, however since the
 * getUserMailboxInfo will be removed when the Respond approach is implemented the decision is to
 * break the circular dependency by setting the method that will get the MailboxInfo using the selected account.
 *
 * @param userIdentity identifies the mailbox, when not specified the selected mailbox is assumed
 * @returns The mailbox that should be used for the identity
 */
export function setAltGetUserMailboxInfo(
    callback: (userIdentity?: string | null) => MailboxInfo | undefined
) {
    altGetUserMailboxInfo = callback;
}

/**
Gets the mailboxInfo object for given userIdentity.
This also adds to the map of mailboxInfos keyed on userIdentity passed in.
The Map only holds userMailbox type info's (and not group mailbox info's)
and hence the userIdentity and mailboxSmtpAddress are both assigned the same userIdentity value in case of connected account
@param userIdentity userIdentity of the userMailbox required. If not passed, we assume default user
@param bypassAlt when true the altGetUserMailboxInfo will be bypassed if set
 */
function getUserMailboxInfoImpl(userIdentity?: string | null, bypassAlt?: boolean): MailboxInfo {
    logDefaultOrReflowCall('getUserMailboxInfo');

    if (altGetUserMailboxInfo && !bypassAlt) {
        // If the altGetUserMailboxInfo callback is set use it to try and get the mailbox
        const altMailboxInfo = altGetUserMailboxInfo(userIdentity);
        if (altMailboxInfo) {
            return altMailboxInfo;
        }
    }

    const userMailboxInfoMap = getStore().userMailboxInfoMap;
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    if (!userMailboxInfoMap || !userMailboxInfoMap.get(userIdentity!)) {
        let isConnectedAccount: boolean = false;
        if (userIdentity) {
            /* eslint-disable-next-line owa-custom-rules/no-default-methods  -- (https://aka.ms/OWALintWiki)
             * Methods that use the default mailbox should not be used, see https://aka.ms/multiaccountlinter
             *	> Do Not Use Default: The getDefaultLogonEmailAddress function should not be used for Respond multi-account support. */
            isConnectedAccount = userIdentity == getDefaultLogonEmailAddress() ? false : true;
        }
        const altGetUserMailboxInfoHint = !!altGetUserMailboxInfo ? 'Alt' : 'NoAlt';
        const connectedAccountHint = isConnectedAccount ? 'Connected' : 'Default';

        /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
         * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
         *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
        const userMailboxInfo = {
            type: 'UserMailbox' as MailboxType,
            /* eslint-disable-next-line owa-custom-rules/no-default-methods  -- (https://aka.ms/OWALintWiki)
             * Methods that use the default mailbox should not be used, see https://aka.ms/multiaccountlinter
             *	> Do Not Use Default: The getDefaultLogonEmailAddress function should not be used for Respond multi-account support. */
            userIdentity: isConnectedAccount ? userIdentity : getDefaultLogonEmailAddress(),
            /* eslint-disable-next-line owa-custom-rules/no-default-methods  -- (https://aka.ms/OWALintWiki)
             * Methods that use the default mailbox should not be used, see https://aka.ms/multiaccountlinter
             *	> Do Not Use Default: The getDefaultUserEmailAddress function should not be used for Respond multi-account support. */
            mailboxSmtpAddress: isConnectedAccount ? userIdentity : getDefaultUserEmailAddress(),
            diagnosticData: `GetUserMailboxInfo-${altGetUserMailboxInfoHint}-${connectedAccountHint}`,
        };

        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (78,32): Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
        // @ts-expect-error
        userMailboxInfoMap.set(userIdentity, userMailboxInfo);

        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (83,9): Type '{ type: MailboxType; userIdentity: string | null | undefined; mailboxSmtpAddress: string | null | undefined; }' is not assignable to type 'MailboxInfo'.
        // @ts-expect-error
        return userMailboxInfo;
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (89,5): Type 'MailboxInfo | undefined' is not assignable to type 'MailboxInfo'.
    // @ts-expect-error
    return userMailboxInfoMap.get(userIdentity);
}

// limit the number of times we log the mismatch
const maxMismatchLoggedCount = 5;
let mismatchLoggedCount = 0;

export default function getUserMailboxInfo(
    userIdentity?: string | null,
    bypassAlt?: boolean
): MailboxInfo {
    const mailboxInfo = getUserMailboxInfoImpl(userIdentity, bypassAlt);
    if (
        !userIdentity && // We only care about the default mailbox
        mismatchLoggedCount < maxMismatchLoggedCount && // We only want to log a few times
        isAccountSourceListStoreInitialized() // the account source list store needs to be initialized so we can get the selected mailbox
    ) {
        // We are getting ready to replace calls to the selected mailbox with calls getUserMailboxInfo
        // so we need to make sure that the selected mailbox is the default mailbox.

        /* eslint-disable-next-line owa-custom-rules/no-reflow-methods  -- (https://aka.ms/OWALintWiki)
         * We will be removing the reflow code, this will require updating callers of getSelectedAccountMailboxInfo() to call
         * getUserMailboxInfo() instead. We expect that these two calls will always return the same value. This change adds telemetry
         * to help us verify that assumption in production. In order to perform this validation we need to make a call to
         * getSelectedAccountMailboxInfo which triggers the no-reflow-methods rule which we need to suppress here. */
        const selectedMailboxInfo = getSelectedAccountMailboxInfo();
        const isSmtpSame = mailboxInfo.mailboxSmtpAddress == selectedMailboxInfo.mailboxSmtpAddress;
        const isIdentitySame = mailboxInfo.userIdentity == selectedMailboxInfo.userIdentity;
        if (!(isSmtpSame && isIdentitySame)) {
            mismatchLoggedCount++;
            const err = new Error('getUserMailboxInfoAndSelectedMismatch');
            logStartCoreGreyError('getUserMailboxInfoAndSelectedMismatch', err, {
                isSmtpSame,
                isIdentitySame,
            });
        }
    }

    return mailboxInfo;
}
