import type { NovaEvent } from '@nova/types';
import type { CalendarSidePanelEventTypes } from './CalendarSidePanelEvents';
import type { SharedCalendarPayload } from './SharedCalendarPayload';

export enum LocalCommands {
    SwitchModule,
}

interface LocalCommandPayload<T extends LocalCommands> extends SharedCalendarPayload {
    localCommandType: T;
}

export type SwitchCommandPayload = LocalCommandPayload<LocalCommands.SwitchModule> & {
    moduleName: string;
};

export type InvokeLocalCommandPayload = SwitchCommandPayload;

export interface InvokeLocalCommandNovaEvent extends NovaEvent<InvokeLocalCommandPayload> {
    type: typeof CalendarSidePanelEventTypes.invokeLocalCommand;
    data?: () => InvokeLocalCommandPayload;
}
