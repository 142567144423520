import type OwaNonBootUserConfiguration from 'owa-service/lib/contract/OwaNonBootUserConfiguration';
import getOwaNonBootUserConfigurationOperation from 'owa-service/lib/operation/getOwaNonBootUserConfigurationOperation';
import { isAnonymousUser } from 'owa-config/lib/anonymousUtils';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { getNonBootUserConfigurationOverride } from './GetNonBootUserConfigurationOverride';

const requestPromiseMap: Map<string, Promise<OwaNonBootUserConfiguration>> = new Map();
const nonBootUserConfigurationMap: Map<string, OwaNonBootUserConfiguration> = new Map();

function getNonBootUserConfiguration(
    mailboxInfo: MailboxInfo,
    skipAnonymousCheck?: boolean
): Promise<OwaNonBootUserConfiguration> {
    const mailboxInfoKey = getIndexerValueForMailboxInfo(mailboxInfo);
    if (
        (!skipAnonymousCheck && isAnonymousUser()) ||
        !isServiceRequestSupportedForMailbox(mailboxInfo)
    ) {
        return Promise.resolve({});
    } else if (nonBootUserConfigurationMap.get(mailboxInfoKey)) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return Promise.resolve(nonBootUserConfigurationMap.get(mailboxInfoKey)!);
    } else if (!requestPromiseMap.get(mailboxInfoKey)) {
        const requestFunction =
            getNonBootUserConfigurationOverride ?? getOwaNonBootUserConfigurationDefault;

        const requestPromise = requestFunction(mailboxInfo)
            .then(userConfig => {
                nonBootUserConfigurationMap.set(mailboxInfoKey, userConfig);
                return userConfig;
            })
            .catch(err => {
                requestPromiseMap.delete(mailboxInfoKey);
                throw err;
            });

        requestPromiseMap.set(mailboxInfoKey, requestPromise);
    }

    return requestPromiseMap.get(mailboxInfoKey) ?? Promise.resolve({});
}

export default getNonBootUserConfiguration;

export function getNonBootUserConfigurationSync(
    mailboxInfo: MailboxInfo,
    skipAnonymousCheck?: boolean
): OwaNonBootUserConfiguration | undefined {
    const mailboxInfoKey = getIndexerValueForMailboxInfo(mailboxInfo);
    if (!nonBootUserConfigurationMap.get(mailboxInfoKey)) {
        // Kick off the request so the store will get updated
        getNonBootUserConfiguration(mailboxInfo, skipAnonymousCheck);
    }

    return nonBootUserConfigurationMap.get(mailboxInfoKey);
}

function getOwaNonBootUserConfigurationDefault(mailboxInfo: MailboxInfo) {
    return getOwaNonBootUserConfigurationOperation(getMailboxRequestOptions(mailboxInfo));
}
