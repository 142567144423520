/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'lodash-es/merge' import is restricted from being used by a pattern. Lodash can cause bundle bloat and performance problems with loaf. Use native function instead */
import merge from 'lodash-es/merge';
import type { MailboxInfo } from 'owa-client-types';
import type { UserConfiguration, UserConfigurationArgs } from 'owa-graph-schema';
import { DEFAULT_USER_CONFIG } from 'owa-graph-schema';
import type { ReadOnlyOwaUserConfiguration } from 'owa-service/lib/ReadOnlyTypes';
import { errorThatWillCauseAlert } from 'owa-trace';

import { convertUserConfigToGraphQLSchema } from './utils/convertUserConfigToGraphQLSchema';

const cache: Record<string, UserConfiguration> = {};

export function setConfiguration(
    configuration: ReadOnlyOwaUserConfiguration | undefined,
    mailboxInfo: MailboxInfo,
    shouldMerge?: boolean
) {
    try {
        if (configuration) {
            const userIdentity = mailboxInfo.userIdentity || DEFAULT_USER_CONFIG;
            const configCopy = JSON.parse(JSON.stringify(configuration));
            const config = convertUserConfigToGraphQLSchema(configCopy, mailboxInfo);
            if (shouldMerge) {
                updateConfiguration(userIdentity, config);
            } else {
                cache[userIdentity] = config;
            }
        } else {
            errorThatWillCauseAlert('User configuration is empty');
        }
    } catch (e) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert('User configuration cache set failed: ' + e, e);
    }
}

export function getConfiguration(mailboxInfo: MailboxInfo): UserConfiguration {
    const userIdentity = mailboxInfo.userIdentity || DEFAULT_USER_CONFIG;

    if (!cache[userIdentity]) {
        errorThatWillCauseAlert('User configuration cache is not set yet');

        return { id: userIdentity };
    }

    return cache[userIdentity];
}

export function updateConfiguration(
    userIdentity: string = DEFAULT_USER_CONFIG,
    updates?: UserConfigurationArgs
) {
    if (updates) {
        const existing = cache[userIdentity] || {};
        cache[userIdentity] = merge(
            existing,
            updates as UserConfigurationArgs & {
                id: string;
            }
        );
    }
}
