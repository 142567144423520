import { LazyModule, createLazyComponent, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ResizeHandle" */ './lazyIndex'),
    { name: 'ResizeHandle' }
);

export const ResizeHandle = createLazyComponent(lazyModule, m => m.ResizeHandle);
export { ResizeHandleDirection } from './components/ResizeHandleDirection';
export { default as ResizeHandleScenario } from './types/ResizeHandleScenario';
export const lazyUpdateScenarioInResizeMode = new LazyAction(
    lazyModule,
    m => m.updateScenarioInResizeMode
);
