import type { FavoriteData } from 'owa-favorites-types';
import getSecondaryKey from '../../utils/getSecondaryKey';
import favoritesStore from '../../store/store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import { removeFavoriteFromCache } from './offlineCacheHelpers';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';

export default function removeFavoriteDataFromStore(favoriteData: FavoriteData) {
    const { favoriteSecondaryKeyMap, outlookFavorites } = favoritesStore;

    const accountKey = getAccountKeyForMailboxInfo(
        favoriteData?.mailboxInfo ?? getModuleContextMailboxInfo()
    );

    let orderedOutlookFavoritesIds = isMonarchMultipleAccountsEnabled()
        ? favoritesStore.orderedCombinedOutlookFavoritesIds
        : favoritesStore.favoriteTreeData.get(accountKey)?.orderedOutlookFavoritesIds;

    if (!orderedOutlookFavoritesIds) {
        return;
    }

    // Remove from favorite array
    orderedOutlookFavoritesIds = orderedOutlookFavoritesIds.filter(
        id => id !== favoriteData.favoriteId
    );

    // Remove from the store
    if (isMonarchMultipleAccountsEnabled()) {
        favoritesStore.orderedCombinedOutlookFavoritesIds = orderedOutlookFavoritesIds;

        // remove from cache
        removeFavoriteFromCache(favoriteData);
    } else {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        favoritesStore.favoriteTreeData.get(accountKey)!.orderedOutlookFavoritesIds =
            orderedOutlookFavoritesIds;
    }

    outlookFavorites.delete(favoriteData.favoriteId);

    const secondaryKey = getSecondaryKey(favoriteData);
    if (secondaryKey) {
        favoriteSecondaryKeyMap.delete(secondaryKey);
    }
}
