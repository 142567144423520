import type Message from 'owa-service/lib/contract/Message';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import React from 'react';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import { ReactionsOnMailListItemContainer } from 'owa-mail-list-reactions-view';
import mailStore from 'owa-mail-store/lib/store/Store';

export default function renderReactions(params: {
    latestItemId: string;
    reactionsContainerClassName: string;
    rowId: string;
    tableViewId: string;
}): JSX.Element | null {
    const { latestItemId, reactionsContainerClassName, rowId, tableViewId } = params;

    const tableView = getListViewStore().tableViews.get(tableViewId);
    const isItemView = tableView?.tableQuery.listViewType === 1;
    const item = isItemView ? mailStore.items.get(rowId) : mailStore.items.get(latestItemId);

    const reactionsForRowId =
        getListViewStore().reactionInformationByRowId.get(rowId)?.reactions ?? [];

    const reactionsForRow =
        (reactionsForRowId?.length ?? 0) > 0
            ? reactionsForRowId
            : (item as Message)?.Reactions ?? [];

    if (reactionsForRow.length === 0) {
        return null;
    }

    return (
        <div className={reactionsContainerClassName}>
            <ReactionsOnMailListItemContainer
                message={item as Message}
                reactionsForRowId={reactionsForRow}
            />
        </div>
    );
}
