import { orchestrator } from 'satcheljs';
import { logUsage } from 'owa-analytics';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { getBackgroundImageUrl, getCurrentThemeId, getFallbackBackgroundColor } from 'owa-theme';
import { onLoadTheme } from 'owa-theme-common';
import setBackgroundColor from '../mutators/setBackgroundColor';
import setBackgroundImage from '../mutators/setBackgroundImage';
import preloadBackgroundImage from '../utils/preloadBackgroundImage';
import {
    addImageUrlToLocalStorage,
    removeImageUrlFromLocalStorage,
} from '../utils/localStorageHelper';

orchestrator(onLoadTheme, async () => {
    try {
        const isDarkTheme = getIsDarkTheme();
        const currentThemeId = getCurrentThemeId();
        const backgroundColor = getFallbackBackgroundColor(currentThemeId, isDarkTheme);
        const imageUrl = getBackgroundImageUrl(currentThemeId, isDarkTheme);

        setBackgroundImage(undefined);
        setBackgroundColor(backgroundColor);
        updateImageUrlInLocalStorage(imageUrl);

        if (!imageUrl) {
            return;
        }

        const preloadUrl = await preloadBackgroundImage(imageUrl);
        setBackgroundImage(preloadUrl);
    } catch (error) {
        logUsage('ErrorLoadingBackgroundImage', {
            error: error.message,
            stack: error.stack,
            themeId: getCurrentThemeId(),
        });
    }
});

function updateImageUrlInLocalStorage(imageUrl?: string) {
    // If the bleedThrough capability is enabled, store the image URL in local storage
    // to initiate the request earlier during boot enhancing the speed of future app launches.
    // If not enabled, remove the image URL from local storage
    if (imageUrl && isCapabilityEnabled(bleedThroughCapability)) {
        addImageUrlToLocalStorage(imageUrl);
    } else {
        removeImageUrlFromLocalStorage();
    }
}
