import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ManualSyncMail" */ './lazyIndex'),
    { name: 'ManualSyncMail' }
);

export const lazyManualSyncMail = new LazyAction(lazyModule, m => m.manualSyncMail);
export { getIsSyncingMailbox } from './selectors/getIsSyncingMailbox';
export const lazyManualSyncFolders = new LazyAction(lazyModule, m => m.manualSyncFolders);
