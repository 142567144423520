import { LaunchPageAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { getPromiseForLocalizedString } from 'owa-localize/lib/loc';
import {
    addApps,
    moreAppsAccessibleLabel,
} from 'owa-locstrings/lib/strings/moreapps.locstring.json';
import { isUnifiedAppStoreEnabled } from 'owa-m365-acquisitions/lib/utils/isUnifiedAppStoreEnabled';
import { getAddInAppAcquisitionLink } from './getAddinAppAcquisitionLink';
import { lazyIsAddInAppStoreEnabled } from 'owa-m365-acquisitions/lib/lazyFunction';
import type { StrictAppAcquisitionLink } from '../types';
import type { MailboxInfo } from 'owa-client-types';

export async function getAppAcquisitionLinkData(
    isShownInAppBar: boolean
): Promise<StrictAppAcquisitionLink> {
    return isShownInAppBar
        ? {
              id: LaunchPageAppId.AppStore,
              __typename: 'AppAcquisitionLink',
              accessibleName: await getPromiseForLocalizedString(moreAppsAccessibleLabel),
              text: await getPromiseForLocalizedString(addApps),
              icon: {
                  __typename: 'InternalIcon',
                  name: 'AddSquareIcon',
              },
          }
        : {
              id: LaunchPageAppId.AppStoreModal,
              __typename: 'AppAcquisitionLink',
              accessibleName: await getPromiseForLocalizedString(moreAppsAccessibleLabel),
              text: await getPromiseForLocalizedString(addApps),
              icon: {
                  __typename: 'InternalIcon',
                  name: 'AddSquareIcon',
              },
          };
}

export async function getAppAcquisitionLink(
    isShownInAppBar: boolean = true,
    mailboxInfo?: MailboxInfo
): Promise<StrictAppAcquisitionLink | undefined> {
    if (isUnifiedAppStoreEnabled(mailboxInfo)) {
        return getAppAcquisitionLinkData(isShownInAppBar);
    }

    // Lazy import util. It's ok if it doesn't import in time. Query will be re written post-boot when
    // apps are fetched.
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    const isAddInAppStoreEnabled = lazyIsAddInAppStoreEnabled.tryImportForRender();
    if (!isShownInAppBar && isAddInAppStoreEnabled?.(mailboxInfo)) {
        return getAddInAppAcquisitionLink();
    }
    return;
}
