import { LazyAction, LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "AcuiLazy"*/ './lazyIndex'), {
    name: 'AcuiLazy',
});

export const lazyGetDefaultRibbonControlInMenuRendererFunction = new LazyAction(
    lazyModule,
    m => m.getDefaultRibbonControlInMenuRendererFunction
);

export const ContextualUI = createLazyComponent(lazyModule, m => m.ContextualUI);
