import setExplicitLogonHeaders from './setExplicitLogonHeaders';
import type MailboxRequestOptions from 'owa-service/lib/MailboxRequestOptions';
import type RequestOptions from 'owa-service/lib/RequestOptions';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function createExplicitLogonRequest(
    smtp: string,
    mailboxRequestOptions?: MailboxRequestOptions
): RequestOptions {
    const req: RequestOptions = mailboxRequestOptions ?? {};
    const headers = req.headers?.set ? new Headers(<Headers>req.headers) : new Headers();
    req.datapoint = req.datapoint || {};
    req.datapoint.mailbox = req.datapoint.mailbox || 'Explicit';

    const hasExplicitLogonHeader = headers?.has('X-OWA-ExplicitLogonUser');
    const hasAnchorMailbox = headers?.has('x-anchormailbox');

    if (
        hasExplicitLogonHeader &&
        hasAnchorMailbox &&
        isFeatureEnabled('fwk-disableOverridingExplicitLogonHeaders')
    ) {
        return req;
    }

    setExplicitLogonHeaders(smtp, <Headers>headers);
    return {
        ...req,
        headers,
    };
}
