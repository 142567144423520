import getListViewState from '../selectors/getListViewState';
import { type TableQueryType } from '../store/schema/TableQuery';
import type TableView from '../store/schema/TableView';
import getTableConversationRelation from '../utils/getTableConversationRelation';
import getTableItemRelation from '../utils/getTableItemRelation';
import isItemOfMessageType from '../utils/isItemOfMessageType';
import {
    getIsTableConversationRelationPinned,
    getIsTableItemDataPinned,
} from '../utils/pinningUtils';
import { returnTopExecutingActionDatapoint, logUsage } from 'owa-analytics';
import type { ClientItemId } from 'owa-client-ids';
import selectConversationById from './selectConversationById';
import type { ClientItem } from 'owa-mail-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import type { InstrumentationContext } from 'owa-search-types/lib/types/InstrumentationContext';
import type BaseItem from 'owa-mail-store/lib/store/schema/BaseItem';
import { isFirstLevelExpanded } from './isConversationExpanded';
import getItemIdForMailList from 'owa-mail-store/lib/selectors/getItemIdForMailList';
import { getItemToShowFromNodeId } from 'owa-mail-store/lib/utils/conversationsUtils';
import { isReadingPaneStackedView } from 'owa-mail-organization-settings/lib/utils/readingPaneStackedViewUtils';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import type ExecuteSearchSortOrder from 'owa-service/lib/contract/ExecuteSearchSortOrder';
import type FlagStatus from 'owa-service/lib/contract/FlagStatus';
import type FlagType from 'owa-service/lib/contract/FlagType';
import type Message from 'owa-service/lib/contract/Message';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type RetentionTagType from 'owa-service/lib/contract/RetentionTagType';
import type InferenceClassification from 'owa-service/lib/contract/InferenceClassification';
import { trace, errorThatWillCauseAlert } from 'owa-trace';
import { owaComputedFn } from 'owa-computed-fn';
import type IconIndexType from 'owa-service/lib/contract/IconIndexType';
import type MailFolderTableQuery from '../store/schema/MailFolderTableQuery';
import type Item from 'owa-service/lib/contract/Item';

import {
    getIsTableConversationRelationSnoozed,
    getIsTableItemDataSnoozed,
} from '../utils/snoozeUtils';
export type MailRowDataPropertyType = any;

/**
 * Get canDelete for a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return boolean for canDelete
 */
export function getCanDelete(rowKey: string, tableView: TableView): boolean {
    return getRowDataProperty(rowKey, tableView, 1) as boolean;
}

/**
 * Get categories for a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return a collection of categories
 */
export function getCategories(rowKey: string, tableView: TableView): string[] {
    return getRowDataProperty(rowKey, tableView, 2) as string[];
}

/**
 * Get conversation id for a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return a collection of categories
 */
export function getConversationId(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 3) as string;
}

/**
 * Get if a row is pinned
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return true if a row is pinned, false otherwise
 */
export function getIsPinned(rowKey: string, tableView: TableView): boolean {
    return getRowDataProperty(rowKey, tableView, 16) as boolean;
}

/**
 * Get the Item
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the item the rowKey is on
 */
export function getItem(rowKey: string, tableView: TableView): ClientItem {
    return getRowDataProperty(rowKey, tableView, 19) as ClientItem;
}

/**
 * Get the itemIds
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return itemIds if row is Conversation, or itemId if row is Item
 */
export function getItemIds(rowKey: string, tableView: TableView): string[] {
    return getRowDataProperty(rowKey, tableView, 20) as string[];
}

/**
 * Get the LatestGlobalItemId
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return latest global item id
 */
export function getLatestGlobalItemId(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 10) as string;
}

/**
 * Get all the global item ids
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return all global item ids
 */
export function getGlobalItemIds(rowKey: string, tableView: TableView): string[] {
    return getRowDataProperty(rowKey, tableView, 9) as string[];
}

/**
 * Gets the draft item ids
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return items ids of all drafts
 */
export function getDraftItemIds(rowKey: string, tableView: TableView): string[] {
    return getRowDataProperty(rowKey, tableView, 4) as string[];
}

/**
 * Get the executeSearchSortOrder of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the executeSearchSortOrder
 */
export function getExecuteSearchSortOrder(
    rowKey: string,
    tableView: TableView
): ExecuteSearchSortOrder {
    return getRowDataProperty(rowKey, tableView, 6) as ExecuteSearchSortOrder;
}

/**
 * Get the getUniqueSenders of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the getUniqueSenders
 */
export function getUniqueSenders(rowKey: string, tableView: TableView): string[] {
    return getRowDataProperty(rowKey, tableView, 33) as string[];
}

/**
 * Get the UniqueRecipients of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the UniqueRecipients
 */
export function getUniqueRecipients(rowKey: string, tableView: TableView): string[] {
    return getRowDataProperty(rowKey, tableView, 36) as string[];
}

/**
 * Get the FlagType of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the FlagType object
 */
export function getFlagType(rowKey: string, tableView: TableView): FlagType {
    return getRowDataProperty(rowKey, tableView, 7) as FlagType;
}

/**
 * Get the flag status of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the flag status
 */
export function getFlagStatus(rowKey: string, tableView: TableView): FlagStatus {
    return getRowDataProperty(rowKey, tableView, 8) as FlagStatus;
}

/**
 * Get the effective mentioned of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return whether there is a mention
 */
export function getEffectiveMentioned(rowKey: string, tableView: TableView): boolean {
    return getRowDataProperty(rowKey, tableView, 5) as boolean;
}

/**
 * Get the lastDeliveryOrRenewTimeStamp of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the lastDeliveryOrRenewTimeStamp
 */
export function getLastDeliveryOrRenewTimeStamp(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 21) as string;
}

/**
 * Get the lastModifiedTimeStamp of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the lastDeliveryOrRenewTimeStamp
 */
export function getLastModifiedTimeStamp(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 23) as string;
}

/**
 * Get the lastDeliveryTimeStamp of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the lastDeliveryTimeStamp
 */
export function getLastDeliveryTimeStamp(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 22) as string;
}

/**
 * Get the subject of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the subject
 */
export function getSubject(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 32) as string;
}

/**
 * Get the last sender mailbox of a row
 * @param rowKey key of the row data
 * @param tableView the table that contains the row data
 * @return the last sender mailbox of a row
 */
export function getLastSenderMailbox(rowKey: string, tableView: TableView): EmailAddressWrapper {
    return getRowDataProperty(rowKey, tableView, 24) as EmailAddressWrapper;
}

/**
 * Get the last sender smtp address of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the last sender smtp address of a row
 */
export function getLastSenderSMTP(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 25) as string;
}

/**
 * Get the unread count of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the unread count
 */
export function getUnreadCount(rowKey: string, tableView: TableView): number {
    return getRowDataProperty(rowKey, tableView, 34) as number;
}

/**
 * Get the size of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the unread count
 */
export function getSize(rowKey: string, tableView: TableView): number {
    return getRowDataProperty(rowKey, tableView, 31) as number;
}

/**
 * Get the string type rowId of the row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the row id string
 */
export function getRowIdString(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 30) as string;
}

/**
 * Get the ClientItemId type rowId of the row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the row client id
 */
export function getRowClientItemId(rowKey: string, tableView: TableView): ClientItemId {
    return getRowDataProperty(rowKey, tableView, 28) as ClientItemId;
}

/**
 * Get the ClientItemId type rowId of the row to be shown in reading pane.
 * For conversation view and message view, this returns the same result as getRowClientItemId.
 * For unstacked conversation, it returns rowId of item corresponding to selected coversation or item part
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the row client id
 */
export function getRowIdToShowInReadingPane(rowKey: string, tableView: TableView): ClientItemId {
    return getRowDataProperty(rowKey, tableView, 29) as ClientItemId;
}

/**
 * Get the source folder id of the row data
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the source folder id
 */
export function getParentFolderId(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 26) as string;
}

/**
 * Get the size of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return the 3S search result instrumentation context
 */
export function getInstrumentationContext(
    rowKey: string,
    tableView: TableView
): InstrumentationContext {
    return getRowDataProperty(rowKey, tableView, 14) as InstrumentationContext;
}

/**
 * Get if an item is a draft
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return true if an item is a draft, false otherwise
 */
export const getIsDraft = function getIsDraft(rowKey: string, tableView: TableView): boolean {
    return getRowDataProperty(rowKey, tableView, 15) as boolean;
};

/**
 * Get if an item is submitted
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return true if an item is submitted, false otherwise
 */
export const getIsSubmitted = function getIsSubmitted(
    rowKey: string,
    tableView: TableView
): boolean {
    return getRowDataProperty(rowKey, tableView, 18) as boolean;
};

/**
 * Get if an item is snoozed
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return true if an item is snoozed, false otherwise
 */
export function getIsSnoozed(rowKey: string, tableView: TableView): boolean {
    return getRowDataProperty(rowKey, tableView, 17) as boolean;
}

export function getMailboxGuids(rowKey: string, tableView: TableView): string[] {
    return getRowDataProperty(rowKey, tableView, 35) as string[];
}

/**
 * Get importance for a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return importance of row
 */
export function getImportance(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 12) as string;
}

/**
 * Get archive tag for a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return archive tag of row
 */
export function getArchiveTag(rowKey: string, tableView: TableView): RetentionTagType {
    return getRowDataProperty(rowKey, tableView, 0) as RetentionTagType;
}

/**
 * Get policy tag for a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return policy tag of row
 */
export function getPolicyTag(rowKey: string, tableView: TableView): RetentionTagType {
    return getRowDataProperty(rowKey, tableView, 27) as RetentionTagType;
}

/**
 * Get inference classification for a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return policy tag of row
 */
export function getInferenceClassification(
    rowKey: string,
    tableView: TableView
): InferenceClassification {
    return getRowDataProperty(rowKey, tableView, 13) as InferenceClassification;
}

/**
 * Get if the item is protected
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return true or false
 */
export function getHasIRM(rowKey: string, tableView: TableView): boolean {
    switch (tableView.tableQuery.listViewType) {
        case 0:
            return !!getRowDataProperty(rowKey, tableView, 11) as boolean;

        case 1:
            const rowId = getRowIdString(rowKey, tableView);
            const item = mailStore.items.get(rowId);
            return (
                !!item?.RightsManagementLicenseData ||
                item?.IconIndex === ('MailListViewIRM' as IconIndexType) ||
                item?.IconIndex === ('MailListViewIRMReplied' as IconIndexType) ||
                item?.IconIndex === ('MailListViewIRMForwarded' as IconIndexType)
            );

        default:
            return false;
    }
}

/**
 * Get internet Message id for a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return Internet Message Id of row
 */
export function getInternetMessageId(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 37) as string;
}

/**
 * Returns shouldShowRSVP for a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return true or false
 */
export function getShouldShowRSVP(rowKey: string, tableView: TableView): boolean {
    return getRowDataProperty(rowKey, tableView, 38) as boolean;
}

/**
 * Get the preview text of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return preview text of row
 */
export function getPreviewText(rowKey: string, tableView: TableView): string {
    return getRowDataProperty(rowKey, tableView, 39) as string;
}

/**
 * Get the Copilot Inbox score of a row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @return Copilot Inbox score of row
 */
export function getCopilotInboxScore(rowKey: string, tableView: TableView): number {
    return getRowDataProperty(rowKey, tableView, 40) as number;
}

/**
 * Get the specified property from the row
 * @param rowKey rowKey of row
 * @param tableView the table that contains the row data
 * @param propertyType to retrieve on the row data
 */
function getRowDataProperty(
    rowKey: string,
    tableView: TableView,
    propertyType: MailRowDataPropertyType
) {
    if (!tableView) {
        return null;
    }

    switch (tableView.tableQuery.listViewType) {
        case 0:
            // since this property uses multiple observables, we are going to special case this
            // the other property types only use one obserable of tableView.id
            if (propertyType === 26) {
                const scenarioType =
                    (tableView.tableQuery as MailFolderTableQuery).scenarioType ?? '';
                if (
                    tableView.tableQuery.type === 1 ||
                    scenarioType === 'persona' ||
                    scenarioType === 'category'
                ) {
                    return getTableConversationRelation(rowKey, tableView.id)?.parentFolderId;
                } else {
                    // Use tableView.tableQuery.folderId for non-search scenarios, because parentFolderId is not returned for findConversation
                    return tableView.tableQuery.folderId;
                }
            }
            return getConversationProperty(rowKey, tableView.id, propertyType);

        case 1:
            return getItemProperty(rowKey, tableView.id, propertyType);

        default:
            return null;
    }
}

/**
 * Get the specified property on the item
 * @param rowKey the rowKey of the conversation
 * @param tableViewId the id of the table view
 * @param propertyType the MailRowDataPropertyType
 * @return the specified property on the conversation
 */
export const getConversationProperty = owaComputedFn(
    (rowKey: string, tableViewId: string, propertyType: MailRowDataPropertyType) => {
        const tableConversationRelation = getTableConversationRelation(rowKey, tableViewId);

        if (!tableConversationRelation) {
            traceErrorString('ConversationNotInCache', propertyType);
            return null;
        }

        const conversationId = tableConversationRelation.id;
        const conversationItem = selectConversationById(conversationId);
        const conversationItemParts = mailStore.conversations.get(conversationId);
        let firstItem = undefined;
        if (
            conversationItemParts?.conversationNodeIds &&
            conversationItemParts.conversationNodeIds.length > 0
        ) {
            firstItem = getItemToShowFromNodeId(conversationItemParts.conversationNodeIds[0]);
        }

        switch (propertyType) {
            case 30:
                return conversationId;

            case 28:
                return conversationItem?.clientId;

            case 29:
                if (!isReadingPaneStackedView()) {
                    const expandedConversationState =
                        getListViewState().expandedConversationViewState;

                    // The expanded conversation could be different than the conversation for which the properties are being fetched.
                    if (expandedConversationState.expandedRowKey == rowKey) {
                        const selectedNodeIds = expandedConversationState.selectedNodeIds;

                        // When conversation is fully expanded in mail list and single item part is selected,
                        // return rowId for selected itempart.
                        if (selectedNodeIds && selectedNodeIds.length == 1) {
                            return {
                                Id: getItemIdForMailList(
                                    selectedNodeIds[0],
                                    isFirstLevelExpanded(rowKey)
                                ),
                                mailboxInfo: conversationItem?.clientId.mailboxInfo,
                            };
                        }
                    }

                    // Default to return rowId for item corresponding to latest local itempart.
                    return {
                        Id: tableConversationRelation.itemIds[0],
                        mailboxInfo: conversationItem?.clientId.mailboxInfo,
                    };
                }
                return conversationItem?.clientId;

            case 3:
                return conversationId;

            case 7:
                return firstItem?.Flag;

            case 8:
                return tableConversationRelation.flagStatus;

            case 16:
                return getIsTableConversationRelationPinned(tableConversationRelation);

            case 19:
                return firstItem;

            case 20:
                return tableConversationRelation.itemIds;

            case 10:
                return conversationItem?.globalItemIds[0];

            case 9:
                return conversationItem?.globalItemIds || [];

            case 4:
                return tableConversationRelation.draftItemIds || [];

            case 21:
                return tableConversationRelation.lastDeliveryOrRenewTimeStamp;

            case 22:
                return tableConversationRelation.lastDeliveryTimeStamp;

            case 23:
                return tableConversationRelation.lastModifiedTimeStamp;

            case 34:
                return tableConversationRelation.unreadCount;

            case 24:
                return tableConversationRelation.lastSender?.Mailbox; // lastSender is undefined in rowModified notification payload, if the message is directly POSTed
            // lastSender is undefined in rowModified notification payload, if the message is directly POSTed

            case 25:
                return tableConversationRelation.lastSender?.Mailbox?.EmailAddress;

            case 6:
                return tableConversationRelation.executeSearchSortOrder;

            case 26:
                // this should never be called here. Call getParentFolderId instead
                errorThatWillCauseAlert('InvalidParentFolderIdCallRowGetter');
                return null;

            case 32:
                return (conversationItem as BaseItem)?.subject;

            case 33:
                return tableConversationRelation.uniqueSenders;

            case 36:
                return tableConversationRelation.uniqueRecipients;

            case 31:
                return conversationItem?.size;

            case 5:
                return tableConversationRelation.effectiveMentioned;

            case 14:
                return tableConversationRelation.instrumentationContext;

            case 2:
                return tableConversationRelation.categories;

            case 1:
                return conversationItemParts?.canDelete;

            case 12:
                return tableConversationRelation.importance;

            case 0:
                return firstItem?.ArchiveTag;

            case 27:
                return firstItem?.PolicyTag;

            case 17:
                return getIsTableConversationRelationSnoozed(tableConversationRelation);

            case 35:
                return tableConversationRelation.mailboxGuids;
            case 11:
                return tableConversationRelation.hasIrm;

            case 13:
                return firstItem?.InferenceClassification;

            case 37:
                const mailRowItem = firstItem ?? conversationItem;
                return isItemOfMessageType(mailRowItem as Item)
                    ? (mailRowItem as Message)?.InternetMessageId
                    : null;

            case 38:
                return tableConversationRelation.shouldShowRSVP ?? false;

            case 39:
                return tableConversationRelation.previewText;

            case 40:
                return tableConversationRelation.copilotInboxScore;

            case 18:
            case 15:
            default:
                return null;
        }
    }
);

/**
 * Get the specified property on the item
 * @param itemRowKey the rowKey for the item
 * @param tableViewId the id of the table view
 * @param propertyType the MailRowDataPropertyType
 * @return the specified property on the item
 */
const getItemProperty = owaComputedFn(
    (itemRowKey: string, tableViewId: string, propertyType: MailRowDataPropertyType) => {
        const itemRelation = getTableItemRelation(itemRowKey, tableViewId);
        if (!itemRelation) {
            traceErrorString('ItemNotInRelations', propertyType);
            return null;
        }

        const itemId = itemRelation.clientId;
        const item = itemId ? mailStore.items.get(itemId.Id) : undefined;

        if (!item) {
            traceErrorString('ItemNotInCache', propertyType);
            return null;
        }

        const message = isItemOfMessageType(item) ? (item as Message) : null;
        const lastSenderMailBox = message?.From?.Mailbox;

        switch (propertyType) {
            case 30:
                return itemId.Id;

            case 29:
            case 28:
                //TODO: 22724 we need to use our own item type which has clientItemId instead of retrieving from tableItemRelation
                return itemId;

            case 3:
                return message?.ConversationId?.Id;

            case 7:
                return item.Flag;

            case 8:
                // item.Flag could be undefined in search results
                return item.Flag ? item.Flag.FlagStatus : null;

            case 16:
                return getIsTableItemDataPinned(item);

            case 19:
                return item;

            case 20:
                return [itemId.Id];

            case 10:
                return itemId.Id;

            case 9:
                return [itemId.Id];

            case 21:
                return item.ReceivedOrRenewTime;

            case 22:
                return item.DateTimeReceived;

            case 23:
                return item.LastModifiedTime;

            case 34:
                // returns 1 if message exists and it's unread
                // returns 0 if message is read, or item is not Message type
                return message && !message.IsRead ? 1 : 0;

            case 24:
                return lastSenderMailBox;

            case 25:
                return lastSenderMailBox?.EmailAddress;

            case 33:
                if (lastSenderMailBox) {
                    return [lastSenderMailBox.Name];
                }
                return null;

            case 36:
                return [item.DisplayTo];

            case 6:
                // The SortOrderSource depends on each search table, so it should be accessed from item relation
                return itemRelation.executeSearchSortOrder;

            case 26:
                return item.ParentFolderId?.Id ?? '';

            case 32:
                return item.Subject;

            case 31:
                return item.Size;
            case 5:
                return item.MentionedMe;

            case 15:
                return item.IsDraft;

            case 4:
                return item.IsDraft ? [itemId.Id] : [];

            case 18:
                return item.IsSubmitted;

            case 14:
                return itemRelation.instrumentationContext;

            case 2:
                return item.Categories;

            case 1:
                return item.CanDelete;

            case 12:
                return item.Importance;

            case 0:
                return item.ArchiveTag;

            case 27:
                return item.PolicyTag;

            case 17:
                return getIsTableItemDataSnoozed(item);

            case 35:
                return message?.MailboxGuids;

            case 13:
                return item.InferenceClassification;

            case 37:
                return message?.InternetMessageId;

            case 38:
                return item.shouldShowRSVP ?? false;

            case 39:
                return item.Preview;

            case 40:
                return message?.CopilotInboxScore ?? -1;

            default:
                return null;
        }
    }
);

function traceErrorString(error: string, propertyType: MailRowDataPropertyType) {
    const datapoint = returnTopExecutingActionDatapoint(() => true);
    let errorString = error + ' PropertyType:' + propertyType;
    if (datapoint) {
        errorString += ' Scenario:' + datapoint.getEventName();
    }

    trace.warn(errorString, 'conversation');
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
    logUsage(error, { Scenario: datapoint?.getEventName(), propertyType });
}
export type { default as TableViewItemRelation } from '../store/schema/TableViewItemRelation';
export type { default as TableViewConversationRelation } from '../store/schema/TableViewConversationRelation';
