import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isSupportedAppServiceEnvironment } from '../utils/isSupportedAppServiceEnvironment';

export function isUnifiedAppStoreEnabled(mailboxInfo?: MailboxInfo): boolean {
    return (
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'mos-mos3AppService' */
        isFeatureEnabled('mos-mos3AppService', mailboxInfo) &&
        isSupportedAppServiceEnvironment(mailboxInfo)
    );
}
