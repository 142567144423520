import type AttachmentFullViewState from 'owa-attachment-full-data/lib/schema/AttachmentFullViewState';

export interface ConversationAttachmentsStore {
    // map conversations by id to the attachment list on all the items in the conversation
    conversationAttachments: Map<string, ConversationAttachmentsViewState>;
}

export enum AttachmentFetchStatus {
    NotStarted = 'NotStarted',
    InProgress = 'InProgress',
    Completed = 'Completed',
}

export interface ConversationAttachmentsViewState {
    conversationAttachmentsList: AttachmentFullViewState[] | undefined;
    fetchStatus: AttachmentFetchStatus;
    conversationId: string;
}
