import type MailboxInfo from './MailboxInfo';

/**
 * Specialization of the MailboxInfo type for PST files, these mailboxes are used
 * for the PST file added as an account
 */
export interface PstFileMailboxInfo extends MailboxInfo {
    readonly type: 'PstFile';
}

/**
 * Predicate function to check if the mailboxInfo is a PstFileMailboxInfo
 */
export function isPstFileMailboxInfo(mailboxInfo: MailboxInfo): mailboxInfo is PstFileMailboxInfo {
    return mailboxInfo.type === 'PstFile';
}
