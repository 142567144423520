import { orchestrator, action, mutatorAction } from 'satcheljs';
import {
    setAvailableWidthBucket,
    setAvailableHeightBucket,
} from '../utils/initializeDynamicLayout';
import { getBrowserWidth, getBrowserHeight } from 'owa-config';
import { onWindowResize } from './onWindowResize';
import type LayoutChangeSource from '../store/schema/LayoutChangeSource';
import { getStore } from '../store/store';
import type BrowserHeightBucket from '../store/schema/BrowserHeightBucket';
import type BrowserWidthBucket from '../store/schema/BrowserWidthBucket';

/**
 * While we want to generally avoid using orchestrators if the action is defined in the
 * same package, setAvailableHeightAndWidthBuckets has to be an action/orchestrator because we want
 * mobx to batch all of the store updates together, to avoid components rendering multiple times
 * in response to these updates. To ensure that the orchestrator is registered by the time the action is fired,
 * we're defining the actions, mutators, and orchestrator all in the same file. This also makes future updates
 * easier, as all the related code is in one place.
 */
export const setAvailableHeightAndWidthBuckets = action(
    'setAvailableHeightAndWidthBuckets',
    (layoutChangeSource: LayoutChangeSource) => ({
        layoutChangeSource,
    })
);

orchestrator(setAvailableHeightAndWidthBuckets, actionMessage => {
    const { layoutChangeSource } = actionMessage;
    setAvailableWidthBucket(layoutChangeSource);
    setAvailableHeightBucket(layoutChangeSource);
    // Notify consumers
    onWindowResize(getBrowserHeight(), getBrowserWidth());
});

/**
 * Sets browser's current height bucket
 * @param browserHeightBucket Browser height bucket
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setBrowserHeightBucket = mutatorAction(
    'setBrowserHeightBucket',
    (browserHeightBucket: BrowserHeightBucket) => {
        getStore().browserHeightBucket = browserHeightBucket;
    }
);

/**
 * Sets browser's current width bucket
 * @param browserWidthBucket Browser width bucket
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setBrowserWidthBucket = mutatorAction(
    'setBrowserWidthBucket',
    (browserWidthBucket: BrowserWidthBucket) => {
        getStore().browserWidthBucket = browserWidthBucket;
    }
);
