import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { mutatorAction } from 'satcheljs';
import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import { getStore } from '../store/store';
import { shouldHideFavoritesList } from '../selectors/shouldHideFavoritesList';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const initializeShouldHideFavoritesList = mutatorAction('initializeShouldHideFavoritesList', () => {
    const bitMaskFlagBool = getIsBitSet(16384, getGlobalSettingsAccountMailboxInfo());

    const mailLayoutFlag = shouldHideFavoritesList();
    if (bitMaskFlagBool !== mailLayoutFlag) {
        getStore().hideFavoritesList = bitMaskFlagBool;
    }
});

export default initializeShouldHideFavoritesList;
