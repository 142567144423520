import type MoveToFolderMenuStore from './schema/MoveToFolderMenuStore';
import type NewFolderViewState from './schema/NewFolderViewState';
import { createStore } from 'owa-satcheljs';
import { MAIN_WINDOW_ID } from '../utils/constants';

export const initialStore: MoveToFolderMenuStore = {
    findTextMap: new Map<string, string>([[MAIN_WINDOW_ID, '']]),
    isFocusInSearchBox: false,
    newFolderViewStateMap: new Map<string, NewFolderViewState | null>([[MAIN_WINDOW_ID, null]]),
    shouldShowAllFolders: false,
    moveToMailboxType: 'UserMailbox',
    moveToSharedFolderRootId: '',
};

const moveToStore = createStore<MoveToFolderMenuStore>('moveToStore', { ...initialStore })();
export const getStore = () => moveToStore;
export default moveToStore;
