import type { RibbonControlProps } from 'owa-acui';
import { owaComputedFn } from 'owa-computed-fn';
import { type MailRibbonControlId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { RibbonControlId } from 'owa-ribbon-ids';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import {
    getConversationsControl,
    getMessagePreviewControl,
    getReadingPaneControl,
    getRibbonModeControl,
} from './mlrViewTabControlDefinitions';
import {
    getDensityControl,
    getFolderPaneControl,
    getViewSettingsControl,
    getRemindersWindowControl,
    getExpandCollapseConversationControl,
    getSyncMailboxControl,
    getMyDayControl,
    getLightRPControl,
} from '../../sharedControls/readSharedControlDefinitions';
import {
    getImmersiveReaderControl,
    getZoomControl,
} from '../../sharedControls/readProjectionSharedControlDefinitions';
import { errorThatWillCauseAlert } from 'owa-trace';

/**
 * This function supplies a mapping from MailRibbonControlId to their respective control definition function.
 * For any new control in a tab, be sure to update the mapping below.
 * Note: This function should *not* have any logic regarding showing/hiding.
 *       That should be in shouldShowRibbonControl.ts instead.
 */
export const getMLRViewTabControlDefinition = owaComputedFn(function getMLRViewTabControlDefinition(
    controlId: RibbonControlId,
    _tabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps | undefined {
    switch (controlId) {
        case 644:
            return getConversationsControl(props);
        case 649:
            return getDensityControl(false /* isSLR*/, 3, props);
        case 647:
            return getFolderPaneControl(false /*isSLR */, props);
        case 532:
            return getImmersiveReaderControl(false /*isSLR*/, 3, props);
        case 645:
            return getMessagePreviewControl(props);
        case 648:
            return getReadingPaneControl(props);
        case 643:
            return getViewSettingsControl(false /* isSLR*/, 3, props);
        case 690:
            return getRemindersWindowControl(false /*isSLR*/, 3, props);
        case 687:
            return getExpandCollapseConversationControl(props);
        case 720:
            return getLightRPControl(props);
        case 688:
            return getZoomControl(false /*isSLR*/, 3, props);
        case 691:
            return getRibbonModeControl(props);
        case 701:
            return getSyncMailboxControl(false /*isSLR*/, 3, props);
        case 706:
            return getMyDayControl(props);
        default:
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * The error name (message) must be a string literal (no variables in it).
             *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
            errorThatWillCauseAlert(
                'Unknown control: ' + controlId + ' searched for in getMLRViewTabControlDefinition'
            );
            return undefined;
    }
});
