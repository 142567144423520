import { logStartGreyError, logStartUsage } from 'owa-analytics-start';
import { setTryLookupIndexerFunc } from 'owa-client-types/lib/tryLookupIndexer';
import {
    getCoprincipalAccounts,
    getAccountsRemovedThisSession,
    getGlobalSettingsAccount,
    type CoprincipalAccountSource,
    StartupFilter,
    ContractsSupportedFilter,
} from 'owa-account-source-list-store';
import { coprincipalAccountIndexerFromSourceId } from 'owa-client-types/lib/coprincipalAccountIndexerFromSourceId';
import type { MailboxInfo } from 'owa-client-types';
import type { TryLookupIndexerFunc } from 'owa-client-types/lib/tryLookupIndexer';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';
import { isFeatureEnabled } from 'owa-feature-flags';

let currentLogCount = 0;
const maxLogCount = 10;

interface UserIdentityAndSourceId {
    sourceId: string;
    userIdentity: string;
}

/**
 * Allow the tests to reset the log count
 */
export function resetLogCount() {
    currentLogCount = 0;
}

/**
 * Checkes to see if the user identity is for a coprincipal account or a removed account, if it
 * is we can use the user identity as the indexer value
 * @param userIdentity Specifies the user identity to be checked
 * @returns True if userIdentity matches an account or removed account
 */
function tryLookupCoprincipalAccountOrRemovedAccountByUserIdentity(
    allCoprincipalAccounts: CoprincipalAccountSource[],
    userIdentity: string
): UserIdentityAndSourceId | undefined {
    const matchingAccounts = allCoprincipalAccounts.filter(
        account => account.mailboxInfo.userIdentity == userIdentity
    );

    if (matchingAccounts.length > 0) {
        return { sourceId: matchingAccounts[0].sourceId, userIdentity };
    }

    // Check if the user identity matches any of the removed accounts
    const matchingRemovedAccounts = getAccountsRemovedThisSession().filter(
        account => account.mailboxInfo.userIdentity == userIdentity
    );

    return matchingRemovedAccounts.length > 0
        ? { sourceId: matchingRemovedAccounts[0].sourceId, userIdentity }
        : undefined;
}

/**
 * The map of known indexers based on the user identity value
 */
const knownUserIdentitiesByAlias = new Set<string>();

// Allow the unit tests to clear this cache
export function resetKnownUserIdentitiesByAlias() {
    knownUserIdentitiesByAlias.clear();
}

/**
 * Attempts to lookup the indexer value for the supplied MailboxInfo
 *
 * Note, that any changes to this function need to be reflected in the buildIndexerByUserIdentityMap
 * function in the shared/internal/owa-account-source-list-store/src/utils/buildIndexerByUserIdentityMap.ts
 * @param mailboxInfo Identifies a mailbox within an account for which the indexer value is to be returned
 * @returns Indexer value if one can be found or undefined if we cannot determine the indexer value
 */
export function tryLookupUserIdentityAndSmtpBasedIndexer(
    mailboxInfo: MailboxInfo
): UserIdentityAndSourceId | undefined {
    if (
        mailboxInfo.mailboxRank === 'Licensing' ||
        !mailboxInfo.userIdentity ||
        !mailboxInfo.mailboxSmtpAddress
    ) {
        // we cannot lookup SMTP and user identity based indexers for licensing accounts or if either
        // the user identity or mailbox SMTP address are missing
        return undefined;
    }

    const matchingAccounts = getCoprincipalAccounts(
        StartupFilter.StartingOrCompleteOrError,
        ContractsSupportedFilter.Any
    ).filter(
        account =>
            account.mailboxInfo.userIdentity == mailboxInfo.userIdentity &&
            account.mailboxInfo.mailboxSmtpAddress == mailboxInfo.mailboxSmtpAddress
    );

    if (matchingAccounts.length > 0) {
        // found a match return the first value
        return {
            sourceId: matchingAccounts[0].sourceId,
            userIdentity: matchingAccounts[0].mailboxInfo.userIdentity,
        };
    }

    // Did not find a matching SMTP and user identity based indexer
    return undefined;
}

/**
 * Attempts to lookup the indexer value for the supplied MailboxInfo
 *
 * Note, that any changes to this function need to be reflected in the buildIndexerByUserIdentityMap
 * function in the shared/internal/owa-account-source-list-store/src/utils/buildIndexerByUserIdentityMap.ts
 * @param mailboxInfo Identifies a mailbox within an account for which the indexer value is to be returned
 * @returns Indexer value if one can be found or undefined if we cannot determine the indexer value
 */
export function tryLookupUserIdentityBasedIndexer(
    mailboxInfo: MailboxInfo
): UserIdentityAndSourceId | undefined {
    if (!mailboxInfo.userIdentity) {
        // user identity is required to lookup the account
        return undefined;
    }

    const allCoprincipalAccounts = getCoprincipalAccounts(
        StartupFilter.StartingOrCompleteOrError,
        ContractsSupportedFilter.Any
    );
    if (allCoprincipalAccounts.length == 0) {
        // there are no coprincipal accounts so we cannot determine the matching information
        return undefined;
    }

    const fromUserIdentity = tryLookupCoprincipalAccountOrRemovedAccountByUserIdentity(
        allCoprincipalAccounts,
        mailboxInfo.userIdentity
    );

    if (fromUserIdentity) {
        // We found a matching user identity, either in a coprincipal accounts or
        // an account that was removed this session, so we can use userIdentity
        // as the indexer value.
        return fromUserIdentity;
    }

    if (mailboxInfo.mailboxRank === 'Licensing') {
        // licensing accounts are resources of the global settings account, return it's user identity
        const GlobalSettingsAccount = getGlobalSettingsAccount();
        return {
            sourceId: GlobalSettingsAccount.sourceId,
            userIdentity: GlobalSettingsAccount.mailboxInfo.userIdentity,
        };
    }

    const accountByAlias = allCoprincipalAccounts.filter(
        account => !account.isAnotherMailbox && account.aliases?.includes(mailboxInfo.userIdentity)
    )[0];
    if (!!accountByAlias) {
        // We have found the account by looking at the aliases of the existing accounts, use
        // the userIdentity of the account as the indexer value
        const foundByAlias = `${mailboxInfo.userIdentity}=>${accountByAlias.mailboxInfo.userIdentity}`;
        if (!knownUserIdentitiesByAlias.has(foundByAlias)) {
            // We have not reported on this mapping before so log that it was found
            knownUserIdentitiesByAlias.add(foundByAlias);
            const error = new Error('Found indexer by alias of an account');
            const info = {
                totalFound: knownUserIdentitiesByAlias.size,
                mailboxType: mailboxInfo.type,
                diagnosticData: mailboxInfo.diagnosticData ?? '<>',
            };
            logStartGreyError('IncorrectMailboxInfoUserIdentity', error, info);
        }

        return {
            sourceId: accountByAlias.sourceId,
            userIdentity: accountByAlias.mailboxInfo.userIdentity,
        };
    }

    // We were unable to determine the indexer value. This can happen if the indexer value is
    // requested before the account source list store is initialized or if the MailboxInfo is
    // not associated with any account.
    return undefined;
}

// Checked aliases for the user identity
const previouslyCheckedAliases = new Set<string>();

// Allow the unit tests to clear this cache
export function resetPreviouslyCheckedAliases() {
    previouslyCheckedAliases.clear();
}

/**
 * When sourceId is not present in a MailboxInfo we need to fallback to looking up the indexer
 * from the user identity. This function will lookup the user identity from the indexer value
 * @param mailboxInfo Specifies the mailbox info for which the indexer value is to be returned
 * @returns Indexer value to be used
 */
const tryLookupIndexer: TryLookupIndexerFunc = (mailboxInfo: MailboxInfo) => {
    let smtpLookupFailed = false;
    let lookedupIndexer: UserIdentityAndSourceId | undefined = undefined;
    if (
        isPersistenceIdIndexerEnabled() &&
        isFeatureEnabled('acct-sharedcpa', undefined, /*dontThrowErrorIfNotInitialized*/ true)
    ) {
        lookedupIndexer = tryLookupUserIdentityAndSmtpBasedIndexer(mailboxInfo);
        smtpLookupFailed = lookedupIndexer === undefined;
    }

    lookedupIndexer = lookedupIndexer ?? tryLookupUserIdentityBasedIndexer(mailboxInfo);
    if (!isPersistenceIdIndexerEnabled()) {
        return lookedupIndexer?.userIdentity;
    }

    // We want to know when we are looking up indexer values by user identity but to not
    // want to overwhelm the telemetry system so we only log a set number of lookup
    if (currentLogCount < maxLogCount) {
        ++currentLogCount;

        const errorForStack: TraceErrorObject = new Error('TryLookupIndexerCalled');
        const hasSourceId: boolean = !!mailboxInfo.sourceId;
        const foundIndexer: boolean = !!lookedupIndexer;
        const data = {
            hasSourceId,
            foundIndexer,
            smtpLookupFailed,
            currentLogCount,
        };
        errorForStack.additionalInfo = data;

        logStartGreyError('TryLookupIndexerCalled', errorForStack, data);
        if (
            isFeatureEnabled(
                'acct-smtplookupalerts',
                undefined,
                /*dontThrowErrorIfNotInitialized*/ true
            )
        ) {
            errorThatWillCauseAlert('TryLookupIndexerCalled', errorForStack);
        }
    }

    return coprincipalAccountIndexerFromSourceId(lookedupIndexer?.sourceId);
};

export function initializeTryLookupIndexer() {
    logStartUsage('initializeTryLookupIndexer');

    setTryLookupIndexerFunc(tryLookupIndexer);
}
