import { createStore } from 'owa-satcheljs';
import type {
    GroupActivityState,
    GroupActivityStateByMailboxInfo,
} from './schema/GroupMemberRequestData';
import type { AccountKey } from 'owa-client-types';

export const getGroupsActivityStore = createStore<GroupActivityStateByMailboxInfo>(
    'groupActivityStoreSchema',
    {
        groupActivityStateByMailboxInfo: new Map<AccountKey, GroupActivityState>(),
    }
);
