import type OverallPremiumState from './schema/OverallPremiumState';
import { PremiumStatusEnum } from './schema/OverallPremiumState';
import { createStore } from 'owa-satcheljs';

const overallPremiumState: OverallPremiumState = {
    overallPremiumBit: PremiumStatusEnum.Unset,
    accountsLoadPending: true,
};

export default createStore<OverallPremiumState>('overallPremiumState', overallPremiumState);
