import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import getFolderTable from '../selectors/getFolderTable';
import getFolderTreeLoadingState from '../selectors/getFolderTreeLoadingState';
import { getAccountScopeUserSettings, getUserConfiguration } from 'owa-session-store';
import { DatapointStatus, logUsage, PerformanceDatapoint } from 'owa-analytics';
import { PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import { hasCalculatedTti } from 'owa-tti/lib/tti';
import { setLoadingStateForFolderTree } from '../actions/setLoadingStateForFolderTree';
import onFolderHierarchyResponse from '../util/onFolderHierarchyResponse';
import FolderTreeLoadStateEnum from '../store/schema/FolderTreeLoadStateEnum';
import { initializeFolderTreeData } from './initializeFolderTreeData';
import {
    lazyFetchPrimaryMailFolders,
    lazyFetchDumpsterFolder,
    lazyFetchSearchFolders,
} from '../lazyFunctions';
import { extractFolderHierarchy } from './extractFolderHierarchy';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { type MailboxInfo, getAccountKeyForMailboxInfo } from 'owa-client-types';
import { getCustomFolderCount } from './getCustomFolderCount';
import { isGlobalSettingsMailbox } from 'owa-account-source-list-store';
import type { MailFolderFromServiceFragment } from 'owa-mail-folder-view-graphql';
import { getIsCustomSortEnabled } from '../selectors/getIsCustomSortEnabled';
import { setFoldersDataCollectionInformation } from './registerDataCollector';

/**
 * An orchestrator that gets the primary mail folders.
 */
export async function getPrimaryMailFolders(
    userMailboxInfo: MailboxInfo,
    sessionData?: SessionData
) {
    initializeFolderTreeData(userMailboxInfo);
    const loadingState = getFolderTreeLoadingState(
        PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
        userMailboxInfo
    );

    // if loading is in progress or it is loaded and we are not loading more, just return
    if (
        loadingState == FolderTreeLoadStateEnum.Loading ||
        loadingState == FolderTreeLoadStateEnum.Loaded
    ) {
        return;
    }

    // sets the loading state to 'Loading' for primary folder tree
    setLoadingStateForFolderTree(
        PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
        FolderTreeLoadStateEnum.Loading,
        userMailboxInfo
    );

    let dp: PerformanceDatapoint | undefined;

    let primaryFolderGqlResponse = extractFolderHierarchy(sessionData, userMailboxInfo);
    const accountKey = getAccountKeyForMailboxInfo(userMailboxInfo);

    try {
        // Check if folder hierarchy is present in the session data, if yes convert it to the gql type response
        // before consuming it.
        if (!primaryFolderGqlResponse) {
            // Fetch the folder hierarchy incase it is missing in session data.
            const fetchPrimaryMailFolders = await lazyFetchPrimaryMailFolders.import();
            const customSort = getIsCustomSortEnabled(userMailboxInfo);
            primaryFolderGqlResponse =
                (await fetchPrimaryMailFolders(userMailboxInfo, !!customSort)) || undefined;

            if (!hasCalculatedTti()) {
                dp = new PerformanceDatapoint('Tti_PrimaryMailFoldersResponse');
            }
        }
    } catch (e) {
        setFoldersDataCollectionInformation(accountKey, e);
        // Set tree loading to error state
        setLoadingStateForFolderTree(
            PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
            FolderTreeLoadStateEnum.ErrorLoading,
            userMailboxInfo
        );
        dp?.endWithError(DatapointStatus.ServerError, e);
        return;
    }

    // Handle undefined response appropriately
    if (!primaryFolderGqlResponse) {
        setLoadingStateForFolderTree(
            PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
            FolderTreeLoadStateEnum.ErrorLoading,
            userMailboxInfo
        );
        dp?.end();
        return;
    } else {
        setFoldersDataCollectionInformation(accountKey, primaryFolderGqlResponse);
    }

    const userDisplayName =
        getAccountScopeUserSettings(userMailboxInfo).SessionSettings?.UserEmailAddress ===
        userMailboxInfo.userIdentity
            ? getAccountScopeUserSettings(userMailboxInfo).SessionSettings?.UserDisplayName
            : userMailboxInfo.userIdentity;

    if (primaryFolderGqlResponse?.RootFolder && primaryFolderGqlResponse?.Folders) {
        // Consume the gql response
        onFolderHierarchyResponse(
            userDisplayName ?? '',
            primaryFolderGqlResponse.Folders as MailFolderFromServiceFragment[],
            primaryFolderGqlResponse.RootFolder,
            PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID, // folderTreeIdentifier
            !!primaryFolderGqlResponse?.IncludesLastItemInRange,
            primaryFolderGqlResponse?.offset ?? 0,
            userMailboxInfo,
            !!primaryFolderGqlResponse?.CustomSorted
        );

        // log various counts after primary mail folders have been loaded
        logDataOnFolderLoaded(userMailboxInfo, primaryFolderGqlResponse);
    }

    if (primaryFolderGqlResponse?.RootFolder) {
        lazyFetchSearchFolders.importAndExecute(
            userMailboxInfo,
            primaryFolderGqlResponse.RootFolder as MailFolderFromServiceFragment
        );
    }

    // prefetch dumpster folder
    lazyFetchDumpsterFolder.importAndExecute(userMailboxInfo);

    if (dp) {
        // Include the remainder of the task including reactions
        setTimeout(() => dp?.end(), 0);
    }
}

function logDataOnFolderLoaded(mailboxInfo: MailboxInfo, primaryFolderGqlResponse: any) {
    const numInboxChildFolders =
        getFolderTable().get(folderNameToId('inbox', mailboxInfo))?.childFolderIds?.length ?? 0;
    const msgRootFolderCount = getFolderTable().get(
        folderNameToId('msgfolderroot', mailboxInfo)
    )?.totalMessageCount;

    logUsage('TnS_LoadFolders', [numInboxChildFolders, msgRootFolderCount]);

    const isPrimaryAccount = isGlobalSettingsMailbox(mailboxInfo);
    logUsage('TnS_MailFoldersRetrieved', [
        getCustomFolderCount(
            primaryFolderGqlResponse.RootFolder?.id,
            primaryFolderGqlResponse.Folders as MailFolderFromServiceFragment[]
        ), // number of folders that are a first level child of root
        primaryFolderGqlResponse.IncludesLastItemInRange,
        isPrimaryAccount
            ? getUserConfiguration()?.UserOptions?.IsMailRootFolderTreeCollapsed
            : false,
        isPrimaryAccount,
        primaryFolderGqlResponse.Folders?.length ?? 0, // total number of folders in the response
        primaryFolderGqlResponse.TotalItemsInView, // total number of folders the user has
    ]);
}
