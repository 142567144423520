import { Module } from 'owa-workloads-module';
import { getCurrentModule } from 'owa-app-module-store';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';

export function isAppBarEnabled() {
    // we will default to the mail module
    const currentModule = getCurrentModule() || Module.Mail;
    return !isHostAppFeatureEnabled('hideAppBar') && isAppBarEnabledForModule(currentModule);
}

function isAppBarEnabledForModule(module: Module): boolean {
    return (
        module === Module.Mail ||
        module === Module.Calendar ||
        module === Module.AppHost ||
        module === Module.People ||
        module === Module.FilesHub ||
        module === Module.Groups
    );
}
