import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "LocalDataAccountSupport" */ './lazyIndex'),
    { name: 'LocalDataAccountSupport' }
);

export const lazyAddLocalDataAdditionalAccount = new LazyAction(
    lazyModule,
    m => m.addLocalDataAdditionalAccount
);

export const lazyDoCleanupForRemovedLocalDataAccount = new LazyAction(
    lazyModule,
    m => m.doCleanupForRemovedLocalDataAccount
);

export const lazyCheckM365Installation = new LazyAction(lazyModule, m => m.checkM365Installation);

export const lazyCheckPstPrerequisites = new LazyAction(lazyModule, m => m.checkPstPrerequisites);
