import getO365ShellShim from './getO365ShellShim';

export default function setShellButtonCustomBadgeCount(buttonId: string, badgeCount: number) {
    // Call after RAF to prevent changing state during render
    /* eslint-disable-next-line no-restricted-globals -- (https://aka.ms/OWALintWiki)
     * BASELINE. DO NOT COPY AND PASTE!
     *	> Unexpected use of 'requestAnimationFrame'. Use safeRequestAnimationFrame in owa-performance instead. */
    requestAnimationFrame(() =>
        getO365ShellShim().Extensibility.SetCustomHeaderButtonBadgeValue(buttonId, badgeCount)
    );
}
