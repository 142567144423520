import getListViewState from 'owa-mail-list-store/lib/selectors/getListViewState';
import type { IPoint } from '@fluentui/react/lib/Utilities';
import { mutatorAction } from 'satcheljs';
import type MailListContextMenuSource from 'owa-mail-list-store/lib/store/schema/MailListContextMenuSource';
import type MailListContextMenuType from 'owa-mail-list-store/lib/store/schema/MailListContextMenuType';
import type MailListItemContextMenuState from 'owa-mail-list-store/lib/store/schema/MailListItemContextMenuState';

/**
 * Show the mail item context menu by initiating the itemContextMenuState
 * @param anchor the anchor where the mouse/keyboard event happens
 * @param the list view store which contains itemContextMenuState
 */
export function showMailItemContextMenu(
    anchor: IPoint,
    menuType: MailListContextMenuType,
    menuSource?: MailListContextMenuSource,
    anchorRowKeyDiv?: HTMLDivElement | null
): void {
    setItemContextMenuState({
        anchor,
        menuType,
        menuSource,
        anchorRowKeyDiv,
    });
}

/**
 * Hide the mail item context menu by clearing the itemContextMenuState
 */
export function hideMailItemContextMenu(): void {
    setItemContextMenuState(null);
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const setItemContextMenuState = mutatorAction(
    'setItemContextMenuState',
    (state: MailListItemContextMenuState | null) => {
        getListViewState().itemContextMenuState = state;
    }
);
