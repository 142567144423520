import { createStore } from 'owa-satcheljs';
import { type MailboxInfo, getIndexerValueForMailboxInfo } from 'owa-client-types';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { lazyFetchReportDialogStrings } from '../index';
import fetchReportSubmissionPolicy from '../utils/fetchReportSubmissionPolicy';
import getDefaultReportSubmissionPolicy from '../utils/getDefaultReportSubmissionPolicy';
import getReportSubmissionPolicyFromPrimeSettings from '../utils/getReportSubmissionPolicyFromPrimeSettings';
import type { ReportingStoreMap } from './schema/ReportingStore';

export const reportingStoreMap = createStore<ReportingStoreMap>('reportingStoreMap', {
    stores: new Map(),
})();

export const getStore = () => reportingStoreMap;

export function getStoreByMailboxInfo(mailboxInfo: MailboxInfo) {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    let store = reportingStoreMap.stores.get(key);

    if (!store) {
        // In most cases, we get the report submission policy information from PrimeSettings.
        const reportSubmissionPolicyFromPrimeSettings =
            getReportSubmissionPolicyFromPrimeSettings(mailboxInfo);

        // Initialize the store with the policy information from PrimeSettings if available,
        // or the default policy if not.
        store = {
            reportSubmissionPolicy:
                reportSubmissionPolicyFromPrimeSettings || getDefaultReportSubmissionPolicy(),
            reportDialogStringsInformation: null,
        };
        reportingStoreMap.stores.set(key, store);

        // If the report submission policy information isn't available from PrimeSettings (i.e.
        // shared/delegate mailboxes scenarios), then fetch it from OWS' so the store
        // can be updated with the correct policy information.
        if (!reportSubmissionPolicyFromPrimeSettings) {
            fetchReportSubmissionPolicy(mailboxInfo);
        }

        // Fetch report dialog strings information for the mailbox
        lazyFetchReportDialogStrings.importAndExecute(mailboxInfo);
    }

    return store;
}
