import { lazyGetRetentionPolicyTagsForGroup } from 'owa-group-utils';
import getSharedFolderPolicyTagList from 'owa-shared-folder/lib/selectors/getSharedFolderPolicyTagList';
import type { MailboxInfo } from 'owa-client-types';
import { getCoprincipalMailboxInfo } from 'owa-account-source-list-store';
import { getUserMailboxInfo } from 'owa-client-ids';
import { getAccountScopeUserSettings } from 'owa-session-store';

export default function getRetentionPolicyTagListForMailboxInfo(mailboxInfo: MailboxInfo) {
    if (mailboxInfo?.type === 'GroupMailbox') {
        const groupMailboxInfo = mailboxInfo;
        const coprincipalMailboxInfo =
            /* eslint-disable-next-line owa-custom-rules/no-default-methods  -- (https://aka.ms/OWALintWiki)
             * Methods that use the default mailbox should not be used, see https://aka.ms/multiaccountlinter
             *	> Do Not Use Default: The getUserMailboxInfo function should not be used for Respond multi-account support, user identity is not enough to identify account information. */
            getCoprincipalMailboxInfo(groupMailboxInfo) ?? getUserMailboxInfo();
        const getRetentionPolicyTagsForGroup =
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * BASELINE. Do not copy and paste!
             *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
            lazyGetRetentionPolicyTagsForGroup.tryImportForRender();
        return getRetentionPolicyTagsForGroup
            ? getRetentionPolicyTagsForGroup(
                  coprincipalMailboxInfo,
                  groupMailboxInfo.mailboxSmtpAddress
              )
            : [];
    }

    if (mailboxInfo?.type === 'SharedMailbox') {
        return getSharedFolderPolicyTagList(mailboxInfo.mailboxSmtpAddress);
    }

    return getAccountScopeUserSettings(mailboxInfo).RetentionPolicyTags;
}
