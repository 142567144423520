import { onSearchTextChangedMutator } from 'owa-mail-moveto-control/lib/actions/onSearchTextChanged';
import { onChangeMoveToMailboxTypeMutator } from 'owa-mail-moveto-control/lib/actions/onChangeMoveToMailbox';
import moveToStore from 'owa-mail-moveto-control/lib/store/store';
import { MAIN_WINDOW_ID } from 'owa-mail-moveto-control';

export const resetMoveToSearch = (onEnter: boolean, projectionTabId?: string) => {
    // The space is necessary on exit so that if the user now opens the message list r-click
    // MOVE menu, we won't show ALL user mailbox folders
    const searchText: string = onEnter ? '' : ' ';
    const id = projectionTabId || MAIN_WINDOW_ID;
    const findText = moveToStore.findTextMap.get(id);
    onSearchTextChangedMutator(findText ?? '', searchText, projectionTabId ?? '');
    onChangeMoveToMailboxTypeMutator('UserMailbox');
};
