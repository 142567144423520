import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import getStore from '../store/store';
import type { GeneralPreferences } from '../store/store';
import { trace } from 'owa-trace';

export function saveGeneralPreferences(
    mailboxInfo: MailboxInfo,
    preferences?: GeneralPreferences
): void {
    if (preferences) {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        const existingPreferences = getStore().copilotGeneralPreferencesMap.get(key);
        if (existingPreferences) {
            trace.info(`Existing isCopilotUIDisabled: ${existingPreferences.isCopilotUIDisabled}`);
        }
        trace.info(`New isCopilotUIDisabled: ${preferences?.isCopilotUIDisabled}`);
        getStore().copilotGeneralPreferencesMap.set(key, {
            isCopilotUIDisabled: preferences?.isCopilotUIDisabled,
        });
    }
}
