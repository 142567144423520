import { trace } from 'owa-trace';
import { Constants } from 'owa-copilot-themes-config';

/**
 * Writes a response to the root_active cache
 */
export default async function setCopilotThemeImageInCache(imageBlob: Blob, timestamp: string) {
    try {
        // Clear the old cache image if it exists
        const cache = await self.caches.open(Constants.COPILOT_THEME_CACHE_NAME);
        await cache.delete(Constants.COPILOT_THEME_IMAGE_CACHE_KEY);

        // Save the new image in the cache with the timestamp as a header
        await cache.put(
            Constants.COPILOT_THEME_IMAGE_CACHE_KEY,
            new Response(imageBlob, {
                headers: {
                    'Content-Type': 'image/jpeg',
                    [Constants.COPILOT_THEME_TIMESTAMP_HEADER]: timestamp,
                },
            })
        );
    } catch (error) {
        trace.warn(`Error putting generated blob image in cache`, error);
        throw error;
    }
}
