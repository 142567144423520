import type { IContextualMenuItem, Point } from '@fluentui/react';
import { createStore } from 'owa-satcheljs';

interface NativeContextMenuState {
    point?: Point;
    contextualMenuItems: IContextualMenuItem[];
    showContextMenu: boolean;
}

export default createStore<NativeContextMenuState>('nativeContextMenuState', {
    point: undefined,
    contextualMenuItems: [],
    showContextMenu: false,
});
