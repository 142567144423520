import initializeExtendedStateForItemViewState from 'owa-mail-reading-pane-store/lib/actions/initializeExtendedStateForItemViewState';
import onItemPartSelected from './onItemPartSelected';
import { FocusedItemArea } from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import { getFocusedItemPart, isItemPartSelected } from '../utils/focusedItemPartUtils';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import { addDatapointConfig } from 'owa-analytics-actions';
import { onItemPartDeselected } from 'owa-mail-actions/lib/readingPaneActions';
import findInlineComposeViewState from 'owa-mail-compose-actions/lib/utils/findInlineComposeViewState';
import getSelectedTableView from 'owa-mail-list-store/lib/utils/getSelectedTableView';
import { action, mutator, mutatorAction } from 'satcheljs';
import { getReadHostItemIndex } from 'owa-addins-core';
import { lazyOnHostItemChanged } from 'owa-addins-view';
import {
    setIsExpandedItemPartMutator,
    setLastActionPerformedMutator,
} from 'owa-mail-reading-pane-find-in-message-store/lib/mutators/findInMessageMutators';
import { LastActionPerformed } from 'owa-mail-reading-pane-find-in-message-store/lib/store/schema/LastActionPerformed';

export default function toggleSelectItemPart(
    conversationId: string,
    toggleExpandedCollapsed: boolean,
    itemPart?: ItemPartViewState,
    fromKeyboard?: boolean,
    shouldNotGrabFocus?: boolean
) {
    if (!toggleExpandedCollapsed && isItemPartSelected(itemPart, conversationId)) {
        // If only update selection and the item part is already selected, no-op
        return;
    }
    const canFocus = !findInlineComposeViewState(conversationId);
    // Update the expanded/collapsed state
    if (toggleExpandedCollapsed) {
        expandCollapseItemPartMutator(itemPart, !!fromKeyboard, canFocus, conversationId);
    }
    // If the item part is expanded initialize the extended state
    if (itemPart?.isExpanded) {
        initializeExtendedStateForItemViewState(itemPart);
    }
    // Unselect all itemParts
    unselectItemParts(conversationId);
    onItemChanged(conversationId, true /*isFocused*/, itemPart);
    // If this is not from the keyboard & can focus, update the focused item part

    // update search results, if needed
    if (itemPart?.itemId) {
        setIsExpandedItemPartMutator('default', itemPart.itemId, itemPart.isExpanded);
        setLastActionPerformedMutator('default', LastActionPerformed.ItemPartExpanded);
    }

    if (!fromKeyboard && itemPart && canFocus) {
        setFocusedItemPartMutator(conversationId, itemPart, shouldNotGrabFocus);
    }
}

const setFocusedItemPartMutator = mutatorAction(
    'setFocusedItemPart',
    (
        conversationId: string,
        itemPart: ItemPartViewState,
        shouldNotGrabFocus: boolean | undefined
    ) => {
        const conversationReadingPaneState = getConversationReadingPaneViewState(conversationId);
        conversationReadingPaneState.focusedItemPart = {
            focusedItemArea: FocusedItemArea.Item,
            itemPart,
            shouldNotGrabFocus,
        };
    }
);

export function unselectItemParts(conversationId: string) {
    const selectedItemPart = getFocusedItemPart(conversationId);
    if (selectedItemPart) {
        // onItemChanged calls mutators so make sure it is called in
        // a seperate microtask/task
        Promise.resolve().then(() => {
            onItemChanged(conversationId, false /*isFocused*/, selectedItemPart);
        });
    }
}

// Count the number of times user expand/collapse item part
// CustomData: 1 - itemPartStateChange:
//                      "SC" - select to collapse,
//                      "ES" - expand to select,
//                      "CS" - collapse to select
//                      "EC" - expand to collapse
//                      "CE" - collapse to expand
// CustomData: 2 - fromKeyboard
const expandCollapseItemPartMutator = action(
    'expandCollapseItemPart',
    (
        itemPart: ItemPartViewState | undefined,
        fromKeyboard: boolean,
        canFocus: boolean,
        conversationId?: string
    ) => {
        let firstCustomData: string;
        if (fromKeyboard || canFocus) {
            if (itemPart?.isExpanded) {
                firstCustomData = 'EC';
            } else {
                firstCustomData = 'CE';
            }
        } else {
            if (itemPart == getFocusedItemPart()) {
                firstCustomData = 'SC';
            } else if (itemPart?.isExpanded) {
                firstCustomData = 'ES';
            } else {
                firstCustomData = 'CS';
            }
        }
        return addDatapointConfig(
            {
                name: 'RPCountExpCollItemPart',
                customData: [firstCustomData, fromKeyboard],
            },
            { itemPart, fromKeyboard, canFocus, conversationId }
        );
    }
);

mutator(expandCollapseItemPartMutator, ({ itemPart, fromKeyboard, canFocus, conversationId }) => {
    if (itemPart) {
        if (fromKeyboard || !canFocus) {
            // If it is from the keyboard, toggle the current state
            itemPart.isExpanded = !itemPart.isExpanded;
        } else {
            // If it is from a mouse click, if the current item part is selected and expanded, collapse otherwise expand
            itemPart.isExpanded =
                isItemPartSelected(itemPart, conversationId) && itemPart.isExpanded ? false : true;
        }

        if (!itemPart.hasExpanded && itemPart.isExpanded) {
            itemPart.hasExpanded = true;
        }
    }
});

async function onItemChanged(
    conversationId: string,
    isFocused: boolean,
    itemPart?: ItemPartViewState
) {
    if (!isFocused) {
        onItemPartDeselected(itemPart?.itemId ?? '', getSelectedTableView());
    } else {
        onItemPartSelected(itemPart?.conversationNodeId ?? '', conversationId);
    }

    if (!isFocused) {
        await lazyOnHostItemChanged.importAndExecute(
            getReadHostItemIndex(itemPart?.itemId ?? ''),
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2345 (151,9): Argument of type 'null' is not assignable to parameter of type 'Window'.
            // @ts-expect-error
            null
        );
    }
}
