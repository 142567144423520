import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ReactionsMailList"*/ './lazyIndex'),
    { name: 'ReactionsMailList' }
);

export const ReactionsOnMailListItemContainer = createLazyComponent(
    lazyModule,
    m => m.ReactionsOnMailListItemContainer
);
