import type SweepRequestStatus from './schema/SweepRequestStatus';
import SweepOptionType from './schema/SweepOptionType';
import type SweepStore from './schema/SweepStore';
import { createStore } from 'owa-satcheljs';

const defaultSweepStore: SweepStore = {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (10,5): Type 'null' is not assignable to type 'string'.
    // @ts-expect-error
    sweepDestinationFolderId: null,
    isSweepDialogShown: false,
    sweepOptionType: SweepOptionType.All,
    sweepRequestStatus: 0,
};

export default createStore<SweepStore>('sweepStore', defaultSweepStore)();
