import { isReadingPanePositionOff } from 'owa-mail-layout/lib/selectors/readingPanePosition';
import { shouldShowReadingPane } from 'owa-mail-layout/lib/selectors/shouldShowReadingPane';
import getSelectedTableView from 'owa-mail-list-store/lib/utils/getSelectedTableView';
import { owaComputedFn } from 'owa-computed-fn';
import { computed } from 'mobx';

/**
 * @returns true if no item is selected in the list view
 */
export const isNoItemContext = owaComputedFn(function isNoItemContext(): boolean {
    // We are using computed here so the we don't observe selectedRowKeys but instead observe the size only.
    // This prevents us from re-computing for every click in the maillist.
    const selectedRowKeysSize = computed(() => getSelectedTableView()?.selectedRowKeys?.size).get();
    return selectedRowKeysSize === 0 && !getSelectedTableView()?.isInVirtualSelectAllMode;
});

/**
 * @param isPopOut boolean indicating if we are in the reading pane or a popout
 * @returns true if more than one item is selected in the list view or if the reading pane is hidden
 */
export const isMultipleSelection = owaComputedFn(function isMultipleSelection(
    isPopOut: boolean
): boolean {
    // We are using computed here so the we don't observe selectedRowKeys but instead observe the size only.
    // This prevents us from re-computing for every click in the maillist.
    const selectedRowKeysSize = computed(() => getSelectedTableView()?.selectedRowKeys?.size).get();

    return (
        (!isPopOut && (selectedRowKeysSize > 1 || isReadingPaneHidden())) ||
        getSelectedTableView()?.isInVirtualSelectAllMode
    );
});

/**
 * @returns true if the reading pane is hidden
 */
export function isReadingPaneHidden(): boolean {
    return isReadingPanePositionOff() && !shouldShowReadingPane();
}
