import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupsSearchView"*/ './lazyIndex'),
    { name: 'GroupsSearchView' }
);

// Export delay loaded components
export const GroupSearchBoxContainer = createLazyComponent(
    lazyModule,
    m => m.GroupSearchBoxContainer
);
export const GroupFolderScopePicker = createLazyComponent(
    lazyModule,
    m => m.GroupFolderScopePicker
);
export const GroupSearchHeaderFirstRowContent = createLazyComponent(
    lazyModule,
    m => m.GroupSearchHeaderFirstRowContent
);
