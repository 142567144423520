import getMasterCategoryList from 'owa-categories/lib/utils/getMasterCategoryList';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import type { FavoriteCategoryData } from 'owa-favorites-types';
import loc, { format } from 'owa-localize';
import { getStore as getFavoritesStore } from 'owa-favorites';
import {
    duplicateFavoriteDisplayNameTemplate,
    duplicateFavoriteTooltipTemplate,
} from './FavoriteDisplayNameForMultiAccounts.locstring.json';
import { getMailboxInfoForMailAccounts } from 'owa-account-source-list-store';
import { getMailboxLabelForFavorite } from './getMailboxLabelForFavorite';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';

export function getFavoriteCategoryDisplayNameForMultiAccount(
    categoryName: string,
    mailboxInfo: MailboxInfo,
    useBothIdentifiers: boolean
): string {
    if (!isMonarchMultipleAccountsEnabled()) {
        return categoryName;
    }

    const favoriteCategoryIdsSet = new Set(
        [...getFavoritesStore().outlookFavorites.values()]
            .filter(favorite => favorite.type === 'category')
            .map(favorite => (favorite as FavoriteCategoryData).categoryId)
    );

    if (!favoriteCategoryIdsSet || favoriteCategoryIdsSet.size < 2) {
        return categoryName;
    }

    const categoriesNamesSet = new Set();

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    getMailboxInfoForMailAccounts().forEach(mailboxInfoParam => {
        if (
            getIndexerValueForMailboxInfo(mailboxInfo) !==
            getIndexerValueForMailboxInfo(mailboxInfoParam)
        ) {
            getMasterCategoryList(mailboxInfoParam).forEach(category => {
                if (category.Id && favoriteCategoryIdsSet.has(category.Id)) {
                    categoriesNamesSet.add((category.Name ?? '').toLocaleLowerCase());
                }
            });
        }
    });

    if (categoriesNamesSet.has(categoryName.toLocaleLowerCase())) {
        const mailboxLabel = getMailboxLabelForFavorite(mailboxInfo);
        if (useBothIdentifiers && mailboxLabel && mailboxLabel !== mailboxInfo.mailboxSmtpAddress) {
            return format(
                loc(duplicateFavoriteTooltipTemplate),
                categoryName,
                mailboxLabel,
                mailboxInfo.mailboxSmtpAddress
            );
        } else {
            return format(
                loc(duplicateFavoriteDisplayNameTemplate),
                categoryName,
                mailboxLabel ?? mailboxInfo.mailboxSmtpAddress
            );
        }
    }

    return categoryName;
}
