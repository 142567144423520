import React from 'react';
import {
    shouldShowFolderPane,
    shouldShowFolderPaneAsOverlay,
    toggleFolderPaneExpansion,
} from 'owa-mail-layout';
import { type StateChangeSource } from 'owa-left-pane-layout/lib/types/StateChangeSource';

export default function useMailListItemDragEvents() {
    // Track folder pane state so we can properly show/hide drag and drop UX
    const [isFolderPaneAutoExpanded, setIsFolderPaneAutoExpanded] = React.useState(false);

    const onDragStart = () => {
        // If the folder pane is collapsed, expand it when dragging starts
        // and the folder pane is not an overlay
        if (!shouldShowFolderPane() && !shouldShowFolderPaneAsOverlay()) {
            setIsFolderPaneAutoExpanded(true);
            toggleFolderPaneExpansion(3, false /* persistOnServer */);
        }
    };

    const onDragEnd = React.useCallback(() => {
        // If the folder pane was collapsed originally, collapse again it when dragging ends
        // and the folder pane is not an overlay
        if (isFolderPaneAutoExpanded && !shouldShowFolderPaneAsOverlay()) {
            setIsFolderPaneAutoExpanded(false);
            toggleFolderPaneExpansion(3, false /* persistOnServer */);
        }
    }, [isFolderPaneAutoExpanded]);

    return [onDragStart, onDragEnd];
}
