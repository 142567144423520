import { shouldTableSortByRenewTime } from 'owa-mail-triage-common';
import type {
    ListViewStore,
    TableViewConversationRelation,
    TableQuery,
    MailFolderTableQuery,
    MailListRowDataType,
    TableView,
} from 'owa-mail-list-store';
import {
    getStore as getListViewStore,
    type SortColumn,
    type TableQueryType,
} from 'owa-mail-list-store';
import type ConversationType from 'owa-service/lib/contract/ConversationType';
import type Item from 'owa-service/lib/contract/Item';
import * as trace from 'owa-trace';
import type Message from 'owa-service/lib/contract/Message';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import getTableConversationRelation from 'owa-mail-list-store/lib/utils/getTableConversationRelation';
import mailStore from 'owa-mail-store/lib/store/Store';
import { isFeatureEnabled } from 'owa-feature-flags';

export interface IsSortKeyEqualState {
    listViewStore: ListViewStore;
}

export default function isSortKeyEqual(row: MailListRowDataType, tableView: TableView) {
    let result = false;
    switch (tableView.tableQuery.listViewType) {
        case 0:
            const tableConversationRelation = row.InstanceKey
                ? getTableConversationRelation(row.InstanceKey, tableView.id)
                : undefined;
            if (tableConversationRelation) {
                result = isConversationSortKeyEqual(
                    tableView.tableQuery,
                    tableConversationRelation,
                    row as ConversationType
                );
            }
            break;

        case 1:
            const itemId = (row as Item)?.ItemId?.Id;
            const itemReference = itemId ? mailStore?.items?.get(itemId) : undefined;
            if (itemReference) {
                result = isItemSortKeyEqual(tableView.tableQuery, row as Item, itemReference);
            }
            break;
    }

    return result;
}

export const isConversationSortKeyEqual = function isConversationSortKeyEqual(
    tableQuery: TableQuery,
    tableConversationRelation: TableViewConversationRelation,
    conversation: ConversationType,
    state: IsSortKeyEqualState = { listViewStore: getListViewStore() }
): boolean {
    if (
        !(
            tableQuery.type === 0 ||
            tableQuery.type === 2 ||
            (isFeatureEnabled('grp-loadFolders') && tableQuery.type === 3)
        )
    ) {
        trace.errorThatWillCauseAlert(
            'isConversationSortKeyEqual should not be called on non MailFolderTableQuery type'
        );
        return false;
    }
    const sortBy = (tableQuery as MailFolderTableQuery).sortBy;
    if (!sortBy) {
        throw new Error('SortBy should not be null');
    }
    const isInstanceKeyEqual = tableConversationRelation.instanceKey === conversation.InstanceKey;
    const isDateTimeEqual = areConversationsDateTimeEqual(
        tableQuery,
        tableConversationRelation,
        conversation
    );
    if (!isInstanceKeyEqual || !isDateTimeEqual) {
        // if instanceKey or dateTime are not equal, return false
        return false;
    }
    const conversationItem = state.listViewStore.conversationItems.get(
        tableConversationRelation.id
    );
    switch (sortBy.sortColumn) {
        case 1:
        case 3:
            return true;
        case 5:
            return tableConversationRelation.importance === conversation.Importance;
        case 8:
            return conversationItem?.subject === conversation.ConversationTopic;
        case 7:
            return conversation.Size === conversationItem?.size;
        case 9:
            return areArraysEqual(
                tableConversationRelation.uniqueRecipients,
                conversation.UniqueRecipients
            );
        case 16:
            return (
                tableConversationRelation.copilotInboxScore ===
                conversation.ConversationCopilotInboxScore
            );
        case 15:
            return tableConversationRelation.returnTime === conversation.ReturnTime;
        case 14:
        default:
            trace.trace.warn('isSortKeyEqual: sortColumn not supported ' + sortBy.sortColumn);
            return false;
    }
};

export function isItemSortKeyEqual(
    tableQuery: TableQuery,
    itemTarget: Item,
    itemReference: Item
): boolean {
    if (
        !(
            tableQuery.type == 0 ||
            tableQuery.type === 2 ||
            (isFeatureEnabled('grp-loadFolders') && tableQuery.type === 3)
        )
    ) {
        trace.errorThatWillCauseAlert(
            'isItemSortKeyEqual should not be called on non MailFolderTableQuery type'
        );
        return false;
    }

    const sortBy = (tableQuery as MailFolderTableQuery).sortBy;
    if (!sortBy) {
        throw new Error('SortBy should not be null');
    }

    const isItemIdEqual = !!(
        itemTarget.ItemId?.Id &&
        itemReference.ItemId?.Id &&
        itemTarget.ItemId?.Id === itemReference.ItemId?.Id
    );
    const isDateTimeEqual = areItemsDateTimeEqual(tableQuery, itemTarget, itemReference);
    const isSameItem = isItemIdEqual && isDateTimeEqual;

    // Conditions to ensure we're paging in items at the correct place in the
    // list view. The first item in the response should be the last item in the
    // previous batch.
    if (!isSameItem) {
        return false;
    }

    const itemTargetMessage = getItemAsMessage(itemTarget);
    const itemReferenceMessage = getItemAsMessage(itemReference);

    switch (sortBy.sortColumn) {
        case 1:
            return true;
        case 5:
            return itemTarget.Importance === itemReference.Importance;
        case 8:
            return itemTarget.Subject === itemReference.Subject;
        case 14:
            return areArraysEqual(itemTarget.Categories, itemReference.Categories);
        case 3:
            if (itemTargetMessage && itemReferenceMessage) {
                const itemTargetEmailAddress = itemTargetMessage?.From?.Mailbox?.EmailAddress;
                const itemReferenceEmailAddress = itemReferenceMessage?.From?.Mailbox?.EmailAddress;

                if (
                    itemTargetEmailAddress === undefined ||
                    itemReferenceEmailAddress === undefined
                ) {
                    return false;
                }

                return itemTargetEmailAddress === itemReferenceEmailAddress;
            } else {
                // it won't be the case that one is a message and other is not as the instance keys have already matched
                // so we return true for other item types
                return true;
            }
        case 9:
            return itemTarget.DisplayTo === itemReference.DisplayTo;

        case 7:
            return itemTarget.Size === itemReference.Size;

        case 16:
            if (itemTargetMessage && itemReferenceMessage) {
                return (
                    itemTargetMessage.CopilotInboxScore === itemReferenceMessage.CopilotInboxScore
                );
            } else {
                return true;
            }

        case 15:
            return itemTarget.ReturnTime === itemReference.ReturnTime;

        default:
            trace.trace.warn('isSortKeyEqual: sortColumn not supported ' + sortBy.sortColumn);
            return false;
    }
}

function getItemAsMessage(item: Item): Message | null {
    if (
        item?.ItemClass?.indexOf('IPM.Schedule.Meeting') !== -1 ||
        item?.ItemClass?.indexOf('IPM.Note') !== -1
    ) {
        return item as Message;
    }

    return null;
}

function areItemsDateTimeEqual(
    tableQuery: TableQuery,
    itemTarget: Item,
    itemReference: Item
): boolean {
    if (
        itemTarget?.DateTimeReceived === undefined ||
        itemReference?.DateTimeReceived === undefined
    ) {
        return false;
    }

    // Note: we need to convert the timestamp into date objects for comparison
    // because we've seen issues where the server may return the time in UTC format.
    // We should be resilient on the client against such issues.
    const isDateTimeReceivedEqual =
        new Date(itemTarget.DateTimeReceived).getTime() ===
        new Date(itemReference.DateTimeReceived).getTime();

    if (shouldTableSortByRenewTime(tableQuery)) {
        if (
            itemTarget?.ReceivedOrRenewTime === undefined ||
            itemReference?.ReceivedOrRenewTime === undefined
        ) {
            return false;
        }

        const isReceivedOrRenewTimeEqual =
            new Date(itemTarget.ReceivedOrRenewTime).getTime() ===
            new Date(itemReference.ReceivedOrRenewTime).getTime();

        // If table supports renew time the date time equality
        // is based on both primary (ReceivedOrRenewTime) and secondary (DateTimeReceived) sorts
        return isReceivedOrRenewTimeEqual && isDateTimeReceivedEqual;
    }

    return isDateTimeReceivedEqual;
}

function areConversationsDateTimeEqual(
    tableQuery: TableQuery,
    tableConversationRelation: TableViewConversationRelation,
    conversation: ConversationType
): boolean {
    if (conversation?.LastDeliveryTime === undefined) {
        return false;
    }

    // Note: we need to convert the timestamp into date objects for comparison
    // because we've seen issues where the server may return the time in UTC format.
    // We should be resilient on the client against such issues.
    const isDeliveryTimeEqual =
        new Date(tableConversationRelation.lastDeliveryTimeStamp).getTime() ===
        new Date(conversation.LastDeliveryTime).getTime();

    if (shouldTableSortByRenewTime(tableQuery)) {
        if (conversation?.LastDeliveryOrRenewTime === undefined) {
            return false;
        }

        const isLastDeliveryOrRenewTimeEqual =
            new Date(tableConversationRelation.lastDeliveryOrRenewTimeStamp).getTime() ===
            new Date(conversation.LastDeliveryOrRenewTime).getTime();

        // If table supports renew time the date time equality
        // is based on both primary (LastDeliveryOrRenewTime) and secondary (LastDeliveryTime) sorts
        return isLastDeliveryOrRenewTimeEqual && isDeliveryTimeEqual;
    }

    return isDeliveryTimeEqual;
}

const areArraysEqual = (array1: string[] | undefined, array2: string[] | undefined) => {
    if (!array1 && !array2) {
        return true;
    }

    if (array1?.length !== array2?.length) {
        return false;
    }

    // shouldn't be possible for this to happen, but for type checking
    if (!array1 || !array2) {
        return false;
    }

    // Sort both arrays before testing equality
    array1.sort();
    array2.sort();

    // Check lists of categories are the same
    for (let i = 0; i < array1.length && i < array2.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }

    return true;
};
