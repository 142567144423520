import type { MailboxInfo } from 'owa-client-types';
import { isAccountExplicitLogon } from 'owa-account-shared-mailbox-utils';

/** When Shared Mailbox is opened using Open Another Mailbox option in OWA
 *  or a shared mailbox is added as an account inMonarch mailbox type is UserMailbox.
 *  So, we are checking using explicit logon.*/
export default function isSharedMailboxExplicitLogon(mailboxInfo: MailboxInfo): boolean {
    if (isAccountExplicitLogon(mailboxInfo)) {
        return true;
    }
    return false;
}
