import { getStore } from '../store/store';
import {
    COPILOT_THEME_IMAGE_URL,
    COPILOT_THEME_TIMESTAMP_QUERY_PARAM,
} from '../constants/constants';

export default function getCopilotThemeImageUrl(): string {
    const qsParams = `${COPILOT_THEME_TIMESTAMP_QUERY_PARAM}=${getStore().timestamp}`;
    return `${COPILOT_THEME_IMAGE_URL}?${qsParams}`;
}
