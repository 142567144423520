import { orchestrator } from 'satcheljs';
import onAcquisitionsRemoteRefresh from 'owa-m365-acquisitions/lib/actions/onAcquisitionsRemoteRefresh';
import { updateAppBarTiles } from '../utils/updateAppBarTiles';
import { writeAcquisitionQueries } from '../utils/writeAcquisitionQueries';

/**
 * Runs when MOS3 app service data refreshes. Implies MOS3 is enabled and available.
 */
orchestrator(onAcquisitionsRemoteRefresh, async ({ mailboxInfo }) => {
    // redraw the app bar with the current cached data
    await writeAcquisitionQueries(mailboxInfo);

    // update the AppBarTiles setting which saves stub tiles to improve app bar flicker on boot
    await updateAppBarTiles(mailboxInfo);
});
