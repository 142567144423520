import { createStore } from 'owa-satcheljs';

interface ClipboardUtilsStore {
    clipboardWritable: boolean;
    clipboardReadable: boolean;
}

const defaultStore: ClipboardUtilsStore = {
    clipboardWritable: true,
    clipboardReadable: true,
};

export const getStore = createStore<ClipboardUtilsStore>('owaClipboardUtilsStore', defaultStore);
