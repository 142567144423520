import { OutlookTeachingBubble } from 'owa-lightning-views';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import type { Target } from '@fluentui/react';
import { observer } from 'owa-mobx-react';
import { lightable } from 'owa-lightning-v2';
import { lighted } from 'owa-lightning-core-v2';
import type { LightningProps } from 'owa-lightning-core-v2';
import loc, { formatToArray } from 'owa-localize';
import React from 'react';
import {
    CopilotPrioritizeOnboardedCalloutTitle,
    CopilotPrioritizeOnboardedCalloutDesc,
    CopilotPrioritizeOnboardedCalloutGotItButtonText,
} from './CopilotPrioritizeOnboardedCallout.locstring.json';
import { logUsage, PerformanceDatapoint } from 'owa-analytics';
import { CopilotInboxPriorityIcon } from 'owa-mail-copilot-inbox-shared';

interface CopilotPrioritizeOnboardedCalloutProps extends LightningProps {
    target: Target;
}

const CopilotPrioritizeOnboardedCallout = (props: CopilotPrioritizeOnboardedCalloutProps) => {
    const { target, id } = props;

    let dwellTimeDatapoint: PerformanceDatapoint | undefined = undefined;

    React.useEffect(() => {
        dwellTimeDatapoint = new PerformanceDatapoint(
            'CopilotPrioritizeOnboardedCallout_DwellTime'
        );
    }, []);

    const onGotItButtonClicked = React.useCallback(() => {
        logUsage('CopilotPrioritizeOnboardedCalloutGotItButtonClicked');
        dwellTimeDatapoint?.end();
        lighted(id);
    }, [dwellTimeDatapoint, id]);

    const calloutDescription = React.useMemo(() => {
        return (
            <div>
                {formatToArray(
                    loc(CopilotPrioritizeOnboardedCalloutDesc),
                    <CopilotInboxPriorityIcon priorityOverride="high" scenario="OnboardedCallout" />
                )}
            </div>
        );
    }, []);

    return (
        <OutlookTeachingBubble
            target={target}
            directionalHint={DirectionalHint.rightCenter}
            calloutTitle={loc(CopilotPrioritizeOnboardedCalloutTitle)}
            calloutDescription={calloutDescription}
            primaryButtonText={loc(CopilotPrioritizeOnboardedCalloutGotItButtonText)}
            lightningId={id}
            gotItButtonOnClick={onGotItButtonClicked}
        ></OutlookTeachingBubble>
    );
};

export default lightable(
    observer(CopilotPrioritizeOnboardedCallout, 'CopilotPrioritizeOnboardedCallout')
);
