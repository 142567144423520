import { getCopilotCategoriesList as categoriesList } from '../store';
import { default as getMasterCategoryList } from '../../utils/getMasterCategoryList';
import type { MailboxInfo } from 'owa-client-types';
import type CopilotCategoryType from '../schema/CopilotCategoryType';
import { removeCopilotCategory } from '../../actions/copilotActions';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getItem, setItem, itemExists, type LocalStorageKeys } from 'owa-local-storage';

export default function getCopilotCategoriesList(mailboxInfo: MailboxInfo): CopilotCategoryType[] {
    removeDeletedCategories(mailboxInfo);
    // return copilot categories list
    return categoriesList(mailboxInfo);
}

//Remove copilot categories not existing in master category list
function removeDeletedCategories(mailboxInfo: MailboxInfo) {
    const copilotCategoriesList = categoriesList(mailboxInfo);
    const masterCategoryList = getMasterCategoryList(mailboxInfo);
    //Check for copilot categories not existing in master category list
    const categoriesToRemove = copilotCategoriesList
        .filter(
            copilotCategory =>
                !masterCategoryList.find(category => category.Name === copilotCategory.Name)
        )
        .map(copilotCategory => copilotCategory.Name)
        .filter(copilotName => copilotName !== undefined);
    if (categoriesToRemove?.length > 0) {
        removeCopilotCategory(categoriesToRemove, mailboxInfo);
    }
}

/**
 * Get the copilot category reason for the mailbox
 * @param mailboxInfo the mailbox info for current user
 */
export function getCopilotCategoryReasonStoreMap(mailboxInfo: MailboxInfo): Map<string, string[]> {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const copilotCategoryReasonLocalStoreMap = fetchCopilotCategoryReasonFromLocalStorage(key);
    if (!copilotCategoryReasonLocalStoreMap) {
        return new Map<string, string[]>();
    }

    return copilotCategoryReasonLocalStoreMap;
}

/**
 * Get the copilot category reason from local storage
 * @param key the key to fetch the prompt
 */
function fetchCopilotCategoryReasonFromLocalStorage(
    key: string
): Map<string, string[]> | undefined {
    const localStorageKey: LocalStorageKeys = `mail_copilot_category_reason${key}`;
    try {
        return itemExists(window, localStorageKey)
            ? new Map<string, string[]>(JSON.parse(getItem(window, localStorageKey) as string))
            : undefined;
    } catch (e) {
        return undefined;
    }
}

/**
 * Save the copilot prompt to local storage
 * @param copilotPrompt the copilot prompt object to save
 */
export const saveCopilotCategoryReason = (
    mailboxInfo: MailboxInfo,
    copilotCategoriesMap: Map<string, string[]>
) => {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const localStorageKey: LocalStorageKeys = `mail_copilot_category_reason${key}`;
    setItem(window, localStorageKey, JSON.stringify(Array.from(copilotCategoriesMap.entries())));
};
