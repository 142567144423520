import { createStore } from 'owa-satcheljs';
import type {
    UnifiedGroupsSettingsState,
    UnifiedGroupsSettingsByMailboxInfo,
} from './schema/UnifiedGroupsSettingsState';

export const getUnifiedGroupsSettingsStore = createStore<UnifiedGroupsSettingsByMailboxInfo>(
    'unifiedGroupsSettingsStore',
    {
        unifiedGroupsSettingsByMailboxInfo: new Map<string, UnifiedGroupsSettingsState>(),
    }
);
