import { getGroupFolderRulePermissionStore } from '../groupFolderRulePermissionStore';
import type { GroupFolderAndRulePermission } from '../schema/GroupFolderAndRulePermission';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import type { GroupFolderRulePermission } from '../schema/GroupFolderRulePermissionStore';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export function getGroupFolderAndRulePermissionTable(mailboxInfo: MailboxInfo) {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const folderRulePermission =
        getGroupFolderRulePermissionStore().groupFolderRulePermissionByMailboxInfo.get(accountKey);

    if (!folderRulePermission) {
        const defaultGroupFolderRulePermission: GroupFolderRulePermission = {
            folderRulePermissionTable: new Map<string, GroupFolderAndRulePermission>([]),
        };

        getGroupFolderRulePermissionStore().groupFolderRulePermissionByMailboxInfo.set(
            accountKey,
            defaultGroupFolderRulePermission
        );

        return defaultGroupFolderRulePermission.folderRulePermissionTable;
    }

    return folderRulePermission.folderRulePermissionTable;
}

export function getGroupFolderAndRulePermission(
    mailboxInfo: MailboxInfo,
    groupId: string | null | undefined
): GroupFolderAndRulePermission | null | undefined {
    if (!groupId) {
        return null;
    }

    return getGroupFolderAndRulePermissionTable(mailboxInfo).get(transformGroupSmtp(groupId));
}
