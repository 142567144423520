import { onError } from '@apollo/client/link/error';
import { trace, type TraceErrorObject } from 'owa-trace';
import { logGreyError } from 'owa-analytics';
import { scrubForPii, scrubFileNameForPii } from 'owa-config';
import type { ResolverContext } from 'owa-graph-schema';
import { isNetworkError } from 'owa-errors';

export const onErrorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(error => {
            const context = operation.getContext() as ResolverContext | undefined;

            // Add the queryStack to improve diagnosability
            if (context?.queryStack && error.extensions) {
                error.extensions.queryStack = context.queryStack;
            }

            const { extensions } = error;
            const originalTraceError = error.originalError as TraceErrorObject | undefined;
            if (
                !extensions?.fetchErrorType &&
                !originalTraceError?.fetchErrorType &&
                !(extensions?.code == 'EXTERNAL_SERVICE_ERROR') &&
                !(extensions?.code == 'TRANSIENT_ERROR') &&
                !isNetworkError(error.message)
            ) {
                // This wasn't a known error type we generate. e.g. ServerFailure, RequestTimeout.
                // So we've failed because some other unhandled exception in the resolvers,
                // e.g. in validation, or mapping from OWS to GQL schema.
                // We don't always alert on this, but rather preserve current behavior of
                // allowing the calling scenario decide whether to alert.
                // So we are just adding a logUsage and a debug trace.

                // Troubleshoot Variable "mailboxInfo" cannot be non-input type "MailboxInfoInput".
                let logggingOperationVariable;
                if (error?.message?.indexOf('MailboxInfoInput') > -1) {
                    logggingOperationVariable = scrubForPii(JSON.stringify(operation?.variables));
                }

                logGreyError('onErrorLink_GraphQLError', error, {
                    path: error.path?.toString(),
                    operationName: operation.operationName,
                    code: extensions?.code,
                    operationVariable: logggingOperationVariable,
                    innerMessage: extensions?.InnerMessage,
                    additionalCodes: extensions?.codes?.toString(),
                    locations: extensions?.locations?.toString(),
                    queryStack: scrubFileNameForPii(context?.queryStack),
                });
                trace.warn(
                    `[GraphQL error]: Message: ${error.message}, OperationName:${operation.operationName} Location: ${error.locations?.[0]}, Path: ${error.path}, Code: ${extensions?.code}`
                );
            }
        });
    }
    if (networkError) {
        const context = operation.getContext() as ResolverContext | undefined;
        const traceError = networkError as TraceErrorObject;
        traceError.queryStack = context?.queryStack;
        traceError.gqlPath = operation.operationName;

        // Errors thrown from our links are reported via networkError so log anything unexpected.
        if (!traceError.fetchErrorType && !isNetworkError(traceError.message, traceError)) {
            logGreyError('onErrorLink_NetworkError', traceError);
        }
    }
});
