import type { MailboxInfo } from 'owa-client-types';
import { mapOWSFolderToGql } from 'owa-folder-gql-mappers';
import { getFolderTable, getPrimaryMailFolders } from 'owa-folders';
import type { FolderForestTreeType, MailFolder } from 'owa-graph-schema';
import loc from 'owa-localize';
import type { ActionSource } from 'owa-mail-store';
import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import type FolderInfoResponseMessage from 'owa-service/lib/contract/FolderInfoResponseMessage';
import type FolderResponseShape from 'owa-service/lib/contract/FolderResponseShape';
import folderId from 'owa-service/lib/factory/folderId';
import folderResponseShape from 'owa-service/lib/factory/folderResponseShape';
import getFolderRequest from 'owa-service/lib/factory/getFolderRequest';
import propertyUri from 'owa-service/lib/factory/propertyUri';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import getFolderOperation from 'owa-service/lib/operation/getFolderOperation';
import { lazyOnSelectingAttachmentsSearchFolder } from 'owa-storage-store';
import { lazyRemoveSortByOverride } from 'owa-mail-folder-store';
import selectFolder from '../actions/selectFolder';
import { folderDisplayName } from './AttachmentsSearchFolder.locstring.json';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';

export async function tryLoadAttachmentsSearchFolder(
    attachmentsSearchFolderId: string | undefined,
    mailboxInfo: MailboxInfo,
    shouldFallbackToOpenInboxInNewTab: boolean
) {
    if (attachmentsSearchFolderId && isConsumer(undefined, mailboxInfo)) {
        const attachmentSearchFolder = {
            id: attachmentsSearchFolderId,
            displayName: loc(folderDisplayName),
            mailboxInfo,
        };

        function searchFolderShape(): FolderResponseShape {
            return folderResponseShape({
                BaseShape: 'IdOnly',
                AdditionalProperties: [
                    propertyUri({ FieldURI: 'ParentFolderId' }),
                    propertyUri({ FieldURI: 'FolderClass' }),
                ],
            });
        }

        const response = await getFolderOperation(
            {
                Header: getJsonRequestHeader(),
                Body: getFolderRequest({
                    FolderShape: searchFolderShape(),
                    FolderIds: [folderId({ Id: attachmentsSearchFolderId })],
                }),
            },
            getMailboxRequestOptions(mailboxInfo)
        );
        const folder = (response?.Body?.ResponseMessages?.Items?.[0] as FolderInfoResponseMessage)
            ?.Folders?.[0];
        if (folder) {
            const mailFolder: MailFolder | null = mapOWSFolderToGql(folder, mailboxInfo);
            if (mailFolder) {
                mailFolder.displayName = loc(folderDisplayName);
                mailFolder.childFolderIds = [];
                getFolderTable().set(attachmentsSearchFolderId, mailFolder);
                getPrimaryMailFolders(mailboxInfo);
                lazyRemoveSortByOverride.importAndExecute();
                selectFolder(
                    attachmentSearchFolder.id,
                    'primaryFolderTree' as FolderForestTreeType,
                    'QuickSwitcher' as ActionSource
                );

                const onSelectingAttachmentsSearchFolder =
                    await lazyOnSelectingAttachmentsSearchFolder.import();
                onSelectingAttachmentsSearchFolder(mailboxInfo);
                return;
            }
        }
    }

    if (shouldFallbackToOpenInboxInNewTab) {
        window.open(
            'https://outlook.live.com/mail/?Mode=FreeUpMSQSpace&login_hint=' +
                mailboxInfo.mailboxSmtpAddress,
            '_blank'
        );
    }
}
