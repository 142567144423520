import store from '../store/Store';
import type {
    AccountFeatureName,
    GlobalFeatureName,
    FeatureName,
} from '../store/featureDefinitions';
import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';
import { areFeatureFlagsInitialized } from '../actions/initializeFeatureFlags';
import { type MailboxInfo } from 'owa-client-types';
import isAnonymousFlightingEnabled from './anonymousFlighting';
import getFeatureFlagsIndexerForMailboxInfo from './getFeatureFlagsIndexerForMailboxInfo';
import {
    getCoprincipalAccountForMailboxInfo,
    getGlobalSettingsAccountIndexer,
} from 'owa-account-source-list-store';
import getAccountDiagnosticDataForMailboxInfo from 'owa-account-source-list-store/lib/utils/getAccountDiagnosticDataForMailboxInfo';
import { isTryLookupIndexerFuncSet, tryLookupIndexer } from 'owa-client-types/lib/tryLookupIndexer';
import { checkIfFeatureCheckWouldHaveThrownBeforeAliasLookup } from './checkIfFeatureCheckWouldHaveThrownBeforeAliasLookup';
import { getHostValue } from 'owa-config/lib/getHostValue';
import { isRunningOnWorker } from 'owa-config/lib/isRunningOnWorker';
import { accountKeyForAddAccount } from 'owa-account-source-list-types';
import { logStartGreyError } from 'owa-analytics-start';

export enum TypeOfEarlyAccess {
    UnknownMailbox = 'UnknownMailbox',
    RunningOnWorker = 'RunningOnWorker',
    GlobalSettingsAccount = 'GlobalSettingsAccount',
    RemovedAccount = 'RemovedAccount',
    Other = 'Other',
}

export function getTypeOfEarlyAccess(
    mailboxInfo: MailboxInfo | undefined | null,
    hasAccount: boolean,
    runningOnWorker: boolean,
    isGlobalSettingsAccount: boolean,
    isRemoved: boolean
): TypeOfEarlyAccess {
    if (mailboxInfo && !hasAccount) {
        // How are we getting and using a mailboxInfo which is not in the accounts list?
        return TypeOfEarlyAccess.UnknownMailbox;
    } else if (runningOnWorker) {
        return TypeOfEarlyAccess.RunningOnWorker;
    } else if (!mailboxInfo || isGlobalSettingsAccount) {
        return TypeOfEarlyAccess.GlobalSettingsAccount;
    } else if (isRemoved) {
        return TypeOfEarlyAccess.RemovedAccount;
    }

    return TypeOfEarlyAccess.Other;
}

/**
 * Returns the diagnostic information that could be useful for debugging and should not contain PII,
 * which will allow usage in errors and logs.
 * @param mailboxInfo Optionally specifies the mailbox info for which the diagnostic information is being requested
 */
export function getMailboxDiagnosticInfo(mailboxInfo?: MailboxInfo) {
    const accountDiagnostic = getAccountDiagnosticDataForMailboxInfo(mailboxInfo);
    const globalSettingsIndexer = getGlobalSettingsAccountIndexer(/*throwIfNotInitialized*/ false);
    const tryIndexer = !!mailboxInfo ? tryLookupIndexer(mailboxInfo) : undefined;
    const runningOnWorker = isRunningOnWorker();
    const typeOfAccess = getTypeOfEarlyAccess(
        mailboxInfo,
        accountDiagnostic.hasAccount,
        runningOnWorker,
        !!accountDiagnostic.isGlobalSettingsAccount,
        !!accountDiagnostic.isRemovedAccount
    );

    return {
        hasMailboxInfo: !!mailboxInfo,
        mailboxType: mailboxInfo?.type,
        hasSourceId: !!mailboxInfo?.sourceId,
        hasUserId: !!mailboxInfo?.userIdentity,
        hasSmtp: !!mailboxInfo?.mailboxSmtpAddress,
        rank: mailboxInfo?.mailboxRank,
        diagnosticData: mailboxInfo?.diagnosticData,
        isAnonymous: mailboxInfo?.isAnonymous,
        isRemoved: mailboxInfo?.isRemoved,
        isTryLookupSet: isTryLookupIndexerFuncSet(),
        hasGlobalSettingsIndexer: !!globalSettingsIndexer,
        hasTryIndexer: !!tryIndexer,
        isTryIndexerGSA: tryIndexer === globalSettingsIndexer,
        isTryIndexderUID: tryIndexer === mailboxInfo?.userIdentity,
        runningOnWorker,
        typeOfAccess,
        ...accountDiagnostic,
    };
}

/**
 * Returns the diagnostic information that should be logged to help diagnose issues with feature flags
 * @param feature Specifies the feature for which the diagnostic information is being requested
 * @param mailboxInfo Optionally specifies the mailbox info for which the diagnostic information is being requested
 */
export function getDiagnosticInfo(feature?: FeatureName, mailboxInfo?: MailboxInfo) {
    const featureFlagsIndexer = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
    const tryIndexer = !!mailboxInfo ? tryLookupIndexer(mailboxInfo) : undefined;
    const mailboxDiagnosticsInfo = getMailboxDiagnosticInfo(mailboxInfo);

    return {
        feature,
        hasIndexer: featureFlagsIndexer !== '',
        isGlobalInitalized: areFeatureFlagsInitialized(),
        isTryIndexderFF: tryIndexer === featureFlagsIndexer,
        ...mailboxDiagnosticsInfo,
    };
}

/**
 * Gets the error object that should be alerted on when a feature flag is accessed too early
 * @param typeOfAccess Type of early access
 * @param feature Feature for which access was attempted
 * @returns Error object that should be alerted on
 */
function getFeatureFlagsAccessError(
    typeOfAccess: TypeOfEarlyAccess,
    feature: string
): TraceErrorObject {
    switch (typeOfAccess) {
        case TypeOfEarlyAccess.UnknownMailbox:
            // How are we getting and using a mailboxInfo which is not in the accounts list?
            return getHostValue() == 'teamshub'
                ? new Error('[TeamsHub] Attempted to read feature flag from unknown mailbox')
                : new Error('Attempted to read feature flag from unknown mailbox');

        case TypeOfEarlyAccess.RunningOnWorker:
            // Typically caused by a systemic problem in sync or the action queue
            return new Error('Attempted to read feature flag too early on worker');

        case TypeOfEarlyAccess.GlobalSettingsAccount:
            // Typically a feature specific bug where we are looking at a feature flag too early in boot
            // These issues tend to be specific to the feature flag being checked
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * The error name (message) must be a string literal (no variables in it).
             *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
            return new Error(`Attempted to read global account feature flag ${feature} too early`);

        case TypeOfEarlyAccess.RemovedAccount:
            // Why are we looking at feature flags for a removed account?
            return new Error('Attempted to read secondary account feature flag of removed account');

        default:
            // Unknown remaining issue. These tend to be systemic rather than specific to the feature flag.
            // You most likely are looking at an account(s) that is not yet initialized.
            // Make sure you are not looking at all accounts but only looking at accounts that are COMPLETED.
            return new Error('Attempted to read secondary account feature flag too early');
    }
}

function isFeatureEnabledInternal(
    feature: FeatureName,
    mailboxInfo?: MailboxInfo,
    dontThrowErrorIfNotInitialized?: boolean
): boolean {
    checkIfFeatureCheckWouldHaveThrownBeforeAliasLookup(
        feature,
        mailboxInfo,
        dontThrowErrorIfNotInitialized,
        getDiagnosticInfo
    );

    if (!areFeatureFlagsInitialized(mailboxInfo) && !process.env.JEST_WORKER_ID) {
        if (dontThrowErrorIfNotInitialized || isAnonymousFlightingEnabled()) {
            return false;
        }

        if (accountKeyForAddAccount == getFeatureFlagsIndexerForMailboxInfo(mailboxInfo)) {
            // the mailbox info is associated with an account that is being added
            return false;
        }

        // We are adding this clue to see if the flags were not initialized for a specific mailbox info.
        const info = getDiagnosticInfo(feature, mailboxInfo);

        // We are using different error messages based on the type of bugs we are seeing currently in telemetry
        const error = getFeatureFlagsAccessError(info.typeOfAccess, feature);
        error.additionalInfo = info;

        // If the account is in a bad state, we don't have access to the real feature flags and there's
        // not really an additional user visible error from using defaults.
        if (
            mailboxInfo &&
            getCoprincipalAccountForMailboxInfo(mailboxInfo)?.bootState === 'StartupError'
        ) {
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logStartGreyError('isFeatureEnabled: ' + error.message, error);
        } else {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * The error name (message) must be a string literal (no variables in it).
             *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
            errorThatWillCauseAlert(error);
        }
    }

    const result = store(mailboxInfo).featureFlags.get(feature.toLowerCase()) || false;

    return result;
}

/**
 * Checks a feature flag against the global settings account.
 * @param feature Specifies the feature to be checked
 * @param mailboxInfo (Obsolete) mailbox information for the account to be checked
 * @param dontThrowErrorIfNotInitialized True if an error should not be thrown if the feature flags are not initialized
 * @returns True if the feature is enabled, false otherwise
 */
export default function isFeatureEnabled(
    feature: GlobalFeatureName,
    mailboxInfo?: MailboxInfo,
    dontThrowErrorIfNotInitialized?: boolean
): boolean {
    return isFeatureEnabledInternal(feature, mailboxInfo, dontThrowErrorIfNotInitialized);
}

/**
 * Checks if an account scoped feature is enabled. To identify an account scoped feature, the feature
 * name must be included in the AccountScopedFeatures union in the ./store/featureDefinitions.ts file
 * @param feature Specified the feature to be checked
 * @param mailboxInfo Mailbox within the account to be checked
 * @param dontThrowErrorIfNotInitialized True if an error should not be thrown if the feature flags are not initialized
 * @returns True if the feature is enabled, false otherwise
 */
export function isAccountFeatureEnabled(
    feature: AccountFeatureName,
    mailboxInfo: MailboxInfo,
    dontThrowErrorIfNotInitialized?: boolean
): boolean {
    return isFeatureEnabledInternal(feature, mailboxInfo, dontThrowErrorIfNotInitialized);
}
