import type { MailboxInfo } from 'owa-client-types';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';
import { getUserConfiguration } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getMailboxInfoForPublicFolder } from 'owa-mailbox-info/lib/getMailboxInfoForPublicFolder';

const cachedMailboxInfo: Map<string, MailboxInfo> = new Map();

export default function getPublicFolderMailboxInfo(
    folderId: string | null | undefined
): MailboxInfo {
    const globalAccountMailboxInfo = getGlobalSettingsAccountMailboxInfo();
    if (!folderId) {
        return globalAccountMailboxInfo;
    }
    const publicFolderMailboxInfoForSmtpAddress =
        publicFolderFavoriteStore.folderTable.get(folderId)?.replicaList?.[0] ??
        getUserConfiguration().SessionSettings?.DefaultPublicFolderMailbox;

    if (!publicFolderMailboxInfoForSmtpAddress) {
        return globalAccountMailboxInfo;
    }

    // Save recomputation by storing the PF mailbox info in a map per folder
    if (!cachedMailboxInfo.has(folderId)) {
        // Public folder mailbox is only supported in OWA and primary account
        const mailboxInfo = getMailboxInfoForPublicFolder(
            globalAccountMailboxInfo,
            publicFolderMailboxInfoForSmtpAddress
        );
        cachedMailboxInfo.set(folderId, mailboxInfo);
    }

    return cachedMailboxInfo.get(folderId) ?? globalAccountMailboxInfo;
}
