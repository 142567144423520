import type TableView from '../store/schema/TableView';
import getListViewState from '../selectors/getListViewState';
import getAreAllChildrenSelectedInConversation from '../utils/getAreAllChildrenSelectedInConversation';

/**
 * Gets whether 1 or more items are checkmark-selected in the table
 * @param tableView the table view
 * @return true if 1 or more items are checkmark-selected in table, false otherwise
 */
export default function getTableHasCheckedItems(tableView: TableView): boolean {
    const { isInCheckedMode, isInVirtualSelectAllMode, selectedRowKeys } = tableView;
    // If there is only one selected rowKey, only if it is expanded, check to see if all of its children are selected. If yes, then return true. Else, return false.
    if (selectedRowKeys.size === 1) {
        const selectedRowKey = [...tableView.selectedRowKeys.keys()][0];
        const expandedConversationState = getListViewState().expandedConversationViewState;
        if (expandedConversationState?.expandedRowKey === selectedRowKey) {
            return getAreAllChildrenSelectedInConversation(selectedRowKey, tableView);
        }
    }
    // Return true when in virtual select all mode OR when the tableView is in multi-check mode and at least one item is in selection.
    return isInVirtualSelectAllMode || (isInCheckedMode && selectedRowKeys.size >= 1);
}
