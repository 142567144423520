import type Message from 'owa-service/lib/contract/Message';
import getItemRightsManagementRestrictions from 'owa-mail-store/lib/utils/getItemRightsManagementRestrictions';
import { isAccountFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';

export default function isIRMDownloadAllowed(item: Message, mailboxInfo: MailboxInfo): boolean {
    const IRMRestrictions = getItemRightsManagementRestrictions(item);
    return isAccountFeatureEnabled('snc-editIRM-saveAs', mailboxInfo)
        ? IRMRestrictions.EditAllowed
        : IRMRestrictions.PrintAllowed ||
              IRMRestrictions.CopyAllowed ||
              IRMRestrictions.ForwardAllowed;
}
