import { folderStore } from '../store/store';
import type { MailFolder, Maybe } from 'owa-graph-schema';

export interface FolderTableState {
    folderTable: Map<string, MailFolder>;
}

export const folderTableSelector = {
    folderTable: () => folderStore.folderTable,
};

export function isMessageFolderRoot(folder: MailFolder): boolean {
    return folder.distinguishedFolderType === 'msgfolderroot';
}

/** Returns true if a folder is specified distinguished folder. */
export function isDistinguishedFolder(
    folder: MailFolder | undefined,
    distinguishedFolderId: Maybe<string> | undefined
): boolean {
    // folder is null in search scenario
    return !!folder && folder.distinguishedFolderType == distinguishedFolderId;
}

/** Returns true if a folder is a subfolder of a specified distinguished folder. */
export function isSubFolderOfDistinguishedFolder(
    folder: MailFolder | undefined,
    distinguishedFolderId: Maybe<string> | undefined,
    state: FolderTableState = {
        folderTable: folderStore.folderTable,
    }
): boolean {
    // folder is null in search scenario
    const parentFolderId = !!folder && folder.parentFolderId;

    if (!parentFolderId) {
        // The parentFolderId is null for root folder of the folder trees, which means it's not a subfolder
        return false;
    }

    let parentFolder: MailFolder | undefined = state.folderTable.get(parentFolderId);
    while (parentFolder) {
        if (parentFolder.distinguishedFolderType == distinguishedFolderId) {
            return true;
        } else if (!parentFolder.parentFolderId) {
            return false;
        }
        parentFolder = state.folderTable.get(parentFolder.parentFolderId);
    }

    return false;
}

export const isUnderDistinguishedFolder = isSubFolderOfDistinguishedFolder;

/** Returns true if a folder is a specified distinguished folder or is a subfolder of it.*/
export default function isFolderOrSubFolderOfDistinguishedFolder(
    folder: MailFolder | undefined,
    distinguishedFolderId: Maybe<string> | undefined,
    state: FolderTableState = { folderTable: folderStore.folderTable }
): boolean {
    return (
        isDistinguishedFolder(folder, distinguishedFolderId) ||
        isUnderDistinguishedFolder(folder, distinguishedFolderId, state)
    );
}
