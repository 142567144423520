import type TableView from '../../store/schema/TableView';
import { mutatorAction } from 'satcheljs';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const resetTableViewProperties = mutatorAction(
    'resetTableViewProperties',
    function resetTableViewProperties(tableView: TableView) {
        tableView.isInitialLoadComplete = false;
        tableView.rowKeys = [];
        tableView.isInCheckedMode = false;
        tableView.selectedRowKeys.clear();
    }
);
