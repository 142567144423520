import { registerCreateServiceResponseCallback as registerOwsCreateServiceResponseCallback } from 'owa-service/lib/fetchWithRetry';
import { registerCreateServiceResponseCallback as registerOwsPrimeCreateServiceResponseCallback } from 'owa-ows-gateway/lib/registerCreateServiceResponseCallback';
import { addPerfObserverCallback } from 'owa-performance-observer-manager';
import { registerBundleLoadCallback } from 'owa-bundling-light';
import { trace } from 'owa-trace';
import { hasQueryStringParameter } from 'owa-querystring';
import type { CustomDataType, CustomDataMap } from 'owa-analytics-types';
import { addToTimingMap } from 'owa-performance/lib/utils/timingMap';
import { markTTIFinished } from 'owa-config';

const TTI_PM_QUERYSTRING_KEY = 'ttiPm';

type TtiSource = 'ows' | 'prime' | 'longtask' | 'bundle';
type TttSourceMap = {
    [key in TtiSource]: number;
};
type TtiInfo = {
    tti: number;
    source: TtiSource;
    name?: string;
    customData: CustomDataMap;
} & TttSourceMap;
type TtiCallback = (TtiInfo: TtiInfo) => void;

const CalmDurationInMs = 5000; // 5 seconds
const MaximumNetworkRequests = 2;

let ttiValue: TtiInfo | undefined;
let networkRequestCount = 0;
let lastLongTaskEnd = 0;
let timeoutId: number | undefined;
let trackingTti = false;
const ttiCustomData: CustomDataMap = {};
const ttiCallbacks: TtiCallback[] = [];

export function registerForTti(cb: TtiCallback): void {
    if (ttiValue !== undefined) {
        cb(ttiValue);
    } else {
        ttiCallbacks.push(cb);
    }
}

// tti is defined as 5 seconds (CalmDurationInMs) with
// zero long running tasks and 2 or less network requests
// (MaximumNetworkRequests)
export function trackTti() {
    if (trackingTti) {
        return;
    }
    trackingTti = true;

    trace.verbose('Start tracking tti', 'tti');

    const sourceMap: TttSourceMap = { ows: 0, prime: 0, longtask: 0, bundle: 0 };

    // track long running tasks
    const unregisterLongTask = addPerfObserverCallback(
        'TTI',
        'longtask',
        (list: PerformanceEntryList) => {
            sourceMap.longtask += list.length;
            for (const entry of list) {
                lastLongTaskEnd = Math.max(lastLongTaskEnd, entry.startTime + entry.duration);
            }
            checkForTti('longtask');
        }
    );

    // track network requests going to the BE
    const unregisterOws = registerOwsCreateServiceResponseCallback((p, n) =>
        trackNetworkRequest('ows', p, n)
    );
    const unregisterPrime = registerOwsPrimeCreateServiceResponseCallback((p, n) =>
        trackNetworkRequest('prime', p, n)
    );

    // track bundles
    const unregisterBundle = registerBundleLoadCallback((p, n) =>
        trackNetworkRequest('bundle', p, n)
    );

    function trackNetworkRequest(
        source: TtiSource,
        responsePromise: Promise<Response>,
        name: string | undefined
    ) {
        sourceMap[source]++;
        networkRequestCount++;

        // if a network request puts us over the limit, then cancel the timer
        if (networkRequestCount > MaximumNetworkRequests) {
            self.clearTimeout(timeoutId);
        }

        (async () => {
            // VSO:334252 resulted in an error when `responsePromise` was a value rather than a Promise for a value.
            // Doing this IIFE to make this code more resilient
            try {
                await responsePromise;
            } finally {
                networkRequestsCompleted(source, name);
            }
        })();
    }

    function networkRequestsCompleted(source: TtiSource, name: string | undefined): void {
        networkRequestCount--;

        // if we hit the limit, then check for TTI
        if (networkRequestCount == MaximumNetworkRequests) {
            checkForTti(source, name);
        }
    }

    function checkForTti(source: TtiSource, name?: string) {
        trace.info(`TTI reset after ${source} name:${name || 'unknown'}`, 'tti');
        markTti(`owa-checkForTti-${source}-${sourceMap[source]}`);

        self.clearTimeout(timeoutId);

        const possibleTtiValue: TtiInfo = {
            tti: performance.now(),
            source,
            customData: ttiCustomData,
            name,
            ...sourceMap,
        };
        if (networkRequestCount <= MaximumNetworkRequests) {
            timeoutId = self.setTimeout(() => {
                addToTimingMap('setTimeout', 'checkForTti');
                trace.info(
                    `TTI reached at ${Math.round(possibleTtiValue.tti)} with ${Object.entries(
                        sourceMap
                    )
                        .map(([s, c]) => `${s}:${c}`)
                        .join(',')}`,
                    'tti'
                );
                markTti('TTIReached');
                ttiValue = possibleTtiValue;
                markTTIFinished();
                ttiCallbacks.map(cb => cb(possibleTtiValue));

                // cleanup trackers
                ttiCallbacks.length = 0;
                unregisterOws();
                unregisterPrime();
                unregisterLongTask?.();
                unregisterBundle();
            }, CalmDurationInMs);
        }
    }
}

function markTti(value: string) {
    if (hasQueryStringParameter(TTI_PM_QUERYSTRING_KEY)) {
        self.performance.mark(value);
    }
}

/**This is close to telling whether we have reached tti, but will continue to return false for the 5 seconds
between reaching tti and realizing we reached tti.
*/
export function hasCalculatedTti(): boolean {
    return ttiValue !== undefined;
}

export function setTtiCustomData(key: string, value: CustomDataType) {
    if (!hasCalculatedTti()) {
        ttiCustomData[key] = value;
    }
}
