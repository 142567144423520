import React from 'react';

import type {
    ICalloutContentStyleProps,
    ICalloutContentStyles,
    IContextualMenuItem,
    IContextualMenuItemProps,
    IContextualMenuProps,
} from '@fluentui/react';
import { ContextualMenu, ContextualMenuItemType, DirectionalHint, Icon } from '@fluentui/react';
import type {
    MenuDividerProps,
    MenuItemProps,
    MenuGroupHeaderProps,
    MenuItemCheckboxProps,
    MenuOpenEvents,
    MenuOpenChangeData,
    MenuProps,
} from '@fluentui/react-components';
import {
    MenuItem,
    MenuDivider,
    MenuGroup,
    MenuGroupHeader,
    MenuTrigger,
    Menu,
    MenuList,
    MenuPopover,
    MenuItemCheckbox,
} from '@fluentui/react-components';
import { wrapForcedLayout } from 'owa-performance';
import { useFluent_unstable } from '@fluentui/react-shared-contexts';
import type { VirtualElement } from '@popperjs/core/lib/types';
import type { IStyleFunctionOrObject, Point, Rectangle } from '@fluentui/utilities';

type Trimmedv8MenuProps = Pick<
    IContextualMenuProps,
    | 'onDismiss'
    | 'onItemClick'
    | 'items'
    | 'key'
    | 'hidden'
    | 'onMenuOpened'
    | 'onMenuDismissed'
    | 'directionalHint'
    | 'gapSpace'
    // v9 does not support the below properties, so not shimming them in v9
    | 'shouldFocusOnContainer'
    | 'bounds'
    | 'styles'
    | 'onRestoreFocus'
>;
interface ShimMenuProps extends Trimmedv8MenuProps {
    className?: string;
    target?: string | Element | null | MouseEvent | Point | Rectangle | React.RefObject<Element>;
    calloutProps?: {
        // Default behavior in v9, so ignored
        preventDismissOnScroll?: boolean;
        layerProps?: {
            className?: string;
        };
        styles?: Partial<IStyleFunctionOrObject<ICalloutContentStyleProps, ICalloutContentStyles>>;
    };
    v9Menu?: boolean;
}

const defaultIconRender = (props?: IContextualMenuItemProps) =>
    props?.item.iconProps ? <Icon {...props?.item.iconProps} /> : null;

const dismiss =
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    (onDismiss?: IContextualMenuProps['onDismiss']) => (ev?: any, dismissAll?: boolean) => {
        onDismiss?.(ev, dismissAll);
    };

const shimMenuItemProps = (props: IContextualMenuItem): MenuItemProps => {
    /**
     * TODO: Props to be shimmed:
     * - componentRef
     * - data
     * - href
     *   - target (window when using href)
     *   - rel
     * - itemProps
     * - onRenderContent
     * - keytipProps
     */

    const { onRenderIcon = defaultIconRender } = props;

    //TODO: Better handle props given to menuItem.onRenderIcon
    const icon = onRenderIcon({ item: props } as IContextualMenuItemProps, defaultIconRender);

    return {
        // spread incoming props to propogate ForwardRefComponent<MenuItemProps> properties
        ...props,
        text: props.text ?? props.name,
        'aria-label': props.ariaLabel,
        children: props.text ?? props.name,
        icon,
        secondaryContent: props.secondaryText,
    } as unknown as MenuItemProps;
};

const shimMenuItemCheckboxProps = (props: IContextualMenuItem): MenuItemCheckboxProps => {
    return {
        ...shimMenuItemProps(props),
        name: props.name || 'name',
        value: props.value || 'value',
    };
};

const shimMenuHeaderProps = (props: IContextualMenuItem): MenuGroupHeaderProps => {
    return {
        // Bug 251913: Remove cast to any after FluentUI Slot types are fixed
        children: props.sectionProps?.title as any,
    };
};

const MenuItemShim = (props: IContextualMenuItem) => {
    if (props.onRender) {
        return (
            <React.Fragment key={props.key}>
                {props.onRender(
                    props,
                    dismiss(props.onDismiss as IContextualMenuProps['onDismiss'])
                )}
            </React.Fragment>
        );
    }

    /**
     * TODO:
     * - Handle props.itemType === ContextualMenuItemType.Header
     */

    if (props.itemType === ContextualMenuItemType.Divider) {
        const shimProps = shimMenuItemProps(props) as MenuDividerProps;
        return <MenuDivider {...shimProps} />;
    }

    /**
     * TODO: Use and audit all props on sectionProps
     */
    if (props.itemType === ContextualMenuItemType.Section) {
        const shimProps = shimMenuHeaderProps(props);
        return (
            <MenuGroup>
                {shimProps.children && <MenuGroupHeader>{shimProps.children}</MenuGroupHeader>}
                {(props.sectionProps?.items ?? props.items ?? props.subMenuProps?.items)?.map(
                    (item, index, arr) => (
                        // eslint-disable-next-line react/jsx-key  -- (https://aka.ms/OWALintWiki) - The `key` prop is part of `item`
                        <MenuItemShim
                            {...item}
                            aria-posinset={index + 1}
                            aria-setsize={arr.length}
                        />
                    )
                )}
            </MenuGroup>
        );
    }

    if (props.canCheck) {
        const shimProps = shimMenuItemCheckboxProps(props);
        return <MenuItemCheckbox {...shimProps} />;
    }

    // Nested Menu to handle submenus
    // TODO: Audit everything is handled
    const subMenuItems = props.items ?? props.subMenuProps?.items;
    if (subMenuItems?.length) {
        const shimProps = shimMenuItemProps(props);

        return (
            <Menu>
                <MenuTrigger>
                    <MenuItem {...shimProps} hasSubmenu={true} />
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {subMenuItems.map(item => (
                            // eslint-disable-next-line react/jsx-key  -- (https://aka.ms/OWALintWiki) - The `key` prop is part of `item`
                            <MenuItemShim {...item} />
                        ))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        );
    }

    const shimProps = shimMenuItemProps(props);
    return <MenuItem {...shimProps} />;
};

function useShimPositioning({
    target,
    directionalHint,
    gapSpace,
}: ShimMenuProps): MenuProps['positioning'] {
    const { targetDocument = document } = useFluent_unstable();

    function createVirtualElement(
        left: number,
        top: number,
        right: number = left + 1,
        bottom: number = top + 1
    ): VirtualElement {
        const rect = {
            top,
            left,
            bottom,
            right,
            x: left,
            y: top,
            height: bottom - top,
            width: right - left,
        };
        return {
            getBoundingClientRect(): DOMRect {
                return {
                    ...rect,
                    toJSON() {
                        return rect;
                    },
                };
            },
        };
    }
    const positioning: MenuProps['positioning'] = {};

    // Target
    if (target === null || target === undefined || target instanceof HTMLElement) {
        positioning.target = target as null | undefined | HTMLElement;
    } else if (target instanceof Element) {
        positioning.target = target as VirtualElement;
    } else if (typeof target === 'string') {
        positioning.target = targetDocument.querySelector(target);
    } else if (target instanceof MouseEvent) {
        positioning.target = createVirtualElement(target.clientX, target.clientY);
    } else if ('left' in target && 'top' in target) {
        positioning.target = createVirtualElement(target.left ?? 0, target.top ?? 0);
    } else if ('x' in target && 'y' in target) {
        positioning.target = createVirtualElement(target.x ?? 0, target.y ?? 0);
    } else if ('current' in target) {
        positioning.target = {
            getBoundingClientRect() {
                return wrapForcedLayout('Menu', () =>
                    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                     * This is baseline exception, if you edit this file you need to fix this exception.
                     *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
                    target.current?.getBoundingClientRect()
                );
            },
        } as VirtualElement;
    }

    // directionalHint and gapSpace
    switch (directionalHint) {
        case DirectionalHint.topLeftEdge:
        case DirectionalHint.topCenter:
        case DirectionalHint.topRightEdge:
        case DirectionalHint.topAutoEdge:
            positioning.position = 'above';
            positioning.offset = gapSpace;
            break;
        case DirectionalHint.bottomLeftEdge:
        case DirectionalHint.bottomCenter:
        case DirectionalHint.bottomRightEdge:
        case DirectionalHint.bottomAutoEdge:
            positioning.position = 'below';
            positioning.offset = gapSpace;
            break;
        case DirectionalHint.leftTopEdge:
        case DirectionalHint.leftCenter:
        case DirectionalHint.leftBottomEdge:
            positioning.position = 'before';
            positioning.offset = gapSpace;
            break;
        case DirectionalHint.rightTopEdge:
        case DirectionalHint.rightCenter:
        case DirectionalHint.rightBottomEdge:
            positioning.position = 'after';
            positioning.offset = gapSpace;
            break;
    }
    switch (directionalHint) {
        case DirectionalHint.leftTopEdge:
        case DirectionalHint.rightTopEdge:
            positioning.align = 'top';
            break;
        case DirectionalHint.leftBottomEdge:
        case DirectionalHint.rightBottomEdge:
            positioning.align = 'bottom';
            break;
        case DirectionalHint.topLeftEdge:
        case DirectionalHint.bottomLeftEdge:
            positioning.align = 'start';
            break;
        case DirectionalHint.topRightEdge:
        case DirectionalHint.bottomRightEdge:
            positioning.align = 'end';
            break;
        case DirectionalHint.topCenter:
        case DirectionalHint.topAutoEdge:
        case DirectionalHint.bottomCenter:
        case DirectionalHint.bottomAutoEdge:
        case DirectionalHint.leftCenter:
        case DirectionalHint.rightCenter:
            positioning.align = 'center';
            break;
    }

    return positioning;
}

function MenuShim(props: ShimMenuProps) {
    const { className, onDismiss, items, hidden, onMenuOpened, onMenuDismissed } = props;
    const onOpenChange = React.useCallback(
        (ev: MenuOpenEvents, { open }: MenuOpenChangeData) => {
            if (!open) {
                onDismiss?.(ev as any);
                onMenuDismissed?.(props);
            } else {
                onMenuOpened?.(props);
            }
        },
        [onDismiss, onMenuDismissed, onMenuOpened]
    );

    const positioning = useShimPositioning(props);
    return (
        <Menu onOpenChange={onOpenChange} positioning={positioning} open={!hidden}>
            <MenuPopover className={className ?? props.calloutProps?.layerProps?.className}>
                <MenuList>
                    {items.map(item => (
                        // eslint-disable-next-line react/jsx-key  -- (https://aka.ms/OWALintWiki) The `key` prop is part of `item`
                        <MenuItemShim {...item} onDismiss={onDismiss} />
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}

export function FluentMenu(props: ShimMenuProps) {
    if (props.v9Menu) {
        return <MenuShim {...props} />;
    } else {
        return <ContextualMenu {...props} />;
    }
}
