export function memoize<TArgs extends any[], TReturn>(
    func: (...args: TArgs) => TReturn,
    resolver: (...args: TArgs) => string
): (...args: TArgs) => TReturn {
    const cache: Map<string, TReturn> = new Map();
    return function (this: any, ...args: TArgs) {
        const key = resolver(...args);
        if (!cache.has(key)) {
            cache.set(key, func.apply(this, args));
        }
        return cache.get(key) as TReturn;
    };
}
