import { LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailContentUtils" */ './lazyIndex'),
    { name: 'MailContentUtils' }
);

export const lazyCreateMailResponseBodyContent = new LazyImport(
    lazyModule,
    m => m.createMailResponseBodyContent
);

export const lazyCreateQuotedBodyForPstOperation = new LazyImport(
    lazyModule,
    m => m.createQuotedBodyForPstOperation
);
