import { LazyImport, LazyModule } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailComposeControlSections"*/ './lazyIndex'),
    { name: 'MailComposeControlSections' }
);

export const lazyGetSensitivitySections = new LazyImport(lazyModule, m => m.getSensitivitySections);
export const lazyGetProtectEncryptSections = new LazyImport(
    lazyModule,
    m => m.getProtectEncryptSections
);
export const lazyGetSignatureSections = new LazyImport(lazyModule, m => m.getSignatureSections);
export const lazyGetEditorSections = new LazyImport(lazyModule, m => m.getEditorSections);
export const lazyGetFluidHeroSections = new LazyImport(lazyModule, m => m.getFluidHeroSections);
export const lazyGetTemplateSections = new LazyImport(lazyModule, m => m.getTemplateSections);
