import { owaComputedFn } from 'owa-computed-fn';
import { getCoprincipalMailboxInfo } from 'owa-account-source-list-store';
import { IsShareableCRUDEnabled } from 'owa-groups-shared-store/lib/utils/shareableCRUDFlight';
import { lazyCreateGroup } from 'owa-group-create-integration';
import { lazyCreateGroupV2 } from 'owa-group-create-integration-v2';
import {
    lazyLoadGroupAccountSources,
    lazyDoesGroupSmtpExistInLeftNav,
} from 'owa-group-mail-left-nav-actions';
import { initializeUserSettings } from 'owa-groups-adaptors';
import { lazyGroupsDiscover, UserType } from 'owa-groups-discover-integration';
import { getCurrentCulture, getCurrentLanguage } from 'owa-localize';
import { lazySelectGroup } from 'owa-mail-folder-forest-actions/lib/lazyFunctions';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import type { RibbonControlDefinitionProps } from 'owa-mail-ribbon-utils';
import { getAccountScopeUserSettings } from 'owa-session-store';
import type { EnterpriseGroup, GroupInfo } from 'owa-groups-discover-integration';
import type { MailboxInfo } from 'owa-client-types';
import { lazyOpenGroupsPanel } from 'owa-groups-panel-actions';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';

const onGroupCreated = (mailboxInfo: MailboxInfo, groupSmtpAddress: string | undefined) => {
    if (groupSmtpAddress) {
        reloadAndSelectGroup(mailboxInfo, groupSmtpAddress);
    }
};

const getOnGroupCreated = owaComputedFn(function getOnGroupCreated(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string | undefined
) {
    return () => onGroupCreated(mailboxInfo, groupSmtpAddress);
});

export async function openCreateGroup(props: RibbonControlDefinitionProps) {
    const mailboxInfo = getRibbonMailboxInfo(props.mailboxInfo);

    if (IsShareableCRUDEnabled(mailboxInfo)) {
        const createGroup = await lazyCreateGroupV2.import();
        const sessionSettings = getAccountScopeUserSettings(mailboxInfo).SessionSettings;

        if (sessionSettings?.ExternalDirectoryUserGuid) {
            createGroup(
                sessionSettings.ExternalDirectoryUserGuid,
                getCurrentLanguage(),
                getCurrentCulture(),
                'Ribbon',
                mailboxInfo,
                getOnGroupCreated(mailboxInfo, undefined /* groupSmtpAddress */)
            );
        }
    } else {
        const createGroup = await lazyCreateGroup.import();
        createGroup(
            isConsumer(undefined, mailboxInfo) ? UserType.Consumer : UserType.Enterprise,
            getCurrentCulture(),
            'Ribbon',
            mailboxInfo,
            getOnGroupCreated(mailboxInfo, undefined /* groupSmtpAddress */)
        );
    }
}

const onGroupsDiscoverClosed = (
    mailboxInfo: MailboxInfo,
    group: GroupInfo | EnterpriseGroup | null
) => {
    if (group) {
        const groupSmtpAddress = group.smtpAddress.toLowerCase();
        reloadAndSelectGroup(mailboxInfo, groupSmtpAddress);
    }
};

const getOnGroupsDiscoverClosed = owaComputedFn(function getOnGroupsDiscoverClosed(
    mailboxInfo: MailboxInfo,
    group: GroupInfo | EnterpriseGroup | null
) {
    return () => onGroupsDiscoverClosed(mailboxInfo, group);
});

export const openDiscoverGroups = async (props: RibbonControlDefinitionProps) => {
    const discoverGroups = await lazyGroupsDiscover.import();
    const moduleMailboxInfo = getRibbonMailboxInfo(props.mailboxInfo);
    const accountMailboxInfo = getCoprincipalMailboxInfo(moduleMailboxInfo);
    const userSmtp = accountMailboxInfo
        ? getAccountScopeUserSettings(accountMailboxInfo).SessionSettings?.UserEmailAddress
        : undefined;

    if (userSmtp) {
        initializeUserSettings(userSmtp, getCurrentCulture());
        discoverGroups(
            UserType.Enterprise,
            getCurrentCulture(),
            'MailRibbon',
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            accountMailboxInfo!,
            getOnGroupsDiscoverClosed(moduleMailboxInfo, null /* group */)
        );
    }
};

export function openGroupsPanel() {
    lazyOpenGroupsPanel.importAndExecute('MailRibbon');
}

const reloadAndSelectGroup = async (mailboxInfo: MailboxInfo, groupSmtpAddress: string) => {
    const doesExistInLeftNav = await lazyDoesGroupSmtpExistInLeftNav.import();
    const doesExist = doesExistInLeftNav(mailboxInfo, groupSmtpAddress);
    if (!doesExist) {
        // joined group, reload left nav
        lazyLoadGroupAccountSources.importAndExecute(mailboxInfo);
    }

    lazySelectGroup.importAndExecute(groupSmtpAddress, 'groups' /* treeType */, mailboxInfo);
};
