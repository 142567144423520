export const jumpToButtonLabel = "zWrEib";
export const goButtonLabel = "ereW3b";
export const jumpToInputPlaceholder = "GRq3nb";
export const importanceNormal = "jBI75";
export const importanceLow = "GWeH0c";
export const importanceHigh = "ud1qWb";
export const dateLastWeek = "ENpzEe";
export const dateLastMonth = "spTlbe";
export const dateLastYear = "fQpqDb";
export const sizeTinyOption = "MiY0Ke";
export const sizeSmallOption = "pILUdd";
export const sizeMediumOption = "dSRdfe";
export const sizeLargeOption = "fFV$yc";
export const sizeVeryLargeOption = "Nl5Mie";
export const sizeHugeOption = "ToZsSb";
export const sizeEnormousOption = "Qu198";
export default {jumpToButtonLabel,goButtonLabel,jumpToInputPlaceholder,importanceNormal,importanceLow,importanceHigh,dateLastWeek,dateLastMonth,dateLastYear,sizeTinyOption,sizeSmallOption,sizeMediumOption,sizeLargeOption,sizeVeryLargeOption,sizeHugeOption,sizeEnormousOption};