import React from 'react';
import { App } from 'owa-application';
import { NativeContextMenu, useInitializeNativeContextMenuEvents } from 'native-context-menu';
import { SkipLinkControl } from 'owa-skip-link';
import type { SearchBoxContainerHandle } from 'owa-search';
import { getBootstrapOptions } from '../optionsRegistry';
import { observer } from 'owa-mobx-react';
import { OwaSuiteHeader } from 'owa-suite-header';
import { Module } from 'owa-workloads-module';
import { lazySetupAppModuleKeys } from './lazy/lazyFunctions';
import { useLazyKeydownHandler } from 'owa-hotkeys';
import { RebootModal } from 'owa-app-reboot';
import { NovaSharedDialogs } from 'owa-nova-shared-dialogs';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { ProjectionPopoutHost } from 'owa-projection-popout';
import { getCurrentModule } from 'owa-app-module-store';
import { RestoreItemsDialog } from 'owa-freeze-dry';
import { NovaContextProvider } from 'owa-nova';
import { AppBar, isAppBarEnabled } from 'owa-appbar';
import { AppRibbon } from 'owa-app-ribbon';
import { AppModuleContainer } from './AppModuleContainer';
import { AppBackground } from 'owa-app-background';
import { MsalAuthInfoBar } from 'owa-app-msal-messagebar';
import { DialogHost } from 'owa-dialog/lib/components/DialogHost';
import { SignInStateChangedInfoBar } from 'owa-app-signinstatechanged-infobar';

export interface AppModuleProps {
    wrapSuiteHeader?: (suiteHeader: React.ReactNode) => React.ReactNode;
}

export default observer(function AppModule(props: AppModuleProps) {
    useLazyKeydownHandler(undefined, lazySetupAppModuleKeys.importAndExecute, undefined);

    // we will default to the mail module
    const currentModule = getCurrentModule() || Module.Mail;
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    const appModuleProps = getBootstrapOptions(currentModule)!.appModuleProps;
    const searchBoxRef = React.useRef<SearchBoxContainerHandle>(null);
    const isSkiplinkEnabled = appModuleProps.isSkiplinkEnabled;

    const renderSkipLinkControl = React.useCallback(() => {
        return isSkiplinkEnabled ? <SkipLinkControl currentModule={currentModule} /> : undefined;
    }, [isSkiplinkEnabled, currentModule]);

    const headerInfo = appModuleProps.getHeaderInfo?.(searchBoxRef);
    const headerProps = headerInfo?.props;
    if (headerProps && props.wrapSuiteHeader) {
        headerProps.wrapSuiteHeader = props.wrapSuiteHeader;
    }
    const renderHeader = React.useCallback(() => {
        const OverrideHeader = appModuleProps.overrideHeader?.();
        return isHostAppFeatureEnabled('hideAppSuiteHeader') ? undefined : OverrideHeader ? (
            <OverrideHeader />
        ) : headerProps ? (
            <OwaSuiteHeader {...headerProps} />
        ) : undefined;
    }, [
        currentModule,
        headerProps?.searchAlignmentWidth,
        headerProps?.enableResponsiveLayout,
        headerInfo?.changes,
    ]);

    useInitializeNativeContextMenuEvents();

    return (
        <NovaContextProvider>
            <AppBackground />
            <App renderHeader={renderHeader} renderSkipLinkControl={renderSkipLinkControl}>
                {isAppBarEnabled() && <AppBar />}
                {appModuleProps.ribbonProps ? (
                    <AppModuleContainer>
                        <AppRibbon {...appModuleProps.ribbonProps} />
                        <SignInStateChangedInfoBar />
                        <MsalAuthInfoBar />
                        {appModuleProps.renderModule(searchBoxRef)}
                    </AppModuleContainer>
                ) : (
                    appModuleProps.renderModule(searchBoxRef)
                )}
                {isFeatureEnabled('fwk-reboot-notif') && <RebootModal />}
                <NovaSharedDialogs />
                <ProjectionPopoutHost />
                {isFeatureEnabled('fwk-freezeDry') && <RestoreItemsDialog />}
            </App>
            {isFeatureEnabled('cmp-dialogs-no-new-root') && <DialogHost />}
            {isFeatureEnabled('fwk-contextMenu') && <NativeContextMenu />}
        </NovaContextProvider>
    );
}, 'AppModule');
