import type { ModulesEnabledForAppBootstrap } from 'owa-workloads-module';
import type { CalendarReadFormInitializationConfig } from 'owa-calendar-helpers-types/lib/CalendarReadFormInitializationConfig';
import type { CalendarComposeInitializationConfig } from 'owa-calendar-helpers-types/lib/CalendarComposeInitializationConfig';
import type { MailboxInfo } from 'owa-client-types';

export interface FreezeDryItemBase {
    type: FreezeDryItemTypes;
    freezeDryId?: string; // This is used to identify a given freezeDry item between the client-web & client-native-host in order to restore the window position
}

export interface MainWindowState {
    timestamp: number;
    urlPathAndQuery: string | undefined;
    rowKey: string | undefined;
    folderId: string | undefined;
    module: ModulesEnabledForAppBootstrap | undefined;
    focusedViewFilter: number | undefined;
    globalItemId: string | undefined;
}

export interface PopoutMailData {
    mailboxInfo: MailboxInfo | undefined;
    id: string | undefined;
}

export interface FreezeDryMailItem extends FreezeDryItemBase {
    type: FreezeDryItemTypes.MailItems;
    data: PopoutMailData;
}

export interface FreezeDryMailComposeItem extends FreezeDryItemBase {
    type: FreezeDryItemTypes.MailComposeItems;
    data: PopoutMailData;
}

export interface FreezeDryCalendarItem extends FreezeDryItemBase {
    type: FreezeDryItemTypes.CalendarItems;
    config: CalendarReadFormInitializationConfig;
}

export interface FreezeDryCalendarComposeItem extends FreezeDryItemBase {
    type: FreezeDryItemTypes.CalendarComposeItems;
    config: CalendarComposeInitializationConfig;
}

export type MainWindow = MainWindowState;
export type MailItems = FreezeDryMailItem[];
export type MailComposeItems = FreezeDryMailComposeItem[];
export type CalendarItems = FreezeDryCalendarItem[];
export type CalendarComposeItems = FreezeDryCalendarComposeItem[];

export type FreezeDryItem =
    | MainWindow
    | MailItems
    | MailComposeItems
    | CalendarItems
    | CalendarComposeItems;

export enum FreezeDryItemTypes {
    MainWindow,
    MailItems,
    MailComposeItems,
    CalendarItems,
    CalendarComposeItems,
}

export enum EventTypes {
    Restore,
    Persist,
}

export enum RestoreMotive {
    SettingsAsk,
    SettingsAlways,
    ScrollPosition,
    RecallFeature,
    None,
}

export interface FdEventContext {
    restoreMotive: RestoreMotive;
}
