import type NativeadsUpsellEffectState from './schema/NativeadsUpsellEffectState';
import { createStore } from 'owa-satcheljs';

const nativeadsUpsellEffectState: NativeadsUpsellEffectState = {
    isEffective: false,
};

export default createStore<NativeadsUpsellEffectState>(
    'nativeadsUpsellEffectState',
    nativeadsUpsellEffectState
);
