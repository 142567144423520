import { getApplicationSettings } from 'owa-application-settings';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isOfflinePeopleDataViaPDVEnabled } from 'owa-offline-sync-feature-flags';

export const isPeopleAppEnabled = () => {
    // Only enable Jana/People App with PDV enabled, new LPE enabled and monarch settings enabled for primary account
    // PDV enabling means monarch uses offline data
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    return (
        isOfflinePeopleDataViaPDVEnabled(mailboxInfo) &&
        getApplicationSettings('Jana').peopleNovaPeopleApp &&
        getApplicationSettings('Jana').lpeNova
    );
};
