import type { Module } from 'owa-workloads-module';
import type { AppBootstrapOptions } from './types/AppBootstrapOptions';
import type { RouteGenerator } from 'owa-router';
import { getCurrentModule } from 'owa-app-module-store';
import { updateModuleForVdirChange } from './utils/updateModuleForVdirChange';

const moduleToMainComponentMap: {
    [P in Module]?: AppBootstrapOptions;
} = {};
const moduleToRouteGenerator: {
    [P in Module]?: RouteGenerator;
} = {};

export function registerBootstrapOptions(options: AppBootstrapOptions): void {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    const appModuleProps = options.appModuleProps!;
    const mod = appModuleProps.module;
    if (options.routerOptions) {
        options.routerOptions.routeGenerator.then(generator => {
            moduleToRouteGenerator[mod] = generator;
        });
        options.routerOptions.setModuleForVdir = () => updateModuleForVdirChange(options);

        // We only need to register the global route generator once
        if (Object.keys(moduleToMainComponentMap).length == 0) {
            options.routerOptions.routeGenerator = options.routerOptions.routeGenerator.then(
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                 * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                 *	> Forbidden non-null assertion.
                 *	> Forbidden non-null assertion. */
                () => () => moduleToRouteGenerator[getCurrentModule()!]!()
            );
        }
    }

    moduleToMainComponentMap[mod] = options;
}

export function getBootstrapOptions(mod: Module): AppBootstrapOptions | undefined {
    return moduleToMainComponentMap[mod];
}
