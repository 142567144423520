import { getStore, initialStore } from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { MAIN_WINDOW_ID } from '../utils/constants';

/**
 * When user clicks to see all folders of any mailbox (primary or archive) then this action is dispatched
 * We only use MAIN_WINDOW_ID here because we are not using projection tab in this case
 */
export const onViewAllMenuClickedMutator = mutatorAction(
    'onViewAllMenuClickedMutator',
    (mailboxInfo: MailboxInfo) => {
        // reset text in search box
        getStore().findTextMap.set(
            MAIN_WINDOW_ID,
            initialStore.findTextMap.get(MAIN_WINDOW_ID) ?? ''
        );
        getStore().moveToSharedFolderRootId = mailboxInfo.mailboxSmtpAddress;
        // resets new folder view state to initial value
        getStore().newFolderViewStateMap.set(
            MAIN_WINDOW_ID,
            initialStore.newFolderViewStateMap.get(MAIN_WINDOW_ID) ?? null
        );
        // show all folders
        getStore().shouldShowAllFolders = true;
    }
);
