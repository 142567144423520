import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailListEmptyState"*/ './lazyIndex'),
    { name: 'MailListEmptyState' }
);

// components
export { default as MailListShimmerState } from './components/MailListShimmerState';

// lazy components
export const MailListEmptyState = createLazyComponent(lazyModule, m => m.MailListEmptyState);
