import { type CachedAcquisitionsState } from './schema/cachedAcquisitionsState';
import { LoadState, NetworkCallState } from './schema/cachedAcquisitionsState';
import { createStore } from 'owa-satcheljs';

const store: CachedAcquisitionsState = createStore<CachedAcquisitionsState>(
    'cachedAcquisitionsState',
    {
        loadState: LoadState.Unloaded,
        networkCallState: NetworkCallState.Unqueued,
    }
)();

type MultiAccountCachedAcquisitionsStateStore = Map<string, CachedAcquisitionsState>;
const initialStore: MultiAccountCachedAcquisitionsStateStore = new Map<
    string,
    CachedAcquisitionsState
>();

const multiAccountStore: MultiAccountCachedAcquisitionsStateStore =
    createStore<MultiAccountCachedAcquisitionsStateStore>(
        'multiAccountCachedAcquisitionsStateStore',
        initialStore
    )();

export { store, multiAccountStore };
