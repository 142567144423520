import type { RibbonFlyoutAnchorProps } from 'owa-acui';
import { owaComputedFn } from 'owa-computed-fn';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonGroupId } from 'owa-ribbon-ids';

export const getMLRViewTabCommonGroupFlyout = owaComputedFn(function getMLRViewTabCommonGroupFlyout(
    _groupId: RibbonGroupId,
    _props: ReadOnlyRibbonControlDefProps
): RibbonFlyoutAnchorProps | undefined {
    // View tab has no commonGroupFlyouts
    return undefined;
});
