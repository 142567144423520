import { getRibbonMailboxInfo } from './getRibbonMailboxInfo';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import ClockAlarm from 'owa-fluent-icons-svg/lib/icons/ClockAlarmRegular';
import AddSquareRegular from 'owa-fluent-icons-svg/lib/icons/AddSquareRegular';
import AccessibilityCheckmark from 'owa-fluent-icons-svg/lib/icons/AccessibilityCheckmarkRegular';
import AppFolderRegular from 'owa-fluent-icons-svg/lib/icons/AppFolderRegular';
import AppsAddIn from 'owa-fluent-icons-svg/lib/icons/AppsAddInRegular';
import { default as ArrowHookUpLeft } from 'owa-fluent-icons-svg/lib/icons/ArrowHookUpLeftRegular';
import ArrowClockwiseRegular from 'owa-fluent-icons-svg/lib/icons/ArrowClockwiseRegular';
import ArrowRepeatAllOff from 'owa-fluent-icons-svg/lib/icons/ArrowRepeatAllOffRegular';
import ArrowRepeatAll from 'owa-fluent-icons-svg/lib/icons/ArrowRepeatAllRegular';
import Undo from 'owa-fluent-icons-svg/lib/icons/ArrowUndoRegular';
import Broom from 'owa-fluent-icons-svg/lib/icons/BroomRegular';
import Classification from 'owa-fluent-icons-svg/lib/icons/ClassificationRegular';
import ClipboardDataBar from 'owa-fluent-icons-svg/lib/icons/ClipboardDataBarRegular';
import ClipboardPaste from 'owa-fluent-icons-svg/lib/icons/ClipboardPasteRegular';
import Clock from 'owa-fluent-icons-svg/lib/icons/ClockRegular';
import CloudArrowDown from 'owa-fluent-icons-svg/lib/icons/CloudArrowDownRegular';
import CloudOff from 'owa-fluent-icons-svg/lib/icons/CloudOffRegular';
import Color from 'owa-fluent-icons-svg/lib/icons/ColorRegular';
import Delete from 'owa-fluent-icons-svg/lib/icons/DeleteRegular';
import FlashRegular from 'owa-fluent-icons-svg/lib/icons/FlashRegular';
import FolderSwap from 'owa-fluent-icons-svg/lib/icons/FolderSwapRegular';
import LockClosed from 'owa-fluent-icons-svg/lib/icons/LockClosedRegular';
import MailListRegular from 'owa-fluent-icons-svg/lib/icons/MailListRegular';
import MailMultipleRegular from 'owa-fluent-icons-svg/lib/icons/MailMultipleRegular';
import Mail from 'owa-fluent-icons-svg/lib/icons/MailRegular';
import MailSettings from 'owa-fluent-icons-svg/lib/icons/MailSettingsRegular';
import Note from 'owa-fluent-icons-svg/lib/icons/NoteRegular';
import PaintBrush from 'owa-fluent-icons-svg/lib/icons/PaintBrushRegular';
import PanelLeftFilled from 'owa-fluent-icons-svg/lib/icons/PanelLeftFilled';
import PanelLeftFocusRightFilled from 'owa-fluent-icons-svg/lib/icons/PanelLeftFocusRightFilled';
import PanelLeftRegular from 'owa-fluent-icons-svg/lib/icons/PanelLeftRegular';
import PeopleCommunity from 'owa-fluent-icons-svg/lib/icons/PeopleCommunityRegular';
import Unpin from 'owa-fluent-icons-svg/lib/icons/PinRegular';
import Send from 'owa-fluent-icons-svg/lib/icons/SendRegular';
import Settings from 'owa-fluent-icons-svg/lib/icons/SettingsRegular';
import SpeakerMuteRegular from 'owa-fluent-icons-svg/lib/icons/SpeakerMuteRegular';
import TextDensityRegular from 'owa-fluent-icons-svg/lib/icons/TextDensityRegular';
import ZoomInRegular from 'owa-fluent-icons-svg/lib/icons/ZoomInRegular';
import TextCollapseRegular from 'owa-fluent-icons-svg/lib/icons/TextCollapseRegular';
import TextExpandRegular from 'owa-fluent-icons-svg/lib/icons/TextExpandRegular';
import WindowFilled from 'owa-fluent-icons-svg/lib/icons/WindowFilled';
import WeatherSunnyRegular from 'owa-fluent-icons-svg/lib/icons/WeatherSunnyRegular';
import WeatherMoonRegular from 'owa-fluent-icons-svg/lib/icons/WeatherMoonRegular';
import { CalendarTodoRegular } from 'owa-ribbon-brand-icons/lib/icons/CalendarTodoRegular';
import loc from 'owa-localize';
import AccessibilityCheckerStrings from 'owa-locstrings/lib/strings/acccheckermenuitemtext.locstring.json';
import ChangeNoteColorStrings from 'owa-locstrings/lib/strings/changenotecolormenutext.locstring.json';
import CommandBarStrings from 'owa-locstrings/lib/strings/commandBarStrings.locstring.json';
import DiscardStrings from 'owa-locstrings/lib/strings/composediscardbutton.locstring.json';
import ComposeStrings from 'owa-locstrings/lib/strings/composesendbutton.locstring.json';
import DeleteStrings from 'owa-locstrings/lib/strings/deleteitem.locstring.json';
import { restoreAll } from 'owa-locstrings/lib/strings/restoreall.locstring.json';
import { restoreItem } from 'owa-locstrings/lib/strings/restoreitem.locstring.json';
import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import SplitButtonStrings from './ribbon_mail_split_buttons.locstring.json';
import RibbonMailStrings from './ribbon_mail_strings.locstring.json';
import SensitivityStrings from 'owa-locstrings/lib/strings/sensitivitymenutext.locstring.json';
import ShowFromStrings from 'owa-locstrings/lib/strings/showfrom.locstring.json';
import MessageSettingsStrings from 'owa-locstrings/lib/strings/showmessageoptions.locstring.json';
import SwitchToPlainTextMenuItemStrings from 'owa-locstrings/lib/strings/switchtoplaintextmenuitem.locstring.json';
import MoreAppsStrings from 'owa-locstrings/lib/strings/moreapps.locstring.json';
import ViewEmailFromNoteStrings from 'owa-locstrings/lib/strings/viewemailfromnotelabel.locstring.json';
import { getMailMenuItemShouldShow } from 'owa-mail-filterable-menu-behavior';
import { isPublicFolderSelected } from 'owa-publicfolder-utils';
import ReadingPaneStrings from 'owa-reading-pane-option/lib/ReadingPaneOption.locstring.json';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type {
    ComposeRibbonControlDefProps,
    RibbonControlDefinitionProps,
    ReadOnlyRibbonControlDefProps,
} from './RibbonControlDefinitionProps';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { getHoverTooltip } from './HoverTooltips/hoverTooltipConfig';
import type { ControlInfo } from './controlInfo';
import { isAllItemPartsExpanded } from 'owa-mail-reading-pane-store-conversation/lib/utils/isAllItemPartsExpanded';
import getIsLightRPOn from 'owa-light-reading-pane-store/lib/selectors/getIsLightRPOn';
import { isSingleLineRibbon } from 'owa-command-ribbon-store/lib/selectors/isSingleLineRibbon';
import { getAddIns_Button } from 'owa-locstrings/lib/strings/get_addins_button.locstring.json';

/**
 * Retrieves the display information pertaining to a given mail ribbon
 * control
 * @param controlId for which to retrieve the display information for
 * @param props the definition props
 * @returns display information for passed in control
 */
export function getControlInfo(
    controlId: RibbonControlId,
    props: RibbonControlDefinitionProps
): ControlInfo {
    const mailboxInfo = getRibbonMailboxInfo(props.mailboxInfo);
    switch (controlId) {
        //home tab controls
        case 587: {
            const controlName: string = loc(Strings.newEMailMessage_Button);

            return {
                iconName: Mail,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 588: {
            const isNote: boolean = getMailMenuItemShouldShow(57, mailboxInfo);
            const controlSplitBtn: string = loc(SplitButtonStrings.newMessage_SplitButton);

            if (isNote) {
                return {
                    iconName: Note,
                    text: loc(Strings.newNote_HeroButton),
                    ariaText: controlSplitBtn,
                    tooltip: undefined,
                    hasChevron: false,
                };
            }

            const isPublicFolder = isPublicFolderSelected();

            const controlName = isPublicFolder
                ? loc(Strings.newPostHero_Button)
                : loc(Strings.newMessage_Button);

            const controlText =
                !isPublicFolder && !isSingleLineRibbon()
                    ? loc(Strings.newMessage_Button_MLR)
                    : controlName;

            return {
                iconName: Mail,
                text: controlText,
                ariaText: controlSplitBtn,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 656: {
            const controlName: string = loc(Strings.newPost_Button);

            return {
                iconName: Mail,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 572: {
            const controlName: string = loc(Strings.sweepEmail_Button);

            return {
                iconName: Broom,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 531: {
            const controlName =
                getMailMenuItemShouldShow(20, mailboxInfo) ||
                !getMailMenuItemShouldShow(54, mailboxInfo)
                    ? loc(Strings.ignoreEmail_Button)
                    : loc(Strings.stopIgnoringEmail_Button);

            return {
                iconName: SpeakerMuteRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 548: {
            const controlName = loc(Strings.pinUnpin_Button);

            const controlText = isSingleLineRibbon()
                ? controlName
                : loc(Strings.pinUnpin_Button_MLR);

            return {
                iconName: Unpin,
                text: controlText,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 571: {
            const controlName: string = loc(Strings.snooze_Button);

            return {
                iconName: Clock,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        case 508: {
            const controlName: string = loc(Strings.browseGroups_Button);

            return {
                iconName: PeopleCommunity,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 663: {
            const controlName = loc(Strings.viewGroupsPanel_Button);
            const controlSplitButtonAriaText = loc(SplitButtonStrings.viewGroups_SplitButton);

            const controlText = isSingleLineRibbon()
                ? controlName
                : loc(Strings.viewGroupsPanel_Button_MLR);

            return {
                iconName: PeopleCommunity,
                text: controlText,
                ariaText: controlSplitButtonAriaText,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        case 580: {
            const controlName: string = loc(Strings.undo_Button);

            return {
                iconName: Undo,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 529: {
            const controlName: string = loc(getAddIns_Button);

            return {
                iconName: AppsAddIn,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 7016: {
            const controlName: string = loc(Strings.getMessageExtensions_Button);

            return {
                iconName: AppFolderRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 652: {
            const controlName = loc(Strings.manageQuickSteps_Button);
            return {
                iconName: Settings,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 550: {
            const controlName = loc(Strings.quickSteps_Button);
            const menuLauncherLabel = loc(Strings.quickSteps_MenuLauncherLabel);
            return {
                iconName: FlashRegular,
                text: controlName,
                ariaText: menuLauncherLabel,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 683: {
            const controlName = loc(restoreItem);
            return {
                iconName: ArrowHookUpLeft,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 684: {
            const controlName = loc(restoreAll);
            return {
                iconName: ArrowHookUpLeft,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        // view tab controls
        case 643: {
            const controlName: string = loc(Strings.viewSettings_Button);

            return {
                iconName: Settings,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 644: {
            const controlName: string = loc(Strings.conversations_Button);

            return {
                iconName: MailMultipleRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 645: {
            const controlName: string = loc(Strings.messagePreview_Button);

            return {
                iconName: MailListRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 646: {
            const controlName: string = loc(Strings.layout_Button);

            return {
                iconName: PanelLeftRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }
        case 647: {
            const controlName: string = loc(Strings.folder_Button);

            return {
                iconName: PanelLeftFilled,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }
        case 648: {
            const controlName: string = loc(ReadingPaneStrings.optionTitle_readingPane);

            return {
                iconName: PanelLeftFocusRightFilled,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }
        case 706: {
            const controlName: string = loc(RibbonMailStrings.myDayButton);
            return {
                iconName: CalendarTodoRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }
        case 649: {
            const controlName: string = loc(Strings.density_Button);
            return {
                iconName: TextDensityRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }
        case 654: {
            const controlName: string = loc(Strings.messages_Button);
            return {
                iconName: Mail,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        case 690: {
            const controlName: string = loc(Strings.remindersWindow_Button);
            return {
                iconName: ClockAlarm,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }
        //message tab controls
        case 546:
            return {
                iconName: ClipboardPaste,
                text: loc(Strings.paste_FlyoutButton),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: true,
            };
        case 569:
            return {
                iconName: '', // Icon "on order" from fluent
                text: loc(ShowFromStrings.showFrom),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };

        case 528:
            return {
                iconName: PaintBrush,
                text: loc(Strings.formatPainter_Button),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };

        case 564: {
            const controlName: string = loc(Strings.sensitivity_FlyoutButton);

            return {
                iconName: Classification,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 565: {
            const controlName: string = loc(SensitivityStrings.clpLabelDropDownLearnMoreButtonText);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 570:
            return {
                iconName: MailSettings,
                text: loc(MessageSettingsStrings.showMessageOptions),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };
        case 573:
            const viewState = (props as ComposeRibbonControlDefProps)?.composeViewState;
            const buttonText =
                viewState && viewState.bodyType != 'HTML'
                    ? CommandBarStrings.switchToHTMLMenuItem
                    : SwitchToPlainTextMenuItemStrings.switchToPlainTextMenuItem;
            return {
                iconName: '', // Currently no icon, by design
                text: loc(buttonText),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };
        case 510:
            return {
                iconName: AccessibilityCheckmark,
                text: loc(AccessibilityCheckerStrings.accCheckerMenuItemText),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };

        case 525: {
            const controlName: string = loc(Strings.encrypt_FlyoutButton);

            return {
                iconName: LockClosed,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        //send receive tab controls
        case 562:
            return {
                iconName: ArrowRepeatAll,
                text: loc(Strings.sendReceiveAllFolders_Button),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };
        case 561:
            return {
                iconName: Send,
                text: loc(Strings.sendAll_Button),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };
        case 582:
            return {
                iconName: FolderSwap,
                text: loc(Strings.updateFolder_Button),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };
        case 563:
            return {
                iconName: FolderSwap,
                text: loc(Strings.sendReceiveGroups_Button),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };
        case 575:
            return {
                iconName: ClipboardDataBar,
                text: loc(Strings.showSyncProgress_Button),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };
        case 574:
            return {
                iconName: ArrowRepeatAllOff,
                text: loc(Strings.cancelSync_Button),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };
        case 544:
            return {
                iconName: CloudOff,
                text: loc(Strings.workOffline_Button),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };
        case 521:
            return {
                iconName: CloudArrowDown,
                text: loc(Strings.downloadHeaders_Button),
                ariaText: undefined,
                tooltip: undefined,
                hasChevron: false,
            };

        //toolbar message tab
        case 594: {
            const controlName: string = loc(ComposeStrings.composeSendButton);

            return {
                iconName: Send,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 593: {
            const controlName: string = loc(DiscardStrings.composeDiscardButton);

            return {
                iconName: Delete,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 558: {
            const controlName: string = loc(Strings.ribbonCustomizer_Button);

            return {
                iconName: '',
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        //insert tab controls
        case 640: {
            const controlName: string = loc(DeleteStrings.deleteItem);
            return {
                iconName: Delete,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 641: {
            const controlName: string = loc(ChangeNoteColorStrings.changeNoteColorMenuText);
            return {
                iconName: Color,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: true,
            };
        }

        case 642: {
            const controlName: string = loc(ViewEmailFromNoteStrings.viewEmailFromNoteLabel);
            return {
                iconName: Mail,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 7022: {
            const controlName: string = loc(MoreAppsStrings.addApps);
            return {
                iconName: AddSquareRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 687: {
            const conversationId = (props as ReadOnlyRibbonControlDefProps)?.itemId;
            const allItemsExpanded = isAllItemPartsExpanded(conversationId?.Id ?? '');
            const controlName: string = allItemsExpanded
                ? loc(Strings.collapseConversation_Button)
                : loc(Strings.expandConversation_Button);

            return {
                iconName: allItemsExpanded ? TextCollapseRegular : TextExpandRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 688: {
            const controlName: string = loc(Strings.zoom_Button);

            return {
                iconName: ZoomInRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 720: {
            const isLightRPOn = getIsLightRPOn();
            const controlName: string = isLightRPOn
                ? loc(RibbonMailStrings.lightRPOffButton)
                : loc(RibbonMailStrings.lightRPOnButton);

            return {
                iconName: isLightRPOn ? WeatherMoonRegular : WeatherSunnyRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 691: {
            const controlName: string = loc(Strings.ribbonMode_Button);

            return {
                iconName: WindowFilled,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 701: {
            const controlName: string = loc(Strings.syncMailbox_Button);
            return {
                iconName: ArrowClockwiseRegular,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 714: {
            const controlName: string = loc(RibbonMailStrings.folderSettingsButton);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 715: {
            const controlName: string = loc(RibbonMailStrings.favoriteSettingsButton);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 716: {
            const controlName: string = loc(RibbonMailStrings.customSortButton);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 721: {
            const controlName: string = loc(RibbonMailStrings.messageListOrganizationButton);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        case 722: {
            const controlName: string = loc(RibbonMailStrings.readingPaneMessageViewButton);

            return {
                iconName: undefined,
                text: controlName,
                ariaText: undefined,
                tooltip: getHoverTooltip(controlId, controlName),
                hasChevron: false,
            };
        }

        default:
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            throw new Error(
                'The ribbon id ' +
                    controlId +
                    ' was not added to the switch case in getControlInfo.ts'
            );
    }
}
