import getStore from '../store/store';

export function getRowKeysForGroupHeaderAction(selectedRowKeys: string[]): string[] {
    if (selectedRowKeys.length === 1) {
        const selectedRowKey = selectedRowKeys[0];

        if (selectedRowKey.startsWith('groupHeader')) {
            const headerText = selectedRowKey.split('groupHeader')[1];
            selectedRowKeys = getStore().virtualizedGroupHeaders.get(headerText)?.rowKeys ?? [];
        }
    }
    return selectedRowKeys;
}
