import type { MailboxInfo } from 'owa-client-types';
import { getMailboxInfos, AccountStatus, App } from 'owa-account-source-list-store';
import { isSameIdentityKeyForMailboxInfos } from './isSameIdentityKeyForMailboxInfos';

/**
 * Get's the user coprincipal account that is associated with authenticaiton identity
 * @param mailboxInfo Specifies the MailboxInfo to get the user account for that is associated with the MailboxInfo
 * @returns The user account MailboxInfo that is associated with the authentication identity or undefined if not found
 */
export function getUserAccountForMailboxInfo(mailboxInfo: MailboxInfo): MailboxInfo | undefined {
    const matchingMailboxes = getMailboxInfos(AccountStatus.Completed, App.All).filter(mailbox => {
        return !!mailbox.isExplicitLogon
            ? false
            : isSameIdentityKeyForMailboxInfos(mailbox, mailboxInfo);
    });

    return matchingMailboxes[0];
}
