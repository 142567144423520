import type { MailboxInfo } from 'owa-client-types';
import type { CopilotSettingsResponse } from '../types/serviceTypes';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getNativeHostDeviceId } from '../../utils/getNativeHostDeviceId';
import { logUsage } from 'owa-analytics';

/**
 * Retrieves the Copilot settings response from the startup data based on the provided mailbox information.
 *
 * @param mailboxInfo - The mailbox information used to retrieve the Copilot settings.
 * @returns The Copilot settings response.
 *
 * This function first attempts to retrieve the Copilot settings specific to the device by matching the device ID
 * and ensuring the settings were saved within the last 48 hours. If no such settings are found, it falls back to
 * the account-level Copilot settings.
 */
export function getCopilotSettingsResponseFromStartupData(
    mailboxInfo: MailboxInfo,
    retrieveDeviceInfoSettings: boolean
): CopilotSettingsResponse {
    let cacheType = 'DeviceId';
    let copilotSettings;

    let missDeviceCacheReason = 'NA';

    if (retrieveDeviceInfoSettings) {
        const deviceId = getNativeHostDeviceId();
        missDeviceCacheReason = 'DeviceIdNoMatch';
        const coShareCopilotSettings =
            getAccountScopeUserSettings(mailboxInfo)?.CoShareCopilotSettings;

        if (coShareCopilotSettings?.Items) {
            for (const copilotSettingItem of coShareCopilotSettings?.Items) {
                if (copilotSettingItem.DeviceId === deviceId) {
                    if (isDeviceLastSavedWithin48Hours(copilotSettingItem.LastSavedTime)) {
                        copilotSettings = copilotSettingItem;
                    } else {
                        missDeviceCacheReason = 'DeviceIdExpired';
                    }

                    // We should break the loop either way as we found the device id as there will be only one entry for a device id
                    break;
                }
            }
        } else {
            missDeviceCacheReason = 'NoDeviceIdCollection';
        }
    }

    if (!copilotSettings) {
        copilotSettings = getAccountScopeUserSettings(mailboxInfo)?.AccountCopilotSetting;
        cacheType = 'Account';
    }

    const copilotSettingResponse: CopilotSettingsResponse = copilotSettings?.Root;

    logUsage('CopilotFromStartup', {
        hasCache: copilotSettingResponse ? 'Y' : 'N',
        cacheType: copilotSettingResponse ? cacheType : 'NA',
        missDeviceCacheReason:
            copilotSettingResponse && cacheType == 'Account' ? missDeviceCacheReason : 'NA',
    });

    return copilotSettingResponse;
}

function isDeviceLastSavedWithin48Hours(lastSavedTime: string | undefined): boolean {
    if (!lastSavedTime) {
        return false; // Handle undefined or empty string as false
    }

    const lastSavedDate = new Date(lastSavedTime); // Convert string to Date object
    const currentDate = new Date(); // Get current date and time
    const timeDifference = currentDate.getTime() - lastSavedDate.getTime(); // Difference in milliseconds
    const hoursDifference = timeDifference / (1000 * 60 * 60); // Convert milliseconds to hours

    return hoursDifference <= 48;
}
