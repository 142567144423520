import { createStore } from 'owa-satcheljs';
import { mutatorAction } from 'satcheljs';

// Define the store interface
interface FolderNavigationStore {
    message: string | undefined;
}

// Create the satchel store
const getStore = createStore<FolderNavigationStore>('folderNavigationStore', {
    message: undefined,
});

// Define the getters and setters
const setMessageMutator = mutatorAction('setMessage', (message: string | undefined) => {
    getStore().message = message;
});

const getMessage = () => {
    return getStore().message;
};

export { setMessageMutator, getMessage };
