import { createLazyComponent, LazyModule } from 'owa-bundling';
export { default as BigHoverActionPlaceholder } from './components/BigHoverAction/BigHoverActionPlaceholder';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailListViewBigHoverAction"*/ './lazyIndex'),
    { name: 'MailListViewBigHoverAction' }
);

import BigHoverActionBasePlaceholder from './components/BigHoverAction/BigHoverActionPlaceholder';

// lazy components
export const BigHoverActionBase = createLazyComponent(
    lazyModule,
    m => m.BigHoverActionBase,
    BigHoverActionBasePlaceholder
);
