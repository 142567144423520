import { createStore } from 'owa-satcheljs';
import type DictationStore from './schema/DictationStore';
import DictationButtonState from './schema/DictationButtonStateEnum';
import { DictationStatus } from '@1js/office-online-draggable-floatie';

const dictationStoreData: DictationStore = {
    dictationButtonState: DictationButtonState.DictationStopped,
    currentLanguage: '',
    activeMicrophone: null,
    autoPunctuationEnabled: false,
    profanityFilterEnabled: true,
    dictationFloatieState: {
        floatieDictationStatus: DictationStatus.DictationIdle,
        floatieMessageType: undefined,
        floatieExtraMessage: '',
        floatieIsSpeaking: false,
        isFloatieMicButtonDisabled: false,
        floatieCalloutId: '',
    },
    hasLanguageStoreValueBeenUpdated: false,
    hasMicStoreValueBeenUpdated: false,
    hasAutoPunctuationStoreValueBeenUpdated: false,
    hasProfanityFilterStoreValueBeenUpdated: false,
    activeDockingId: '',
};

export const getDictationStore = createStore<DictationStore>('dictationStore', dictationStoreData);
export type { DictationStore };
