import { format } from 'owa-localize';
import { MESSAGE_DOWNLOAD_RELATIVE_URL_TEMPLATE } from '../constants';
import type { ImportExportFormatType } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import { getUrlDataForUserIdentity } from '../utils/getUrlDataForUserIdentity';
import { getRoutingKeyForMailboxInfo } from 'owa-files-url-common';
import type { FilesUrlData } from '../schema/FilesUrlData';

export function getDownloadMessageUrl(
    itemId: string,
    formatTypeValue: ImportExportFormatType,
    mailboxInfo: MailboxInfo
): string | undefined {
    const urlData: FilesUrlData | undefined = getUrlDataForUserIdentity(mailboxInfo);

    // If downloadUrlBase or downloadToken is not available, return undefined
    if (!urlData?.downloadUrlBase || !urlData?.downloadToken) {
        return undefined;
    }

    const fileDownloadUrl = format(
        MESSAGE_DOWNLOAD_RELATIVE_URL_TEMPLATE,
        encodeURIComponent(itemId),
        formatTypeValue === 'EML' ? '0' : '1', // OFT files are just msg files with the IsDraft flag set to true
        urlData?.downloadToken
    );

    return format(
        '{0}/{1}',
        `${urlData?.downloadUrlBase}${getRoutingKeyForMailboxInfo(mailboxInfo)}`,
        fileDownloadUrl
    );
}
