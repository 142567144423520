import type LeftNavGroupsSchema from './schema/LeftNavGroups';
import { createStore } from 'owa-satcheljs';
import type LeftNavGroupsState from './schema/LeftNavGroupsState';
import type { AccountKey } from 'owa-client-types';

const defaultLeftNavGroupsStore: LeftNavGroupsState = {
    leftNavGroupsByMailboxInfo: new Map<AccountKey, LeftNavGroupsSchema>(),
};

export const getLeftNavGroupsStore = createStore<LeftNavGroupsState>(
    'leftNavGroupsStore',
    defaultLeftNavGroupsStore
);
