import type { SurfaceActionsOptions } from 'owa-outlook-service-option-store';
import { OwsOptionsFeatureType, getOptionsForFeature } from 'owa-outlook-service-option-store';

export function isMessageExtensionPinned(appId: string): boolean {
    return isReadMessageExtensionPinned(appId) || isComposeMessageExtensionPinned(appId);
}

export function isReadMessageExtensionPinned(appId: string): boolean {
    const mailOptions: SurfaceActionsOptions = getOptionsForFeature<SurfaceActionsOptions>(
        OwsOptionsFeatureType.SurfaceActions
    );

    return !!mailOptions.readSurfaceAddins.find(app => app.toLowerCase() == appId.toLowerCase());
}

export function isComposeMessageExtensionPinned(appId: string): boolean {
    const mailOptions: SurfaceActionsOptions = getOptionsForFeature<SurfaceActionsOptions>(
        OwsOptionsFeatureType.SurfaceActions
    );

    return !!mailOptions.composeSurfaceAddins.find(app => app.toLowerCase() == appId.toLowerCase());
}
