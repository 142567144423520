import type { MailboxInfo } from 'owa-client-types';
import type { CopilotSettingsStore, ImplementedCopilotFeatures } from 'owa-copilot-settings-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import createStoreCheckForGenericEnablement from './createStoreCheckForGenericEnablement';

const canUseBetaEndpoint = createStoreCheckForGenericEnablement<
    ImplementedCopilotFeatures,
    boolean
>('BetaEndpoint', canUseBetaEndpointInternal);

export default canUseBetaEndpoint;

function canUseBetaEndpointInternal(
    store: CopilotSettingsStore,
    _language: string,
    _scenario: ImplementedCopilotFeatures,
    mailboxInfo: MailboxInfo
): boolean {
    if (
        store.isEnabled &&
        store.isBetaUser &&
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'mon-copilot-beta-endpoint' */
        isFeatureEnabled('mon-copilot-beta-endpoint', mailboxInfo)
    ) {
        return true;
    }

    return false;
}
