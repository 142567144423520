import { createStore } from 'owa-satcheljs';
import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';
import type CopilotStyleSettings from '../types/CopilotStyleSettings';
interface CopilotPrioritizeSettingsStore {
    isInitialized: boolean;
    settings: CopilotPrioritizeSettings;
}

interface CopilotStyleSettingsStore {
    isInitialized: boolean;
    settings: CopilotStyleSettings;
}

export interface GeneralPreferences {
    isCopilotUIDisabled: boolean; // true if the user chooses to disable Copilot UI which will hide all Copilot entrypoint UIs, but not affect teaching moments
}

interface CopilotSettingsStoreMap {
    copilotPrioritizeSettingsStoreMap: Map<string, CopilotPrioritizeSettingsStore>;
    copilotStyleSettingsStoreMap: Map<string, CopilotStyleSettingsStore>;
    copilotGeneralPreferencesMap: Map<string, GeneralPreferences>;
}

export default createStore<CopilotSettingsStoreMap>('copilotSettingsStore', {
    copilotPrioritizeSettingsStoreMap: new Map(),
    copilotStyleSettingsStoreMap: new Map(),
    copilotGeneralPreferencesMap: new Map(),
});
