import { getOcpsPolicyFromLocalStore } from './getOcpsPolicyFromLocalStore';
import type { OcpsPolicy } from './OcpsPolicy';
import type { MailboxInfo } from 'owa-client-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

// This function does not have a check for consumer accounts
// Caller to check for consumer accounts before calling this function
// Returns null if nothing is found in the local store
export default function getOcpsPolicyValue(
    policy: OcpsPolicy,
    mailboxInfo?: MailboxInfo
): string | null {
    if (!mailboxInfo) {
        mailboxInfo = getModuleContextMailboxInfo();
    }

    const policyState = getOcpsPolicyFromLocalStore(mailboxInfo, policy);

    if (!policyState) {
        return null;
    }

    return policyState;
}
