import { getFolderTable, isFolderInMailboxType, isPublicFolder } from 'owa-folders';
import {
    ARCHIVE_FOLDERS_TREE_TYPE,
    SHARED_FOLDERS_TREE_TYPE,
    PRIMARY_FOLDERS_TREE_TYPE,
} from 'owa-folders-constants';
import { getUserPermissionForFolderId } from 'owa-mail-store/lib/utils/getUserPermissionForFolderId';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import getFolderIdFromTableView from './getFolderIdFromTableView';
import type ReadingPanePopoutItemFolderInfo from 'owa-popout-utils/lib/schema/ReadingPanePopoutItemFolderInfo';
import { publicFolderFavoriteStore } from 'owa-public-folder-favorite';
import { getAccountScopeUserSettings } from 'owa-session-store';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { errorThatWillCauseAlert } from 'owa-trace';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Utility to prepare popout data for reading pane scenario
 * @param tableView
 */
export default function preparePopoutDataForReadingPane(
    tableView: TableView | undefined,
    itemFolderId?: string | undefined
): ReadingPanePopoutItemFolderInfo | undefined {
    const folderId = !itemFolderId
        ? tableView && getFolderIdFromTableView(tableView)
        : itemFolderId;
    const mailboxInfo = getMailboxInfo(folderId);
    let folderData: ReadingPanePopoutItemFolderInfo | undefined;
    if (folderId && mailboxInfo) {
        if (
            !isConsumer(undefined, mailboxInfo) &&
            isFolderInMailboxType(folderId, 'SharedMailbox')
        ) {
            folderData = setPopoutDataForSharedFolders(folderId, mailboxInfo);
        } else if (
            !isConsumer(undefined, mailboxInfo) &&
            isFolderInMailboxType(folderId, 'ArchiveMailbox')
        ) {
            folderData = setPopoutDataForArchiveMailbox(folderId, mailboxInfo);
        } else if (isPublicFolder(folderId)) {
            folderData = setPopoutDataForPublicFolder(folderId, mailboxInfo);
        } else {
            folderData = setPopoutDataForPrimaryFolders(folderId, mailboxInfo);
        }
    }

    return folderData;
}

/**
 * Utility to set the popout data with required folder data for shared folders
 * This is exported only for test purpose and need not be used directly. Use the wrapper preparePopoutDataForReadingPane instead
 * @param folderId
 */
function setPopoutDataForSharedFolders(
    folderId: string,
    mailboxInfo: MailboxInfo
): ReadingPanePopoutItemFolderInfo | undefined {
    let folderData: ReadingPanePopoutItemFolderInfo | undefined;
    try {
        folderData = {
            folderId,
            treeType: SHARED_FOLDERS_TREE_TYPE,
            mailboxInfo,
            permission: getUserPermissionForFolderId(
                folderId,
                getAccountScopeUserSettings(mailboxInfo).SessionSettings?.UserEmailAddress || ''
            ),
            principalSMTPAddress: mailboxInfo.mailboxSmtpAddress,
        };
    } catch (e) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert('setPopoutDataForSharedFolders failed : ' + e);
    }
    return folderData;
}

/**
 * Utility to set the popout data with required folder data for shared folders
 * This is exported only for test purpose and need not be used directly. Use the wrapper preparePopoutDataForReadingPane instead
 * @param folderId
 */
function setPopoutDataForPrimaryFolders(
    folderId: string,
    mailboxInfo: MailboxInfo
): ReadingPanePopoutItemFolderInfo | undefined {
    let folderData: ReadingPanePopoutItemFolderInfo | undefined;
    try {
        folderData = {
            folderId,
            treeType: PRIMARY_FOLDERS_TREE_TYPE,
            mailboxInfo,
        };
    } catch (e) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert('setPopoutDataForPrimaryFolders failed : ' + e);
    }
    return folderData;
}

/**
 * Utility to set the popout data with required folder data for archive mailbox
 * This is exported only for test purpose and need not be used directly. Use the wrapper preparePopoutDataForReadingPane instead
 * @param folderId
 */
function setPopoutDataForArchiveMailbox(
    folderId: string,
    mailboxInfo: MailboxInfo
): ReadingPanePopoutItemFolderInfo | undefined {
    let folderData: ReadingPanePopoutItemFolderInfo | undefined;
    try {
        folderData = {
            folderId,
            treeType: ARCHIVE_FOLDERS_TREE_TYPE,
            mailboxInfo,
        };
    } catch (e) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert('setPopoutDataForArchiveMailbox failed : ' + e);
    }
    return folderData;
}

function setPopoutDataForPublicFolder(
    folderId: string,
    mailboxInfo: MailboxInfo
): ReadingPanePopoutItemFolderInfo | undefined {
    let folderData: ReadingPanePopoutItemFolderInfo | undefined;
    try {
        const sourceFolder = publicFolderFavoriteStore.folderTable.get(folderId);
        folderData = {
            folderId,
            treeType: 'favorites',
            replicaItem: sourceFolder?.replicaList?.[0] || '',
            mailboxInfo,
        };
    } catch (e) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert('setPopoutDataForPublicFolder failed : ' + e);
    }
    return folderData;
}

function getMailboxInfo(folderId: string | undefined | null): MailboxInfo | undefined {
    if (folderId) {
        const folder = getFolderTable().get(folderId);
        return folder?.mailboxInfo;
    }
    return undefined;
}
