// List of valid scenario names from https://o365exchange.visualstudio.com/DefaultCollection/O365%20Core/_git/9d4dd053-ebd8-47fc-93e1-27cbed0c9f2e?path=/sources/dev/SubstrateSearch/src/Core/StaticSettings/Constants/ScenarioName.cs&version=GBmaster

export enum SubstrateSearchScenario {
    Mail = 'owa.react',
    MailCompose = 'owa.react.compose',
    Calendar = 'owa.react.calendar',
    FilesHub = 'owa.react.filesHub',
    MailSubject = 'owa.react.mail.subject',
    MailEmailAttachmentPicker = 'owa.react.mail.emailPicker',
    SuggestedAttachments = 'suggestedattachments',
    MeetingPrep = 'MeetingPrep',
    MeetingPrepPrefetch = 'MeetingPrep.Prefetch',
    MeetingPrepPeek = 'MeetingPrep.Peek',
    MeetingPrepAgenda = 'MeetingPrep.AgendaView',
    MeetingPrepMailCalendarCard = 'MeetingPrep.MailCalendarCard',
    MeetingPrepDesktop = 'OutlookDesktopOPX.MeetingPrep',
    MeetingPrepShell = 'ShellOPX.MeetingPrep',
    ContextualInsights = 'ContextualInsights',
    FileSuggestions = 'owa.react.filepicker',
    SonoraFilePicker = 'outlook.inlinefilepicker',
    SonoraHoneybee = 'owa.honeybee',
    ContextIQ = 'outlook.web.oa',
    MeetingCatchUpMonarch = 'MeetingCatchUp.Outlook.Monarch',
    MeetingCatchUpTeams = 'MeetingCatchUp.Teams.Monarch',
    InboxRules = 'owa.react.inboxrules',
    MacInboxRules = 'outlookmac.inboxrules',
    RecipientCache = 'owa.react.recipientcache',
    People = 'owa.react.people',
}

export default SubstrateSearchScenario;
