import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/store';
import type { CalendarsUnionType } from 'owa-graph-schema';

export default mutatorAction(
    'updateEligibleCalendars',
    function updateEligibleCalendars(smtpAddress: string, calendar: CalendarsUnionType) {
        if (!smtpAddress) {
            return;
        }
        getStore().eligibleCalendars.set(smtpAddress, calendar);
    }
);
