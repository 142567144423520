import { mutatorAction } from 'satcheljs';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';
import { mailListItemAnimationStore } from '../store/Store';
import type { ActionId } from '../util/actionId';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const addPendingAnimation = mutatorAction(
    'addPendingAnimation',
    (animationRowIds: string[], animationAction?: ActionId, onAnimationComplete?: () => void) => {
        if (animationRowIds) {
            const animationToAdd: MailListItemAnimation = {
                animationId: JSON.stringify(animationRowIds),
                animationAction: animationAction ?? null,
                animationRowIds: new Set(animationRowIds),
                ...(onAnimationComplete && { animationCallback: onAnimationComplete }),
            };

            mailListItemAnimationStore.pendingAnimations = [
                ...mailListItemAnimationStore.pendingAnimations,
                animationToAdd,
            ];
        }
    }
);
