import type { AccountSourceType } from 'owa-account-source-list-types';
import type { AutomappedMailbox } from './AutomappedMailbox';

export const CoprincipalAccount_Version1 = 1;

export interface CoprincipalAccount {
    uuid: string;
    version: number;
    accountType: AccountSourceType;
    emailAddress: string;
    userIdentity: string;
    routingHint?: string;
    aliases: string[];
    isEasidOverrideEmailAddress?: boolean;
    isAnotherMailbox?: boolean;
    localAuthId?: string;
    accountDescription?: string;
    hiddenAutomappedMailboxes?: AutomappedMailbox[];
}
