import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import deleteFolderOperation from 'owa-service/lib/operation/deleteFolderOperation';
import folderId from 'owa-service/lib/factory/folderId';
import deleteFolderRequest from 'owa-service/lib/factory/deleteFolderRequest';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import type { MailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';

export const validateCategorySearchFolder = async (
    mailboxInfo: MailboxInfo,
    totalConversationRowsInView: number | null | undefined,
    searchFolderId: string | null | undefined
) => {
    // We only want to delete the search folder if it exists, but has no items
    if (searchFolderId && totalConversationRowsInView === 0) {
        const header = getJsonRequestHeader();
        const requestBody = deleteFolderRequest({
            DeleteType: 'HardDelete',
            FolderIds: [folderId({ Id: searchFolderId })],
        });

        try {
            const response = await deleteFolderOperation(
                {
                    Header: header,
                    Body: requestBody,
                },
                getMailboxRequestOptions(mailboxInfo)
            );
            const responseItem = response?.Body?.ResponseMessages?.Items?.[0];
            if (responseItem?.ResponseClass == 'Success') {
                logUsage('validateCategorySearchFolder_Succeeded');
            } else {
                logUsage('validateCategorySearchFolder_Failed');
            }
        } catch {
            logUsage('validateCategorySearchFolder_Errored');
        }
    }
};
