import {
    lazyRegisterDataCollectorIfNotRegistered,
    type DataCollector,
} from 'diagnostics-data-collection';
import loc from 'owa-localize';
import { getSelectedCalendars } from '../selectors/getSelectedCalendars';
import { calendarModuleSelectedCalendarsCollectorDescription } from './registerCalendarModuleSelectedCalendarsCollector.locstring.json';
import { mapToObj } from 'owa-map-utils';

export async function registerCalendarModuleSelectedCalendarsCollector() {
    const calendarModuleSelectedCalendarsDataCollector: DataCollector = {
        dataCollectionAction: async () =>
            JSON.stringify({
                calendarModuleSelectedCalendarsSetting: mapToObj(getSelectedCalendars()),
            }),
        name: 'CalendarModuleSelectedCalendarsDebugData',
        odsFileDescription: "Calendar selected calendars according to the user's configuration.",
        description: loc(calendarModuleSelectedCalendarsCollectorDescription),
    };
    await lazyRegisterDataCollectorIfNotRegistered.importAndExecute(
        calendarModuleSelectedCalendarsDataCollector
    );
}
