import { mutatorAction } from 'satcheljs';
import { taskModules, instanceFactory } from '../store/taskModule';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const registerInstance = mutatorAction('CREATE_NEW_TASK_MODULE_INSTANCE', (id: string) => {
    const instanceStore = instanceFactory(id);
    taskModules().instances.set(id, instanceStore);
});
