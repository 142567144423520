import {
    getMailListLoadDatapoint,
    setMailListLoadDatapoint,
} from './mailListLoadDatapointGetterAndSetter';
import { addTimingsAndEndDatapointOnRender } from 'owa-analytics-shared';
import { DatapointStatus } from 'owa-analytics-types';
import type { PerformanceDatapoint } from 'owa-analytics';
import { getStore as getListViewStore, type TableQueryType } from 'owa-mail-list-store';
import { isReadingPanePositionOff } from 'owa-mail-layout';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';

export default function endMailListLoadDatapoint(
    componentRendered: string,
    tableViewId: string,
    shouldInvalidate?: boolean
) {
    const mailListLoadDp = getMailListLoadDatapoint();
    if (mailListLoadDp && !mailListLoadDp.hasEnded) {
        if (shouldInvalidate) {
            mailListLoadDp.invalidate();
        } else {
            addCustomDataToMailListLoadDatapoint(mailListLoadDp, componentRendered, tableViewId);

            if (window.document && window.document.visibilityState == 'visible') {
                // Passing logOnly as false ends the datapoint after logging render end time if it has not already ended.
                addTimingsAndEndDatapointOnRender(mailListLoadDp, false /* logOnly */);
            } else {
                mailListLoadDp.end(undefined /* duration */, DatapointStatus.BackgroundSuccess);
            }
        }
        setMailListLoadDatapoint(undefined);
    }
}

function addCustomDataToMailListLoadDatapoint(
    mailListLoadDp: PerformanceDatapoint,
    componentRendered: string,
    tableViewId: string
) {
    const tableView = getListViewStore().tableViews.get(tableViewId) ?? null;
    const autoSelectFirstRow = getUserConfiguration()?.UserOptions?.ShowReadingPaneOnFirstLoad;

    mailListLoadDp.addCustomData({
        cmp: componentRendered,
        lv: tableView?.tableQuery.listViewType,
        tq: tableView?.tableQuery.type,
        rpOn: !isReadingPanePositionOff(),
        fName: tableView?.tableQuery.type === 0 && folderIdToName(tableView?.tableQuery.folderId),
        auto: autoSelectFirstRow,
    });
}
