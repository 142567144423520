import { mutatorAction } from 'satcheljs';
import type GroupSiteStatus from 'owa-groups-sharepoint-commands/lib/schema/GroupSiteStatus';
import type { MailboxInfo } from 'owa-client-types';
import {
    getGroupSiteStatus,
    getGroupSiteStatusMap,
} from '../selectors/groupSiteStatusStoreSelectors';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setGroupSiteStatus = mutatorAction(
    'setGroupSiteStatus',
    function setGroupSiteStatus(
        mailboxInfo: MailboxInfo,
        groupSmtpAddress: string,
        groupSiteStatusParam: GroupSiteStatus
    ) {
        groupSmtpAddress = transformGroupSmtp(groupSmtpAddress);
        const groupSiteStatus = getGroupSiteStatus(mailboxInfo, groupSmtpAddress);

        if (groupSiteStatus && groupSiteStatus == groupSiteStatusParam) {
            return;
        }

        getGroupSiteStatusMap(mailboxInfo).set(groupSmtpAddress, groupSiteStatusParam);
    }
);

export default setGroupSiteStatus;
