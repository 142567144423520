import { createLazyComponent, LazyModule, LazyAction } from 'owa-bundling';
export { getModuleUrl, getModuleUrlForNewAccount } from './utils/getModuleUrl';
export { isEasiIdUser } from './services/isEasiIdUser';
export { isEasiIdUserWithoutCloudCache } from './services/isEasiIdUserWithoutCloudCache';
export { logUsageBasedOnPremiumFlag } from './utils/logUsageBasedOnPremiumFlag';
export { isGreaterThanOrEqualTo } from './utils/semverCompare';
export { isCloudCacheLoaded, userHadCloudCacheInSession, getStore } from './store/store';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "CloudCache"*/ './lazyIndex'), {
    name: 'CloudCache',
});

// Export delay loaded components
export var CloudCacheOptionFull = createLazyComponent(lazyModule, m => m.CloudCacheOptionFull);
export var AddCloudCacheAccountCallout = createLazyComponent(
    lazyModule,
    m => m.AddCloudCacheAccountCallout
);
export var CloudCacheAccountRetentionCallout = createLazyComponent(
    lazyModule,
    m => m.CloudCacheAccountRetentionCallout
);
export var AddCloudCacheAccountDialog = createLazyComponent(
    lazyModule,
    m => m.AddCloudCacheAccountDialog
);
export const lazyAddCloudCacheAccount = new LazyAction(lazyModule, m => m.addCloudCacheAccount);
export const lazyGetCloudCacheAccount = new LazyAction(lazyModule, m => m.getCloudCacheAccount);
