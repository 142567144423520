import { logGreyError } from 'owa-analytics';
import {
    getOSTimeZoneId,
    getTimeZoneRangeLoadState,
    isTimezoneAvailable,
    setLocalTimeZone,
} from 'owa-datetime-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { updateServiceConfig } from 'owa-service/lib/config';
import { updateUserConfiguration } from 'owa-session-store';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';

/**
 * Update the mailbox time zone to the OS time zone only locally for Teams Calendar.
 */
export default function updateLocalMailboxTimeZone() {
    if (!isFeatureEnabled('teams-useOSTimeZone')) {
        return;
    }

    const userOptions = getUserConfiguration()?.UserOptions;
    const osTimeZone = getOSTimeZoneId();
    const shouldUpdateTimeZone = userOptions?.TimeZone !== osTimeZone;

    if (!shouldUpdateTimeZone) {
        return;
    }

    if (!osTimeZone || !isTimezoneAvailable(osTimeZone)) {
        logGreyError('OSTimeZoneNotAvailable', new Error('Timezone unavailable'), {
            OSTimeZone: osTimeZone,
            MailboxTimeZone: userOptions?.TimeZone,
            TimeZoneRangeLoadState: getTimeZoneRangeLoadState(),
        });

        return;
    }

    // Save
    updateServiceConfig({ timezone: osTimeZone });

    // Update owa-datetime
    setLocalTimeZone(osTimeZone, true /* isLocalUpdate */);

    // Update UserOptions
    updateUserConfiguration(config => {
        if (config.UserOptions) {
            config.UserOptions.TimeZone = osTimeZone;
        }
    });
}
