import { createStore } from 'owa-satcheljs';
import { getZoomIncrementFromStorage } from '../utils/localStorage';
import { ZOOM_INCREMENTS } from '../utils/constants';
import type ZoomStore from './schema/ZoomStore';
import type ZoomViewState from './schema/ZoomViewState';

const initialZoomStore: ZoomStore = {
    zoomViewStates: new Map<string, ZoomViewState>([]),
    zoomIncrementIndex: ZOOM_INCREMENTS.indexOf(getZoomIncrementFromStorage()),
};

const store = createStore<ZoomStore>('zoomStore', initialZoomStore)();
export default store;
export const getStore = () => store;

export const getZoomViewState = (context: string) => {
    if (!getStore().zoomViewStates.has(context)) {
        getStore().zoomViewStates.set(context, { isCalloutVisible: false });
    }

    return getStore().zoomViewStates.get(context);
};
