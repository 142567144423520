import { createStore } from 'owa-satcheljs';
import type ConversationItemParts from './schema/ConversationItemParts';
import type ConversationReadingPaneNode from './schema/ConversationReadingPaneNode';
import type MailStore from './schema/MailStore';
import type ClientItem from './schema/ClientItem';

const mailStoreData: MailStore = {
    conversationNodes: new Map<string, ConversationReadingPaneNode>([]),
    conversations: new Map<string, ConversationItemParts>([]),
    items: new Map<string, ClientItem>([]),
    searchFolderDisplayName: undefined,
    typeOfItemBeingDragged: '',
    triageAnnouncement: {
        message: '',
        politenessSetting: 'off',
    },
};

const store = createStore<MailStore>('mail', mailStoreData)();
export default store;
export const getStore = () => store;
