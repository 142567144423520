import type { MailboxInfo } from 'owa-client-types';
import { lazyGetAndUpdateAccessToken } from 'owa-headers-refresh-token';
import {
    getOWAConnectedAccount,
    OWAConnectedAccountState,
    isMonarchMultipleAccountsEnabled,
} from 'owa-accounts-store';
import type { OWAConnectedAccount } from 'owa-accounts-store';
import { getAnchorMailboxMailboxInfo } from 'owa-anchormailbox';
import { isPastTime } from 'owa-observable-datetime';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { logCoreUsage } from 'owa-analytics';
import { now, differenceInSeconds } from 'owa-datetime';
import { isFeatureEnabled } from 'owa-feature-flags';
import { trace } from 'owa-trace';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';

const HEADER_ANCHOR_MAILBOX: string = 'X-AnchorMailbox';
const HEADER_AUTHORIZATION: string = 'Authorization';
const HEADER_WEBSESSION_TYPE: string = 'X-OwaWebSessionType';
const HEADER_OWA_EXPLICITLOGONUSER: string = 'X-OWA-ExplicitLogonUser';

/**
 * Connected accounts need to return the web service type as well as the auth token
 */
interface AuthTokenAndWebSessionType {
    token: string;
    webSessionType?: string;
}

/**
 * Gets the anchor mailbox and explicit logon user headers using the information
 * from the mailboxInfo. This code path is intended to work with using the
 * information in the owa-account-source-list-store.
 */
export function getConnectedAccountHeadersForMailboxInfo(
    mailboxInfo: MailboxInfo,
    addExplicitLogonUser: boolean,
    isSharedCoprincipalAccount?: boolean
): {
    [headerName: string]: string;
} {
    const headers: {
        [headerName: string]: string;
    } = {};

    const anchorMailbox = getAnchorMailboxMailboxInfo(mailboxInfo);
    if (anchorMailbox) {
        headers[HEADER_ANCHOR_MAILBOX] = anchorMailbox;
        if (addExplicitLogonUser) {
            const explicitLogonUser =
                isSharedCoprincipalAccountEnabled() && !!isSharedCoprincipalAccount
                    ? mailboxInfo.mailboxSmtpAddress
                    : anchorMailbox;

            headers[HEADER_OWA_EXPLICITLOGONUSER] = explicitLogonUser;
        }
    }

    return headers;
}

export async function getConnectedAccountHeaders(
    userIdentity: string,
    groupMailboxAddress?: string
): Promise<{
    [headerName: string]: string;
}> {
    const headers: {
        [headerName: string]: string;
    } = {};
    const account = getOWAConnectedAccount(userIdentity);

    if (groupMailboxAddress) {
        headers[HEADER_ANCHOR_MAILBOX] = groupMailboxAddress;
        headers[HEADER_OWA_EXPLICITLOGONUSER] = groupMailboxAddress;
    } else if (account) {
        headers[HEADER_ANCHOR_MAILBOX] = account.anchorMailbox;
    }

    if (isMonarchMultipleAccountsEnabled() || isFeatureEnabled('acct-connected-tokens')) {
        trace.info(
            '[cal-multiAccounts-sourceId] Bypassing legacy connected account header processing.'
        );
        return headers;
    }

    // Get the token and web session type and add to the headers if the values are returned
    const tokenAndWebSession = await getConnectedAccountAuthTokenInfo(userIdentity, account);

    if (tokenAndWebSession?.token) {
        headers[HEADER_AUTHORIZATION] = tokenAndWebSession?.token;
        if (tokenAndWebSession?.webSessionType) {
            headers[HEADER_WEBSESSION_TYPE] = tokenAndWebSession?.webSessionType;
        }
    }

    return headers;
}

export async function getConnectedAccountAuthTokenInfo(
    userIdentity: string,
    account?: OWAConnectedAccount
): Promise<AuthTokenAndWebSessionType | undefined> {
    if (account) {
        if (account.accountState == OWAConnectedAccountState.AccessRevoked) {
            return undefined;
        } else if (
            account.accountState == OWAConnectedAccountState.AccountDeprovisioned ||
            account.accountState == OWAConnectedAccountState.AccountNotFound
        ) {
            return undefined;
        }
    }

    if (account) {
        let token = account.token;
        const isExpired = isPastTime(account.tokenExpiry);

        if (isExpired || !token) {
            logCoreUsage('getAndUpdateAccessToken', {
                tokenExpiry: differenceInSeconds(account.tokenExpiry, now()),
                isTokenExpired_1: isExpired,
                isTokenInvalid_2: !token,
            });
            token =
                (await lazyGetAndUpdateAccessToken.importAndExecute(
                    account.accountId,
                    userIdentity,
                    token
                )) || '';
            trace.info(
                '[AccountLoadRecovery] getConnectedAccountHeaders: finished GetAndUpdateAccessToken for ' +
                    userIdentity +
                    token
            );

            if (!token) {
                // The account is already being marked as invalid in getAndUpdateRefreshToken, but the caller doesn't know about it,
                // so we will set a fake token as a token header to fail the request and log it
                token = '';
            }
        }

        if (token) {
            let tokenString: string = '';
            const webSessionString = getWebSessionTypeForAccount(account);
            if (webSessionString === (1).toString()) {
                tokenString = `MSAuth1.0 usertoken="${token}", type="MSACT"`;
            } else if (webSessionString === (2).toString()) {
                tokenString = `Bearer ${token}`;
            }

            return { token: tokenString, webSessionType: webSessionString };
        }
    }

    return undefined;
}

export function getWebSessionTypeForAccount(account: OWAConnectedAccount): string {
    if (account.accountProviderType == 'Outlook') {
        return (1).toString();
    } else if (
        account.accountProviderType == 'Google' ||
        account.accountProviderType == 'ICloud' ||
        account.accountProviderType == 'Yahoo'
    ) {
        return (2).toString();
    }

    return '';
}
