import { LazyImport, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "RibbonLightIconFont" */ './lazyIndex'),
    {
        name: 'RibbonLightIconFont',
    }
);

// ADO 337897, keep these lazy loading exports until we rollout the new flight WW
export const lazyIsLightIconFontEnabled = new LazyImport(lazyModule, m => m.isLightIconFontEnabled);
export const lazyRegisterLightIcon = new LazyImport(lazyModule, m => m.registerLightIcon);

export { registerAllLightIcons } from './util/registerAllLightIcons';
export { getLightIconName } from './util/getLightIconName';

// ADO 337897, to remove this export and use isLightFontEnabled directly
export { lightRibbonIconFont } from './util/lightRibbonIconFont';
