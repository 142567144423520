export const USER_OCPS_FEEDBACK_ENABLED: string = 'ocpsfeedbackenabled';
export const USER_OCPS_NPS_SURVEY_ENABLED: string = 'ocpsnpssurveyenabled';
export const USER_OCPS_EMAIL_COLLECTION_ENABLED: string = 'ocpsemailcollectionenabled';
export const USER_OCPS_POLICIES_EXPIRATION: string = 'ocpspoliciesexpiration';
export const USER_OCPS_SCREENSHOT_ENABLED: string = 'ocpsscreenshotenabled';
export const USER_OCPS_LOOP_ENABLED: string = 'L_Loop_Outlook';
export const USER_OCPS_LOOP_ENABLED_FOR_OUTLOOK: string = 'LoopOutlook';
export const USER_OCPS_LOG_CONTENT_ENABLED: string = 'ocpslogcollectionenabled';
export const USER_OCPS_OUTLOOK_RECOMMENDATION_ENABLED: string = 'ocpsoutlookrecommendationenabled';
export const USER_OCPS_OPTIONAL_CONNECTED_EXPERIENCES_ENABLED: string =
    'ocpsoptionalconnectedexperiencesenabled';
export const USER_OCPS_EXPERIENCES_DOWNLOADING_CONTENT_ENABLED =
    'ocpsexperiencesdownloadingcontentenabled';
export const USER_OCPS_EXPERIENCES_ANALYZING_CONTENT_ENABLED =
    'ocpsexperiencesanalyzingcontentenabled';
export const USER_OCPS_DISABLE_SHARING_PERMISSIONS = 'ocpsdisablesharingpermissions';

export const USER_OCPS_CONTACT_SUPPORT_ENABLED: string = 'ocpscontactsupportenabled';
export const USER_OCPS_DIAGNOSTICS_TROUBLESHOOTING_ENABLED: string =
    'ocpsdiagnosticstroubleshootingenabled';
export const USER_OCPS_POLICY_STORE = 'OcpsPolicyStore';

export const FEEDBACK_SETTING_ID = 'office16;L_SendFeedback';
export const EMAILCOLLECTION_SETTING_ID = 'office16;L_EmailCollection';
export const EMAILCOLLECTION_DEFAULT_SETTING_ID =
    'office16;L_FeedbackOptionsDefaults;L_FeedbackOptionsDefaultsEmail';
export const NPS_SURVEY_SETTING_ID = 'office16;L_SendSurvey';
export const SCREENSHOT_SETTING_ID = 'office16;L_Screenshot';
export const SCREENSHOT_DEFAULT_SETTING_ID =
    'office16;L_FeedbackOptionsDefaults;L_FeedbackOptionsDefaultsScreenshot';
export const LOG_CONTENT_SETTING_ID = 'office16;L_LogCollection';
export const CONTENT_SAMPLES_DEFAULT_SETTING_ID =
    'office16;L_FeedbackOptionsDefaults;L_FeedbackOptionsDefaultsContentSamples';
export const RECOMMEND_OUTLOOK_SETTING_ID = 'outlk16;L_RecommendOutlookExtension';
export const CHEKIN_INTERVAL_HEADER_KEY = 'CheckIn-Interval';
export const OPTIONAL_CONNECTED_EXPERIENCES_SETTING_ID = 'office16;L_OptionalConnectedExperiences';
export const EXPERIENCES_DOWNLOADING_CONTENT_SETTING_ID =
    'office16;L_OfficeExperiencesDownloadingContent';
export const CONNECTED_OFFICE_EXPERIENCES_ID = 'office16;L_ConnectedOfficeExperiences';
// Beware of the typo in the following constant name: "Anlayzing" instead of "Analyzing"
// Unfortunately, this is the way that it is stored in the server
export const EXPERIENCES_ANALYZING_CONTENT_SETTING_ID =
    'office16;L_OfficeExperiencesAnlayzingContent';
export const LOOP_SETTING_ID = 'office16;L_Loop';
export const LOOP_SETTING_ID_FOR_OUTLOOK = 'outlk16;L_Loop_Outlook';
export const PERMISSION_SHARING_ID = 'outlk16;L_TurnOffSharingRecommendation';

export const CONTACT_SUPPORT_SETTING_ID = 'outlooknew;L_ContactSupport';
export const GET_DIAGNOSTICS_SETTING_ID =
    'office16;L_ConfigureGetDiagnostics;L_ConfigureGetDiagnosticsEnum';
export const DIAGNOSTICS_TROUBLESHOOTING_SETTING_ID =
    'outlooknew;L_EnableDiagnosticsTroubleshooting';

export const M365_LINKS_HANDLING_BROWSER_CHOICE_SETTING_ID = 'office16;L_M365LinksBrowserChoice';
export const LOOP_POLLS_SETTING_ID = 'office16;L_Loop_Polls';
export const SHOW_CARD_LOOPS = 'outlk16;L_ShowOutlookLoopComponentsForSupportedApps'; // controls if card loops should be unfurled by default on the reading pane
export const LOOP_PLANNER_SETTING_ID = 'office16;L_Loop_Planner';
export const LOOP_VIDEO_PLAYBACK_SETTING_ID = 'office16;L_Stream_Video_Preview_and_Playback';

// Beware of the duplicae office16 prefix.
// Unfortunately this is the way that it is stored in the server and cannot be fixed.
export const LOOP_VIDEO_RECORD_SETTING_ID = 'office16;office16;L_Stream_Video_Record';
