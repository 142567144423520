import { optionTitle_mailHandling } from './MailHandlingOption.locstring.json';
import type { LazyOption } from 'owa-options-core';
import { createLazyOption } from 'owa-options-core';
import { LazyModule } from 'owa-bundling';
import { IsShadowMailboxUser } from 'owa-mail-ads-shared/lib/sharedAdsUtils';

const lazyFullModule = new LazyModule(
    () => import(/* webpackChunkName: "MailHandlingOption" */ './lazyFullOption'),
    { name: 'MailHandlingOption' }
);

const MailHandlingOption: LazyOption = createLazyOption(
    {
        key: 'mailHandlingOption',
        titleStringKey: optionTitle_mailHandling,
        searchTermsStringKey: optionTitle_mailHandling,
        isSupportedForAccount: mailboxInfo => !IsShadowMailboxUser(mailboxInfo),
    },
    lazyFullModule,
    m => m.default
);

export default MailHandlingOption;
