import type OofRollUpViewState from 'owa-mail-reading-pane-store/lib/store/schema/OofRollUpViewState';
import { OOF_ITEM_CLASS_REGEX } from 'owa-mail-store/lib/utils/constants';
import { isFeatureEnabled } from 'owa-feature-flags';

export function createOofRollUpViewState(itemClass: string): OofRollUpViewState {
    return {
        isOofItem: OOF_ITEM_CLASS_REGEX.test(itemClass),
        oofReplyNodeIds: [],
        isOofRollUpExpanded: isFeatureEnabled('rp-expand-all'),
    };
}
