import { LazyModule, LazyAction, createLazyComponent } from 'owa-bundling';
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GuidedSetupCards" */ './lazyIndex'),
    { name: 'GuidedSetupCards' }
);
export const lazymountOfficeAppCard = new LazyAction(lazyModule, m => m.mountOfficeAppCard);
export const lazymountPhoneAppCard = new LazyAction(lazyModule, m => m.mountPhoneAppCard);

export const EmptyStateMobilePromoComponent = createLazyComponent(
    lazyModule,
    m => m.EmptyStateMobilePromo
);

export { OutlookMobileContainer } from './utils/OutlookMobileContainer';
