import type { PerformanceCoreDatapoint, PerformanceDatapoint } from 'owa-analytics';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';

/**
 * Peformance datapoint that tracks end to end time from selection of an email in message list to rendering the content on reading pane.
 */
let selectMailItemDp: PerformanceCoreDatapoint | PerformanceDatapoint | undefined = undefined;
let smiCustomDataRawArguments:
    | {
          mailListItemSelectionSource: MailListItemSelectionSource;
          tableView: TableView;
          rowKey: string;
      }
    | undefined = undefined;

export function getSelectMailItemDatapoint():
    | PerformanceCoreDatapoint
    | PerformanceDatapoint
    | undefined {
    return selectMailItemDp;
}

export function setSelectMailItemDatapoint(
    datapoint: PerformanceCoreDatapoint | PerformanceDatapoint | undefined
) {
    selectMailItemDp = datapoint;
}

export function setSmiCustomDataRawArguments(
    mailListItemSelectionSource: MailListItemSelectionSource,
    tableView: TableView,
    rowKey: string
) {
    smiCustomDataRawArguments = {
        mailListItemSelectionSource,
        tableView,
        rowKey,
    };
}

export function getSmiCustomDataRawArguments() {
    return smiCustomDataRawArguments;
}

export function resetSmiCustomDataRawArguments() {
    smiCustomDataRawArguments = undefined;
}
