import { useEffect, type DependencyList, type EffectCallback } from 'react';
import { yieldNow } from 'owa-task-queue/lib/schedule';

/**
 * A hook that runs a side effect after the component has rendered.
 */
export function useSideEffect(source: string, effectCb: EffectCallback, deps?: DependencyList) {
    useEffect(() => {
        const destPromise = yieldNow(`useSideEffect:${source}`).then(() => effectCb());
        return () => {
            // the cast to any is because this fails the build in non strict mode
            destPromise.then(destructor => (destructor as any)?.());
        };
    }, deps);
}
