import type MailListItemAnimationStore from './schema/MailListItemAnimationStore';
import { createStore } from 'owa-satcheljs';

const MailListItemAnimationDefaultStore: MailListItemAnimationStore = {
    activeAnimations: [],
    pendingAnimations: [],
};

export const mailListItemAnimationStore = createStore<MailListItemAnimationStore>(
    'MailItem',
    MailListItemAnimationDefaultStore
)();
