import { createStore } from 'owa-satcheljs';
import type CommandBarActionStore from './schema/CommandBarActionStore';

const defaultCommandBarActionStore = {
    surfaceActions: [],
    overflowActions: [],
};

const store = createStore<CommandBarActionStore>(
    'commandBarAction',
    defaultCommandBarActionStore
)();
export default store;
export const getStore = () => store;
