import type { MailboxInfo } from 'owa-client-types';
import getFolderTable from '../selectors/getFolderTable';
import {
    ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
    ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
} from 'owa-folders-constants';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getMailboxInfoForArchive } from 'owa-mailbox-info/lib/getMailboxInfoForArchive';
import { getMailboxInfoForAuxArchive } from 'owa-mailbox-info/lib/getMailboxInfoForAuxArchive';
import { getMailboxInfoForCoprincipalAccount } from 'owa-mailbox-info/lib/getMailboxInfoForCoprincipalAccount';
import { getMailboxInfoForShared } from 'owa-mailbox-info/lib/getMailboxInfoForShared';
import type { MailFolder } from 'owa-graph-schema';

function getMailboxInfoForFolder(
    folderId: string,
    folder: MailFolder | undefined,
    mailboxInfo: MailboxInfo,
    routeToAuxIfAuxArchive: boolean
): MailboxInfo {
    let auxArchiveMailboxGuid;

    if (folder?.mailboxInfo.type === 'SharedMailbox') {
        return getMailboxInfoForShared(mailboxInfo, folder.mailboxInfo.mailboxSmtpAddress);
    } else if (
        folderId == ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID ||
        folderId == ARCHIVE_DUMPSTER_DISTINGUISHED_ID ||
        folder?.mailboxInfo.type == 'ArchiveMailbox'
    ) {
        // folderId check is required because when fetching the folder list for the archive, the root folder is not
        // yet present in the folder table
        auxArchiveMailboxGuid = folder?.replicaList?.[0];

        // Most requests should be routed to the mailbox the folder is in.
        // However, for folder operations on auxilliary archive folders,
        // the request always needs to go to the main archive mailbox.
        if (auxArchiveMailboxGuid && routeToAuxIfAuxArchive) {
            return getMailboxInfoForAuxArchive(mailboxInfo, auxArchiveMailboxGuid);
        }

        return getMailboxInfoForArchive(mailboxInfo);
    } else {
        return getMailboxInfoForCoprincipalAccount(mailboxInfo);
    }
}

/**
 * Fetches mailbox info for a given folder
 * @param folderId Id of the folder
 * @param routeToAuxIfAuxArchive whether to route the request to the the aux archive if the folder
 * belongs to aux archive mailbox (should only be false for some folder operations).
 */
export function getMailboxInfoForFolderId(
    folderId: string,
    routeToAuxIfAuxArchive: boolean
): MailboxInfo {
    const folderTable = getFolderTable();
    const folder = folderTable?.get(folderId);

    const mailboxInfo: MailboxInfo = folder?.mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    return getMailboxInfoForFolder(folderId, folder, mailboxInfo, routeToAuxIfAuxArchive);
}
