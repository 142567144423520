import { isGroupTableQuery } from 'owa-group-utils';
import type { TableView } from 'owa-mail-list-store';
import type { MailboxInfo } from 'owa-client-types';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';

// Folders where archive action is not supported
const RESTRICTED_FOLDERS_FOR_ARCHIVE = [
    'drafts',
    'junkemail',
    'deleteditems',
    'archive',
    'notes',
    'outbox',
    'recoverableitemsdeletions', // PRIMARY_DUMPSTER_DISTINGUISHED_ID
    'archiverecoverableitemsdeletions', // ARCHIVE_DUMPSTER_DISTINGUISHED_ID
];

export default function isArchiveSupported(
    tableView: TableView,
    mailboxInfo: MailboxInfo,
    folderName: DistinguishedFolderIdName
): boolean {
    if (RESTRICTED_FOLDERS_FOR_ARCHIVE.includes(folderName)) {
        return false;
    }

    // If the archive folder does not exist, archiving is disabled
    if (folderNameToId('archive', mailboxInfo) == '') {
        return false;
    }

    const mailboxType = mailboxInfo?.type;

    // Archiving is disabled for shared mailboxes, archive mailboxes, and group mailboxes
    if (
        mailboxType === 'SharedMailbox' ||
        mailboxType === 'ArchiveMailbox' ||
        mailboxType === 'GroupMailbox'
    ) {
        return false;
    }

    // Archiving is disabled for groups
    if (isGroupTableQuery(tableView.tableQuery)) {
        return false;
    }

    return true;
}
