import { createLazyComponent, LazyModule, LazyAction } from 'owa-bundling';
import { getStore } from './store';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "TestService" */ './lazyIndex'),
    { name: 'TestService' }
);

// Delay loaded components
export const TestService = createLazyComponent(
    lazyModule,
    m => m.TestService,
    undefined,
    undefined,
    { onlyImportIf: () => getStore().showTestService }
);

export const lazySetShowTestService = new LazyAction(lazyModule, m => m.setShowTestService);
