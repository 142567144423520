import { observer } from 'owa-mobx-react';
import FocusedInboxPivot from './FocusedInboxPivot';
import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import type KeyboardCharCodes from 'owa-hotkeys/lib/utils/keyboardCharCodes';
import { selectFocusedViewFilter } from 'owa-mail-triage-table-utils';
import mailStore from 'owa-mail-store/lib/store/Store';
import { lazyMoveMailListRowsToFocusedOrOther } from 'owa-mail-triage-action';
import type { MailListRowDragData } from 'owa-mail-types/lib/types/MailListRowDragData';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import React from 'react';
import {
    getFocusedOtherDropViewState,
    getFocusedFilterForTable,
    getStore as getListViewStore,
    getSelectedTableView,
} from 'owa-mail-list-store';
import type { UserSettingsStyleSelector } from '../../utils/getStyleSelectorAsPerUserSettings';

export interface FocusedInboxHeaderProps {
    tableViewId: string;
    mailListHeaderStylesAsPerUserSettings?: UserSettingsStyleSelector;
}

export default observer(function FocusedInboxHeader(props: FocusedInboxHeaderProps) {
    /**
     * Called when user presses keyboard on the focused other button in listview
     */
    const onKeyDownOnFocusedOther = React.useCallback(
        (ev: React.KeyboardEvent<HTMLElement>) => {
            const tableView = getListViewStore().tableViews.get(props.tableViewId);
            const isFocusedSelected = getFocusedFilterForTable(tableView) == 0;
            switch (ev.keyCode) {
                case 32:
                    ev.stopPropagation();
                    ev.preventDefault();
                    selectFocusedViewFilter(isFocusedSelected ? 1 : 0, 'Keyboard');
                    break;
            }
        },
        [props.tableViewId]
    );

    return (
        <FocusedInboxPivot
            tableViewId={props.tableViewId}
            onDropFocused={onDropFocusedPill}
            onDropOther={onDropOtherPill}
            onKeyDown={onKeyDownOnFocusedOther}
            isSupportedItemTypeForDragDrop={isSupportedItemTypeForDragDrop(
                mailStore.typeOfItemBeingDragged
            )}
            shouldShowOnDropHoverTreatment={getFocusedOtherDropViewState().isDragOver}
        />
    );
}, 'FocusedInboxHeader');

const onDropFocusedPill = (dragInfo: DragData) => {
    onDropPill(dragInfo, 0);
};

const onDropOtherPill = (dragInfo: DragData) => {
    onDropPill(dragInfo, 1);
};

const onDropPill = (dragInfo: DragData, viewFilter: FocusedViewFilter) => {
    const selectedTableView = getSelectedTableView();
    if (
        isSupportedItemTypeForDragDrop(dragInfo.itemType) &&
        getFocusedFilterForTable(selectedTableView) !== viewFilter
    ) {
        const mailListRowDragData = dragInfo as MailListRowDragData;
        lazyMoveMailListRowsToFocusedOrOther.importAndExecute(
            mailListRowDragData.rowKeys,
            mailListRowDragData.tableViewId,
            viewFilter,
            'Drag'
        );
    }
};

const isSupportedItemTypeForDragDrop = (itemType: string | null): boolean => {
    return (
        itemType == DraggableItemTypes.MailListRow ||
        itemType == DraggableItemTypes.MultiMailListConversationRows ||
        itemType == DraggableItemTypes.MultiMailListMessageRows
    );
};
