import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * This is used to render the app root, which is allowed */
import { render } from 'owa-react-dom';
import { addBottleneck, markFunction, getPltPromise } from 'owa-performance';
import { setScriptsLoadedAtPlt } from 'owa-shared-post-boot/lib/scriptsLoadedAtPlt';
import { removeLoadingScreen } from './removeLoadingScreen';
import type { LoaderOptions } from './types/LoaderOptions';
import { getConfig } from 'owa-service/lib/config';
import { patchRaf } from './patchRaf';

import { timeout } from 'owa-sleep/lib/timeout';
const PLT_PROMISE_TIMEOUT = 2000;

export default function asyncRender(
    node: JSX.Element,
    getContainer: () => HTMLElement,
    loaderOptions?: LoaderOptions
): Promise<void> {
    return new Promise<void>(function (resolve: () => void, reject: (reason: any) => void) {
        const serviceConfig = getConfig();
        const shouldDelayPlt =
            loaderOptions?.delayRender && serviceConfig.isFeatureEnabled('fwk-loader-delay');
        const shouldPatchRaf = shouldDelayPlt && serviceConfig.isFeatureEnabled('fwk-sync-raf');
        const restoreRaf = shouldPatchRaf ? patchRaf() : undefined;

        function completeBoot() {
            restoreRaf?.();
            removeLoadingScreen(loaderOptions);
            resolve();
        }
        function onMount() {
            setScriptsLoadedAtPlt();

            if (shouldDelayPlt) {
                timeout(getPltPromise(), PLT_PROMISE_TIMEOUT).then(completeBoot, completeBoot);
            } else {
                completeBoot();
            }
        }
        function Component() {
            React.useEffect(onMount, []);
            return node;
        }

        const asyncRenderInternal = markFunction((bottleneck: string) => {
            addBottleneck('DR', bottleneck);

            try {
                render(<Component />, getContainer());
            } catch (error) {
                reject(error);
            }
        }, 'ren');

        if (window.document.readyState != 'loading') {
            // Render on a separate task
            setTimeout(() => asyncRenderInternal('R'), 0);
        } else if (window.document.addEventListener as any) {
            addDomLoadedEvent(asyncRenderInternal);
        } else {
            window.onload = () => asyncRenderInternal('OL');
        }
    });
}

const domLoadedEvent = 'DOMContentLoaded';
function addDomLoadedEvent(asyncRenderInternal: (bottleneck: string) => void) {
    function domLoaded() {
        window.document.removeEventListener(domLoadedEvent, domLoaded);
        asyncRenderInternal('EL');
    }
    window.document.addEventListener(domLoadedEvent, domLoaded, false);
}
