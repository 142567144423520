import { PerformanceDatapoint } from 'owa-analytics';
import type { ActionSource } from 'owa-mail-store';
import { setMailListLoadDatapoint } from './mailListLoadDatapointGetterAndSetter';
import endMailListLoadDatapoint from './endMailListLoadDatapoint';

let eventTimestamp: number | undefined = undefined;

export function setInteractionTimestamp(timeStamp?: number) {
    eventTimestamp = timeStamp;
}

export function createMailListLoadDatapoint(actionSource: ActionSource, tableViewId: string) {
    // End the previous MLL datapoint if it did not complete before a new ML load.
    endMailListLoadDatapoint(
        'None' /*componentRendered*/,
        tableViewId,
        true /* shouldInvalidate */
    );

    const options = {
        timeout: 90 * 1000,
        eventTimestamp,
    };

    const mllDp = new PerformanceDatapoint('MailListLoad', options);
    mllDp.addCustomData({ act: actionSource });
    setMailListLoadDatapoint(mllDp);
}
