/**
 * Converts a base 64 string data to a blob
 */
export default function createImageBlob(base64Data: string) {
    const imageContent = atob(base64Data);
    const contentLength = imageContent.length;
    const byteArray = new Uint8Array(new ArrayBuffer(contentLength));

    for (let i = 0; i < contentLength; i++) {
        byteArray[i] = imageContent.charCodeAt(i);
    }

    return new Blob([byteArray], { type: 'image/jpeg' });
}
