import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ResourceId } from 'owa-localize';

export const ComposeTabs = [10, 5, 4, 12, 11];

export const MailRibbonTabTitleMap: Map<MailRibbonTabId, ResourceId> = new Map<
    MailRibbonTabId,
    ResourceId
>([
    [4, Strings.format_tab],
    [5, Strings.insert_tab],
    [10, Strings.message_tab],
    [11, Strings.options_tab],
    [7, Strings.format_table_tab],
    [12, Strings.draw_tab],
    [13, Strings.format_picture_tab],
]);
