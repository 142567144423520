import type { AccountKey } from 'owa-client-types';

export interface GroupMemberRequestData {
    groupId: string;
    requestedUserId: string;
    requestedUserLabel: string;
    requestorUserId: string;
    requestorUserLabel: string;
    timeStamp: Date;
}

export enum LoadState {
    NotLoaded,
    Loading,
    Aborted,
    Loaded,
    Failed,
}

export interface GroupActivityState {
    memberRequestsData: GroupMemberRequestData[];
    loadState: LoadState;
}

export interface GroupActivityStateByMailboxInfo {
    groupActivityStateByMailboxInfo: Map<AccountKey, GroupActivityState>;
}
