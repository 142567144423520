import type PopoutParentStore from './schema/PopoutParentStore';
import { createStore } from 'owa-satcheljs';

export let resolvePopoutProjectionMountedPromise: (value: boolean) => void;
export const popoutProjectionMountedPromise: Promise<boolean> = new Promise<boolean>(resolve => {
    resolvePopoutProjectionMountedPromise = resolve;
});

const parentStoreData: PopoutParentStore = {
    projections: [],
    isAvailable: false,
};

const parentStore = createStore<PopoutParentStore>('popoutParent', parentStoreData)();
export default parentStore;
export const getStore = () => parentStore;
