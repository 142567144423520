import { mutatorAction } from 'satcheljs';
import getOrCreateSxSStoreData, { getStore } from '../store/Store';
import { SXSID_MAIN_WINDOW } from '../store/schema/SxSStore';

const moveSxSStoreMutator = mutatorAction('setType', (sxsId: string | null, newSxSId: string) => {
    const sxsStore = getOrCreateSxSStoreData(sxsId);
    getStore().sxsStoreMapping.delete(sxsId || SXSID_MAIN_WINDOW);
    getStore().sxsStoreMapping.set(newSxSId, sxsStore);
});

export default moveSxSStoreMutator;
