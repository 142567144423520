import { yieldNow } from 'owa-task-queue/lib/schedule';

export type OwaEntryType =
    | 'delayed-post-message'
    | 'element'
    | 'event'
    | 'layout-shift'
    | 'long-animation-frame'
    | 'longtask'
    | 'paint';

const entryTypes: {
    [P in OwaEntryType]: 1;
} = {
    'delayed-post-message': 1,
    element: 1,
    event: 1,
    'layout-shift': 1,
    'long-animation-frame': 1,
    longtask: 1,
    paint: 1,
};

type PerformanceObserverCallback = (entry: PerformanceEntryList) => void;
interface PerformanceObserverWrapper {
    id: number;
    source: string;
    types: Record<OwaEntryType, 1>;
    callback: PerformanceObserverCallback;
}

let callbackNumber = 0;

let performanceObserverCbs: PerformanceObserverWrapper[] | undefined;

export function addPerfObserverCallback(
    source: string,
    type: OwaEntryType | OwaEntryType[],
    cb: PerformanceObserverCallback
): (() => void) | undefined {
    if ('PerformanceObserver' in self && 'supportedEntryTypes' in self.PerformanceObserver) {
        const filteredEntryTypes = Object.keys(entryTypes).filter(e =>
            PerformanceObserver.supportedEntryTypes.includes(e)
        );
        if (filteredEntryTypes.length > 0) {
            const types = typeof type === 'string' ? [type] : type;
            if (!performanceObserverCbs) {
                performanceObserverCbs = [];
                const observer = new PerformanceObserver(
                    async (list: PerformanceObserverEntryList) => {
                        const entries = list.getEntries();
                        if (performanceObserverCbs) {
                            for (const wrapper of performanceObserverCbs) {
                                const filteredEntries = entries.filter(
                                    e => wrapper.types[e.entryType as OwaEntryType]
                                );
                                if (filteredEntries.length > 0) {
                                    await yieldNow(`perfObserver:${wrapper.source}`);
                                    wrapper.callback(filteredEntries);
                                }
                            }
                        }
                    }
                );

                observer.observe({ entryTypes: filteredEntryTypes });
            }

            const id = callbackNumber++;
            performanceObserverCbs.push({
                id,
                types: types.reduce(
                    (acc, t) => ({ ...acc, [t]: 1 }),
                    {} as Record<OwaEntryType, 1>
                ),
                callback: cb,
                source,
            });

            return () => {
                if (performanceObserverCbs) {
                    const idx = performanceObserverCbs.findIndex(w => w.id === id);
                    performanceObserverCbs.splice(idx, 1);
                }
            };
        }
    }
    return undefined;
}

export function isPerfObserverTypeSupported(type: OwaEntryType) {
    return (
        'PerformanceObserver' in self &&
        'supportedEntryTypes' in self.PerformanceObserver &&
        PerformanceObserver.supportedEntryTypes.includes(type)
    );
}
