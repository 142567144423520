import type { MailComposeTabViewState } from 'owa-tab-store';
import { composeStore } from 'owa-mail-compose-store';
import type { ComposeViewState } from 'owa-mail-compose-store';

/**
 * Finds the first active compose tab where the OnSend event is triggered.
 *
 * This function iterates through the provided active compose tabs and checks
 * if the OnSend event is triggered for any of them. If found, it returns the
 * corresponding compose tab.
 *
 * @param {MailComposeTabViewState[]} activeComposeTabs - An array of active compose tabs.
 * @returns {MailComposeTabViewState | undefined} The first active compose tab where the OnSend event is triggered, or undefined if none is found.
 */
export function findAddinTriggeredComposeViewState(
    activeComposeTabs: MailComposeTabViewState[]
): MailComposeTabViewState | undefined {
    for (const activeComposeTab of activeComposeTabs) {
        const composeId = activeComposeTab.data;
        let composeViewState: ComposeViewState | undefined;

        if (composeId) {
            composeViewState = composeStore.viewStates.get(composeId);
        }

        if (!composeViewState) {
            const primaryComposeId = composeStore.primaryComposeId;
            if (primaryComposeId) {
                composeViewState = composeStore.viewStates.get(primaryComposeId);
            }
        }

        if (composeViewState?.addin.isOnSendEventTriggered) {
            return activeComposeTab;
        }
    }
    return undefined;
}
