import { owaComputedFn } from 'owa-computed-fn';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { RibbonTabId, RibbonGroupId } from 'owa-ribbon-ids';
import { type MailRibbonTabId, type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type {
    RibbonControlDefinitionProps,
    ComposeRibbonControlDefProps,
} from './RibbonControlDefinitionProps';
import { getRibbonMailboxInfo } from './getRibbonMailboxInfo';
import { isCapabilityEnabled } from 'owa-capabilities';
import { populateAddinsCapability } from 'owa-capabilities-definitions/lib/populateAddinsCapability';
import { isRemindersPopupEnabled } from 'monarch-desktop-notifications-settings/lib/selectors/desktopNotificationsEnabledHelpers';
import { hasPaidAccount } from 'owa-mail-ribbon-store';
import { isTeamsChatEnabled } from 'owa-teams-integration/lib/utils/isTeamsChatEnabled';
import { hasUserSelectedThirdPartyMeetingProvider } from 'owa-thirdparty-im-integration/lib/utils/hasUserSelectedThirdPartyMeetingProvider';

/**
 * This function decides whether a group should show or not.
 * For any logic on show/hiding groups, please put them here.
 *
 * @tabId the current Tab that is calling this function
 * @groupId the current group being tested to see whether it should show
 * @props Optional parameter, used for certain buttons to test whether they should show.
 */
export const shouldShowRibbonGroup = owaComputedFn(function shouldShowRibbonGroup(
    tab: RibbonTabId,
    groupId: RibbonGroupId,
    props: RibbonControlDefinitionProps | undefined
): boolean {
    // Customization flight check
    if (groupId === 122) {
        return !(props as ComposeRibbonControlDefProps)?.composeViewState;
    }
    const mailboxInfo = getRibbonMailboxInfo(props?.mailboxInfo);
    if (tab == 1) {
        switch (groupId) {
            case 101:
                return isCapabilityEnabled(populateAddinsCapability, mailboxInfo);

            case 120:
                return hasPaidAccount() && mailboxInfo.type != 'GroupMailbox';

            case 192:
                return isFeatureEnabled('addin-enableIntegratedSpam');

            case 198:
                return (
                    (isFeatureEnabled('mon-teams-ChatAroundEmail') ||
                        isFeatureEnabled('mon-teams-ShareToTeamsChat')) &&
                    isTeamsChatEnabled(mailboxInfo)
                );
            case 200:
                return (
                    isFeatureEnabled('thirdParty-chatIntegration') &&
                    hasUserSelectedThirdPartyMeetingProvider(mailboxInfo)
                );
            default:
                return true;
        }
    } else if (tab == 10) {
        return true;
    } else if (tab == 3) {
        switch (groupId) {
            case 194:
                return isRemindersPopupEnabled();
            default:
                return true;
        }
    } else if (tab == 5) {
        return true;
    } else if (tab == 8) {
        switch (groupId) {
            case 198:
                return (
                    (isFeatureEnabled('mon-teams-ChatAroundEmail') ||
                        isFeatureEnabled('mon-teams-ShareToTeamsChat')) &&
                    isTeamsChatEnabled(mailboxInfo)
                );

            default:
                return true;
        }
    }

    return true;
});
