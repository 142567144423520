import type { M365Acquisition } from 'owa-m365-acquisitions/lib/types';
import { FirstPartyAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';

// Keep in sync with packages/calendar-common/forms/compose/owa-calendar-compose-form-lifecycle/src/actions/openCalendarFullComposeFromDropData.ts
const allowedCalendarExternalDropContent: string[] = [
    DraggableItemTypes.MailListItemPart,
    DraggableItemTypes.MailListRow,
    DraggableItemTypes.MultiMailListConversationRows,
    DraggableItemTypes.MultiMailListMessageRows,
    DraggableItemTypes.LocalFile,
    DraggableItemTypes.Attachment,
];

/**
 * Returns the allowed external drop content for a given application.
 *
 * If adding drag-and-drop functionality for an app, add a new allowedExternalDropContent array
 * as well as a corresponding case in the below switch statement.
 */
export function getAllowedExternalDropContent(acquisition: M365Acquisition): string[] {
    let allowedExternalDropContent: string[] = [];
    switch (acquisition.appId) {
        case FirstPartyAppId.Calendar:
            allowedExternalDropContent = allowedCalendarExternalDropContent;
            break;
        default:
            break;
    }
    return allowedExternalDropContent;
}
