import type { SelectionType } from 'owa-addins-view/lib/utils/navigations/SelectionType';
import { lazyOnItemNavigation } from 'owa-addins-view';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';

export default async function updateAddinOnItemNavigation(
    selectionType: SelectionType,
    mailboxInfo: MailboxInfo
) {
    if (!isConsumer(undefined /* smtpAddress */, mailboxInfo)) {
        await lazyOnItemNavigation.importAndExecute(selectionType, '' /*hostItemIndex*/);
    }
}
