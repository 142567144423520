import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { saveGeneralPreferences } from '../utils/saveGeneralPreferences';
import type { GeneralPreferences } from '../store/store';

export const onSaveGeneralPreferencesMutator = mutatorAction(
    'onSaveGeneralPreferencesMutator',
    (mailboxInfo: MailboxInfo, preferences?: GeneralPreferences) => {
        saveGeneralPreferences(mailboxInfo, preferences);
    }
);
