import { lazyGetConversationsSections } from 'owa-mail-ribbon-sections/lib/sections';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onConversationsMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetConversationsSections.import().then(getConversationsSections => {
        setMenuDefinition(
            'ConversationsMenuDefinitionStore',
            undefined /* projectionTabId */,
            () => {
                return {
                    sections: getConversationsSections(props),
                };
            }
        );
    });
};
