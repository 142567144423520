import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "LoopNav" */ './lazyIndex'), {
    name: 'LoopNav',
});

export const lazyFocusNextEvent = new LazyAction(lazyModule, m => m.focusNextEvent);
export const lazyFocusNextElement = new LazyAction(
    lazyModule,
    m => m.ModuleNavigationManager.focusNextElement
);
export const lazySetAppSections = new LazyAction(
    lazyModule,
    m => m.ModuleNavigationManager.initAppSections
);
export const lazyOnKeyDownEvent = new LazyAction(lazyModule, m => m.onKeyDownEvent);
export const lazyArrangeCalendarSections = new LazyAction(
    lazyModule,
    m => m.arrangeCalendarSections
);
export const lazyIsFocusingElement = new LazyAction(
    lazyModule,
    m => m.ModuleNavigationManager.isFocusingElement
);
export const lazyFocusSection = new LazyAction(
    lazyModule,
    m => m.ModuleNavigationManager.focusSection
);

export type { default as NavigationManager } from './NavigationManager';
