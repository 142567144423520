import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import { getSelectedCalendars } from '../selectors/getSelectedCalendars';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const removeSelectedCalendarsForUser = mutatorAction(
    'removeSelectedCalendarsForUser',
    (mailboxInfo: MailboxInfo) => {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        getSelectedCalendars().delete(key);
    }
);
