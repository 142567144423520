import type AttachmentPreviewWellView from './schema/AttachmentPreviewWellView';
import type ConversationItem from './schema/ConversationItem';
import type ListViewState from './schema/ListViewState';
import type ListViewStore from './schema/ListViewStore';
import type TableView from './schema/TableView';
import type TableViewConversationRelation from './schema/TableViewConversationRelation';
import type TableViewItemRelation from './schema/TableViewItemRelation';
import type { AttachmentFullViewState } from 'owa-attachment-well-data';
import { createStore } from 'owa-satcheljs';
import type { ClientItem } from 'owa-mail-store';
import type ActionButtonData from 'txp-data/lib/schema/viewSchema/ActionButtonData';
import type ReactionsGroupedByType from 'owa-service/lib/contract/ReactionsGroupedByType';
import type { CalendarEvent } from 'owa-calendar-types';

const defaultListViewStore: ListViewStore = {
    tableViews: new Map<string, TableView>([]),
    conversationItems: new Map<string, ConversationItem>([]),
    tableViewConversationRelations: new Map<string, TableViewConversationRelation>([]),
    tableViewItemRelations: new Map<string, TableViewItemRelation>([]),
    attachmentViewStates: new Map<string, AttachmentFullViewState>([]),
    rowAttachmentPreviewWellViews: new Map<string, AttachmentPreviewWellView>([]),
    meetingMessageItems: new Map<string, ClientItem>([]),
    calendarEvents: new Map<string, CalendarEvent>([]),
    txpActionButtonData: new Map<string, ActionButtonData>([]),
    listViewStateByModule: new Map<string, ListViewState>([]),
    reactionInformationByRowId: new Map<
        string,
        {
            reactions: ReactionsGroupedByType[];
            lastRequested: number;
        }
    >([]),
    shouldShowSelectedRowInRp: true,
};

const listViewStore = createStore<ListViewStore>('listview', defaultListViewStore)();
const getStore = () => listViewStore;
export default getStore;
