import isGeneric1PAddinSupported from './isGeneric1PAddinSupported';
import { isConsumer } from 'owa-session-store';
import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export default function getCapabilitiesParams(mailboxInfo?: MailboxInfo) {
    const capabilitiesParams = [];

    mailboxInfo = mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();

    if (isGeneric1PAddinSupported(mailboxInfo)) {
        capabilitiesParams.push('extensibility');
    }

    if (isConsumer(undefined /* smtpAddress */, mailboxInfo)) {
        capabilitiesParams.push('M365ConsumerCredits');
    }

    return capabilitiesParams;
}
