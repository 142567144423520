import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { hasQueryStringParameter, getQueryStringParameter, removeQuery } from 'owa-querystring';
import distinguishedFolderId from 'owa-service/lib/factory/distinguishedFolderId';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import findFolderByDisplayName from 'owa-service/lib/findFolderByDisplayName';
import { tryLoadAttachmentsSearchFolder } from './tryLoadAttachmentsSearchFolder';
import { ATTACHMENTS_SEARCH_FOLDER_NAME } from 'owa-folders-constants';

const MODE_PARAMETER_NAME: string = 'Mode';
const MODE_PARAMETER_VALUE: string = 'FreeUpMSQSpace';
export async function checkAttachmentsSearchFolderAndLoad() {
    if (hasQueryStringParameter(MODE_PARAMETER_NAME)) {
        const filterValue: string = getQueryStringParameter(MODE_PARAMETER_NAME);
        if (filterValue === MODE_PARAMETER_VALUE) {
            const newSearch = removeQuery(location.search, MODE_PARAMETER_NAME);
            history.replaceState(history.state, '/', location.pathname + newSearch);
            const mailboxInfo = getModuleContextMailboxInfo();
            const attachmentsSearchFolder = await findFolderByDisplayName(
                distinguishedFolderId({ Id: 'root' }),
                ATTACHMENTS_SEARCH_FOLDER_NAME,
                getMailboxRequestOptions(mailboxInfo)
            );

            if (attachmentsSearchFolder?.FolderId?.Id) {
                tryLoadAttachmentsSearchFolder(
                    attachmentsSearchFolder.FolderId?.Id,
                    mailboxInfo,
                    false /* shouldFallbackToOpenInboxInNewTab */
                );
            }
        }
    }
}
