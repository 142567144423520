import { readM365AcquisitionsFromCache } from 'owa-m365-acquisitions/lib/readM365AcquisitionsFromCache';
import { writeM365MessageExtensionsQuery } from 'owa-nova-cache/lib/transform/writeM365MessageExtensionsQuery';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';
import getExtensibilityState from 'owa-addins-store/lib/store/getExtensibilityState';
import isExtensibilityContextInitialized from 'owa-addins-store/lib/store/isExtensibilityContextInitialized';
import { lazyInitializeExtensibilityContext } from 'owa-addins-boot/lib/lazyFunctions';
import { writeM365MessageExtensionsQueryFromEnabledAddins } from 'owa-nova-cache/lib/transform/writeM365MessageExtensionsQueryFromEnabledAddins';
import { writeM365AppsQuery } from 'owa-nova-cache/lib/transform/writeM365AppsQuery';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { isCapabilityEnabled } from 'owa-capabilities';
import { populateAddinsCapability } from 'owa-capabilities-definitions/lib/populateAddinsCapability';
import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isAppBarEnabled } from './isAppBarEnabled';

/**
 * Writes cached acquisitions to Apollo UI queries (appbar, flyouts). This should only be
 * executed if mos-mos3AppService is enabled.
 */
export async function writeAcquisitionQueries(mailboxInfo?: MailboxInfo) {
    // Check if the current mailbox is the still the one that was switched to. It's possible that while fetching acquisitions from MOS3,
    // the user switched to another mailbox. In that case, we don't want to write the flyout queries. If there is no mailboxInfo,
    // multiaccount is disabled so there was no account switch
    if (
        !mailboxInfo ||
        getIndexerValueForMailboxInfo(getModuleContextMailboxInfo()) ===
            getIndexerValueForMailboxInfo(mailboxInfo)
    ) {
        // Fetch apps from cache for account being switched to
        const acquisitions = readM365AcquisitionsFromCache(undefined /*datapoint*/, mailboxInfo);

        if (isAppBarEnabled()) {
            await writeM365AppsQuery(acquisitions, mailboxInfo);
        }

        if (isMOS3AppServiceAvailable(mailboxInfo)) {
            // Write read/compose flyout UI queries from cached acquisitions
            await writeM365MessageExtensionsQuery(acquisitions, mailboxInfo);
        } else if (
            isCapabilityEnabled(
                populateAddinsCapability,
                mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()
            )
        ) {
            // Fetch addins from GEC/store instead of MOS3/apollo cache and write flyout queries using it
            if (!isExtensibilityContextInitialized(mailboxInfo)) {
                await lazyInitializeExtensibilityContext.importAndExecute(mailboxInfo);
            }

            writeM365MessageExtensionsQueryFromEnabledAddins(
                getExtensibilityState(mailboxInfo).EnabledAddinCommands,
                mailboxInfo
            );
        }
    }
}
