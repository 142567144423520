import type { M365ApplicationIcon } from 'owa-graph-schema';
import type { StrictM365Application, StrictM365ApplicationIcon } from '../types';
import type { RecursiveRequired } from 'owa-m365-acquisitions/lib/types';
import type { IAddin } from 'owa-addins-types';

export function convertAddinToM365Application(addin: IAddin): StrictM365Application {
    const PRIMARY_COLOR = '#0078D4';
    const displayIcon = generateIconSchema(addin.IconUrl);
    const m365ApplicationNode: StrictM365Application = {
        __typename: 'M365PlatformApplication',
        accessibleName: addin.DisplayName || '',
        badge: {
            __typename: 'M365ApplicationNumericBadge',
            count: 0,
            countDescription: '',
        },
        brandingColor: PRIMARY_COLOR,
        canLaunchOut: false,
        platformType: 'm365_addin',
        enablePlatformCommands: false,
        canBeUnacquired: false,
        icon: displayIcon,
        selectedStateIconOverride: displayIcon,
        outlineIcon: displayIcon,
        id: addin.Id || '',
        isInUse: false,
        isPinnable: false,
        lockedIndex: -1,
        name: addin.DisplayName || '',
        pinnedIndex: -1,
        secondaryActions: [],
        allowedExternalDropContent: [],
    };
    return m365ApplicationNode;
}

function generateIconSchema(icon: string | undefined): RecursiveRequired<M365ApplicationIcon> {
    const defaultIcon: StrictM365ApplicationIcon = {
        name: 'HomeIcon',
        __typename: 'InternalIcon',
    };
    if (!icon) {
        return defaultIcon;
    }
    return { __typename: 'RemoteImage', src: icon, isFullBleed: false };
}
