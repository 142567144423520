import { observer } from 'owa-mobx-react';
import type { FavoriteNodeCommonProps } from './FavoriteNode';
import DragAndDroppable from 'owa-dnd/lib/components/DragAndDroppable';
import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import { GroupRightCharm, GroupRightCharmHover } from 'owa-group-left-nav-mail';
import { lazyDropMailListRowsOnGroup } from 'owa-group-mail-left-nav-actions';
import { GroupNode } from 'owa-groups-left-nav-view';
import {
    getFavoriteNodeViewStateFromId,
    lazyInitializeFavoriteNodeViewState,
} from 'owa-mail-favorites-store';
import { showFavoritesContextMenu } from 'owa-mail-favorites-store/lib/actions/favoritesContextMenu';
import { isGroupNodeSelected, lazySelectGroup } from 'owa-mail-folder-forest-actions';
import { DRAG_X_OFFSET, DRAG_Y_OFFSET } from 'owa-mail-folder-view';
import { getAnchorForContextMenu } from 'owa-positioning';
import React from 'react';
import { getFavoriteIdFromGroupId } from 'owa-favorites';
import type { FavoriteNodeDragData } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type { MailListRowDragData } from 'owa-mail-types/lib/types/MailListRowDragData';
import { PeopleCommunityRegular, PeopleCommunityFilled, bundleIcon } from '@fluentui/react-icons';
import type { MailboxInfo } from 'owa-client-types';
import {
    canDropFavorite,
    canDropFolder,
    onDropFolder,
    onDropFavorite,
} from '../util/dragDropUtils';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { dragPreview } from './FavoriteNode.scss';
import type DropEffect from 'owa-dnd/lib/store/schema/DropEffect';
import { FAVORITE_FOLDERS_TREE_TYPE } from 'owa-folders-constants';

const PeopleCommunity = bundleIcon(PeopleCommunityFilled, PeopleCommunityRegular);

export interface FavoriteGroupNodeProps extends FavoriteNodeCommonProps {
    displayName: string;
    groupId: string;
    mailboxInfo: MailboxInfo;
    ellipsesOnHover?: boolean;
}

export default observer(function FavoriteGroupNode(props: FavoriteGroupNodeProps) {
    // Besides the isDragOver property in store, we also add this property here to distingush from the dropping on MailFolderNode
    const isDragOver = React.useRef<boolean>();

    React.useEffect(() => {
        lazyInitializeFavoriteNodeViewState.importAndExecute(props.favoriteId);
    }, []);

    const onContextMenu = React.useCallback(
        (evt: React.MouseEvent<HTMLElement>) => {
            evt.stopPropagation();
            evt.preventDefault();
            showFavoritesContextMenu(props.favoriteId, 2, getAnchorForContextMenu(evt));
        },
        [props.favoriteId]
    );
    const onDrop = React.useCallback(
        async (
            dragData: DragData,
            _pageX: number,
            _pageY: number,
            _currentTarget?: HTMLElement,
            ctrlKey?: boolean
        ) => {
            const itemType = dragData.itemType;
            switch (itemType) {
                case DraggableItemTypes.MailFolderNode:
                    await onDropFolder(dragData, props.mailboxInfo, props.favoriteId);
                    break;
                case DraggableItemTypes.FavoriteNode:
                    await onDropFavorite(dragData, props.favoriteId);
                    break;
                case DraggableItemTypes.MultiMailListMessageRows:
                case DraggableItemTypes.MailListRow:
                    lazyDropMailListRowsOnGroup.importAndExecute(
                        dragData as MailListRowDragData,
                        props.groupId,
                        ctrlKey
                    );
                    break;
            }
        },
        [props.favoriteId, props.mailboxInfo, props.groupId]
    );
    const onDragOver = React.useCallback(() => {
        isDragOver.current = true;
    }, []);
    const onDragLeave = React.useCallback(() => {
        isDragOver.current = false;
    }, []);
    const canDrop = React.useCallback(
        (dragData: DragData, dragEvent?: React.DragEvent<HTMLElement>): DropEffect => {
            const itemType = dragData.itemType;
            switch (itemType) {
                case DraggableItemTypes.FavoriteNode:
                    return canDropFavorite(dragData, props.mailboxInfo) ? 'move' : 'none';
                case DraggableItemTypes.MailFolderNode:
                    return canDropFolder(dragData, props.mailboxInfo) ? 'move' : 'none';
                case DraggableItemTypes.MailListRow:
                case DraggableItemTypes.MultiMailListMessageRows:
                case DraggableItemTypes.MultiMailListConversationRows:
                    const draggedItemsMailboxInfo = getModuleContextMailboxInfo();
                    // Drag and drop not allowed on Group Node from Group Mailboxes
                    if (draggedItemsMailboxInfo.type === 'GroupMailbox') {
                        return 'none';
                    }

                    // Drag and drop not allowed on Group Node across accounts
                    if (
                        getIndexerValueForMailboxInfo(draggedItemsMailboxInfo) !==
                        getIndexerValueForMailboxInfo(props.mailboxInfo)
                    ) {
                        return 'none';
                    }

                    return dragEvent?.ctrlKey ? 'copy' : 'move';
                default:
                    return 'none';
            }
        },
        [props.mailboxInfo]
    );

    const getDragData = React.useCallback(() => {
        const displayName = props.displayName;
        const groupNodeDragData: FavoriteNodeDragData = {
            itemType: DraggableItemTypes.FavoriteNode,
            favoriteId: props.favoriteId,
            favoriteType: 2,
            displayName,
            itemData: {
                mailboxInfo: props.mailboxInfo,
            },
        };
        return groupNodeDragData;
    }, [props.favoriteId, props.displayName, props.mailboxInfo]);
    const favoriteId = getFavoriteIdFromGroupId(props.groupId);
    const viewState = getFavoriteNodeViewStateFromId(favoriteId);

    const renderRightCharm = React.useCallback(
        (groupId: string): JSX.Element => {
            return <GroupRightCharm groupId={groupId} mailboxInfo={props.mailboxInfo} />;
        },
        [props.mailboxInfo]
    );

    const renderRightCharmHover = React.useCallback(
        (groupId: string): JSX.Element => {
            return <GroupRightCharmHover groupId={groupId} mailboxInfo={props.mailboxInfo} />;
        },
        [props.mailboxInfo]
    );

    const isGroupNodeSelectedInternal = React.useCallback(
        (groupId: string): boolean => {
            return isGroupNodeSelected(groupId, 'favorites', props.mailboxInfo);
        },
        [props.mailboxInfo]
    );

    const selectGroupInternal = React.useCallback(
        async (groupId: string) => {
            await lazySelectGroup.importAndExecute(
                groupId,
                FAVORITE_FOLDERS_TREE_TYPE,
                props.mailboxInfo
            );
        },
        [props.mailboxInfo]
    );

    if (!viewState?.drop) {
        return null;
    }

    return (
        <DragAndDroppable
            dragViewState={viewState.drag}
            getDragData={getDragData}
            getDragPreview={getDragPreview}
            xOffset={DRAG_X_OFFSET}
            yOffset={DRAG_Y_OFFSET}
            dropViewState={viewState.drop}
            onDrop={onDrop}
            canDrop={canDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
        >
            <GroupNode
                selectGroup={selectGroupInternal}
                isSelected={isGroupNodeSelectedInternal}
                renderRightCharm={renderRightCharm}
                renderRightCharmHover={renderRightCharmHover}
                displayName={props.displayName}
                groupId={props.groupId}
                customIconComponent={PeopleCommunity}
                isDroppedOver={viewState?.drop?.isDragOver && isDragOver.current}
                isBeingDragged={viewState?.drag?.isBeingDragged}
                key={props.groupId}
                onContextMenu={onContextMenu}
                showHoverStateOnDroppedOver={
                    viewState?.drop?.draggableItemType == DraggableItemTypes.MailListRow ||
                    viewState?.drop?.draggableItemType ==
                        DraggableItemTypes.MultiMailListMessageRows
                }
                isFavorited={true}
                mailboxInfo={props.mailboxInfo}
                ellipsesOnHover={props.ellipsesOnHover}
                shouldShowFocusBorder={true}
            />
        </DragAndDroppable>
    );
}, 'FavoriteGroupNode');

function getDragPreview(groupNodeDragData: DragData) {
    const elem = document.createElement('div');
    elem.className = dragPreview;
    elem.innerText = (groupNodeDragData.itemData as FavoriteNodeDragData).displayName;
    return elem;
}
