import { computed } from 'mobx';
import { getPalette } from 'owa-theme';
import type { IButtonStyles, IRawStyle, IStyle } from '@fluentui/react';
import { getButtonRootConstants } from './singleLineRibbonStyleConstants';
import { isFeatureEnabled } from 'owa-feature-flags';

const getButtonLabelConstants = (isSelected: boolean, isHeroStyle: boolean): IRawStyle => {
    return {
        fontSize: 14,
        fontWeight: isSelected === true ? '600' : '400',
        lineHeight: 16.41,
        marginLeft: isHeroStyle ? 10 : 4,
    };
};

const getButtonIconConstants = (): IRawStyle => {
    const { iconSize } = getButtonRootConstants();
    return {
        width: iconSize,
        height: iconSize,
        fontSize: iconSize,
    };
};

const getSlrAppButtonConstants = (): IRawStyle => {
    const constants = getButtonRootConstants(false /* isHeroStyle */);
    return {
        height: constants.rootHeight,
        marginTop: constants.rootMarginTop,
        borderRadius: constants.borderRadius,
    };
};

const getSlrAppSplitButtonConstants = (isHeroStyle: boolean): IRawStyle => {
    const constants = getButtonRootConstants(isHeroStyle);
    return {
        height: constants.rootHeight,
        marginTop: constants.rootMarginTop,
        paddingRight: 12,
        borderWidth: 0,
        borderTopLeftRadius: constants.borderRadius,
        borderBottomLeftRadius: constants.borderRadius,
    };
};

const getSlrAppSplitButtonMenuButtonConstants = (isHeroStyle: boolean) => {
    const constants = getButtonRootConstants(isHeroStyle);
    return {
        height: constants.rootHeight,
        width: constants.splitButtonMenuButtonWidth,
        marginTop: constants.rootMarginTop,
        borderWidth: 0,
        borderTopRightRadius: constants.borderRadius,
        borderBottomRightRadius: constants.borderRadius,
        fontSize: constants.chevronIconSize,
    };
};

/**
 * This function is being used by two different functions, one of them is adding extra styles for split buttons. If we mark it as computed it wouldn't work
 * as we expect. Due to this we have this as non computed and we compute the values when they are ready to return.
 */
const getSlrAppBaseButtonStyle = (): IButtonStyles => {
    const constants = getButtonRootConstants();
    const palette = getPalette();

    // Bug154909 -- remove SunValley "hacks" when 1js natively supports SV2
    // UNCOMMENT the following section (note the four '* /' than need to be
    // changed to '*/')
    /*
        return {
            root: getSlrAppButtonConstants(),
            label: getButtonLabelConstants(
                false /*isSelected* /,
                false /*isHeroStyle* /
            ),
            icon: getButtonIconConstants(),
            splitButtonMenuButton: getSlrAppSplitButtonMenuButtonConstants(
                false /*isHeroStyle* /
            ),
        };
        */
    // DELETE the following section

    return {
        root: getSlrAppButtonConstants(),
        rootPressed: {
            backgroundColor: palette.neutralQuaternary,
            borderColor: palette.neutralTertiary,
        },
        rootChecked: {
            backgroundColor: palette.neutralLighter,
            borderColor: palette.neutralSecondaryAlt,
        },
        rootHovered: {
            backgroundColor: palette.neutralLighterAlt,
            borderColor: palette.neutralQuaternary,
        },
        label: getButtonLabelConstants(false /*isSelected*/, false /* isHeroStyle */),
        icon: getButtonIconConstants(),
        splitButtonMenuButton: getSlrAppSplitButtonMenuButtonConstants(false /* isHeroStyle */),
        menuIcon: {
            fontSize: constants.chevronIconSize,
        },
    };
    //DELETE the above section
};

const computeSlrSplitBaseButtonStyle = computed((): IButtonStyles => {
    const appBaseButtonStyle = getSlrAppBaseButtonStyle();
    const { neutralPrimaryAlt } = getPalette();

    const splitButtonContainerChecked: IStyle = {
        selectors: {
            button: {
                borderColor: neutralPrimaryAlt,
            },
            '.splitPrimaryButton': {
                borderRight: 0,
                paddingRight: '3px',
            },
        },
    };

    return {
        ...appBaseButtonStyle,
        root: {
            ...(appBaseButtonStyle.root as IRawStyle),
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: 'transparent',
        },
        rootHovered: {
            ...(appBaseButtonStyle.rootHovered as IRawStyle),
            outline: 'transparent dashed 2px',
        },
        splitButtonMenuButton: {
            ...(appBaseButtonStyle.splitButtonMenuButton as IRawStyle),
            borderWidth: 1,
        },
        splitButtonContainerChecked,
        splitButtonContainerCheckedHovered: splitButtonContainerChecked,
    };
});

/**
 * Get button styles for SLR Hero App Split Button.
 */
const computeGetSlrHeroAppSplitButtonStyles = computed((): IButtonStyles => {
    const palette = getPalette();
    const constants = getButtonRootConstants(true /* isHeroStyle */);
    const slrAppSplitButtonConstants = getSlrAppSplitButtonConstants(true /* isHeroStyle */);
    const slrAppSplitButtonMenuButtonConstants = getSlrAppSplitButtonMenuButtonConstants(
        true /* isHeroStyle */
    );

    const style: IButtonStyles = {
        root: {
            ...slrAppSplitButtonConstants,
            backgroundColor: palette.themePrimary,
            color: palette.white,

            selectors: {
                ':hover': {
                    ...slrAppSplitButtonConstants,
                    backgroundColor: palette.themeDark,
                    color: palette.white,
                },
                ':active': {
                    ...slrAppSplitButtonConstants,
                    backgroundColor: palette.themeDarker,
                    color: palette.white,
                },
            },
        },
        rootDisabled: {
            ...slrAppSplitButtonConstants,
            backgroundColor: palette.themeLight,
            color: palette.white,
        },
        label: getButtonLabelConstants(false /* isSelected */, true /* isHeroStyle */),
        labelDisabled: {
            ...getButtonLabelConstants(false /* isSelected */, true /* isHeroStyle */),
            color: palette.white,
            opacity: 0.4,
        },
        icon: getButtonIconConstants(),
        splitButtonContainer: {
            marginLeft: 0,
            marginRight: 4,
        },
        splitButtonMenuButton: {
            ...slrAppSplitButtonMenuButtonConstants,
            backgroundColor: palette.themePrimary,
            color: palette.white,

            selectors: {
                ':hover': {
                    ...slrAppSplitButtonMenuButtonConstants,
                    backgroundColor: palette.themeDark,
                    color: palette.white,
                },
                ':active': {
                    ...slrAppSplitButtonMenuButtonConstants,
                    backgroundColor: palette.themeDarker,
                    color: palette.white,
                },
            },
        },
        splitButtonMenuButtonDisabled: {
            ...slrAppSplitButtonConstants,
            backgroundColor: palette.themeLight,
            color: palette.white,
        },
        splitButtonMenuButtonExpanded: {
            ...slrAppSplitButtonMenuButtonConstants,
            backgroundColor: palette.themeDark,
            color: palette.white,

            selectors: {
                ':hover': {
                    ...slrAppSplitButtonMenuButtonConstants,
                    backgroundColor: palette.themeDark,
                    color: palette.white,
                },
            },
        },
        splitButtonMenuIcon: {
            lineHeight: constants.chevronIconSize,
            height: constants.chevronIconSize,
            width: constants.chevronIconSize,
        },
        splitButtonDivider: {
            width: 1,
            height: 32,
            right: constants.splitButtonMenuButtonWidth,
            top: 4,
            background: palette.neutralPrimarySurface,
        },
    };

    return style;
});

const computeGetSlrHeroAppButtonStyles = computed((): IButtonStyles => {
    const palette = getPalette();
    const slrAppButtonConstants = getSlrAppButtonConstants();

    const style: IButtonStyles = {
        root: {
            ...slrAppButtonConstants,
            backgroundColor: palette.themePrimary,
            color: palette.white,
            marginRight: 4,
            selectors: {
                ':hover': {
                    ...slrAppButtonConstants,
                    backgroundColor: palette.themeDark,
                    color: palette.white,
                },
                ':active': {
                    ...slrAppButtonConstants,
                    backgroundColor: palette.themeDarker,
                    color: palette.white,
                },
            },
        },
        rootDisabled: {
            ...slrAppButtonConstants,
            backgroundColor: palette.themeLight,
            color: palette.white,
        },
        label: getButtonLabelConstants(false /* isSelected */, true /* isHeroStyle */),
        labelDisabled: {
            ...getButtonLabelConstants(false /* isSelected */, true /* isHeroStyle */),
            color: palette.white,
            opacity: 0.4,
        },
        icon: getButtonIconConstants(),
    };

    return style;
});

export const getSlrHeroAppSplitButtonStyles = (): IButtonStyles =>
    isFeatureEnabled('mon-ribbon-fluent-v9-ribbon')
        ? {}
        : computeGetSlrHeroAppSplitButtonStyles.get();

export const getDefaultSlrAppRibbonButtonStyles = () => computeSlrAppBaseButtonStyle.get();

const computeSlrAppBaseButtonStyle = computed((): IButtonStyles => getSlrAppBaseButtonStyle());

const computeDefaultSlrAppRibbonButtonStylesForSelected = computed((): IButtonStyles => {
    return {
        root: getSlrAppButtonConstants(),
        label: getButtonLabelConstants(true /*isSelected*/, false /* isHeroStyle */),
        icon: getButtonIconConstants(),
        splitButtonMenuButton: getSlrAppSplitButtonMenuButtonConstants(false /* isHeroStyle */),
    };
});

export const getDefaultSlrAppRibbonButtonStylesForSelected = () =>
    computeDefaultSlrAppRibbonButtonStylesForSelected.get();

export const getDefaultSlrSplitButtonAppRibbonButtonStyles = () =>
    computeSlrSplitBaseButtonStyle.get();

export const getSlrHeroAppButtonStyles = () => computeGetSlrHeroAppButtonStyles.get();
