import { mutatorAction } from 'satcheljs';
import { mailListItemAnimationStore } from '../store/Store';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const removePendingAnimation = mutatorAction(
    'removePendingAnimation',
    (animationToRemove: MailListItemAnimation) => {
        mailListItemAnimationStore.pendingAnimations =
            mailListItemAnimationStore.pendingAnimations.filter(pendingAnimation => {
                return pendingAnimation.animationId !== animationToRemove.animationId;
            });
    }
);
