import { mutatorAction } from 'satcheljs';
import { getFollowButtonStateMap } from '../selectors/followButtonStoreSelectors';
import type ActionState from '../schema/ActionState';
import type { MailboxInfo } from 'owa-client-types';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setFollowButtonState = mutatorAction(
    'setFollowButtonState',
    function setFollowButtonState(
        mailboxInfo: MailboxInfo,
        groupSmtpAddress: string,
        newState: ActionState
    ) {
        const groupActionState = getFollowButtonStateMap(mailboxInfo);

        groupActionState.set(transformGroupSmtp(groupSmtpAddress), newState);
    }
);

export default setFollowButtonState;
