import {
    menuTextChevronClass,
    menuTextContainerClass,
    menuTextLine2Class,
    splitContainerClass,
    splitIconClass,
} from 'owa-acui';
import type { IButtonStyles, IStyle } from '@fluentui/react';
import { computed } from 'mobx';
import { getPalette } from 'owa-theme';

const chevronSelectors = {
    defaultButton: `.${menuTextContainerClass} .${menuTextChevronClass}`,
    splitButton: `.${splitContainerClass} .${splitIconClass}`,
};

const verticalChevronStyles: IStyle = {
    height: 13,
    paddingTop: 3,
    width: 'auto',
};

const horizontalChevronStyles: IStyle = {
    fontSize: 12,
    height: 12,
    width: 12,
};

// Overwriting the default styles defined in:
// https://office.visualstudio.com/Office/_git/1JS?path=/ooui/packages/acui-button/src/components/AppFlyoutAnchor/ActionFlyoutAnchor.styles.ts
// https://office.visualstudio.com/Office/_git/1JS?path=/ooui/packages/acui-button/src/components/AppSplitButton/ActionSplitButton.styles.ts
const getMlrAppBaseButtonStyle = (): IButtonStyles => {
    return {
        root: {
            borderRadius: 4,
            [chevronSelectors.defaultButton]: verticalChevronStyles,
        },
        rootPressed: {
            backgroundColor: getPalette().neutralQuaternary,
            borderColor: getPalette().neutralTertiary,
        },
        rootChecked: {
            backgroundColor: getPalette().neutralLighter,
            borderColor: getPalette().neutralSecondaryAlt,
        },
        rootHovered: {
            backgroundColor: getPalette().neutralLighterAlt,
            borderColor: getPalette().neutralQuaternary,
        },
        textContainer: {
            // Matching the `display` and `align-items` definition on `.ribbon-split-text` for consistent alignment:
            // https://office.visualstudio.com/Office/_git/1JS?path=/ooui/packages/acui-button/src/components/AppSplitButton/ActionSplitButton.styles.ts
            [`.${menuTextLine2Class}`]: {
                display: 'flex',
                alignItems: 'flex-end',
            },
        },
        splitButtonMenuButton: {
            [chevronSelectors.splitButton]: verticalChevronStyles,
        },
        splitButtonMenuIcon: {
            // Targeting an extra selector to workaround these customizations being
            // ignored when set directly on `splitButtonMenuIcon`
            '&.ms-Button-icon': {
                ...horizontalChevronStyles,
                marginLeft: 0,
            },
        },
        menuIcon: {
            // Targeting an extra selector to workaround these customizations being
            // ignored when set directly on `menuIcon`
            '&.ms-Button-menuIcon': horizontalChevronStyles,
        },
    };
};

const computeGetDefaultMlrAppButtonStyles = computed(
    (): IButtonStyles => getMlrAppBaseButtonStyle()
);

export const getDefaultMlrAppButtonStyles = (): IButtonStyles =>
    computeGetDefaultMlrAppButtonStyles.get();

const computeGetDefaultMlrAppRibbonButtonStylesForSelected = computed((): IButtonStyles => {
    const baseStyles: IButtonStyles = getMlrAppBaseButtonStyle();
    baseStyles.label = {
        fontWeight: 600,
    };
    return baseStyles;
});

export const getDefaultMlrAppRibbonButtonStylesForSelected = () =>
    computeGetDefaultMlrAppRibbonButtonStylesForSelected.get();
