import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaSuiteHeader"*/ './lazyIndex'),
    { name: 'OwaSuiteHeader' }
);

export const lazyUpdateEmbedChatOptions = new LazyAction(
    lazyModule,
    m => m.updateEmbedChatLastUsedDate
);

export const lazyShouldLoadEmbedPreAuthOnIdle = new LazyAction(
    lazyModule,
    m => m.shouldLoadEmbedPreAuthOnIdle
);

export const lazyGetChatUnreadMessageCount = new LazyAction(
    lazyModule,
    m => m.getChatUnreadMessageCount
);
