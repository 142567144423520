import { lazyGovern, GovernPriority } from 'owa-tti';
import { lazyShouldLoadEmbedPreAuthOnIdle, lazyGetChatUnreadMessageCount } from '../lazyFunctions';
import { isFeatureEnabled } from 'owa-feature-flags';
import { triggerEmbedChatCallout } from './embedChatTeachingCallout/triggerEmbedChatCallout';

let isShouldLoadPreAuthComputed: boolean = false;
let isUnreadChatCountComputed: boolean = false;
let shouldLoadPreAuthOnIdleResult: boolean = false;
let unreadChatCount: number = 0;

export const shouldLoadPreAuthOnIdleCallbackPromise = new Promise<boolean>((resolve, reject) => {
    if (!isShouldLoadPreAuthComputed) {
        lazyGovern.importAndExecute({
            task: async () => {
                try {
                    shouldLoadPreAuthOnIdleResult =
                        isFeatureEnabled('fwk-teamsSDKPreAuth') &&
                        isFeatureEnabled('fwk-teamsSuite') &&
                        !isFeatureEnabled('mon-hide-chat-integration')
                            ? await lazyShouldLoadEmbedPreAuthOnIdle.importAndExecute()
                            : false;
                    isShouldLoadPreAuthComputed = true;
                    resolve(shouldLoadPreAuthOnIdleResult);
                } catch (error) {
                    reject(error);
                }
            },
            priority: GovernPriority.Idle,
            name: 'shouldLoadPreAuthOnIdleCallbackPromise',
        });
    } else {
        resolve(shouldLoadPreAuthOnIdleResult);
    }
});

export const getChatUnreadMessageCallbackPromise = new Promise<number>((resolve, reject) => {
    if (!isUnreadChatCountComputed) {
        lazyGovern.importAndExecute({
            task: async () => {
                try {
                    unreadChatCount =
                        isFeatureEnabled('fwk-teamsSuite') &&
                        !isFeatureEnabled('mon-hide-chat-integration')
                            ? await lazyGetChatUnreadMessageCount.importAndExecute()
                            : 0;
                    if (
                        isFeatureEnabled('mon-embed-chat-teaching-callout') &&
                        unreadChatCount > 0
                    ) {
                        triggerEmbedChatCallout();
                    }
                    isUnreadChatCountComputed = true;
                    resolve(unreadChatCount);
                } catch (error) {
                    reject(error);
                }
            },
            priority: GovernPriority.Idle,
            name: 'getChatUnreadMessageCallbackPromise',
        });
    } else {
        resolve(unreadChatCount);
    }
});
