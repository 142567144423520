import { default as favoritesStore } from '../store/store';
import type { FavoritePersonaData } from 'owa-favorites-types';

export function isFolderPersonaFavoriteSearchFolder(folderId: string): boolean {
    // Lookup the new favorites store
    return [...favoritesStore.outlookFavorites.values()]
        .filter(favorite => favorite.type === 'persona')
        .some(
            favorite =>
                (favorite as FavoritePersonaData).searchFolderId &&
                (favorite as FavoritePersonaData).searchFolderId === folderId
        );
}
