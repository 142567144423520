import type { RibbonId } from 'owa-ribbon-ids';
import { type ActionControlId, type RuntimeControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { type MailRibbonGroupId, type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import { isSpamIntegrationRuntimeControlId } from './isSpamIntegrationRuntimeControlId';
import { isReadPinnedAppRuntimeControl } from './isReadPinnedAppRuntimeControl';

/**
 * Initializes the map that maps RibbonIds to MenuItemTypes.
 * Used in @see {getIsReadButtonDisabled} to track whether a button should show or not in the Ribbon.
 */
const MailRibbonIdToCmdIdMap: Map<RibbonId, MenuItemType> = new Map<RibbonId, MenuItemType>([
    [7016, -1],
    [529, -1],
    [7021, -1],
    [7023, -1],
    [505, 3],
    [616, 49],
    [507, 5],
    [658, 5],
    [508, 53],
    [509, 6],
    [516, 21],
    [519, 50],
    [527, 51],
    [108, -1],
    [109, -1],
    [113, -1],
    [128, -1],
    [531, 54],
    [532, 68],
    [535, 55],
    [538, -1],
    [540, 30],
    [659, 5],
    [584, -1],
    [585, -1],
    [586, -1],
    [587, 56],
    [588, -1],
    [589, 57],
    [640, 36],
    [641, 36],
    [642, 77],
    [545, -1],
    [547, 25],
    [548, 58],
    [549, 40],
    [552, 59],
    [553, 44],
    [696, 87],
    [698, 87],
    [699, 44],
    [700, 44],
    [555, 52],
    [680, 52],
    [651, 52],
    [556, 60],
    [678, 60],
    [679, 60],
    [557, 61],
    [681, 61],
    [683, 46],
    [684, 47],
    [559, 80],
    [567, -1],
    [570, 14],
    [571, 66],
    [572, 63],
    [580, 64],
    [581, -1],
    [663, 53],
    [550, 82],
    [687, 84],
    [688, 85],
    [686, 83],
    [91000, 48],
    [697, 86],
    [731, 88],
    [705, 90],
    [712, 92],
    [709, 91],
    [7016, 93],
    [719, 96],
    [720, 97],
]);

export const getCmdIdFromRibbonId = (ribbonId: RibbonId, mailboxInfo: MailboxInfo): number => {
    /**
     * The "Report" split button default behavior is normally "Report phishing",
     * but that command is not available for cloud cache accounts, so we'll modify
     * the default command in this to be "Report junk".
     */
    if (ribbonId == 657) {
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Adding IsShadowMailbox to restricted properties/methods.
         *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
        const cmdId = !!getAccountScopeUserSettings(mailboxInfo)?.SessionSettings?.IsShadowMailbox
            ? 55
            : 25;
        return cmdId;
    }

    // Indentify if the ribbonId corresponding to a runtime control used for spam integration
    if (isSpamIntegrationRuntimeControlId(ribbonId as number)) {
        return 48;
    }

    // If this is a read pinned app runtime control, return the corresponding MenuItemType
    if (isReadPinnedAppRuntimeControl(ribbonId as number)) {
        return 94;
    }
    // The map may not contain btnIdentifier, and the resulting commandId would be undefined. Check for that.
    return MailRibbonIdToCmdIdMap.get(ribbonId) ?? -1;
};
