import { getListViewDimensions } from './getListViewDimensions';
import {
    MIN_LIST_VIEW_WIDTH,
    LIST_VIEW_NOTIFICATION_PADDING,
    LIST_VIEW_NOTIFICATION_PADDING_V9,
} from '../internalConstants';
import { isFeatureEnabled } from 'owa-feature-flags';

export function getListViewNotificationDimensions() {
    const listViewWidth = getListViewDimensions().listViewWidth;
    if (isFeatureEnabled('tri-newToast-notifications')) {
        return {
            minWidth: MIN_LIST_VIEW_WIDTH - LIST_VIEW_NOTIFICATION_PADDING_V9,
            maxWidth: listViewWidth - LIST_VIEW_NOTIFICATION_PADDING_V9,
        };
    }
    return {
        minWidth: MIN_LIST_VIEW_WIDTH - LIST_VIEW_NOTIFICATION_PADDING,
        maxWidth: listViewWidth - LIST_VIEW_NOTIFICATION_PADDING,
    };
}
