import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailLocalSearch" */ './lazyIndex'),
    { name: 'MailLocalSearch' }
);

export const lazyLoadMoreGqlSearch = new LazyAction(lazyModule, m => m.loadMoreGqlSearch);
export const lazyLoadLocalMailSearchTable = new LazyAction(
    lazyModule,
    m => m.loadLocalMailSearchTable
);
