import type {
    FavoriteCategoryNode,
    FavoritePersonaNode,
    FavoriteSearchNode,
    FolderForestNode,
} from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import createDropViewState from 'owa-dnd/lib/utils/createDropViewState';
import { trace } from 'owa-trace';

interface PersistedFavoriteNode {
    id: string;
    type: number;
    displayName?: string;
    personaSearchFolderId?: string;
    personaEmail?: string;
    personaAllEmails?: string[];
    personaId?: string;
}

/**
 * Parse a single raw node to folder forest node
 * @param rawNode the raw favorite node data as string
 * @return folder forest node
 */
export function parse(rawNode: string): FolderForestNode {
    let parsedNode;

    try {
        parsedNode = JSON.parse(rawNode) as PersistedFavoriteNode;
    } catch (error) {
        trace.warn('Cannot parse folderForestNode: ' + rawNode);
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (37,9): Type 'undefined' is not assignable to type 'FolderForestNode'.
        // @ts-expect-error
        return undefined;
    }

    // Make sure the node has valid id
    if (parsedNode.id === undefined) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (45,9): Type 'undefined' is not assignable to type 'FolderForestNode'.
        // @ts-expect-error
        return undefined;
    }

    switch (parsedNode.type) {
        case 5:
        case 0:
            return <FolderForestNode>{
                id: parsedNode.id,
                type: parsedNode.type,
                treeType: 'favorites',
            };

        case 3:
            return <FavoriteSearchNode>{
                id: parsedNode.id,
                type: parsedNode.type,
                treeType: 'favorites',
                dropViewState: createDropViewState(),
            };

        case 4:
            return <FavoriteCategoryNode>{
                id: parsedNode.id,
                type: parsedNode.type,
                treeType: 'favorites',
                dropViewState: createDropViewState(),
            };

        case 1:
            const personaNode: FavoritePersonaNode = {
                id: parsedNode.id,
                type: parsedNode.type,
                treeType: 'favorites',
                displayName: parsedNode.displayName || '',
                mainEmailAddress: parsedNode.personaEmail,
                allEmailAddresses: parsedNode.personaAllEmails || [],
                searchFolderId: parsedNode.personaSearchFolderId,
                isJustAdded: false,
                isSearchFolderPopulated: true,
                isSyncUpdateDone: false,
                dropViewState: createDropViewState(),
            };

            if (parsedNode.personaId) {
                personaNode.personaId = parsedNode.personaId;
            }

            return personaNode;

        default:
            // Safeguard to make sure we don't parse invalid favorite in case other client update userConfiguration which corrupted our data
            trace.warn('Non supported FolderForestNodeType: ' + parsedNode.type);
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (103,13): Type 'undefined' is not assignable to type 'FolderForestNode'.
            // @ts-expect-error
            return undefined;
    }
}

/**
 * Stringify single node
 * @param node the folder forest node
 * @return string of raw data
 */
export function stringify(node: FolderForestNode): string {
    const persistedNode: PersistedFavoriteNode = {
        id: node.id,
        type: node.type,
    };

    if (node.type === 1) {
        const personaNode = <FavoritePersonaNode>node;

        persistedNode.displayName = personaNode.displayName;
        persistedNode.personaSearchFolderId = personaNode.searchFolderId;
        persistedNode.personaEmail = personaNode.mainEmailAddress;
        persistedNode.personaAllEmails = personaNode.allEmailAddresses;
        persistedNode.personaId = personaNode.personaId;
    }

    return JSON.stringify(persistedNode);
}
