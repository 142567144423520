import { isFeatureEnabled } from 'owa-feature-flags';
import { getStore } from 'owa-protection-store/lib/store/protectionStore';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';

export default function isCLPEnabled(mailboxInfo: MailboxInfo): boolean {
    const protectionStore = getStore(mailboxInfo);
    const { clpLabels } = protectionStore;
    return (
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'cmp-clp' */
        isFeatureEnabled(
            'cmp-clp',
            mailboxInfo,
            !!mailboxInfo /* dontThrowErrorIfNotInitialized to help visually identify the scenarios hitting the error */
        ) &&
        clpLabels.length > 0 &&
        clpLabels.some(
            label =>
                (!label.children?.length && label.isLabelEnabled) ||
                label.children?.some(childLabel => childLabel.isLabelEnabled)
        ) &&
        !isConsumer(undefined /* smtpAddress */, mailboxInfo)
    );
}
