import AbstractTaskQueue from './AbstractTaskQueue';

export type TaskFunc<T> = () => Promise<T>;

export interface Task<TValue, TReturn> {
    value: TValue;
    resolve: (value: TReturn) => void;
    reject: (reason?: any) => void;
}

export interface PriorityTaskQueueConfig {
    maxParallelTasks?: number;
    taskDelay?: number;
    taskTimeout?: number;
}

export interface PriorityTaskQueueExConfig<TValue, TReturn> {
    taskCallback: (value: TValue) => Promise<TReturn>;
    maxParallelTasks?: number;
    taskDelay?: number;
    taskTimeout?: number;
}

/**
 * Task queue with priorities
 */
export class PriorityTaskQueueEx<TValue, TReturn> extends AbstractTaskQueue<Task<TValue, TReturn>> {
    protected taskList: {
        [priority: number]: Task<TValue, TReturn>[];
    } = {}; // ordered as a FIFO
    protected config: Required<PriorityTaskQueueExConfig<TValue, TReturn>>;

    constructor(config: PriorityTaskQueueExConfig<TValue, TReturn>) {
        super();
        this.config = {
            maxParallelTasks: 1,
            taskDelay: 0,
            taskTimeout: 60000,
            ...config,
        };
    }

    add(value: TValue, priority: number = 0, addToFront: boolean = false): Promise<TReturn> {
        if (!this.taskList[priority]) {
            this.taskList[priority] = [];
        }

        return new Promise((resolve, reject) => {
            const task = {
                value,
                resolve,
                reject,
            };
            if (addToFront) {
                this.taskList[priority].push(task);
            } else {
                this.taskList[priority].unshift(task);
            }
            this.scheduleTask();
        });
    }

    remove(): Task<TValue, TReturn> | undefined {
        const nextPriority = Math.min.apply(null, this.priorities);
        const nextTask = this.taskList[nextPriority].pop();

        // If there are no more tasks then delete the key
        if (!this.taskList[nextPriority] || this.taskList[nextPriority].length == 0) {
            delete this.taskList[nextPriority];
        }

        return nextTask;
    }

    removeTask(task: TValue, priority: number): void {
        const taskIndex = this.taskList[priority].findIndex(t => t.value === task);
        if (taskIndex !== -1) {
            if (this.taskList[priority].length > 1) {
                this.taskList[priority].splice(taskIndex, 1);
            } else {
                delete this.taskList[priority];
            }
        }
    }

    clear() {
        this.taskList = {};
    }

    hasHighPriorityTasks(): boolean {
        const highPriorityLists = Object.keys(this.taskList) as unknown as number[];
        return highPriorityLists.some((priority: number) => priority <= 10 /* Default */);
    }

    getQueuedTasks(priority?: number): TValue[] {
        if (priority !== undefined) {
            return (
                this.taskList[priority]
                    ?.map(task => task.value)
                    .slice(0)
                    .reverse() || []
            );
        } else {
            return this.priorities.sort().flatMap(p => this.getQueuedTasks(p));
        }
    }

    get length(): number {
        return this.priorities.reduce((acc, p) => acc + this.taskList[p].length, 0);
    }

    get isEmpty(): boolean {
        return this.priorities.length === 0;
    }

    protected get priorities(): number[] {
        return Object.keys(this.taskList).map(p => parseInt(p));
    }

    protected get canRunMoreTasks(): boolean {
        return (
            this.numberOfTasksRunning < this.config.maxParallelTasks && this.priorities.length > 0
        );
    }

    protected runTask(task: Task<TValue, TReturn>): Promise<void> {
        return this.config.taskCallback(task.value).then(task.resolve, task.reject);
    }
}

/**
 * Simplified version of PriorityTaskQueue that just queues task functions
 */
export class PriorityTaskQueue<T> extends PriorityTaskQueueEx<TaskFunc<T>, T> {
    constructor(config: PriorityTaskQueueConfig) {
        super({
            ...config,
            taskCallback: (task: TaskFunc<T>) => task(),
        });
    }
}
