import { observer } from 'owa-mobx-react';
import { default as SelectAllOn } from 'owa-fluent-icons-svg/lib/icons/SelectAllOnRegular';
import { default as SelectAllOff } from 'owa-fluent-icons-svg/lib/icons/SelectAllOffRegular';
import { FluentButton } from 'owa-fluent-v9-shims';
import setSelectionSLV from '../mutators/setSelectionSLV';
import { getDensityModeCssClass, getDensityModeString } from 'owa-fabric-theme';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import resetSelectAllState from '../mutators/resetSelectAllState';
import loc from 'owa-localize';
import { selectLabel } from 'owa-locstrings/lib/strings/selectlabel.locstring.json';
import React from 'react';
import {
    selectAllIconFullMCL,
    selectAllIconMediumAndCompactMCL,
    full,
    medium,
    compact,
    singleLineCheckboxButton,
    selectAllCheckboxButtonSingleLine,
    selectLabelSingleLine,
    buttonChecked,
} from './MailListHeader.scss';

import classnames from 'owa-classnames';

const MAIL_LIST_SELECT_ALL_SLV_ID = 'MailListSelectAllSLV';

export interface MailListSelectAllProps {
    tableView: any;
}

export default observer(function MailListSelectAllSLV(props: MailListSelectAllProps) {
    const { tableView } = props;
    const isFullDensity = getDensityModeString() === 'full';

    const iconButtonProps = useComputedValue(() => {
        return {
            iconName:
                tableView.isInSelectAllStateSLV || tableView.isInCheckedMode
                    ? SelectAllOn
                    : SelectAllOff,
            className: classnames(
                isFullDensity ? selectAllIconFullMCL : selectAllIconMediumAndCompactMCL
            ),
        };
    }, [tableView.isInCheckedMode, tableView.isInSelectAllStateSLV, isFullDensity]);

    const onCheckboxClick = React.useCallback(() => {
        setSelectionSLV(tableView, !tableView.isInSelectAllStateSLV);
    }, [tableView, tableView.isInSelectAllStateSLV]);

    React.useEffect(() => {
        setSelectionSLV(tableView, tableView.isInCheckedMode);
        resetSelectAllState(tableView);
    }, [tableView, tableView.isInCheckedMode]);

    const onCheckboxKeyDown = React.useCallback(
        (event: React.KeyboardEvent<unknown>) => {
            if (event.key === 'Escape') {
                setSelectionSLV(tableView, !tableView.isInSelectAllStateSLV);
            }
        },
        [tableView, tableView.isInSelectAllStateSLV]
    );

    return (
        <div
            className={classnames(
                singleLineCheckboxButton,
                (tableView.isInSelectAllStateSLV || tableView.isInCheckedMode) && buttonChecked,
                getDensityModeCssClass(full, medium, compact)
            )}
        >
            <FluentButton
                appearance="icon"
                className={classnames(
                    selectAllCheckboxButtonSingleLine,
                    getDensityModeCssClass(full, medium, compact)
                )}
                iconProps={iconButtonProps}
                onClick={onCheckboxClick}
                onKeyDown={onCheckboxKeyDown}
                aria-labelledby={MAIL_LIST_SELECT_ALL_SLV_ID}
            >
                <span
                    id={MAIL_LIST_SELECT_ALL_SLV_ID}
                    className={classnames(
                        selectLabelSingleLine,
                        getDensityModeCssClass(full, medium, compact)
                    )}
                >
                    {loc(selectLabel)}
                </span>
            </FluentButton>
        </div>
    );
}, 'MailListSelectAllSLV');
