import getScenarioIdKey from '../utils/getScenarioIdKey';
import getStore, { createDefaultSearchStore } from '../store/store';
import { initializeScenarioStore } from '../actions/initializeScenarioStore';
import { mutator } from 'satcheljs';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(initializeScenarioStore, actionMessage => {
    const scenarioIdKey = getScenarioIdKey(actionMessage.scenarioId);

    /**
     * If instance store already exists, return as its store is already
     * initialized.
     */
    if (getStore().scenarioStores.get(scenarioIdKey)) {
        return;
    }

    // Initialize instance store with default values.
    getStore().scenarioStores.set(scenarioIdKey, { ...createDefaultSearchStore() });
});
