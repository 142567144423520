import { isFeatureEnabled } from 'owa-feature-flags';

let promptForResourceEnabled = false;

export function isPromptForResourceTokenEnabled(): boolean {
    if (!promptForResourceEnabled) {
        promptForResourceEnabled = isFeatureEnabled(
            'auth-stepup-promptForResourceToken',
            undefined /*mailboxInfo*/,
            false /*throw error on too early initialization*/
        );
    }
    return promptForResourceEnabled;
}
