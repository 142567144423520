import shouldSkipProcessRow from './helpers/shouldSkipProcessRow';
import fetchRichContentForRows from './helpers/fetchRichContentForRows';
import type { MailListRowDataType, TableView } from 'owa-mail-list-store';
import { getRowKeyFromListViewType } from 'owa-mail-list-store';
import updateInstrumentationContext from 'owa-mail-list-store/lib/utils/updateInstrumentationContext';
import TableOperations from 'owa-mail-list-table-operations';
import type { InstrumentationContext } from 'owa-search-types/lib/types/InstrumentationContext';
import { mutatorAction } from 'satcheljs';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Using transaction to mitigate a perf issue; this should be refactored to use idiomatic
 * Satchel/MobX patterns (https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/242008) */
import { transaction } from 'mobx';
import type { ListViewLoadingDirection } from 'owa-mail-loading-action-types';

/**
 * Process FindConversation/FindItem response and add them to the beginning or end of the given TableView
 * @param tableView to add results in
 * @param rows to be added
 * @param totalRowsInViewInResponse - total rows in the table returned from server
 * @param source - source of addition
 * @param searchResponseId - the search query id
 * @param doNotOverwriteData determines if updates should be written (default is false)
 */
export default function addRowResponse(
    tableView: TableView,
    rows: MailListRowDataType[],
    totalRowsInViewInResponse: number,
    source: string = '',
    searchResponseId?: string,
    searchLogicalId?: string,
    doNotOverwriteData: boolean = false,
    listViewLoadingDirection: ListViewLoadingDirection = 'NextPage'
) {
    const isAppending = listViewLoadingDirection === 'NextPage';
    const rowList: MailListRowDataType[] = (isAppending ? rows : rows.reverse()) || [];
    let failedCount = 0;

    transaction(() => {
        for (const row of rowList) {
            // Check if row should be processed
            if (shouldSkipProcessRow(row, tableView)) {
                continue;
            }

            let wasSuccessful = true;
            // Add all rows to beginning or end of table
            if (
                TableOperations.containsRowKey(
                    tableView,
                    getRowKeyFromListViewType(row, tableView.tableQuery.listViewType)
                )
            ) {
                const rowUpdatedIndex = isAppending ? tableView.rowKeys.length - 1 : 0;

                // Move row to new position in table if it already exists. There could be some timing issue
                // where the row was moved down on the server. In this case we want to keep the server's latest order
                TableOperations.updateRowMutator(
                    rowUpdatedIndex,
                    row,
                    tableView,
                    source,
                    doNotOverwriteData
                );
            } else {
                const rowNewIndex = isAppending ? tableView.rowKeys.length : 0;
                wasSuccessful = TableOperations.addRowMutator(
                    rowNewIndex,
                    row,
                    tableView,
                    source,
                    doNotOverwriteData
                );
            }

            // Account for failures
            if (!wasSuccessful) {
                failedCount++;
            }

            if (searchResponseId && row.InstanceKey) {
                const instrumentationContext = <InstrumentationContext>{
                    referenceKey: row.ReferenceKey,
                    index: tableView.rowKeys.length - 1,
                    traceId: searchResponseId,
                    logicalId: searchLogicalId,
                };

                updateInstrumentationContext(row.InstanceKey, tableView, instrumentationContext);
            }
        }

        // VSO 1285: optimize this number on empty folder
        setTotalRowsInView(tableView, totalRowsInViewInResponse - failedCount);
    });

    fetchRichContentForRows(tableView);
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setTotalRowsInView = mutatorAction(
    'setTotalRowsInView',
    (tableView: TableView, totalRowsInView: number) => {
        tableView.totalRowsInView = totalRowsInView;
    }
);
