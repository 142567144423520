import { mutatorAction } from 'satcheljs';
import getStore from '../store/store';

const setShowSettingsHoverActionMutator = mutatorAction(
    'setShowSettingsHoverActionMutator',
    showSettingsButton => {
        getStore().showSettingsHoverAction = showSettingsButton;
    }
);

export default setShowSettingsHoverActionMutator;
