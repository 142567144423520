import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import type { AdditionalAccountConfiguration } from '../store/schema/OwaSessionStore';
import { makeEmptyAccountConfiguration } from './makeEmptyAccountConfiguration';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';

export const makeAdditionalAccountConfiguration = (
    userConfiguration: OwaUserConfiguration
): AdditionalAccountConfiguration => {
    const additionalProperties = makeEmptyAccountConfiguration();

    const sessionSettings = userConfiguration.SessionSettings;
    const folderNames = sessionSettings?.DefaultFolderNames as DistinguishedFolderIdName[];
    const folderIds = sessionSettings?.DefaultFolderIds;
    if (folderNames !== undefined && folderIds !== undefined) {
        folderNames.forEach((defaultFolderName, i) => {
            if (defaultFolderName) {
                const folderId = folderIds[i];
                if (folderId) {
                    additionalProperties.defaultFolderNameToIdMap.set(
                        defaultFolderName,
                        folderId.Id
                    );
                    additionalProperties.defaultFolderIdToNameMap.set(
                        folderId.Id,
                        defaultFolderName
                    );
                }
            }
        });
    }

    return additionalProperties;
};
