import type MailboxInfo from './MailboxInfo';

/**
 * Specialization of the MailboxInfo type for public folder mailboxes.
 */
export interface PublicFolderMailboxInfo extends MailboxInfo {
    readonly type: 'PublicMailbox';
}

/**
 * Predicate function to check if the mailboxInfo is a PublicFolderMailboxInfo
 */
export function isPublicFolderMailboxInfo(
    mailboxInfo: MailboxInfo
): mailboxInfo is PublicFolderMailboxInfo {
    return mailboxInfo.type === 'PublicMailbox';
}
