import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyIsTeamsChatEnabled } from 'owa-teams-policies';
import type { TeamsPoliciesScenario } from 'owa-teams-policies-store/lib/store/schema';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export default async function isTeamsEnabled(scenario: TeamsPoliciesScenario): Promise<boolean> {
    if (isFeatureEnabled('fwk-teamsPolicies')) {
        return lazyIsTeamsChatEnabled.importAndExecute(
            scenario,
            getGlobalSettingsAccountMailboxInfo()
        );
    } else {
        return false;
    }
}
