import { owaComputedFn } from 'owa-computed-fn';
import {
    getPinnedAddInsRuntimeControls,
    getReadPinnedAppsRuntimeControls,
} from './getPinnedAddInsRuntimeControls';
import { getInstalledAddInsRuntimeControls } from './getInstalledAddInsRuntimeControls';
import type { ComposeViewState } from 'owa-mail-compose-store';
import type { RibbonControlProps } from 'owa-acui';
import type { RibbonGroupId, RuntimeControlId } from 'owa-ribbon-ids';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { isMessageExtensionsFlyoutEnabled } from 'owa-m365-acquisitions/lib/utils/isMessageExtensionsFlyoutEnabled';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';

type RibbonRuntimeControlGroup =
    | 'ComposeAddInsInstalled'
    | 'ComposeAddInsPinned'
    | 'ReadAppsPinned';

export type RibbonRuntimeControl = {
    controlId: RuntimeControlId;
    buttonProps: RibbonControlProps;
};
export interface RibbonRuntimeControlsGroup {
    /* Identifies the runtime control group */
    controlsGroupName: RibbonRuntimeControlGroup;

    /* Callback that gates whether this control group should get scaling steps */
    shouldAddScalingSteps: () => boolean;

    /* Generate a list of a runtime control group ribbon's button props */
    getComposeControlsProps: (props: {
        composeViewState?: ComposeViewState;
    }) => RibbonRuntimeControl[];

    getReadControlsProps: (props: ReadOnlyRibbonControlDefProps) => RibbonRuntimeControl[];

    /* Get a list of a runtime control group's controlIds; needs to be called after getControlsProps */
    getControlIds: () => RuntimeControlId[];

    getNumControls: () => number;
}

// List of all runtime controls.
let runtimeControlsGroups: RibbonRuntimeControlsGroup;
let readRuntimeControlsGroups: RibbonRuntimeControlsGroup;
let isComposeInitialized = false;
let isReadInitialized = false;

/**
 * Pinned and Installed addins are mutually exclusive based on message extension flights
 * We only need one instance, to save from having to load both
 */
export const getComposeRuntimeControls = owaComputedFn(
    function getComposeRuntimeControls(): RibbonRuntimeControlsGroup {
        if (!isComposeInitialized) {
            // getPinnedAddins and getInstalledAddins are mutually exclusive based on message extension flights
            // Only load one of them based on the flight
            runtimeControlsGroups = isMessageExtensionsFlyoutEnabled()
                ? getPinnedAddInsRuntimeControls()
                : getInstalledAddInsRuntimeControls();
            isComposeInitialized = true;
        }
        return runtimeControlsGroups;
    }
);

/**
 * Returns a list of runtime controls for read mode
 * Gated using areMessageExtensionsEnabledOnReadRibbon
 */
export const getReadRuntimeControls = owaComputedFn(
    function getReadRuntimeControls(): RibbonRuntimeControlsGroup {
        if (!isReadInitialized) {
            readRuntimeControlsGroups = getReadPinnedAppsRuntimeControls();
            isReadInitialized = true;
        }
        return readRuntimeControlsGroups;
    }
);

/**
 * Runtime controls only ever are added to either Message Tab Addins group or Insert Tab Addins group
 */
export const isGroupRuntimeControlEnabled = owaComputedFn(function isGroupRuntimeControlEnabled(
    groupId: RibbonGroupId
): boolean {
    return groupId === 159 || groupId === 165;
});

/**
 * Runtime controls for spam-itegration only ever are added to either Home Tab  or Read Tab Spam Addins group
 */
export const isGroupRuntimeControlEnabledForSpamIntegration = owaComputedFn(
    function isGroupRuntimeControlEnabledForSpamIntegration(groupId: RibbonGroupId): boolean {
        return groupId === 192;
    }
);

/**
 * Runtime controls for read pinned apps are only ever are added to either the Home Tab or Read Tab Addins group
 */
export const isReadGroupRuntimeControlEnabled = owaComputedFn(
    function isReadGroupRuntimeControlEnabled(groupId: RibbonGroupId): boolean {
        return groupId === 101;
    }
);
