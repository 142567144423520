import type { MailboxInfo } from 'owa-client-types';
import isBusiness from 'owa-session-store/lib/utils/isBusiness';
import { isTeamsChatPolicyEnabled } from 'owa-teams-policies-store/lib/selectors/isTeamsChatPolicyEnabled';
import { isCapabilityEnabled } from 'owa-capabilities';
import { chatIntegrationCapability } from 'owa-capabilities-definitions/lib/chatIntegrationCapability';

export function isTeamsChatEnabled(mailboxInfo: MailboxInfo): boolean {
    return (
        isCapabilityEnabled(chatIntegrationCapability, mailboxInfo) &&
        isBusiness(mailboxInfo) &&
        isTeamsChatPolicyEnabled()
    );
}
