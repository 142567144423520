import {
    createLazyComponent,
    registerLazyOrchestrator,
    LazyImport,
    LazyModule,
} from 'owa-bundling';
import { endSearchSession } from 'owa-search-actions';
import { onAppBarTrigger } from 'owa-nova-actions';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Lightable"*/ './lazyIndex'), {
    name: 'Lightable',
});

export const lazySetLatestQFRequestId = new LazyImport(lazyModule, m => m.setLatestQFRequestId);

// Export mutators
export const SearchBoxDropdown = createLazyComponent(lazyModule, m => m.SearchBoxDropdown);
export const SearchBoxPillWell = createLazyComponent(lazyModule, m => m.SearchBoxPillWell);
export const SuggestionsCallout = createLazyComponent(lazyModule, m => m.SuggestionsCallout);
export const CompactSearchBox = createLazyComponent(lazyModule, m => m.CompactSearchBox);
export const SearchScopePicker = createLazyComponent(lazyModule, m => m.SearchScopePicker);
export const ModifiedQueryInformationalView = createLazyComponent(
    lazyModule,
    m => m.ModifiedQueryInformationalView
);
export const SearchScopePickerContextualMenu = createLazyComponent(
    lazyModule,
    m => m.SearchScopePickerContextualMenu
);

// Export selectors
export const lazyGetSuggestionAtIndex = new LazyImport(lazyModule, m => m.getSuggestionAtIndex);
export const lazyIsInSearchMode = new LazyImport(lazyModule, m => m.isInSearchMode);
export const lazyIsQFRequestIdEqualToLatest = new LazyImport(
    lazyModule,
    m => m.isQFRequestIdEqualToLatest
);
export const lazyIsSearchBoxEmpty = new LazyImport(lazyModule, m => m.isSearchBoxEmpty);
export const lazyHasSuggestionPills = new LazyImport(lazyModule, m => m.hasSuggestionPills);
export const lazyGetCopilotChatQuery = new LazyImport(lazyModule, m => m.getCopilotChatQuery);

// Export orchestrators
registerLazyOrchestrator(endSearchSession, lazyModule, m => m.endSearchSessionOrchestrator);
registerLazyOrchestrator(onAppBarTrigger, lazyModule, m => m.onAppBarTriggerOrchestrator);
