import { isConnectedAccount } from 'owa-accounts-store';
import type { MailboxInfo } from 'owa-client-types';
import getMasterCategoryList from './getMasterCategoryList';
import type { CalendarsUnionType } from 'owa-graph-schema';
import type CategoryType from 'owa-service/lib/contract/CategoryType';
import { canDelegateManageCategories } from 'owa-calendar-cache/lib/utils/canDelegateManageCategories';
import { isSupportingRemoteCategories } from 'owa-categories-option-store';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';

export default function getMasterOrRemoteCategoryList(
    calendarEntry: CalendarsUnionType | undefined | null,
    mailboxInfo: MailboxInfo
) {
    let manageCategoriesDisabled = false;
    let createNewCategoryDisabled = false;
    let categoryList = getMasterCategoryList(mailboxInfo);
    const isConnected = mailboxInfo && isConnectedAccount(mailboxInfo.userIdentity);
    if (isConnected) {
        createNewCategoryDisabled = true;
    }

    if (calendarEntry) {
        const maybeCategoryList = isSupportingRemoteCategories(calendarEntry)
            ? calendarEntry.RemoteCategories?.MasterList
            : undefined;
        if (maybeCategoryList as CategoryType[]) {
            if (!canDelegateManageCategories(calendarEntry)) {
                // on remote calendars, we use the remote category list and don't allow managing categories
                manageCategoriesDisabled = true;
                createNewCategoryDisabled = true;
            }
            categoryList = maybeCategoryList as CategoryType[];
        } else if (canDelegateManageCategories(calendarEntry)) {
            // Returning a dummy category here is because the return type of categoryList is CategoryType[] and it is used in a lot of other places. If we return undefined here, it will be a lot of value checks in other places.
            // If returning an empty array, it will be confused with user deleting all categories and left with an empty array.
            categoryList = [{ Id: 'Dummy' }];
        }
    }
    // Removing Manage Categories for users in Teams calendar
    if (isConnected || !isHostAppFeatureEnabled('settings')) {
        manageCategoriesDisabled = true;
    }

    return { manageCategoriesDisabled, createNewCategoryDisabled, categoryList };
}
