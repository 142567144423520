import { M365AcquisitionsDocument } from 'owa-m365-acquisitions/lib/graphql/query/__generated__/M365Acquisitions.interface';
import {
    getBootM365Acquisitions,
    toBootM365AcquisitionsQuery,
} from 'owa-m365-acquisitions/lib/getBootM365Acquisitions';
import { errorThatWillCauseAlert } from 'owa-trace';
import { writeQuery } from 'owa-apollo';
import type { MailboxInfo } from 'owa-client-types';
import checkFeatureFlagLoadStateForMailboxInfo from 'owa-feature-flags/lib/utils/checkFeatureFlagLoadStateForMailboxInfo';

export async function bootstrapM365AcquisitionsCache(mailboxInfo?: MailboxInfo) {
    try {
        const m365Acquisitions = await getBootM365Acquisitions(mailboxInfo);

        // check if feature flags are loaded for the account associated with the mailboxInfo
        checkFeatureFlagLoadStateForMailboxInfo('M365AcquisitionsWrite', mailboxInfo);
        writeQuery(M365AcquisitionsDocument, {
            data: toBootM365AcquisitionsQuery(m365Acquisitions),
            variables: {
                mailboxInfo,
            },
        });
    } catch (e) {
        errorThatWillCauseAlert('M365Acquisitions_CachePriming_Error', e);
    }
}
