import { trace } from 'owa-trace';
import { Constants } from 'owa-copilot-themes-config';

/**
 * Gets the AI generated image from the cache storage
 */
export default async function fetchCopilotThemeImageFromCache(
    timestamp: string
): Promise<Response> {
    try {
        const cache = await self.caches.open(Constants.COPILOT_THEME_CACHE_NAME);

        // Fetch the image response from the cache
        const response = await cache.match(Constants.COPILOT_THEME_IMAGE_CACHE_KEY);

        if (response && isTimestampValid(timestamp, response)) {
            return response;
        }

        return Response.error();
    } catch (error) {
        trace.warn(`Error matching generated blob image from cache`, error);
        throw error;
    }
}

function isTimestampValid(timestamp: string, cachedResponse: Response): boolean {
    const cachedTimestamp = cachedResponse.headers?.get(Constants.COPILOT_THEME_TIMESTAMP_HEADER);

    if (!cachedTimestamp) {
        return false;
    }

    if (timestamp != cachedTimestamp) {
        return false;
    }

    return true;
}
