import type { RibbonTabDefinitionWithStyles, RibbonControlGroupDefinitionBase } from 'owa-acui';
import type { RibbonId } from 'owa-ribbon-ids';
import { getStringRibbonId } from 'owa-ribbon-ids';
import { SingleLine, Multiline } from './ribbonTypes';
import { isSingleLineRibbon } from 'owa-command-ribbon-store/lib/selectors/isSingleLineRibbon';
import { constructKeytip } from './Keytips/keytipUtils';
import type { LazyImport, LazyModule } from 'owa-bundling';
import { getTabStyles } from './getRibbonStyles';

export function createLazyRibbonTabDefinition(
    currentTab: RibbonId,
    ribbonId: RibbonId,
    title: string,
    lazySlrControlGroups: LazyImport<() => RibbonControlGroupDefinitionBase[], LazyModule<any>>,
    lazyMlrControlGroups: LazyImport<() => RibbonControlGroupDefinitionBase[], LazyModule<any>>
): RibbonTabDefinitionWithStyles {
    const isSingleLine = isSingleLineRibbon();
    const type = isSingleLine ? SingleLine : Multiline;
    const id = getStringRibbonId(ribbonId);

    let groups: RibbonControlGroupDefinitionBase[] = [];
    if (getStringRibbonId(currentTab) === id) {
        if (isSingleLine) {
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * This will cause an extra render which is intentional as we want the button
             * in the top bar to render first and then the bottom bar to render next */
            const slrControlGroups = lazySlrControlGroups.tryImportForRender();
            if (slrControlGroups) {
                groups = slrControlGroups();
            }
        } else {
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * This will cause an extra render which is intentional as we want the button
             * in the top bar to render first and then the bottom bar to render next */
            const mlrControlGroups = lazyMlrControlGroups.tryImportForRender();
            if (mlrControlGroups) {
                groups = mlrControlGroups();
            }
        }
    }

    return {
        id,
        type,
        title,
        controlGroups: {
            type,
            groups,
        },
        styles: getTabStyles(),
        keytipProps: constructKeytip([], ribbonId, true /* hasMenu */),
    };
}
