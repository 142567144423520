import { getStore } from '../store/store';
import { mutatorAction } from 'satcheljs';

/*
 * Toggles isDraggedOver state
 * @param isDraggedOver indicates if folder pane is dragged over
 */
export default mutatorAction(
    'setIsDraggedOverState',
    function setIsDraggedOverState(isDraggedOver: boolean): void {
        getStore().isDraggedOver = isDraggedOver;
    }
);
