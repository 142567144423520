import type { NovaEvent } from '@nova/types';
import type { AppHostServiceEventOriginator } from './originator';
import type { MailboxInfo } from 'owa-client-types';

export const ImportContactsEventType = 'importContacts' as const;

export interface ImportContactsEvent extends NovaEvent<ImportContactsEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof ImportContactsEventType;
    data: () => ImportContactsEventData;
}

export interface ImportContactsEventData {
    fileName: string;
    contactsToImport: ArrayBufferLike | null | undefined;
    mailboxInfo: MailboxInfo;
}
