import { createLazyComponent, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import { onShowToast } from 'owa-nova-actions';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaActionResultToaster" */ './lazyIndex'),
    { name: 'OwaActionResultToaster' }
);

registerLazyOrchestrator(onShowToast, lazyModule, m => m.showActionResultToast);

export const ActionResultToasterAsync = createLazyComponent(
    lazyModule,
    m => m.ActionResultToasterWrapper
);
