import { LazyAction, LazyModule, LazyImport, createLazyComponent } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SearchRefiners"*/ './lazyIndex'),
    { name: 'SearchRefiners' }
);

// Lazy-load components
export const RefinersContainer = createLazyComponent(
    lazyModule,
    m => m.RefinersContainer,
    undefined,
    undefined,
    { govern: true }
);

export const QfRefinersContainer = createLazyComponent(
    lazyModule,
    m => m.QfRefinersContainer,
    undefined,
    undefined,
    { govern: true }
);

export const lazyCopyQfRefinersToAppliedRefiners = new LazyImport(
    lazyModule,
    m => m.copyQfRefinersToAppliedRefiners
);

export const lazySetRefinersInstrumentationContext = new LazyImport(
    lazyModule,
    m => m.setRefinersInstrumentationContext
);

// Register mutators
import './mutators/initializeRefinersScenarioStore';

// selectors
export { default as getAppliedRefiners } from './selectors/getAppliedRefiners';
export { default as getSuggestedRefiners } from './selectors/getSuggestedRefiners';
export { default as getSearchRefinersState } from './selectors/getSearchRefinersState';

// actions
export const lazyClearAppliedRefiners = new LazyAction(lazyModule, m => m.clearAppliedRefiners);
export const lazySetSuggestedRefiners = new LazyAction(lazyModule, m => m.setSuggestedRefiners);
export const lazyClearSuggestedRefiners = new LazyAction(lazyModule, m => m.clearSuggestedRefiners);
