import { type MailRibbonGroupId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import Flag from 'owa-fluent-icons-svg/lib/icons/FlagRegular';
import FolderArrowRight from 'owa-fluent-icons-svg/lib/icons/FolderArrowRightRegular';
import GroupStrings from 'owa-locstrings/lib/strings/ribbon_mail_groups.locstring.json';
import type { MenuDefinition, RibbonFlyoutAnchorProps } from 'owa-acui';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import Search from 'owa-fluent-icons-svg/lib/icons/SearchRegular';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip } from 'owa-command-ribbon';
import { createMailAppFlyoutAnchor } from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import loc from 'owa-localize';

const emptyMenuDefinition: MenuDefinition = {
    sections: [],
};

export const getMLRHomeTabCommonGroupFlyout = owaComputedFn(function getMLRHomeTabCommonGroupFlyout(
    groupId: RibbonGroupId,
    props: ReadOnlyRibbonControlDefProps
): RibbonFlyoutAnchorProps | undefined {
    switch (groupId) {
        case 108:
            return createMailAppFlyoutAnchor(
                groupId,
                loc(GroupStrings.find_GroupButton),
                undefined /*tooltip*/,
                props,
                emptyMenuDefinition,
                constructKeytip([1], 108),
                Search
            );

        case 113:
            return createMailAppFlyoutAnchor(
                groupId,
                loc(GroupStrings.move_GroupButton),
                undefined /*tooltip*/,
                props,
                emptyMenuDefinition,
                constructKeytip([1], 113),
                FolderArrowRight
            );

        case 128:
            return createMailAppFlyoutAnchor(
                groupId,
                loc(GroupStrings.tags_GroupButton),
                undefined /*tooltip*/,
                props,
                emptyMenuDefinition,
                constructKeytip([1], 128),
                Flag
            );

        default:
            return undefined;
    }
});
