import {
    accountRankTypeChecker,
    getAccountBySourceId,
    getCoprincipalAccountForAccountKey,
} from 'owa-account-source-list-store';
import getAccountDiagnosticDataForMailboxInfo from 'owa-account-source-list-store/lib/utils/getAccountDiagnosticDataForMailboxInfo';
import type {
    M365InstallationCheckResult,
    AccountActionOrigin,
    AccountSourceProtocolData,
    AccountSourceType,
    LicensingMailboxInfo,
    LicensingRemediationResult,
    PstPrerequisitesStatus,
} from 'owa-account-source-list-types';
import type { AuthUXCallbacks } from 'owa-auth-callbacks';
import type { IdentityInfo } from 'owa-auth-types';
import { isValidAccountKey, getAccountKeyForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import {
    errorThatWillCauseAlert,
    errorThatWillCauseAlertAndThrow,
    type TraceErrorObject,
} from 'owa-trace';
import { CreateAccountErrors, type CreateAccountResult, type RemoveAccountResult } from '../types';
import type { AutomappedMailbox } from 'owa-account-storage-types';
import { removeAutomappedSharedMailbox } from './removeAutomappedSharedMailbox';

/**
 * Defines the interface that the hosting application must implement
 */
export interface PluggableHostAccountSource {
    addM365Account?: (
        correlationId: string,
        email: string,
        userIdentity: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks,
        isAnotherMailbox?: boolean,
        silentAuthToken?: string,
        skipAccountPolicyChecks?: boolean
    ) => Promise<CreateAccountResult>;
    addOutlookDotComAccount?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks,
        silentAuthToken?: string
    ) => Promise<CreateAccountResult>;
    addGoogleAccount?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addYahooAccount?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addImapAccount?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        protocolData: AccountSourceProtocolData[],
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addPop3Account?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        protocolData: AccountSourceProtocolData[],
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addICloudAccount?: (
        correlationId: string,
        email: string,
        origin: AccountActionOrigin,
        protocolData: AccountSourceProtocolData[],
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    addPstFileAccount?: (
        correlationId: string,
        filepath: string,
        displayName: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    removeAccount?: (sourceId: string, origin: AccountActionOrigin) => Promise<RemoveAccountResult>;
    removePendingAccount?: (
        emailAddress: string,
        userIdenitity: string,
        origin: AccountActionOrigin,
        accountType: AccountSourceType
    ) => Promise<RemoveAccountResult>;
    disablePendingAccount?: (
        emailAddress: string,
        userIdentity: string,
        origin: AccountActionOrigin,
        accountType: AccountSourceType
    ) => Promise<RemoveAccountResult>;
    createAndAddOutlookDotComAccount?: (
        correlationId: string,
        origin: AccountActionOrigin,
        authUxCallbacks?: AuthUXCallbacks
    ) => Promise<CreateAccountResult>;
    setGlobalSettingsAccountAndRunOOBE?: (
        sourceId: string,
        origin: AccountActionOrigin
    ) => Promise<boolean>;
    // The getTokenForIdentity function is used when it is necessary to get a token
    // for an M365 identity for which there is not an account configured.
    getAuthTokenForIdentityInfo?: (
        identityInfo: IdentityInfo,
        headers?: HeadersWithoutIterator,
        isInteractive?: boolean,
        isFirstUsage?: boolean
    ) => Promise<string | undefined>;
    checkM365Installation?: () => Promise<M365InstallationCheckResult>;
    remediateAllLicensingAccounts?: (isSilent: boolean) => Promise<LicensingRemediationResult[]>;
    remediateLicensingMailbox?: (
        licensingMailbox: LicensingMailboxInfo,
        isSilent: boolean
    ) => Promise<LicensingRemediationResult>;
    tryGetTokenSilently?: (
        mailboxInfo: MailboxInfo,
        sourceType: AccountSourceType
    ) => Promise<string | undefined>;
    getHiddenAutomappedMailboxesForAccount?: (sourceId: string) => AutomappedMailbox[] | undefined;
    hideAutomappedMailboxForAccount?: (sourceId: string, display: string, email: string) => void;
    unhideAutomappedMailboxforAccount?: (sourceId: string, email: string) => void;
    doesSharedMailboxHaveFullAccess?: (mailboxInfo: MailboxInfo) => Promise<boolean>;
    checkPstPrerequisites?: () => Promise<PstPrerequisitesStatus>;
}

// The pluggable host instance
let thePluggableHost: PluggableHostAccountSource = {};

/**
 * Sets the pluggable host
 * @param host provides the host specific implementation for getting account information
 */
export function updatePluggableHost(host?: Partial<PluggableHostAccountSource>) {
    thePluggableHost = { ...thePluggableHost, ...host };
}

/**
 * Restores the pluggable host to the default pluggable host
 */
export function testHookToResetPluggableHost() {
    thePluggableHost = {};
}

/**
 * Adds a Microsoft 365 enterprise account
 * @param email Email address of the mailbox to be connected to
 * @param userIdentity The user principal named that identifies how we will be authenticating
 * @param origin The source of the request to add the Microsoft 365 account
 * @returns The result of creating the account
 */
export async function addM365Account(
    correlationId: string,
    email: string,
    userIdentity: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks,
    isAnotherMailbox?: boolean,
    silentAuthToken?: string,
    skipAccountPolicyChecks?: boolean
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addM365Account) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddM365Account');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addM365Account!(
        correlationId,
        email,
        userIdentity,
        origin,
        authUxCallbacks,
        isAnotherMailbox,
        silentAuthToken,
        skipAccountPolicyChecks
    );
}

export async function addOutlookDotComAccount(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks,
    silentAuthToken?: string
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addOutlookDotComAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddOutlookDotComAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addOutlookDotComAccount!(
        correlationId,
        email,
        origin,
        authUxCallbacks,
        silentAuthToken
    );
}

export async function addGoogleAccount(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addGoogleAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddGoogleAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addGoogleAccount!(correlationId, email, origin, authUxCallbacks);
}

export async function addYahooAccount(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addYahooAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddYahooAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addYahooAccount!(correlationId, email, origin, authUxCallbacks);
}

export async function addImapAccount(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    protocolData: AccountSourceProtocolData[],
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addImapAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddImapAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addImapAccount!(
        correlationId,
        email,
        origin,
        protocolData,
        authUxCallbacks
    );
}

export async function addPop3Account(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    protocolData: AccountSourceProtocolData[],
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addPop3Account) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddPop3Account');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addPop3Account!(
        correlationId,
        email,
        origin,
        protocolData,
        authUxCallbacks
    );
}

export async function addICloudAccount(
    correlationId: string,
    email: string,
    origin: AccountActionOrigin,
    protocolData: AccountSourceProtocolData[],
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addICloudAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddiCloudAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.addICloudAccount!(
        correlationId,
        email,
        origin,
        protocolData,
        authUxCallbacks
    );
}

export async function addPstFileAccount(
    correlationId: string,
    filepath: string,
    displayName: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.addPstFileAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddPstFileAccount');
    }

    return (
        thePluggableHost?.addPstFileAccount(
            correlationId,
            filepath,
            displayName,
            origin,
            authUxCallbacks
        ) ??
        Promise.resolve({
            wasSuccessful: false,
            error: {
                error: CreateAccountErrors.ProviderNotSupported,
            },
        })
    );
}

export async function removeAccount(
    sourceId: string,
    origin: AccountActionOrigin
): Promise<RemoveAccountResult> {
    if (!thePluggableHost.removeAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodRemoveAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.removeAccount!(sourceId, origin);
}

export async function removePendingAccount(
    emailAddress: string,
    userIdentity: string,
    origin: AccountActionOrigin,
    accountType: AccountSourceType
): Promise<RemoveAccountResult> {
    if (!thePluggableHost.removePendingAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodRemoveAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.removePendingAccount!(emailAddress, userIdentity, origin, accountType);
}

export async function disablePendingAccount(
    emailAddress: string,
    userIdentity: string,
    origin: AccountActionOrigin,
    accountType: AccountSourceType
): Promise<RemoveAccountResult> {
    if (!thePluggableHost.disablePendingAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodDisableAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.disablePendingAccount!(emailAddress, userIdentity, origin, accountType);
}

export async function createAndAddOutlookDotComAccount(
    correlationId: string,
    origin: AccountActionOrigin,
    authUxCallbacks?: AuthUXCallbacks
): Promise<CreateAccountResult> {
    if (!thePluggableHost.createAndAddOutlookDotComAccount) {
        errorThatWillCauseAlertAndThrow('NoPluggableMethodAddOutlookDotComAccount');
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return thePluggableHost.createAndAddOutlookDotComAccount!(
        correlationId,
        origin,
        authUxCallbacks
    );
}

export function isSettingGlobalSettingsAccountAllowed(): boolean {
    return !!thePluggableHost.setGlobalSettingsAccountAndRunOOBE;
}

export function setGlobalSettingsAccountAndRunOOBE(
    sourceId: string,
    origin: AccountActionOrigin
): Promise<boolean> {
    if (thePluggableHost.setGlobalSettingsAccountAndRunOOBE) {
        return thePluggableHost.setGlobalSettingsAccountAndRunOOBE(sourceId, origin);
    }

    errorThatWillCauseAlert('NoPluggableMethodSetGlobalSettingsAccountAndRunOOBE');
    return Promise.resolve(false);
}

/**
 * When the account cannot be found when getting the persistence Id we raise an alert
 * and include diagnostic data about the MailboxInfo.
 * @param mailboxInfo MailboxInfo for which an account could not be found
 */
function alertOnAccountNotFound(mailboxInfo: MailboxInfo): void {
    const accountDiagnostic = getAccountDiagnosticDataForMailboxInfo(mailboxInfo);
    const info = {
        mailboxType: mailboxInfo?.type,
        hasUserId: !!mailboxInfo?.userIdentity,
        hasSmtp: !!mailboxInfo?.mailboxSmtpAddress,
        rank: mailboxInfo?.mailboxRank,
        isAnonymous: mailboxInfo?.isAnonymous,
        isRemoved: mailboxInfo?.isRemoved,
        ...accountDiagnostic,
    };

    const error: TraceErrorObject = new Error('FailedToFindCoprincipalAccount');
    error.additionalInfo = info;
    errorThatWillCauseAlert(error);
}

export function getCoprincipalAccountPersistenceId(
    mailboxInfo: MailboxInfo,
    noAlertOnFailure?: boolean
): string {
    if (isPersistenceIdIndexerEnabled()) {
        // When the account key is based on the persistenceId we can just get the account key
        const persistenceId = getAccountKeyForMailboxInfo(mailboxInfo);
        if (isValidAccountKey(persistenceId)) {
            return persistenceId;
        }

        if (!noAlertOnFailure) {
            alertOnAccountNotFound(mailboxInfo);
        }

        return '';
    }

    // If sourceId (the key in the store) is present try and use it to find the coprincipal account
    if (!!mailboxInfo.sourceId) {
        const accountViaSourceId = getAccountBySourceId(mailboxInfo.sourceId);
        if (
            accountRankTypeChecker.isCoprincipal(accountViaSourceId) &&
            accountViaSourceId.persistenceId
        ) {
            return accountViaSourceId.persistenceId;
        }
    }

    // The second option is to use the account key to try and find the coprincipal account
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const account = getCoprincipalAccountForAccountKey(accountKey);
    if (account?.persistenceId) {
        return account.persistenceId;
    }

    if (!noAlertOnFailure) {
        alertOnAccountNotFound(mailboxInfo);
    }

    return '';
}

export function getAuthTokenForIdentityInfo(
    identityInfo: IdentityInfo,
    headers?: HeadersWithoutIterator,
    isInteractive?: boolean,
    isFirstUsage?: boolean
): Promise<string | undefined> {
    if (!thePluggableHost.getAuthTokenForIdentityInfo) {
        errorThatWillCauseAlert('NoPluggableGetAuthTokenForIdentityInfo');
        return Promise.resolve(undefined);
    }

    return thePluggableHost.getAuthTokenForIdentityInfo(
        identityInfo,
        headers,
        isInteractive,
        isFirstUsage
    );
}

export function checkM365Installation(): Promise<M365InstallationCheckResult> {
    if (!thePluggableHost.checkM365Installation) {
        errorThatWillCauseAlert('NoPluggableMethodCheckM365Installation');
        return Promise.resolve({
            hasOutlookMapiInstalled: false,
            targetArch: undefined,
            checkedForOutlookMapiWithMismatchedArch: false,
            outlookMapiArch: undefined,
        });
    }

    return thePluggableHost.checkM365Installation();
}

export function remediateAllLicensingAccounts(
    isSilent: boolean
): Promise<LicensingRemediationResult[]> {
    if (!thePluggableHost.remediateAllLicensingAccounts) {
        errorThatWillCauseAlert('NoPluggableMethodRemediateAllLicensingAccounts');
        throw new Error('NoPluggableMethodRemediateAllLicensingAccounts');
    }

    return thePluggableHost.remediateAllLicensingAccounts(isSilent);
}

export function remediateLicensingMailbox(
    licensingMailbox: LicensingMailboxInfo,
    isSilent: boolean
): Promise<LicensingRemediationResult> {
    if (!thePluggableHost.remediateLicensingMailbox) {
        errorThatWillCauseAlert('NoPluggableMethodRemediateLicensingMailbox');
        throw new Error('NoPluggableMethodRemediateAllLicensingAccounts');
    }

    return thePluggableHost.remediateLicensingMailbox(licensingMailbox, isSilent);
}

export function tryGetTokenSilently(
    mailboxInfo: MailboxInfo,
    sourceType: AccountSourceType
): Promise<string | undefined> {
    if (thePluggableHost.tryGetTokenSilently) {
        return thePluggableHost.tryGetTokenSilently(mailboxInfo, sourceType);
    }

    errorThatWillCauseAlert('NoPluggableMethodSetGlobalSettingsAccountAndRunOOBE');
    return Promise.resolve('');
}

export function getHiddenAutomappedMailboxes(sourceId: string): AutomappedMailbox[] | undefined {
    return thePluggableHost.getHiddenAutomappedMailboxesForAccount?.(sourceId);
}

/**
 * Hides the specified automapped mailbox and removes it from the store
 * @param sourceId sourceId of the account
 * @param display Display name of the automapped mailbox
 * @param email Email address of the automapped mailbox
 */
export function hideAutomappedMailbox(sourceId: string, display: string, email: string) {
    if (thePluggableHost.hideAutomappedMailboxForAccount) {
        thePluggableHost.hideAutomappedMailboxForAccount(sourceId, display, email);
        // remove the automapped mailbox from the store
        removeAutomappedSharedMailbox(sourceId, email);
    }
}

/**
 * Remove the hidding of the automapped mailbox, to get the mailbox back into the store
 * the ensureSharedMailboxData will need to be called for forceUpdate as true
 * @param sourceId SourcId of the account
 * @param email Email of the automapped shared mailbox to be unhidden
 */
export function unhideAutomappedMailbox(sourceId: string, email: string) {
    thePluggableHost.unhideAutomappedMailboxforAccount?.(sourceId, email);
}

/**
 *
 * @param mailboxInfo
 * @returns
 */
export function doesSharedMailboxHaveFullAccess(mailboxInfo: MailboxInfo) {
    if (thePluggableHost.doesSharedMailboxHaveFullAccess) {
        return thePluggableHost.doesSharedMailboxHaveFullAccess(mailboxInfo);
    }

    errorThatWillCauseAlert('NoPluggableMethodDoesSharedMailboxHaveFullAccess');
    return Promise.resolve(false);
}

/**
 * Checks all of the prerequisites for PST support
 * @returns Summary of prerequisite check results
 */
export function checkPstPrerequisites(): Promise<PstPrerequisitesStatus> {
    if (thePluggableHost.checkPstPrerequisites) {
        return thePluggableHost.checkPstPrerequisites();
    }

    errorThatWillCauseAlertAndThrow('NoPluggableMethodCheckPstPrerequisites');
}
