import { LazyModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NotificationHelpers"*/ './lazyIndex'),
    { name: 'NotificationHelpers' }
);

export const lazyLaunchRemindersPopup = new LazyAction(lazyModule, m => m.launchRemindersPopup);
export const lazyCloseRemindersPopup = new LazyAction(lazyModule, m => m.closeRemindersPopup);
export { RemindersPopupActionSource } from './reminderHelpers/remindersPopupActionSource';
