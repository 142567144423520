import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import {
    isMailboxSharedOrDelegate,
    isAccountExplicitLogon,
} from 'owa-account-shared-mailbox-utils';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used to determine if the outlook favorites UI should be enabled
 */
export const favoritesCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (mailboxInfo) {
            if (isMonarchMultipleAccountsEnabled()) {
                // Monarch Favorites support logic
                if (isFeatureEnabled('fp-local-folder-favorites')) {
                    if (isFeatureEnabled('fp-local-pst-favorites')) {
                        return true;
                    } else {
                        return isServiceRequestSupportedForMailbox(mailboxInfo);
                    }
                } else {
                    const isShared = isMailboxSharedOrDelegate(mailboxInfo);
                    const isArchive = mailboxInfo.type === 'ArchiveMailbox';
                    const isPst = !isServiceRequestSupportedForMailbox(mailboxInfo);
                    // Without the isLocalFolderFavoriteSupported feature flag, no support for shared/delegate, archive and PST mailboxes
                    return !isShared && !isArchive && !isPst;
                }
            } else {
                // OWA Favorites support logic
                return mailboxInfo?.type === 'UserMailbox' && !isAccountExplicitLogon(mailboxInfo);
            }
        }
        return false;
    },
};
