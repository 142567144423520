import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { isMOSLaunchPagesEnabled } from './isMOSLaunchPagesEnabled';

/**
 * ToDo MetaOS app is enabled if mos-todoApp feature flag is enabled and MOS launch pages are enabled.
 */
export function isToDoMetaOsEnabled(mailboxInfo?: MailboxInfo) {
    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
     * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
     *	> The parameter mailboxInfo must be undefined. Feature flight: 'mos-todoApp' */
    return isFeatureEnabled('mos-todoApp', mailboxInfo) && isMOSLaunchPagesEnabled(mailboxInfo);
}
