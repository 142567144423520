/**
 * Function to construct a plain object from a Map. This is needed because `JSON.stringify` does not work with Maps.
 * @param map Map to be mapped to plain object
 * @returns Plain Object with the same key-value pairs as the input map
 */
export const mapToObj = <T>(map: Map<string | number, T>) => {
    const obj: Record<string | number, T | undefined | null> = {};
    for (const key of map.keys()) {
        obj[key] = map.get(key);
    }
    return obj;
};
