import type { NovaEvent } from '@nova/types';
import type { AppHostServiceEventOriginator } from './originator';

export const RefreshPersonInfoEventType = 'refreshPersonInfo' as const;

export interface RefreshPersonInfoEvent extends NovaEvent<RefreshPersonInfoEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof RefreshPersonInfoEventType;
    data: () => RefreshPersonInfoEventData;
}

export interface RefreshPersonInfoEventData {
    accountId?: string;
    /** PGS ID of the person. */
    personId?: string;

    /**
     * New identifiers after updating the LPC card.
     * It is not usually the case that the usual identifiers change after each update of a person.
     * However, upon unlinking a profile source from a person, the identifiers may change (especially AAD object ID).
     * This is why we need to pass the new identifiers to the orchestrator.
     */
    newIdentifiers?: Partial<Record<'aadObjectId' | 'contactId', string>>;
}
