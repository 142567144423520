import type { MailboxInfo } from 'owa-client-types';
import {
    getAccountKeyForMailboxInfo,
    isSameCoprincipalAccountByMailboxAndAccountKey,
} from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import favoritesStore from '../store/store';
import removeFavoriteDataFromStore from './helpers/removeFavoriteDataFromStore';

export default mutatorAction(
    'removeFavoritesForAccount',
    function removeFavoritesForAccount(mailboxInfo: MailboxInfo) {
        const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        favoritesStore.outlookFavorites.forEach(favoriteData => {
            if (
                isSameCoprincipalAccountByMailboxAndAccountKey(favoriteData.mailboxInfo, accountKey)
            ) {
                removeFavoriteDataFromStore(favoriteData);
            }
        });

        favoritesStore.favoriteTreeData.delete(accountKey);
    }
);
