import type { MailboxInfo } from 'owa-client-types';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import { getOpxHostApi, isOpxHostInitialized } from 'owa-opx';
import { isResourceTokenPrefetchEnabled } from './isResourceTokenPrefetchEnabled';
import { getConfig } from 'owa-service/lib/config';

export async function getResourceToken(
    resource: string,
    wwwAuthenticateHeader?: string,
    mailboxInfo?: MailboxInfo,
    scope?: string,
    requestId?: string
): Promise<TokenResponse | undefined> {
    const opxHostApi = isOpxHostInitialized() ? getOpxHostApi() : undefined;

    const serviceConfig = getConfig();
    const resourceTokenPrefetchEnabled = await isResourceTokenPrefetchEnabled();

    if (resourceTokenPrefetchEnabled) {
        // for Monarch we dont rely on opxHostApi anymore, instead get it from config.
        if (opxHostApi?.getResourceToken) {
            return opxHostApi.getResourceToken(resource, wwwAuthenticateHeader, mailboxInfo, scope);
        }

        return serviceConfig.getResourceToken?.(
            resource,
            wwwAuthenticateHeader,
            mailboxInfo,
            scope,
            undefined /*childAppId*/,
            undefined /*allowPrompt*/,
            undefined /*claims*/,
            requestId
        );
    }

    return Promise.resolve(undefined);
}
