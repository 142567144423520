import type { MailboxInfo } from 'owa-client-types';
import getUserMailboxInfo from './getUserMailboxInfo';

export default function getGroupMailboxInfo(groupId: string, userIdentity: string): MailboxInfo {
    /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
     * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
     *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
    return {
        type: 'GroupMailbox',
        userIdentity: userIdentity || getUserMailboxInfo().userIdentity, // Fallback to the previous implementation
        mailboxSmtpAddress: groupId,
    };
}
