import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getOcpsPolicyFromLocalStore } from './getOcpsPolicyFromLocalStore';
import type { OcpsPolicy } from './OcpsPolicy';
import type { MailboxInfo } from 'owa-client-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isOcpsStoreReady } from 'owa-ocps-get-policies';

export default function isOcpsPolicyEnabled(
    policy: OcpsPolicy,
    enabledIfUndefined?: boolean,
    mailboxInfo?: MailboxInfo
): boolean {
    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
     * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
     *	> The parameter mailboxInfo must be undefined. Feature flight: 'ocps-policy-check' */
    if (!isFeatureEnabled('ocps-policy-check', mailboxInfo) || isConsumer(undefined, mailboxInfo)) {
        return true;
    }

    if (!mailboxInfo) {
        mailboxInfo = getModuleContextMailboxInfo();
    }

    const policyState = getOcpsPolicyFromLocalStore(mailboxInfo, policy);
    if (policyState) {
        return policyState === '1';
    }

    return (isOcpsStoreReady() && enabledIfUndefined) ?? false;
}
