import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function isGeneric1PAddinSupported(mailboxInfo: MailboxInfo): boolean {
    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
     * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
     *	> The parameter mailboxInfo must be undefined. Feature flight: 'mon-copilot-ext-enableGeneric1PAddinSupport'
     This is a moving file that mon-copilot-ext-enableGeneric1PAddinSupport is used a couple of places. Making mailboxInfo as undefined as this time is too risky to only evaluate the global account.
     Making the flight as an account feature would touch multiple files and need careful consideration and testing seperately*/
    return isFeatureEnabled('mon-copilot-ext-enableGeneric1PAddinSupport', mailboxInfo);
}
