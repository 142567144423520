import { type default as TombstoneReasonType } from '../types/TombstoneReasonType';
import { logVerboseUsage } from 'owa-analytics';
import { addMailListLog, getMailListLogObjectToAddToStore } from 'owa-mail-list-logging';
import { lazyGetHashedLogString } from 'owa-logging-utils/lib/lazyFunctions';
import type { TableView } from 'owa-mail-list-store';
import { type TableQueryType } from 'owa-mail-list-store';
import { mutatorAction } from 'satcheljs';

interface TombstoneInfo {
    tombstoneReason: TombstoneReasonType;
    customData?: string; // This customData property is so we can include some data with the tombstone entry if needed. Consumers should stringify this data when adding to the tombstone map and parse it when they retrieve it.
}

interface RowKeyTombstoneMap {
    [instanceKey: string]: TombstoneInfo[];
}

export let folderTombstoneMap: {
    [folderId: string]: RowKeyTombstoneMap;
} = {};

/**
 * TombstoneOperations is a suppression map.
 * We add to the tombstone list after doing a client operation and we want
 * to suppress ( or do special processing on) server notifications for this row until
 * we get correct notification which indicates the completion of the operation server-side
 */

/**
 * Add each instanceKeys in the given list to the tombstone map
 * @param instanceKeys - the instanceKeys to be added to the tombstones map
 * @param tableView - tableView these instanceKeys belong to
 * @param tombstoneReasonType - the reason for which the row is being tombstoned
 * @param jsonData - custom data to store with the tombstone entry. Must be a valid JSON string.
 */
export const addMutator = mutatorAction(
    'tombstoneOperations.add',
    (
        instanceKeys: string[],
        tableView: TableView,
        tombstoneReasonType: TombstoneReasonType,
        jsonData?: string
    ) => {
        // Tombstone is not supported in search
        if (tableView.tableQuery.type == 1) {
            return;
        }

        const folderId = tableView.serverFolderId;

        // Create the tombstone map for the folder if it doesn't exist
        if (!(folderId in folderTombstoneMap)) {
            folderTombstoneMap[folderId] = {};
        }

        /* eslint-disable-next-line owa-custom-rules/no-async-calls-in-mutator -- (https://aka.ms/OWALintWiki)
         * This code should be fixed by anyone touching it. - WI #324665
         *	> Mutators should not dispatch asynchronous operations
         *	> Mutators should not dispatch asynchronous operations */
        lazyGetHashedLogString.import().then(getHashedLogString => {
            addMailListLog(
                getMailListLogObjectToAddToStore('Tombstone:AddRow', {
                    folderId: getHashedLogString(folderId),
                    rowKeys: instanceKeys.toString(),
                    addReason: tombstoneReasonType,
                })
            );
        });

        // Add to tombstone list
        const rowsTombstoneMap = folderTombstoneMap[folderId];
        for (const instanceKey of instanceKeys) {
            /**
             *  Add tombstone reason if no entry exists for this instanceKey
             */
            const existingTombstonedReasons = rowsTombstoneMap[instanceKey];
            if (!existingTombstonedReasons) {
                rowsTombstoneMap[instanceKey] = [
                    { tombstoneReason: tombstoneReasonType, customData: jsonData },
                ];
                return;
            }

            /**
             * Override the entry if its "RowRemove" type reason
             * If a row is removed and pending delete, no row modified operation should bring it back.
             */
            if (tombstoneReasonType == 3) {
                rowsTombstoneMap[instanceKey] = [{ tombstoneReason: 3, customData: jsonData }];
                return;
            }

            /**
             * Do not add the entry to the list if
             * there exists a RowRemove reason type entry for this row as it supercedes all other reasons
             */
            if (
                existingTombstonedReasons.findIndex(
                    tombstoneInfo => tombstoneInfo.tombstoneReason === 3
                ) > -1
            ) {
                return;
            }

            /**
             * Add the tombstone reason to the list if
             * it does not exist in the list
             */
            if (
                existingTombstonedReasons.findIndex(
                    tombstoneInfo => tombstoneInfo.tombstoneReason === tombstoneReasonType
                ) == -1
            ) {
                existingTombstonedReasons.push({
                    tombstoneReason: tombstoneReasonType,
                    customData: jsonData,
                });
            }
        }
    }
);

/**
 * Remove given reason for given instanceKey from the tombstones map if it exists
 * @param instanceKey - the instanceKey to remove
 * @param folderId for these instanceKeys
 * @param tombstoneReason reason to remove from the tombstone map
 */
export const removeMutator = mutatorAction(
    'tombstoneOperations.remove',
    function remove(instanceKey: string, folderId: string, tombstoneReason: TombstoneReasonType) {
        /**
         * Tombstone list doesn't exist for this folder.
         * User may have never performed scenarios that are supported by tombstone in this folder
         */
        if (!(folderId in folderTombstoneMap)) {
            return;
        }

        const rowTombstoneMap = folderTombstoneMap[folderId];
        const tombstoneReasonsForRow = rowTombstoneMap[instanceKey];
        const indexOfTombstoneReasonToRemove = tombstoneReasonsForRow
            ? tombstoneReasonsForRow.findIndex(
                  tombstoneInfo => tombstoneInfo.tombstoneReason === tombstoneReason
              )
            : -1;
        if (indexOfTombstoneReasonToRemove == -1) {
            throw new Error(
                'tombstone.remove given reason does not exist when trying to remove entry from it'
            );
        }

        tombstoneReasonsForRow.splice(indexOfTombstoneReasonToRemove, 1);

        /**
         * Delete row's entry if there are no more reasons are tombstoned for it
         */
        if (tombstoneReasonsForRow.length == 0) {
            delete rowTombstoneMap[instanceKey];
        }

        /**
         * Delete folder's entry if there are no more rows tombstoned in it
         */
        if (Object.keys(rowTombstoneMap).length == 0) {
            delete folderTombstoneMap[folderId];
        }

        /* eslint-disable-next-line owa-custom-rules/no-async-calls-in-mutator -- (https://aka.ms/OWALintWiki)
         * This code should be fixed by anyone touching it. - WI #324665
         *	> Mutators should not dispatch asynchronous operations
         *	> Mutators should not dispatch asynchronous operations */
        lazyGetHashedLogString.import().then(getHashedLogString => {
            addMailListLog(
                getMailListLogObjectToAddToStore('Tombstone:RemoveRow', {
                    folderId: getHashedLogString(folderId),
                    rowKey: instanceKey,
                    removalReason: tombstoneReason,
                })
            );
        });
    }
);

/**
 * Removes all instanceKeys from the tombstone map
 */
export const removeAllMutator = mutatorAction('tombstoneOperations.removeAll', () => {
    // Remove all by re-initializing entire folderTombstoneMap
    folderTombstoneMap = {};
    logVerboseUsage('TnS_RemoveAllFromTombstone');
    addMailListLog(getMailListLogObjectToAddToStore('Tombstone:ClearTombstoneForAllFolders', {}));
});

export const getCount = function getCount(
    folderId: string,
    tombstoneReasonType: TombstoneReasonType
): number {
    if (!(folderId in folderTombstoneMap)) {
        return 0;
    }

    let count = 0;
    const tombstoneMap = folderTombstoneMap[folderId];
    const tombstoneMapValuesForAllRows = Object.values(tombstoneMap);
    for (const tombstoneEntriesForRow of tombstoneMapValuesForAllRows) {
        for (const tombstoneEntry of Object.values(tombstoneEntriesForRow)) {
            if (tombstoneEntry.tombstoneReason === tombstoneReasonType) {
                count++;
            }
        }
    }

    return count;
};

/**
 * Returns tombstoned reasons for this row
 * @param instanceKey - the instanceKey to check
 * @param folderId for the instanceKey
 * @returns the array of TombstoneReasonType if it exists, undefined otherwise
 */
export const getTombstonedReasons = function getTombstonedReasons(
    instanceKey: string,
    folderId: string
): TombstoneReasonType[] {
    if (folderId in folderTombstoneMap) {
        const rowTombstoneMap = folderTombstoneMap[folderId];
        const tombstoneReasonsForRow = rowTombstoneMap[instanceKey];
        if (tombstoneReasonsForRow) {
            return tombstoneReasonsForRow.map(tombstoneData => tombstoneData.tombstoneReason);
        }
    }

    /**
     * This row may not have been tombstoned
     */
    return [];
};

/**
 * Returns the custom (JSON) data for a given tombstone reason if it exists
 * @param instanceKey - the instanceKey to check
 * @param folderId for the instanceKey
 * @param tombstoneReasonType- the tombstone reason to check
 * @returns the customData corresponding to a given tombstone reason for the row if it exists, null otherwise
 */
export const getTombstonedReasonCustomData = function getTombstonedReasonCustomData(
    instanceKey: string,
    folderId: string,
    tombstoneReasonType: TombstoneReasonType
): string | undefined {
    if (folderId in folderTombstoneMap) {
        const rowTombstoneMap = folderTombstoneMap[folderId];
        const tombstoneReasonsForRow = rowTombstoneMap[instanceKey];
        const index = tombstoneReasonsForRow.findIndex(
            tombstoneInfo => tombstoneInfo.tombstoneReason === tombstoneReasonType
        );
        if (index > -1) {
            return tombstoneReasonsForRow[index].customData;
        }
    }

    return undefined;
};

/**
 * Clear all instanceKeys for the given folderId from tombstone map
 * @param folderId for these instanceKeys
 */
export const clearMapForFolderMutator = mutatorAction(
    'tombstoneOperations.clearMapForFolder',
    (folderId: string) => {
        // Delete the map for the folder
        if (folderTombstoneMap[folderId]) {
            logVerboseUsage('TnS_ClearFolderMapInTombstone', [
                Object.keys(folderTombstoneMap[folderId]).length,
            ]);
            delete folderTombstoneMap[folderId];
        }
    }
);

/**
 * Remove rows from tombstone
 * @param instanceKeys InstanceKeys for the rows to be removed from tombstone
 * @param folderId folder to which the rows belong
 */
export const removeRowsFromTombstoneMutator = mutatorAction(
    'tombstoneOperations.removeRowsFromTombstoneMutator',
    (instanceKeys: string[], folderId: string) => {
        // Tombstone list doesn't exist for this folder.
        // User may have never performed triage operations supported by tombstone in this folder
        if (!(folderId in folderTombstoneMap)) {
            return;
        }
        const rowTombstoneMap = folderTombstoneMap[folderId];
        for (const instanceKey of instanceKeys) {
            if (instanceKey in rowTombstoneMap) {
                delete rowTombstoneMap[instanceKey];
            }
        }

        /**
         * Delete folder's entry if there are no more rows tombstoned in it
         */
        if (Object.keys(rowTombstoneMap).length == 0) {
            delete folderTombstoneMap[folderId];
        }

        /* eslint-disable-next-line owa-custom-rules/no-async-calls-in-mutator -- (https://aka.ms/OWALintWiki)
         * This code should be fixed by anyone touching it. - WI #324665
         *	> Mutators should not dispatch asynchronous operations
         *	> Mutators should not dispatch asynchronous operations */
        lazyGetHashedLogString.import().then(getHashedLogString => {
            addMailListLog(
                getMailListLogObjectToAddToStore('Tombstone:RemoveRows', {
                    folderId: getHashedLogString(folderId),
                    rowKeys: instanceKeys.toString(),
                })
            );
        });
    }
);
