import { observer } from 'owa-mobx-react';
import {
    getIsSearchTableShown,
    getStore as getListViewStore,
    type MailFolderTableQuery,
    type SortColumn,
    updateCopilotPrioritizeOnboardedCalloutTarget,
} from 'owa-mail-list-store';
import type { MailListItemDataProps, MailListTableProps } from 'owa-mail-list-item-shared';
import React from 'react';
import MailListItemSingleLineView from './MailListItemSingleLineView';
import MailListItemCondensedThreeColumn from './MailListItemCondensedThreeColumn';
import MailListItemFullThreeColumn from './MailListItemFullThreeColumn';
import { lazyFetchCopilotInboxHeadline } from 'owa-mail-spotlight';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { isCopilotHighPriority } from 'owa-mail-copilot-inbox-shared';

export interface MailListItemProps {
    isFirstLevelExpanded: boolean;
    isLoadingSecondLevelExpansion: boolean;
    isSecondLevelExpanded: boolean;
    mailListItemDataProps: MailListItemDataProps;
    mailListTableProps: MailListTableProps;
    styleSelectorAsPerUserSettings: string;
    renderMinimum: boolean;
}

export default observer(function MailListItem(props: MailListItemProps) {
    const {
        isFirstLevelExpanded,
        isLoadingSecondLevelExpansion,
        isSecondLevelExpanded,
        mailListItemDataProps,
        mailListTableProps,
        styleSelectorAsPerUserSettings,
        renderMinimum,
    } = props;

    const {
        canArchive,
        canDelete,
        canPin,
        categories,
        conversationId,
        copilotInboxScore,
        effectiveMentioned,
        firstLineText,
        firstLineTooltipText,
        hasAttachment,
        hasSharepointLink,
        importance,
        isComplete,
        isFlagged,
        isInArchiveFolder,
        isInNotesFolder,
        isInOutboxFolder,
        isInJunkFolder,
        isPinned,
        isSelected,
        isSnoozed,
        isTaggedForBigScreen,
        isUnauthenticatedSender,
        itemClassIcon,
        lastDeliveryTimestamp,
        lastSender,
        latestItemId,
        mailboxGuids,
        parentFolderDisplayName,
        parentFolderId,
        returnTime,
        rowId,
        rowKey,
        secondLineText,
        secondLineTooltipText,
        shouldShowExternalLabel,
        shouldShowRSVP,
        shouldShowTwisty,
        shouldShowSenderGroupingsButton,
        shouldSuggestUnsubscribe,
        showAttachmentPreview,
        showCondensedView,
        showDraftsIndicator,
        smimeType,
        thirdLineText,
        unreadCount,
        isScheduledSend,
    } = mailListItemDataProps;

    const {
        highlightTerms,
        isSingleLine,
        listViewType,
        showFolderTag,
        showPreviewText,
        supportsFlagging,
        supportsMove,
        supportsPinning,
        tableViewId,
    } = mailListTableProps;

    // turn off hover icons while scrolling in the list
    const supportsHoverIcons = mailListTableProps.supportsHoverIcons;
    const isSearchTable = getIsSearchTableShown();

    React.useEffect(() => {
        if (!renderMinimum) {
            setTimeout(() => {
                const tableView = getListViewStore().tableViews.get(tableViewId);
                if (!tableView) {
                    return;
                }

                const sort = (tableView.tableQuery as MailFolderTableQuery).sortBy?.sortColumn;
                const isPrioritySort = sort === 16;

                lazyFetchCopilotInboxHeadline.importAndExecute({
                    rowKeys: [rowKey],
                    mailboxInfo: getMailboxInfo(tableView),
                    listViewType,
                    tableViewId,
                    fetchCopilotInboxHeadlineOverride: false,
                    isPrioritySort,
                });
            }, 0);
        }
    }, [renderMinimum, rowKey, listViewType, tableViewId]);

    // If the current row is a valid target for the Prioritize callout, then attempt
    // to update the store with it's ID. The setter will only write to the store once,
    // so whatever the first valid target is will be the one that is stored.
    React.useEffect(() => {
        const tableView = getListViewStore().tableViews.get(tableViewId);

        const isPrioritizeCalloutTarget =
            !isPinned &&
            unreadCount > 0 &&
            isCopilotHighPriority(copilotInboxScore) &&
            tableView?.rowKeys.slice(0, 10).includes(rowKey);

        if (isPrioritizeCalloutTarget) {
            updateCopilotPrioritizeOnboardedCalloutTarget(rowId);
        }
    }, [rowId, isPinned, unreadCount, copilotInboxScore, rowKey]);

    if (isSingleLine) {
        return (
            <MailListItemSingleLineView
                canArchive={canArchive}
                canDelete={canDelete}
                canPin={canPin}
                categories={categories}
                conversationId={conversationId}
                copilotInboxScore={copilotInboxScore}
                effectiveMentioned={effectiveMentioned}
                firstLineText={firstLineText}
                firstLineTooltipText={firstLineTooltipText}
                hasAttachment={hasAttachment}
                hasSharepointLink={hasSharepointLink}
                highlightTerms={highlightTerms}
                importance={importance}
                isComplete={isComplete}
                isFirstLevelExpanded={isFirstLevelExpanded}
                isFlagged={isFlagged}
                isInArchiveFolder={isInArchiveFolder}
                isInNotesFolder={isInNotesFolder}
                isInOutboxFolder={isInOutboxFolder}
                isInJunkFolder={isInJunkFolder}
                isLoadingSecondLevelExpansion={isLoadingSecondLevelExpansion}
                isPinned={isPinned}
                renderMinimum={renderMinimum}
                isSearchTable={isSearchTable}
                isSecondLevelExpanded={isSecondLevelExpanded}
                isSelected={isSelected}
                isSnoozed={isSnoozed}
                isTaggedForBigScreen={isTaggedForBigScreen}
                isUnauthenticatedSender={isUnauthenticatedSender}
                itemClassIcon={itemClassIcon}
                lastDeliveryTimestamp={lastDeliveryTimestamp}
                lastSender={lastSender}
                latestItemId={latestItemId}
                listViewType={listViewType}
                mailboxGuids={mailboxGuids}
                parentFolderDisplayName={parentFolderDisplayName}
                parentFolderId={parentFolderId}
                returnTime={returnTime}
                rowId={rowId}
                rowKey={rowKey}
                secondLineText={secondLineText}
                secondLineTooltipText={secondLineTooltipText}
                shouldShowExternalLabel={shouldShowExternalLabel}
                shouldShowRSVP={shouldShowRSVP}
                shouldShowTwisty={shouldShowTwisty}
                shouldShowSenderGroupingsButton={shouldShowSenderGroupingsButton}
                shouldSuggestUnsubscribe={shouldSuggestUnsubscribe}
                showAttachmentPreview={showAttachmentPreview}
                showCondensedView={showCondensedView}
                showDraftsIndicator={showDraftsIndicator}
                showFolderTag={showFolderTag}
                showPreviewText={showPreviewText}
                smimeType={smimeType}
                supportsFlagging={supportsFlagging}
                supportsHoverIcons={supportsHoverIcons}
                supportsMove={supportsMove}
                supportsPinning={supportsPinning}
                tableViewId={tableViewId}
                thirdLineText={thirdLineText}
                unreadCount={unreadCount}
                isScheduledSend={isScheduledSend}
            />
        );
    }

    if (showCondensedView) {
        return (
            <MailListItemCondensedThreeColumn
                canArchive={canArchive}
                canDelete={canDelete}
                canPin={canPin}
                categories={categories}
                conversationId={conversationId}
                copilotInboxScore={copilotInboxScore}
                effectiveMentioned={effectiveMentioned}
                firstLineText={firstLineText}
                firstLineTooltipText={firstLineTooltipText}
                hasAttachment={hasAttachment}
                hasSharepointLink={hasSharepointLink}
                highlightTerms={highlightTerms}
                importance={importance}
                isComplete={isComplete}
                isFirstLevelExpanded={isFirstLevelExpanded}
                isFlagged={isFlagged}
                isInArchiveFolder={isInArchiveFolder}
                isInNotesFolder={isInNotesFolder}
                isInOutboxFolder={isInOutboxFolder}
                isInJunkFolder={isInJunkFolder}
                isPinned={isPinned}
                isLoadingSecondLevelExpansion={isLoadingSecondLevelExpansion}
                renderMinimum={renderMinimum}
                isSearchTable={isSearchTable}
                isSecondLevelExpanded={isSecondLevelExpanded}
                isSelected={isSelected}
                isSnoozed={isSnoozed}
                isTaggedForBigScreen={isTaggedForBigScreen}
                isUnauthenticatedSender={isUnauthenticatedSender}
                itemClassIcon={itemClassIcon}
                lastDeliveryTimestamp={lastDeliveryTimestamp}
                lastSender={lastSender}
                mailboxGuids={mailboxGuids}
                parentFolderDisplayName={parentFolderDisplayName}
                parentFolderId={parentFolderId}
                rowId={rowId}
                rowKey={rowKey}
                secondLineText={secondLineText}
                secondLineTooltipText={secondLineTooltipText}
                shouldShowExternalLabel={shouldShowExternalLabel}
                shouldShowTwisty={shouldShowTwisty}
                shouldShowSenderGroupingsButton={shouldShowSenderGroupingsButton}
                showDraftsIndicator={showDraftsIndicator}
                showFolderTag={showFolderTag}
                showPreviewText={showPreviewText}
                smimeType={smimeType}
                styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                supportsFlagging={supportsFlagging}
                supportsHoverIcons={supportsHoverIcons}
                supportsMove={supportsMove}
                supportsPinning={supportsPinning}
                tableViewId={tableViewId}
                unreadCount={unreadCount}
                isScheduledSend={isScheduledSend}
            />
        );
    } else {
        return (
            <MailListItemFullThreeColumn
                canArchive={canArchive}
                canDelete={canDelete}
                canPin={canPin}
                categories={categories}
                conversationId={conversationId}
                copilotInboxScore={copilotInboxScore}
                effectiveMentioned={effectiveMentioned}
                firstLineText={firstLineText}
                firstLineTooltipText={firstLineTooltipText}
                hasAttachment={hasAttachment}
                hasSharepointLink={hasSharepointLink}
                highlightTerms={highlightTerms}
                importance={importance}
                isComplete={isComplete}
                isFirstLevelExpanded={isFirstLevelExpanded}
                isFlagged={isFlagged}
                isInArchiveFolder={isInArchiveFolder}
                isInNotesFolder={isInNotesFolder}
                isInOutboxFolder={isInOutboxFolder}
                isInJunkFolder={isInJunkFolder}
                isLoadingSecondLevelExpansion={isLoadingSecondLevelExpansion}
                isPinned={isPinned}
                renderMinimum={renderMinimum}
                isSearchTable={isSearchTable}
                isSecondLevelExpanded={isSecondLevelExpanded}
                isSelected={isSelected}
                isSnoozed={isSnoozed}
                isTaggedForBigScreen={isTaggedForBigScreen}
                isUnauthenticatedSender={isUnauthenticatedSender}
                itemClassIcon={itemClassIcon}
                lastDeliveryTimestamp={lastDeliveryTimestamp}
                lastSender={lastSender}
                latestItemId={latestItemId}
                listViewType={listViewType}
                mailboxGuids={mailboxGuids}
                parentFolderDisplayName={parentFolderDisplayName}
                parentFolderId={parentFolderId}
                returnTime={returnTime}
                rowId={rowId}
                rowKey={rowKey}
                secondLineText={secondLineText}
                secondLineTooltipText={secondLineTooltipText}
                shouldShowExternalLabel={shouldShowExternalLabel}
                shouldShowRSVP={shouldShowRSVP}
                shouldShowTwisty={shouldShowTwisty}
                shouldShowSenderGroupingsButton={shouldShowSenderGroupingsButton}
                shouldSuggestUnsubscribe={shouldSuggestUnsubscribe}
                showAttachmentPreview={showAttachmentPreview}
                showDraftsIndicator={showDraftsIndicator}
                showFolderTag={showFolderTag}
                showPreviewText={showPreviewText}
                smimeType={smimeType}
                styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                supportsFlagging={supportsFlagging}
                supportsHoverIcons={supportsHoverIcons}
                supportsMove={supportsMove}
                supportsPinning={supportsPinning}
                tableViewId={tableViewId}
                thirdLineText={thirdLineText}
                unreadCount={unreadCount}
                isScheduledSend={isScheduledSend}
            />
        );
    }
}, 'MailListItem');
