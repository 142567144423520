import { LazyModule, registerLazyOrchestrator } from 'owa-bundling';

import { incrementSidepaneClickCountAction } from './actions/incrementSidepaneClickCountAction';

export { incrementSidepaneClickCountAction };

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupsPanelFeedback"*/ './lazyIndex'),
    { name: 'GroupsPanelFeedback' }
);

registerLazyOrchestrator(
    incrementSidepaneClickCountAction,
    lazyModule,
    m => m.incrementSidepaneClickCountOrchestrator
);
