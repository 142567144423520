import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isTeamsFeatureEnabled } from 'owa-teams-policies-store/lib/selectors/isTeamsFeatureEnabled';
import { isTeamsPoliciesFetchNotStarted } from 'owa-teams-policies-store/lib/selectors/isTeamsPoliciesFetchNotStarted';
import { lazyGetTeamsPolicies } from 'owa-teams-policies';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { isBootFeatureEnabled } from 'owa-metatags';
import { getApp } from 'owa-config';
import { TeamsPoliciesScenario } from 'owa-teams-policies-store/lib/store/schema';

const isPresenceSupported = (mailboxInfo: MailboxInfo) => {
    if (isTeamsPoliciesFetchNotStarted()) {
        // Trigger fetching of teams policies if not already started
        lazyGetTeamsPolicies.importAndExecute(TeamsPoliciesScenario.Presence, mailboxInfo);
    }

    return (
        (isFeatureEnabled('mon-presence') || isPlacesAppWithPlacesAppId()) &&
        !isConsumer(undefined, mailboxInfo) &&
        isServiceRequestSupportedForMailbox(mailboxInfo) &&
        isTeamsFeatureEnabled()
    );
};

function isPlacesAppWithPlacesAppId() {
    return isBootFeatureEnabled('auth-msaljs-places') && getApp() === 'Places';
}

export default isPresenceSupported;
