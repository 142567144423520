import classNames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { logUsage, logCoreUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import {
    getNativeAdPlacement,
    type NativeAdPlacement,
    shouldShowAdsInFocusedPivot,
} from 'owa-mail-ads-shared';
import { onMailListScroll as onMailListScrollForSearch } from 'owa-mail-search/lib/utils/onMailListScroll';
import { lazyClearFilter } from 'owa-mail-filter-actions';
import { type FocusComponent } from 'owa-mail-focus-manager';
import {
    registerComponent,
    resetFocus,
} from 'owa-mail-focus-manager/lib/mailModuleAutoFocusManager';
import {
    isReadingPanePositionOff,
    setShowReadingPane,
    shouldShowListView,
    isSingleLineListView,
} from 'owa-mail-layout';
import { hideMailItemContextMenu } from 'owa-mail-list-actions/lib/actions/itemContextMenuActions';
import { MailListItemContextMenuV9 } from 'owa-mail-list-context-menu';
import { MailListEmptyState, MailListShimmerState } from 'owa-mail-list-empty-state';
import type { SearchTableQuery } from 'owa-mail-list-search';
import type { TableView } from 'owa-mail-list-store';
import {
    getFocusedFilterForTable,
    getStore as getListViewStore,
    getListViewState,
    type TableQueryType,
} from 'owa-mail-list-store';
import type MailListItemContextMenuState from 'owa-mail-list-store/lib/store/schema/MailListItemContextMenuState';
import { getMailListLoadStateFromTableView } from 'owa-mail-list-store/lib/utils/getMailListLoadState';
import { messageAdListViewStatusStore } from 'owa-mail-messagead-list-store';
import isAnswerFeatureEnabled from 'owa-mail-search/lib/utils/isAnswerFeatureEnabled';
import { type MailListViewState } from 'owa-mail-store/lib/store/schema/MailListViewState';
import { loadTableViewFromTableQuery } from 'owa-mail-table-loading-actions';
import { isFocusedInboxEnabled } from 'owa-mail-triage-common';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import React from 'react';
import { wrapperDiv } from './MailList.scss';
import MailListContent from './MailListContent';
import { getMailboxInfoFromTableViewId } from 'owa-mail-mailboxinfo/lib/getMailboxInfo';
import { useResizeObserverRef } from 'owa-react-hooks/lib/useResizeObserver';
import { useWindowSize } from 'owa-react-hooks/lib/useWindowSize';

export interface MailListProps {
    itemContextMenuState?: MailListItemContextMenuState | null;
    tableViewId: string;
    styleSelectorAsPerUserSettings: string;
}

export default observer(function MailList(props: MailListProps) {
    const listViewStore = getListViewStore();
    const tableView = listViewStore.tableViews.get(props.tableViewId);

    const { height: windowHeight } = useWindowSize('MailList');

    // The planned floating ad position is between the 3rd item to the 5th item. (Top ad is not counted)
    // Skip the floatingAdIndex change when the floating ad flight is not turned on.
    if (
        tableView != null &&
        tableView.floatingAdIndex == -1 &&
        (isFeatureEnabled('adsExp-NativeAds-FloatingAd-OtherInbox') ||
            isFeatureEnabled('adsExp-NativeAds-FloatingAd-PrimaryInbox'))
    ) {
        tableView.floatingAdIndex = Math.floor(Math.random() * 3 + 3);
    }

    props = {
        ...props,
        itemContextMenuState: props.itemContextMenuState ?? getListViewState().itemContextMenuState,
    };
    React.useEffect(() => {
        // Register the component with FocusManager.
        // This should NOT be moved to lazy, as we want the
        // focus to be delegated immediately upon mount and
        // can't afford to wait for the lazy import.
        // See https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/79563.
        const unregisterComponent: () => void = registerComponent('MailList', trySetFocus);
        // Unregister our component from FocusManager when we unmount
        return function cleanup() {
            if (unregisterComponent) {
                unregisterComponent();
            }
        };
    }, []);

    React.useEffect(() => {
        oldNumRows.current = tableView?.rowKeys.length ?? 0;
    }, [props.tableViewId]);

    const listViewContainer = React.useRef<HTMLDivElement>(null);
    const mailListContent = React.useRef<HTMLDivElement>(null);
    const oldNumRows = React.useRef<number>(0);
    const itemsLoaded = React.useRef<boolean>(false);
    const mailListViewState: MailListViewState = useComputedValue(() => {
        return getMailListLoadStateFromTableView(tableView);
    }, [tableView]);
    const isSearch: boolean = useComputedValue(() => {
        if (tableView?.tableQuery.type !== 1) {
            return false;
        }
        const scenarioType = (tableView.tableQuery as SearchTableQuery).scenarioType;
        return scenarioType !== 'persona' && scenarioType !== 'privateDistributionList';
    }, [tableView]);

    React.useEffect(() => {
        if (mailListViewState == 'Loaded') {
            itemsLoaded.current = true;
        } else if (mailListViewState == 'Loading') {
            itemsLoaded.current = false;
        }

        // If the ML completes loading, then focus should be moved to the content.
        if (mailListViewState !== 'Loading') {
            resetFocus('MailListMount');
        }
    }, [mailListViewState]);

    function checkAnyOtherPreferPlacement(nativeAdPlacement: NativeAdPlacement): boolean {
        return nativeAdPlacement == 4;
    }

    function checkAnyFocusAllowedPlacement(nativeAdPlacement: NativeAdPlacement): boolean {
        return nativeAdPlacement == 4;
    }

    function checkAnyAllfoldersAllowedPlacement(nativeAdPlacement: NativeAdPlacement): boolean {
        return nativeAdPlacement == 4;
    }

    function checkWindowHeightAllowMiddleAd(plannedMidFloatingAdRow: number): boolean {
        // If floating ad is already moved, we will skip the window height check to allow the floating ad to be pushed down out of the screen
        if (tableView?.floatingAdIndexChanged) {
            return true;
        }

        // Check the window size. If the window size is too small, we will not show the middle ad.
        // The calculation is a little conservative as we'd rather show top two ads instead of show a middle floating ad out of the view.
        // We use the header height as the base and plus the full size of the message height with header (assuming each message has a header).
        const minimumShowMiddleAdHeight = isSingleLineListView()
            ? 290 + 70 * plannedMidFloatingAdRow
            : 300 + 120 * plannedMidFloatingAdRow;
        return windowHeight > minimumShowMiddleAdHeight;
    }

    const shouldShowMiddleMessageAdList = (table: TableView | undefined): boolean => {
        // If none of the FloatingAd flight is on, skip all the further checks to save the time.
        if (
            !(
                isFeatureEnabled('adsExp-NativeAds-FloatingAd-OtherInbox') ||
                isFeatureEnabled('adsExp-NativeAds-FloatingAd-PrimaryInbox')
            )
        ) {
            return false;
        }

        // Middle ad will not be shown in the following cases:
        // 1. Table is undefined.
        // 2. The floating ad is already deleted.
        // 3. The ad placement is not OtherPrefer
        // 4. Planned position is greater than the number of rows.
        // 5. The folder is not inbox.
        if (table == undefined || messageAdListViewStatusStore?.midFloatingAdDeleted) {
            return false;
        }

        if (table.tableQuery.type == 1) {
            return false;
        }

        const nativeAdPlacement = getNativeAdPlacement();

        // If the ad placement is not OtherPrefer. Non-consumer user will return false for the following check.
        const isAnyOtherPreferPlacement = checkAnyOtherPreferPlacement(nativeAdPlacement);
        if (!checkAnyOtherPreferPlacement(nativeAdPlacement)) {
            return false;
        }

        if (
            tableView != null &&
            table.rowKeys.length - 1 <= tableView?.floatingAdIndex &&
            !tableView?.floatingAdIndexChanged
        ) {
            return false;
        }

        if (tableView != null && !checkWindowHeightAllowMiddleAd(tableView.floatingAdIndex)) {
            // During the feature experiment stage, as the log only happens when the user under the floating ad flight while passing all other checks, the number will not be too big.
            logCoreUsage('FloatingAdHideForSmallScreen');
            return false;
        }

        const folderId = folderIdToName(table.tableQuery.folderId);
        const inOtherPivot = getFocusedFilterForTable(table) == 1;

        if (folderId == 'inbox') {
            return (
                (inOtherPivot &&
                    isAnyOtherPreferPlacement &&
                    isFeatureEnabled('adsExp-NativeAds-FloatingAd-OtherInbox')) ||
                (isAnyOtherPreferPlacement &&
                    !isFocusedInboxEnabled(getMailboxInfoFromTableViewId(props.tableViewId)) &&
                    isFeatureEnabled('adsExp-NativeAds-FloatingAd-PrimaryInbox'))
            );
        }

        return false;
    };

    const shouldShowTopMessageAdList = (table: TableView | undefined): boolean => {
        // Non-consumer user will get false for the following check.
        const nativeAdPlacement = getNativeAdPlacement();
        if (nativeAdPlacement === 0) {
            return false;
        }

        if (table == undefined) {
            return false;
        }

        const folderId = folderIdToName(table.tableQuery.folderId);

        if (folderId == 'inbox') {
            // We will hide the top ad in the following cases:
            // 1. If the ad is deleted by the user. we will re-trigger when
            // --a. User is a super clicker
            // --b. User's refreshAfterDeleteInterval is greater than 0 which means he is at some refresh flights
            // --c. Current time minus the last Ad deletion time is greater than the set interval
            // 2. Top floating ad is deleted
            if (
                (messageAdListViewStatusStore?.inboxFolderShowAdCount == 0 &&
                    !(
                        messageAdListViewStatusStore?.isSuperClicker &&
                        messageAdListViewStatusStore?.refreshAfterDeleteInterval > 0 &&
                        messageAdListViewStatusStore?.latestAdDeleteTime != 0 &&
                        Date.now() - messageAdListViewStatusStore.latestAdDeleteTime >
                            messageAdListViewStatusStore.refreshAfterDeleteInterval
                    )) ||
                messageAdListViewStatusStore?.topFloatingAdDeleted
            ) {
                return false;
            }

            const isAnyOtherPreferPlacement = checkAnyOtherPreferPlacement(nativeAdPlacement);
            const inOtherPivot = getFocusedFilterForTable(table) == 1;
            const loadInFocusedPivot = getFocusedFilterForTable(table) == 0;

            // Show the native ads in Inbox when the following condistions are true
            // 1. The placement needs to be OtherPrefer or OtherPreferAllFolders
            // 2. The view is in the other pivot OR [the view in the Inbox primary view when Focus/Other option is off AND middle floating ad primary inbox cannot be shown]
            // The reason of 2 is we only show the middle floating ad for primary inbox
            return (
                (inOtherPivot && isAnyOtherPreferPlacement) ||
                (isAnyOtherPreferPlacement &&
                    !isFocusedInboxEnabled(getMailboxInfoFromTableViewId(props.tableViewId)) &&
                    !(
                        // To show a middle ad in the primary inbox, the following conditions need to be met:
                        // 1. The floating ad flight is enabled for Inbox
                        // 2. The number of rows is greater than the planned position
                        // 3. The window height is large enough to show the middle ad
                        (
                            isFeatureEnabled('adsExp-NativeAds-FloatingAd-PrimaryInbox') &&
                            tableView != null &&
                            table.rowKeys.length - 1 > tableView.floatingAdIndex &&
                            checkWindowHeightAllowMiddleAd(tableView.floatingAdIndex)
                        )
                    )) ||
                (loadInFocusedPivot &&
                    checkAnyFocusAllowedPlacement(nativeAdPlacement) &&
                    shouldShowAdsInFocusedPivot())
            );
        } else {
            // For non-Inbox folders, we show the ad when the following conditions are true
            // 1. OtherPreferAllFolders is the placement
            // 2. The folder is in the allow list. We allow archive and custom folders to show the ad.
            // 3. The Ad is not deleted in this list
            const foldersAllowNativeAdOtherPreferAllFolders = ['archive', 'none'];

            return (
                checkAnyAllfoldersAllowedPlacement(nativeAdPlacement) &&
                foldersAllowNativeAdOtherPreferAllFolders.indexOf(
                    folderIdToName(table.tableQuery.folderId)
                ) > -1 &&
                messageAdListViewStatusStore?.adRemovedFolderIds.indexOf(
                    table.tableQuery.folderId
                ) == -1
            );
        }
    };

    /**
     * Callback when the list view gains focus from the focus manager
     */
    const trySetFocus = (): boolean => {
        // This can be null when component is not fully mounted
        if (!listViewContainer.current) {
            return false;
        }
        const currentShowListPane = shouldShowListView();
        if (currentShowListPane) {
            // mailListViewContent instance can be null until content gets
            // fully mounted when 1) user switches folder or 2) OWA reloads
            // Or when the state of the MailLIstViewState is still loading
            if (mailListContent.current && mailListViewState == 'Loaded') {
                // Focus on content if it exists and focus is not currently there
                if (mailListContent.current != document.activeElement) {
                    mailListContent.current.focus();
                }
                return true;
            } else {
                if (listViewContainer.current != document.activeElement) {
                    listViewContainer.current.focus();
                }
                return true;
            }
        }
        // Let FocusManager handle the job when listview is hidden
        return false;
    };
    /**
     * Returns flag indicating whether the document active element is contained within this MailList
     */
    const getIsFocusInMailList = React.useCallback((): boolean => {
        return !!listViewContainer.current?.contains?.(document.activeElement);
    }, []);

    const isFocusOnMailList = React.useCallback((): boolean => {
        return listViewContainer.current == document.activeElement;
    }, []);
    /**
     * Reload the default list view for selected folder id
     */
    const reloadTable = () => {
        if (tableView) {
            loadTableViewFromTableQuery(
                tableView.tableQuery,
                undefined /* loadTableViewDatapoint */,
                'EmptyListState'
            );
        }
        // Log that retry was clicked
        logUsage('TnS_RetryLoadMailListClicked');
    };
    const onDragOver = (evt: React.DragEvent<EventTarget>) => {
        /* We need preventDefault so that the circle-slash ghost icon does not show and to prevent redirection in firefox */
        evt.preventDefault();
        if (evt.ctrlKey) {
            evt.dataTransfer.dropEffect = 'copy';
        }
    };
    const onDrop = (evt: React.DragEvent<EventTarget>) => {
        /* We need preventDefault to prevent redirection in firefox */
        evt.preventDefault();
    };
    /**
     * Renders the mail list item context menu by initiating the itemContextMenuState state in store
     */
    const renderItemContextMenu = (): JSX.Element | null => {
        const { itemContextMenuState } = props;
        if (!itemContextMenuState) {
            return null;
        }

        return (
            <MailListItemContextMenuV9
                anchorPoint={itemContextMenuState.anchor}
                menuType={itemContextMenuState.menuType}
                menuSource={itemContextMenuState.menuSource}
                onDismiss={dismissContextMenu}
                tableViewId={props.tableViewId}
            />
        );
    };
    /**
     * Called when mail list scroll
     */
    const onMailListScroll = React.useCallback(
        (scrollingRegion: HTMLDivElement) => {
            // If context menu was open, dismiss the context menu
            if (props.itemContextMenuState) {
                dismissContextMenu();
            }

            if (!tableView) {
                return;
            }
            const { rowKeys, totalRowsInView } = tableView;
            const newNumRows = rowKeys.length;
            // Log how many total rows loaded in after user scroll and total number of rows in the view
            // This dp is TEMPORARY (as it will be very noisy)
            if (newNumRows > oldNumRows.current) {
                logUsage(
                    'TnS_ScrollLoadMore',
                    [newNumRows, totalRowsInView],
                    undefined /* DatapointOptions */,
                    true /* shouldYield */
                );
                oldNumRows.current = newNumRows;
            }

            onMailListScrollForSearch(tableView, scrollingRegion);

            if (!getIsFocusInMailList()) {
                resetFocus('MailListScroll');
            }
        },
        [props.itemContextMenuState, tableView]
    );

    const { current: listViewContainerRect } = useResizeObserverRef('MailList', listViewContainer);

    /**
     * Dismiss the context menu
     */
    const dismissContextMenu = () => {
        props.itemContextMenuState?.anchorRowKeyDiv?.focus();
        hideMailItemContextMenu();
        // After dismissing context menu show the reading pane so that user does not
        // have to select the item again
        if (!isReadingPanePositionOff()) {
            setShowReadingPane(true /* showReadingPane */);
        }
    };
    let listViewContent: JSX.Element | null = null;
    const shouldShowSearchAnswer = isAnswerFeatureEnabled();
    // Render when table is Loaded.
    if (mailListViewState == 'Loaded') {
        listViewContent = tableView ? (
            <>
                <MailListContent
                    ref={mailListContent}
                    isFocusOnMailList={isFocusOnMailList}
                    getIsFocusInMailList={getIsFocusInMailList}
                    tableViewId={props.tableViewId}
                    tableView={tableView}
                    onScroll={onMailListScroll}
                    shouldShowTopMessageAdList={shouldShowTopMessageAdList(tableView)}
                    shouldShowFloatingMessageAdList={shouldShowMiddleMessageAdList(tableView)}
                    plannedMiddleMessageAdRow={tableView ? tableView.floatingAdIndex : -1}
                    styleSelectorAsPerUserSettings={props.styleSelectorAsPerUserSettings}
                    itemContextMenuState={
                        props.itemContextMenuState ?? getListViewState().itemContextMenuState
                    }
                />
                {renderItemContextMenu()}
            </>
        ) : null;
    } else {
        listViewContent =
            mailListViewState === 'Loading' && !isSearch ? (
                <MailListShimmerState heightOfView={listViewContainerRect?.height ?? 1000} />
            ) : (
                <MailListEmptyState
                    tableViewId={props.tableViewId}
                    stateType={mailListViewState}
                    clearFilterCommand={clearFilter}
                    reloadCommand={reloadTable}
                    shouldShowMessageAdList={shouldShowTopMessageAdList(tableView)}
                    shouldShowSearchAnswer={shouldShowSearchAnswer}
                    itemsPreviouslyLoaded={itemsLoaded.current}
                />
            );
    }
    return (
        <div
            id={'MailList'}
            tabIndex={-1}
            ref={listViewContainer}
            className={classNames(wrapperDiv, shouldShowSearchAnswer && 'customScrollBar')}
            {...(mailListViewState == 'Loaded' && {
                onDragOver,
                onDrop,
            })}
        >
            {listViewContent}
        </div>
    );
}, 'MailList');
function clearFilter() {
    lazyClearFilter.importAndExecute('EmptyListState');
}
